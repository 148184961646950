import { useNavigate } from "react-router-dom";

function RelatedArticles({ relatedArticles, className }) {
  const navigate = useNavigate();
  return (
    <section className={className+" mt-12 "}>
      <h2 className="text-2xl font-semibold mb-6">Artículos Recomendados</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {relatedArticles.map((article, index) => (
          <div
            key={index}
            className="border rounded-lg hover:shadow-lg transition-shadow"
          >
            <img
              src={article.image}
              alt={article.title}
              className="w-full rounded-t-lg"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{article.title}</h3>
              <p className="text-gray-600">{article.description}</p>
              <button
                onClick={() => navigate(article.href)}
                className="mt-4 border border-blue-600 text-blue-600 px-4 py-2 rounded hover:bg-blue-600 hover:text-white transition-colors"
              >
                {article.buttonText}
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default RelatedArticles;
