import React from 'react';
import NotImgPerfil from '../../assets/Perfiluser.webp'

function CardPerfil({ nombre, imagenPerfil }) {
    return (
        <div className='flex items-center justify-left mx-auto lg:w-4/5 mx-auto pl-10 pt-0 border-b-2 border-gray-300 pb-2 '>
            <div className='text-6xl'>
            <img width="300" height="200"
 className='w-20 h-20 rounded-full' src={imagenPerfil || NotImgPerfil} alt="Perfil" />
            </div>
            <div className='ml-5'>
                <h2 className='font-black uppercase text-2xl'>
                    <span className="text-[#1e3d8b]">Mi</span>
                    <span className="text-[#1e3d8b] ml-2">perfil</span>
                </h2>
                <p className='text-[#1e3d8b] font-semibold '>{nombre}</p>
            </div>
        </div>
    );
}

export default CardPerfil;
