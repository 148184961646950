import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import HeaderEspecial from '../../Headers/HeadeEpecial';
import InputDos from '../../inputs/Inputo2';
import Resumnpagoslist from '../Resumnpagoslist';

import axios from 'axios';
import CargandoSpinner from '../../cargacomponetes/CargandoSpinner';
import { API_URL } from '../../../constants/constants';
// import { generateVoucherOxxo, payWithOxxo } from '../../../utils/PixelMeta/metaPixelEvents';
import { SnipPaymentMethods } from '../../../utils/Analitycs/analytics';
import ModalErrorTransfer from '../modales/ModalErrorTransfer';
import { addPaymentInfo } from '../../../utils/PixelMeta/metaPixelEvents';
import { getRandomInt } from '../../../utils/utils';


function OxxoForm() {

  const [pagoCompletado, setPagoCompletado] = useState(false); // Nuevo estado para controlar la visibilidad del botón
  const [instructionsPayment, setInstructions] = useState(false)
  const [showModal, setShowModal] = useState(false); // Estado para controlar la visibilidad del modal
  const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

  let navigate = useNavigate();


  const location = useLocation();
  const user_data = location.state || {};

  const [cargando, setCargando] = useState(false);
  const [quantityProducts, setQuantityProducts] = useState(0)

  const { token, id, datospadso } = location.state || {};
   console.log('Paso el nuemro ', datospadso)
  const estadoInicial = {
    cliente: '',
    email: '',

  };

  const [formulario, setFormulario] = useState(estadoInicial);



  useEffect(() => {

    let event_id = getRandomInt()

    const fetchData = async () => {
      const pedido = await getPedidos(datosPerfil['$udi09345'], id);
      // Función para verificar si el link_pago está desactualizado (más de 24 horas)
      const isLinkOutdated = (updatedAt) => {
        const updatedDate = new Date(updatedAt);
        const now = new Date();
        const timeDiff = now - updatedDate;
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        return hoursDiff > 72;
      };
      if (!pedido.link_pago || pedido.link_pago.includes('linkdelete')|| pedido.link_pago.includes('https://u.mitec.com.mx/') || pedido.link_pago.includes('https://payments.stripe.com/bank_transfers/') || isLinkOutdated(pedido.updated_at)) {
        //  console.log('link_pago no encontrado, llamando a handleSubmit()');

        handleSubmit(event_id)
      }
      else {
      //  console.log('link_pago encontrado:', pedido.link_pago);
        setInstructions(pedido.link_pago);
      }
    };

    fetchData();
    // -------- Pixel Meta ---------- //
    addPaymentInfo(
      datospadso?.datos_orden?.email,
      datospadso?.datos_orden?.total,

      // Data
      datospadso,
      event_id
    )
  }, []);


  const getPedidos = async (idusuari, idpedido) => {
    try {
      const config = {
        headers: {
          'Authorization': datosPerfil['$tk3m09s']
        },
      };

      let response = await axios.get(`${API_URL}/getOrdersByUser?user_id=${idusuari}&order_id=${idpedido}`, config);
      //    console.log('-------dasd', response.data.success);
      return response.data.success;
    } catch (error) {
      console.error('Error al obtener EL PEDIDO: ' + error.toString());

      if (error.response.data.error === 'refresh') {
        localStorage.removeItem('k-9SMD879na89dN87m');
        navigate('/');
        return;
      }
    }
  };

  useEffect(() => {
    if (datospadso?.datos_orden) {
      // console.log('fksaflkasjf',datospadso)
      setFormulario({ cliente: datospadso?.datos_orden?.cliente, email: datospadso?.datos_orden?.email });
    } else {
      setFormulario(estadoInicial);
    }

    // ------------------------------ //

    const aux = datospadso?.carrito_usuario.map((value) => {
      // console.log(value?.cantidad)
      setQuantityProducts(prevValue => prevValue + value?.cantidad)
    })


  }, [user_data]);




  const handleSubmit = async (event_id) => {

    let order_id;

    if (id) {
      // Si `id` tiene valor, usar ese valor directamente
      order_id = id;
      // console.log('Usando order_id existente: ', order_id);
    } else {

      // const order_id = await createOrder()
      const body = {
        "id_usuario": datospadso?.id_usuario,
        "datos_orden": datospadso?.datos_orden,
        "carrito_usuario": datospadso?.carrito_usuario,
        "direccion_usuario": datospadso?.direccion_usuario
      }
      // console.log(body)
      //Crear pedido y obtener id_pedido para enviarlo en los metadatos del pago
      const responseOrder = await axios.post(API_URL + '/createOrder', datospadso)


      console.log('---Response order: ', responseOrder)
      order_id = await responseOrder?.data?.status

      // console.log('El id del pedido es: ', order_id)

    }

    setCargando(true);
    const payload = {
      "amount": datospadso?.datos_orden?.total.toFixed(2),
      "user_name": datospadso?.datos_orden?.cliente,
      "email": datospadso?.datos_orden?.email,
      "id_pedido": order_id,
      "id_odoo": datospadso?.sale_order_id,
      "invoice_is_required": datospadso?.factura,
      "event_id": event_id
    }

    //console.log('Payload: ', payload)
    try {
      const { data } = await axios.post(API_URL + '/createPaymentIntentOxxo', payload);
      console.log('Respuesta data:', data)
      const { clientSecret, id_pedido, instructions } = data;
      saveLink(instructions)
      setInstructions(instructions);

      setCargando(false);
    } catch (error) {
      console.error('Error al crear la intención de pago: ', error);
      setCargando(false);
      setShowModal(true); // Mostrar el modal en caso de error

      return; // Termina la ejecución si ocurre un error al crear la intención de pago
    }


    // -------- Pixel Meta ---------- //
    // payWithOxxo(
    //   datospadso?.datos_orden?.email,
    //   datospadso?.datos_orden?.total,

    //   // Data
    //   datospadso
    // )

    // ------------------------------ //

    // -- Snip -- //
    SnipPaymentMethods('oxxo')

    setPagoCompletado(true); // Actualizamos el estado para mostrar el botón

  };


  //guardar link 
  const saveLink = async (link) => {

    try {

      const config = {
        headers: {
          'Authorization': datosPerfil['$tk3m09s']
        }
      };

      const formdata = {
        no_pedido: id,
        link_pago: link
      }

      //   console.log('El form data para el link es ', formdata)

      const response = await axios.put(API_URL + '/updateOrder', formdata, config);
      //console.log('se guardo tu ', response)


    } catch (error) {
      console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
    }
  }




  const gopedidodetale = () => {
    navigate('/Pedidos');
  }

  return (
    <div >

      {cargando && (
        <CargandoSpinner txtcharge='Generando Recibo de pago Oxxo' />

      )}

      {showModal && <ModalErrorTransfer onClose={() => setShowModal(false)} />}
      <HeaderEspecial butonpedios={true} />
      {/* <div className='flex justify-center mt-4 font-medium font-robotoch'>
        <p>Confirma tu compra</p>

      </div> */}

      <div className='text-center mt-5 mb-5 lg:w-4/5 mx-auto lg:text-left lg:flex'>
        <h2 className='text-[#1e3d8b] font-black text-lg lg:text-2xl'>CONTINÚA CON TU</h2>
        <h1 className='text-[#030303] font-black text-xl lg:text-2xl lg:ml-2'>PAGO</h1>
      </div>

      <div className="lg:flex lg:w-4/5 mx-auto lg:mb-10 mb-24">
        {instructionsPayment && (
          <div className="w-full lg:w-4/5 xl:w-12/12 mx-auto flex justify-center items-center h-full">
            <iframe
              src={instructionsPayment}
              frameBorder="0"
              scrolling="no"
              seamless="seamless"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full rounded"
              height={'700px'}
              title='frameoxxopay'
            ></iframe>
          </div>
        )}
        <Resumnpagoslist listaCar={datospadso?.carrito_usuario} />
      </div>
      <div className='lg:hidden h-20 mb-20'>

      </div>
      <div className='fixed ml-28 lg:ml-0 bottom-0 left-1/4 transform -translate-x-1/2 lg:translate-x-0 w-full lg:w-1/2 bg-white border-t-2 lg:border-l-2 lg:border-r-2 border-gray-300 p-4 mx-auto'>



        <div className='flex justify-between items-center w-full'>
          <span className='font-bold text-base lg:mr-2 text-[#757575]'>Total de Productos:</span>
          <span className='font-bold text-base lg:mr-2 text-[#757575]'>${datospadso.datos_orden.total_productos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN</span>
        </div>
        <div className='flex justify-between items-center w-full'>
          <span className='font-bold text-base lg:mr-2 text-[#757575] '>Costo de envío:</span>
          <span className='font-bold text-base lg:mr-2 text-[#757575] '>${datospadso.datos_orden.total_envio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN</span>
        </div>
        <div className='flex justify-between items-center w-full mt-1 py-1 border-t-2 border-gray-300'>
          <span className='font-bold text-xl lg:mr-2 text-[#474747]'>Total:</span>
          <span className='font-bold text-xl lg:mr-2 text-[#474747]'>
            ${datospadso.datos_orden.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            MXN
          </span>
        </div>




        <div className='flex justify-center mt-5'>
          <button
            name='btn-gorodersoxxo'
            id='btn-gorodersoxxo'
            className='bg-[#e9bf56] px-5 py-1  w-80 rounded-md' onClick={gopedidodetale}>
            Ir a pedidos
          </button>
        </div>


      </div>
    </div>

  );
}

export default OxxoForm;
