import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Headers/Header';
import Footer from '../../componentes/footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import imgheader from '../../assets/imgpruabconcones.webp';
import Conocenosn1 from '../../assets/Conocenosn1.webp';
import Conocenos1 from '../../assets/Conocenos1.webp';
import Conocenos2 from '../../assets/Conocenos2.webp';
import Conocenos3 from '../../assets/filosofia.png';

import Columnas from '../../componentes/columnas/Columnas';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBeforeUnload } from '../../utils/Analitycs/analytics';


const secciones = [
    {
        id: 1,
        titulo: "MISIÓN",
        contenido: "Facilitar a nuestros clientes innovación y tecnología a través de nuestras familias de productos para centros de servicios y talleres automotrices.",
        imagen: Conocenos1
    },
    {
        id: 2,
        titulo: "VISIÓN",
        contenido: "Ser la empresa líder en innovación automotriz a nivel mundial con la mejor red de soporte técnico.",
        imagen: Conocenos2
    },
    {
        id: 3,
        titulo: "FILOSOFÍA",
        contenido: "Contribuir con nuestros cliente y distribuidores en los estándares de optimización para incrementar sus ingresos bajo la ideología “HACERLO POSIBLE”.",
        imagen: Conocenos3
    }
];

const seccion = secciones.find(s => s.id === 1);
const seccionvision = secciones.find(s => s.id === 2);

function Conocenos() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 1000 // Duración de la animación en milisegundos
        });
    }, []);

    const [isHovered, setIsHovered] = useState(false);

    // El usuario ha salido de la página
    const location = useLocation();
    const fullPath = location.pathname;
    const routeName = fullPath.split('/')[1];
    useBeforeUnload(routeName);

    const goAllProducts = () => {
        navigate('/allProducts'); // Asegúrate de tener esta ruta configurada para mostrar todas las categorías
    };

    return (
        <div className='bg-white overflow-x-hidden'>
            <Header />
            <div className='px-1 overflow-x-hidden'>
                <div className='relative w-full min-h-52 lg:min-h-[85vh] mx-auto flex flex-col justify-center items-center overflow-hidden' data-aos="fade-up">
                    <div className='absolute inset-0 bg-black bg-opacity-35 z-10' style={{ filter: 'drop-shadow(0 0 10px black)' }}></div>
                    <div className='w-full h-full absolute z-0' style={{ backgroundImage: `url(${imgheader})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                    <div className='z-10 p-4 text-center lg:w-4/5 '>
                        <h2 className='font-robotoch text-lg lg:text-4xl text-white lg:font-bold'>Hantec, es una empresa  dedicada a la comercialización de Herramientas automotrices de alta calidad.</h2>
                        <button
                            name='btn-goAllProducts'
                            id='btn-goAllProducts' onClick={goAllProducts} className='font-montserrat text-white bg-[#e9bf56] p-2 w-3/4 lg:w-1/4 lg:mt-8 text-base lg:text-base font-black rounded mt-2 transition-colors duration-300 hover:bg-[#1e3d8b]'>
                            PRODUCTOS
                        </button>
                    </div>
                </div>

                <div className="services-section w-full px-4 lg:px-0">
                    <p className="font-medium text-white text-center md:text-left">Redes Sociales</p>
                    <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-4">
                        <a href="https://www.facebook.com/InnovacionHantec/" className="transition hover:opacity-75 bg-blue-700 py-2 px-4 rounded text-center" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook-square text-white text-5xl md:text-3xl"></i>
                            <span className="block text-sm text-white mt-2"> Facebook</span>
                        </a>
                        <a href="https://www.instagram.com/hantec.herramientas/" className="transition hover:opacity-75 bg-gradient-to-r from-pink-500 to-yellow-500 py-2 px-4 rounded text-center" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram text-white text-5xl md:text-3xl"></i>
                            <span className="block text-sm  text-white mt-2"> Instagram</span>
                        </a>
                        <a href="https://youtube.com/@hantecinnovacionautomotriz7670?feature=shared" className="transition hover:opacity-75 bg-red-600 py-2 px-4 rounded text-center" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-youtube text-white text-5xl md:text-3xl"></i>
                            <span className="block text-sm  text-white mt-2"> YouTube</span>
                        </a>
                        <a href="https://www.tiktok.com/@hantec_innovacion?lang=es" className="transition hover:opacity-75 bg-black py-2 px-4 rounded text-center" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-tiktok text-white text-4xl md:text-3xl"></i>
                            <span className="block text-sm  text-white mt-4"> TikTok</span>
                        </a>
                    </div>
                </div>

                <Columnas />

                <section data-aos="fade-in" className=' w-4/5  lg:mt-20 mx-auto'>
                    <div className='text-center lg:mt-20 mt-4 lg:mb-10 flex flex-col justify-center items-center lg:w-4/5 mx-auto'>
                        <h2 className='text-[#1e3d8b] font-black uppercase font-montserrat text-2xl lg:text-4xl'>
                            FILOSOFÍA
                        </h2>
                        <img width="300" height="200"
                            loading="lazy"
                            className='my-10 w-4/5 lg:hidden' src={Conocenosn1} alt="Filosofía" />
                        <div className='lg:w-2/3 mx-auto'>
                            <p className='text-sm lg:text-2xl mt-2'>
                                Contribuir con nuestros cliente y distribuidores en los estándares de optimización para incrementar sus ingresos bajo la ideología <br />“HACERLO POSIBLE”.
                            </p>
                        </div>
                    </div>
                </section>

                <div
                    className='lg:w-4/5 mx-auto mt-10 mb:20 relative hidden lg:block'
                    style={{ height: '500px' }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <img width="300" height="200"
                        loading="lazy"
                        className={`absolute top-0 transition-all h-auto duration-1000 ease-in-out w-full object-cover ${isHovered ? 'left-[0%] brightness-75' : 'left-[98%]'}`}
                        src={Conocenosn1}
                        alt="Conocenos"
                    />
                </div>

                <section className="body-font w-4/5 mx-auto lg:mt-10  " data-aos="fade-right">
                    <div className="container mx-auto flex px-5 py-5 md:flex-row flex-col items-center">
                        <div className="lg:max-w-lg lg:w-1/2 w-full mb-10 md:mb-0">
                            <img width="300" height="200"
                                loading="lazy"
                                className="object-cover w-full object-center rounded" alt="Misión" src={seccion.imagen} />
                        </div>
                        <div className="mx-auto md:w-1/2 lg:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-black font-montserrat text-[#171717]">{seccion.titulo}</h1>
                            <div className='lg:w-2/3'>
                                <p className="mb-8 leading-relaxed text-[#030303] font-medium font-montserrat">{seccion.contenido}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="body-font w-4/5 mx-auto lg:mt-20 " data-aos="fade-left">
                    <div className="container mx-auto flex px-5 py-5 md:flex-row flex-col items-center">
                        <div className="lg:max-w-lg lg:w-1/2 w-full mb-10 md:mb-0 lg:hidden">
                            <img width="300" height="200"
                                loading="lazy"
                                className="object-cover object-center rounded" alt="Visión" src={seccionvision.imagen} />
                        </div>
                        <div className="mx-auto md:w-1/2 lg:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-black font-montserrat text-[#171717]">{seccionvision.titulo}</h1>
                            <div className='lg:w-2/3'>
                                <p className="mb-8 leading-relaxed text-[#030303] font-medium font-montserrat">{seccionvision.contenido}</p>
                            </div>
                        </div>
                        <div className="lg:max-w-lg lg:w-1/2 w-full mb-10 md:mb-0 hidden lg:block">
                            <img width="300" height="200"
                                loading="lazy"
                                className="object-cover object-center rounded" alt="Visión" src={seccionvision.imagen} />
                        </div>
                    </div>
                </section>

            </div>

            <Footer />
        </div>
    );
}

export default Conocenos;
