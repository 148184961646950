import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../componentes/footer/Footer';
import Header from '../../componentes/Headers/Header';

function Sooprte() {

    useEffect(() => {
        // Forzar la re-renderización del Slider una vez que los productos están listos
        window.scrollTo(0, 0);
    }, []); // Dependencia a 'productos' para actualizar cuando cambie


    return (

        <div >

            <Header />
            <div className=' lg:w-5/6 mx-auto flex-grow'> {/* flex-grow permite que este div ocupe el espacio disponible */}

                <div className="lg:w-1/2 w-full mb-6 lg:mb-0 pl-5 mt-5">
                    <div>
                        <h2 className='font-oswald font-semibold text-2xl text-[#1e3d8b]'>
                            Soporte
                        </h2>

                    </div>
                    <div className="h-1 w-40 bg-[#152a6e] rounded"></div>
                </div>

                <section>

                    <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-8">

                        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">

                            <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
                            

                            </div>

                            <div className="lg:py-16">
                                <article className="space-y-4 text-gray-600 ">
                                    <p className="text-justify">
                                        En Hantec, nos enorgullece brindar un servicio de soporte técnico insuperable, 
                                        diseñado específicamente para satisfacer todas tus necesidades y garantizar el máximo rendimiento de tus productos. 
                                        Nuestro equipo de especialistas, dotado de una vasta experiencia y conocimientos técnicos profundos, 
                                        está a tu disposición para resolver eficazmente cualquier duda o desafío que puedas encontrar.
                                    </p>

                                    <p className="text-justify">
                                        Para una asistencia personalizada e inmediata, 
                                        te invitamos a comunicarte con nosotros a través del número 5544408376 o al 3324510093 Ya sea por llamada telefónica o mensaje, 
                                        estamos preparados para ofrecerte soluciones concretas y adaptadas a tus requerimientos específicos.
                                    </p>
                                    <p className="text-justify">
                                        En Hantec, tu tranquilidad y satisfacción son nuestra prioridad. 
                                        Permítenos acompañarte en cada paso del camino, 
                                        asegurando una experiencia sin contratiempos con tus productos.
                                    </p>
                                </article>
                            </div>
                        </div>

                    </div>
                    
                </section>


            </div>
            <Footer />
        </div>

    );
}

export default Sooprte;
