import React, { useState, useEffect } from 'react';
import InputDos from '../inputs/Inputo2';
import axios from 'axios';
import { API_URL, customStyles } from '../../constants/constants';

// import { selectedInvoice, selectedNoInvoice } from '../../utils/PixelMeta/metaPixelEvents';
import Select from 'react-select';
import CargandoSpinner from '../cargacomponetes/CargandoSpinner';


function PaginaDos({ datosPerfilenv, habilartedir, cancel2, dualbutons, requierefactura, nofacturar, pasoapay }) {
    const datosPerfil = datosPerfilenv || JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) || null;
    const [cargando, setCargando] = useState(false);
    const [estaEditando, setEstaEditando] = useState(habilartedir || false);

    const [estados, setEstados] = useState([]);
    const [colonias, setColonias] = useState([]);
    const [isColoniaSelectDisabled, setIsColoniaSelectDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const emptyFormData = {
        user_id: datosPerfil?.['$udi09345'] || '',
        partner_id: datosPerfil?.['$od'] || '',
        update_vals: {
            name: '',
            vat: '',
            l10n_mx_edi_fiscal_regime: '',
            company_type: 'person',
            zip: '',
            street_name: '',
            street_number: '',
            street_number2: '',
            l10n_mx_edi_colony: '',
            city: '',
            country_id: 156,
            state_id: '',
            code_usage: ''
        }
    };

    const [formData, setFormData] = useState(emptyFormData);
    const [opcionFacturacion, setOpcionFacturacion] = useState('principal');
    const [direccionNueva, setDireccionNueva] = useState('');

    const opcionesRegimenFiscal = [
        { value: '601', label: 'General de Ley Personas Morales' },
        { value: '603', label: 'Personas Morales con Fines no Lucrativos' },
        { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
        { value: '606', label: 'Arrendamiento' },
        { value: '607', label: 'Régimen de Enajenación o Adquisición de Bienes' },
        { value: '608', label: 'Demás ingresos' },
        { value: '609', label: 'Consolidación' },
        { value: '610', label: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
        { value: '611', label: 'Ingresos por Dividendos (socios y accionistas)' },
        { value: '612', label: 'Personas Físicas con Actividades Empresariales y Profesionales' },
        { value: '614', label: 'Ingresos por intereses' },
        { value: '615', label: 'Régimen de los ingresos por obtención de premios' },
        { value: '616', label: 'Sin obligaciones fiscales' },
        { value: '620', label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
        { value: '621', label: 'Incorporación Fiscal' },
        { value: '622', label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
        { value: '623', label: 'Opcional para Grupos de Sociedades' },
        { value: '624', label: 'Coordinados' },
        { value: '625', label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
        { value: '626', label: 'Régimen Simplificado de Confianza - RESICO' },
        { value: '628', label: 'Hidrocarburos' },
        { value: '629', label: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
        { value: '630', label: 'Enajenación de acciones en bolsa de valores' },
    ];

    const opcionesPersonaFisica = [
        { value: 'G01', label: 'Adquisición de mercancías.' },
        { value: 'G02', label: 'Devoluciones, descuentos o bonificaciones.' },
        { value: 'G03', label: 'Gastos en general.' },
        { value: 'I01', label: 'Construcciones.' },
        { value: 'I02', label: 'Mobiliario y equipo de oficina por inversiones.' },
        { value: 'I03', label: 'Equipo de transporte.' },
        { value: 'I04', label: 'Equipo de computo y accesorios.' },
        { value: 'I05', label: 'Dados, troqueles, moldes, matrices y herramental.' },
        { value: 'I06', label: 'Comunicaciones telefónicas.' },
        { value: 'I07', label: 'Comunicaciones satelitales.' },
        { value: 'I08', label: 'Otra maquinaria y equipo.' },
        { value: 'S01', label: 'Sin efectos fiscales.' },
        { value: 'CP01', label: 'Pagos' }
    ];

    const opcionesEmpresa = [
        { value: 'G01', label: 'Adquisición de mercancías.' },
        { value: 'G02', label: 'Devoluciones, descuentos o bonificaciones.' },
        { value: 'G03', label: 'Gastos en general.' },
        { value: 'I01', label: 'Construcciones.' },
        { value: 'I02', label: 'Mobiliario y equipo de oficina por inversiones.' },
        { value: 'I03', label: 'Equipo de transporte.' },
        { value: 'I04', label: 'Equipo de computo y accesorios.' },
        { value: 'I05', label: 'Dados, troqueles, moldes, matrices y herramental.' },
        { value: 'I06', label: 'Comunicaciones telefónicas.' },
        { value: 'I07', label: 'Comunicaciones satelitales.' },
        { value: 'I08', label: 'Otra maquinaria y equipo.' },
        { value: 'D01', label: 'Honorarios médicos, dentales y gastos hospitalarios.' },
        { value: 'D02', label: 'Gastos médicos por incapacidad o discapacidad.' },
        { value: 'D03', label: 'Gastos funerales.' },
        { value: 'D04', label: 'Donativos.' },
        { value: 'D05', label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
        { value: 'D06', label: 'Aportaciones voluntarias al SAR.' },
        { value: 'D07', label: 'Primas por seguros de gastos médicos.' },
        { value: 'D08', label: 'Gastos de transportación escolar obligatoria.' },
        { value: 'D09', label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
        { value: 'D10', label: 'Pagos por servicios educativos (colegiaturas).' },
        { value: 'S01', label: 'Sin efectos fiscales.' },
        { value: 'CP01', label: 'Pagos' },
        { value: 'CN01', label: 'Nómina' }
    ];

    const handleRegimenFiscalChange = selectedOption => {
        setFormData(prevFormData => ({
            ...prevFormData,
            update_vals: {
                ...prevFormData.update_vals,
                l10n_mx_edi_fiscal_regime: selectedOption.value
            }
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            update_vals: {
                ...prevState.update_vals,
                [name]: value
            }
        }));
    };

    const handleColoniaChange = (selectedOption) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            update_vals: {
                ...prevFormData.update_vals,
                l10n_mx_edi_colony: selectedOption.value
            }
        }));
    };

    const handleCFDIChange = selectedOption => {
        setFormData(prevFormData => ({
            ...prevFormData,
            update_vals: {
                ...prevFormData.update_vals,
                code_usage: selectedOption.value
            }
        }));
    };

    const handleSubmit = () => {
        const tk = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
        // selectedInvoice(tk ? tk['$email'] : 'No registrado');
        onEnviarDatos(formData);
    };

    useEffect(() => {
        EstadoConodoo();
        dataInvoiceById();
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (formData.update_vals.zip) {
                handlePostalCodeChange(formData.update_vals.zip);
                setIsColoniaSelectDisabled(false);
            } else {
                setColonias([]);
                setIsColoniaSelectDisabled(true);
            }
        }, 500);
        return () => clearTimeout(handler);
    }, [formData.update_vals.zip]);

    const EstadoConodoo = async () => {
        try {
            const response = await axios.get(API_URL + '/getStatesMexico');
            setEstados(response.data.success);
        } catch (error) {
            console.error("Error al obtener los estados: ", error);
        }
    };

    const dataInvoiceById = async () => {
        const formDataSat = { user_id: datosPerfil?.['$udi09345'] };
        try {
            const config = {
                headers: { 'Authorization': datosPerfil?.['$tk3m09s'] },
                timeout: 10000
            };
            const response = await axios.post(API_URL + "/getDataInvoiceById", formDataSat, config);
            setFormData(prevFormData => {
                const data = response.data.success;
                return {
                    ...prevFormData,
                    update_vals: {
                        ...prevFormData.update_vals,
                        name: data?.name ?? prevFormData.update_vals.name,
                        vat: data?.vat ?? prevFormData.update_vals.vat,
                        l10n_mx_edi_fiscal_regime: data?.l10n_mx_edi_fiscal_regime ?? prevFormData.update_vals.l10n_mx_edi_fiscal_regime,
                        company_type: data?.company_type ?? prevFormData.update_vals.company_type,
                        zip: data?.zip ?? prevFormData.update_vals.zip,
                        street_name: data?.street_name ?? prevFormData.update_vals.street_name,
                        street_number: data?.street_number ?? prevFormData.update_vals.street_number,
                        street_number2: data?.street_number2 ?? prevFormData.update_vals.street_number2,
                        l10n_mx_edi_colony: data?.l10n_mx_edi_colony ?? prevFormData.update_vals.l10n_mx_edi_colony,
                        city: data?.city ?? prevFormData.update_vals.city,
                        country_id: data?.country_id ?? prevFormData.update_vals.country_id,
                        state_id: data?.state_id ?? prevFormData.update_vals.state_id,
                        code_usage: data?.code_usage ?? prevFormData.update_vals.code_usage
                    }
                };
            });
        } catch (error) {
            console.error("Error al obtener los datos: ", error);
        }
    };

    const handleSubmitNoInvoice = () => {
        const tk = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
        // selectedNoInvoice(tk ? tk['$email'] : 'No registrado');
        cleanData();
        onEnviarDatos(formData);
    };

    const handlePostalCodeChange = async (nuevoCodigoPostal) => {
        const formDataCp = { cp: nuevoCodigoPostal };
        try {
            const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getDistricts', formDataCp, { timeout: 10000 });
            if (response.data && Array.isArray(response.data.data)) {
                setColonias(response.data.data);
                setIsColoniaSelectDisabled(false);
            } else {
                setFormData({ ...formData, l10n_mx_edi_colony: '' });
                setColonias([]);
                setIsColoniaSelectDisabled(true);
            }
        } catch (error) {
            console.error("Error al obtener las colonias:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }
            setColonias([]);
            setIsColoniaSelectDisabled(true);
        }
    };

    const selectOcolonias = colonias.map(colonia => ({
        value: `${colonia.text}`,
        label: `${colonia.text}`
    }));

    const handleEstadoChange = (selectedOption) => {
        const valorComoEntero = parseInt(selectedOption.value, 10);
        setFormData(prevFormData => ({
            ...prevFormData,
            update_vals: {
                ...prevFormData.update_vals,
                state_id: valorComoEntero
            }
        }));
    };

    const selectEstado = estados.map(estado => ({
        value: estado.id,
        label: estado.name
    }));

    const sendDatos = async () => {
        setLoading(true)
        try {
            const config = {
                headers: { 'Authorization': datosPerfil['$tk3m09s'] },
                timeout: 10000
            };
            formData["user_id"] = datosPerfil['$udi09345']
            await axios.post(API_URL + "/setDataInvoice", formData, config);
            if (pasoapay) {
                requierefactura(formData.update_vals);
            }
            setLoading(false);

        } catch (error) {
            console.error('Error al enviar los datos: ', error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }
        } finally {
            setLoading(false);
        }
    };

    const validarFormularioCompleto = () => {
        const { update_vals } = formData;
        const esValido = (
            update_vals.name &&
            update_vals.vat &&
            update_vals.l10n_mx_edi_fiscal_regime &&
            update_vals.company_type &&
            update_vals.zip &&
            update_vals.street_name &&
            update_vals.street_number &&
            update_vals.l10n_mx_edi_colony &&
            update_vals.city &&
            update_vals.country_id &&
            update_vals.state_id &&
            update_vals.code_usage
        );
        // console.log('Validación del formulario:', esValido);
        // console.log('Datos del formulario:', formData.update_vals);
        return esValido;
    };

    useEffect(() => {
        const esFormularioValido = validarFormularioCompleto();
        setCargando(!esFormularioValido);
    }, [formData]);


    const handleCancelar = () => {
        if (cancel2) {
            cancel2();
        } else {
            setEstaEditando(false);
        }
    };


    return (
        <div className='mx-auto'>
            {loading && <CargandoSpinner txtcharge="Guardando datos de facturación" />}

            <div className='text-center mt-2 lg:text-left lg:flex font-robotoch'>
                <h2 className='text-[#1e3d8b] font-black text-lg lg:text-2xl'>DATOS DE</h2>
                <h1 className='text-[#030303] font-black text-xl lg:text-2xl lg:ml-2'>FACTURACIÓN</h1>
            </div>
            <div className="w-full flex flex-col items-center justify-center mx-auto font-robotoch">
                <div className='lg:flex lg:justify-center text-left items-center lg:w-full w-full px-5 mx-auto lg:px-0'>
                    <InputDos
                        disabled={!estaEditando}
                        label="Nombre Completo "
                        name="name"
                        value={formData.update_vals.name}
                        placeholder="Nombre completo"
                        onChange={handleChange}
                    />
                    <InputDos
                        disabled={!estaEditando}
                        label="RFC"
                        name="vat"
                        value={formData.update_vals.vat}
                        placeholder="RFC"
                        onChange={handleChange}
                    />
                    <div className="mt-4 w-full lg:mt-7 lg:w-1/3">
                        <p className='text-sm lg:text-sm mb-2'>Regimen fiscal</p>
                        <Select
                            isDisabled={!estaEditando}
                            options={opcionesRegimenFiscal}
                            value={opcionesRegimenFiscal.find(option => option.value === formData.update_vals.l10n_mx_edi_fiscal_regime)}
                            onChange={handleRegimenFiscalChange}
                            placeholder="Selecciona..."
                            styles={customStyles}
                        />
                    </div>

                </div>

                <div className="w-full flex flex-col lg:flex-row lg:items-center justify-center mx-auto font-robotoch">

                    <div className="w-full mt-4 lg:mt-2 lg:w-2/2 lg:px-5">
                        <p className='text-sm lg:text-sm'>Cliente</p>
                        <label className="inline-flex items-center mt-2 ml-2">
                            <input
                                disabled={!estaEditando}
                                type="radio"
                                className="form-radio h-5 w-5 text-gray-600"
                                name="company_type"
                                value="person"
                                checked={formData.update_vals.company_type === 'person'}
                                onChange={handleChange}
                            />
                            <span className="ml-2 text-gray-700">Estoy comprando como Persona Fisica</span>
                        </label>
                        <label className="inline-flex items-center mt-2 ml-2">
                            <input
                                disabled={!estaEditando}
                                type="radio"
                                className="form-radio h-5 w-5 text-gray-600"
                                name="company_type"
                                value="company"
                                checked={formData.update_vals.company_type === 'company'}
                                onChange={handleChange}
                            />
                            <span className="ml-2 text-gray-700">Estoy comprando como Empresa</span>
                        </label>
                    </div>

                    {formData.update_vals.company_type === 'person' && (
                        <div className='lg:flex lg:justify-left text-left items-center lg:w-full w-full mx-auto lg:px-0 lg:ml-5'>
                            <div className="mt-4 w-full lg:mt-0 lg:w-2/2">
                                <p className='text-sm lg:text-sm mb-1'>CFDI de Persona Física</p>
                                <Select
                                    isDisabled={!estaEditando}
                                    options={opcionesPersonaFisica}
                                    value={opcionesPersonaFisica.find(option => option.value === formData.update_vals.code_usage)}
                                    onChange={handleCFDIChange}
                                    placeholder="Selecciona..."
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    )}

                    {formData.update_vals.company_type === 'company' && (
                        <div className='lg:flex lg:justify-left text-left items-center lg:w-full w-full px-5 mx-auto lg:px-0 lg:ml-5'>
                            <div className="mt-4 w-full lg:mt-0 lg:w-3/3">
                                <p className='text-sm lg:text-sm mb-1'>CFDI de Empresa</p>
                                <Select
                                    isDisabled={!estaEditando}
                                    options={opcionesEmpresa}
                                    value={opcionesEmpresa.find(option => option.value === formData.update_vals.code_usage)}
                                    onChange={handleCFDIChange}
                                    placeholder="Selecciona..."
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex items-center mt-5">
                    <div className="flex-1 border-t border-2 border-black"></div>
                    <span className="px-4 text-lg text-[#030303] font-semibold">Dirección de facturación</span>
                    <div className="flex-1 border-t border-2 border-black"></div>
                </div>

                <div className='lg:flex lg:justify-center text-left items-center lg:w-full w-full px-5 mx-auto lg:px-0'>
                    <InputDos
                        disabled={!estaEditando}
                        label="C.P."
                        name="zip"
                        value={formData.update_vals.zip}
                        placeholder="Código Postal"
                        onChange={handleChange}
                    />
                    <div className="mt-4 w-full lg:mt-7 lg:w-1/3">
                        <p className='text-xs lg:text-sm mb-2'>Colonia</p>
                        <Select
                            isDisabled={isColoniaSelectDisabled || !estaEditando}
                            options={selectOcolonias}
                            value={selectOcolonias.find(option => option.value === formData.update_vals.l10n_mx_edi_colony)}
                            onChange={handleColoniaChange}
                            placeholder="Seleccione una Colonia"
                            menuPlacement="auto"
                            styles={customStyles}
                        />
                    </div>
                    <InputDos
                        label='Ciudad'
                        name="city"
                        type="text"
                        value={formData.update_vals.city}
                        onChange={handleChange}
                        placeholder="Ciudad"
                        disabled={isColoniaSelectDisabled || !estaEditando}
                    />
                </div>

                <div className='lg:flex lg:justify-center text-left items-center lg:w-full w-full px-5 mx-auto lg:px-2 mt-2'>
                    <div className="mt-4 w-full lg:mt-7 lg:w-1/3">
                        <p className='text-xs lg:text-sm mb-2'>Estado</p>
                        <Select
                            isDisabled={isColoniaSelectDisabled || !estaEditando}
                            options={selectEstado}
                            value={selectEstado.find(option => option.value === formData.update_vals.state_id)}
                            onChange={handleEstadoChange}
                            placeholder="Seleccione un estado"
                            menuPlacement="auto"
                            styles={customStyles}
                        />
                    </div>
                    <InputDos
                        disabled={!estaEditando}
                        label='Calle'
                        name="street_name"
                        type="text"
                        value={formData.update_vals.street_name}
                        onChange={handleChange}
                        placeholder="Calle"
                    />
                    <div className='flex space-x-4 lg:space-x-2'>
                        <InputDos
                            disabled={!estaEditando}
                            label='Num. Ext.'
                            name="street_number"
                            type="text"
                            value={formData.update_vals.street_number}
                            onChange={handleChange}
                            placeholder="Num. Ext."
                        />
                        <InputDos
                            disabled={!estaEditando}
                            label='Num. int.'
                            name="street_number2"
                            type="text"
                            value={formData.update_vals.street_number2}
                            onChange={handleChange}
                            placeholder="Num. Int."
                        />
                    </div>
                </div>

                {datosPerfil && datosPerfil['$r07sad'] !== 'Tienda' && (
                    <button


                        name='btn-editpgdos'
                        id='btn-editpgdos'
                        onClick={() => setEstaEditando(true)}
                        className={`py-2 px-5 rounded mt-4 ${estaEditando ? 'hidden' : 'bg-[#e9bf56] text-black'}`}
                    >
                        Editar
                    </button>
                )}

                {estaEditando && (
                    <div>
                        {dualbutons ? (
                            <div>
                                <button

                                    name='btn-nofactuapgdos'
                                    id='btn-nofactuapgdos'
                                    onClick={nofacturar}
                                    className="bg-[#e9bf56] text-black px-2 py-2 mt-5 text-lg rounded"
                                >
                                    No requiero factura
                                </button>
                                <button
                                    name='btn-savefactuapgdos'
                                    id='btn-savefactuapgdos'
                                    onClick={sendDatos}
                                    className={`bg-[#1a2869] text-white px-2 ml-2 py-2 mt-5 text-lg rounded ${cargando ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={cargando}
                                >
                                    Guardar para facturar
                                </button>
                            </div>
                        ) : (
                            <div>
                                <button
                                    name='btn-cancelpgdos'
                                    id='btn-cancelpgdos'
                                    onClick={handleCancelar}
                                    className="my-5 py-2 px-5 rounded bg-red-600 text-white"
                                >
                                    Cancelar
                                </button>
                                <button
                                    name='btn-saveepgdos'
                                    id='btn-saveepgdos'
                                    onClick={sendDatos}
                                    className={`my-5 py-2 px-5 ml-4 rounded bg-[#1a2869] text-white ${cargando ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={cargando}
                                >
                                    Guardar
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default PaginaDos;