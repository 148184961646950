import React from 'react';

function FormHeader({ title, description }) {
    return (
        <div className=' flex flex-col justify-center text-center mb-4'>
            <h2 className='font-black text-3xl'>{title}</h2>
            <p className='mt-4 text-xl'>{description}</p>
        </div>
    );
}

export default FormHeader;
