import React, { useState, useEffect } from 'react';

const ConsentimientoCookies = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consentimiento = localStorage.getItem('consentimientoCookies');
    if (!consentimiento) {
      setVisible(true);
    }
  }, []);

  const handleAceptar = () => {
    localStorage.setItem('consentimientoCookies', 'aceptado');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 z-50 text-white p-4">
      <p>Utilizamos cookies para mejorar tu experiencia. Al continuar navegando aceptas su uso.</p>
      <button
        name='btn-cookies'
        id='btn-cookies'
        onClick={handleAceptar} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Aceptar
      </button>
    </div>
  );
};

export default ConsentimientoCookies;
