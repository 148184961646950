import React, { useEffect, useState } from 'react';
import Approvedpayment from '../../assets/Aprobado1.webp';
import Paymentdeclined from '../../assets/paydeclined.webp';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// import { viewStatusPay } from '../../utils/PixelMeta/metaPixelEvents';
import { API_URL } from '../../constants/constants';
import axios from 'axios';
import { purchase } from '../../utils/PixelMeta/metaPixelEvents';


function Cargapago() {
    const location = useLocation();
    const [paymentResponse, setPaymentResponse] = useState(null);
    const tk = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;


    const gopedidos = () => {
        window.open('/Pedidos', '_blank');
        window.close();
    };

    const gopagos = (idPedido) => {
        window.open(`/otro-Metodo-de-Pago/${idPedido}`, '_blank');
        window.close();
    };

    const updateOrder = async (responsePago) => {
        const config = {
            headers: {
                'Authorization': tk['$tk3m09s']
            },
            timeout: 100000
        };

        const formdata = {
            'no_pedido': responsePago.referencia,
            'data_extra_order': responsePago
        };

        console.log('Datos enviados:', formdata);
        try {
            const response = await axios.put(API_URL + '/updateOrder', formdata, config);
            let email = tk['$email']
            if (responsePago.nbResponse === 'Aprobado') {
                // -------- Pixel Meta ---------- //
                purchase(
                    responsePago.importe,
                    response?.data?.content_ids,
                    email,
                    response?.data?.phone_number,
                    formdata?.no_pedido
                )
            }

            console.log('Order update response:', response.data);
        } catch (error) {
            console.error('Error:', error);


        };
    }

    useEffect(() => {
        // viewStatusPay(
        //     tk ? tk['$email'] : 'No registrado'
        // );

        const parsed = queryString.parse(location.search);

        const nb_error = parsed.nb_error;
        const nbResponse = parsed.nbResponse;
        const referencia = parsed.referencia;
        const nuAut = parsed.nuAut;
        const banco = parsed.banco;
        const marca = parsed.marca;
        const tpTdc = parsed.tpTdc;
        const importe = parsed.importe;

        const numbers = referencia.match(/\d+/g);
        const extractedNumber = numbers ? numbers.join('') : '';

        const responsePago = {
            nb_error,
            nbResponse,
            referencia: extractedNumber,
            nuAut,
            banco,
            marca,
            tpTdc,
            importe
        };

        setPaymentResponse(responsePago);
        updateOrder(responsePago);
    }, [location.search]);

    if (!paymentResponse) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            {paymentResponse.nbResponse === 'Rechazado' ? (
                <div>
                    <div className="flex justify-center items-center">
                        <img width="300" height="200"
                            className='w-48 h-auto' src={Paymentdeclined} alt="Pago Rechazado" />
                    </div>
                    <div className="flex flex-col items-center text-center justify-center mt-5">
                        <h2 className='text-red-500 font-black text-3xl'>Pago Rechazado</h2>
                        <h2 className='text-red-500 font-black text-xl mt-2'>
                            {paymentResponse.nb_error ? paymentResponse.nb_error : 'Pago rechazado por falta de fondos'}
                        </h2>
                        <button

                            name='btn-otromedotod'
                            id='btn-otromedotod'
                            onClick={() => gopagos(paymentResponse.referencia)} className="bg-[#e9bf56] text-black font-bold py-2 px-4 rounded mt-5">Probar otro método de pago</button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex justify-center items-center">
                        <img width="300" height="200"
                            className='w-48 h-auto' src={Approvedpayment} alt="Compra Aprobada" />
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5">
                        <h2 className='text-[#1e3d8b] font-black text-3xl'>TU COMPRA</h2>
                        <h2 className='text-[#1e3d8b] font-black text-4xl mt-2'>ESTÁ LISTA</h2>
                        <button onClick={gopedidos} className="bg-[#e9bf56] text-black font-bold py-2 px-4 rounded mt-5" 
                        
                        name='btn-gopedidos'
                        id='btn-gopedidos'
                        >IR A PEDIDOS</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Cargapago;
