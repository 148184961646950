import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useProductStore } from '../../store/bookStore';
import { API_URL } from '../../constants/constants';
import { SnipClicksCategories } from '../../utils/Analitycs/analytics';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ScrollableCategories = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [dataCategories, setDataCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(API_URL + '/getAllCategories', {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: 10000
        });
        const activeCategories = response.data.filter(category => category.activo === true);
        const indexDesmontadora = activeCategories.findIndex(categoria => categoria.nombre === 'Desmontadora');
        // Verifica si se encontró el objeto
        if (indexDesmontadora !== -1) {
          // Remueve el objeto de su posición actual
          const [desmontadora] = activeCategories.splice(indexDesmontadora, 1);

          // Inserta el objeto en la posición deseada (índice 2)
          activeCategories.splice(2, 0, desmontadora);
        }

     

        console.log('las categorias son ', activeCategories)
        setDataCategories(activeCategories);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al obtener las categorías:", error);
        if (error.code === 'ECONNABORTED') {
          console.log('Tiempo de espera agotado en categorías slider');
        }
        setIsLoading(false);
      }
    };

    getCategories();
  }, []);

  const { fetchProducts } = useProductStore();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth <= 768 ? 5 : 8,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
    ],
    afterChange: () => {
      // Ajustar el atributo tabIndex de los botones dentro de elementos clónicos
      document.querySelectorAll('.slick-cloned button').forEach(button => {
        button.setAttribute('tabIndex', '-1');
      });
    }
  };

  const goCategories = (categoryId) => {
    fetchProducts(categoryId);
    SnipClicksCategories();
    navigate(`/Categorias/${categoryId}`);
  };

  const viewAllCategories = () => {
    navigate('/NuestrasCategorias');
  };

  return (
    <div className="mt-5 lg:w-4/5 mx-auto w-full pt-2">
      {isLoading ? (
        <div className='lg:flex lg:w-4/5 px-5 w-full mx-auto lg:mt-10'>
          <div className='lg:w-full lg:h-[100px]'>
            <div className="border border-blue-700 shadow rounded-md p-4 w-full h-full mx-auto">
              <div className="animate-pulse flex">
                <div className="rounded-md bg-slate-700 h-10 lg:h-16 w-full"></div>
                <div className="flex-1 space-y-6 py-1 h-full">
                  <div className="h-full bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          {dataCategories.map((category, index) => (
            <div key={index} className="cursor-pointer flex flex-col justify-center text-center items-center">
              <button onClick={() => goCategories(category?.nombre)} className="flex w-full justify-center">
                <span className="sr-only">boton categoria</span>
                <div className="bg-[#EAEBF1] rounded-full mt-2 w-13 h-13 flex justify-center items-center md:w-20 md:h-20 lg:w-24 lg:h-24">
                  <LazyLoadImage
                    src={category?.imagen}
                    alt={category?.nombre}
                    className="w-12 h-12 object-contain rounded-full transition-transform duration-300 hover:scale-110 md:w-20 md:h-20 lg:w-24 lg:h-24"
                    effect="blur"
                  />
                </div>
              </button>
              <div className='text-center mt-1 lg:px-5 w-full'>
                <p className='tamanio'>{category?.nombre}</p>
              </div>
            </div>
          ))}
          <div onClick={() => { viewAllCategories(); SnipClicksCategories() }} className="flex flex-col justify-center items-center text-center px-2 cursor-pointer lg:mt-5 text-[#152a6e] hover:scale-110 transition duration-300">
            <i className="fas fa-plus-circle text-4xl lg:text-5xl"></i>
            <h3 className="text-xs lg:text-lg">Ver todas</h3>
          </div>
        </Slider>
      )}
    </div>
  );
};

export default ScrollableCategories;
