import React from 'react';
import { STATES, COMPANY_OPTIONS, INDIVIDUAL_OPTIONS } from '../../../constants/constants';

const FacturacionForAdmin = ({ direccionFiscal, factura }) => {
    const state = STATES.find(state=>state.id == direccionFiscal?.state_id) // Search its state by id
    // Search the name by value and the company type
    const options = direccionFiscal?.company_type === "company" ? COMPANY_OPTIONS : INDIVIDUAL_OPTIONS;
    const code_usage = options.find(cfdi => cfdi.value === direccionFiscal?.code_usage);

    if (direccionFiscal && factura) {
        return (
            <div className='bg-white border border-blue-900 shadow-lg w-4/5 mx-auto p-4 rounded '>
                <p className='  shrink-0 px-6 font-black my-2 text-xl text-center text-blue-800'>
                Datos de facturación
                </p>
            <div className="flex items-center justify-between w-full mt-4">
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal?.company_type == "company" ? "Compañia" : "Persona"}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-4">
                <p className="text-[#757575] text-base mr-2">Nombre:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.name}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Calle:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.street_name} {direccionFiscal.street_number}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Colonia:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.l10n_mx_edi_colony}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Ciudad:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.city}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Estado:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{state?.name}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Código Postal:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.zip}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">País:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.country_id == 156 ? "México" : direccionFiscal.country_id }</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Régimen Fiscal:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.l10n_mx_edi_fiscal_regime}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">RFC:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{direccionFiscal.vat}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Uso del CFDI:</p>
                <h2 className="text-[#012a45] text-lg font-semibold">{code_usage?.label}</h2>
            </div>
        </div>
        );
    } else if (factura === false) {
        return <div className='text-center'>El cliente no solicitó factura*</div>;
    } else {
        return <div className='text-center'>No hay datos de facturación*</div>;
    }
};

export default FacturacionForAdmin;
