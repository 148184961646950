import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Tooltip, Title } from 'chart.js';
import ModalGraficos from './ModalGracios';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Title
);

const DoughnutChart = ({ datalabes, datadat, label, title }) => {
  const [showModal, setShowModal] = useState(false);

  const datosDeDoughnut = {
    labels: datalabes,
    datasets: [
      {
        label: label,
        data: datadat,
        backgroundColor: [
          '#007BFC', // Azul
          '#163d6d', // Azul oscuro
          '#e9bf56', // Amarillo
          '#060082', // Azul más oscuro
          // Añade más colores según necesites
        ],
        borderColor: [
          '#007BFC', // Azul
          '#163d6d', // Azul oscuro
          '#e9bf56', // Amarillo
          '#060082', // Azul más oscuro
          // Añade más colores según necesites
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda
      },
      title: {
        display: false,
        text: title,
      },
    },
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Mostrar la leyenda
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  return (
    <div className="w-full px-4">
      <div className="flex grid grid-flow-col px-4 justify-between mx-auto items-center">
        <h2 className="text-center text-base font-semibold">
          {title}
        </h2>
        <button

          name='btn-seemore2'
          id='btn-seemore2'
          onClick={() => setShowModal(true)}
          className="ml-4 px-2 py-1 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <i className="fas fa-search-plus"></i>
        </button>
      </div>
      <Doughnut data={datosDeDoughnut} options={options} />

      <ModalGraficos show={showModal} onClose={() => setShowModal(false)}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-11/12 h-5/6">
            <Doughnut data={datosDeDoughnut} options={options2} />
          </div>
        </div>
      </ModalGraficos>
    </div>
  );
};

export default DoughnutChart;