import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <GoogleOAuthProvider clientId="168873968061-1942s1c9ol2gp8tthml0crte3ot97lv8.apps.googleusercontent.com">
    <App />
    </GoogleOAuthProvider>
    
  // </React.StrictMode>,
)
