
import axios from "axios";
import CryptoJS from 'crypto-js';
import { API_URL, STANDARD_EVENTS_META } from "../../constants/constants";
import { getCookie, getRandomInt } from "../utils";
import validateEmail from "../validateEmail";

const getAddressIP = async () => {
    try {
        const res = await axios.get("https://api.ipify.org/?format=json");
        return res.data.ip        
    } catch (error) {
        return null
    }
  };

/**
 * Llamar evento "EventInitCheckout" en la API de conversiones.
 * @param {string} email - Email of user.
 * @param {string} value - Total value of cart.
 */
const callEventInitCheckout = async (email, value, event_id="") => {
    const headers = {        
        fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
        fbc: getCookie("_fbc") ? getCookie("_fbc") : null,
        ttp: getCookie("_ttp") ? getCookie("_ttp") : null,   
    }
    
    try {
        const res = await axios.post(`${API_URL}/eventInitCheckout`, {
            "email": email,
            "value": value,
            "event_id": event_id
        }, {headers: headers})
    } catch (error) {
        return null
    }
}

/**
 * Llamar evento en la API de conversiones.
 * @param {string} word_seach - Word searched
 * @param {string} value - Total value of cart.
 */
const callEventSearch = async (word_seach, value, email, event_id) => {
    try {
        const res = await axios.post(`${API_URL}/eventSearch`, {
            "word_seach": word_seach,
            "value": value,
            "email": email,
            "event_id": event_id
        })
    } catch (error) {
        return null
    }
}

function generateHashes(values) {
    return values.map(value => CryptoJS.SHA256(value).toString(CryptoJS.enc.Hex));
}

// ---------------------------------------- List events ---------------------------------------- //

/**
 * Evento Pixel: El usuario se ha registrado
 * @param string Email
 * @param string Origin, default: 'Website'
 */
export const signup = async (email, event_id="", origin='Website') => {
    let ip = await getAddressIP()
    const hashes = generateHashes([email])
    
    fbq('track', STANDARD_EVENTS_META.COMPLETE_REGISTRATION, {
        em: hashes[0],
        origin: origin,
        client_user_agent: window.navigator.userAgent || null,
        client_ip_address: ip,
        fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
        fbc: getCookie("_fbc") ? getCookie("_fbc") : null
    }, {event_id: event_id})

    // Pixel Tiktok
    ttq.track(STANDARD_EVENTS_META.COMPLETE_REGISTRATION, {
        email: hashes[0],
        currency: "MXN",
        ip: ip,
        user_agent: window.navigator.userAgent || ""
    }, {event_id: event_id});
}

/**
 * Evento Pixel: El usuario ha agregado un producto a su carrito
 * @param string ID
 * @param string Name
 * @param string Value
 * @param string Email
 * @param string Name
 * @param string Event ID
 * @param string Quantity
 * @param string Currency
 */
export const productToCart = async (id_product, product_name, product_value, email=null, name=null,  event_id="", quantity=1, currency='MXN') => {
    try {        
        let ip = await getAddressIP()
        const hashes = []
        if(email){
            const result = generateHashes([email, name.toLowerCase().split(" ")[0]]) // Obtiene el primer nombre en minúsculas y genera el hash.
            hashes.push(...result)
        }else{
            hashes.push(...[null, null])
        }
    
        fbq('track', STANDARD_EVENTS_META.ADD_TO_CART, {
            id_product: id_product,
            content_name: product_name,
            value: product_value.toFixed(2),
            em: hashes[0],
            fn: hashes[1],
            currency: currency,
            client_user_agent: window.navigator.userAgent || null,
            client_ip_address: ip,
            fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
            fbc: getCookie("_fbc") ? getCookie("_fbc") : null
        }, {event_id: event_id})
    
        // Pixel Tiktok
        ttq.track(STANDARD_EVENTS_META.ADD_TO_CART, {
            contents: [
                {
                    content_id: id_product,
                    content_name: product_name,
                    quantity: quantity,
                    price: product_value
                }
            ],
            email: email ? hashes[0] : "",
            content_type: 'product',
            content_id: '1',
            value: product_value,
            currency: currency,
            ip: ip,
            user_agent: window.navigator.userAgent || "",
        }, {event_id: event_id});
        
    } catch (error) {
        console.log("Ha ocurrido un error")
    }
}

/**
 * Evento Pixel: El usuario ha agregado un producto a su carrito
 * @param string id_product
 * @param string product_name
 * @param string product_value
 * @param string email, default: 'No registrado'
 * @param string event_id
 * @param string quantity
 * @param string currency, default: 'MXN'
 */
export const addToFavorites = async (id_product, product_name, product_value, email='No registrado', event_id="", quantity=1, currency='MXN') => {
    let ip = await getAddressIP()
    const hashes = generateHashes([email])
    fbq('track', STANDARD_EVENTS_META.ADD_TO_WISH_LIST, {
        id_product: id_product,
        content_name: product_name,
        value: product_value.toFixed(2),
        em: hashes[0],
        currency: currency,        
        client_user_agent: window.navigator.userAgent || null,
        client_ip_address: ip,
        fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
        fbc: getCookie("_fbc") ? getCookie("_fbc") : null
    }, {event_id: event_id})

    // Pixel Tiktok
    ttq.track(STANDARD_EVENTS_META.ADD_TO_WISH_LIST, {
        email: email ? hashes[0] : null,
        contents: [
            {
                content_id: id_product,
                content_name: product_name,
                quantity: quantity,
                price: product_value
            }
        ],
        content_type: 'product',
        value: product_value,
        currency: currency,
        ip: ip,
        user_agent: window.navigator.userAgent || ""
    }, {event_id: event_id});
}

/**
 * Evento Pixel: El usuario ha dado click a "Continuar y comprar"
 * @param string Value.
 * @param string Email.
 * @param string Currency.
 */
export const goToPay = async (value, email='No registrado', currency='MXN') => {
    let event_id = getRandomInt()
    let ip = await getAddressIP()
    if (!validateEmail(email)) {        
        const hashes = generateHashes([email])
        fbq('track', STANDARD_EVENTS_META.INITIATE_CHECKOUT, {
            em: hashes[0],
            value: value.toFixed(2),
            currency: currency,
            client_user_agent: window.navigator.userAgent || null,
            client_ip_address: ip,
            fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
            fbc: getCookie("_fbc") ? getCookie("_fbc") : null
        }, {event_id: event_id})

        await callEventInitCheckout(email, value, event_id)
    
        // Pixel Tiktok
        ttq.track(STANDARD_EVENTS_META.INITIATE_CHECKOUT, {
            email: hashes[0],
            content_id: '1',
            currency: currency,
            content_type: 'product',
            value: value,
            ttp: getCookie("_ttp") ? getCookie("_ttp") : "",
            ip: ip ? ip : "",
            user_agent: window.navigator.userAgent || ""
        }, {event_id: event_id});
    }
}

/**
 * Evento Pixel: El usuario añade su información de pago
 * @param string Email.
 * @param string Total.
 * @param string data.
 * @param string event_id.
 */
export const addPaymentInfo = async (email, total, data_user, event_id="", quantity=1, currency='MXN') => {
    let ip = await getAddressIP()
    const hashes = generateHashes([
        email,
        data_user?.direccion_usuario?.telefono,
        data_user?.direccion_usuario?.cp,
        data_user?.datos_orden?.cliente.toLowerCase().split(" ")[0],
        data_user?.direccion_usuario?.ciudad,
        data_user?.direccion_usuario?.estado,
        "mx"
    ])
    fbq('track', STANDARD_EVENTS_META.ADD_PAYMENT_INFO, {
        em: hashes[0],
        value: total.toFixed(2),        
        quantity: quantity,

        // Extra        
        ph: hashes[1], // Cellphone
        zp: hashes[2],      // ZIP
        fn: hashes[3],      // First Name
        ct: hashes[4],   // City
        st: hashes[5],   // State
        country: hashes[6], // Country
        client_user_agent: window.navigator.userAgent || null,
        client_ip_address: ip,
        fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
        fbc: getCookie("_fbc") ? getCookie("_fbc") : null
    }, {event_id: event_id})

    // Pixel Tiktok
    ttq.track(STANDARD_EVENTS_META.ADD_PAYMENT_INFO, {
        email: email ? hashes[0] : "",
        phone: hashes[1],
        value: total,
        quantity: quantity,
        currency: currency,
        content_type: 'product',
        value: total,
        content_id: '1',
        ip: ip ? ip : "",
        user_agent: window.navigator.userAgent || ""
    }, {event_id: event_id});
}

/**
 * Evento Pixel: El usuario ha comprado y llego a la ventana de confirmación.
 * @param {string} value - Total of purchase.
 * @param {array} content_ids - Array of IDs products.
 * @param {string} email - Email of user.
 * @param {string} phone_number - Phone number of user.
 * @param {string} currency - Currency (optional).
 */
export const purchase = async (value, content_ids, email=null, phone_number=null, event_id="", currency='MXN') => {      
    try {
        let ip = await getAddressIP()
        let hashes = []
        if(email && phone_number){
            hashes = generateHashes([email, "+52"+phone_number])
        }    
        fbq('track', STANDARD_EVENTS_META.PURCHASE, {
            // Fields requireds
            em: email ? hashes[0] : null,
            ph: phone_number ? hashes[1] : null,
            value: value,
            currency: currency,
            // Extra fields
            client_user_agent: window.navigator.userAgent || null,
            client_ip_address: ip,
            fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
            fbc: getCookie("_fbc") ? getCookie("_fbc") : null
        }, {event_id: event_id})
        
        ttq.track(STANDARD_EVENTS_META.COMPLETE_PAYMENT, {
            email: email ? hashes[0] : "",
            phone: phone ? hashes[1] : "",
            content_type: "product",
            quantity: content_ids?.length,
            content_ids: content_ids,
            currency: currency,
            value: value,
            ip: ip ? ip : "",
            user_agent: window.navigator.userAgent || ""
        }, {event_id: event_id})
        
    } catch (error) {
        // Ha ocurrido un error inesperado
    }
}

/**
 * Evento Pixel: El usuario realizó una búsqueda
 * @param string Query. Word searched.
 * @param string Product. Data of product.
 * @param string email. Email of user (optional).
 * @param string currency (optional).
 */
export const search = async (query, product, email=null, currency="MXN") => {
    try {
        let event_id = getRandomInt()        
        let ip = await getAddressIP()
        let hashes = []

        if (email) {
            hashes = generateHashes([email])
        }
        //Pixel Meta
        fbq('track', STANDARD_EVENTS_META.SEARCH, {
            em: email ? hashes[0] : null,
            search_string: product?.titulo,
            value: product?.precio?.cantidad,
            currency: currency,
            client_user_agent: window.navigator.userAgent || null,
            client_ip_address: ip,
            fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
            fbc: getCookie("_fbc") ? getCookie("_fbc") : null
        }, {event_id: event_id})
    
        await callEventSearch(
            product?.titulo,
            product?.precio?.cantidad,
            email ? hashes[0] : null,
            event_id
        )
    
        // Pixel Tiktok
        ttq.track(STANDARD_EVENTS_META.SEARCH, {
            email: email ? hashes[0] : "",
            query: product?.titulo,
            currency: currency,
            value: product?.precio.cantidad,
            content_id: product?.sku,
            content_type: 'product',
            ip: ip ? ip : "",
            user_agent: window.navigator.userAgent || "",
        }, {event_id: event_id});        
    } catch (error) {
        // Ha ocurrido un error inesperado
    }
}