// Importa las dependencias necesarias
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../../componentes/Headers/Header';
import Footer from '../../../componentes/footer/Footer';
import StarRating from '../../../componentes/Usercliente/StarRating ';
import axios from 'axios';
import CargandoSpinner from '../../../componentes/cargacomponetes/CargandoSpinner';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../constants/constants';


// Componente OpinarEspecifico
const OpinarEspecifico = () => {
    // Utiliza useLocation para acceder al estado pasado en la navegación
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const { idpedido, idpdrocut, nameproduct, ulrimg, user_id } = location.state || {}; // Asegúrate de manejar casos donde el estado pueda ser undefined
    const [opinion, setOpinion] = useState("");


    const [rating, setRating] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        // -------- Pixel Meta ---------- //
        // selectReview(token ? token['$email'] : 'No registrado')
    }, [])


    const handleRatingChange = (newRating) => {
        setRating(newRating);
        // console.log(`La nueva calificación es: ${newRating}`);
        // Aquí puedes hacer más acciones, como enviar la calificación a una API
    };





    const sendOpinion = async () => {
        setIsLoading(true); // Inicia la carga
        // console.log("opinion hecha y calificaicon: ", opinion);

        const payload = {
            "id_usuario": user_id,
            "id_producto": parseInt(idpdrocut),
            "comentario": opinion,
            "calificacion": parseInt(rating),
            "id_pedido": parseInt(idpedido)
        }
        // console.log('enviare', payload)

        try {

            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                },
                timeout: 15000

            };

            const response = await axios.post(API_URL + '/saveComment', payload, config)
            // console.log('correcto opinion guardada', response.data);

            // -------- Pixel Meta ---------- //
            // reviewProduct(
            //     token ? token['$email'] : 'No registrado',
            //     nameproduct,
            //     parseInt(rating),
            // )

            setOpinion('')
            setRating(0)
            navigate(-1);
        } catch (error) {
            console.error('Error', error)

            //Verificar si el token ya expiro
            if (error.response.data.error == 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m')
                navigate('/');
                return
            }

            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out saveComment');
            }
            console.error('Ha ocurrido un error: ', error)
        } finally {
            setIsLoading(false); // Finaliza la carga
        }
    };


    return (
        <div>
            {
                isLoading ? <CargandoSpinner txtcharge="Estamos enviando tu opinion espera un poco" /> : null
            }

            <Header />
            <div className='h-screen lg:bg-gray-100 lg:pt-10'>
                <div className='bg-white lg:w-4/5 mx-auto  lg:rounded'>
                    <div className='flex flex-col items-center justify-center text-center  w-full px-5 mx-auto py-2'>
                        <img width="300" height="200"
                            src={ulrimg} className='w-40 h-40 object-cover rounded border-2 mb-2 border-gray-200' />
                        <p className='text-sm text-[#030303] '>{nameproduct}</p>
                    </div>

                    <div className='text-center text-lg font-black w-80 mx-auto mt-4'>
                        <h3>
                            ¿Cómo evaluarías tu experiencia con el producto?
                        </h3>
                    </div>

                    <StarRating onRatingChange={handleRatingChange} />


                    <div className='flex flex-col items-center justify-center'>
                        <textarea
                            className="textarea textarea-bordered w-4/5 h-40 mt-4 rounded  border p-2 border-black"
                            placeholder="Escribe tu opinión aquí..."
                            value={opinion}
                            onChange={(e) => setOpinion(e.target.value)}
                        ></textarea>
                        <button

                            name='btn-sendOpinion'
                            id='btn-sendOpinion'
                            className={`btn mt-4 mb-4 px-10 py-3 text-lg ${rating > 0 && opinion ? 'bg-[#1e3d8b] text-white hover:bg-[#163472] hover:text-[#e2e8f0]' : 'bg-gray-200 text-gray-500 '} rounded-lg transition ease-in-out duration-150`}
                            disabled={rating === 0 || !opinion}
                            onClick={sendOpinion}
                        >
                            Enviar Opinión
                        </button>

                    </div>
                </div>

            </div>

            <Footer />
        </div>
    );
};

export default OpinarEspecifico;
