// En DtoComparativo.jsx
import React from 'react';
import RayoC from '../../assets/RayoC.png'
import CaLeC from '../../assets/CaLeC.png'
import AlturaC from '../../assets/AlturaC.png'
import AnchoC from '../../assets/AnchoC.png'
import AlnchiinC from '../../assets/AlnchiinC.png'
import Alturalevnatec from '../../assets/Alturalevnatec.png'

const DtoComparativo = ({ productos, onLimpiarComparacion }) => {
    return (
        <div className=' '>
            <div className="flex justify-center space-x-4 my-4 px-2 ">
                {productos.map(producto => (
                    <div key={producto.id_producto} className="card flex-1 text-center rounded-xl p-4 ">
                        <img width="300" height="200"
                            src={producto.imagen} alt={producto.titulo} className="lg:w-1/3 mx-auto" />
                        <div className='w-full text-center h-26 overflow-hidden text-sm'>
                            <h2>
                                {producto.titulo}
                            </h2>
                        </div>
                        <p className="text-md text-[#1e3d8b] mt-2 font-medium">
                            {parseFloat(producto.precio.cantidad).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {producto.precio.moneda}
                        </p>
                        <button
                            name='btn-addcarcoparation'
                            id='btn-addcarcoparation' className='bg-[#007bfc] text-white px-2 text-xs py-1 rounded-lg uppercase my-2'>Añadir al carrito</button>
                        <div className='text-[#007bfc] uppercase text-sm'>
                            <a>ver más <i className="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
                            <div className='border-t-2 pt-2 flex flex-col justify-center items-center'>
                                <img width="300" height="200"
                                    src={RayoC} alt='imgrayo' />
                                <p className='mt-1 text-xs'>Dato-1</p>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <img width="300" height="200"
                                    src={CaLeC} alt='imgrayo' />
                                <p className='mt-1 text-xs'>Dato-2</p>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <img width="300" height="200"
                                    src={AlturaC} alt='imgrayo' />
                                <p className='mt-1 text-xs'>Dato-3</p>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <img width="300" height="200"
                                    src={AnchoC} alt='imgrayo' />
                                <p className='mt-1 text-xs'>Dato-4</p>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <img width="300" height="200"
                                    src={AlnchiinC} alt='imgrayo' />
                                <p className='mt-1 text-xs'>Dato-5</p>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <img width="300" height="200"
                                    src={Alturalevnatec} alt='imgrayo' />
                                <p className='mt-1 text-xs'>Dato-6</p>
                            </div>
                        </div>

                    </div>
                ))}
            </div>

            {productos.length > 0 && (
                <div className='flex justify-center'>
                    <button

                        name='btn-clearcoti'
                        id='btn-clearcoti'
                        onClick={onLimpiarComparacion} className='bg-[#e9bf56] px-5 py-1 rounded-md'>
                        Limpiar comparación
                    </button>
                </div>
            )}
        </div>
    );
};

export default DtoComparativo;