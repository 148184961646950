import React from 'react';
import { useNavigate } from 'react-router-dom';

const TablaCupones = ({ cupones }) => {
  const navigate = useNavigate();

  const handleAddCuponClick = () => {
    navigate('/add-cupon');
  };

  const handleEditCuponClick = (cupon) => {
    navigate('/add-cupon', { state: { cupon } });
  };

  return (
    <div className="p-4 mt-10">
      <h2 className="text-2xl font-bold mb-4">Tabla de Cupones</h2>

      <div className='py-2 px-2 mb-12'>
        <button
          onClick={handleAddCuponClick}
          className='bg-[#1a2869] text-white py-2 w-1/6 rounded hover:bg-[#3b82f6] transition duration-300 ease-in-out'
        >
          Agregar Cupon
        </button>
      </div>

      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">ID</th>
            <th className="py-2 px-4 border-b">Código</th>
            <th className="py-2 px-4 border-b">Descuento</th>
            <th className="py-2 px-4 border-b">Estatus</th>
            <th className="py-2 px-4 border-b">Expiración</th>
            <th className="py-2 px-4 border-b">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {cupones.map((cupon) => (
            <tr key={cupon.id}>
              <td className="py-2 px-4 border-b text-center">{cupon.id}</td>
              <td className="py-2 px-4 border-b text-center">{cupon.nombre}</td>
              <td className="py-2 px-4 border-b text-center">
                {cupon.des_cantidad > 0
                  ? ` $${cupon.des_cantidad}`
                  : ` ${cupon.des_porcentaje}%`}
              </td>
              <td className='py-2 px-4 border-b text-center'>
                <div className={`rounded ${cupon.estatus ? 'text-white bg-green-700' : 'text-white bg-red-700'}`}>

                  {cupon.estatus ? 'Activo' : 'Inactivo'}
                </div>

              </td>




              <td className="py-2 text-center px-4 border-b">{new Date(cupon.expiracion).toLocaleDateString()}</td>
              <td className="py-2 px-4 border-b text-center">
                <button
                  onClick={() => handleEditCuponClick(cupon)}
                  className='p-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition duration-300 ease-in-out'
                >
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablaCupones;
