import React, { useState, useEffect, useRef } from 'react';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ValidateRefreshToken } from '../../utils/validations';
import { API_URL, customStyles } from '../../constants/constants';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import TablaClientes from '../../componentes/Admi/TablaClientes';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import ComboCard from '../productos/combos/ComboCard';
import Sidebar from '../../componentes/Admi/Sidebar';



const AddCombo = () => {

    //datos recibidos d enavegacion
    const navigate = useNavigate();
    const location = useLocation();
    const { combo } = location.state || { combo: null }; // Obtiene idadress del estado, si existe
    const [dataCategories, setDataCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null);


    // console.log('el combo selecioando es: ', combo)
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);

    const [errors, setErrors] = useState({});
    const [msi, setMsi] = useState('') //meses sin intereces

    // Formulario estado inicial
    const [formData, setFormData] = useState({
        combo_id: '',
        combo_name: '',
        sku_combo: '',
        imagen: '',
        imagenes: [],
        active: false,
        products: [],
        stock: {
            disponible: '',
            mostrado: '',
        },
        msi: '',
        category: '',
        user_id: ''



    });

    const goComboDetail = (idCombo) => {
        // console.log('-----El combo selecionado es:', idCombo)

    }


    useEffect(() => {
        if (combo) {

            setFormData({
                combo_id: combo.id_combo || '',
                combo_name: combo.nombre_combo || '',
                sku_combo: combo.sku || '',
                imagen: combo.imagen || '',
                imagenes: combo.imagenes || [],
                active: combo.active || false,
                createdAt: combo.created_at || '',
                updatedAt: combo.updated_at || '',
                products: combo.productos || [],
                stock: {
                    disponible: combo.stock ? combo.stock.disponible : '',
                    mostrado: combo.stock ? combo.stock.mostrado : '',
                },
                msi: combo.msi ? combo.msi : '',
                category: combo.category ? combo.category : '',
                user_id: combo.user_id ? combo.user_id : '',



            });


        }

        getCategories()
        ProductsToCombos(info['$tk3m09s']);
    }, []);

    useEffect(() => {
        if (combo && dataCategories.length > 0) {
            setSelectedCategory(getSelectedCategory(combo.category));
        }
    }, [combo, dataCategories]);

    const ProductsToCombos = async (tk) => {
        // console.log('Busncoas productos')
        setIsLoading(true);

        try {

            const config = {
                headers: {
                    'Authorization': tk
                }
            };



            const response = await axios.get(API_URL + '/getProductsToCombos', config);
            const productsWithIdOdoo = response.data.success.filter(product => product.id_odoo);

            // console.log('Los productos son:', productsWithIdOdoo);
            const options = productsWithIdOdoo.map(product => ({
                value: product.id_producto,
                label: `${product.sku} - ${product.titulo}`, // Concatenando SKU y título
                productDetails: product
            }));
            setProducts(options); // Almacenar en el estado

        } catch (error) {
        }

        setIsLoading(false);
    };

    const fileInputRef = useRef(null);
    const multipleInputRef = useRef(null);

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const multipleFilesInputRef = () => {
        multipleInputRef.current.click();

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Verifica si el input es parte del objeto stock
        if (name.startsWith('stock_')) {
            const stockKey = name.split('stock_')[1]; // Obtiene la clave real para el estado de stock
            setFormData(prevState => ({
                ...prevState,
                stock: {
                    ...prevState.stock,
                    [stockKey]: parseInt(value)  // Actualiza solo la propiedad relevante de stock
                }
            }));
        } else {
            // Maneja otros inputs normalmente
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handleProductChange = (index, e) => {
        const { name, value } = e.target;

        // Actualizar el estado del producto basado en el input modificado
        const updatedProducts = formData.products.map((product, idx) => {
            if (idx === index) {
                // Si el campo modificado es precio o cantidad, actualizar directamente
                if (name.includes('.')) {
                    const [key, nestedKey] = name.split('.');
                    let adjustedValue = value;
                    // Ajustar el producto actualizado
                    let updatedProduct = {
                        ...product,
                        [key]: {
                            ...product[key],
                            [nestedKey]: parseFloat(adjustedValue)
                        }
                    };

                    // Si se actualiza el precio, recalcula el precio con descuento
                    if (nestedKey === 'cantidad') {

                        const precioConDescuento = parseFloat(adjustedValue) - (parseFloat(adjustedValue) * (product.discount?.value || 0) / 100);
                        const precioSinIVA = parseFloat(adjustedValue) / 1.16;
                        updatedProduct.precio_descuento = {
                            cantidad: precioConDescuento.toFixed(2),
                            moneda: 'MXN'
                        };
                        updatedProduct.precio_sin_iva = {
                            cantidad: parseFloat(precioSinIVA.toFixed(2)),
                            moneda: 'MXN'
                        }


                    }

                    return updatedProduct;
                }
                // Si el cambio no involucra puntos (p.ej., 'discount'), actualiza directamente
                return { ...product, [name]: value };
            }
            return product;
        });

        // Actualizar el estado del formulario con los productos modificados
        setFormData({ ...formData, products: updatedProducts });
    };

    const handleSelectProduct = (index, selectedOption) => {
        //  console.log('Opción seleccionada:', selectedOption);

        const updatedProducts = formData.products.map((prod, idx) => {
            if (idx === index) {
                return {
                    ...prod,
                    id_odoo: selectedOption.productDetails.id_odoo, // Usa productDetails aquí
                    id_producto: selectedOption.value,
                    sku: selectedOption.productDetails.sku, // Asumiendo que sku también está dentro de productDetails
                    titulo: selectedOption.label,
                    cantidad: prod.cantidad || '',
                    precio: prod.precio || { cantidad: '', moneda: 'MXN' }
                };
            }
            return prod;
        });
        const newImage = selectedOption.productDetails.imagen;
        const imagesArray = formData.imagenes.includes(newImage) ? formData.imagenes : [...formData.imagenes, newImage];


        setFormData({
            ...formData,
            products: updatedProducts,
            imagenes: imagesArray
        });
    };

    const addProduct = () => {
        setFormData(prevState => ({
            ...prevState,
            products: [...prevState.products, {
                id_odoo: '',
                id_producto: '',
                sku: '',
                titulo: '',
                cantidad: '',
                precio: { cantidad: '', moneda: 'MXN' },
                isGift: false // Estado de regalo

            }]
        }));
    };


    const handleGiftChange = (index, checked) => {
        const updatedProducts = formData.products.map((product, idx) => {
            if (idx === index) {
                return {
                    ...product,
                    isGift: checked,
                    precio: { cantidad: checked ? '0' : product.precio.cantidad, moneda: 'MXN' },
                    descuento: checked ? 100 : product.descuento
                };
            }
            return product;
        });

        setFormData({ ...formData, products: updatedProducts });
    };

    const removeProduct = index => {
        const updatedProducts = formData.products.filter((_, i) => i !== index);
        setFormData({ ...formData, products: updatedProducts });
    }

    const handleDiscountChange = (index, selectedOption) => {
        // Actualiza los productos ajustando el descuento y recalculando el precio con descuento
        const updatedProducts = formData.products.map((product, idx) => {
            if (idx === index) {
                const descuentoAplicado = selectedOption.value;  // Valor del descuento seleccionado
                const precioOriginal = product.precio.cantidad;
                const precioConDescuento = precioOriginal - (precioOriginal * descuentoAplicado / 100);

                return {
                    ...product,
                    descuento: selectedOption.value,  // Guarda el objeto de descuento seleccionado
                    precio_descuento: {  // Actualiza el precio con descuento
                        cantidad: parseFloat(precioConDescuento.toFixed(2)),  // Formatea a dos decimales
                        moneda: 'MXN'
                    }
                };
            }
            return product;
        });

        // Actualizar el estado del formulario con los productos modificados
        setFormData({ ...formData, products: updatedProducts });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Agrega user_id a formData
        const updatedFormData = {
            ...formData,
            user_id: info['$udi09345']
        };

        // Imprime los datos actualizados
        // console.log('Form data submitted: ', updatedFormData);

        if (validateForm()) {
            // console.log('Form data submitted: ', formData);
            setIsLoading(true);

            if (updatedFormData.combo_id) {
                // console.log(`El ID del combo existe: ${formData.combo_id}`);
                // Lógica para enviar los datos al servidor por PUT


                try {

                    const config = {
                        headers: {
                            'Authorization': info['$tk3m09s']
                        }
                    };

                    // console.log('---------Madare al put', formData)



                    const response = await axios.put(API_URL + '/managementCombo', updatedFormData, config);

                    // console.log('Los Respuesta put es:', response);


                } catch (error) {

                    console.log('ERROR', error)
                }

                setIsLoading(false);

            } else {
                // console.log("Sin ID de combo, enviando por POST");
                // Lógica para enviar los datos al servidor por POST

                try {

                    const config = {
                        headers: {
                            'Authorization': info['$tk3m09s']
                        }
                    };

                    const response = await axios.post(API_URL + '/managementCombo', updatedFormData, config);
                    // console.log('respuestra post es:', response);



                } catch (error) {

                    console.log('ERROR', error)
                }

                setIsLoading(false);

            }
        }

    };

    ///carga de las iamgenes*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
    const handleImageChange = (event) => {
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = () => {
                let img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    canvas.width = img.width / 2;
                    canvas.height = img.height / 2;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    const dataUrl = canvas.toDataURL('image/jpeg', 0.7);

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        imagen: dataUrl
                    }));
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        }
    };
    const handleImagesChange = (event) => {
        const files = Array.from(event.target.files).filter(file => file.type.match('image.*'));

        files.forEach(file => {
            let reader = new FileReader();
            reader.onloadend = () => {
                let img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    canvas.width = img.width / 2;
                    canvas.height = img.height / 2;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    const dataUrl = canvas.toDataURL('image/jpeg', 0.7);

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        imagenes: [...prevFormData.imagenes, dataUrl]
                    }));
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const handleDeleteImage = (index) => {
        const updatedImages = formData.imagenes.filter((_, i) => i !== index);
        setFormData({ ...formData, imagenes: updatedImages });
    };

    //validaciones
    const validateForm = () => {
        let formIsValid = true;

        // Validación del nombre del combo
        if (!formData.combo_name) {
            formIsValid = false;
            toast.error("El nombre del combo es necesario.");
        }

        // Validación de SKU
        if (!formData.sku_combo) {
            formIsValid = false;
            toast.error("El SKU es necesario.");
        }
        // Validación de stock 
        if (formData.stock.disponible === undefined || formData.stock.disponible === '' || isNaN(formData.stock.disponible) || formData.stock.disponible < 0) {
            formIsValid = false;
            toast.error("El stock disponible debe ser un número no negativo y estar presente.");
        }
        if (formData.stock.mostrado === undefined || formData.stock.mostrado === '' || isNaN(formData.stock.mostrado) || formData.stock.mostrado < 1) {
            formIsValid = false;
            toast.error("El mínimo para mostrar debe ser al menos 1.");
        }


        // Validación de los productos seleccionados
        formData.products.forEach((product, index) => {

            if (!product.id_producto || !product.cantidad) {
                formIsValid = false;
                toast.error(`ID y cantidad del producto ${index + 1} son necesarios.`);
            }
            // Omite la validación de precio y descuento si es regalo
            if (!product.isGift) {
                if (product.precio.cantidad == null || product.descuento == null) {
                    formIsValid = false;
                    toast.error(`Precio y descuento del producto ${index + 1} son necesarios.`);
                } else if (product.descuento < 0) {
                    formIsValid = false;
                    toast.error(`El descuento del producto ${index + 1} no puede ser negativo.`);
                }
            }

        });

        return formIsValid;
    };

    //opciones de descuento 
    const optionsDescuento = [
        { value: 0, label: 'Sin descuento' },
        { value: 5, label: '5%' },
        { value: 10, label: '10%' },
        { value: 15, label: '15%' },
        { value: 20, label: '20%' },
        { value: 25, label: '25%' },
        { value: 30, label: '30%' },
        { value: 35, label: '35%' },
        { value: 40, label: '40%' },
        { value: 45, label: '45%' },
        { value: 50, label: '50%' }
    ];

    const handleMSIChange = (event) => {
        const newMSIValue = event.target.value;
        setMsi(newMSIValue);  // Actualiza el estado de MSI local

        // Actualiza el valor de msi en formData y también actualiza cada producto si es necesario
        setFormData(prevFormData => {
            const updatedProducts = prevFormData.products.map(product => ({
                ...product,
                msi: newMSIValue  // Añade o actualiza el campo 'msi' en cada producto
            }));

            return {
                ...prevFormData,
                msi: newMSIValue,  // Actualiza el valor de msi en el objeto principal de formData
                products: newMSIValue !== "0" ? updatedProducts : prevFormData.products // Solo actualiza productos si msi no es "0"
            };
        });
    };

    const getCategories = async () => {
        setIsLoading(true); // Comienza la carga
        try {
            const response = await axios.get(API_URL + '/getAllCategories', {
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 10000
            });
            const activeCategories = response.data.filter(category => category.activo === true);
            // console.log('Se obtuvieron las categorias', activeCategories)
            setDataCategories(activeCategories);
            setIsLoading(false); // Carga finalizada con éxito
        } catch (error) {
            console.error("Error al obtener las categorías:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Tiempo de espera agotado en categorías slider');
            }
            setIsLoading(false); // Finaliza la carga debido a un error
        }
    }
    const handleChangeSelect = (selectedOption) => {
        // console.log('se seleccionó', selectedOption);
        setSelectedCategory(selectedOption);
        setFormData(prevFormData => ({
            ...prevFormData,
            category: selectedOption.value
        }));
    };

    const getSelectedCategory = (categoryName) => {
        return optionsCategory.find(option => option.value === categoryName);
    };

    const customStyles2 = {
        control: (provided) => ({
            ...provided,
            minWidth: 250,
            marginTop: 24,
            marginLeft: 10

        }),
        option: (provided, { data, isFocused, isSelected }) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: 'black', // Aseguramos que el texto siempre sea negro
            backgroundColor: isFocused ? 'rgba(0, 123, 255, 0.1)' : isSelected ? 'rgba(0, 123, 255, 0.2)' : 'white', // Color de fondo al enfocar o seleccionar
        }),
        singleValue: (provided, { data }) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: 'black', // Aseguramos que el texto siempre sea negro
        }),
    };



    const optionsCategory = dataCategories.map(category => ({
        value: category.nombre,
        label: category.nombre,
        imagen: category.imagen
    }));
    return (

        <div className=''>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <div className='p-4 sm:ml-64'>
                    <div className='w-2/3 bg-gray-300 p-5'>
                        <Link className='pr-5' to={'/products_managment'}>
                            <i className="fa-solid fa-arrow-left"></i>
                        </Link>

                        <h2 className='text-2xl font-black mb-8'>
                            {combo ? `Editar Combo: ${formData.combo_name}` : 'Nuevo Combo'}
                        </h2>


                        <form onSubmit={handleSubmit} className='p-5'>
                            <div className='flex w-full'>


                                <div className='flex flex-col w-2/3 mb-3'>
                                    <label htmlFor="combo_name">Nombre del Combo</label>
                                    <input type="text" id="combo_name" name="combo_name" className='rounded p-1 border border-slate-300 hover:border-indigo-300 px-2 mt-1' value={formData.combo_name} onChange={handleInputChange} />
                                </div>

                                <div className='flex flex-col w-1/3 ml-5'>
                                    <p>  Estado del combo</p>
                                    <label htmlFor="activeToggle" className="relative inline-block h-6 w-11 cursor-pointer">
                                        <input
                                            type="checkbox"
                                            id="activeToggle"
                                            className="sr-only"
                                            checked={formData.active}
                                            onChange={e => setFormData({ ...formData, active: e.target.checked })}
                                        />
                                        <span className={`absolute inset-y-0 left-0 w-11 h-6 rounded-full transition duration-300 ease-in-out ${formData.active ? 'bg-green-500' : 'bg-red-500'}`}></span>
                                        <span className={`absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition-transform duration-300 ease-in-out ${formData.active ? 'translate-x-5' : ''}`}></span>
                                    </label>
                                </div>


                            </div>
                            <div className='flex flex-col'>
                                <label htmlFor="sku_combo">SKU</label>
                                <input type="text" id="sku_combo" name="sku_combo" className='rounded p-1 border border-slate-300 hover:border-indigo-300 px-2 mt-1' value={formData.sku_combo} onChange={handleInputChange} />
                            </div>
                            <div className='flex justify-start mt-2'>
                                <div className='flex flex-col'>
                                    <label htmlFor="stock_inventario_inicial">Inventario Inicial</label>
                                    <input type="number" min="0" id="stock_inventario_inicial" name="stock_disponible" className='rounded p-1 border border-slate-300 hover:border-indigo-300 px-2 mt-1' value={formData.stock.disponible} onChange={handleInputChange} />
                                </div>
                                <div className='flex flex-col ml-4'>
                                    <label htmlFor="stock_minimo_mostrar">Mínimo para Mostrar</label>
                                    <input type="number" min="1" id="stock_minimo_mostrar" name="stock_mostrado" className='rounded p-1 border border-slate-300 hover:border-indigo-300 px-2 mt-1' value={formData.stock.mostrado} onChange={handleInputChange} />
                                </div>
                                <div>
                                    <Select
                                        value={selectedCategory}
                                        onChange={handleChangeSelect}
                                        options={optionsCategory}
                                        styles={customStyles2}
                                        placeholder="Selecciona una categoría"
                                        getOptionLabel={(option) => `${option.label}`}
                                        getOptionValue={(option) => option.value}
                                    />


                                </div>
                            </div>


                            <div className='flex flex-col'>
                                <input ref={fileInputRef} className='hidden' type="file" accept="image/*" onChange={handleImageChange} />

                                <div className='bg-white h-3/4 p-16 my-5 rounded cursor-pointer border border-slate-300 hover:border-indigo-300' onClick={triggerFileInput}
                                >
                                    <p className='text-center'>Cargar imagen</p>
                                </div>

                                {formData.imagen && <img width="300" height="200"
                                    src={formData.imagen} className='w-24 h-24 contian' alt="Imagen principal" />}

                                <input className='hidden' ref={multipleInputRef} type="file" accept="image/*" multiple onChange={handleImagesChange} />

                                <div className='bg-white h-3/4 p-16 my-5 rounded cursor-pointer border border-slate-300 hover:border-indigo-300' onClick={multipleFilesInputRef}
                                >
                                    <p className='text-center'>Cargar imagen</p>
                                </div>
                                <div className='flex flex-row flex-wrap'>
                                    {formData.imagenes.map((url, index) => (
                                        <div key={index} className='relative' >
                                            <div className='flex justify-end'>
                                                <button onClick={() => handleDeleteImage(index)} className=''

                                                    name='btn-redtext'
                                                    id='btn-redtext'
                                                >
                                                    <i className="fas fa-trash text-red-500"></i>
                                                </button>
                                            </div>

                                            <img width="300" height="200"
                                                src={url} alt={`Preview ${index}`} className='w-24 h-24 contian' />

                                        </div>
                                    ))}
                                </div>
                            </div>


                            {/* Products management section */}
                            {formData.products.map((product, index) => (
                                <div key={index} className='mt-4 bg-white p-2'>
                                    <h4 className='text-lg font-black'>Producto {index + 1}</h4>

                                    <div className='px-4'>
                                        <h1 className='text-sm mb-2'>
                                            Nombre del producto:
                                        </h1>
                                        <Select
                                            options={products}
                                            onChange={(selectedOption) => handleSelectProduct(index, selectedOption)}
                                            value={products.find(option => option.value === product.id_producto)}
                                            getOptionLabel={(option) => `${option.label}`}
                                            getOptionValue={(option) => option.value}
                                            styles={customStyles}
                                        />
                                    </div>

                                    <div className='flex px-4 mt-4'>
                                        <div className='w-1/3'>
                                            <h1 className='text-sm mb-1'>
                                                Cantidad
                                            </h1>
                                            <input
                                                type="number"
                                                name="cantidad"
                                                className='rounded p-1 border border-slate-300  hover:border-indigo-300 px-2 mt-1 w-1/2'
                                                value={product.cantidad}
                                                onChange={(e) => handleProductChange(index, e)}
                                                placeholder="Cantidad"
                                                min="1"  // Esto asegura que el valor mínimo ingresado sea 1
                                            />
                                        </div>
                                        <div className='w-1/3'>
                                            <h1 className='text-sm mb-1'>
                                                Precio
                                            </h1>
                                            $
                                            <input
                                                type="number"
                                                name="precio.cantidad"
                                                className='rounded p-1 border border-slate-300  hover:border-indigo-300 px-2 mt-1 w-1/2'
                                                value={product.precio.cantidad}
                                                onChange={(e) => handleProductChange(index, e)}
                                                placeholder="Precio"
                                            />
                                        </div>
                                        <div className='w-1/3'>
                                            <h1 className='text-sm mb-2 '>
                                                Descuento
                                            </h1>
                                            <Select
                                                options={optionsDescuento}
                                                onChange={(selectedOption) => handleDiscountChange(index, selectedOption)}
                                                value={optionsDescuento.find(option => option.value === product.descuento)}
                                                getOptionLabel={(option) => `${option.label}`}
                                                getOptionValue={(option) => option.value}
                                                className=" "
                                            />
                                        </div>


                                    </div>

                                    <div className='w-1/3'>
                                        <h1 className='text-sm mb-1'>
                                            Es Regalo
                                        </h1>
                                        <input
                                            type="checkbox"
                                            checked={product.isGift}
                                            onChange={e => handleGiftChange(index, e.target.checked)}
                                            className='rounded p-1 border border-slate-300 hover:border-indigo-300'
                                        />
                                    </div>



                                    <h1 className="text-lg font-bold text-black ml-4 mt-2">Precio al publico: ${product.precio_descuento?.cantidad || product.precio.cantidad}</h1>


                                    <div className=' mt-5'>
                                        <button

                                            name='btn-delproducto'
                                            id='btn-delproducto'
                                            type="button" className='bg-red-500 px-5 py-2 text-white ml-5' onClick={() => removeProduct(index)}>Eliminar Producto</button>

                                    </div>
                                </div>
                            ))}

                            <div className='bg-white p-2 mt-4'>
                                <div className='w-1/2'>
                                    {/* Selector de MSI */}
                                    <p className='text-xl mb-1 mt-4'>Opciones de financiamiento</p>
                                    <div className='flex flex-col xl:pr-20'>
                                        <label htmlFor="msi">Meses sin intereses</label>
                                        <select onChange={handleMSIChange} value={formData.msi}
                                            className='rounded p-1 border border-slate-300 hover:border-indigo-300 w-4/5 mt-1'>
                                            <option value="0">Sin meses</option>
                                            <option value="3">3 meses</option>
                                            <option value="6">6 meses</option>
                                            <option value="9">9 meses</option>
                                            <option value="12">12 meses</option>
                                            <option value="18">18 meses</option>
                                        </select>

                                    </div>
                                </div>
                            </div>

                            <div className='mt-5 flex  justify-center   '>
                                <button type="button" className='bg-[#1E3D8B] px-5 py-4 text-sm  text-white ml-5' onClick={addProduct}>Agregar Producto</button>

                                <button className='bg-[#e9bf56] px-5 py-4 text-sm text-black ml-5' type="submit">Guardar</button>
                            </div>
                        </form>
                    </div>

                    <div className='fixed top-24 right-10 bg-gray-100 sm:w-1/5 xl:w-1/5 rounded pb-2'>
                        <p className='text-center font-semibold text-gray-700'>Previsualización</p>
                        <div>
                            <img width="300" height="200"

                                onClick={() => goComboDetail(combo.id_combo)}
                                src={formData.imagen}
                                alt={formData.combo_name}
                                className="cursor-pointer w-48 h-48 object-contain rounded mx-auto"
                            />
                            <div className=' text-center  lg:mt-8'>

                                <h2 className="text-lg font-medium text-sm ">{formData.combo_name}</h2>

                            </div>
                            <div className="mt-auto mt-10">



                                <p className='text-center font-bold mt-16'>Llévatelo por:</p>
                                <p className="font-bold text-center text-[#d30711]">
                                    $999,999.00
                                </p>
                                <div className='flex justify-center mt-5'>
                                    <p
                                        className='bg-[#e9bf56] p-2 mt-2 uppercase text-xs font-semibold rounded'
                                    >
                                        Añadir a carrito
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            )}
        </div>
    );
};

export default AddCombo;
