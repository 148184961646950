// SkeletonLoader.js

const CardSkeletonLoader = () => (
    <div className=' lg:flex lg:w-4/5 px-5  w-full mx-auto lg:mt-10'>
        <div className='lg:w-full lg:h-[400px] '>
            <div className="border border-blue-700 shadow rounded-md p-4  w-full  h-full mx-auto">
                <div className="animate-pulse flex ">
                    <div className="rounded-md bg-slate-700 h-36 lg:h-80 lg:mt-8 w-full"></div>
                    <div className="flex-1 space-y-6 py-1  h-full">
                        <div className="h-full bg-slate-700 rounded"></div>

                    </div>
                </div>
            </div>
        </div>



    </div>
);

export default CardSkeletonLoader;
