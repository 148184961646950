import React from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '../../componentes/footer/Footer';
import Header from '../../componentes/Headers/Header';

function BlogDetail() {
    let navigate = useNavigate();


    return (



        <div className='bg-white'>

            <Header />
            <div className='mt-2 p-5 w-4/5 text-left mx-auto'>
                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                    Titulo del blog que se esta presentando
                </h1>
            </div>

            <section className=" lg:w-4/5  mx-auto">
                <div className="container mx-auto flex px-5 py-2 md:flex-row flex-col justify-center items-center">

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img width="300" height="200"
 className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
                    </div>

                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 lg:ml-6 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center mt-4">

                        <p className="mb-8 leading-relaxed">aqui va a ir la infomacion sobre el contendiod el blog. texto ejemplo: Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray </p>




                     
                    </div>



                </div>
            </section>

            <section>
                <div className="mx-auto max-w-screen-2xl px-4 py-4 sm:px-6 lg:px-8 lg:w-4/5">
                    <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">

                        <div className="relative flex items-center bg-gray-100">
                            <span
                                className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-gray-100"
                            ></span>

                            <div className="p-8 sm:p-16 lg:p-24">
                                <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore, debitis.
                                </h2>

                                <p className="mt-4 text-gray-600">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, molestiae! Quidem est
                                    esse numquam odio deleniti, beatae, magni dolores provident quaerat totam eos, aperiam
                                    architecto eius quis quibusdam fugiat dicta.
                                </p>

                                <a
                                    href="#"
                                    className="mt-8 inline-block rounded border border-[#1a2869] bg-[#1a2869] px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-[#1a2869] focus:outline-none focus:ring active:text-[#1a2869]"
                                >
                                    Comprar ahora
                                </a>
                            </div>
                        </div>

                        <div className="relative z-10 lg:py-12">
                            <div className="relative h-96 sm:h-80 lg:h-full">
                                {/* Reemplaza este iframe con el código de incrustación específico proporcionado por TikTok para tu video */}
                                <iframe
                                    src="https://www.tiktok.com/embed/v2/7283644303342128390?autoplay=1"
                                    className="absolute inset-0 h-full w-full"
                                    allowFullScreen
                                    frameBorder="0"
                                    title='tiktokvideo'
                                ></iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <Footer />
        </div>

    );
}

export default BlogDetail;
