import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ContactForm({ handleCloseClick, handleSubmit }) {
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        telefono: '',
        asunto: '',
        mensaje: '',
        compra: '', // Añadido nuevo campo para almacenar si el usuario ha comprado antes

    });
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const isAllFieldsFilled = Object.values(formData).every(value => {
            // Verifica si el valor es una cadena, si lo es, hace trim y verifica que no esté vacío
            // Si no es una cadena (por ejemplo, un booleano), simplemente verifica que no sea indefinido
            if (typeof value === 'string') {
                return value.trim() !== '';
            } else {
                return value !== undefined;
            }
        });
        setIsFormValid(isAllFieldsFilled);
    }, [formData]);


    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        // Manojo específico para los botones de radio de "compra"
        if (name === "compra") {
            // Asigna true si el valor es "Si", de lo contrario false
            setFormData({ ...formData, [name]: value === "Si" ? true : false });
        } else if (type === "radio" && name === "asunto") {
            setFormData({ ...formData, [name]: checked ? value : formData[name] });
        } else {
            // Para todos los demás inputs
            setFormData({ ...formData, [name]: value });
        }
    };

    // Función específica para manejar cambios en el input de teléfono
    const handlePhoneChange = (value) => {
        setFormData({ ...formData, telefono: value });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Validaciones
        if (!formData.nombre.trim()) {
            toast.error("Por favor, ingrese su nombre.");
            return;
        }

        // Validaciones
        if (!formData.email.trim()) {
            toast.error("Por favor, ingrese su correo electrónico.");
            return;
        }
        // Validaciones
        if (!formData.telefono.trim()) {
            toast.error("Por favor, ingrese su número telefónico.");
            return;
        }
        // Validaciones
        if (!formData.asunto.trim()) {
            toast.error("Por favor, seleciona un asunto.");
            return;
        }
        // Validaciones
        if (!formData.mensaje.trim()) {
            toast.error("Por favor, Ingresa un mensjae para enviar");
            return;
        }

        // Validaciones
        if (formData.compra === '') {
            toast.error("Por favor, indica si has comprado un producto anteriormente.");
            return;
        }

        // Añade más validaciones según necesites aquí
        if (isFormValid) {
            handleSubmit(formData); // Si todo está bien, envía los datos del formulario
        }

    };

    return (
        <div
            className="transition-opacity duration-500 opacity-100 visible font-roboto bg-gray-100"
            style={{ transition: 'visibility 0s, opacity 500ms' }}
        >
            <div className='flex justify-end px-2'>
                <button

                    name='btn-closecontect'
                    id='btn-closecontect'
                    className="text-2xl text-black p-2 lg:text-4xl"
                    onClick={handleCloseClick}
                >
                    <i className="fas fa-times"></i>
                </button>
            </div>

            <div className='text-center'>


                <section className="bg-gray-100">
                    <div className="mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:px-8">
                        <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                            <div className="lg:col-span-2 lg:py-12">
                                <p className="max-w-xl text-lg text-black ">
                                    En <span className='font-black text-[#1e3d8b]'>HANTEC</span>, nos comprometemos a brindar un servicio personalizado a nuestros clientes. Nuestro equipo está dedicado a ofrecer servicios de la más alta calidad, asegurando que cada solución esté perfectamente alineada con tus necesidades específicas.
                                </p>

                                <div className="mt-8">
                                    <a href="#" className="text-2xl font-bold text-[#1e3d8b]"> 7714480159 </a>

                                    <address className="mt-2 not-italic">Blvrd Luis Donaldo Colosio, Colinas de Plata, 42186 Pachuca de Soto, Hgo.</address>
                                </div>
                            </div>

                            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                                <form action="#" onSubmit={handleFormSubmit} className="space-y-4">
                                    <div>
                                        <label className="sr-only" htmlFor="name">Nombre</label>
                                        <input
                                            name="nombre"
                                            className="w-full rounded-lg p-3 text-sm border border-[#1a2869]"
                                            placeholder="Nombre"
                                            type="text"
                                            onChange={handleInputChange}
                                            value={formData.nombre}
                                        />
                                    </div>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                        <div>
                                            <label className="sr-only" htmlFor="email">Correo</label>
                                            <input
                                                name="email"
                                                className="w-full rounded-lg p-3 text-sm border border-[#1a2869]"
                                                placeholder="Correo"
                                                type="email"
                                                onChange={handleInputChange}
                                                value={formData.email}
                                            />
                                        </div>

                                        <div>
                                            <label className="sr-only" htmlFor="phone">Teléfono</label>

                                            <PhoneInput
                                                defaultCountry="MX"
                                                placeholder="Ingrese su número de teléfono"
                                                onChange={handlePhoneChange}
                                                value={formData.telefono}
                                                className="w-full rounded-lg p-3 text-sm border border-[#1a2869]"
                                                autoComplete="off"

                                            />
                                        </div>

                                    </div>

                                    <div className="text-left text-sm lg:text-lg">
                                        <h3>Asunto</h3>
                                    </div>

                                    <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-2">
                                        <div>
                                            <label
                                                htmlFor="Option1"
                                                className="block w-full cursor-pointer rounded-lg border border-[#1a2869] p-3 text-gray-600 hover:border-[#1e3d8b] has-[:checked]:border-[#1e3d8b] has-[:checked]:bg-[#1e3d8b] has-[:checked]:text-white"
                                                tabIndex="0"
                                            >
                                                <input
                                                    className="sr-only"
                                                    id="Option1"
                                                    type="radio"
                                                    tabIndex="-1"
                                                    name="asunto"
                                                    value="Deseo comprar en mayoreo"
                                                    onChange={handleInputChange}
                                                    checked={formData.asunto === "Deseo comprar en mayoreo"}
                                                />
                                                <span className="text-sm">Deseo comprar en mayoreo</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="Option2"
                                                className="block w-full cursor-pointer rounded-lg border border-[#1a2869] p-3 text-gray-600 hover:border-[#1e3d8b] has-[:checked]:border-[#1e3d8b] has-[:checked]:bg-[#1e3d8b] has-[:checked]:text-white"
                                                tabIndex="0"
                                            >
                                                <input
                                                    className="sr-only"
                                                    id="Option2"
                                                    type="radio"
                                                    tabIndex="-1"
                                                    name="asunto"
                                                    value="Cotización de producto"
                                                    onChange={handleInputChange}
                                                    checked={formData.asunto === "Cotización de producto"}
                                                />
                                                <span className="text-sm">Cotización de producto</span>
                                            </label>
                                        </div>

                                    </div>


                                    <div className="text-left text-sm lg:text-lg">
                                        <h3>¿Ya ha comprado un producto?</h3>
                                    </div>

                                    <div className="grid grid-cols-1 gap-2 text-center sm:grid-cols-2">

                                        <div>
                                            <label
                                                htmlFor="haCompradoSi"
                                                className="block w-full cursor-pointer rounded-lg border border-[#1a2869] p-3 text-gray-600 hover:border-[#1e3d8b] has-[:checked]:border-[#1e3d8b] has-[:checked]:bg-[#1e3d8b] has-[:checked]:text-white"
                                                tabIndex="0"
                                            >
                                                <input
                                                    className="sr-only"
                                                    id="haCompradoSi"
                                                    type="radio"
                                                    tabIndex="-1"
                                                    name="compra"
                                                    value="Si"
                                                    onChange={handleInputChange}
                                                    checked={formData.compra === true}
                                                />
                                                <span className="text-sm">Si</span>
                                            </label>
                                        </div>

                                        <div>

                                            <label
                                                htmlFor="haCompradoNo"
                                                className="block w-full cursor-pointer rounded-lg border border-[#1a2869] p-3 text-gray-600 hover:border-[#1e3d8b] has-[:checked]:border-[#1e3d8b] has-[:checked]:bg-[#1e3d8b] has-[:checked]:text-white"
                                                tabIndex="0"
                                            >

                                                <input
                                                    className="sr-only"
                                                    id="haCompradoNo"
                                                    type="radio"
                                                    tabIndex="-1"
                                                    name="compra"
                                                    value="No"
                                                    onChange={handleInputChange}
                                                    checked={formData.compra === false}
                                                />

                                                <span className="text-sm">No</span>
                                            </label>
                                        </div>

                                    </div>


                                    <div>
                                        <label className="sr-only" htmlFor="message">Mensaje</label>
                                        <textarea
                                            name="mensaje"
                                            className="w-full rounded-lg p-3 text-sm border border-[#1a2869]"
                                            placeholder="Mensaje"
                                            rows="8"
                                            onChange={handleInputChange}
                                            value={formData.mensaje}
                                        ></textarea>
                                    </div>

                                    <div className="mt-4">
                                        <button

                                            name='btn-sumbitcontact'
                                            id='btn-sumbitcontact'
                                            type="submit"
                                            className='inline-block w-full rounded-lg px-5 py-3 font-medium text-white sm:w-auto bg-black '
                                        >
                                            Enviar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

        </div>
    );
}

export default ContactForm;
