import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Asegúrate de importar el CSS de AOS
import Header from '../../componentes/Headers/Header';
import Footer from '../../componentes/footer/Footer';
import Tutulosubrtitulo from '../../componentes/Tutulosubrtitulo';
import axios from 'axios';
import { API_URL } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';


import ContactForm from '../../componentes/formularios/ContactForm';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import ModalContacto from '../../componentes/modales/ModalContacto';
import { useLocation } from 'react-router-dom';
import { useBeforeUnload } from '../../utils/Analitycs/analytics';
// pagina contacto
function Contact() {
  const navigate = useNavigate();

  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOk, setIsOK] = useState(false);




  // Aquí se manejarían los datos del formulario
  const handleSubmit = async (formData) => {
    setShowForm(false);
    setIsLoading(true)

    try {
      // Oculta el formulario y muestra el contenido original
      // Log de los datos del formulario para verificar que todo esté correcto
      // console.log('Datos del formulario ', formData);
      const response = await axios.post(`${API_URL}/saveContact`, formData)

      // Manejo de la respuesta del servidor
      // console.log('Respuesta del servidor: ', response.data);
      setIsLoading(false)
      setIsOK(true)

      // Aquí podrías, por ejemplo, mostrar un mensaje de éxito al usuario
      // utilizando toast o algún método similar
    } catch (error) {
      // Manejo de errores
      console.error('Error al enviar los datos del formulario: ', error);
      setIsLoading(false)

      // Aquí podrías, por ejemplo, mostrar un mensaje de error al usuario
      // utilizando toast o algún método similar
    }


  };

  useEffect(() => {

    window.scrollTo(0, 0);

  }, []);

  // El usuario ha sálido de la página
  const location = useLocation();
  const fullPath = location.pathname;
  const routeName = fullPath.split('/')[1];
  useBeforeUnload(routeName);

  const services = [
    { icon: 'fa-file-invoice-dollar', text: 'Servicio post venta' },
    { icon: 'fa-truck', text: 'Envío y entrega' },
    { icon: 'fa-headset', text: 'Apoyo técnico' },
    { icon: 'fa-comments', text: 'Consulta de preventas' },
    { icon: 'fa-credit-card', text: 'Cuenta y pago' },
    { icon: 'fa-shield-alt', text: 'Certificados y garantías' },
  ];

  const handleServiceClick = (serviceText) => {
    const phoneNumber = '5593311600';
    const message = `Hola, tengo una consulta sobre ${serviceText}.`;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };


  const handleStartClick = () => {
    window.scrollTo(0, 0);

    setShowForm(true); // Cuando se hace click en Comenzar, muestra el formulario
  };
  const handleCloseClick = () => {
    setShowForm(false); // Oculta el formulario y muestra el contenido original
  };

  const contactos = [
    {
      icono: "fas fa-phone-volume text-[#1e3d8b]",
      nombre: "VENTAS",
      enlaces: ["55 9331 1600"]
    },
    {
      icono: "fas fa-headset text-[#1e3d8b]",
      nombre: "SOPORTE",
      enlaces: [" 55 4440 8376"]
    },
    {
      icono: "fas fa-envelope text-[#1e3d8b]",
      nombre: "CORREO ELECTRÓNICO",
      enlaces: ["ventas@hantec.us", "soporte.tecnico@hantec.one"]
    },
    {
      icono: "fas fa-truck text-[#1e3d8b]",
      nombre: "MAYOREO Y DISTRIBUIDORES",
      enlaces: ["55 9252 3229",]
    }
  ];



  const handleCloseModal = () => {
    setIsOK(false); // Esto cierra el modal cambiando el estado
    navigate('/NuestrasCategorias'); // Esto redirige al usuario a la ruta deseada
  };

  return (
    <div>
      <Header />

      <ModalContacto isOpen={isOk} onClose={handleCloseModal} />



      {
        isLoading ? <CargandoSpinner txtcharge="Estamos enviando tu opinion espera un poco" /> : null
      }


      {!showForm ? (
        <div className='lg:w-4/5 mx-auto mb-10'>
          <Tutulosubrtitulo />
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 p-4 ">
            {services.map((service, index) => (
              <div onClick={() => handleServiceClick(service.text)}
                key={index}
                className="flex flex-col items-center text-center text-[#757575] p-4 border border-gray-300 rounded-lg cursor-pointer bg-[#f2f2f2] bg-opacity-45 transition-colors duration-300 hover:bg-[#1a2869] hover:text-white"

              >
                <i className={`fas ${service.icon} text-4xl mb-2 `}></i>
                <span className='text-sm lg:text-lg font-montserrat' >{service.text}</span>
              </div>
            ))}
          </div>


          <div className='p-5 text-center mx-auto  lg:w-1/2 mt-4 lg:mt-10 '

          >
            <h3 className='text-[#1e3d8b] text-2xl mb-2 lg:text-4xl font-montserrat'>
              <span className='font-black text-black mr-2' >¿Necesitas</span>
              ayuda?
            </h3>
            <p>
              En HANTEC nos parece importante mantenernos en contacto, por favor ingrese sus datos, comentarios y/o inquietudes para poder brindarte un servicio más personalizado.
            </p>

            <div className='mt-5 mb-10'>

              <button
                className='bg-[#e9bf56] text-black px-10 py-2 w-80 cursor-pointer rounded-lg transition-colors duration-300 hover:bg-[#1e3d8b] hover:text-white'
                name='btn-handleStartClick'
                id='btn-handleStartClick'
                onClick={handleStartClick}
              >
                Comenzar
              </button>

            </div>

          </div>



          <div className="flex px-1  mx-auto  flex-wrap justify-center my-10">
            {contactos.map((contacto, index) => (
              <div key={index}
                className={`mt-2   py-4 px-2 w-1/2 md:w-1/4 flex flex-col items-center ${index % 2 === 0 ? "bg-[#1e3d8b] bg-opacity-10" : "bg-[#f0f0f0] bg-opacity-80"
                  }`}>
                <i className={`${contacto.icono} text-6xl text-[#1e3d8b]`}></i>

                <h2 className='text-[#1e3d8b] font-black mt-4 text-sm lg:text-xl text-center'>
                  {contacto.nombre}
                </h2>

                <ul className='w-full  flex flex-col justify-center text-center'>
                  {contacto.enlaces.map((enlace, indexEnlace) => (
                    <li key={indexEnlace} className="text-[#030303] hover:text-[#007bfc] transition duration-150 ease-in-out">
                      {enlace.includes("@") ? (
                        <a href={`mailto:${enlace}`} className="hover:opacity-75 text-xs lg:text-lg transition-opacity duration-150 ease-in-out">{enlace}</a>
                      ) : (
                        <a href={`tel:${enlace}`} className="hover:opacity-75 text-sm transition-opacity lg:text-lg duration-150 ease-in-out">{enlace}</a>
                      )}
                    </li>
                  ))}
                </ul>


              </div>
            ))}
          </div>



        </div>
      ) : (
        <ContactForm handleCloseClick={handleCloseClick} handleSubmit={handleSubmit} />

      )}



      <Footer />
    </div>
  );
}

export default Contact;