import React from 'react';

function EditableField({ title, value, onChange, onSave, onCancel, onEdit, isEditable, showEditButtons }) {
  const info = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

  return (
    <div className='rounded  w-full mt-5 '>
      <p className='text-left'>{title}</p>
      <input
        className="mt-1 mb-3 p-2 border border-gray-300 rounded mr-4"
        type="text"
        value={value}
        onChange={onChange}
        disabled={!isEditable}
      />
      {info && info['$r07sad'] !== 'Tienda' && (
        <div>
          {!showEditButtons && (
            <div>
              <button
                className=" duration-500 ease-in-out px-4 py-2 bg-[#e9bf56] text-[#1a2869] text-sm rounded"

                name='btn-Modificarfield'
                id='btn-Modificarfield'
                onClick={onEdit} // Cambiado a onEdit
              >
                <i className="fas fa-edit"></i> Modificar
              </button>
            </div>

          )}
          {showEditButtons && (
            <div className='flex  justify-center '>
              <button
                name='btn-Cancelarfield'
                id='btn-Cancelarfield'
                className="transition duration-500 ease-in-out px-4 py-2 mr-2 bg-red-600 text-white text-sm rounded"
                onClick={onCancel}
              >
                <i className="fas fa-times"></i> Cancelar
              </button>
              <button
                className="transition duration-500 ease-in-out px-4 py-2 bg-green-600 text-white text-sm rounded"
                name='btn-savefield'
                id='btn-savefield'
                onClick={onSave}
              >
                <i className="fas fa-save"></i> Guardar
              </button>
            </div>
          )}
        </div>
      )}

    </div>
  );
}

export default EditableField;
