import React, { useEffect } from 'react';
// Importa useNavigate de react-router-dom si estás utilizando React Router para la redirección.
// import { useNavigate } from 'react-router-dom';

const RegistroExitosoModal = ({ isVisible, onClose }) => {
  // Descomenta la siguiente línea si estás utilizando React Router para la redirección.
  // const navigate = useNavigate();

  useEffect(() => {
    if (!isVisible) return;

    // Configura el temporizador para cerrar el modal y redirigir después de 15 segundos.
    const timer = setTimeout(() => {
      onClose(); // Cierra el modal.
      // Aquí agregas tu lógica de redirección. Por ejemplo, si usas React Router:
      // navigate('/pagina-principal');
      // O puedes usar window.location si no estás usando React Router:
      // window.location.href = '/pagina-principal';
    }, 10000); // 15 segundos.

    // Limpieza al desmontar el componente.
    return () => clearTimeout(timer);
  }, [isVisible, onClose]);

  if (!isVisible) {
    return null;
  }

  return (
    <div role="alert" className="fixed inset-0 bg-gray-600 bg-opacity-50 px-5 py-20 z-10">
      <div className="rounded-xl border border-gray-100 bg-white p-4">
        <div className="flex items-start gap-4">
          <span className="text-green-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </span>
          <div className="flex-1">
            <strong className="block font-medium text-gray-900">Registro Exitoso</strong>
            <p className="mt-1 text-sm text-gray-700">Tu registro se ha completado exitosamente. Serás redirigido a la página principal en breve.</p>
            {/* Botones o acciones adicionales aquí */}
          </div>
          <button onClick={onClose} className="text-gray-500 transition hover:text-gray-600"

            name='btn-closemdlcompl'
            id='btn-closemdlcompl'

          >
            <span className="sr-only">Cerrar</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistroExitosoModal;
