import React, { useState, useEffect } from 'react';
import SidebarAdmi from './SidebarAdmi';
import Select from 'react-select';

import { Link } from 'react-router-dom';
import InputDos from '../inputs/Inputo2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, customStyles } from '../../constants/constants';
import axios from 'axios';
import Sidebar from './Sidebar';


function AddDistribuidor({ }) {

    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const [colonias, setColonias] = useState([]);
    const [estados, setEstados] = useState([]);
    const [isColoniaSelectDisabled, setIsColoniaSelectDisabled] = useState(true);
    const [modalRegistroPrevio, setModalRegistroPrevio] = useState(false);
    const [loading, setLoading] = useState(false)
    const [dtoDireccion, setDtoDireccion] = useState([]);

    const [isSaved, setIsSaved] = useState(false);  // Estado para saber si el formulario fue guardado exitosamente

    const estadoInicial = {
        // nombre: '',
        store_name: '',
        num_dist: '',
        correo: '',

    };

    const estadoInicialDireccion = {
        // nombre: '',
        client_id: '',
        child_contact_id: '',
        nombre_tienda: '',
        contact_name: '',
        calle: '',
        noExterior: '',
        noInterior: '',
        colonia: '',
        cp: '',
        ciudad: '',
        id_estado: '',
        pais: 'México', // Puedes predefinir México como país
        telefono: '',
        email: '', // Usa el operador de encadenamiento opcional aquí
        referencia: '',
        direccion_fiscal: false, // Nuevo campo para manejar el estado del botón de radio
        id_direccion: '',
        principal: false // Aquí ajustas el valor basado en la existencia de 'token'

    };

    // Estado para manejar los datos del formulario
    const [formularioDireccion, setFormularioDireccion] = useState(estadoInicialDireccion);

    // Estado para manejar los datos del formulario
    const [formulario, setFormulario] = useState(estadoInicial);

    const handleChange = (e) => {
        setFormulario({ ...formulario, [e.target.name]: e.target.value });
        //console.log(formulario);
    };
    const handleChangeDirecion = (e) => {
        setFormularioDireccion({ ...formularioDireccion, [e.target.name]: e.target.value });
        //console.log(formulario);
    };

    const handleColoniaChange = (selectedOption) => {
        setFormularioDireccion({ ...formularioDireccion, colonia: selectedOption.value });
    };
    //guardar distribuidor
    const handleSave = async () => {
        if (!formulario.store_name || !formulario.num_dist || !formulario.correo) {
            toast.error("Todos los campos son obligatorios.");
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(API_URL + '/signupStores', formulario, {
                headers: { 'Authorization': info['$tk3m09s'] },
                timeout: 500000
            });

            //  console.log("La respuesta del distribuidor registrado es: ", response.data);

            const { $udi09345, $od, $name } = response.data;

            // Actualiza el estado con los nuevos valores obtenidos
            setFormularioDireccion(prevState => ({
                ...prevState,
                client_id: $udi09345,
                child_contact_id: $od,
                nombre_tienda: $name
            }));

            setIsSaved(true);  // Aquí se actualiza el estado para indicar que se guardó con éxito
            setLoading(false);
            toast.success("Datos guardados con éxito.");
        } catch (error) {
            console.error("Error al guardar el distribuidor:", error);
            toast.error("Ha ocurrido un error al guardar el distribuidor. Vuelve a intentarlo.");
            setLoading(false);
        }
    };

    //buscar colonias en base al CP
    const handlePostalCodeChange = async (nuevoCodigoPostal) => {

        const formData = {
            cp: nuevoCodigoPostal,
        };


        try {
            const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getDistricts', formData, { timeout: 10000 });
            if (response.data && Array.isArray(response.data.data)) {

                setColonias(response.data.data);

                setIsColoniaSelectDisabled(false);
            } else {
                setColonias([]);
                setIsColoniaSelectDisabled(true);
            }
        } catch (error) {
            console.error("Error al obtener las colonias:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }

            setColonias([]);
            setIsColoniaSelectDisabled(true);
        }
    };

    const camposHabilitados = formularioDireccion.cp.length > 0;

    const selectOcolonias = colonias.map(colonia => ({
        value: `${colonia.text}`,
        label: `${colonia.text}`
    }));


    //estyado con oddo
    const EstadoConodoo = async () => {
        try {
            const response = await axios.get(API_URL + '/getStatesMexico');
            setEstados(response.data.success); // Asumiendo que response.data es un array de estados
        } catch (error) {
            console.error("Error al obtener los estados: ", error);
        }
    };

    const selectEstado = estados.map(estado => ({
        value: estado.id, // Ajusta estas propiedades según la estructura de tus datos
        label: estado.name
    }));

    const handleEstadoChange = (selectedOption) => {
        // convertir selectedOption en entero
        const valorComoEntero = parseInt(selectedOption.value, 10);
        setFormularioDireccion({ ...formularioDireccion, id_estado: valorComoEntero, estado: selectedOption.label });
    };

    useEffect(() => {
        EstadoConodoo()
        const handler = setTimeout(() => {
            if (formularioDireccion.cp) {
                handlePostalCodeChange(formularioDireccion.cp);
            } else {
                // Si el CP se limpia, también limpiamos las colonias y reseteamos el select
                setColonias([]);
                setFormularioDireccion({ ...formularioDireccion, colonia: '' }); // Asegúrate de limpiar la colonia seleccionada
                setIsColoniaSelectDisabled(true); // Deshabilitamos el select hasta que haya un CP válido
            }
        }, 500);

        return () => clearTimeout(handler);
    }, [formularioDireccion.cp]);

    const handleGuardarDireccion = async () => {
        // Verifica que los campos requeridos estén llenos
        if (!formularioDireccion.nombre_tienda || !formularioDireccion.calle ||
            !formularioDireccion.colonia || !formularioDireccion.cp ||
            !formularioDireccion.ciudad || !formularioDireccion.id_estado ||
            !formularioDireccion.pais || !formularioDireccion.telefono) {
            toast.error("Todos los campos necesarios deben estar llenos.");
            return;
        }

        try {
            setLoading(true); // Iniciar el indicador de carga
            const response = await axios.post(API_URL + '/setAddressStore', formularioDireccion, {
                headers: { 'Authorization': info['$tk3m09s'] }
            });

            //   console.log("Respuesta al guardar la dirección:", response.data);
            setLoading(false); // Detener el indicador de carga
            toast.success("Dirección guardada con éxito.");
        } catch (error) {
            console.error("Error al guardar la dirección:", error);
            setLoading(false); // Detener el indicador de carga
            toast.error("Ha ocurrido un error al guardar la dirección. Vuelve a intentarlo.");
        }
    };


    return (
        <div className=''>

            {/* <SidebarAdmi /> */}
            <Sidebar />
            <div className='p-4 sm:ml-64'>
                <div className='flex justify-between mb-10'>
                    <div className='flex items-center'>
                        <Link className='pr-5' to={'/lista-Clientes'}><i className="fa-solid fa-arrow-left"></i></Link>
                        <p className='font-semibold  text-xl'>Nuevo Distribuidor</p>
                    </div>

                </div>

                <div className=' '>
                    <h2 className='text-lg ml-2'>Ingresa los datos para agregar un nuevo distribuidor</h2>
                    <div className='w-full'>
                        <InputDos
                            label="Nombre de tienda *"
                            name="store_name"
                            type="text"
                            value={formulario.store_name}
                            onChange={handleChange}
                            placeholder="Ejemplo: Tienda 2049"
                        />


                    </div>
                    <div className='w-full'>
                        <InputDos
                            label="Número de distribuidor *"
                            name="num_dist"
                            type="number"
                            value={formulario.num_dist}
                            onChange={handleChange}
                            placeholder="Número de distribuidor"
                        />


                    </div>
                    <div className='w-full'>
                        <InputDos
                            label="Correo de distribuidor *"
                            name="correo"
                            type="email"
                            value={formulario.correo}
                            onChange={handleChange}
                            placeholder="Correo electronico "
                        />


                    </div>
                    {!isSaved ? (
                        <div className=' w-1/3 flex items-center justify-center py-4 mt-1 ml-2'>
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"

                                id='savedistr'
                                name='savedistr'
                                onClick={handleSave}>
                                Guardar
                            </button>
                        </div>
                    ) : (
                        <div className=' mt-4 px-2'>



                            <span className="flex items-center">
                                <span className="h-px flex-1 bg-black"></span>
                                <span className="shrink-0 px-6">
                                    Agregar dirección de tienda
                                </span>
                                <span className="h-px flex-1 bg-black"></span>
                            </span>
                            {/*nombre y codicgo postalx */}
                            <div className='w-full  flex mt-2'>
                                <div className="flex w-1/2 flex  flex-col px-2">  {/* Ajusta esta div para ocupar la mitad del espacio */}
                                    <p className='text-xs mb-1 lg:text-sm'>
                                        Nombre de quien recibe
                                    </p>
                                    <input
                                        label="Nombre de quien recibe"
                                        name="contact_name"
                                        type="text"
                                        value={formularioDireccion.contact_name}
                                        onChange={handleChangeDirecion}
                                        placeholder="Nombre"
                                        className='w-full md:w-4/5 lg:w-3/5 rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                                    />
                                    <p className='mt-1 text-xs text-gray-800'>* Tal como aparece en tu INE o IFE</p>

                                </div>

                                <div className=" w-1/2  flex flex-col justify-start px-2">  {/* Ajusta esta div para ocupar la mitad del espacio */}
                                    <label htmlFor="cp" className='text-xs lg:text-sm'>Código Postal</label>
                                    <input
                                        id="cp"
                                        name="cp"
                                        type="number"
                                        className='mt-1 w-3/5 rounded h-14 px-2 border border-gray-300' // Asegúrate de que el input ocupe el tamaño correcto
                                        placeholder="Código Postal"
                                        value={formularioDireccion.cp}
                                        onChange={handleChangeDirecion}
                                    />
                                </div>
                            </div>
                            {/*Colonia y ciudad*/}
                            <div className='w-full flex mt-2'>
                                <div className="flex w-1/2 flex-col ">
                                    <p className='text-xs mb-1 lg:text-sm'>
                                        Colonia
                                    </p>
                                    <Select
                                        disabled={!camposHabilitados}
                                        options={selectOcolonias}
                                        value={selectOcolonias.find(option => option.value === formularioDireccion.colonia)}
                                        onChange={handleColoniaChange}
                                        placeholder="Seleccione"
                                        menuPlacement="auto" // o "top" según tu necesidad de UI
                                        styles={customStyles}
                                    />
                                </div>

                                <div className="w-1/2 flex flex-col px-2">
                                    <label htmlFor="ciudad" className='text-xs lg:text-sm'>
                                        Ciudad
                                    </label>
                                    <input
                                        id="ciudad"
                                        name="ciudad"
                                        type="text"
                                        value={formularioDireccion.ciudad}
                                        onChange={handleChangeDirecion}
                                        placeholder="Ciudad"
                                        disabled={!camposHabilitados}
                                        className='mt-1 w-3/5 md:w-3/5 lg:w-3/5 rounded h-14 px-2 border border-gray-300' // Asegurarse de que el input tenga el mismo tamaño y estilo
                                    />
                                </div>
                            </div>
                            {/*estado y pais*/}

                            <div className='w-full  flex mt-2'>
                                <div className="flex w-1/2 flex-col ">
                                    <p className='text-xs mb-1 lg:text-sm'>
                                        Colonia
                                    </p>
                                    <Select
                                        disabled={!camposHabilitados}
                                        options={selectEstado}
                                        value={selectEstado.find(option => option.value === formularioDireccion.id_estado)} // Asegúrate de mostrar la opción seleccionada como el valor actual
                                        onChange={handleEstadoChange} // Usar la función de manejo específica para Select
                                        placeholder="Seleccione"
                                        menuPlacement="auto" // o "top" según tu necesidad de UI
                                        styles={customStyles}
                                    />
                                </div>

                                <div className=" w-1/2  flex flex-col justify-start px-2">  {/* Ajusta esta div para ocupar la mitad del espacio */}
                                    <label htmlFor="cp" className='text-xs lg:text-sm'>Pais</label>
                                    <input
                                        disabled={!camposHabilitados}
                                        type="text"
                                        label='Pais'
                                        name="pais"
                                        className='mt-1 w-3/5 rounded h-14 px-2 border border-gray-300' // Asegúrate de que el input ocupe el tamaño correcto
                                        placeholder="País"
                                        value={formularioDireccion.pais}
                                        onChange={handleChangeDirecion}
                                    />
                                </div>
                            </div>
                            {/*direccion y numeros */}
                            <div className='w-full flex mt-4'>
                                {/* Div para Dirección */}
                                <div className="w-1/2 flex flex-col px-2">
                                    <label htmlFor="calle" className='text-xs lg:text-sm'>Dirección</label>
                                    <input
                                        id="calle"
                                        name="calle"
                                        type="text"
                                        value={formularioDireccion.calle}
                                        onChange={handleChangeDirecion}
                                        placeholder="Dirección"
                                        className='mt-1 w-3/5 rounded h-14 px-2 border border-gray-300'
                                        disabled={!camposHabilitados}
                                    />
                                </div>

                                {/* Div para Número interior y Número exterior */}
                                <div className="w-1/2 flex">
                                    <div className="w-1/2 flex flex-col px-2">
                                        <label htmlFor="noInterior" className='text-xs lg:text-sm'>Número Interior</label>
                                        <input
                                            id="noInterior"
                                            name="noInterior"
                                            type="number"
                                            value={formularioDireccion.noInterior}
                                            onChange={handleChangeDirecion}
                                            placeholder="Num. Interior"
                                            className='mt-1 w-4/5 rounded h-14 px-2 border border-gray-300'
                                            disabled={!camposHabilitados}
                                        />
                                    </div>
                                    <div className="w-1/2 flex flex-col px-2">
                                        <label htmlFor="noExterior" className='text-xs lg:text-sm'>Número Exterior</label>
                                        <input
                                            id="noExterior"
                                            name="noExterior"
                                            type="number"
                                            value={formularioDireccion.noExterior}
                                            onChange={handleChangeDirecion}
                                            placeholder="Num. Exterior"
                                            className='mt-1 w-4/5 rounded h-14 px-2 border border-gray-300'
                                            disabled={!camposHabilitados}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*telefono y referencia  */}
                            <div className='w-full flex mt-2'>
                                {/* Div para Teléfono */}
                                <div className="flex w-1/2 flex-col px-2">
                                    <label htmlFor="telefono" className='text-xs mb-1 lg:text-sm'>
                                        Teléfono
                                    </label>
                                    <input
                                        id="telefono"
                                        name="telefono"
                                        type="text"
                                        value={formularioDireccion.telefono}
                                        onChange={handleChangeDirecion}
                                        placeholder="Teléfono"
                                        className='w-full md:w-4/5 lg:w-3/5 rounded h-14 px-2 border border-gray-300'
                                    />
                                </div>

                                {/* Div para Referencia */}
                                <div className="w-1/2 flex flex-col px-2">
                                    <label htmlFor="referencia" className='text-xs mb-1 lg:text-sm'>
                                        Referencia
                                    </label>
                                    <input
                                        id="referencia"
                                        name="referencia"
                                        type="text"
                                        value={formularioDireccion.referencia}
                                        onChange={handleChangeDirecion}
                                        placeholder="Referencia"
                                        className='w-full md:w-4/5 lg:w-3/5 rounded h-14 px-2 border border-gray-300'
                                    />
                                </div>
                            </div>

                            <div className='flex justify-center my-10'>
                                <button className='bg-[#e9bf56] py-2 px-10'
                                
                                id='savedir'
                                name='savedir'
                                onClick={handleGuardarDireccion}>
                                    Guardar direccion
                                </button>
                            </div>
                        </div>
                    )}

                </div>

            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

        </div>
    );
}

export default AddDistribuidor;
