import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../FormHeader';
import DualButton from '../DualButton';
import CodigoInput from './CodigoInput';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../constants/constants';

function ValidateCode({correo}) {
   
    const [codigo, setCodigo] = useState('');
    const navigate = useNavigate();
    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
    }

    const handleCancel = () => {
        navigate(-1);
    };


    const handleConfirm = async () => {

        const body = JSON.stringify({ email: correo, code: codigo});
        try {
            const response = await fetch(API_URL+'/validateCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });
            if (!response.ok) {
                // alert('Credenciales incorrectas');
                // throw new Error(`Error: ${response.status}`);
                toastError('El código  proporcionado es incorrecto');
            }else{
                navigate(`/resetpassword/${correo}`)
            }
            const data = await response.json();
            
        //    console.log(data);
        } catch (error) {
            console.error('El código  proporcionado es incorrecto: ', error);
            toastError('El código  proporcionado es incorrecto.');
        }
    };

    const handleCodigoChange = (nuevoCodigo) => {
        setCodigo(nuevoCodigo);
    };
    const desc ="Ingresa el código  que se envio al correo "+ correo
    return (

        <div className="mt-10 h-96">
            <FormHeader
                title="Valida tu código "
                description= {desc}
            />

            <CodigoInput onCodigoChange={handleCodigoChange}/>

            <DualButton
                leftButtonText="Cancelar"
                rightButtonText="Validar Código "
                onLeftButtonClick={handleCancel}
                onRightButtonClick={handleConfirm}
            />
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            />
        </div>

    );
}

export default ValidateCode;
