import Acordeon from "./Acordeon";
import Logo from "../../assets/LogoBlanco.png";
import imgTarjetas from "../../assets/tarjetas.webp";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const currentPageUrl = window.location.origin + location.pathname;

  const whatsappMessage = `Hola, Estaba navegando en ${currentPageUrl}, me podrías dar detalles sobre sus productos`;
  const whatsappLink = `https://api.whatsapp.com/send?phone=525593311600&text=${encodeURIComponent(
    whatsappMessage
  )}`;

  const menu_items = [
    {
      title: "Lineas Oficiales",
      items: [
        { name: "55 5989 2720", link: "525559892720", agente: "Carolina" }, //, Zona Occidente y centronorte
        { name: "775 135 1593", link: "527751351593", agente: "Natalia" }, //, Zona línea Noroeste y noreste
        { name: "775 7525 430", link: "527757525430", agente: "Arturo" }, // , Zona línea centrosur y oriente
        { name: "999 5200 267", link: "9995200267", agente: null }, // , Nueva linea
      ],
    },
    {
      title: "Productos",
      items: [
        { name: "Catálogo", link: "/allProducts" },
        { name: "Llantera", link: "/etiquetas/Llantera" },
        {
          name: "Hojalatería y Pintura",
          link: "/etiquetas/Hojalatería%20y%20Pintura",
        },
        { name: "Taller Mecánico", link: "/etiquetas/Taller%20Mecánico" },
        { name: "Industria", link: "/etiquetas/Industria" },
      ],
    },
    {
      title: "Conoce Hantec",
      items: [
        { name: "¿Quiénes Somos?", link: "/Conocenos" },
        { name: "Contacto", link: "/Contact" },
        { name: "Venta en línea", link: "/Conocenos" },
      ],
    },
    {
      title: "Soporte",
      items: [{ name: "Soporte Técnico", link: "/contact" }],
    },
    {
      title: "Legales",
      items: [
        { name: "Términos y Condiciones", link: "/terminos-y-condiciones/1" },
        {
          name: "Política de Devoluciones",
          link: "/terminos-y-condiciones/12",
        },
        { name: "Políticas de Envío", link: "/terminos-y-condiciones/11" },
        { name: "Aviso de Privacidad", link: "/aviso-de-privacidad" },
      ],
    },
  ];

  const handleItemClick = (link, agente) => {
    //   console.log('ekeje',agente)
    let whatsappMessageAgente = `Hola, ${agente} estaba navegando en hantec.us, me podrías dar detalles sobre sus productos`;
    if (agente == null) {
      whatsappMessageAgente = `Hola, estaba navegando en hantec.us, me podrías dar detalles sobre sus productos`;
    }
    const whatsappLink = `https://api.whatsapp.com/send?phone=${link}&text=${encodeURIComponent(
      whatsappMessageAgente
    )}`;
    window.location.href = whatsappLink;
  };

  return (
    <div>
      <div className="bg-[#e4e6eb] mt-10  flex justify-center items-center p-5">
        <img
          width="300"
          height="200"
          loading="lazy"
          src={imgTarjetas}
          alt="Logo de Hantec"
          className="w-full lg:w-2/4 h-auto "
        />
      </div>
      <footer className="bg-[#1a2869] ">
        <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <div className="text-white">
                <img
                  width="300"
                  height="200"
                  loading="lazy"
                  src={Logo}
                  alt="Logo de Hantec"
                  className="w-56 lg:w-4/5 h-auto mb-1"
                />
              </div>
              <div className=" flex justify-center  mt-4 items-center bg-black rounded-md ">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14989.042317202959!2d-98.7278971!3d20.0813912!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1a1315c48bdf7%3A0x131d0198611748c4!2sAgencia%20Hantec%20Plaza%20Q!5e0!3m2!1ses-419!2smx!4v1706642979279!5m2!1ses-419!2smx"
                  className="w-full  h-56  rounded"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="framemaps"
                ></iframe>
              </div>
              <a
                href="https://maps.app.goo.gl/1HxKL4wJTuQdai2cA"
                target="_blank"
                className="mt-4 max-w-xs text-white font-montserrat text-base"
              >
                Blvrd Luis Donaldo Colosio, Colinas de Plata, 42186 Pachuca de
                Soto, Hgo.
              </a>
              <div className="lg:hidden">
                <h1 className="text-white font-bold mb-5 md:text-xl mt-4 font-oswald">
                  NECESITAS AYUDA
                </h1>
                <div className="flex justify-center items-center w-full">
                  <button
                    name="btn-callsell"
                    id="btn-callsell"
                    className="text-blue bg-yellow-400  font-semibold  hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                    onClick={() => (window.location.href = "tel:+525593311600")}
                  >
                    <i className="fas fa-phone mr-1 font-semibold text-xl mr-2"></i>
                    Compra en línea 55 9331 1665
                  </button>
                </div>
                <div className="text-center">
                  <h1 className="text-white mb-3 md:text-xl mt-2 font-oswald">
                    O manda un WhatsApp
                  </h1>
                </div>
                <div className="flex justify-center items-center w-full">
                  <button
                    name="btn-callbuy"
                    id="btn-callbuy"
                    className="text-blue bg-yellow-400 hover:bg-yellow-500  font-semibold focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                    onClick={() => (window.location.href = whatsappLink)}
                  >
                    <i className="fab fa-whatsapp  font-semibold text-xl mr-2"></i>
                    Ventas 55 9331 1600
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden lg:grid lg:grid-cols-1 lg:gap-8 lg:col-span-2 lg:grid-cols-4">
              {menu_items.slice(1, 5).map((section) => (
                <div key={section.title}>
                  <p className="font-medium text-white font-oswald text-lg uppercase">
                    {section.title}
                  </p>
                  <ul className="mt-4 space-y-4 text-sm">
                    {section.items.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.link}
                          className="text-[#b9babd] transition hover:text-white"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="lg:hidden">
              {menu_items.map((section, index) => (
                <Acordeon
                  key={index}
                  title={section.title}
                  items={section.items}
                />
              ))}
            </div>
          </div>
          <div className="hidden lg:flex  justify-between">
            <div>
              <h1 className="text-white font-bold mb-5 md:text-xl mt-4 font-oswald">
                NECESITAS AYUDA
              </h1>
              <div className="flex justify-center items-center w-full">
                <button
                  name="btn-callsell"
                  id="btn-callsell"
                  className="text-blue bg-yellow-400  font-semibold  hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                  onClick={() => (window.location.href = "tel:+525593311600")}
                >
                  <i className="fas fa-phone mr-1 font-semibold text-xl mr-2"></i>
                  Compra en línea 55 9331 1665
                </button>
              </div>
              <div className="text-center">
                <h1 className="text-white mb-3 md:text-xl mt-2 font-oswald">
                  O manda un WhatsApp
                </h1>
              </div>
              <div className="flex justify-center items-center w-full">
                <button
                  name="btn-callbuy"
                  id="btn-callbuy"
                  className="text-blue bg-yellow-400 hover:bg-yellow-500  font-semibold focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                  onClick={() => (window.location.href = whatsappLink)}
                >
                  <i className="fab fa-whatsapp  font-semibold text-xl mr-2"></i>
                  Ventas 55 9331 1600
                </button>
              </div>
            </div>
            {/* Lineas */}
            <div className="text-center">
              <span className="text-center w-full text-white font-bold mb-5 md:text-2xl mt-4 font-oswald">
                Lineas Oficiales
              </span>
              <div className="lg:flex justify-around m-5">
                <div>
                  {/* <span className='text-white font-bold mb-5 md:text-xl mt-4 font-oswald'>Carolina</span> */}
                  <div className="flex justify-center items-center w-full">
                    <button
                      name="btn-callbuy"
                      id="btn-callbuy"
                      className="text-blue bg-yellow-400 hover:bg-yellow-500  font-semibold focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                      onClick={() =>
                        handleItemClick("525559892720", "Carolina")
                      }
                    >
                      <i className="fab fa-whatsapp  font-semibold text-xl mr-2"></i>
                      Línea 55 5989 2720
                    </button>
                  </div>
                  {/* <h1 className='text-white mb-3 md:text-xl mt-1 font-oswald'>Occidente y centronorte</h1> */}
                </div>
                <div>
                  {/* <h1 className='text-white font-bold mb-5 md:text-xl mt-4 font-oswald'>Natalia</h1> */}
                  <div className="flex justify-center items-center w-full">
                    <button
                      name="btn-callbuy"
                      id="btn-callbuy"
                      className="text-blue bg-yellow-400 hover:bg-yellow-500  font-semibold focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                      onClick={() => handleItemClick("527751351593", "Natalia")}
                    >
                      <i className="fab fa-whatsapp  font-semibold text-xl mr-2"></i>
                      Línea 775 135 1593
                    </button>
                  </div>
                  {/* <h1 className='text-white mb-3 md:text-xl mt-1 font-oswald'>Lineas Oficiales</h1>  */}
                  {/* Noroeste y noreste */}
                </div>
                <div>
                  {/* <h1 className='text-white font-bold mb-5 md:text-xl mt-4 font-oswald'>Arturo</h1> */}
                  <div className="flex justify-center items-center w-full">
                    <button
                      name="btn-callbuy"
                      id="btn-callbuy"
                      className="text-blue bg-yellow-400 hover:bg-yellow-500  font-semibold focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                      onClick={() => handleItemClick("527757525430", "Arturo")}
                    >
                      <i className="fab fa-whatsapp  font-semibold text-xl mr-2"></i>
                      Línea 775 7525 430
                    </button>
                  </div>
                  {/* <h1 className='text-white mb-3 md:text-xl mt-1 font-oswald'>Centrosur y oriente</h1> */}
                </div>
                <div>
                  {/* <h1 className='text-white font-bold mb-5 md:text-xl mt-4 font-oswald'>Arturo</h1> */}
                  <div className="flex justify-center items-center w-full">
                    <button
                      name="btn-callbuy"
                      id="btn-callbuy"
                      className="text-blue bg-yellow-400 hover:bg-yellow-500  font-semibold focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded text-sm px-5 py-2.5 text-center w-full me-2 mb-2 dark:focus:ring-yellow-900"
                      onClick={() => handleItemClick("9995200267", null)}
                    >
                      <i className="fab fa-whatsapp  font-semibold text-xl mr-2"></i>
                      Línea 999 5200 267
                    </button>
                  </div>
                  {/* <h1 className='text-white mb-3 md:text-xl mt-1 font-oswald'>Centrosur y oriente</h1> */}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-center lg:justify-end">
              <a
                href="https://www.facebook.com/InnovacionHantec/"
                className="mx-1"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visita nuestra página de Facebook"
              >
                <i className="fab fa-facebook-square text-white text-xl md:text-3xl"></i>
              </a>
              <a
                href="https://www.instagram.com/hantec.herramientas/"
                className="mx-1"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visita nuestra página de Instagram"
              >
                <i className="fab fa-instagram mx-3 text-white text-xl md:text-3xl md:mx-5"></i>
              </a>
              <a
                href="https://youtube.com/@hantecinnovacionautomotriz7670?feature=shared"
                className="mx-1"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visita nuestro canal de YouTube"
              >
                <i className="fab fa-youtube mr-3 text-white text-xl md:text-3xl md:mr-5"></i>
              </a>
              <a
                href="https://www.tiktok.com/@hantec_innovacion?lang=es"
                className="mx-1"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visita nuestra página de TikTok"
              >
                <i className="fab fa-tiktok text-white text-xl md:text-3xl"></i>
              </a>
            </div>
            <p className="mt-2 text-sm xl:text-base text-center text-white lg:text-right">
              &copy; 2023, Hantec
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
