import ProductoCard from '../productos/Card'
import MoonLoader from "react-spinners/MoonLoader";

function ProductDetails({ products, nameProduct, setNameProduct, descriptionProduct, setDescriptionProduct, handlePostProduct, sku, setSku, marca, setMarca, model, setModel, loading }) {
    return (
        <div className='relative mb-10'>

            <div className='bg-gray-100 sm:w-3/5 xl:w-3/5 mr-10 py-10 px-5 rounded shadow-xl'>
                <p className='text-xl mb-5 font-semibold text-gray-700'>Detalles del producto</p>

                <div className='flex flex-col mb-5'>
                    <label htmlFor="nombre_producto">Nombre del producto</label>
                    <input type="text" className='rounded p-1 border border-slate-300 hover:border-indigo-300' placeholder='Ingresa el nombre del producto' value={nameProduct} onChange={(event) => setNameProduct(event.target.value)} />
                </div>

                <div className='flex flex-col mb-5'>
                    <label htmlFor="descripcion_producto">Descripción del producto</label>
                    {/* <input type="text" className='rounded' /> */}
                    <textarea
                        onChange={(event) => setDescriptionProduct(event.target.value)} value={descriptionProduct}
                        className='rounded p-1 border border-slate-300 hover:border-indigo-300' name="descripcion_producto" id="descripcion_producto" cols="30" rows="10" placeholder='Ingresa una descripción para el producto.'></textarea>
                </div>

                <div className='flex flex-col mb-5'>
                    <label htmlFor="nombre_producto">SKU</label>
                    <input type="text" value={sku}
                        className='rounded p-1 border border-slate-300 hover:border-indigo-300' placeholder='Ingresa el sku del producto' onChange={(event) => setSku(event.target.value)} />
                </div>

                <div className='flex flex-col mb-5'>
                    <label htmlFor="nombre_producto">Marca</label>
                    <input type="text" value={marca}
                        className='rounded p-1 border border-slate-300 hover:border-indigo-300' placeholder='Ingresa la marca del producto' onChange={(event) => setMarca(event.target.value)} />
                </div>

                <div className='flex flex-col mb-5'>
                    <label htmlFor="nombre_producto">Modelo</label>
                    <input type="text" value={model}
                        className='rounded p-1 border border-slate-300 hover:border-indigo-300' placeholder='Ingresa el modelo del producto' onChange={(event) => setModel(event.target.value)} />
                </div>
            </div>

            {/* Previsualización */}
            <div className='fixed top-10 right-5 bg-gray-100 px-1 flex flex-col items-center justify-center sm:w-1/5 xl:w-1/5 rounded'>
                <p className='text-center font-semibold text-gray-700'>Previsualización</p>
                <ProductoCard producto={products} />
                {!loading
                    ? <div className='flex justify-center'>
                        <button
                            name='btnpublicarp'
                            id='btnpublicarp'
                            className='bg-green-700 text-white rounded p-1 my-5' onClick={handlePostProduct}>Publicar producto</button>
                    </div>
                    : (
                        <div className="flex justify-center items-center m-5">
                            {/* <!-- Spinner --> */}
                            <MoonLoader color="#1A2869"
                                size={50}
                                speedMultiplier={1}
                                width={2.5}
                            />
                        </div>
                    )

                }
            </div>


        </div>
    )
}

export default ProductDetails