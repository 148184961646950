
import React from 'react';

function ComponenetesCombo({ listaImagenesTransformada }) {
    return (
        <div className='componente'>
            <section className="overflow-hidden">
                <div className="container px-5 py-2 mx-auto">
                    <div className="lg:w-8/6 mx-auto flex flex-wrap border-t-4 border-t border-gray-200 ">
                        <div className="w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                            <div className="mt-1 mb-2">
                                <div className='flex'>
                                    <h2 className='text-[#030303] text-base font-medium ml-2 font-semibold text-base lg:text-3xl font-robotoch'>Detalles</h2>
                                    <h2 className='text-[#030303] text-base ml-2 font-semibold text-base lg:text-3xl font-robotoch'>del Producto</h2>
                                </div>
                                <div className="text-sm text-gray-600">
                                    {/* <p>Aquí va la descripción del combo</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="flex flex-wrap justify-center lg:w-4/5 mx-auto items-center md:grid md:grid-cols-2 md:gap-4 ">
                {listaImagenesTransformada.map((imagen) => (
                    <div key={imagen.id_producto} className="mt-2 px-5 flex items-center justify-center">
                        <img width="300" height="200"
 src={imagen.imagen} alt={imagen.titulo} className="lg:w-3/4 w-full lg:h-auto h-auto object-contain object-center rounded" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ComponenetesCombo;
