import { useState } from "react";
import { X, ChevronDown } from "lucide-react";
import axios from "axios";
import { API_URL } from "../../constants/constants";

export default function HantecPopup({
  isOpen,
  setIsOpen,
  toastSuccess,
  toastError,
}) {
  const [selectedCountryCode, setSelectedCountryCode] = useState("+52");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const countryCodes = ["+52", "+1", "+34", "+57", "+51"];

  if (!isOpen) return null;

  if (window.localStorage.getItem("userSuscribed") == "true") return null;

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    try {
      const res = await axios.post(API_URL+ "/registerUserBlog",
        formData
      );
      toastSuccess("Se ha suscrito exitosamente.");
      setFormData({
        name: "",
        email: "",
        phone: "",
      });      
      setIsOpen(!isOpen);
      window.localStorage.setItem("userSuscribed", "true");
    } catch (error) {
      toastError(error?.response?.data?.message || "Ha ocurrido un error.");
    }
  };

  const handleFormData = (e) => {
    let name = e.target.name;
    setFormData((prevData) => ({
      ...prevData,
      [name]: e.target.value,
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-1 md:p-4 z-50">
      <div className="bg-white rounded-2xl shadow-xl max-w-md w-full relative overflow-hidden">
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
          aria-label="Cerrar"
        >
          <X className="w-6 h-6" />
        </button>
        <div className="p-4 md:p-8">
          <h2 className="text-xl md:text-3xl font-bold mb-4 text-gray-900">
            ¡Potencia tus ventas y conviértete en un experto con Hantec!
          </h2>
          <p className="text-gray-600 mb-6">
            Regístrate para recibir contenido exclusivo sobre nuestros productos
            y técnicas de venta. ¡Sé el primero en enterarte de nuestras
            novedades!
          </p>
          <form className="space-y-4" onSubmit={handleRegister}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Nombre
              </label>
              <input
                value={formData.name}
                onChange={handleFormData}
                type="text"
                id="name"
                name="name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 transition-colors"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleFormData}
                value={formData.email}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 transition-colors"
                required
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Número de teléfono
              </label>
              <div className="flex">
                <div className="relative">
                  <select
                    value={selectedCountryCode}
                    onChange={(e) => setSelectedCountryCode(e.target.value)}
                    className="appearance-none bg-gray-100 border border-gray-300 rounded-l-md px-3 py-2 pr-8 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  >
                    {countryCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" />
                </div>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={handleFormData}
                  value={formData.phone}
                  className="flex-1 px-3 py-2 border border-l-0 border-gray-300 rounded-r-md shadow-sm focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  required
                />
              </div>
            </div>
            <div className="flex items-start">
              <input
                id="terms"
                name="terms"
                type="checkbox"
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                required
              />
              <label
                htmlFor="terms"
                className="ml-2 block text-sm text-gray-900"
              >
                Acepto los{" "}
                <a
                  href="/terminos-y-condiciones/1"
                  className="text-blue-600 hover:underline"
                >
                  términos y condiciones
                </a>
              </label>
            </div>
            <p className="text-xs text-gray-500 mt-2">
              Al registrarte, aceptas que Hantec te envíe comunicaciones por
              correo electrónico y teléfono. Puedes cancelar la suscripción en
              cualquier momento.
            </p>
            {errorMessage && (
              <p className="text-red-600 underline">{errorMessage}</p>
            )}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!acceptTerms}
            >
              Quiero aprender más
            </button>
          </form>
          <p className="text-xs text-gray-500 mt-4">
            Al registrarte, recibirás notificaciones sobre nuevos productos,
            técnicas de venta y contenido exclusivo diseñado para potenciar tus
            ventas.
          </p>
        </div>
      </div>
    </div>
  );
}
