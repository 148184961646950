import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import ModalGraficos from './ModalGracios';

const TasaReboteGrafica = ({ datosGrafica, title }) => {
  const labels = datosGrafica.fechas;
  const [showModal, setShowModal] = useState(false);

  const contadorData = labels.map(fecha => datosGrafica.tasaRebote[fecha]?.contador || 0);
  const porcentajeData = labels.map(fecha => datosGrafica.tasaRebote[fecha]?.porcentaje || 0);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Contador',
        data: contadorData,
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Porcentaje',
        data: porcentajeData,
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        yAxisID: 'y-axis-2',
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <div>
      <div className="flex grid grid-flow-col px-4 justify-between mx-auto items-center">
        <h2 className="text-center text-base font-semibold">
          {title}
        </h2>
        <button
           name='btn-seemore4'
          id='btn-seemore4'
          onClick={() => setShowModal(true)}
          className="ml-4 px-2 py-1 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <i className="fas fa-search-plus"></i>
        </button>
      </div>
      <Line data={data} options={options} />

      <ModalGraficos show={showModal} onClose={() => setShowModal(false)}>
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-11/12 h-5/6">
            <Line data={data} options={options} />
          </div>
        </div>
      </ModalGraficos>
    </div>
  );
};

export default TasaReboteGrafica;