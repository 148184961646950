import React from 'react';

const ModalGraficos = ({ show, onClose, children }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded shadow-lg w-full max-w-4xl">
                <div className="flex justify-end">
                    <button
                        name='btn-closegrafics'
                        id='btn-closegrafics'
                        onClick={onClose} className="text-red-500 text-3xl hover:text-red-700">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className="mt-2">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalGraficos;
