export default function ProductCard({product}) {
    return (
      <div className="flex flex-col items-center">
        <img
          src={product?.imagen}
          alt={product?.product_name}
          className="h-auto w-24"
          // width="200"
          // height="200"
          loading="lazy"
          // className="w-full rounded-md object-cover"
          // style="aspect-ratio:200/200;object-fit:cover"
          // style={{aspectRatio:200/200, objectFit:'cover'}}
        />
        {/* <h3 className="text-lg text-center font-medium mt-2">$ {product?.precio?.cantidad.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN</h3> */}
        <a href={product?.href} rel="noopener noreferrer" target="_blank" className="bg-[#facc15] text-[#1a2869] hover:bg-[#facc15]/70 inline-flex items-center justify-center whitespace-nowrap text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-9 rounded-md px-3 mt-2">
          Comprar ahora
        </a>
      </div>
    )
  }
  

  export function ProductCardAux({product}) {
    return (
      <div className="col-span-1 flex flex-col items-center mr-5">
        <img
          src={product?.imagen}
          alt={product?.product_name}
          width="200"
          height="200"
          loading="lazy"
          className="w-full rounded-md object-cover"
          // style="aspect-ratio:200/200;object-fit:cover"
          style={{aspectRatio:200/200, objectFit:'cover'}}
        />
        <h3 className="text-lg font-medium mt-2">{product?.product_name}</h3>
        <a href={product?.href} rel="noopener noreferrer" target="_blank" className="bg-[#facc15] text-[#1a2869] hover:bg-[#facc15]/70 inline-flex items-center justify-center whitespace-nowrap text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-9 rounded-md px-3 mt-2">
          Comprar ahora
        </a>
      </div>
    )
  }