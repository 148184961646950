import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'


export function ProtectedRoute({children, toRoute='/'}) {
    const token = window.localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(window.localStorage.getItem('k-9SMD879na89dN87m')) : null
    
    //No existe token
    //Protege rutas donde es necesario el id del usuario o el token
    //Ejemplos: Perfil, Pedidos, Direcciones
    try {
        if (!token['$tk3m09s']) {
            return <Navigate to='/login' />
        }

    } catch (error) {
        return <Navigate to='/' />
    }

    return children ? children : <Outlet />
}


export function ProtectedRouteSession({children, toRoute}) {
    const token = window.localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(window.localStorage.getItem('k-9SMD879na89dN87m')) : null
    //Existe token
    //Protege rutas donde no es necesario que el usuario ingrese
    //Ejemplos: Login, Registro

    if (token) {
        return <Navigate to={toRoute} />
    }

    return children ? children : <Outlet />
}

export function ProtectedInterfazAdmi({children}) {
    const token = window.localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(window.localStorage.getItem('k-9SMD879na89dN87m')) : null
    if (!token) {
        // console.log('No tiene token')
        return <Navigate to='/' />
    }

    if (token['$r07sad'] !== 'imda') {
        if (token['$r07sad'] !== 'dkommita') {
            if (token['$r07sad'] !== 'order_viewer') {

                return <Navigate to='/' />
            }
        }
    }

    return children ? children : <Outlet />
}

export function ProtectedInterfazDistribuidor({children}) {
    const token = window.localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(window.localStorage.getItem('k-9SMD879na89dN87m')) : null
    
    if (!token) {
        // console.log('No tiene token')
        return <Navigate to='/' />
    }

    if (token['$r07sad'] !== 'Tienda') {
        return <Navigate to='/' />
    }

    return children ? children : <Outlet />
}