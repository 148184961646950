import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import FacturacionForAdmin from '../../componentes/Admi/pedidoscom/FacturacionForAdmin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Resumnpagoslist from '../../componentes/pagos/Resumnpagoslist';
import DireccionDatosForAdmin from '../../componentes/Admi/pedidoscom/DireccionDatosForAdmin';
import DatosExtraPay from '../../componentes/Admi/pedidoscom/DatosExtraPay';
import { API_URL, STATES } from '../../constants/constants';
import DatosExtraSeend from '../../componentes/Admi/pedidoscom/DatoExtraEnvio';
import Sidebar from '../../componentes/Admi/Sidebar';
import EstafetaProducts from '../../componentes/Admi/pedidoscom/EstafetaProducts';

const toastError = (msg) => {
    toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

const toastSuccess = (msg) => {
    toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

function AddPedido() {
    const location = useLocation();
    const { pedido } = location.state || { pedido: null };
    // console.log('los datos del pedido son:', pedido);
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const [isFocused, setIsFocused] = useState(false);
    const [showExtra, setShowExtra] = useState(false);
    const [showExtraEnvio, setShowExtraEnvio] = useState(false);


    const [loading, setLoading] = useState(false)
    const [loadingTicket, setLoadingTicket] = useState(false)
    const [loadingTrack, setLoadingTrack] = useState(false)


    const itemState = STATES.find(item => item.id === parseInt(pedido?.direccion_fiscal?.state_id))

    const fechaObjeto = new Date(pedido.created_at);
    const fechaFormateada = new Intl.DateTimeFormat('es-ES', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }).format(fechaObjeto);

    const [trackingNumber, setTrackingNumber] = useState(pedido?.tracking_number?.startsWith("{") ? "No se ha generado." : pedido?.tracking_number);

    function formatPhoneNumber(phoneNumber) {
        if (phoneNumber && phoneNumber.length === 10) {
            return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
        return "Teléfono no disponible";
    }

    const telefonoFormateado = formatPhoneNumber(pedido?.direccion_entrega?.telefono);

    const [selectedView, setSelectedView] = useState('resumen');


    const handleTrackingNumber = (event) => {
        setTrackingNumber(event.target.value)
    }



    const handleSaveTrackingNumber = async () => {
        try {
            // setLoadingTicket(true)

            const response = await fetch(`${API_URL}/updateTrackingNumber`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': info['$tk3m09s']
                },
                body: JSON.stringify({
                    "order_id": pedido?.id_pedido,
                    "sale_order_id": pedido?.no_orden_odoo,
                    "tracking_number": trackingNumber
                })
            });

            if (!response.ok) {
                toastError("Ha ocurrido un error al guardar el número de guia. Vuelve a intentarlo.")
                throw new Error('Network response was not ok');
            } else {
                toastSuccess("Se ha guardado correctamente el número de guia.")
            }
        } catch (error) {
            console.error('Error al generar al guardar el número de guia', error);
        } finally {
            // setLoadingTicket(false)
        }
    }

    const renderContent = () => {
        switch (selectedView) {
            case 'odoo':
                return <div className='bg-white border border-blue-900 shadow-lg w-4/5 mx-auto p-4 rounded '>


                    <p className='  shrink-0 px-6 font-black my-2 text-xl text-center text-blue-800'>
                        Datos de odoo
                    </p>
                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Nombre de la orden:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">
                            {pedido?.nombre_orden_odoo}
                        </h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Identificador de la orden:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">
                            {pedido?.no_orden_odoo === "None" ? "" : pedido?.no_orden_odoo}
                        </h2>
                    </div>
                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Timbrado:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">
                            {pedido?.timbrado ? "Si" : "No"}
                        </h2>
                    </div>

                </div>;
            case 'castores':
                return <div className='bg-white border border-blue-900 shadow-lg w-4/5 mx-auto p-4 rounded '>


                    <p className='  shrink-0 px-6 font-black my-2 text-2xl text-center text-blue-800'>
                        Datos de Envio
                    </p>

                    <p className='  shrink-0 px-6 font-black my-2 text-lg text-left text-black'>
                        Datos de castores
                    </p>
                    <div className="flex items-center justify-between w-full mt-2 px-6">
                        <p className="text-[#757575] text-md mr-2">No. guía:</p>
                        {info && info['$r07sad'] !== 'order_viewer' && (
                            <div>
                                <div className='relative'>
                                    <input className='text-[#012a45] text-lg font-semibold rounded'
                                        onChange={handleTrackingNumber}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                        type="text" name="trackingNumber" id="trackingNumber" value={trackingNumber} />
                                    <i className="fas fa-edit absolute right-1.5 bottom-2"></i>
                                </div>
                                <button
                                    className={`transition bg-[#1e3d8b] w-full rounded text-white hover:bg-[#007bfc] shadow-xl overflow-hidden transition-all duration-500 ${isFocused ? 'h-auto py-2 mt-1' : 'h-0 py-0'}`}
                                    onClick={handleSaveTrackingNumber}>
                                    Guardar
                                </button>
                            </div>
                        )}


                    </div>

                    <div>
                        {/* se carga la etiqueta para ver los datos si tiene prodcutos que se envien por estafeta*/}
                        <EstafetaProducts productos={pedido?.productos} />
                    </div>
                    
                </div>;
            case 'entrega':
                return <DireccionDatosForAdmin direccionEntrega={pedido?.direccion_entrega} ocurre={pedido?.ocurre} />;
            case 'facturacion':
                return <FacturacionForAdmin direccionFiscal={pedido?.direccion_fiscal} factura={pedido?.factura} />;
            case 'resumen':
                return <div className='w-4/5 mx-auto'>
                    <Resumnpagoslist listaCar={pedido.productos} />
                    <div>
                        <div className="flex items-center justify-between w-full  px-24 mt-2">
                            <p className="text-[#757575] text-md mr-2">Total de productos:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                ${pedido?.total_productos?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </h2>
                        </div>

                        <div className="flex items-center justify-between w-full  px-24  mt-2">
                            <p className="text-[#757575] text-md mr-2">Total del envío:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                ${pedido?.total_envio?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </h2>
                        </div>

                        <div className="flex items-center justify-between w-full  border-t-2 border-gray-200 px-24 py-2 mt-2">
                            <p className="text-[#757575] text-md mr-2">Total del pedido:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                ${pedido?.total?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </h2>
                        </div>
                    </div>

                </div>;
            default:
                return <p>Selecciona una opción</p>;
        }
    };

    const listItemClasses = "cursor-pointer border border-[#1a2869] my-4 w-2/3 mx-auto text-center py-3 rounded shadow-lg transition duration-300";
    const activeClasses = "bg-[#1a2869] text-white";


    //botnrezs  funciones 

    const handleGenerateTrack = async () => {
        try {
            setLoadingTrack(true)

            const response = await fetch(`${API_URL}/generateTicketCastores`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': info['$tk3m09s']
                },
                body: JSON.stringify(pedido)
            });
            // console.log("Response: ", response)

            if (!response.ok) {
                toastError("Ha ocurrido un error al generar la guia. Vuelve a intentarlo.")
                throw new Error('Network response was not ok');
            } else {
                toastSuccess("La guia ha sido generada correctamente y guardado en el drive.")
            }
        } catch (error) {
            console.error('Error al generar el ticket', error);
        } finally {
            setLoadingTrack(false)
        }
    }

    const handleGenerateTicket = async () => {
        try {
            setLoadingTicket(true)

            const response = await fetch(`${API_URL}/odoo/saveTicketPickingOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': info['$tk3m09s']
                },
                body: JSON.stringify({ "sale_order_id": pedido?.no_orden_odoo })
            });
            // console.log("Response: ", response)

            if (!response.ok) {
                toastError("Ha ocurrido un error al generar el ticket. Vuelve a intentarlo.")
                throw new Error('Network response was not ok');
            } else {
                toastSuccess("El ticket ha sido generado correctamente y guardado en el drive.")
            }
        } catch (error) {
            console.error('Error al generar el ticket', error);
        } finally {
            setLoadingTicket(false)
        }
    }

    //btna deacarga factura 
    const downloadPdf = async (invoice_id) => {
        try {
            setLoading(true)
            const response = await fetch(`${API_URL}/odoo/getPDFInvoice/${invoice_id}`, {
                method: 'GET',
            });

            if (!response.ok) {
                toastError("Ha ocurrido un error al descargar la factura. Vuelve a intentarlo.")
                throw new Error('Network response was not ok');
            }

            // Obtiene el blob del PDF
            const blob = await response.blob();

            // Crea un enlace virtual para descargar el archivo
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `factura_${invoice_id}.pdf`); // Usa el invoice_id en el nombre
            document.body.appendChild(link);
            link.click();

            // Limpia el recurso cuando ya no es necesario
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);

            // Mensaje de éxito
            if (response.ok) {
                toastSuccess("La factura se ha descargado correctamente.")
            }
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        } finally {
            setLoading(false)
        }
    };


    return (
        <div className=''>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            <div className='p-4 sm:ml-64'>
                <div className='pl-5 py-2 flex'>
                    <Link className='pr-5' to={'/list_orders'}><i className="fa-solid fa-arrow-left text-xl mt-1"></i></Link>
                    <h1 className='font-black uppercase text-2xl'>Detalles del pedido #{pedido?.id_pedido}</h1>
                </div>

                <div className="flex space-x-4">
                    <button
                        onClick={handleGenerateTrack}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        disabled={loadingTrack}
                    >
                        {loadingTrack ? 'Generando...' : 'Generar Guia'}
                    </button>
                    <button
                        onClick={handleGenerateTicket}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                        disabled={loadingTicket}
                    >
                        {loadingTicket ? 'Generando...' : 'Generar Ticket'}
                    </button>
                    {pedido.invoice_id && (
                        <button
                            onClick={() => downloadPdf(pedido.invoice_id)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            disabled={loading}
                        >
                            {loading ? 'Descargando...' : 'Descargar Factura'}
                        </button>
                    )}
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>

                    <div className='bg-white border border-blue-900 text-white rounded mb-5 mt-4 mr-5 py-10 px-5 shadow-xl w-full sm:w-2/2 lg:w-2/2 xl:w-3/3'>
                        <span className="flex items-center">
                            <span className="h-px flex-1 bg-blue-800"></span>
                            <span className="shrink-0 px-6 font-black my-2 text-center text-blue-800">Datos del cliente</span>
                            <span className="h-px flex-1 bg-blue-800"></span>
                        </span>
                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Nombre del cliente:</p>
                            <h2 className="text-[#012a45] text-base text-right font-semibold">
                                {pedido?.cliente}
                            </h2>
                        </div>
                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Correo de contacto:</p>
                            <h2 className="text-[#012a45] text-base text-right font-semibold truncate">
                                {pedido?.email}
                            </h2>
                        </div>
                        <span className="flex items-center">
                            <span className="h-px flex-1 bg-blue-800"></span>
                            <span className="shrink-0 px-6 font-black my-2 text-center text-blue-800">Dirección principal</span>
                            <span className="h-px flex-1 bg-blue-800"></span>
                        </span>
                        <div>
                            <div className="flex items-center justify-between w-full mt-2">
                                <p className="text-[#757575] text-md mr-2">Dirección:</p>
                                <h2 className="text-[#012a45] text-base text-right font-semibold">
                                    {pedido?.direccion_entrega?.calle}, #{pedido?.direccion_entrega?.noExterior}, {pedido?.direccion_entrega?.colonia}
                                </h2>
                            </div>
                            <div className="flex items-center justify-between w-full mt-2">
                                <p className="text-[#757575] text-md mr-2">Código postal:</p>
                                <h2 className="text-[#012a45] text-base text-right font-semibold">
                                    {pedido?.direccion_entrega?.cp}
                                </h2>
                            </div>
                            <div className="flex items-center justify-between w-full mt-2">
                                <p className="text-[#757575] text-md mr-2">Ciudad:</p>
                                <h2 className="text-[#012a45] text-base text-right font-semibold">
                                    {pedido?.direccion_entrega?.ciudad}
                                </h2>
                            </div>
                            <div className="flex items-center justify-between w-full mt-2">
                                <p className="text-[#757575] text-md mr-2">Estado:</p>
                                <h2 className="text-[#012a45] text-base text-right font-semibold">
                                    {pedido?.direccion_entrega?.estado}
                                </h2>
                            </div>
                            <div className="flex items-center justify-between w-full mt-2">
                                <p className="text-[#757575] text-md mr-2">País:</p>
                                <h2 className="text-[#012a45] text-base text-right font-semibold">
                                    {pedido?.direccion_entrega?.pais}
                                </h2>
                            </div>
                        </div>
                        <span className="flex items-center">
                            <span className="h-px flex-1 bg-blue-800"></span>
                            <span className="shrink-0 px-6 font-black my-2 text-center text-blue-800">Información de contacto</span>
                            <span className="h-px flex-1 bg-blue-800"></span>
                        </span>
                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Teléfono de contacto:</p>
                            <h2 className="text-[#012a45] text-base text-right font-semibold">
                                {telefonoFormateado}
                            </h2>
                        </div>
                    </div>

                    <div className='bg-white border border-blue-900 text-white rounded mb-5 mt-4 mr-5 py-10 px-5 shadow-xl w-full sm:w-2/2 lg:w-2/2 xl:w-3/3'>

                        <span className="flex items-center">
                            <span className="h-px flex-1 bg-blue-800"></span>
                            <span className="shrink-0 px-6 font-black my-2 text-center text-blue-800">Datos de stripe</span>
                            <span className="h-px flex-1 bg-blue-800"></span>
                        </span>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">ID stripe</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                {pedido?.id_reference_stripe}
                            </h2>
                        </div>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">ID transacción</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                {pedido?.id_balance_transaction}
                            </h2>
                        </div>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">ID intento de pago</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                {pedido?.id_payment_intent}
                            </h2>
                        </div>

                        <span className="flex items-center">
                            <span className="h-px flex-1 bg-blue-800"></span>
                            <span className="shrink-0 px-6 font-black my-2 text-center text-blue-800">Datos del pedido</span>
                            <span className="h-px flex-1 bg-blue-800"></span>
                        </span>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Pedido realizado:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">{fechaFormateada}</h2>
                        </div>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Método de pago:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">{pedido?.metodo_pago} <br />
                                {pedido.extra && (
                                    <button
                                        onClick={() => setShowExtra(!showExtra)}
                                        className=" text-sm text-blue-500 hover:underline focus:outline-none"
                                    >
                                        {showExtra ? "Ver menos" : "Ver más"}
                                    </button>
                                )}

                            </h2>


                        </div>

                        {showExtra && <DatosExtraPay extra={pedido.extra} />}



                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Estado de la orden:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">{pedido?.estado_orden}</h2>
                        </div>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Estado del pago:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">{pedido?.estado_pago}</h2>
                        </div>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Total de productos:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                ${pedido?.total_productos?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </h2>
                        </div>

                        <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-[#757575] text-md mr-2">Total del envío Cliente:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                ${pedido?.total_envio?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                <br />

                                <button
                                    onClick={() => setShowExtraEnvio(!showExtraEnvio)}
                                    className=" text-sm text-blue-500 hover:underline focus:outline-none"
                                >
                                    {showExtraEnvio ? "Ver menos" : "Ver más"}
                                </button>


                            </h2>
                        </div>

                        {showExtraEnvio && <DatosExtraSeend EnvioCastores={pedido?.envio_castores} gap={pedido?.gap} t_envio={pedido?.t_envio} p_envio={pedido?.p_envio} />}


                        <div className="flex items-center justify-between w-full border-t-2 border-blue-800 py-2 mt-2">
                            <p className="text-[#757575] text-md mr-2">Total General:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                ${pedido?.total?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </h2>
                        </div>

                    </div>



                </div>

                <div className='grid grid-cols-1 lg:grid-cols-3'>
                    <div className='w-3/3 py-2'>
                        <ul>
                            <li
                                onClick={() => setSelectedView('resumen')}
                                className={`${listItemClasses} ${selectedView === 'resumen' ? activeClasses : 'bg-white hover:bg-[#1a2869] hover:text-white'}`}
                            >
                                Resumen del Pedido
                            </li>
                            <li
                                onClick={() => setSelectedView('odoo')}
                                className={`${listItemClasses} ${selectedView === 'odoo' ? activeClasses : 'bg-white hover:bg-[#1a2869] hover:text-white'}`}
                            >
                                Datos de Odoo
                            </li>
                            <li
                                onClick={() => setSelectedView('castores')}
                                className={`${listItemClasses} ${selectedView === 'castores' ? activeClasses : 'bg-white hover:bg-[#1a2869] hover:text-white'}`}
                            >
                                Datos de envio
                            </li>
                            <li
                                onClick={() => setSelectedView('entrega')}
                                className={`${listItemClasses} ${selectedView === 'entrega' ? activeClasses : 'bg-white hover:bg-[#1a2869] hover:text-white'}`}
                            >
                                Dirección de Entrega
                            </li>
                            <li
                                onClick={() => setSelectedView('facturacion')}
                                className={`${listItemClasses} ${selectedView === 'facturacion' ? activeClasses : 'bg-white hover:bg-[#1a2869] hover:text-white'}`}
                            >
                                Datos de Facturación
                            </li>
                        </ul>
                    </div>

                    <div className='lg:col-span-2 w-3/3 bg-white p-4 transition-all duration-300 ease-in-out'>

                        <TransitionGroup>
                            <CSSTransition
                                key={selectedView}
                                timeout={300}
                                classNames="fade"
                            >
                                <div className=' p-4'>{renderContent()}</div>
                            </CSSTransition>
                        </TransitionGroup>                   
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddPedido;
