import React, { useState, useEffect } from 'react';

import Header from '../../../componentes/Headers/Header';
import axios from 'axios';
import { API_URL } from '../../../constants/constants';

import Footer from '../../../componentes/footer/Footer';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import ProductoCard from '../../../componentes/productos/Card';


function AllProducts() {
    const [productos, setProductos] = useState([]);
    const [conIdOdoo, setConIdOdoo] = useState([]);
    const [totalProductos, setTotalProductos] = useState(null);
    const [npaginas, setNPaginas] = useState(0);
    const [paginaActual, setPaginaActual] = useState(1);

    const getproductos = async () => {
        const formdata = {
            'page': paginaActual,
            'page_size': 200,
        };

        try {
            const response = await axios.post(API_URL + '/getProductsByLimit', formdata);
            const data = response.data.data;

            const filtradoconIdOdoo = data.filter(item => item.id_odoo !== undefined && item.stock.disponible > item.stock.mostrado)
            .sort((a, b) => {
                if (b.precio.cantidad === a.precio.cantidad) {
                    return b.cantidad - a.cantidad;
                }
                return b.precio.cantidad - a.precio.cantidad;
            });
            // console.log('-----La respuesta es', response.data);
            // console.log('Elementos con id_odoo:', filtradoconIdOdoo);

            setConIdOdoo(filtradoconIdOdoo);

            const totalFiltrados = filtradoconIdOdoo.length;
            const paginasCalculadas = Math.ceil(totalFiltrados / 20);
            setTotalProductos(totalFiltrados);
            setNPaginas(paginasCalculadas);
        } catch (error) {
            console.error("Error al obtener los estados: ", error);
        }
    };

    useEffect(() => {
        getproductos();
        window.scrollTo(0, 0);
    }, []);

    const cambiarPagina = (numeroPagina) => {
        setPaginaActual(numeroPagina);
        window.scrollTo(0, 0);
    };

    const productosPaginados = conIdOdoo.slice((paginaActual - 1) * 20, paginaActual * 20);

    const renderizarBotonesPaginacion = () => {
        let botones = [];
        for (let i = 1; i <= npaginas; i++) {
            botones.push(
                <button
                    key={i}
                    onClick={() => cambiarPagina(i)}
                    name='btn-paginaall'
                    id='btn-paginaall'
                    className={`text-lg px-2 hover:bg-[#1e3d8b] mx-1 hover:text-white ${i === paginaActual ? 'bg-[#1e3d8b] text-white' : 'text-[#1e3d8b]'}`}
                >
                    {i}
                </button>
            );
        }
        return botones;
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <ScrollableCategories />
            <div className='w-full mx-auto md:w-full lg:w-4/5 lg:pl-4 mt-8 lg:mt-6'>
                <div className='w-full px-2 mx-auto md:w-full lg:flex lg:w-4/5 lg:pl-4 lg:text-3xl text-2xl font-black mt-8 lg:mt-4 font-robotoch'>
                    <p className='md:ml-2 lg:ml-0 text-[#1e3d8b]'>Todos los</p>
                    <p className='md:ml-2 lg:ml-2 text-[#030303] font-black'>productos</p>
                </div>
                <div className="flex-grow w-full lg:w-4/5 mx-auto mt-5">
                    <div className="grid grid-cols-2 md:grid-cols-3 w-full lg:grid-cols-4 xxl:grid-cols-5 gap-1 px-2">
                        {productosPaginados.map((producto) => (
                            <ProductoCard key={producto.id_producto} producto={producto} />
                        ))}
                    </div>
                </div>
                <div className='flex w-4/5 mx-auto justify-center'>
                    <button
                        onClick={() => cambiarPagina(paginaActual - 1)}
                        name='btn-paginaActual-1'
                        id='btn-paginaActual-1'
                        className='text-lg text-[#1e3d8b] mx-1 p-2 hover:bg-[#1e3d8b] hover:text-white'
                        disabled={paginaActual <= 1}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                    {renderizarBotonesPaginacion()}
                    <button
                        onClick={() => cambiarPagina(paginaActual + 1)}
                        className='text-lg text-[#1e3d8b] p-2 mx-1 hover:bg-[#1e3d8b] hover:text-white'
                        disabled={paginaActual >= npaginas}
                        name='btn-cambiarPagina2'
                        id='btn-cambiarPagina2'
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AllProducts;