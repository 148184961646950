import React, { useState } from 'react';

const StarRating = ({ totalStars = 5 , onRatingChange }) => {
  const [rating, setRating] = useState(0);

  const handleSelect = (index) => {
    setRating(index);
    onRatingChange(index); // Llama a la función callback con el nuevo rating
  };


  const Star = ({ selected = false, onSelect = () => {} }) => (
    <div
      className={`cursor-pointer text-5xl ${selected ? 'text-[#1e3d8b]' : 'text-gray-300'}`}
      onClick={onSelect}>
      ★
    </div>
  );

  return (
    <div className="flex justify-center items-center mt-2">
      {[...Array(totalStars)].map((n, i) => (
        <Star
          key={i}
          selected={i < rating}
          onSelect={() => handleSelect(i + 1)}
        />
      ))}
    </div>
  );
};

export default StarRating;
