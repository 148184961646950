// import BlogPostTemplate from './BlogPostTemplate';
import blogPostContent from './blogPostContent1.json';
import BlogPostTemplate from '../TemplateBlog';

const BlogPost = () => {
  return <>
  <BlogPostTemplate content={blogPostContent} />
  </>
};

export default BlogPost;