import React, { useState } from 'react';
import InputField from '../../inputs/InputField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../FormHeader';
import DualButton from '../DualButton';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../constants/constants';

function ChangePassForm({correo}) {

    const [passwordString, setPasswordString] = useState('');
    const [passwordStringConfirm, setPasswordStringConfirm] = useState('');

    const navigate = useNavigate();
    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
    }

    const handleCancel = () => {
        navigate(`/login`);
    };


    const handleConfirm = async () => {

        if(passwordString == '' || passwordStringConfirm==''){
            toastError('Error: Los campos no deben estar vacios.');
            return;
        }

        if(passwordString != passwordStringConfirm){
            toastError('Error: No coinciden las contraseñas.');
        }else{   
            const body = JSON.stringify({ email: correo, password: passwordStringConfirm});
            try {
                const response = await fetch(API_URL+'/resetPass', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: body
                });
                if (!response.ok) {
                    // alert('Credenciales incorrectas');
                    // throw new Error(`Error: ${response.status}`);
                    toastError('Ha ocurrido un error, por favor intentelo mas tarde.');
                }else{
                    navigate(`/login`)
                }
                const data = await response.json();
                
                //console.log(data);
            } catch (error) {
                console.error('El codigo proporcionado es incorrecto: ', error);
                toastError('Ha ocurrido un error, por favor intentelo mas tarde.');
            }

        }
       
    };

    const handlePasswordChange = (event) => {
        setPasswordString(event.target.value);
    };

    const handlePasswordChangeRepit = (event) => {
        setPasswordStringConfirm(event.target.value);
    };
    const desc ="Nueva contraseña para: "+ correo
    return (

        <div className="mt-20">
            <FormHeader
                title="Cambiar contraseña"
                description= {desc}
            />

            <InputField label="Ingresa tu contraseña" type="password" placeholder="Contraseña" handleChange={handlePasswordChange} />
            <InputField label="Repite tu contraseña" type="password" placeholder="Contraseña" handleChange={handlePasswordChangeRepit} />

            <DualButton
                leftButtonText="Iniciar sesión"
                rightButtonText="Cambiar contraseña"
                onLeftButtonClick={handleCancel}
                onRightButtonClick={handleConfirm}
            />
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            />
        </div>

    );
}

export default ChangePassForm;
