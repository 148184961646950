import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Headers/Header';
import ProductCartList from '../../../componentes/carrito/ProductCarList';
import Footer from '../../../componentes/footer/Footer';
import { useProductsCart } from '../../../store/bookStore';
import { dateDiffFromNow } from '../../../utils/utils';
import { useLocation } from 'react-router-dom';




function CarStore() {
    const { carts } = useProductsCart();
    const location = useLocation()

    //console.log('--carrito--',carts)

    useEffect(() => {
        window.scrollTo(0, 0);


    }, []);

    useEffect(() => {
        // Function to extract UTM parameters from the URL
        const extractUTMParameters = () => {

            const searchParams = new URLSearchParams(location.search);

            const utmSource = searchParams.get('utm_source');
            const utmMedium = searchParams.get('utm_medium');
            const utmCampaign = searchParams.get('utm_campaign');

            // const utmTerm = searchParams.get('utm_term');
            // const utmContent = searchParams.get('utm_content');

            let today = new Date()

            // Set hours in zero
            today.setHours(0, 0, 0, 0);

            const lastVisitDateStr = localStorage.getItem('utm');
            const lastVisitDate = lastVisitDateStr ? new Date(JSON.parse(lastVisitDateStr).date) : null;
            lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

            // Delete localstorage if date is more than 2 daty
            if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
                window.localStorage.removeItem("utm")
            }

            if (utmSource && utmMedium && utmCampaign) {

                // Has values
                const valuesUTM = {
                    "utmSource": utmSource,
                    "utmMedium": utmMedium,
                    "utmCampaign": utmCampaign,
                    "date": today
                }
                window.localStorage.setItem("utm", JSON.stringify(valuesUTM))

                return
            }

        };

        // Call the function when the component mounts
        extractUTMParameters();
    }, [location.search]);


    return (
        <div className="carrito">
            <Header />

            <ProductCartList />


            <Footer />

        </div>
    );
}

export default CarStore;
