import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const FloatingWhatsAppButton = ({ page, categoryName, urlproduto }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showWhatsAppOptions, setShowWhatsAppOptions] = useState(false);
    const [showIcon, setShowIcon] = useState(true);

    const handleButtonClick = () => {
        if (showWhatsAppOptions) {
            // If WhatsApp options are shown, hide them and show the main button
            setShowWhatsAppOptions(false);
            setShowIcon(true);
        } else {
            const newShowOptions = !showOptions;
            setShowOptions(newShowOptions);
            setShowIcon(!newShowOptions);
        }
    };

    const handleWhatsAppClick = () => {
        setShowWhatsAppOptions(true);
        setShowOptions(false);
        setShowIcon(false);
    };

    const handleOptionClick = (message) => {
        window.open(`https://wa.me/5593311600?text=${encodeURIComponent(message)}`);
        setShowWhatsAppOptions(false);
        setShowIcon(true);
    };

    const handleCallClick = () => {
        window.location.href = 'tel:5593311600';
    };

    let options;
    if (page === 'categorias') {
        options = [
            { message: `Hola, me interesan los productos de la categoría ${categoryName}`, name: 'Interés en categoría' },
            { message: 'Ayuda con el filtrado', name: 'Ayuda con filtrado' },
            { message: '¿Cómo comprar?', name: 'Cómo comprar' }
        ];
    } else if (page === 'productos') {
        options = [
            { message: `Hola, quiero más información sobre: ${categoryName} que vi en ${urlproduto}`, name: 'Comprar por WhatsApp' },
            { message: 'No aparece mi código postal', name: 'Problema con código postal' },
            { message: 'No puedo añadir a carrito', name: 'Problema añadiendo al carrito' },
            { message: 'No encuentro mi oficina de recolección', name: 'Ubicación de oficina de recolección' }
        ];
    } else if (page === 'combos') {
        options = [
            { message: '¿Cuántos productos vienen en el combo?', name: 'Detalles del combo' },
            { message: '¿Cómo puedo comprar un combo?', name: 'Comprar combo' },
            { message: 'Solo quiero algunos productos del combo, ¿qué puedo hacer?', name: 'Personalizar combo' },
            { message: '¿Llegan el mismo día todos los productos del combo?', name: 'Entrega de combo' }
        ];
    } else if (page === 'busqueda') {
        options = [
            { message: 'No encontré resultados en mi búsqueda, ¿puedes ayudarme?', name: 'Ayuda en búsqueda' },
            { message: '¿Cómo puedo comprar un producto?', name: 'Comprar producto' },
            { message: '¿Cómo puedo realizar una búsqueda de productos?', name: 'Buscar productos' },
            { message: '¿Cuáles son las formas de pago disponibles?', name: 'Formas de pago' },
            { message: '¿Qué hago si no encuentro el producto que busco?', name: 'Producto no encontrado' },
            { message: '¿Cómo puedo ver los detalles de un producto?', name: 'Detalles del producto' }
        ];
    }

    return (
        <div className="fixed bottom-4 right-4 z-50">
            <button
                name='btn-floting1'
                id='btn-floting1'
                aria-label='btn-flotanteoptions'

                onClick={handleButtonClick}
                className={`text-white px-3.5 py-2.5 rounded-full shadow-lg flex items-center justify-center transition duration-200 ease-in-out ${showWhatsAppOptions ? 'bg-green-500 px-4 py-2.5  hover:bg-red-600' : 'bg-blue-900 hover:bg-blue-900 text-white px-4 py-2.5 rounded-full shadow-lg  flex items-center justify-center'}`}
            >
                <i className={`${showIcon ? 'fa fa-phone' : 'fa fa-times text-2xl'} text-2xl`}></i>
            </button>
            <CSSTransition
                in={showOptions}
                timeout={300}
                classNames="options"
                unmountOnExit
            >
                <div className="absolute bottom-20  right-6 bg-blue-900 w-80 rounded shadow-lg z-50">
                    <div className='bg-blue-900 py-4 px-4'>
                        <p className="text-white font-semibold mb-2">Selecciona una opción.</p>
                    </div>
                    <div className='bg-white py-2 px-4'>
                        <ul>
                            <li className="text-gray-800 border-s-4 px-2 bg-gray-100 border-blue-600 my-2 py-4 hover:bg-gray-500 hover:text-white hover:border-blue-600 rounded cursor-pointer" onClick={handleCallClick}>
                                Llamar a un agente
                            </li>
                            <li className="text-gray-800 border-s-4 px-2 bg-gray-100 border-green-500 my-2 py-4 hover:bg-gray-500 hover:text-white hover:border-green-500 rounded cursor-pointer" onClick={handleWhatsAppClick}>
                                Enviar un WhatsApp
                            </li>
                        </ul>
                    </div>
                </div>
            </CSSTransition>

            <CSSTransition
                in={showWhatsAppOptions}
                timeout={300}
                classNames="options"
                unmountOnExit
            >
                <div className="absolute bottom-20 right-4 bg-blue-900 w-80 rounded shadow-lg z-50">
                    <div className='bg-green-500 py-4 px-4'>
                        <p className="text-white font-semibold mb-2">Selecciona una opción.</p>
                    </div>
                    <div className='bg-white py-2 px-4'>
                        <ul>
                            {options.map((option, index) => (
                                <li key={index} className="text-gray-800 border-s-4 px-2 bg-gray-100 border-green-500 my-2 py-4 hover:bg-gray-500 hover:text-white hover:border-green-600 rounded cursor-pointer" onClick={() => handleOptionClick(option.message)}>
                                    {option.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

export default FloatingWhatsAppButton;
