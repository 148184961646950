import React, { useState, useEffect } from 'react';
import HeaderEspecial from '../../componentes/Headers/HeadeEpecial';
import Tarjetas4 from '../../assets/Tarjetas4.webp'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL, API_URL_PAY } from '../../constants/constants';
// import { addPaymentInfoCredit, addPaymentInfoDebit } from '../../utils/PixelMeta/metaPixelEvents';
import { SnipPaymentMethods } from '../../utils/Analitycs/analytics';

import imgSantander from '../../assets/Santander_Logo.webp'
import imgBanorte from '../../assets/BanorteLogo.png'
import imgScotiabank from '../../assets/Logo_Scotiabank.webp'
import imgInbursa from '../../assets/Logo_de_Inbursa.webp'
import imgHsbc from '../../assets/HSBC_LOGO.webp'
import imgBcoAzteca from '../../assets/Logo_de_Banco_Azteca.webp'
import { addPaymentInfo } from '../../utils/PixelMeta/metaPixelEvents';
import { getRandomInt } from '../../utils/utils';

const AddTarjetaCB = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [linkPago, setLinkPago] = useState("");
    const [quantityProducts, setQuantityProducts] = useState(0)

    const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const { token, id, datospadso } = location.state || {};

    const imagenes = [
        imgSantander,
        imgBanorte,
        imgScotiabank,
        imgInbursa,
        imgHsbc,
        imgBcoAzteca
    ]

    useEffect(() => {
        const fetchData = async () => {
            const pedido = await getPedidos(datosPerfil['$udi09345'], id);

            // Función para verificar si el link_pago está desactualizado (más de 24 horas)
            const isLinkOutdated = (updatedAt) => {
                const updatedDate = new Date(updatedAt);
                const now = new Date();
                const timeDiff = now - updatedDate;
                const hoursDiff = timeDiff / (1000 * 60 * 60);
                return hoursDiff > 24;
            };
            if (!pedido.link_pago ||  pedido.link_pago.includes('linkdelete') || pedido.link_pago.includes('https://payments.stripe.com/oxxo/voucher/') || pedido.link_pago.includes('https://payments.stripe.com/bank_transfers/') || isLinkOutdated(pedido.updated_at)) {
                 console.log('link_pago no encontrado, llamando a linkpago()');

                linkpago();
            }
            else {
            //    console.log('link_pago encontrado:', pedido.link_pago);
                setLinkPago(pedido.link_pago);
            }
        };

        fetchData();
    }, []);

    const linkpago = async () => {
        let msiString = '';
        let event_id = getRandomInt()
        if (datospadso && datospadso.datos_orden && datospadso.datos_orden.msi !== undefined) {
            msiString = datospadso.datos_orden.msi === 1 ? "C" : datospadso.datos_orden.msi.toString();
        }

        //     console.log('msiString:', msiString);

        try {
            datospadso?.carrito_usuario.forEach((value) => {
                //     console.log('Producto cantidad:', value?.cantidad);
                setQuantityProducts(prevValue => prevValue + value?.cantidad);
            });

            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                }
            };

            const data = token === 'credito' ? {
                'id_usuario': datosPerfil['$udi09345'],
                'costo': datospadso.datos_orden.total,
                'referencia': 'PAGO HANTEC N°' + id,
                'meses': msiString
            } : {
                'id_usuario': datosPerfil['$udi09345'],
                'costo': datospadso.datos_orden.total,
                'referencia': 'PAGO HANTEC N°' + id,
                'meses': ''
            };

            //  console.log('enviare:', data);
            const endpoint = API_URL_PAY + (token === 'credito' ? "/generatePayMSI" : "/generatePaymentLink");
            //console.log('el end point es', endpoint);
            data["event_id"] = event_id
            const response = await axios.post(endpoint, data, config);
            // console.log('la respuesta es', response.data.link);
            saveLink(response.data.link)

            setLinkPago(response.data.link);
        } catch (error) {
            // console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
        } finally {
            if (token === 'credito') {
                // -------- Analytics ---------- //
                SnipPaymentMethods("credito")
                // ------------------------------ //
            } else {
                // -------- Analytics ---------- //
                SnipPaymentMethods("debito")
                // ------------------------------ //
            }

            // -------- Pixel Meta ---------- //
            addPaymentInfo(
                datosPerfil && datosPerfil['$email'] ? datosPerfil['$email'] : '',
                datospadso?.datos_orden?.total,
                datospadso,
                event_id
            )
            // ------------------------------ //
        }
    };

    const getPedidos = async (idusuari, idpedido) => {
        try {
            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                },
            };

            let response = await axios.get(`${API_URL}/getOrdersByUser?user_id=${idusuari}&order_id=${idpedido}`, config);
        console.log('-------dagdffdsd', response.data.success);
            return response.data.success;
        } catch (error) {
            console.error('Error al obtener EL PEDIDO: ' + error.toString());

            if (error.response.data.error === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }
        }
    };

    const saveLink = async (link) => {

        try {

            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                }
            };

            const formdata = {
                no_pedido: id,
                link_pago: link
            }

            //   console.log('El form data para el link es ', formdata)

            const response = await axios.put(API_URL + '/updateOrder', formdata, config);
            //    console.log(response)


        } catch (error) {
            console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
        }
    }

    return (
        <div>
            <HeaderEspecial butonpedios={true} />
            <div className='lg:w-4/5 mx-auto'>
                <div className='text-center mt-5'>
                    <h2 className='text-[#1e3d8b] font-black text-lg'>INGRESA TUS DATOS DE</h2>
                    <h1 className='text-[#030303] font-black text-xl'>TARJETA</h1>
                </div>

                <div className='flex flex-col justify-center items-center borderinput p-5 mx-5 mt-5 rounded'>
                    <div className='mt-2 mb-2'>
                        <img width="300" height="200"
 src={Tarjetas4} alt='tarjeta4' className='w-64 mx-auto' />
                        {token === 'credito' && datospadso.datos_orden.msi > 1 && (
                            <div>
                                <div className='lg:w-4/5 text-center mt-2 mx-auto'>
                                    <h3 className=' text-lg my-3'>Bancos participantes (Meses)</h3>

                                </div>

                                <div className="flex flex-wrap justify-center">
                                    {imagenes.map((url, index) => (
                                        <div key={index} className="w-1/3 p-2 flex justify-center">
                                            <img width="300" height="200"

                                                src={url}
                                                alt={`Logo ${index + 1}`}
                                                className="h-6 w-24 object-contain" // Mantiene el tamaño fijo de las imágenes
                                            />
                                        </div>
                                    ))}
                                </div>



                            </div>
                        )}
                    </div>

                    <div className="w-full lg:w-4/5 xl:w-10/12 mx-auto flex justify-center items-center h-full mt-3">
                        {linkPago && (
                            <div className="w-full lg:w-4/5 xl:w-10/12 mx-auto flex justify-center items-center h-full">
                                <iframe
                                    src={linkPago}
                                    frameBorder="0"
                                    scrolling="no"
                                    seamless="seamless"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    className="w-full rounded"
                                    height={'800px'}
                                    title='likpago'
                                ></iframe>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTarjetaCB;