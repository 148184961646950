import React from 'react';

const ImageComponent = ({ backgroundImage }) => {
  return (
    <div className=' w-full'>
      <img width="300" height="200"
 src={backgroundImage} className="mx-auto w-full lg:w-4/5 lg:h-60 object-fill" alt="rampas" />
    </div>
  );
};

export default ImageComponent;
