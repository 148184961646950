import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Acordeon({ title, items }) {

 
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleItemClick = (link, agente) => {
    if (link.startsWith('52') || link.startsWith('99') ) {
      console.log('ekeje',agente)
      let whatsappMessageAgente = `Hola, ${agente} Estaba navegando en hantec.us, me podrias dar detalles sobre sus productos`;
      if (agente == null) {
        whatsappMessageAgente = `Hola, estaba navegando en hantec.us, me podrías dar detalles sobre sus productos`;
      }
      const whatsappLink = `https://api.whatsapp.com/send?phone=${link}&text=${encodeURIComponent(whatsappMessageAgente)}`;
      window.location.href = whatsappLink;
    } else {
      navigate(link);
    }
  };

  return (
    <div className="border-b mb-5">
      <div>
        <div className='flex flex-row justify-between py-2'>
          <button
            name='btn-down20'
            id='btn-down20'
            onClick={() => setIsOpen(!isOpen)}
            className="text-white flex w-full justify-between"
            aria-label='btn-openoption'
          >
            <p className='text-white font-oswald'>{title}</p>
            <span className="sr-only">ir a opcion</span>
            <i className="fas fa-chevron-down"></i>
          </button>
        </div>
      </div>

      <div
        className={`overflow-hidden transition-all duration-500 ${isOpen ? 'max-h-96' : 'max-h-0'}`}
        style={{ transitionProperty: 'max-height' }}
      >
        <div className="p-4">
          {items.map((item, index) => (
            <button
              name='btn-itemname2'
              id='btn-itemname2'
              key={index}
              className='text-white text-xs md:text-base w-full text-left mb-2'
              onClick={() => handleItemClick(item.link, item.agente)}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Acordeon;
