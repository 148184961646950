import main_bg from "../../assets/products/alineadoras/test_ali2.webp";
import main_bg_phone from "../../assets/products/alineadoras/main_bg_phone.webp";
import { useEffect, useState } from "react";
import {
  Camera,
  Zap,
  Wifi,
  Repeat,
  Database,
  Crosshair,
  Bluetooth,
  Smartphone,
  Maximize,
  ChevronDown,
  ChevronLeft,
} from "lucide-react";
import { motion } from "framer-motion";
import imgCompressor from "../../assets/bg-compressor.webp";
import imgDesmontadoras from "../../assets/products/desmontadoras/bg_main.webp";
import imgBienvenidaMin from "../../assets/blog/bienvenida_min.webp";
import Header from "../../componentes/Headers/Header";
import Footer from "../../componentes/footer/Footer";
import { useNavigate } from "react-router-dom";
import RelatedArticles from "../../componentes/blog/RelatedArticles";
import CallToAction from "../../componentes/blog/CallToAction";

export default function LandingAlineadora() {
  const [activeTab, setActiveTab] = useState("3D");
  const [expandedSpec, setExpandedSpec] = useState("");
  const navigate = useNavigate();

  const relatedArticles = [
    {
      title: "Bienvenidos a Hantec",
      description: "Diferencia y Valor en Herramientas Automotrices...",
      image: imgBienvenidaMin,
      href: "/blog/autozone/1",
      buttonText: "Leer Más",
    },
    {
      title: "Desmontadoras",
      description:
        "Explora las últimas innovaciones en el mundo de las herramientas...",
      image: imgDesmontadoras,
      href: "/desmontadoras/autozone",
      buttonText: "Leer Más",
    },
    {
      title: "Compresores",
      description:
        "Libera el poder del aire comprimido con una eficiencia y un rendimiento inigualables...",
      image: imgCompressor,
      href: "/force_element",
      buttonText: "Leer Más",
    },
  ];

  const handleOptionClick = (message) => {
    window.open(`https://wa.me/5593311600?text=${encodeURIComponent(message)}`);
  };

  const toggleSpec = (spec) => {
    setExpandedSpec(expandedSpec === spec ? "" : spec);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [
    "https://www.youtube.com/embed/p7F9f81FUas",
    "https://www.youtube.com/embed/jZvu0fsQ_ao",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const buttonStyle =
    "bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-md";
  const secondaryButtonStyle =
    "bg-white text-red-600 font-bold py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-md border border-red-600";

  const productsAlineadoras = [
    {
      HTCAD908PAQ: {
        id: 9,
        sku: "HTCAD908PAQ",
        image:
          "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/1_b2d6a361-5d8a-4c60-bbf6-3e6e56d2da47.jpg2024-04-30%2015%3A35%3A02.003811",
        url: "https://hantec.us/product/alineadora-automotriz-hantec-autos-y-camionetas-3d/9",
        sheet: "",
      },
      HTCA908PAQ: {
        id: 8,
        sku: "HTCA908PAQ",
        image:
          "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/1_a595974e-6d02-4394-a3b1-5603aaf265b4.jpg2024-04-29%2023%3A05%3A39.639214",
        url: "https://hantec.us/product/alineadora-automotriz-hantec-autos-y-camionetas-ccd/8",
        sheet: "",
      },
    },
  ];

  return (
    <>
      <Header />
      <div className="bg-white text-gray-800">
        {/* Hero Section */}
        <section className="relative h-screen flex justify-center text-center text-white pt-6">
          {/* Mobile */}
          <div
            className="block md:hidden  inset-0 bg-cover bg-center"
            style={{ backgroundImage: main_bg_phone }}
          >
            <div className="absolute inset-0">
              <img
                src={main_bg_phone}
                alt="banner alineadoras"
                width="1600"
                height="800"
                className="h-full w-full object-cover object-center aspect-[1600/800]"
                style={{ aspectRatio: 1600 / 800, objectFit: "cover" }}
              />
            </div>
          </div>

          {/* Desktop */}
          <div
            className="hidden md:block  inset-0 bg-cover bg-center"
            style={{ backgroundImage: main_bg }}
          >
            <div className="absolute inset-0">
              <img
                src={main_bg}
                alt="banner alineadoras"
                width="1600"
                height="800"
                className="h-full w-full object-cover object-center aspect-[1600/800]"
                style={{ aspectRatio: 1600 / 800, objectFit: "cover" }}
              />
            </div>
          </div>

          {/* Btn back */}
          <div className="absolute left-0 top-2 bg-blue-600 w-28 rounded-lg ml-2">
              <div className="absolute flex w-auto">
                <ChevronLeft className="my-auto w-6 h-6 text-white" />
                <button
                  onClick={() => navigate("/blog/autozone")}
                  className="text-muted-foreground text-lg hover:underline text-white"
                >
                  Regresar
                </button>
              </div>
            </div>

          <div className="relative z-10 max-w-4xl mx-auto px-4">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl md:text-5xl font-bold mt-4 mb-8"
            >
              Alineadoras de Neumáticos Hantec.
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-xl md:text-2xl mb-8"
            >
              Precisión y tecnología para un alineado perfecto en cada vehículo.
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
            >
              {/* <button className={buttonStyle}>
              Comienza con Hantec Hoy
            </button>
            <button className={secondaryButtonStyle}>
              Explora Nuestros Productos
            </button> */}
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
          >
            <ChevronDown className="w-10 h-10 text-white animate-bounce" />
          </motion.div>
        </section>

        {/* Video Presentation */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-3xl font-bold text-center mb-8"
            >
              Explora nuestras Alineadoras en Acción
            </motion.h2>

            {/* Carousel de videos */}
            <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 overflow-hidden">
              <div
                className="relative w-full"
                style={{ paddingBottom: "56.25%" }}
              >
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`absolute inset-0 w-full h-full transition-all duration-500 ease-in-out transform ${
                      index === currentIndex
                        ? "translate-x-0 opacity-100"
                        : index < currentIndex
                        ? "-translate-x-full opacity-0"
                        : "translate-x-full opacity-0"
                    }`}
                  >
                    <iframe
                      src={image}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full rounded-lg shadow-lg"
                    ></iframe>
                  </div>
                ))}
                <button
                  onClick={prevSlide}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-label="Previous image"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14"
                    width="12.25"
                    viewBox="0 0 448 512"
                  >
                    <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8 .4 34.3z" />
                  </svg>
                </button>
                <button
                  onClick={nextSlide}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 transition-opacity duration-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-label="Next image"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14"
                    width="12.25"
                    viewBox="0 0 448 512"
                  >
                    <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Feature Overview - 3D Aligner */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-3xl font-bold text-center mb-12"
            >
              Alineadora 3D - Precisión en Cada Alineación
            </motion.h2>
            <div className="flex flex-wrap items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                className="w-full md:w-1/2 mb-8 md:mb-0"
              >
                <img
                  src={productsAlineadoras[0].HTCAD908PAQ.image}
                  alt="Alineadora 3D"
                  className="rounded-lg shadow-xl"
                />
              </motion.div>
              <div className="w-full md:w-1/2 md:pl-12">
                <ul className="space-y-6">
                  {[
                    {
                      icon: Camera,
                      title: "Diseño en T",
                      description:
                        "Barra con 2 cámaras de alta definición en ambos lados para capturar cada detalle con precisión.",
                    },
                    {
                      icon: Zap,
                      title: "Lecturas en Tiempo Real",
                      description:
                        "Obtén resultados inmediatos con nuestra tecnología de última generación.",
                    },
                    {
                      icon: Wifi,
                      title: "Comunicación Inalámbrica",
                      description:
                        "Las cámaras y los objetivos se comunican sin cables, facilitando el proceso de alineación.",
                    },
                    {
                      icon: Repeat,
                      title: "Modalidades de Alineación",
                      description:
                        "Rápida (utilizando volante y ruedas) y Estándar (incluyendo SAI/KPI).",
                    },
                    {
                      icon: Database,
                      title: "Base de Datos en Español",
                      description:
                        "Acceso a información detallada para más de 20,000 vehículos, actualizada hasta el día de hoy.",
                    },
                  ].map((feature, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: 20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.6, delay: index * 0.1 }}
                      className="flex items-start"
                    >
                      <feature.icon className="w-6 h-6 mr-4 text-blue-600" />
                      <div>
                        <h3 className="font-semibold text-lg">
                          {feature.title}
                        </h3>
                        <p>{feature.description}</p>
                      </div>
                    </motion.li>
                  ))}
                </ul>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  className="mt-8"
                >
                  <a
                    href="https://drive.google.com/uc?export=download&id=1XqpEHgK1IOZqXoUNVq4Eru4omgvrPi6u"
                    className={buttonStyle}
                    download
                  >
                    Descargar Ficha Técnica
                  </a>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        {/* Feature Overview - CCD Aligner */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-3xl font-bold text-center mb-12"
            >
              Alineadora CCD - Tecnología de Precisión
            </motion.h2>
            <div className="flex flex-wrap items-center">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                className="w-full md:w-1/2 mb-8 md:mb-0 md:order-2"
              >
                <img
                  src={productsAlineadoras[0].HTCA908PAQ.image}
                  alt="Alineadora CCD"
                  className="rounded-lg shadow-xl"
                />
              </motion.div>
              <div className="w-full md:w-1/2 md:pr-12 md:order-1">
                <ul className="space-y-6">
                  {[
                    {
                      icon: Crosshair,
                      title: "Captadores Avanzados",
                      description:
                        "Equipados con tecnología inalámbrica Bluetooth, 8 sensores CCD, indicadores luminosos LED, acelerómetro y teclado integrado.",
                    },
                    {
                      icon: Maximize,
                      title: "Lecturas Precisas",
                      description:
                        "La tecnología instalada en los captadores garantiza una alineación precisa en cada operación.",
                    },
                    {
                      icon: Smartphone,
                      title: "Movilidad y Flexibilidad",
                      description:
                        "Ideal para talleres que necesitan una mayor movilidad y un equipo compacto.",
                    },
                    {
                      icon: Database,
                      title: "Base de Datos en Español",
                      description:
                        "Acceso a información detallada para más de 20,000 vehículos, actualizada hasta el día de hoy.",
                    },
                  ].map((feature, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.6, delay: index * 0.1 }}
                      className="flex items-start"
                    >
                      <feature.icon className="w-6 h-6 mr-4 text-blue-600" />
                      <div>
                        <h3 className="font-semibold text-lg">
                          {feature.title}
                        </h3>
                        <p>{feature.description}</p>
                      </div>
                    </motion.li>
                  ))}
                </ul>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  className="mt-8"
                >
                  <a
                    href="https://drive.google.com/uc?export=download&id=1kdHMCG1y4-1Obm9f-Nt4EAVhwarvTRJV"
                    className={buttonStyle}
                    download
                  >
                    Descargar Ficha Técnica
                  </a>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        {/* Technical Specifications */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-3xl font-bold text-center mb-12"
            >
              Especificaciones Técnicas
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-center text-xl mb-8"
            >
              Precisión y Rango de Medición: Nuestra alineadoras garantizan
              precisión en cada ajuste, cubriendo todos los ángulos críticos
              tanto en llantas delanteras como traseras.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="overflow-x-auto"
            >
              <h3 className="mx-auto my-5 text-center w-full text-2xl font-semibold">
                Llantas Delanteras
              </h3>
              <table className="w-full bg-white shadow-lg rounded-lg mb-5">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-3 text-left">Elementos de Medición</th>
                    <th className="p-3 text-left">Precisión</th>
                    <th className="p-3 text-left">Rango de Medición</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    { spec: "Toe", d3: "±1'", ccd: "±24°" },
                    { spec: "Camber", d3: "±1'", ccd: "±10°" },
                    { spec: "Caster", d3: "±2'", ccd: "±20°" },
                    {
                      spec: "Inclinación del eje de dirección",
                      d3: "±2'",
                      ccd: "±20°",
                    },
                    { spec: "Set-Back", d3: "±1'", ccd: "±10°" },
                  ].map((row, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                    >
                      <td className="p-3 text-wrap">{row.spec}</td>
                      <td className="p-3">{row.d3}</td>
                      <td className="p-3">{row.ccd}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="overflow-x-auto"
            >
              <h3 className="mx-auto my-5 text-center w-full text-2xl font-semibold">
                Llantas Traseras
              </h3>
              <table className="w-full bg-white shadow-lg rounded-lg ">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-3 text-left">Elementos de Medición</th>
                    <th className="p-3 text-left">Precisión</th>
                    <th className="p-3 text-left">Rango de Medición</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    { spec: "Toe", d3: "±1'", ccd: "±24°" },
                    { spec: "Camber", d3: "±1'", ccd: "±10°" },
                    { spec: "Set-Back", d3: "±1'", ccd: "±10°" },
                    { spec: "Ángulo de Empuje", d3: "±1'", ccd: "±10°" },
                  ].map((row, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                    >
                      <td className="p-3">{row.spec}</td>
                      <td className="p-3">{row.d3}</td>
                      <td className="p-3">{row.ccd}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-center mt-8"
            ></motion.div>
          </div>
        </section>

        {/* Comparison Chart – 3D vs. CCD */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-3xl font-bold text-center mb-12"
            >
              Elige la Tecnología que Mejor se Adapte a Tu Taller
            </motion.h2>
            <div className="flex flex-wrap -mx-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="w-full md:w-1/2 px-4 mb-8"
              >
                <div className="bg-white rounded-lg shadow-lg p-6 h-full">
                  <div className="flex justify-center mb-4">
                    <img
                      src={productsAlineadoras[0].HTCAD908PAQ.image}
                      alt="Alineadora 3D"
                      className="w-40 h-40 object-contain"
                    />
                  </div>
                  <h3 className="text-2xl font-semibold mb-4 text-center">
                    Alineadora 3D
                  </h3>
                  <ul className="space-y-4">
                    <li className="flex">
                      <IconCorrectSVG />
                      Tecnología de Sensores: 2 cámaras de alta definición
                    </li>
                    <li className="flex">
                      <IconCorrectSVG /> Modalidades de Alineación: Rápida y
                      Estándar
                    </li>
                    <li className="flex">
                      <IconCorrectSVG /> Lectura de Datos: En tiempo real
                    </li>
                    <li className="flex">
                      <IconCorrectSVG /> Comunicación: Inalámbrica entre cámaras
                      y objetivos
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Compatibilidad: Compatible con todas las rampas Hantec
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Base de Datos: Más de 20,000 vehículos, actualizada a la
                      fecha
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Manejo: Desde computadora con monitor incluida en la
                      compra
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Espacio Requerido: Requiere espacio fijo para alineado
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Aspecto de Modernización: Da una imagen de tecnología
                      avanzada al taller
                    </li>
                  </ul>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="w-full md:w-1/2 px-4 mb-8"
              >
                <div className="bg-white rounded-lg shadow-lg p-6 h-full">
                  <div className="flex justify-center mb-4">
                    <img
                      src={productsAlineadoras[0].HTCA908PAQ.image}
                      alt="Alineadora 3D"
                      className="w-40 h-40 object-contain"
                    />
                  </div>

                  <h3 className="text-2xl font-semibold mb-4 text-center">
                    Alineadora CCD
                  </h3>
                  <ul className="space-y-4">
                    <li className="flex">
                      <IconCorrectSVG /> Tecnología de Sensores: 8 sensores CCD
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Modalidades de Alineación: Estándar
                    </li>
                    <li className="flex">
                      <IconCorrectSVG /> Lectura de Datos: A través de
                      captadores
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Comunicación: Bluetooth
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Compatibilidad: Compatible con rampas de 4 postes, bancos
                      de alineación, fosas, y suelos perfectamente nivelados
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Base de Datos: Más de 20,000 vehículos, actualizada a la
                      fecha
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Manejo: Desde computadora con monitor incluida en la
                      compra
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Espacio Requerido: Ocupa menos espacio y ofrece mayor
                      movilidad
                    </li>
                    <li className="flex">
                      <IconCorrectSVG />
                      Aspecto de Modernización: Diseño compacto y eficiente en
                      el espacio
                    </li>
                  </ul>
                </div>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-center mt-8"
            ></motion.div>
          </div>
        </section>
      </div>

      {/* Call To Action */}
      <CallToAction
        title={
          "¡Mantén una actitud positiva, sé persistente, y lograrás grandes cosas!"
        }
        subtitle={
          "Descubre cómo las herramientas Hantec pueden ayudarte a alcanzar el éxito en ventas."
        }
        className={"max-w-4xl mx-auto px-4 py-8 space-y-8"}
      />

      {/* Related Articles */}
      <RelatedArticles
        className={"max-w-4xl mx-auto px-4 py-8"}
        relatedArticles={relatedArticles}
      />

      <Footer />
    </>
  );
}

const IconCorrectSVG = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 mr-2 text-green-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );
};
