import React, { useEffect } from 'react';
import { useProductStore } from '../../store/bookStore';

const CardComparativo = ({ rampas, onAgregarComparar }) => {
    const { products, fetchProducts } = useProductStore();
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    const renderNombre = (nombre) => {
        const palabras = nombre.split(' ');
        const primerasPalabras = palabras.slice(0, 3).join(' ');
        const restoDelTexto = palabras.slice(2).join(' ');

        return (
            <>
                <span className="text-lg font-semibold uppercase text-xl">{primerasPalabras}</span>
                <br />
                <span className="text-xs uppercase text-[#757575]">{restoDelTexto}</span>
            </>
        );
    };

    const handleAddProduct = async (producto) => {
        const payload = {
            "id_producto": "" + producto.id_producto,
            "cantidad": 1,
            "email": token['$email'],
            "precio": {
                "cantidad": parseFloat(producto.precio.cantidad),
                "moneda": "MXN"
            },
            "titulo": producto.titulo,
            "imagen": producto.imagen,
            "sku": producto.sku
        };
        // Implementa aquí la lógica para añadir el producto al carrito
    };

    useEffect(() => {
        fetchProducts('Rampas');
    }, []);

    return (
        <div className='gap-4 mx-4 mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'>
            {products?.map(rampa => (
                <div key={rampa?.id_producto} className="card border rounded-2xl p-3 px-5 mt-10 flex flex-col items-center text-center custom-shadow">
                    <div className='text-[#007bfc] w-full text-right pr-2 uppercase text-sm '>
                        <a>ver más <i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                    <img width="300" height="200"
                        src={rampa?.imagen} alt={rampa?.titulo} />
                    <h3>{renderNombre(rampa?.titulo)}</h3>
                    <p className='text-[#1e3d8b] text-base font-semibold text-xl mt-1'>
                        {parseFloat(rampa?.precio?.cantidad).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {rampa?.precio?.moneda}
                    </p>
                    <button className='bg-[#007bfc] text-white px-5 py-2 rounded-lg uppercase my-2' onClick={() => handleAddProduct(rampa)}
                        name='btn-addcvart'
                        id='btn-addcvart'
                    >Añadir al carrito</button>
                    <button

                        name='btn-addcompare'
                        id='btn-addcompare'
                        className='border-b-2 border-color text-[#007bfc] text-xs my-2' onClick={() => onAgregarComparar(rampa)}>Agregar al comparador</button>
                </div>
            ))}
        </div>
    );
};

export default CardComparativo;
