import React, { useState } from 'react';
import '../../../assets/style/style.css'

const CodigoInput = ({ onCodigoChange }) => {
    const [codigo, setCodigo] = useState({ digito1: '', digito2: '', digito3: '', digito4: '' });

    const handleInputChange = (event, campo) => {
        const nuevoCodigo = { ...codigo, [campo]: event.target.value };
        setCodigo(nuevoCodigo);

        // Llama a la función callback con el nuevo valor
        onCodigoChange(Object.values(nuevoCodigo).join(''));

        if (event.target.value && event.target.nextSibling) {
            event.target.nextSibling.focus();
        }
    };

    return (
        <div className="codigo-container">
            {Object.keys(codigo).map((key, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={codigo[key]}
                    onChange={(e) => handleInputChange(e, key)}
                    className="digito-input"
                />
            ))}
        </div>
    );
};

export default CodigoInput;
