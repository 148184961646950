import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ModalGraficos from './ModalGracios';

const MetodosPagoGrafica = ({ datosGrafica, datos1 }) => {
  const [showModal, setShowModal] = useState(false);

  const dataMetodosPago = {
    labels: datosGrafica.fechas,
    datasets: [
      {
        label: 'Oxxo',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.oxxo || 0),
        backgroundColor: 'rgba(0, 123, 252, 0.5)', // #007BFC
      },
      {
        label: 'Transferencia',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.transferencia || 0),
        backgroundColor: 'rgba(15, 107, 227, 0.5)', // #0F6BE3
      },
      {
        label: 'Debito',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.debito || 0),
        backgroundColor: 'rgba(25, 91, 176, 0.5)', // #195BB0
      },
      {
        label: 'Credito',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.credito || 0),
        backgroundColor: 'rgba(22, 61, 109, 0.5)', // #163D6D
      },
    ],
  };

  const opcionesMetodosPago = {

    scales: {
      y: {
        beginAtZero: true,
      },
    },


  };
  const opcionesMetodosPago2 = {
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Métodos de Pago',
      },

    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },


  };


  const colors = [
    'rgba(59, 89, 152, 0.7)',   // Color de Facebook
    'rgba(188, 42, 141, 0.6)',  // Color de Instagram
    'rgba(30, 61, 139, 0.9)',   // Color de Anuncios dinámicos
    'rgba(0, 0, 0, 0.6)',       // Color de TikTok
  ];

  // Función para obtener todas las plataformas presentes en los datos
  const getPlatforms = (traficoPlataforma) => {
    const fechas = Object.keys(traficoPlataforma);
    const plataformas = new Set();

    fechas.forEach(fecha => {
      Object.keys(traficoPlataforma[fecha]).forEach(plataforma => {
        plataformas.add(plataforma);
      });
    });

    return Array.from(plataformas);
  };

  const plataformas = getPlatforms(datosGrafica.traficoPlataforma)

  const generateDatasets = (datosGrafica, plataformas, colors) => {
    return plataformas.map((plataforma, index) => ({
      label: plataforma.charAt(0).toUpperCase() + plataforma.slice(1),
      data: datosGrafica.fechas.map(fecha => datosGrafica.traficoPlataforma[fecha]?.[plataforma] || 0),
      backgroundColor: colors[index % colors.length],
    }));
  };

  const dataTraficoPlataforma = {
    labels: datosGrafica.fechas,
    datasets: generateDatasets(datosGrafica, plataformas, colors),
  };

  const opcionesTraficoPlataforma = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };



  // Determina qué conjunto de datos y opciones usar basado en `datos1`
  const dataFinal = datos1 ? dataTraficoPlataforma : dataMetodosPago;
  const opcionesFinal = datos1 ? opcionesTraficoPlataforma : opcionesMetodosPago;
  const opcionesFinal2 = datos1 ? opcionesTraficoPlataforma : opcionesMetodosPago2;


  return (
    <div className="w-full px-1 ">



      <div className="flex rid grid-flow-col justify-between px-4 mx-auto  items-center">
        <h2 className="text-center text-base font-semibold">
          {datos1 ? 'Tráfico de Plataforma' : 'Métodos de Pago'}
        </h2>
        <button
          name='btn-seemore5'
          id='btn-seemore5'
          onClick={() => setShowModal(true)}
          className="ml-4 px-2 py-1 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <i className="fas fa-search-plus"></i>
        </button>
      </div>

      <Bar data={dataFinal} options={opcionesFinal} />


      <ModalGraficos show={showModal} onClose={() => setShowModal(false)}>
        <Bar data={dataFinal} options={opcionesFinal2} />
      </ModalGraficos>


    </div>
  )
};

export default MetodosPagoGrafica;
