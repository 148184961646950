import React, { useState, useEffect } from 'react';
import imgSantander from '../../assets/Santander_Logo.webp'
import imgBanorte from '../../assets/BanorteLogo.png'
import imgScotiabank from '../../assets/Logo_Scotiabank.webp'
import imgInbursa from '../../assets/Logo_de_Inbursa.webp'
import imgHsbc from '../../assets/HSBC_LOGO.webp'
import imgBcoAzteca from '../../assets/Logo_de_Banco_Azteca.webp'


// const PaginaMesesCredito = ({ totalpago, totalenvio, mtdopago, enviarPagofinal, losMsison }) => {

const PaginaMesesCredito = ({ totalpago, totalenvio, mtdopago, enviarPagofinal, losMsison, totalsinIva, totalesDescuentos }) => {
    const [plazos, setPlazos] = useState([]);
    // console.log('total pago ',totalpago)
    // console.log('totales SIN IVa', totalsinIva);
    // console.log(totalesDescuentos)

    const tasasPorPlazo = {
        3: 0.035,
        6: 0.055,
        9: 0.085,
        12: 0.115,
        18: 0.15
    };

    // Usar valores de totalesDescuentos si están presentes
    const totalfinalConEnvio = (totalesDescuentos.totaldescuento || totalpago) + totalenvio;
    const totalSinIva = totalesDescuentos.totalsinIvafrdcuento || totalsinIva;

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    const calcularPlazos = () => {
        const nuevosPlazos = Object.keys(tasasPorPlazo).map(meses => {
            const tasa = tasasPorPlazo[meses];
            const comisionBase = (totalfinalConEnvio * 0.014) * 1.16; // 1.4% de comisión base ajustada por 1.16
            const incrementoPorTasa = (totalfinalConEnvio * tasa) * 1.16; // Incremento por la tasa ajustada por 1.16

            const precioComision = comisionBase + incrementoPorTasa;
            let precioFinal = totalfinalConEnvio + precioComision;
            // console.log('final price',precioFinal)
            let precioMensual = precioFinal / meses;

            // Cálculo por producto sin IVA
            const plazosProductosSinIva = totalSinIva.map(producto => {
                const comisionBaseSIva = (producto.totalSinIva * 0.014) * 1.16;
                const incrementoPorTasaSIVA = (producto.totalSinIva * tasa) * 1.16;
                const precioComisionSIVA = comisionBaseSIva + incrementoPorTasaSIVA;
                const preciofinalSIVA = producto.totalSinIva + precioComisionSIVA;

                const descuento = producto.descuento / 100;

                const precioconIna1 = preciofinalSIVA * (1 - descuento);

                const precioconIna2 = precioconIna1 * 1.16
                // console.log('el presico con IVA es', precioconIna2)

                const precioMensualSIVA = preciofinalSIVA / meses;


                return {
                    sku: producto.sku,
                    precioMensualSIVA: parseFloat(precioMensualSIVA.toFixed(2)),
                    precioFinalSIVA: parseFloat(preciofinalSIVA.toFixed(2)),
                    precioconIna2: roundToTwoDecimals(precioconIna2) // Añadir este valor para su suma posterior

                };
            });

            // Sumar todos los precioconIna2 y añadir preciofinalEnvio
            const totalPrecioconIna2 = plazosProductosSinIva.reduce((total, producto) => total + producto.precioconIna2, 0);
            // console.log('aklaklaklkla', totalPrecioconIna2)

            // Cálculo del envío sin IVA
            const comisionBaseEnvio = (totalenvio * 0.014) * 1.16;
            const incrementoPorTasaEnvio = (totalenvio * tasa) * 1.16;
            const precioComisionEnvio = comisionBaseEnvio + incrementoPorTasaEnvio;
            const preciofinalEnvio = totalenvio + precioComisionEnvio;
            const precioMensualEnvio = preciofinalEnvio / meses;

            const precioFinalTotal = totalPrecioconIna2 + preciofinalEnvio;

            let msi = false;
            if (parseInt(meses) === losMsison) {
                precioFinal = precioFinal;
                precioMensual = precioFinalTotal / losMsison;
                msi = true;
            }

            return {
                meses,
                precioMensual: parseFloat(precioMensual.toFixed(2)),
                precioFinal: parseFloat(precioFinal.toFixed(2)),
                plazosProductosSinIva,
                precioMensualEnvio: parseFloat(precioMensualEnvio.toFixed(2)),
                precioFinalEnvio: parseFloat(preciofinalEnvio.toFixed(2)),
                msi
            };
        });

        nuevosPlazos.unshift({
            meses: "1",
            precioMensual: totalfinalConEnvio,
            precioFinal: totalfinalConEnvio,
            plazosProductosSinIva: totalSinIva.map(producto => ({
                sku: producto.sku,
                precioMensualSIVA: producto.totalSinIva,
                precioFinalSIVA: producto.totalSinIva,
            })),
            precioMensualEnvio: totalenvio,
            precioFinalEnvio: totalenvio,
            msi: false
        });

        setPlazos(nuevosPlazos);
    };

    useEffect(() => {
        calcularPlazos();
    }, [losMsison, totalfinalConEnvio]);

    const seleccionarPlazo = (plazo) => {
        enviarPagofinal(plazo);
    };

    const imagenes = [
        imgSantander,
        imgBanorte,
        imgScotiabank,
        imgInbursa,
        imgHsbc,
        imgBcoAzteca
    ];

    return (
        <div className='h-screen'>
            <div className='px-5'>
                <div className='lg:w-4/5 mx-auto'>
                    <h3 className=' text-xl mb-4'>Bancos participantes</h3>
                </div>

                <div className="flex flex-wrap justify-center pb-2 border-b-2 border-gray-300 ">
                    {imagenes.map((url, index) => (
                        <div key={index} className="w-1/3 p-2 flex justify-center">
                            <img width="300" height="200"
 src={url} alt={`Logo ${index + 1}`}
                                className="h-6 w-24 object-contain" // Mantiene el tamaño fijo de las imágenes
                            />
                        </div>
                    ))}
                </div>
                <p className='text-xs text-center mt-3'>*Si el pago es en una sola exhibición, se puede hacer con cualquier tarjeta. Para pagos a meses, solo con bancos participantes.</p>

                <div className='mt-4 lg:w-4/5 mx-auto'>
                    <h3 className='text-xl mb-4'>Elige las mensualidades</h3>
                </div>

                <div className='border border-gray-100 rounded lg:w-4/5 mx-auto'>
                    {plazos.map((plazo, index) => (
                        <div key={index} onClick={() => seleccionarPlazo(plazo)} className={`transition delay-100 mt-2 border-b py-8 rounded px-4 border-gray-200 cursor-pointer hover:bg-[#1a2869] hover:text-white  flex justify-between ${plazo.msi ? 'text-[#09A956] font-black' : ''}`}>
                            <div className='flex'>
                                <h2 className='text-sm font-black mr-2 '>{plazo.meses}x </h2>
                                <h3 className='text-base'>
                                    <span className="inline-block">
                                        {plazo.precioMensual.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('.')[0]}.
                                        <span className="text-xs" style={{ verticalAlign: 'top' }}>
                                            {plazo.precioMensual.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('.')[1]}
                                        </span>
                                    </span>
                                    {plazo.msi && <span className="text-xs ml-1">MSI</span>}
                                </h3>
                            </div>

                            <div className='flex'>
                                <h2 className='text-lg mr-2 tracking-wide'>
                                    <span className="inline-block">
                                        {plazo.precioFinal.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('.')[0]}.
                                        <span className="text-xs" style={{ verticalAlign: 'top' }}>
                                            {plazo.precioFinal.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split('.')[1]}
                                        </span>
                                    </span>
                                </h2>
                                <p><i className="fas fa-chevron-right"></i></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PaginaMesesCredito;
