import { useEffect, useRef, useState } from "react";
import ProductCard from "./ProductCard";

function TblTechnicalSpecifications({ products }) {
  const scrollRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 2));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(products.length - 2, prevIndex + 2)
    );
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: -scrollRef.current.clientWidth / 2.5,
        behavior: "smooth",
      });
    }
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 2));
  };

  // Función para desplazar a la derecha
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: scrollRef.current.clientWidth / 2.5,
        behavior: "smooth",
      });
    }
    setCurrentIndex((prevIndex) =>
      Math.min(products.length - 2, prevIndex + 2)
    );
  };
  return (
    <section className="relative py-12 md:py-24">
      <div className="container mx-auto px-4 md:px-6">
        <div className="space-y-4 mb-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="grid gap-2 pb-2 md:pb-0">
              <h2 className="text-3xl font-bold">
                Comparación de Características Técnicas
              </h2>
              <p className="text-muted-foreground">
                Descubre cómo los Compresores ForceElement se comparan entre sí.
              </p>
            </div>
            {/* <a
              href="https://hantec.us/Categorias/Compresores"
              className="bg-[#facc15] text-[#1a2869] inline-flex items-center justify-center whitespace-nowrap text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-9 rounded-md px-3"
            >
              Comprar ahora
            </a> */}
          </div>
          {/* <div className="overflow-x-scroll flex md:grid md:grid-cols-5 gap-4">
      {products?.map((product)=>{
          return <ProductCard key={product?.id_producto} product={product}/>
        })}        
      </div> */}
          {/* Vi erste producot en el siatio web y estoy intersado */}
          {/* {!isMobile && (
            <div className="md:grid md:grid-cols-5 gap-4">
              {products?.map((product) => (
                <ProductCard key={product?.id_producto} product={product} />
              ))}
            </div>
          )} */}

          {/* {isMobile && (
            <div className="relative overflow-hidden">
              <div
                className="flex transition-transform duration-300 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 50}%)` }}
              >
                {products?.map((product) => (
                  <div
                    key={product?.id_producto}
                    className="w-1/2 flex-shrink-0"
                  >
                    <ProductCard product={product} />
                  </div>
                ))}
              </div>
              <button
                onClick={handlePrev}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
                disabled={currentIndex === 0}
                style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  width="25"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8 .4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z" />
                </svg>
              </button>
              <button
                onClick={handleNext}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
                disabled={currentIndex >= products.length - 2}
                style={{
                  opacity: currentIndex >= products.length - 2 ? 0.5 : 1,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  width="25"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z" />
                </svg>
              </button>
            </div>
          )} */}
        </div>
        <div ref={scrollRef} className="overflow-x-auto">
          <table className="w-full text-left order-collapse">
            <thead>
              <tr className="bg-muted bg-gray-100">
                <th className="sticky left-0 h-fit bg-gray-100 px-4 py-3 font-bold ">
                  Características
                </th>
                <th className="px-4 py-3 font-bold">ForceElement HFT9X927</th>
                <th className="px-4 py-3 font-bold">ForceElement HFT9X937</th>
                <th className="px-4 py-3 font-bold">ForceElement HFT9X910</th>
                <th className="px-4 py-3 font-bold">ForceElement HFT9X920</th>
                <th className="px-4 py-3 font-bold">ForceElement HFT9X930</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium"></td>
                <td className="px-4 py-3">
                  <ProductCard product={products[0]} />
                </td>
                <td className="px-4 py-3">
                  <ProductCard product={products[1]} />
                </td>
                <td className="px-4 py-3">
                  <ProductCard product={products[2]} />
                </td>
                <td className="px-4 py-3">
                  <ProductCard product={products[3]} />
                </td>
                <td className="px-4 py-3">
                  <ProductCard product={products[4]} />
                </td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Tipo
                </td>
                <td className="px-4 py-3">2 en 1</td>
                <td className="px-4 py-3">3 en 1</td>
                <td className="px-4 py-3">4 en 1</td>
                <td className="px-4 py-3">4 en 1</td>
                <td className="px-4 py-3">4 en 1</td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Voltaje
                </td>
                <td className="px-4 py-3">220V/60Hz/1Ph</td>
                <td className="px-4 py-3">220V/60Hz/1Ph</td>
                <td className="px-4 py-3">220V/60Hz/3Ph</td>
                <td className="px-4 py-3">220V/60Hz/3Ph</td>
                <td className="px-4 py-3">220V/60Hz/3Ph</td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Potencia Motor (HP)
                </td>
                <td className="px-4 py-3">5</td>
                <td className="px-4 py-3">5</td>
                <td className="px-4 py-3">10</td>
                <td className="px-4 py-3">20</td>
                <td className="px-4 py-3">30</td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Máxima Presión de trabajo
                </td>
                <td className="px-4 py-3">10 Bar / 145 Psi</td>
                <td className="px-4 py-3">10 Bar / 145 Psi</td>
                <td className="px-4 py-3">10 Bar / 145 Psi</td>
                <td className="px-4 py-3">15 Bar / 217 Psi</td>
                <td className="px-4 py-3">15 Bar / 217 Psi</td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Caudal de Aire Máximo
                </td>
                <td className="px-4 py-3">350 L/min / 12 CFM</td>
                <td className="px-4 py-3">350 L/min / 12 CFM</td>
                <td className="px-4 py-3">900 L/min / 31 CFM</td>
                <td className="px-4 py-3">2000 L/min / 70 CFM</td>
                <td className="px-4 py-3">3000 L/min / 106 CFM</td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Capacidad Tanque
                </td>
                <td className="px-4 py-3">100L</td>
                <td className="px-4 py-3">130L</td>
                <td className="px-4 py-3">250L</td>
                <td className="px-4 py-3">400L</td>
                <td className="px-4 py-3">400L</td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Conexión
                </td>
                <td className="px-4 py-3">G1/2"</td>
                <td className="px-4 py-3">G1/2"</td>
                <td className="px-4 py-3">G3/4"</td>
                <td className="px-4 py-3">G3/4"</td>
                <td className="px-4 py-3">G1</td>
              </tr>
              <tr className="border-b">
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Peso Neto
                </td>
                <td className="px-4 py-3">125 Kg</td>
                <td className="px-4 py-3">165 Kg</td>
                <td className="px-4 py-3">350 Kg</td>
                <td className="px-4 py-3">510 Kg</td>
                <td className="px-4 py-3">555 Kg</td>
              </tr>
              <tr>
                <td className="sticky left-0 h-fit bg-white px-4 py-3 font-medium">
                  Dimensiones (mm)
                </td>
                <td className="px-4 py-3">1020 x 540 x 1035</td>
                <td className="px-4 py-3">1020 x 540 x 1035</td>
                <td className="px-4 py-3">1430 x 800 x 1580</td>
                <td className="px-4 py-3">1850 x 950 x 1870</td>
                <td className="px-4 py-3">1851 x 950 x 1870</td>
              </tr>
            </tbody>
          </table>

          <button
            onClick={scrollLeft}
            className="md:hidden absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
            // disabled={currentIndex === 0}
            style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              width="25"
              viewBox="0 0 320 512"
            >
              <path d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z" />
            </svg>
          </button>
          <button
            // onClick={handleNext}
            onClick={scrollRight}
            className="md:hidden absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full transition-opacity duration-300 ease-in-out"
            // disabled={currentIndex >= products.length - 2}
            style={{
              opacity: currentIndex >= products.length - 2 ? 0.5 : 1,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="25"
              width="25"
              viewBox="0 0 320 512"
            >
              <path d="M285.5 273L91.1 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.7-22.7c-9.4-9.4-9.4-24.5 0-33.9L188.5 256 34.5 101.3c-9.3-9.4-9.3-24.5 0-33.9l22.7-22.7c9.4-9.4 24.6-9.4 33.9 0L285.5 239c9.4 9.4 9.4 24.6 0 33.9z" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}

export default TblTechnicalSpecifications;
