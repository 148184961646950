import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/LogoBlanco.png";
import cellphone from "../../assets/cellphone.webp";
import { useEffect } from "react";
import { SnipTrafficPlatform } from "../../utils/Analitycs/analytics";
import { dateDiffFromNow } from "../../utils/utils";

const Category = ({ title, image, path }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(path);
      }}
      className="flex items-center hover:cursor-pointer justify-between bg-[#ffcc02] hover:bg-yellow-500 outer-element rounded-3xl p-1 sm:p-2 mx-4 my-4 shadow-lg lg:w-3/5 min-w-min max-w-xl sm:mx-auto "
    >
      <img
        src={image}
        className=" bg-blue-900 inner-element rounded-full w-10 h-10 flex items-center justify-center"
      />
      <h2 className="flex-1 text-center text-lg text-[#141b4d] font-black pl-4 sm:pl-0">
        {title}
      </h2>
    </div>
  );
};

function ViewsLinks() {
  const location = useLocation();
  const whatsappMessage = `Hola Hantec, me gustaría hablar con un asesor respecto a`;
  const whatsappLink = `https://api.whatsapp.com/send?phone=525593311600&text=${encodeURIComponent(
    whatsappMessage
  )}`;

  const list_categories = [
    {
      title: "LLANTERA",
      path: "/etiquetas/Llantera",
      image:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/1_b2d6a361-5d8a-4c60-bbf6-3e6e56d2da47.jpg2024-04-30%2015%3A35%3A02.003811",
    },
    {
      title: "MECÁNICO",
      path: "/etiquetas/Taller%20Mecánico",
      image:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/CARRUCELHGP96_Mesadetrabajo1_2.jpg2024-03-28%2019%3A06%3A27.293064",
    },
    {
      title: "HOJALATERÍA",
      path: "/etiquetas/Hojalatería%20y%20Pintura",
      image:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/2024-03-20%2021%3A10%3A49.132831",
    },
    {
      title: "MOTOCICLETAS",
      path: "/Categorias/Motos",
      image:
        "https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/general/categorias/Motos2024-07-05%2020%3A00%3A25.952182",
    },
  ];

  useEffect(() => {
    const extractUTMParameters = () => {
      const searchParams = new URLSearchParams(location.search);

      const utmSource = searchParams.get("utm_source");
      const utmMedium = searchParams.get("utm_medium") || "desconocido";
      const utmCampaign = searchParams.get("utm_campaign");
      const utmContent = searchParams.get("utm_content") || "desconocido";

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      const lastVisitDateStr = window.localStorage.getItem("utm");
      const lastVisitDate = lastVisitDateStr
        ? new Date(JSON.parse(lastVisitDateStr).date)
        : null;
      lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

      if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
        window.localStorage.removeItem("utm");
      }

      if (utmSource && utmMedium && utmCampaign) {
        const valuesUTM = {
          utmSource: utmSource,
          utmMedium: utmMedium,
          utmCampaign: utmCampaign,
          utmContent: utmContent,
          date: today,
        };
        window.localStorage.setItem("utm", JSON.stringify(valuesUTM));
        SnipTrafficPlatform(utmSource, utmCampaign, utmContent);
      }
    };

    extractUTMParameters();
  }, [location.search]);

  return (
    <div className="bg-gradient-to-t from-[#007bfc] to-[#141b4d] min-h-screen min-w-full flex flex-col items-center pt-16">
      <div className="mb-8 mt-16">
        <a href="https://hantec.us/" target="_blank" rel="noopener noreferrer">
          <img src={Logo} className="h-16" alt="Logo" />
        </a>
      </div>
      <div className="grid grid-cols-4 gap-6">
        <a
          href="https://www.facebook.com/InnovacionHantec/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f text-white text-2xl lg:text-3xl"></i>
        </a>
        <a
          href="https://www.instagram.com/hantec_innovacion/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram text-white text-2xl lg:text-3xl"></i>
        </a>
        <a
          href="https://youtube.com/@hantecinnovacionautomotriz7670?feature=shared"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-youtube text-white text-2xl lg:text-3xl"></i>
        </a>
        <a
          href="https://www.tiktok.com/@hantec_innovacion?lang=es"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-tiktok text-white text-2xl lg:text-3xl"></i>
        </a>
      </div>

      <div className="w-full">
        <h2 className="text-white text-center text-xl p-4 mt-8 font-thin">
          Categorias
        </h2>
        {list_categories.map((category) => (
          <Category
            key={category.title}
            title={category.title}
            path={category.path}
            image={category.image}
          />
        ))}
        {/* static mx-10 mt-16 lg:w-3/5 lg:mx-auto */}
        <div className="relative">
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="fixed bottom-10 left-10 sm:left-20 2xl:left-1/4 rounded-full bg-[#ffcc02] hover:bg-yellow-500 w-14 h-14 flex items-center justify-center shadow-lg"
          >
            <i className="fab fa-whatsapp text-[#141b4d] text-4xl"></i>
          </a>
          <a
            href="tel:5593311600"
            className="fixed bottom-10 right-10 sm:right-20 2xl:right-1/4 rounded-full bg-[#ffcc02] hover:bg-yellow-500 w-14 h-14 p-2 flex items-center justify-center shadow-lg"
          >
            <img src={cellphone} alt="icono de telefono" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ViewsLinks;
