import { Fragment, useState, useEffect } from 'react';
import { Dialog, Popover, Transition, Disclosure, Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import logo from '../../assets/Logo1.webp';
import logoatzo from '../../assets/images.webp';
import imgallproCombos from '../../assets/allcombos.webp';
import imgallpro from '../../assets/allproducts.webp';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useProductsCart } from '../../store/bookStore';
import ModalCodigoPostal from '../modales/ModalCodigoPostal';
import SearchBar from './SearchBar';
import { useProductStore } from '../../store/bookStore';
import { API_URL } from '../../constants/constants';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Navbar2() {
    const { carts } = useProductsCart();
    const location = useLocation();
    const currentPageUrl = window.location.origin + location.pathname;
    const whatsappMessage = `Hola, Estaba navegando en ${currentPageUrl}, me podrias dar detalles sobre sus productos`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=525593311600&text=${encodeURIComponent(whatsappMessage)}`;
    const [cartCount, setCartCount] = useState(0);
    const token = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
    const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : null;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const gotocar = () => {
        navigate('/car-store');
    };

    const gologin = () => {
        navigate('/login');
    };

    const goOut = () => {
        localStorage.removeItem('k-9SMD879na89dN87m');
        window.location.href = '/';
        setOpen(false);
    };

    const goHome = () => {
        navigate('/');
    };

    const goContact = () => {
        navigate('/Contact');
    };

    const goComparation = () => {
        navigate('/Comparation');
    };

    const goSoporte = () => {
        navigate('/soporte');
    };

    const goperfil = () => {
        navigate('/Mi-Perfil');
    };

    const gofavoritos = () => {
        navigate('/Favoritos');
    };

    const goKnowUs = () => {
        navigate('/Conocenos');
    };

    const gopedidos = () => {
        navigate('/Pedidos');
    };

    const goBlog = () => {
        navigate('/Blog');
    };

    const goComparador = () => {
        navigate('/comparation');
    };

    const goOpiniones = () => {
        navigate('/Opiniones');
    };

    const goAllCategories = () => {
        navigate('/allProducts');
    };

    const goCombos = () => {
        navigate('/combos');
    };

    const goAdmin = () => {
        navigate('/dashboard');
    };

    const goAdminOrders = () => {
        navigate('/list_orders');
    };

    const categorias = [
        { idcategoria: 1, titulo: 'Llantera' },
        { idcategoria: 2, titulo: 'Taller Mecánico' },
        { idcategoria: 3, titulo: 'Hojalatería y Pintura' },
        { idcategoria: 4, titulo: 'Industria' },
    ];

    const { products, fetchProducts } = useProductStore();

    const goCategories = (categoryId) => {
        fetchProducts(categoryId);
        navigate(`/Categorias/${categoryId}`);
    };

    const goEtiqueta = (titulo) => {
        navigate(`/etiquetas/${encodeURIComponent(titulo)}`);
    };

    const [codigoPostalYColonia, setCodigoPostalYColonia] = useState({
        codigoPostal: '',
        colonia: ''
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCodigoPostalChange = (datos) => {
        setCodigoPostalYColonia(datos);
        setIsModalOpen(false);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const navigation = {
        categories: [
            {
                id: 'women',
                name: 'Productos',
                featured: [
                    {
                        name: 'SPOTTER ',
                        href: '#',
                        imageSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-YxHAFPuI65c9VzsCl4rAqGiztDSVOmRE3paoXGd0pQ&s',
                        imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
                    },
                    {
                        name: 'Rampa',
                        href: '#',
                        imageSrc: 'https://http2.mlstatic.com/D_NQ_NP_2X_975714-MLM72165587798_102023-F.webp',
                        imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
                    },
                ],
                sections: [
                    {
                        id: 'Rampas',
                        name: 'Catálogo',
                        items: [
                            { name: 'Alienadoras', href: '#' },
                            { name: 'Rampas', href: '#' },
                            { name: 'Balanceadora', href: '#' },
                            { name: 'Herramientas', href: '#' },
                            { name: 'Desmontadoras', href: '#' },
                            { name: 'Generadoras de Nitrógeno', href: '#' },
                            { name: 'Gatos Hidráulicos', href: '#' },
                            { name: 'Torres', href: '#' },
                            { name: 'Prensas', href: '#' },
                            { name: 'Laboratorio', href: '#' },
                            { name: 'Tinas', href: '#' },
                            { name: 'Pluma Hidráulica', href: '#' },
                            { name: 'Spotters', href: '#' },
                            { name: 'Compresores', href: '#' },
                        ],
                    },
                ],
            },
        ],
        pages: [
            { name: 'Conócenos', action: goKnowUs, page: '/Conocenos' },
            { name: 'Contacto', action: goContact, page: '/Contact' },
        ]
    };

    const [showSearchBar, setShowSearchBar] = useState(false);

    const toggleSearchBar = () => setShowSearchBar(!showSearchBar);

    const cerrarsearch = () => {
        setShowSearchBar(false);
    };

    const [categoriasList, setCategoriasList] = useState([]);
    const [isCategoriesFetched, setIsCategoriesFetched] = useState(false);

    const getCategories = async () => {
        if (isCategoriesFetched) return; // Evitar múltiples llamadas
        const cachedCategories = localStorage.getItem('categoriasList');

        if (cachedCategories) {
            setCategoriasList(JSON.parse(cachedCategories));
            setIsCategoriesFetched(true); // Marcar como obtenido
        } else {
            try {
                const response = await axios.get(API_URL + '/getAllCategories', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    timeout: 10000
                });

                const activeCategories = response.data.filter(category => category.activo === true);
                setCategoriasList(activeCategories);
                localStorage.setItem('categoriasList', JSON.stringify(activeCategories));
                setIsCategoriesFetched(true); // Marcar como obtenido
            } catch (error) {
                console.error("Error al obtener las categorías:", error);
                if (error.code === 'ECONNABORTED') {
                    console.log('Tiempo de espera agotado en categorías ');
                }
            }
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (carts && carts.length > 0) {
            setCartCount(carts.length);
        } else {
            const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : [];
            setCartCount(cartLocal.length);
        }
    }, [carts]);

    useEffect(() => {
        const handleStorageChange = () => {
            const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : [];
            if (!carts || carts.length === 0) {
                setCartCount(cartLocal.length);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [carts]);



    const handleCategoriesClick = (isOpen) => {
        if (isOpen) {
            getCategories();
        }
    };
    return (
        <div className="bg-white mt-1">
            {/* Mobile menu option */}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full pr-4 flex-col overflow-y-auto bg-white pb-12 shadow-xl rounded-md">
                                <div className="items-center pb-2 pt-5 px-2 bg-[#1e3d8b]">
                                    <button
                                        type="button"
                                        name='btn-closemenu2'
                                        id='btn-closemenu2'
                                        className="relative inline-flex items-center justify-center px-1.5 py-1 text-white rounded-full text-2xl"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <i className="fas fa-times"></i>
                                    </button>
                                    {token && (
                                        <div className="flex w-4/5 mx-auto text-center mt-3 text-white mb-8 pl-4">
                                            <i className="far fa-user-circle text-5xl mt-2"></i>
                                            <div className='text-left pl-8'>
                                                <h2 className='font-montserrat text-3xl'>
                                                    <span className='font-black mr-1'>MI</span>
                                                    PERFIL
                                                </h2>
                                                <a href='/Mi-Perfil' className='cursor-pointer text-lg'>{token['$name']}<i className="ml-2 fas fa-angle-double-right"></i> </a>
                                            </div>
                                        </div>
                                    )}
                                    {!token && (
                                        <div className='w-4/5 mx-auto text-white text-center text-lg mb-8 mt-4'>
                                            <a href='/login' aria-label='btn-iralogin' className='bg-[#E9BF56] py-2 px-5 text-black text-sm hover:bg-opacity-70 hover:text-gray-200'>
                                                Inicia sesión o Regístrate
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                                    <div className="-my-6 divide-y divide-gray-500/10">
                                        <div className="space-y-2 py-2">
                                            <Disclosure as="div" className="-mx-3">
                                                {({ open }) => (
                                                    <>
                                                        <a
                                                            onClick={goHome}
                                                            className={`-mx-3 block rounded-lg px-3 py-2 ml-0 text-base font-semibold text-[#152a6e] leading-7  hover:bg-gray-50 ${location.pathname === '/' ? 'text-blue-600' : 'text-[#152a6e]'} cursor-pointer`}
                                                        >
                                                            Inicio
                                                        </a>
                                                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base leading-7 font-semibold text-[#152a6e] hover:bg-gray-50">
                                                            Productos
                                                            <ChevronDownIcon
                                                                className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                                aria-hidden="true"
                                                            />
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className="mt-2 ml-5 border-l-2 border-gray-500 space-y-2">
                                                            <Disclosure.Button
                                                                onClick={goAllCategories}
                                                                className={`cursor-pointer block rounded-lg py-2 pl-2 text-sm font-medium text-[#152a6e] leading-7 hover:bg-gray-50 ${location.pathname === '/NuestrasCategorias' ? 'text-blue-600' : 'text-[#152a6e]'} `}
                                                            >
                                                                Catálogo
                                                            </Disclosure.Button>
                                                            {categorias.map((categoria, index) => (
                                                                <Disclosure.Button
                                                                    key={index} // Asegúrate de que cada categoría tenga un ID único
                                                                    as="a"
                                                                    onClick={() => goEtiqueta(categoria.titulo)}
                                                                    className="cursor-pointer block rounded-lg py-2 pl-2 text-sm font-medium text-[#152a6e] leading-7 hover:bg-gray-50"
                                                                >
                                                                    {categoria.titulo}
                                                                </Disclosure.Button>
                                                            ))}
                                                            <Disclosure.Button
                                                                onClick={goCombos}
                                                                className={`cursor-pointer block rounded-lg py-2 pl-2 text-sm font-medium text-[#152a6e] leading-7 hover:bg-gray-50 ${location.pathname === '/NuestrasCategorias' ? 'text-blue-600' : 'text-[#152a6e]'} `}
                                                            >
                                                                Combos
                                                            </Disclosure.Button>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                            {token && (
                                                <div>
                                                    <a
                                                        onClick={gopedidos}
                                                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold ${location.pathname === '/Pedidos' ? 'text-[#007bfc]' : 'text-[#152a6e]'} leading-7 hover:bg-gray-50 cursor-pointer`}
                                                    >
                                                        Mis Pedidos
                                                    </a>
                                                    <a
                                                        onClick={gofavoritos}
                                                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold ${location.pathname === '/Favoritos' ? 'text-[#007bfc]' : 'text-[#152a6e]'} leading-7 hover:bg-gray-50 cursor-pointer`}
                                                    >
                                                        Favoritos
                                                    </a>
                                                    <a
                                                        onClick={goOpiniones}
                                                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold ${location.pathname === '/Opiniones' ? 'text-[#007bfc]' : 'text-[#152a6e]'} leading-7 hover:bg-gray-50 cursor-pointer`}
                                                    >
                                                        Opiniones
                                                    </a>
                                                    {token && (token['$r07sad'] === 'imda' || token['$r07sad'] === 'dkommita') ? (
                                                        <a
                                                            onClick={goComparador}
                                                            className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold ${location.pathname === '/comparation' ? 'text-[#007bfc]' : 'text-[#152a6e]'} leading-7 hover:bg-gray-50 cursor-pointer`}
                                                        >
                                                            Comparador
                                                        </a>
                                                    ) : null}
                                                </div>
                                            )}
                                            {navigation.pages.map((page) => (
                                                <button
                                                    name={page.name}
                                                    id={page.name}
                                                    key={page.name}
                                                    onClick={page.action}
                                                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold ${location.pathname === page.page ? 'text-[#007bfc]' : 'text-[#152a6e]'} leading-7 hover:bg-gray-50`}
                                                >
                                                    {page.name}
                                                </button>
                                            ))}
                                            {token && (token['$r07sad'] === 'dkommita' || token['$r07sad'] === 'imda') && (
                                                <div className="pl-3">
                                                    <a
                                                        onClick={goAdmin}
                                                        className="flex items-center justify-start -mx-3 rounded-lg px-1 py-2 text-base font-semibold text-[#152a6e] leading-7 hover:bg-gray-50 cursor-pointer"
                                                    >
                                                        <i className="fas fa-tools mr-2"></i> {/* Icono de Font Awesome para logout */}
                                                        Panel Administración
                                                    </a>
                                                </div>
                                            )}
                                            {token && token['$r07sad'] === 'order_viewer' && (
                                                <div className="pl-3">
                                                    <button
                                                        id='admingo'
                                                        name='admingo'
                                                        onClick={goAdminOrders}
                                                        className="flex items-center justify-start -mx-3 rounded-lg px-1 py-2 text-base font-semibold text-[#152a6e] leading-7 hover:bg-gray-50 cursor-pointer"
                                                    >
                                                        <i className="fas fa-tools mr-2"></i> {/* Icono de Font Awesome para logout */}
                                                        Panel Administración
                                                    </button>
                                                </div>
                                            )}
                                            {token && (
                                                <div className="px-4 py-6 border-t border-gray-200">
                                                    <button
                                                        name='goOut'
                                                        id='goOut'
                                                        onClick={goOut}
                                                        className="flex items-center justify-start -mx-3 rounded-lg px-1 py-2 text-base font-semibold text-[#152a6e] leading-7 hover:bg-gray-50 cursor-pointer"
                                                    >
                                                        <i className="fas fa-sign-out-alt mr-2"></i> {/* Icono de Font Awesome para logout */}
                                                        Cerrar sesión
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <header className="relative bg-white">
                <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-2">
                    <div className="">
                        <div className="flex h-20 items-center">
                            <button
                                name='btn-openmenu2'
                                id='btn-openmenu2'
                                type="button"
                                className="relative rounded-md bg-white p-1 text-[#1e3d8b] text-xl font-black lg:hidden"
                                onClick={() => setOpen(true)}
                            >
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Open menu</span>
                                <i className="fas fa-bars"></i>
                            </button>
                            {/* Logo */}
                            <div className="ml-2 flex lg:ml-0">
                                <button onClick={goHome} className='cursor-pointer'>
                                    <span className="sr-only">Your Company</span>
                                    {token && token['$r07sad'] === 'Tienda' ? (
                                        <img width="300" height="200" className="h-16 w-auto" src={logoatzo} alt="Logo Alternativo" />
                                    ) : (
                                        <img width="300" height="200" className="h-10 w-auto" src={logo} alt="Logo Hantec" />
                                    )}
                                </button>
                            </div>
                            {isModalOpen &&
                                <ModalCodigoPostal
                                    codigoPostal={codigoPostalYColonia.codigoPostal}
                                    setCodigoPostal={handleCodigoPostalChange}
                                    onClose={toggleModal}
                                />
                            }
                            {/* Flyout menus */}
                            <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                                <div className="flex h-full space-x-8">
                                    <Popover.Group className="hidden lg:block lg:self-stretch z-50">
                                        <div className="flex h-full space-x-8">
                                            {navigation.categories.map((category) => (
                                                <Popover key={category.name} className="flex">
                                                    {({ open }) => (
                                                        <>
                                                            <div className="relative flex">
                                                                <Popover.Button
                                                                    className={classNames(
                                                                        open ? 'border-[#007bfc] text-[#007bfc]' : 'border-transparent text-[#152a6e] hover:text-[#007bfc]',
                                                                        'relative z-10 -mb-px flex items-center border-b-2 pt-px text-xs font-semibold uppercase transition-colors duration-200 ease-out'
                                                                    )}
                                                                    onClick={() => handleCategoriesClick(open)}
                                                                >
                                                                    {category.name} <i className="fas fa-angle-down ml-2"></i>
                                                                </Popover.Button>
                                                            </div>
                                                            {categoriasList.length > 0 && (
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-200"
                                                                    enterFrom="opacity-0"
                                                                    enterTo="opacity-100"
                                                                    leave="transition ease-in duration-150"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Popover.Panel className="fixed inset-x-0 top-20 z-50 p-5 bg-white border-t border-[#1e3d8b]" style={{ height: '50%' }}>
                                                                        <div className="grid grid-cols-8 gap-4 h-auto bg-white border-b-2 border-[#1e3d8b] pb-5 text-white">
                                                                            <div className="cursor-pointer flex flex-col items-center group">
                                                                                <div className='text-center mt-1 w-full'>
                                                                                    <p className='font-robotoch font-semibold text-[#1e3d8b] group-hover:text-[#007bfc]'>
                                                                                        Todos los productos
                                                                                    </p>
                                                                                    <button onClick={goAllCategories} className="flex justify-center items-center mx-auto mt-2">
                                                                                        <img width="300" height="200" src={imgallpro} alt='Todos los productos' className="w-14 h-14 object-cover transform group-hover:scale-110 transition-transform duration-300 md:w-20 md:h-20 lg:w-24 lg:h-24" />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="cursor-pointer flex flex-col items-center group">
                                                                                <div className='text-center items-center mt-1 w-full'>
                                                                                    <p className='font-robotoch font-semibold text-[#1e3d8b] group-hover:text-[#007bfc]'>
                                                                                        Combos
                                                                                    </p>
                                                                                    <button onClick={goCombos} className="flex justify-center items-center mx-auto mt-2">
                                                                                        <img width="300" height="200" src={imgallproCombos} alt='Todos los productos' className="w-14 h-14 mx-auto object-cover transform group-hover:scale-110 transition-transform duration-300 md:w-20 md:h-20 lg:w-24 lg:h-24" />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            {categoriasList.map((category, index) => (
                                                                                <div key={index} className="cursor-pointer flex flex-col items-center group">
                                                                                    <div className='text-center mt-1 w-full'>
                                                                                        <p className='font-robotoch font-semibold text-[#1e3d8b] group-hover:text-[#007bfc]'>{category?.nombre}</p>
                                                                                    </div>
                                                                                    <button onClick={() => goCategories(category?.nombre)} className="flex justify-center mt-2">
                                                                                        <img width="300" height="200" src={category?.imagen} alt={category?.nombre} className="w-14 h-14 object-cover transform group-hover:scale-110 transition-transform duration-300 md:w-20 md:h-20 lg:w-24 lg:h-24" />
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Popover.Panel>
                                                                </Transition>
                                                            )}
                                                        </>
                                                    )}
                                                </Popover>
                                            ))}
                                        </div>
                                    </Popover.Group>
                                    {navigation.pages.map((page) => (
                                        <button
                                            key={page.name}
                                            name={page.name}
                                            id={page.name}
                                            onClick={page.action}
                                            className={`flex items-center cursor-pointer text-xs font-semibold ${location.pathname === page.page ? 'text-[#007bfc] cursor-pointer' : 'text-[#152a6e]'} hover:text-[#007bfc] uppercase`}
                                        >
                                            {page.name}
                                        </button>
                                    ))}
                                    {token && (token['$r07sad'] === 'imda' || token['$r07sad'] === 'dkommita') ? (
                                        <button
                                            onClick={goComparador}
                                            className={`flex items-center cursor-pointer text-xs font-semibold ${location.pathname === '/comparation' ? 'text-[#007bfc] cursor-pointer' : 'text-[#152a6e]'} hover:text-[#007bfc] uppercase`}
                                            id='comparador'
                                            name='comparador'
                                        >
                                            Comparador
                                        </button>
                                    ) : null}
                                    <button
                                        className="flex items-center cursor-pointer text-xs font-semibold text-[#152a6e] hover:text-[#007bfc] hover:text-md uppercase"
                                        onClick={toggleModal}
                                        id='cpdatosnav'
                                        name='cpdatosnav'
                                    >
                                        <i className="fas fa-map-marker-alt"></i>
                                        <h2 className='mx-1 uppercase font-semibold'>
                                            Ingresa
                                        </h2>
                                        <p>tu código postal</p>
                                    </button>
                                </div>
                            </Popover.Group>
                            <div className="ml-auto flex items-center lg:hidden">
                                <div className="flex lg:ml-4">
                                    <button onClick={toggleSearchBar} className="p-1 cursor-pointer text-[#152a6e] text-2xl hover:text-[#007bfc]">
                                        <span className="sr-only">Search</span>
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                                {/* Whatsapp */}
                                <div className="flex lg:ml-4">
                                    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="p-2 text-[#152a6e] text-2xl hover:text-[#007bfc]">
                                        <span className="sr-only">send whatsapp</span>
                                        <i className="fab fa-whatsapp"></i>
                                    </a>
                                </div>
                                {/* Cart */}
                                <div className="ml-2 flow-root lg:ml-4 relative">
                                    <button onClick={gotocar} className={`group cursor-pointer ${location.pathname === '/car-store' ? 'text-[#007bfc]' : 'text-[#152a6e]'} -m-1.5 flex items-center p-2 hover:text-[#007bfc]`}>
                                        <span className={`absolute -top-1.5 -right-1.5 ${location.pathname === '/car-store' ? 'bg-[#007bfc]' : 'bg-[#152a6e]'} text-white text-xs font-semibold mr-3 px-0.5 py-0.5 rounded-full`}>
                                            {cartCount}
                                        </span>
                                        <span className="sr-only">send whatsapp</span>
                                        <i className="fa fa-shopping-cart text-xl"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="ml-auto hidden lg:flex items-center">
                                <div className="flex lg:ml-2">
                                    <button onClick={toggleSearchBar} className="p-2 text-[#152a6e] text-lg hover:text-[#007bfc] cursor-pointer">
                                        <span className="sr-only">Search</span>
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                                {/* Whatsapp */}
                                <div className="flex">
                                    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="p-2 text-[#152a6e] text-2xl hover:text-[#007bfc] cursor-pointer">
                                        <span className="sr-only">send whatsapp</span>
                                        <i className="fab fa-whatsapp"></i>
                                    </a>
                                </div>
                                {/* User */}
                                <div className="flex lg:ml-2">
                                    <Menu as="div" className="relative">
                                        <div>
                                            <Menu.Button className="relative flex rounded-full text-lg text-[#152a6e] focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 hover:text-[#007bfc]">
                                                <i className="fas fa-user"></i>
                                                <span className="sr-only">send whatsapp</span>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {!token && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={gologin}
                                                                className={classNames(active ? 'bg-gray-100' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                Iniciar sesión/Registrarse
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                {token && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={goperfil}
                                                                className={`${location.pathname === '/Mi-Perfil' ? 'bg-gray-200 text-[#007bfc]' : 'text-gray-700'} cursor-pointer block px-4 py-2 text-sm`}
                                                            >
                                                                Mi perfil
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                {token && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={gopedidos}
                                                                className={`${location.pathname === '/Pedidos' ? 'bg-gray-200 text-[#007bfc]' : 'text-gray-700'} cursor-pointer block px-4 py-2 text-sm`}
                                                            >
                                                                Mis Pedidos
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                {token && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={gofavoritos}
                                                                className={`${location.pathname === '/Favoritos' ? 'bg-gray-200 text-[#007bfc]' : 'text-gray-700'} cursor-pointer block px-4 py-2 text-sm`}
                                                            >
                                                                Mis Favoritos
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                {token && (token['$r07sad'] === 'dkommita' || token['$r07sad'] === 'imda') && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={goAdmin}
                                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                Panel Administración
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                {token && (token['$r07sad'] === 'order_viewer') && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={goAdminOrders}
                                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                Panel Administración
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                {token && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={goOut}
                                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                Cerrar sesión
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                {/* favoritos */}
                                <div className="flex lg:ml-1">
                                    <button onClick={gofavoritos} className="p-2 text-[#152a6e] text-lg hover:text-[#007bfc] cursor-pointer">
                                        <span className="sr-only">favoritos</span>
                                        <i className="far fa-heart"></i>
                                    </button>
                                </div>
                                {/* Cart */}
                                <div className="ml-2 flow-root lg:ml-1 relative">
                                    <button onClick={gotocar} className={`group cursor-pointer ${location.pathname === '/car-store' ? 'text-[#007bfc]' : 'text-[#152a6e]'} -m-2 flex items-center p-2 hover:text-[#007bfc]`}>
                                        <span className={`absolute -top-1.5 -right-2 ${location.pathname === '/car-store' ? 'bg-[#007bfc]' : 'bg-[#152a6e]'} text-white text-xs font-semibold mr-3 px-0.5 py-0.5 rounded-full`}>
                                            {cartCount}
                                        </span>
                                        <i className="fa fa-shopping-cart text-lg"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            {showSearchBar && <SearchBar onclose={cerrarsearch} />}
        </div>
    );
}
