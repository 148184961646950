import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function TablaClientes({ 
    clientesConCompra, 
    clientesSinCompra, 
    clientesDistribuidor, 
    todoslosclientes, 
    mostrarClientes, 
    setMostrarClientes, 
    paginaActualConCompra, 
    setPaginaActualConCompra, 
    paginaActualSinCompra, 
    setPaginaActualSinCompra, 
    paginaActualDistribuidor, 
    setPaginaActualDistribuidor 
}) {
   
    const elementosPorPagina = 20;


    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null


    const navigate = useNavigate();

    const paginar = (array, numeroPagina) => {
        return array.slice((numeroPagina - 1) * elementosPorPagina, numeroPagina * elementosPorPagina);
    };

    const addDistribuidor = (client_id) => {
        if (!client_id) {
            // Aquí se maneja el caso de agregar un nuevo distribuidor sin un client_id específico
            navigate('/agregar-distribuidor');
            return;
        }
        // Aquí se maneja el caso con un client_id específico
        navigate('/agregar-distribuidor', { state: { contacto: client_id } });
    };



    const copiarAlPortapapeles = (texto) => {
        navigator.clipboard.writeText(texto).then(() => {
            alert('Copiado al portapapeles');
        }).catch((error) => {
            console.error('Error al copiar al portapapeles:', error);
        });
    };


    const renderFilaCliente = (cliente) => (
        <tr key={cliente.client_id} className="border-b">
            <td onClick={() => copiarAlPortapapeles(cliente.name)} className="p-3 cursor-pointer">{cliente.name}</td>

            <td className="p-3 hover:text-blue-600 cursor-pointer" >
                <span onClick={() => copiarAlPortapapeles(cliente.email)}>{cliente.email}</span>
                {cliente.email !== 'N/A' && (
                    <i
                        className="fas fa-copy ml-2"
                        onClick={() => copiarAlPortapapeles(cliente.email)}

                    ></i>
                )}
            </td>

            <td className="p-3 hover:text-blue-600 cursor-pointer" >
                <span onClick={() => copiarAlPortapapeles(cliente.telefono || 'N/A')}>
                    {cliente.telefono || 'N/A'}
                </span>
                {cliente.telefono && cliente.telefono !== 'N/A' && (
                    <i
                        className="fas fa-copy ml-2"
                        onClick={() => copiarAlPortapapeles(cliente.telefono)}
                    ></i>
                )}
            </td>

            <td className="p-3 text-center" style={{ cursor: 'pointer' }} onClick={(e) => {
                e.stopPropagation(); // Detener la propagación para evitar clics en elementos padres
                if (cliente.num_dist) {
                    addDistribuidor(cliente.client_id);
                }
            }}>
                {cliente.num_dist
                    ? <span className='bg-[#1a2869] hover:bg-[#007bfc] transition-colors duration-300 py-2 px-5 text-white rounded'>{cliente.num_dist} <i className="fas fa-edit"></i> </span>
                    : 'N/A'}
            </td>
        </tr>
    );
    const Paginacion = ({ totalClientes, paginaActual, setPaginaActual }) => {
        const numeroPaginas = Math.ceil(totalClientes / elementosPorPagina);
        const maxVisiblePages = 3;

        const renderPages = () => {
            const pages = [];

            if (paginaActual > 1) {
                pages.push(
                    <button
                        name='btn-precpage'
                        id='btn-precpage'
                        key="prev"
                        onClick={() => setPaginaActual(paginaActual - 1)}
                        className="px-4 py-2 rounded-lg text-white transition-colors duration-300 bg-gray-400 hover:bg-gray-400"
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                );
            }

            // Página 1 fija
            pages.push(
                <button
                    name='btn-paginaActual'
                    id='btn-paginaActual'
                    key={1}
                    onClick={() => setPaginaActual(1)}
                    className={`px-4 py-2 rounded-lg text-white transition-colors duration-300 ${paginaActual === 1
                        ? 'bg-blue-600 hover:bg-blue-700'
                        : 'bg-gray-400 hover:bg-gray-400'
                        }`}
                >
                    1
                </button>
            );

            // Siempre mostrar los puntos suspensivos después de la primera página

            // Páginas ajustables
            let startPage = Math.max(paginaActual, 2);
            let endPage = Math.min(startPage + 1, numeroPaginas - maxVisiblePages);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(
                    <button

                        name='btn-paginaActual2'
                        id='btn-paginaActual2'
                        key={i}
                        onClick={() => setPaginaActual(i)}
                        className={`px-4 py-2 rounded-lg text-white transition-colors duration-300 ${paginaActual === i
                            ? 'bg-blue-600 hover:bg-blue-700'
                            : 'bg-gray-400 hover:bg-gray-400'
                            }`}
                    >
                        {i}
                    </button>
                );
            }

            // Siempre mostrar los puntos suspensivos antes de las últimas páginas
            pages.push(
                <span key="end-ellipsis" className="px-4 py-2">...</span>
            );

            // Últimas 3 páginas fijas
            for (let i = Math.max(numeroPaginas - 2, 2); i <= numeroPaginas; i++) {
                pages.push(
                    <button
                        key={i}
                        name='btn-ulm3paginas'
                        id='btn-ulm3paginas'
                        onClick={() => setPaginaActual(i)}
                        className={`px-4 py-2 rounded-lg text-white transition-colors duration-300 ${paginaActual === i
                            ? 'bg-blue-600 hover:bg-blue-700'
                            : 'bg-gray-400 hover:bg-gray-400'
                            }`}
                    >
                        {i}
                    </button>
                );
            }

            if (paginaActual < numeroPaginas) {
                pages.push(
                    <button
                        key="next"
                        name='btn-nextpage'
                        id='btn-nextpage'
                        onClick={() => setPaginaActual(paginaActual + 1)}
                        className="px-4 py-2 rounded-lg text-white transition-colors duration-300 bg-gray-400 hover:bg-gray-400"
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                );
            }

            return pages;
        };

        return (
            <div className="flex justify-center items-center space-x-2 my-4">
                {renderPages()}
            </div>
        );
    };



    return (


        <div>
            {/*cambiar por order_viewer depsues de las pruebas  */}
          

            {mostrarClientes === 'todos' ? (
                <>
                    <h2 className="text-xl font-semibold my-4">Todos los clientes</h2>
                    <table className="w-full table-auto shadow-lg bg-white">
                        <thead className="bg-[#163d6d] text-white">
                            <tr>
                                <th className="p-3">Nombre</th>
                                <th className="p-3">Email</th>
                                <th className="p-3">Teléfono</th>
                                <th className="p-3">Número de distribuidor</th>

                            </tr>
                        </thead>
                        <tbody>
                            {paginar(todoslosclientes, paginaActualConCompra).map(renderFilaCliente)}
                        </tbody>
                    </table>
                    <Paginacion totalClientes={todoslosclientes.length} paginaActual={paginaActualConCompra} setPaginaActual={setPaginaActualConCompra} />
                </>
            ) : mostrarClientes === 'conCompra' ? (
                <>
                    <h2 className="text-xl font-semibold my-4">Clientes con Compra</h2>
                    <table className="w-full table-auto shadow-lg bg-white">
                        <thead className="bg-blue-700 text-white">
                            <tr>
                                <th className="p-3">Nombre</th>
                                <th className="p-3">Email</th>
                                <th className="p-3">Teléfono</th>
                                <th className="p-3">Número de distribuidor</th>

                            </tr>
                        </thead>
                        <tbody>
                            {paginar(clientesConCompra, paginaActualConCompra).map(renderFilaCliente)}
                        </tbody>
                    </table>
                    <Paginacion totalClientes={clientesConCompra.length} paginaActual={paginaActualConCompra} setPaginaActual={setPaginaActualConCompra} />
                </>
            ) : mostrarClientes === 'sinCompra' ? (
                <>
                    <h2 className="text-xl font-semibold my-4">Clientes sin Compra</h2>
                    <table className="w-full table-auto shadow-lg bg-white">
                        <thead className="bg-red-700 text-white">
                            <tr>
                                <th className="p-3">Nombre</th>
                                <th className="p-3">Email</th>
                                <th className="p-3">Teléfono</th>
                                <th className="p-3">Número de distribuidor</th>

                            </tr>
                        </thead>
                        <tbody>
                            {paginar(clientesSinCompra, paginaActualSinCompra).map(renderFilaCliente)}
                        </tbody>
                    </table>
                    <Paginacion totalClientes={clientesSinCompra.length} paginaActual={paginaActualSinCompra} setPaginaActual={setPaginaActualSinCompra} />
                </>
            ) : mostrarClientes === 'distribuidor' && (

                <>
                    <h2 className="text-xl font-semibold my-4">Distribuidores</h2>

                    <table className="w-full table-auto shadow-lg bg-white">
                        <thead className="bg-green-700 text-white">
                            <tr>
                                <th className="p-3">Nombre</th>
                                <th className="p-3">Email</th>
                                <th className="p-3">Teléfono</th>
                                <th className="p-3">Número de distribuidor</th>

                            </tr>
                        </thead>
                        <tbody>
                            {paginar(clientesDistribuidor, paginaActualDistribuidor).map(renderFilaCliente)}
                        </tbody>
                    </table>
                    <Paginacion totalClientes={clientesDistribuidor.length} paginaActual={paginaActualDistribuidor} setPaginaActual={setPaginaActualDistribuidor} />
                </>
            )}
        </div>
    );
}

export default TablaClientes;
