import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import { API_URL } from '../../constants/constants';
import Sidebar from '../../componentes/Admi/Sidebar';


const IndividualCategoria = () => {
    const location = useLocation();
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

    const navigate = useNavigate();


    const [categoria, setCategoria] = useState({
        id_categoria: '',
        nombre: '',
        descripcion: '',
        imagen: '',
        activo: false,
    });
    const [imagePreview, setImagePreview] = useState('');


    useEffect(() => {
        if (location.state?.categoria) {
            setCategoria(location.state.categoria);
            setImagePreview(location.state.categoria.imagen);

        }
    }, [location.state]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCategoria({ ...categoria, imagen: file });

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Inicia la carga

        // Verificaciones básicas para asegurar que los campos requeridos están presentes
        if (!categoria.nombre) {
            setIsLoading(false); // Inicia la carga

            toastError('Debes ingresar un nombre de categoría.');
            return;
        }

        if (!categoria.descripcion) {
            setIsLoading(false); // Inicia la carga

            toastError('Debes ingresar una descripción.');
            return;
        }

        // Verifica si se está creando una nueva categoría y, de ser así, si se ha cargado una imagen
        if (!categoria.id_categoria && (!categoria.imagen || typeof categoria.imagen !== 'object')) {
            setIsLoading(false); // Inicia la carga

            toastError('Debes cargar una imagen para la categoría.');
            return;
        }

        const formdata = {
            nombre_categoria: categoria.nombre,
            descripcion_categoria: categoria.descripcion,
            activo: categoria.activo
        };


        //console.log('la iamgen es ', categoria.imagen)
        // Añade la imagen solo si se ha especificado una nueva durante la creación o actualización
        if (categoria.imagen && typeof categoria.imagen === 'object') {

            formdata.imagen = imagePreview; // Aquí suponemos que imagePreview es un string en base64

        } if (categoria.imagen && typeof categoria.imagen === 'string') {
            // Aquí asumimos que categoria.imagen es una URL o una cadena en base64
            // No necesitas convertir o ajustar la imagen, simplemente la envías tal como está
            formdata.imagen = categoria.imagen
        }



        const config = {
            headers: {
                'Authorization': info['$tk3m09s'],
                'Content-Type': 'application/json' // Asegúrate de que tu backend espera este tipo de contenido

            },
            timeout: 100000000
        };

        try {
            if (categoria.id_categoria) {
                // Actualizar categoría (no se exige nueva imagen, pero se permite actualizarla)
                formdata.id_categoria = categoria.id_categoria;
                formdata.id_usuario = info['$udi09345']

                //       console.log('vpy a enviar actulizar ', formdata)

                const response = await axios.post(API_URL + '/updateCategory', formdata, config);


                //        console.log('Categoría actualizada con éxito:', response.data);
                setIsLoading(false); // Inicia la carga
                toast.success('Categoría actualizada con éxito!');
                navigate(-1); // Navegar a la página anterior tras éxito

            } else {


                //       console.log('vpy a enviar crear ', formdata)

                // Crear nueva categoría (se requiere imagen)
                const response = await axios.post(API_URL + '/createCategory', formdata, config);
                //       console.log('Categoría creada con éxito:', response);
                setIsLoading(false); // Inicia la carga

                toast.success('Categoría creada con éxito!');
                navigate(-1); // Navegar a la página anterior tras éxito

            }

        } catch (error) {
            console.error('Error al realizar la operación:', error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }
            setIsLoading(false); // Inicia la carga

            toastError('Error al realizar la operación. Por favor, intenta de nuevo.');
        }
    };


    const toggleActivo = () => {
        setCategoria({ ...categoria, activo: !categoria.activo });
    };

    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }



    return (
        <div className='p-4 sm:ml-64'>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            <div className='flex items-center mb-4'>
                <Link className='pr-5' to={'/CategoriesPage'}><i className="fa-solid fa-arrow-left"></i></Link>
                <p className='text-xl font-black '> {categoria.id_categoria ? 'Actualizar' : 'Agregar'} Categoria</p>
            </div>
            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <form onSubmit={handleSubmit} className='bg-gray-100 p-5 w-4/5'>

                    <div className='mt-5 mb-10 '>
                        {imagePreview ? (
                            <label htmlFor="category-image-upload" className="cursor-pointer ">

                                <img width="300" height="200"
 src={imagePreview} alt="Previsualización de imagen" className='w-40 h-40' />
                            </label>

                        ) : (
                            <label htmlFor="category-image-upload" className="border-2 p-2 border-gray-400 cursor-pointer h-10 w-1/4">

                                Arrastra o selecciona una imagen para cargar
                            </label>

                        )}
                        <input id="category-image-upload" type="file" accept="image/*" className="hidden" onChange={handleImageChange} />
                    </div>

                    <div>
                        <p className='mt-8'>Nombre de la categoría:</p>
                        <input type="text" value={categoria.nombre} className='border border-gray-900 px-2 rounded h-10 w-1/4 mt-2' onChange={(e) => setCategoria({ ...categoria, nombre: e.target.value })} />

                        <p className='mt-8'>Descripción de la categoría:</p>
                        <input type="text" value={categoria.descripcion} className='border border-gray-900 px-2 rounded h-10 w-1/4 mt-2' onChange={(e) => setCategoria({ ...categoria, descripcion: e.target.value })} />
                        <div className='mt-5'>
                            <p className='mb-4'>Estado de la categoria (inactiva/activa): </p>
                            <label
                                htmlFor="toggleActivo"
                                className={`relative inline-block h-8 w-14 cursor-pointer rounded-full transition-colors ${categoria.activo ? 'bg-green-500' : 'bg-red-500'
                                    }`}
                            >
                                <input
                                    type="checkbox"
                                    id="toggleActivo"
                                    className="peer sr-only"
                                    checked={categoria.activo}
                                    onChange={toggleActivo}
                                />
                                <span
                                    className={`absolute inset-y-0 left-0 m-1 h-6 w-6 rounded-full bg-white transition-transform ${categoria.activo ? 'translate-x-6' : 'translate-x-0'
                                        }`}
                                ></span>
                            </label>
                        </div>
                    </div>


                    <button type="submit" className='bg-[#1e3d8b] text-white h-10 w-40 mt-8 rounded hover:bg-[#007bfc]'>{categoria.id_categoria ? 'Actualizar' : 'Agregar'}</button>
                </form>
            )}

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
};

export default IndividualCategoria;