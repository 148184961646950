import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../componentes/Headers/Header';
import Footer from '../../../componentes/footer/Footer';
import axios from 'axios';
import RiseLoader from "react-spinners/RiseLoader";
import { API_URL } from '../../../constants/constants';


function Opiniones() {
    let navigate = useNavigate();

    const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    const [pedidos, setPedidos] = useState([]);
    const [cargandoPedidos, setCargandoPedidos] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const getPedidos = async () => {
            const data = { id_usuario: datosPerfil['$udi09345'] };

            try {
                let response = await axios.post(API_URL + "/getOrdersNoCommentsByUserId", data, { headers: { 'Authorization': datosPerfil['$tk3m09s'] }, timeout: 15000 });

                // console.log('los pedidos entregados son',  response.data);
                setPedidos(response.data)
                setCargandoPedidos(false);
            } catch (error) {
                console.error('Error al obtener los pedidos: ' + error.toString());
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out getOrdersNoCommentsByUserId');
                }

                setCargandoPedidos(false);
            }
        };

        getPedidos();
    }, []);



    const goopinar = (idpedido, idpdrocut, nombre, imagenurl) => {

        navigate('/opinarEspecifico', { state: { idpedido: idpedido, idpdrocut: idpdrocut, nameproduct: nombre, ulrimg: imagenurl, user_id: datosPerfil['$udi09345'] } });


    }

    return (



        <div className='bg-white'>
            <Header />

            <div className=' h-screen lg:w-4/5 mx-auto mt-3' >

                <div className='lg:flex w-full px-5 px-3 lg:px-0 pl-5'>
                    <h3 className='text-[#1e3d8b] font-black text-lg lg:text-2xl font-robotoch'>
                        Opina
                    </h3>
                    <h3 className='text-[#030303] font-black text-lg mt-0 lg:text-2xl lg:ml-2 font-robotoch'>
                        y  aconseja a otros
                    </h3>
                </div>

                {cargandoPedidos ? (
                    <div className="flex flex-col items-center justify-center mt-8 w-full h-96 lg:w-4/5 mx-auto">
                        <RiseLoader color="#1A2869" size={40} />
                        <p className='mt-10 text-[#1e3d8b] font-black uppercase text-xl'>Cargando productos...</p>
                    </div>) : (
                    <div className=''>
                        {pedidos.map((pedido) => (
                            <div key={pedido.id_pedido} className=' mt-2 w-full px-5 mx-auto rounded p-2'>
                                <h3 className='font-robotoch text-[#1e3d8b]  font-black text-base lg:text-2xl'>Pedido N°: {pedido.id_pedido}</h3>

                                <ul>
                                    {pedido.productos.map((producto, index) => (
                                        <li key={index} className='border border-gray-200 rounded-lg p-3'>
                                            <div className=' w-full flex'>

                                                <img width="300" height="200"
                                                    src={producto.imagen} className="w-20 h-20 object-cover" />
                                                <div className='ml-2'>
                                                    <p className='text-sm'>{producto.nombre}</p>


                                                    <button
                                                        className='bg-[#1e3d8b] text-white py-1 px-5 mt-1 rounded'
                                                        name='btn-goopinar'
                                                        id='btn-goopinar'
                                                        onClick={() => goopinar(pedido.id_pedido, producto.id_producto, producto.nombre, producto.imagen)}  // Modificado aquí
                                                    >
                                                        Opinar
                                                    </button>

                                                </div>

                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Footer />
        </div>


    );
}

export default Opiniones;
