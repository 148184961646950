import React, { useState } from 'react';
import Select from 'react-select';
import { customStyles } from '../../../constants/constants';

const ModalDescargaPedidos = ({ onClose, onDownload }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleDownload = () => {
        const selectedValues = selectedOptions.map(option => option.value);
        onDownload(selectedValues);
        onClose();
    };

    const options = [
        { value: 'Pendiente', label: 'Pendiente' },
        { value: 'Denegado', label: 'Denegado' },
        { value: 'Pagado', label: 'Pagado' },
        { value: 'Cancelado', label: 'Cancelado' }
    ];

    return (
        <div className="modal">
            <div className="modal-content w-1/3 my-2">
                <h2>Selecciona los estados de los pedidos</h2>
                <Select
                    isMulti
                    value={selectedOptions}
                    onChange={handleChange}
                    options={options}
                    styles={customStyles}
                    placeholder="Selecciona"

                />
                <button

                    name='btn-download5'
                    id='btn-download5'

                    className='bg-blue-900 text-white px-5 py-2 mt-2 mx-2 rounded' onClick={handleDownload}>
                    Descargar
                </button>
                <button

                    name='btn-cancelar5'
                    id='btn-cancelar5'
                    className='bg-gray-500 text-white px-5 py-2 mt-2 rounded' onClick={onClose}>
                    Cancelar
                </button>
            </div>
        </div>
    );
};

export default ModalDescargaPedidos;
