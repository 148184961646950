import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ComboCard from '../../../pages/productos/combos/ComboCard';
import { API_URL } from '../../../constants/constants';
// import { productToCart } from '../../../utils/PixelMeta/metaPixelEvents';

const ListaCombosHirizontal = ({ lstacombo }) => {
  const navigate = useNavigate();

  const [sliderKey, setSliderKey] = useState(0); // Añade una clave para forzar la re-renderización del Slider

  const goAllCombos = () => {
    navigate('/combos')
  }
  //ir a combo
  const goComboDetail = (idCombo) => {
    //console.log('-----El combo selecionado es:', idCombo)

    window.location.href = `/combonum/${idCombo}`;

  }


  useEffect(() => {
    // Forzar la re-renderización del Slider una vez que los productos están listos
    setSliderKey(prevKey => prevKey + 1);
  }, [lstacombo]); // Dependencia a 'productos' para actualizar cuando cambie






  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth <= 1240 ? 5 : 6,
    slidesToScroll: 1,
    initialSlide: 0, // Asegúrate de que el valor inicial es 0
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1440, // Nuevo breakpoint para pantallas grandes
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
        },
      },
    ],
  };


  return (
    <div className='lg:mt-4' >
      <div className=" mt-2 lg:w-4/5 px-4 lg:px-0 mx-auto flex justify-between">
        <div className='lg:flex mb-10 mt-8'>
          <h2 className='text-2xl uppercase font-semibold lg:text-4xl'>
            Productos
          </h2>
          <h3 className='text-lg mt-0 text-[#1e3d8b] uppercase lg:ml-1 lg:text-4xl'> Ralacionados </h3>
        </div>

        <div className='lg:pt-6 pt-7'>
          <a className='text-xs uppercase cursor-pointer  lg:ml-10 lg:text-base  font-montserrat hover:text-[#007bfc] hover:font-semibold ' onClick={goAllCombos} >Ver todos</a>

        </div>



      </div>
      <Slider {...settings} key={sliderKey}> {/* Usa la clave para forzar la re-renderización */}
        {lstacombo.filter(combo => combo.active).slice(0, 5).map((combo) => (
          <div               key={combo.id_combo}
          className='lg:px-1 mx-auto mx- py-2 lg:py-4'>
            <ComboCard
              combo={combo}
              goComboDetail={goComboDetail}
            />
          </div>
        ))}
      </Slider>


    </div>
  );
};

export default ListaCombosHirizontal;
