import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../componentes/Headers/Header';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import Footer from '../../../componentes/footer/Footer';
import { API_URL } from '../../../constants/constants';
import ProductImages from '../../../componentes/productos/ProductImages';
import ComboDetails from '../../../componentes/productos/Combos/ComboDetails';
import { addToFavorites, goToPay } from '../../../utils/PixelMeta/metaPixelEvents';
import ComponenetesCombo from '../../../componentes/productos/Combos/ComponenetesCombo';
import ListaCombosHirizontal from '../../../componentes/productos/Combos/ListaCombosHirizontal';
import CargandoSpinner from '../../../componentes/cargacomponetes/CargandoSpinner';
import { dateDiffFromNow, getRandomInt } from '../../../utils/utils';
import { SnipTrafficPlatform } from '../../../utils/Analitycs/analytics';
import { cotizarCascotes } from '../../../utils/apiHelpers';
import { useProductsCart } from '../../../store/bookStore';
import FloatingWhatsAppButton from '../../../componentes/botones/FloatingWhatsAppButton';


function IndividualCombo() {
    let { id } = useParams();
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    // Recuperar valores del localStorage y asignar 'null' si no existen
    const storedCodigoPostal = localStorage.getItem('codigoPostal') !== null ? localStorage.getItem('codigoPostal') : null;
    const storedNeighborhood = localStorage.getItem('neighborhood') !== null ? localStorage.getItem('neighborhood') : null;

    const {  fetchCarts } = useProductsCart()
    const navigate = useNavigate();


    //z  console.log('combo selecionado es ', id)
    const [isLoading, setIsLoading] = useState(false);
    const [combo, setCombo] = useState([]);
    //funcines par aagregar a favoritos 
    const [isModalFavOpen, setIsModalFavOpen] = useState(false);
    //imagenes
    const [listaImagenesTransformada, setListaImagenesTransformada] = useState([]);
    const [imagenActiva, setImagenActiva] = useState({ imagen: '', titulo: '' });
    //precios 
    const [precioTotal, setPrecioTotal] = useState(0);
    const [precioTotalSinDescuento, setPrecioTotalSinDescuento] = useState(0);

    const [cantidadProductos, setCantidadProductos] = useState(0);
    //variable que indica si esta o no en favoritos 
    const [estaEnFavoritos, setEstaEnFavoritos] = useState(false);
    //variabel para lsita de combos 
    const [combos, setCombos] = useState([]);

    const [getNameOfOcurre, setGetNameOfOcurre] = useState(null);
    const location = useLocation()

    // Efecto para desplazarse a la parte superior al montar el componente
    useEffect(() => {
        window.scrollTo(0, 0);
        getComboWhitId()
        verifcarFavoritos()
        getCombos()
    }, []);

    const getComboWhitId = async () => {
        // console.log('entre a combos')
        setIsLoading(true);
        try {

            const formdata = {
                combo_id: id
            }
            const response = await axios.post(API_URL + '/getCombos', formdata);
            // console.log('Los Datos del combo elegido son padrino ', response.data.success)
            /// Calcular el total y la cantidad de productos
            const productos = response.data.success.productos;
            const skuList = productos.map(producto => producto.sku);

            // console.log('los SKU son',skuList)
            await obtenerProductosConSKU(skuList);  // Añadido 'await' aquí

            const totalPrecioDeceunto = productos.reduce((acc, producto) => acc + (producto.precio_descuento.cantidad * producto.cantidad), 0);
            const totalPrecioSinDescuento = productos.reduce((acc, producto) => acc + (producto.precio.cantidad * producto.cantidad), 0);

            const totalProductos = productos.length;

            setPrecioTotal(totalPrecioDeceunto);
            setPrecioTotalSinDescuento(totalPrecioSinDescuento)
            setCantidadProductos(totalProductos);

            setCombo(response.data.success)

        } catch (error) {
        }

        setIsLoading(false);
    };

    useEffect(() => {
        // Function to extract UTM parameters from the URL
        const extractUTMParameters = () => {

            const searchParams = new URLSearchParams(location.search);

            const utmSource = searchParams.get('utm_source');
            const utmMedium = searchParams.get('utm_medium') || 'desconocido';
            const utmCampaign = searchParams.get('utm_campaign');

            // const utmTerm = searchParams.get('utm_term');
            const utmContent = searchParams.get('utm_content') || 'desconocido';

            let today = new Date()

            // Set hours in zero
            today.setHours(0, 0, 0, 0);

            const lastVisitDateStr = localStorage.getItem('utm');
            const lastVisitDate = lastVisitDateStr ? new Date(JSON.parse(lastVisitDateStr).date) : null;
            lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

            // Delete localstorage if date is more than 2 daty
            if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
                window.localStorage.removeItem("utm")
            }

            if (utmSource && utmMedium && utmCampaign) {

                // Has values
                const valuesUTM = {
                    "utmSource": utmSource,
                    "utmMedium": utmMedium,
                    "utmCampaign": utmCampaign,
                    "utmContent": utmContent,
                    "date": today
                }
                window.localStorage.setItem("utm", JSON.stringify(valuesUTM))

                // ------------ Event Meta -------------- //
                SnipTrafficPlatform(utmSource, utmCampaign, utmContent)

                return
            }

        };

        // Call the function when the component mounts
        extractUTMParameters();
    }, [location.search]);

    // Efecto para actualizar la lista de imágenes cuando combo cambia
    useEffect(() => {
        //  console.log('bucle')
        if (combo.imagenes && combo.imagenes.length > 0) {
            const transformadas = combo.imagenes.map((imagen, index) => ({
                id_producto: index,
                imagen: imagen,
                titulo: `Imagen ${index + 1}`,
            }));
            setListaImagenesTransformada(transformadas);
            setImagenActiva({ imagen: transformadas[0].imagen, titulo: transformadas[0].titulo });

        } else if (combo.imagen) {
            const transformadas = [{
                id_producto: 0,
                imagen: combo.imagen,
                titulo: 'Imagen Principal',
            }];
            setListaImagenesTransformada(transformadas);
            setImagenActiva({ imagen: combo.imagen, titulo: combo.titulo });
        } else {
            setListaImagenesTransformada([{ id_producto: 0, imagen: '', titulo: 'Sin Imagen' }]);
            setImagenActiva({ imagen: '', titulo: '' });
        }
    }, [combo]); // Asegúrate de que combo es una dependencia aquí

    const verifcarFavoritos = async () => {
        const data = {
            "id_usuario": token['$udi09345'],
            "id_product": parseInt(id)

        };


        try {
            let response = await axios.post(API_URL + "/checkFavoriteList", data, { headers: { 'Authorization': token['$tk3m09s'] }, timeout: 15000 });

            // Convertimos el objeto de favoritos a un array para poder iterar sobre él más fácilmente
            // Verificamos también si el resultado es nulo o una cadena vacía
            let estaEnFavoritos = response.data.success;
            //     console.log('Esta en favotiros:', estaEnFavoritos);

            setEstaEnFavoritos(estaEnFavoritos)
        } catch (error) {
            console.error('Error al obtener los favoritos: ' + error.toString());

            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out checkFavoriteList');
            }
        }
    }

    //combo a favoritos

    const addfavorite = async () => {

        setIsLoading(true); // Inicia la carga
        // Verifica si token es null
        if (!token) {
            // Muestra alerta o abre modal aquí para indicar que no hay sesión
            setIsLoading(false);
            setIsModalFavOpen(true);
            return; // Sale de la función para evitar que se ejecute el resto del código
        }

        const dataProduct = {
            "id_producto": combo.id_combo,
            "precio": {
                "cantidad": precioTotal,
                "moneda": "MXN"
            },
            "titulo": combo.nombre_combo,
            "imagen": combo.imagen,
            "combo": true
        };

        const formdata = {
            id_usuario: token['$udi09345'],
            datos_producto: dataProduct
        }

        try {
            let event_id = getRandomInt()
            formdata["event_id"] = event_id
            const response = await axios.post(API_URL + "/addProductToFavorites", formdata, { headers: { Authorization: token['$tk3m09s'] }, timeout: 15000 });
            // console.log('res api', response.data);

            // -------- Pixel Meta ---------- //
            addToFavorites(
                combo.id_combo, // id_producto,
                combo.nombre_combo,// product_name
                precioTotal, // product_value
                token['$email'] ? token['$email'] : 'No registrado', // Email
                event_id, // id para eventos
                1, // quantity
            )
            // ------------------------------- //

            setIsLoading(false); // Finaliza la carga

        } catch (error) {
            console.log(error);
            console.error('Ha ocurrido un error.');
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out addProductToFavorites');
            }

        }

    };


    //combos para productos relacionados
    const getCombos = async () => {
        // console.log('entre a combos')
        setIsLoading(true);

        try {

            const response = await axios.get(API_URL + '/getCombos');

            //    console.log('La Lista de combos es padrino ', response.data.success)
            // Verifica que realmente llegas a esta línea
            //   console.log('Procesando los combos para añadir totales...');

            const combosWithTotals = response.data.success.map(combo => {
                //   console.log('Procesando combo:', combo); // Verifica la estructura de cada combo
                let totalConIva = 0;
                let totalSinIva = 0;
                let totalConDescuento = 0

                combo.productos.forEach(producto => {
                    //  console.log('Producto:', producto); // Verifica la estructura de cada producto
                    totalConIva += producto.precio.cantidad;
                    totalSinIva += producto.precio_sin_iva.cantidad;
                    totalConDescuento += producto.precio_descuento.cantidad;

                });

                //   console.log('Totales calculados para combo:', { totalConIva, totalSinIva });

                return {
                    ...combo,
                    totalConIva,
                    totalSinIva,
                    totalConDescuento
                };
            });

            //     console.log('aaaaaCombos con totales:', combosWithTotals);
            setCombos(combosWithTotals);
        } catch (error) {
        }

    };



    const [productosByChatify, setProductosByChatify] = useState([]);
    const [dtoCastores, setDtoCastores] = useState(null);

    //consulta a chatify
    const obtenerProductosConSKU = async (skuList) => {

        //  console.log('consultar medidas chatyfy', skuList)
        setIsLoading(true);  // Comienza la carga global

        const resultados = [];

        for (const sku of skuList) {
            try {
                const payload = { sku };
                const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getProductsBySku', payload, { timeout: 2000000 });
                const jsonData = response.data;
                if (jsonData.length !== 0) {
                    resultados.push(...jsonData);
                } else {
                    // console.log(`No data returned for SKU: ${sku}`);
                }
            } catch (error) {
                console.error(`Error al obtener los productos con el SKU: ${sku}`, error);
                if (error.code === 'ECONNABORTED') {
                    console.log(`Request timed out for SKU: ${sku}`);
                }
            }
        }
        // console.log('la lsita de chaty es ', resultados)

        if (storedCodigoPostal && storedNeighborhood) {
            try {
                const responseCastores = await cotizarCascotes(resultados, storedCodigoPostal, storedNeighborhood);

                console.log("Datos recibidos:", responseCastores);
                setDtoCastores(responseCastores)

                // Haz algo con la respuesta
            } catch (error) {
                console.error("Error en la cotización:", error);
            }
        } else {
            // console.log("Código postal o vecindario no están definidos.");
            // Manejar el caso en que no están definidos
        }

        setProductosByChatify(resultados); // Almacena los resultados en el estado
    };

    const goCotizar = async (codigoPostal, colonia) => {
        setIsLoading(true);  // Comienza la carga global
        setGetNameOfOcurre(null)


        // console.log(`Quoting for postal code: ${codigoPostal} and neighborhood: ${colonia}`);
        const responseCastores = await cotizarCascotes(productosByChatify, codigoPostal, colonia);
        // console.log("Datos recibidos:", responseCastores);

        setDtoCastores(responseCastores)
        setIsLoading(false);  // Comienza la carga global

    }


    const goCotizarOcurre = async (idOffice, nameOffice) => {
        setIsLoading(true);  // Comienza la carga global
        setGetNameOfOcurre(nameOffice)

        // console.log(`idofice: ${idOffice} nombre : ${nameOffice} cp: ${storedCodigoPostal}`);

        const responseCastores = await cotizarCascotes(productosByChatify, storedCodigoPostal, nameOffice, idOffice);
        // console.log("Datos recibidos:", responseCastores);

        setDtoCastores(responseCastores)

        setIsLoading(false);  // Comienza la carga global

    }


    const gopay = () => {
        setIsLoading(true);  // Comienza la carga global

        goToPay(precioTotal, token ? token['$email'] : 'No registrado');
        fetchCarts (token);
        navigate('/Pagos');
        setIsLoading(false);  // Comienza la carga global

    }

    const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;

    return (
        <div>
            <Header />
            <ScrollableCategories />

            {
                isLoading ? (
                    // Muestra el componente spinner cuando está cargando
                    <CargandoSpinner txtcharge='Cargando datos' />
                ) : (
                    <div className='w-full lg:w-4/5 mx-auto mt-1  flex-grow mb-2'>
                        <FloatingWhatsAppButton page="productos" categoryName={combo.nombre_combo} urlproduto={fullUrl} />

                        <section className="text-gray-600 body-font ">
                            <div className="container px-2 py-5 mx-auto border-t-2 border-gray-300 mt-6">
                                <h2 className="text-sm title-font text-gray-500 tracking-widest lg:hidden">HANTEC</h2>
                                <h1 className="text-gray-900 text-xl title-font font-semibold mb-1 font-robotoch lg:hidden">{combo.nombre_combo}</h1>

                                <div className="lg:w-full mx-auto flex flex-wrap ">


                                    <ProductImages
                                        listaImagenes={listaImagenesTransformada}
                                        imagenActiva={imagenActiva}
                                        setImagenActiva={setImagenActiva}
                                    />
                                    <ComboDetails
                                        dtoCombo={combo}
                                        total={precioTotal}
                                        totalSinDescuento={precioTotalSinDescuento}
                                        nproducts={cantidadProductos}
                                        enFavoritos={estaEnFavoritos}
                                        addfavorite={addfavorite}
                                        dtoCastores={dtoCastores}
                                        productosByChatify={productosByChatify}
                                        storedCodigoPostal={storedCodigoPostal}
                                        storedNeighborhood={storedNeighborhood}
                                        goCotizar={goCotizar}
                                        goCotizarOcurre={goCotizarOcurre}
                                        getNameOfOcurre={getNameOfOcurre}
                                        gopay={gopay}
                                    />





                                </div>
                            </div>
                        </section>

                        <ComponenetesCombo listaImagenesTransformada={listaImagenesTransformada} />

                        <ListaCombosHirizontal lstacombo={combos} />
                    </div>
                )
            }

            <Footer />

        </div>
    );
}

export default IndividualCombo;
