import { useEffect, useState } from 'react';
import ProductoCard from './Card';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoadWrapper from '../cards/LazyLoadWrapper';

const ListaProductos = ({ titulo, subtitulo, alldate, productos, categoria, cat }) => {
  const [sliderKey, setSliderKey] = useState(0);

  useEffect(() => {
    setSliderKey(prevKey => prevKey + 1);
  }, [productos]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth <= 1240 ? 5 : 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <div className='lg:mt-4'>
      <div className="mt-2 lg:w-4/5 px-4 lg:px-0 mx-auto flex justify-between">
        <div className='lg:flex mb-10 mt-6'>
          <h2 className='text-2xl uppercase font-semibold lg:text-4xl'>{titulo}</h2>
          <h3 className='text-lg mt-0 text-[#1e3d8b] uppercase lg:ml-1 lg:text-4xl'>{subtitulo}</h3>
        </div>
        <div className='lg:pt-6 pt-7'>
          <a className='text-xs uppercase cursor-pointer lg:ml-10 lg:text-base font-montserrat hover:text-[#007bfc] hover:font-semibold' aria-label='link-cateespacial' href={`/categoriasespeciales/${cat}`}>{alldate}</a>
        </div>
      </div>
      <div className='lg:px-5'>
        <Slider {...settings} key={sliderKey}>
          {productos?.filter(producto => producto.stock.disponible > 1)
            .slice(0, 6)
            .map(producto => (
              <div key={producto.id_producto} className='lg:px- py-2 flex justify-center lg:py-4'>
                <LazyLoadWrapper>
                  <ProductoCard producto={producto} />
                </LazyLoadWrapper>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default ListaProductos;
