import React from 'react';

const Tutulosubrtitulo = () => {

    return (
        <div className=' mt-1 w-full 	' >



            <div className=' mt-4 px-5 text-center lg:pl-12'>
                <div className='lg:flex flex-col lg:justify-center pt-5 '>
                    
                    <h1 className='text-[#030303] font-black text-2xl font-oswald lg:text-4xl '>CONTACTA</h1>
                    <h2 className='text-[#1e3d8b] font-black text-2xl  font-oswald tracking-wide lg:text-4xl'>CON NOSOTROS</h2>

                </div>

              <div className='lg:w-1/2 lg:mx-auto lg:mt-10'>
              <p className='text-sm mt-2 text-center font-montserrat lg:text-xl'>
                    Bienvenido a la página de contacto de HANTEC para ayudarle a resolver solicitudes de garantía y realizar consultas.                </p>

              </div>
            </div>


            



        </div>
    );
};

export default Tutulosubrtitulo;
