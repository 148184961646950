import React, { useEffect, useState } from 'react'; // Importar useState
import InputField from '../../inputs/InputField';
import SubmitButton from '../../botones/SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import validateEmail from '../../../utils/validateEmail';
import { useUserStore } from '../../../store/bookStore';
import { Link } from 'react-router-dom';
import { data } from 'jquery';
import { API_URL } from '../../../constants/constants';
// import { signin, viewSignin } from '../../../utils/PixelMeta/metaPixelEvents';


async function fetchUserInfo(accessToken) {
    const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch user info');
    }

    return await response.json();
}

function LoginformDistribuidor() {

    const [errorMsg, setError] = useState(null)
    const { setUser, user } = useUserStore();
    // inc()
    function setData() {
        const userData = { name: 'John Doe', email: 'johndoe@example.com' }
        setUser(userData)
    }


    // console.log(id)

    const navigate = useNavigate();

    const [isChecked, setIsChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [numTienda, setNumTienda] = useState('');



    const [password, setPassword] = useState('');

    //Manejo de carga
    const [loading, setLoading] = useState(false)

    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleError = (errorMessage) => {
        toastError(errorMessage)
    }


    const loginInto = async () => {
        setLoading(false)
        setError(null)
        if (email == '') {
            setLoading(false);
            toastError('Es necesario ingresar un nombre de tienda')
            return
        }

        if (numTienda == '') {
            setLoading(false);
            toastError('Es necesario ingresar un número de identificación');
            return
        }

        if (password == '') {
            setLoading(false);
            toastError('Es necesario ingresar una contraseña')
            return
        }


        const body = JSON.stringify({ store_name: email, num_dist: numTienda, password: password });
        try {
            const response = await fetch(API_URL + '/signinStores', {


                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });
            if (!response.ok) {

                return response.json().then(errorData => {
                    console.log(errorData)
                    toastError(errorData.error || 'Ha ocurrido un error.')
                });

            } else {
                let data_user = await response.json();

                // -------- Pixel Meta ---------- //
                // signin(email)

                localStorage.setItem('k-9SMD879na89dN87m', JSON.stringify(data_user));
                if (localStorage.getItem('cartLocal') !== null) {

                    var carrito = localStorage.getItem('cartLocal');
                    var carritoObj = JSON.parse(carrito);
                    //console.log(carritoObj);
                    carritoObj.forEach(item => {
                        item.email = data_user.$email;
                    });
                    // console.log(carritoObj);
                    var carritoJSON = JSON.stringify(carritoObj);
                    //console.log(carritoJSON);
                    var token = data_user.$tk3m09s;
                    // console.log('El token pa: '+token);
                    var carritoObj1 = JSON.parse(carritoJSON);
                    // console.log(carritoObj1[0]);
                    addToCartFromLogin(carritoObj1, token);
                } else {
                    // console.log('Carrito inexistente');
                    if (data_user['$r07sad'] == 'imda') {
                        navigate('/dashboard');
                    } else {
                        navigate('/');
                    }
                }
                if (data_user['$r07sad'] == 'imda') {
                    navigate('/dashboard');
                } else {
                    navigate('/');
                }
            }
            const data = await response.json();

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleStoreNameChange = (event) => {
        setEmail(event.target.value);
    }

    const handleNtiendaChange = (event) => {
        setNumTienda(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }



    const addToCartFromLogin = async (data, token) => {
        try {
            await axios.post(API_URL + "/updateShoppingCart", data);
            //await fetchCarts(token);
            // console.log(response)
            // console.log('Ahora el carrito es: ', carts)
            localStorage.removeItem('cartLocal');
            navigate('/');
        } catch (error) {
            console.error('Ha ocurrido un error al anadir productos.')
        }
    }



    return (
        <div className="w-full h-full">
            <div className="bg-white p-4  rounded-sm  w-full h-auto lg:mt-1">
                <div className="login-list flex justify-center my-5">
                    <h1 className='font-black text-2xl'>
                        <span className="text-black">INICIAR</span>
                        <span className='text-blue-700'> SESIÓN</span>
                    </h1>
                </div>
                <InputField label="" type="text" placeholder="Nombre de tienda" handleChange={handleStoreNameChange} autoComplete="on" />
                <InputField label="" type="text" placeholder="Número de distribuidor" handleChange={handleNtiendaChange} autoComplete="on" />

                <InputField label="" type="password" placeholder="Contraseña" handleChange={handlePasswordChange} autoComplete="on" />

                <SubmitButton buttonText="INICIAR" handleClic={loginInto} disabledInput={loading} color='#1a2869'
                    textColor='#ffffff'
                    hovercolor='#007bfc'
                    hovertextColor='#ffffff'

                />

            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default LoginformDistribuidor;
