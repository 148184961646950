import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { API_URL, customStyles } from '../../constants/constants';
import InputDos from '../inputs/Inputo2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Componente ModalFormAutoZone
const ModalFormAutoZone = ({ isOpen, onClose, onSeleccionarDireccion }) => {
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    //datos necesarios parael formualrio direcicon
    const [colonias, setColonias] = useState([]);
    const [estados, setEstados] = useState([]);
    const [isColoniaSelectDisabled, setIsColoniaSelectDisabled] = useState(true);
    const [temporalAddress, setTemporalAddress] = useState([]);
    const estadoInicial = {
        // nombre: '',
        partner_id: token?.['$od'] || '',
        id_cliente: token?.['$udi09345'] || '',
        contact_name: '',
        calle: '',
        noExterior: '',
        noInterior: '',
        colonia: '',
        cp: '',
        ciudad: '',
        id_estado: '',
        pais: 'México', // Puedes predefinir México como país
        telefono: '',
        email: token?.['$email'] || '', // Usa el operador de encadenamiento opcional aquí
        referencia: '',
        id_direccion: '20',
        principal: false // Aquí ajustas el valor basado en la existencia de 'token'

    };

    // Estado para manejar los datos del formulario
    const [formulario, setFormulario] = useState(estadoInicial);


    useEffect(() => {
        if (isOpen) {
            setFormulario(estadoInicial);
        }
    }, [isOpen]);

    useEffect(() => {
        EstadoConodoo()
        const handler = setTimeout(() => {
            if (formulario.cp) {
                handlePostalCodeChange(formulario.cp);
            } else {
                // Si el CP se limpia, también limpiamos las colonias y reseteamos el select
                setColonias([]);
                setFormulario({ ...formulario, colonia: '' }); // Asegúrate de limpiar la colonia seleccionada
                setIsColoniaSelectDisabled(true); // Deshabilitamos el select hasta que haya un CP válido
            }
        }, 500);

        return () => clearTimeout(handler);
    }, [formulario.cp]);



    //selecliopnar colonias 

    const selectOcolonias = colonias.map(colonia => ({
        value: `${colonia.text}`,
        label: `${colonia.text}`
    }));

    const handleColoniaChange = (selectedOption) => {
        setFormulario({ ...formulario, colonia: selectedOption.value });
    };
    const camposHabilitados = formulario.cp.length > 0;


    //buscar estado con odo 
    const EstadoConodoo = async () => {
        try {
            const response = await axios.get(API_URL + '/getStatesMexico');
            setEstados(response.data.success); // Asumiendo que response.data es un array de estados
        } catch (error) {
            console.error("Error al obtener los estados: ", error);
        }
    };

    //buscar direcicon 
    const handlePostalCodeChange = async (nuevoCodigoPostal) => {

        const formData = {
            cp: nuevoCodigoPostal,
        };


        try {
            const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getDistricts', formData, { timeout: 10000 });
            if (response.data && Array.isArray(response.data.data)) {

                setColonias(response.data.data);

                setIsColoniaSelectDisabled(false);
            } else {
                setColonias([]);
                setIsColoniaSelectDisabled(true);
            }
        } catch (error) {
            console.error("Error al obtener las colonias:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }

            setColonias([]);
            setIsColoniaSelectDisabled(true);
        }
    };

    const handleChange = (e) => {
        setFormulario({ ...formulario, [e.target.name]: e.target.value });
        //console.log(formulario);
    };

    //solicitar ayuda par ale codigo postal 
    const gohelpCP = () => {
        // console.log('ir a whats app a pedir ayuda')
        const whatsappMessage = `Hola, necesito ayuda para conocer mi código postal y registrarme en https://hantec.us/`;
        const whatsappLink = `https://api.whatsapp.com/send?phone=525593311600&text=${encodeURIComponent(whatsappMessage)}`;

        window.open(whatsappLink, "_blank");

    }

    //selecionar estado 

    const selectEstado = estados.map(estado => ({
        value: estado.id, // Ajusta estas propiedades según la estructura de tus datos
        label: estado.name
    }));


    const handleEstadoChange = (selectedOption) => {
        // convertir selectedOption en entero
        const valorComoEntero = parseInt(selectedOption.value, 10);
        setFormulario({ ...formulario, id_estado: valorComoEntero, estado: selectedOption.label });
    };


    const handleNoSeMiCodigo = (e) => {
        e.preventDefault();
        gohelpCP();
    };


    const handleFormSubmit = async (event) => {
        event.preventDefault(); // Prevenir el comportamiento predeterminado del formulario

        if (!formulario.contact_name.trim() || !formulario.email.trim() || !formulario.cp.trim() || !formulario.calle.trim() || !formulario.noExterior.trim() || !formulario.telefono.trim()) {
            toast.error("Por favor, completa todos los campos requeridos.");
            return;
        }


        try {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                }
            };

            let response = await axios.post(API_URL + "/setTemporalAddressStore", formulario, config);
            //  console.log('esto vopy a mansdar ', response.data.address_id)

            let addresdtos = {
                partner_shipping_id: response.data.address_id,
                temporal_address: true

            }

            onSeleccionarDireccion(formulario, addresdtos); // Utilizar la función pasada como prop para enviar los datos del formulario

        } catch (error) {
            console.error('Ha ocurrido un error al guardar la direccion: ' + error.toString());
        }

        // console.log('Datos del formulario:', formulario); // Imprimir los datos del formulario en consola
        onClose(); // Cierra el modal

    };




    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-4 md:pt-10 overflow-y-auto z-50">
            <div className="bg-white p-4 md:p-8 rounded-lg shadow-lg w-full xl:w-1/2 md:max-w-4xl mx-2 md:mx-auto">
                <div className=' flex justify-end items-end p-2'>
                    <button
                        name='btn-closemodalfatz'
                        id='btn-closemodalfatz'
                        onClick={onClose} className="text-red-500 text-2xl font-semibold">
                        <i className="fas fa-times"></i>
                    </button>
                </div>

                <h2 className="text-xl font-bold mb-4">Enviar a otra dirección</h2>
                <form onSubmit={handleFormSubmit} className="space-y-4 ">
                    {/*Nombre y email */}
                    <div className=' lg:flex'>
                        <div className="mb-4  lg:w-1/2 lg:px-2" >
                            <p>Nombre</p>
                            <input
                                label="Nombre"
                                name="contact_name"
                                type="text"
                                value={formulario.contact_name}
                                onChange={handleChange}
                                placeholder="Nombre"
                                className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                            />
                            <p className='mt-1 xl:ml-2 text-xs text-gray-800'>* Tal como aparece en tu INE o IFE</p>

                        </div>
                        <div className="mb-4 lg:w-1/2 lg:px-2" >
                            <p>Email</p>

                            <input
                                label="Email"
                                name="email"
                                type="text"
                                value={formulario.email}
                                onChange={handleChange}
                                placeholder="Email"
                                className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                            />

                        </div>
                    </div>
                    {/*CP y Colonia */}
                    <div className=' lg:flex'>
                        <div className="mb-4  lg:w-1/2 lg:px-2" >
                            <p htmlFor="" className=''>Código Postal</p>
                            <div className='flex flex-col'>
                                <input
                                    label='C.P.'
                                    name="cp"
                                    type="number"
                                    className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'
                                    placeholder="Código Postal"
                                    value={formulario.cp}
                                    onChange={handleChange}
                                />
                                <div>
                                    <button
                                        name='btn-nocopmodalfatz'
                                        id='btn-nocopmodalfatz'
                                        onClick={handleNoSeMiCodigo}
                                    >
                                        No se mi código
                                    </button>
                                </div>

                            </div>

                        </div>

                        <div className="mb-4  lg:w-1/2 lg:px-2" >
                            <p className=''>
                                Colonia
                            </p>
                            <Select
                                disabled={!camposHabilitados}
                                options={selectOcolonias}
                                value={selectOcolonias.find(option => option.value === formulario.colonia)} // Asegúrate de mostrar la opción seleccionada como el valor actual
                                onChange={handleColoniaChange} // Usar la función de manejo específica para Select
                                placeholder="Seleccione"
                                menuPlacement="auto" // o "top" según tu necesidad de UI
                                styles={customStyles}
                            />
                        </div>
                    </div>
                    {/*Ciudad Estado */}
                    <div className=' lg:flex'>

                        <div className="mb-4  lg:w-1/2 lg:px-2" >
                            <p>Ciudad</p>

                            <input
                                label='Ciudad'
                                name="ciudad"
                                type="text"
                                value={formulario.ciudad}
                                onChange={handleChange}
                                placeholder="Ciudad"
                                disabled={!camposHabilitados}
                                className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                            />
                        </div>

                        <div className="mb-4  lg:w-1/2 lg:px-2" >

                            <p className=' '>

                                Estado

                            </p>

                            <Select
                                disabled={!camposHabilitados}
                                options={selectEstado}
                                value={selectEstado.find(option => option.value === formulario.id_estado)} // Asegúrate de mostrar la opción seleccionada como el valor actual
                                onChange={handleEstadoChange} // Usar la función de manejo específica para Select
                                placeholder="Seleccione"
                                menuPlacement="auto" // o "top" según tu necesidad de UI
                                styles={customStyles}

                            />


                        </div>
                    </div>

                    <div className=' lg:flex'>
                        <div className="mb-4  lg:w-1/2 lg:px-2" >
                            <p>Pais</p>

                            <input
                                disabled={!camposHabilitados}
                                type="text"
                                label='Pais'
                                name="pais"
                                value={formulario.pais}
                                onChange={handleChange}
                                placeholder="País"
                                className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                            />
                        </div>
                        <div className="mb-4 lg:w-1/2 lg:px-2" >
                            <p>Calle</p>
                            <input
                                label='Dirección'
                                type="text"
                                name="calle"
                                value={formulario.calle}
                                placeholder="Calle"
                                onChange={handleChange}
                                className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                            />
                        </div>
                    </div>

                    <div className=' lg:flex'>
                        <div className="mb-4  lg:w-1/2 lg:px-2 flex" >
                            <div className='w-1/2 '>
                                <p>Num. Ext.</p>
                                <input
                                    label='Num. Ext.'
                                    type="number"
                                    name="noExterior"
                                    value={formulario.noExterior}
                                    onChange={handleChange}
                                    placeholder="Número "
                                    className='w-4/5 rounded md:w-1/2 lg:w-4/5 lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'
                                />
                            </div>
                            <div className='w-1/2'>
                                <p>Num. Int.</p>
                                <input
                                    label='Num. Int.'
                                    type="number"
                                    name="noInterior"
                                    value={formulario.noInterior}
                                    onChange={handleChange}
                                    placeholder="Número "
                                    className='w-4/5 rounded md:w-1/2 lg:w-4/5 lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                                />
                            </div>
                        </div>
                        <div className="mb-4 lg:w-1/2 lg:px-2" >

                            <p>Teléfono de contacto</p>
                            <input
                                label="Teléfono Contacto"
                                name="telefono"
                                type="tel"
                                value={formulario.telefono}
                                onChange={handleChange}
                                placeholder="Teléfono"
                                className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                            />
                        </div>


                    </div>
                    <div className=' w-full'>
                        <p>Datos de referencia</p>
                        <input
                            label="Datos de referencia"
                            name="referencia"
                            type="text"
                            value={formulario.referencia}
                            onChange={handleChange}
                            placeholder="Referencia"
                            className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'

                        />
                    </div>
                    <button
                        name='btn-submitmodalfatz'
                        id='btn-submitmodalfatz'
                        type="submit" className="w-full my-4 bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded">
                        Enviar a esta dirección
                    </button>
                </form>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </div>
    );
};

export default ModalFormAutoZone;
