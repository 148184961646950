import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function CardEntrega(props) {
    const info = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const { direccion, title, showAddButton, onSeleccionar, buttoneditaroagregar } = props;
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false); // Estado para el hover

    const goadrees = (action) => {
        if (action === 'edit') {
            navigate('/Adrees', { state: { adress: direccion } });
        } else if (action === 'add') {
            navigate('/Adrees');
        }
    }

    const handleClick = () => {
        if (onSeleccionar) {
            onSeleccionar(direccion.id);
        }
    };

    return (
        <div className=' rounded-md	 p-3 mx-5 mt-5 cursor-pointer border border-[#c2c2c2] font-montserrat shadow-lg' >
            <div
                className={`transition cursor-pointer p-2 ${isHovered ? 'bg-[#1e3d8b] text-white' : 'hover:bg-[#1e3d8b] hover:text-white'}`}
                onClick={handleClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className='text-left flex justify-between'>
                    <h2 className={`font-montserrat text-base lg:text-lg ${isHovered ? 'text-white' : 'text-[#030303]'}`}>
                        {title}
                    </h2>
                    <p>
                        <i className={`fas fa-chevron-circle-down text-xl ${isHovered ? 'text-white' : 'text-[#757575]'}`}></i>
                    </p>
                </div>
                <div>
                    <p className={`text-xs font-black lg:mt-1 font-montserrat ${isHovered ? 'text-white' : 'text-[#007bfc]'}`}>
                        {direccion.principal && '(Dirección Principal)'}
                    </p>
                </div>

                <div className='mt-2'>
                    <p className={`text-sm font-montserrat ${isHovered ? 'text-white' : 'text-[#757575]'}`}>{direccion.calle}</p>
                    <div className='flex justify-between text-xs mt-2 font-montserrat'>
                        <p className={isHovered ? 'text-white' : 'text-black'}>{direccion.ciudad}</p>
                        <p className={isHovered ? 'text-white' : 'text-black'}>{direccion.cp}</p>
                    </div>
                </div>
            </div>

            {info && info['$r07sad'] !== 'Tienda' && (
                <div className='flex justify-between text-sm mt-5 border-t border-gray-500 p-2'>
                    {buttoneditaroagregar ? (
                        <button

                            name='btn-editdirecion2'
                            id='btn-editdirecion2'

                            onClick={buttoneditaroagregar} className='text-[#007bfc] font-montserrat hover:text-[#1e3d8b]'>
                            <i className="fas fa-exchange-alt"></i> Elegir otra direccion o editar
                        </button>
                    ) : (
                        <button

                            name='btn-editdirec23'
                            id='btn-editdirec23'
                            onClick={() => goadrees('edit')} className='text-[#007bfc] hover:text-[#1e3d8b]'>
                            <i className="far fa-edit"></i> Editar Dirección
                        </button>
                    )}

                    {showAddButton && (
                        <button

                            name='btn-adddireccion1'
                            id='btn-adddireccion1'
                            onClick={() => goadrees('add')} className='text-[#1e3d8b] font-semibold '>
                            <i className="fas fa-plus"></i> Agregar dirección
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default CardEntrega;
