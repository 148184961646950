import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { customStyles } from '../../constants/constants';

const ModalOcurre = ({ onClose, onSelectIdOffice, oterclose }) => {
    const [listaOcurre, setListaOcurre] = useState([]);
    const [opcionSeleccionadaOcurre, setOpcionSeleccionadaOcurre] = useState(null);
    const [oficeName, setOficeName] = useState(null);
    useEffect(() => {
        const ocurresucursales = async () => {
            try {
                const response = await axios.get('https://api.castores.com.mx/catalogs/ocurreOffices');
                const jsonData = response.data.data.packaging;
                setListaOcurre(jsonData);
            } catch (error) {
                console.error("Error al obtener los productos:", error);
            }
        };

        ocurresucursales();
    }, []);

    const opcionesSelectocurre = listaOcurre.map(item => ({
        label: item.plaza,
        value: item.idoficina
    }));

    const handleChangeocurre = (opcionSeleccionadaOcurre) => {
        setOpcionSeleccionadaOcurre(opcionSeleccionadaOcurre.value);
        setOficeName(opcionSeleccionadaOcurre.label)

    };

    const handleConfirm = () => {
        if (opcionSeleccionadaOcurre) {
            localStorage.setItem('idofiocurre', opcionSeleccionadaOcurre);
            onSelectIdOffice(opcionSeleccionadaOcurre, oficeName);
            onClose();
        } else {
            // console.log("Por favor, seleccione una opción antes de confirmar.");
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-80 lg:w-1/3 shadow-lg rounded-md bg-white">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 text-gray-900 font-black">No podemos llegar a tu dirección</h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500">Selecciona una sucursal de paquetería Castores más cercana:</p>

                        <Select
                            options={opcionesSelectocurre}
                            onChange={handleChangeocurre}
                            isSearchable={true}
                            placeholder="Selecciona una opción"
                            styles={customStyles}

                        />
                    </div>
                    <div className="items-center px-4 py-3">
                        <button
                            className={`px-4 py-2 text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 transition-colors duration-200 ${opcionSeleccionadaOcurre ? "bg-blue-400 hover:bg-[#1e3d8b] focus:ring-blue-300" : "bg-gray-400 cursor-not-allowed"}`}
                            onClick={handleConfirm}
                            disabled={!opcionSeleccionadaOcurre}

                            name='btn-confirmmdlocurre'
                            id='btn-confirmmdlocurre'
                        >
                            Confirmar
                        </button>
                        <p className='mt-2'>o</p>
                        <button
                            id="ok-btn"
                            name='ok-btn'
                            className="relative overflow-hidden bg-[#e9bf56] py-2 px-4 w-full mt-2 rounded-md transition-colors duration-300 ease-linear  text-black hover:bg-[#1e3d8b] hover:text-white"
                            onClick={oterclose}>
                            Probar otra dirección
                        </button>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalOcurre;
