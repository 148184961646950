

function CallToAction({title, subtitle, href="/catalogo/autozone", className}) {
  return (
    <section className={className}>
      <div className="bg-blue-600 text-white p-6 rounded-lg text-center">
        <h3 className="text-xl font-semibold mb-2">
          {title}
        </h3>
        <p className="mb-4">
          {subtitle}
        </p>
        <a
          href={href}
          target="_blank"
          className="bg-white text-blue-600 px-6 py-2 rounded-lg font-semibold hover:bg-blue-100 transition-colors"
        >
          Explorar Productos Hantec
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 inline-block ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </section>
  );
}

export default CallToAction;
