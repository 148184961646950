import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Footer from '../../componentes/footer/Footer';
import Header from '../../componentes/Headers/Header';
import Handimg from '../../assets/Hand1.jpeg'
import Handimg2 from '../../assets/Hand2.jpeg'
// import { viewBlog } from '../../utils/PixelMeta/metaPixelEvents';
import { useBeforeUnload } from '../../utils/Analitycs/analytics';



function Blog() {
    let navigate = useNavigate();
    
    const items = [
        {
            id: 1,
            title: "Instalciones",
            subtitle: "Entrgamso servio de calidad apra ayudarte a impleentar tus productos hantec en tu taller",
            imageSrc1: "https://hantec.us/cdn/shop/files/RAMPAS-53.png?v=1700075865&width=713",
            date: '12 Feb 2024'
        },
        {
            id: 2,
            title: "Carrito Hantec",
            subtitle: "Hand te ensena como administrar tus herramientas y organizarlas de la mejor forma ",
            imageSrc1: Handimg,
            date: '1 Feb 2024'

        },
        {
            id: 3,
            title: "Levnate correcto",
            subtitle: "Techt te enesena a utilizar el gato de patin de forma que no te lastimes la esplada",
            imageSrc1: Handimg2,
            date: '20 Ene 2024'

        }
    ];
    const gotoBlogDetail = () => {
        navigate('/BlogDetail')

    }

    // El usuario ha sálido de la página
    const location = useLocation();
    const fullPath = location.pathname;
    const routeName = fullPath.split('/')[1];
    useBeforeUnload(routeName);

    //buscador prueba
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí podrías manejar el envío del formulario, como enviar una solicitud a una API
        // console.log(`Buscando: ${searchTerm}`);
    };

    return (



        <div className='flex flex-col h-screen justify-between'> {/* Contenedor principal que usa flex */}

            <Header />
            <div className='flex-grow'>

            <div className='lg:w-4/5 mx-auto mt-4'>
                    <h1 className="text-2xl sm:text-4xl mb-4 font-medium text-gray-900 text-center lg:text-left">
                        Blog mecánico con Hand y Tech
                    </h1>
                </div>


                <div className="grid grid-cols-1 px-5 md:grid-cols-3 gap-4 mt-6 lg:w-4/5 mx-auto">
                    {items.map((item) => (
                        <article key={item.id} className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                            <img width="300" height="200"

                                alt=""
                                src={item.imageSrc1}
                                className="absolute inset-0 h-full w-full  object-fill lg:object-contain"
                            />

                            <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64">
                                <div className="p-4 sm:p-6">
                                    <time dateTime="2022-10-10" className="block text-xs text-white/90">{item.date}</time>

                                    <button onClick={gotoBlogDetail}>
                                        <h3 className="mt-0.5 text-lg text-white">{item.title}</h3>
                                    </button>

                                    <p className="mt-2 line-clamp-3 text-sm text-white/95">
                                        {item.subtitle}
                                    </p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div>




            <Footer />
        </div>

    );
}

export default Blog;
