import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useProductStore } from '../../../store/bookStore';

const Header = lazy(() => import('../../../componentes/Headers/Header'));
const Footer = lazy(() => import('../../../componentes/footer/Footer'));
const ScrollableCategories = lazy(() => import('../../../componentes/sliders/ScrollableCategories'));
const ProductoCard = lazy(() => import('../../../componentes/productos/Card'));

function CategoriesSpecial() {
    const { id } = useParams();
    const { productsSeason, productsSeller, fetchProductsSeason, fetchProductsSeller } = useProductStore();
    const [titleCategory, setTitleCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id === 'mas_vendidos') {
            fetchProductsSeller(id);
            setTitleCategory('Los más vendidos');
        } else if (id === 'ofertas_temporada') {
            fetchProductsSeason(id);
            setTitleCategory('Ofertas De Temporada');
        }
        setCurrentPage(1);
    }, [id]);

    let activeProducts = [];

    switch (id) {
        case 'mas_vendidos':
            activeProducts = Array.from(new Map(productsSeller.map(product => [product.id_producto, product])).values())
                .filter(product => product.stock.disponible > product.stock.mostrado && product.id_odoo)
                .sort((a, b) => b.precio.cantidad - a.precio.cantidad);
            break;

        case 'ofertas_temporada':
            activeProducts = Array.from(new Map(productsSeason.map(product => [product.id_producto, product])).values())
                .filter(product => product.stock.disponible > product.stock.mostrado && product.id_odoo)
                .sort((a, b) => b.precio.cantidad - a.precio.cantidad);
            break;

        default:
            break;
    }

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = activeProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(activeProducts.length / productsPerPage);

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Suspense fallback={<div>Loading Header...</div>}>
                <Header />
            </Suspense>
            <Suspense fallback={<div>Loading Categories...</div>}>
                <ScrollableCategories />
            </Suspense>

            <div className='w-full px-5 mx-auto md:w-full md:w-full lg:w-4/5 lg:pl-4 lg:text-4xl text-3xl font-black mt-4 font-robotoch  mt-8'>
                <p className='md:ml-2 lg:ml-0'>{titleCategory}</p>
            </div>

            <div className="flex-grow w-full lg:w-4/5 mx-auto mt-5">
                <div className="grid grid-cols-2 md:grid-cols-3 w-full lg:grid-cols-4 xxl:grid-cols-5 gap-1 px-2 b">
                    {currentProducts.map((producto) => (
                        <Suspense fallback={<div>Loading Product...</div>}>
                            <ProductoCard key={producto.id_producto} producto={producto} />
                        </Suspense>
                    ))}
                </div>
            </div>

            <div className="p-4">
                <div className="flex justify-center space-x-2">
                    {Array.from({ length: totalPages }, (_, i) => (
                        <button
                            key={i + 1}
                            onClick={() => paginate(i + 1)}
                            name='btn-paginate'
                            id='btn-paginate'
                            className={`px-4 py-2 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>

            <Suspense fallback={<div>Loading Footer...</div>}>
                <Footer />
            </Suspense>
        </div>
    );
}

export default CategoriesSpecial;
