import React, { useEffect, useMemo, useRef, useState } from 'react'
import NotImgPerfil from '../../assets/Perfiluser.webp'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import ProductDetails from '../../componentes/Admi/ProductDetails';
import PricesInventoryProduct from '../../componentes/Admi/PricesInventoryProduct';
import CategoriesAndTags from '../../componentes/Admi/CategoriesAndTags';
import ManageImageFiles from '../../componentes/Admi/ManageImageFiles';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, customStyles } from '../../constants/constants';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import Sidebar from '../../componentes/Admi/Sidebar';
import { roundToTwoDecimals } from '../../utils/utils';

function AddProduct() {
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const navigate = useNavigate();

    const location = useLocation();
    const { product } = location.state || { product: null }; // Obtiene idadress del estado, si existe


    // Variantes del producto***********************************************************************************************************************
    const [productsVariante, setProductsVariante] = useState([]);
    const [variantForm, setVariantForm] = useState(false);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentVariant, setCurrentVariant] = useState(null);


    const fileInputRef = useRef();
    const fileInputRefpdf = useRef();
    const multipleFilesInputRef = useRef();

    const [nameProduct, setNameProduct] = useState('')
    const [descriptionProduct, setDescriptionProduct] = useState('')
    const [precioCubreEnvio, setPrecioCubreEnvio] = useState(0);

    const [precioEstafeta, setPrecioEstafeta] = useState(0);
    const [tipoEnvio, setTipoEnvio] = useState('');



    const [sku, setSku] = useState('')
    const [marca, setMarca] = useState('')
    const [model, setModel] = useState('')
    const [price, setPrice] = useState(0)
    const [priceDiscount, setPriceDiscount] = useState(0)
    const [discount, setDiscount] = useState(0);
    const [inventory, setInventory] = useState(0)
    const [inventoryMin, setInventoryMin] = useState(0)
    const [categories, setCategories] = useState([])
    const [categorySelected, setCategorySelected] = useState('')
    const [categoriesSpecial, setCategoriesSpecial] = useState({})
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(false)
    const [nameImage, setNameImage] = useState('')
    const [imagePreviewUrl, setImagePreviewUrl] = useState('')
    const [imagePreviewUrls, setImagePreviewUrls] = useState([])
    const [videoUrl, setVideoUrl] = useState(''); // Estado para la URL del video
    //pdf para poder descar del lado del usuario
    const [fichaTecnicaPDF, setFichaTecnicaPDF] = useState(''); // Estado para la URL del video
    const [msi, setMsi] = useState(0) //meses sin intereces
    const [priceAtoZne, setPriceAtoZne] = useState(0) //precio a distribuidor autozone
    const [inventoryOdoo, setInventoryOdoo] = useState(0)



    const [pdfBase64, setPdfBase64] = useState(''); // Guardar el contenido en base64 del archivo PDF
    const [pdfName, setPdfName] = useState(''); // Opcional: Guardar el nombre del archivo

    //medidedad-*-*-*-*-*-*-*-*-

    const [peso, setPeso] = useState(null);
    const [alto, setAlto] = useState(null);
    const [largo, setLargo] = useState(null);
    const [ancho, setAncho] = useState(null);
    const [idOdoo, setIdOdoo] = useState(null);


    //Datos par ale prdocutod de regalos /*/*/*/*/*/*/*/*/*/--**-*    // Estado para manejar si el checkbox está marcado
    const [isCheckedGift, setIsCheckedGift] = useState(false);
    const [productsGift, setProductsGift] = useState([]);

    const [formData, setFormData] = useState({
        selectedProduct: null // Aquí almacenamos el producto seleccionado
    });


    useEffect(() => {
        // Forzar la re-renderización del Slider una vez que los productos están listos

        if (product) {
            cargarDatos(product.id_producto);  // Asumiendo que quieres cargar datos para el primer producto

        }

        ProductsToCombos(info['$tk3m09s'])


        window.scrollTo(0, 0);

    }, []); // Dependencia a 'productos' para actualizar cuando cambie


    // console.log('El producto a editar es: ', product)
    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const toastSuccess = (msg) => {
        toast.success(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    useMemo(() => {

        const getCategories = async () => {
            try {

                const response = await fetch(`${API_URL}/getCategories`,
                    {
                        headers: {
                            Authorization: info['$tk3m09s'],
                        }
                    }
                );
                const data = await response.json();
                if (data['error'] == 'refresh') {
                    localStorage.removeItem('k-9SMD879na89dN87m')
                    navigate('/');
                    return
                }
                setCategories(data)
            } catch (error) {
                toastError('Ha ocurrido un error al obtener las categorías.')
            }
        }
        getCategories()


    }, []
    )
    const handleDivClick = () => {
        // Activar el clic en el input a través de la referencia
        fileInputRef.current.click();
    };

    const handleDivClickpdf = () => {
        // Activar el clic en el input a través de la referencia
        fileInputRefpdf.current.click();
    };

    const handleClickMultiple = () => {
        // Activar el clic en el input a través de la referencia
        multipleFilesInputRef.current.click();
    };

    const onImageChange = (event) => {
        let file = event.target.files[0];
        if (file) {
            // Crear un FileReader para leer el archivo
            let reader = new FileReader();
            reader.onloadend = () => {
                // Crear un nuevo objeto Image
                let img = new Image();
                img.onload = () => {
                    // Crear un canvas
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Establecer las dimensiones deseadas para el canvas (puedes ajustar esto)
                    canvas.width = img.width / 2; // Reducir el tamaño por 2, por ejemplo
                    canvas.height = img.height / 2;

                    // Dibujar la imagen en el canvas con el tamaño especificado
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // Convertir el canvas a una imagen en base64
                    const dataUrl = canvas.toDataURL('image/jpeg', 0.7); // Ajustar la calidad aquí, 0.7 por ejemplo

                    // Establecer la URL de la imagen para la vista previa y el nombre de archivo
                    setImagePreviewUrl(dataUrl);
                    setNameImage(file.name);
                };
                // Cargar la imagen
                img.src = reader.result;
            };
            // Leer el archivo como URL de datos
            reader.readAsDataURL(file);
        }
    };



    const onImagesChange = (event) => {
        const files = Array.from(event.target.files).filter(file => file.type.match('image.*'));

        files.forEach(file => {
            // Crear un FileReader para leer el archivo
            let reader = new FileReader();
            reader.onloadend = () => {
                // Crear un nuevo objeto Image
                let img = new Image();
                img.onload = () => {
                    // Crear un canvas
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Establecer las dimensiones deseadas para el canvas (ajusta según sea necesario)
                    canvas.width = img.width / 2; // Ejemplo: reducir tamaño por 2
                    canvas.height = img.height / 2;

                    // Dibujar la imagen en el canvas con el tamaño especificado
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // Convertir el canvas a una imagen en base64 con calidad ajustada
                    const dataUrl = canvas.toDataURL('image/webp', 0.7); // Ajustar calidad aquí, 0.7 como ejemplo

                    // Agregar la URL de la imagen convertida al estado para la vista previa
                    setImagePreviewUrls(prevImages => [...prevImages, dataUrl]);
                };
                // Cargar la imagen
                img.src = reader.result;
            };
            // Leer el archivo como URL de datos
            reader.readAsDataURL(file);
        });
    };


    const handleDeleteImage = (index) => {
        const newArray = [
            ...imagePreviewUrls.slice(0, index),
            ...imagePreviewUrls.slice(index + 1),
        ];

        setImagePreviewUrls(newArray)
    }


    const handleCheckOption = (event) => {
        //Agrega las opciones seleccionadas a un array
        if (event.target.checked) {
            // console.log(tags)
            let newTags = [...tags, event.target.value]
            setTags(newTags)
            return
        }

        let newTags = tags.filter((tag) => tag !== event.target.value)
        setTags(newTags)
    }

    const handleCheckOptionSpecial = (event) => {
        if (event.target.checked) {
            let data = {
                [event.target.value]: event.target.checked
            }
            setCategoriesSpecial(prevEstado => ({ ...prevEstado, ...data }))
            return
        }
        // Crea una copia del estado actual
        const newCategories = { ...categoriesSpecial };
        // Elimina la propiedad deseada
        delete newCategories[event.target.value];
        // Actualiza el estado
        setCategoriesSpecial(newCategories);
    }

    const handleCategorySelected = (event) => {
        setCategorySelected(event.target.value)
    }
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-datos el producto a enviar
    const products =
    {
        "categorias_venta": categoriesSpecial,
        "stock": {
            "mostrado": inventoryMin,
            "disponible": inventory
        },
        "categoria": categorySelected,
        "precio": {
            "cantidad": parseFloat(priceDiscount),
            "moneda": "MXN"
        },
        "precio_habitual": {
            "cantidad": parseFloat(price),
            "moneda": "MXN"
        },
        "descuento": parseFloat(discount),
        "titulo": nameProduct,
        "descripcion": descriptionProduct,
        "imagen": imagePreviewUrl,
        "imagenes": imagePreviewUrls,
        "sku": sku,
        "marca": marca,
        "modelo": model,
        "etiquetas": tags,
        "cubre_envio": parseFloat(precioCubreEnvio),
        "url_video": videoUrl,
        "ficha_tecnica": pdfBase64,
        "msi": msi,
        "precio_autozone": {
            "cantidad": parseFloat(priceAtoZne),
            "moneda": "MXN"
        },
        "isGift": isCheckedGift,
        "gifts": formData.selectedProduct,
        "tipo_envio": tipoEnvio,
        "precio_envio": parseFloat(precioEstafeta),





        // "imagen": "https://hantec.us/cdn/shop/files/carrucel_Mesadetrabajo1.jpg?v=1695858725&width=713"
    }

    const listTags = [
        "Taller Mecánico",
        "Hojalatería y Pintura",
        "Industria",
        "Llantera",
    ]


    const listSpecialCategories = [
        {
            "id": "ofertas_temporada",
            "name": "Ofertas de temporada"
        },
        {
            "id": "mas_vendidos",
            "name": "Los más vendidos"
        },
        {
            "id": "descuento_especial",
            "name": "En descuento especial"
        },
    ]


    function validarCampoPrecio(precio) {
        // Verifica que el objeto precio exista, que tenga una propiedad cantidad y moneda,
        // y además que la cantidad sea mayor a 0.
        return precio && precio.cantidad > 0 && precio.moneda;
    }


    function validarCampoStock(stock) {
        return stock && (stock.disponible !== undefined || stock.disponible !== 0) && (stock.mostrado !== undefined || stock.mostrado !== 0);
    }

    const cleanFields = () => {
        setNameProduct('')
        setDescriptionProduct('')
        setSku('')
        setMarca('')
        setModel('')
        setNameImage('')
        setImagePreviewUrl('')
        setImagePreviewUrls([])
        setPrice(0)
        setPriceDiscount(0)
        setInventory(0)
        setInventoryMin(0)
        setCategorySelected('')
        setCategoriesSpecial({})
        setTags([])
        setPriceDiscount(0)
        setVideoUrl('')
        setPdfBase64('')
        setFichaTecnicaPDF('')
        setPdfName('')

    }

    const handlePostProduct = async () => {

        //    console.log('el pedf es ', pdfBase64)


        const priceSinIVA = roundToTwoDecimals(price / 1.16);
        const priceSinIVAAutoZone = roundToTwoDecimals(priceAtoZne / 1.16);

        // Calcular el porcentaje de descuento y redondearlo a dos decimales

        // console.log(`Precio con descuento sin IVA: ${priceDiscountSinIVA}`);

        // console.log('Precio habitual sin IVA:', priceSinIVA);
        //console.log('Phabitual sin IVA ATZN:', priceSinIVAAutoZone);

        // Aquí agregamos las nuevas propiedades a `products`
        products.precio_sin_iva = {
            cantidad: priceSinIVA, // Aquí usamos el precio con descuento sin IVA
            moneda: "MXN"
        };


        // Aquí agregamos las nuevas propiedades a `products`
        products.precio_az_sin_iva = {
            cantidad: priceSinIVAAutoZone, // Aquí usamos el precio con descuento sin IVA
            moneda: "MXN"
        };


        if (selectedVariants.length > 0) {
            products.variants = selectedVariants;
        }


        //        console.log('prductos', products)
        setLoading(true)
        let payload = {
            filename: nameImage,
            action: product ? 'update' : 'create',
            product: products
        }
        if (product) {
            payload = {
                id: product ? product.id_producto : null,
                filename: nameImage,
                action: product ? 'update' : 'create',
                product: products
            }
        }

        // console.log('Voy a mandar ', payload)

        const campos = [
            "titulo", "descripcion", "sku", "marca", "modelo", "imagen", "imagenes"
        ];

        // Validar campos simples
        for (let campo of campos) {
            if (!payload.product[campo] || payload.product[campo] == '' || payload.product[campo].length === 0) {
                //Mostrar toast
                // console.log(campo)
                campo == 'titulo' ? toastError(`El nombre del producto es requerido.`) : ''

                campo == 'descripcion' ? toastError(`La descripción del producto es requerida.`) : ''
                campo == 'sku' ? toastError(`El SKU del producto es requerida.`) : ''
                campo == 'marca' ? toastError(`La marca del producto es requerida.`) : ''
                campo == 'modelo' ? toastError(`El modelo del producto es requerida.`) : ''
                campo == 'imagen' ? toastError(`La imágen principal es requerida.`) : ''
                campo == 'imagenes' ? toastError(`Es necesario subir imágenes del producto.`) : ''
                setLoading(false)
                return; // Retorna falso si algún campo está vacío
            }
        }


        if (products.descuento === null || products.descuento === undefined || isNaN(products.descuento) || products.descuento < 0) {

            toastError(`El campo "Descuento" es requerido y debe ser 0 o mayor.`);

            setLoading(false);
            return;

        }

        //Pendiente revisar si validar número de imagenes
        if (!validarCampoPrecio(products.precio) || !validarCampoPrecio(products.precio_habitual)) {
            // Mostrar mensaje de error
            toastError(`Los campos "Precio base" y "Precio con descuento" son requeridos y deben ser mayores a 0.`);
            setLoading(false);
            return;
        }


        if (!validarCampoStock(products.stock)) {
            //Mostrar toast
            toastError(`Los campos "Inventario inicial" y "Mínimo para mostrarse" son requeridos.`)
            setLoading(false)
            return;
        }
        if (products.tipo_envio === null || products.tipo_envio === '') {
            toastError(`Debe de seleccionar un tipo de envío`);
            setLoading(false);
            return;
        }

        if (products.tipo_envio === null || products.tipo_envio === '') {
            toastError(`Debe de seleccionar un tipo de envío`);
            setLoading(false);
            return;
        }

        if (products.tipo_envio === 'estafeta' && (products.precio_envio === 0 || products.precio_envio === null || isNaN(products.precio_envio))) {
            toastError(`El precio de envío no puede ser 0 para Estafeta`);
            setLoading(false);
            return;
        }

        console.log('------producto a enviar ', payload)
        // setLoading(false)


        //Registrar producto
        try {
            // console.log('entre al try')
            payload["user_id"] = info['$udi09345']
            const response = await axios.post(API_URL + '/productsStore', payload, { headers: { 'Authorization': info['$tk3m09s'] }, timeout: 500000 })
            if (products) {
                // console.log('actulizado1 ')

                if (info['$r07sad'] === 'dkommita') {
                    goupdatemedidad(product.id_producto)
                }


                if (selectedVariants.length > 0) {
                    // console.log('las variantes');
                    await insertVarianrtall(selectedVariants);
                }
                if (response['error'] == 'refresh') {
                    toastError('Su sesión ha expirado, vuelve a iniciar sesión.')
                    return
                }
                // cleanFields()
                return
            }



            if (response['error'] == 'refresh') {
                toastError('Su sesión ha expirado, vuelve a iniciar sesión.')
                localStorage.removeItem('k-9SMD879na89dN87m')
                navigate('/')
                return
            }
            // console.log('Producto creado y agregado.')
            if (payload.action == 'create') {
                // console.log('El producto ha sido creado')
                toastSuccess('El producto ha sido agregado correctamente.')
            }
            if (payload.action == 'update') {
                //    console.log('El producto ha sido actualizado')

                toastSuccess('El producto ha sido actualizado correctamente.')

            }

            cleanFields()

        } catch (error) {
            toastError('Ha ocurrio un error, vuelve a intentarlo.')
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out productsStore');
            }
        } finally {
            setLoading(false)
        }
    }

    const insertVarianrtall = async (arrayVariants) => {
        // console.log('el arreglo de varinates es ', arrayVariants)

        const payload = {
            variants: arrayVariants
        }


        try {
            const response = await axios.post(API_URL + '/addVariantsToProducts', payload, { headers: { 'Authorization': info['$tk3m09s'] }, timeout: 500000 });
            //    console.log('Actualización exitosa:', response.data);
            // Procesar la respuesta o realizar acciones adicionales si es necesario
        } catch (error) {
            console.error('Error al actualizar los detalles del producto:', error);
            // Manejo del error si la API falla o retorna un error
        }
    }

    // console.log(product?.categorias_venta)

    const goupdatemedidad = async (id_producto) => {
        if (id_producto) {
            const detallesProducto = {
                id_producto: id_producto,  // Asumiendo que id_producto es un string o un número que no necesita conversión.
                id_odoo: parseInt(idOdoo),
                peso: parseFloat(peso),
                alto: parseFloat(alto),
                largo: parseFloat(largo),
                ancho: parseFloat(ancho)
            };

            // console.log('enviara', detallesProducto)
            try {
                const response = await axios.post(API_URL + '/updateDetailsProduct', detallesProducto, { headers: { 'Authorization': info['$tk3m09s'] }, timeout: 500000 });
                //   console.log('Actualización exitosa:', response.data);
                // Procesar la respuesta o realizar acciones adicionales si es necesario
            } catch (error) {
                console.error('Error al actualizar los detalles del producto:', error);
                // Manejo del error si la API falla o retorna un error
            }
        } else {
            // console.log('El ID del producto no está disponible');
        }
    }
    //datos si viene el priodcuto
    const cargarDatos = async (id_product) => {
        setLoading(true); // Indicar inicio de carga
        const payload = {
            "id_producto": parseInt(id_product)
        };

        try {
            const response = await axios.post(API_URL + '/getProductById', payload, { timeout: 15000 });
            const datosRecibidos = response.data; // Asegurándote de acceder directamente a response.data

            //console.log('Datos recibidos es: ', datosRecibidos);
            // Asignar los datos recibidos a los estados
            setNameProduct(datosRecibidos[0].titulo || '');
            setDescriptionProduct(datosRecibidos[0].descripcion || '');
            setPrecioCubreEnvio(datosRecibidos[0].cubre_envio || 0);

            //datos envio estafeta---------------------------------------------
            setPrecioEstafeta(datosRecibidos[0].p_envio || 0)
            setTipoEnvio(datosRecibidos[0].t_envio || '')

            setSku(datosRecibidos[0].sku || '');
            setMarca(datosRecibidos[0].marca || '');
            setModel(datosRecibidos[0].modelo || '');
            setPrice(datosRecibidos[0].precio_habitual?.cantidad || 0);
            setPriceDiscount(datosRecibidos[0].precio?.cantidad || 0);
            setDiscount(datosRecibidos[0].descuento || 0);
            setInventory(datosRecibidos[0].stock?.disponible || 0);
            setInventoryMin(datosRecibidos[0].stock?.mostrado || 0);
            setCategoriesSpecial(datosRecibidos[0].categorias_venta || {});
            setTags(datosRecibidos[0].etiquetas || []);
            setNameImage(datosRecibidos[0].imagen || '');
            setImagePreviewUrl(datosRecibidos[0].imagen || '');
            setImagePreviewUrls(datosRecibidos[0].imagenes || []);
            setVideoUrl(datosRecibidos[0].url_video || '');
            setPdfBase64(datosRecibidos[0].url_ficha || '')
            setPriceAtoZne(datosRecibidos[0].precio_autozone?.cantidad || 0)
            setMsi(datosRecibidos[0].msi || 0)

            setPeso(datosRecibidos[0].peso || 0);
            setAlto(datosRecibidos[0].alto || 0);
            setLargo(datosRecibidos[0].largo || 0);
            setAncho(datosRecibidos[0].ancho || 0);
            setIdOdoo(datosRecibidos[0].id_odoo || 0);

            setIsCheckedGift(datosRecibidos[0].isGift || false)

            const variants = datosRecibidos[0].variants || [];
            setSelectedVariants(variants);
            setVariantForm(variants.length > 0)

            setFormData(prevFormData => ({
                ...prevFormData,
                selectedProduct: datosRecibidos[0].gifts || null
            }));


            // Asumiendo que la categoría y las categorías especiales requieren ser manejadas
            if (datosRecibidos[0].categoria) {
                setCategorySelected(datosRecibidos[0].categoria);
            }

            if (datosRecibidos[0].categorias) {
                setCategories(datosRecibidos[0].categorias);
            }

            if (datosRecibidos[0].sku) {
                cotizarInventarioOdoo(datosRecibidos[0].sku);
            }

        } catch (error) {
            console.error('Error al cargar datos del producto:', error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out getProductById');
            }
            // Opcionalmente podrías redirigir o mostrar un error específico aquí
        } finally {
            setLoading(false); // Indicar fin de carga
        }
    };



    //renderizar pdf a base 64

    const onPDFChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Verificar si el archivo es un PDF
            if (file.type === "application/pdf") {
                const reader = new FileReader();

                reader.onloadend = () => {
                    // handleBase64(reader.result); aquí manejas el base64, por ejemplo, actualizando un estado
                    setPdfBase64(reader.result); // Suponiendo que tienes un estado llamado `pdfBase64` para almacenar el base 64
                    setPdfName(file.name); // Suponiendo que tienes un estado `pdfName` para almacenar el nombre del archivo
                };

                reader.readAsDataURL(file);
                // Actualizar estado con el nombre del archivo, si es necesario
                setFichaTecnicaPDF(file);  // Aquí puedes optar por manejar el archivo directamente o solo su nombre
            } else {
                alert("Por favor, sube un archivo PDF.");
            }
        }
    };

    const cotizarInventarioOdoo = async (sku) => {
        const payload = {
            "sku": sku // Asegúrate de que el `sku` se envía como un entero si es necesario
        };

        try {
            const response = await axios.post(API_URL + '/odoo/getInventoryBySKU', payload, { headers: { 'Authorization': info['$tk3m09s'] }, timeout: 500000 });
            // console.log("Cantidad disponible (qty_available):", response.data.success[0].qty_available);
            setInventoryOdoo(response.data.success[0].qty_available)
            // Puedes hacer más lógica aquí, como actualizar el estado con los datos recibidos
        } catch (error) {
            console.error("Error al obtener el inventario por SKU:", error);
        }
    };



    // Manejador para cambiar el estado
    const handleCheckboxChange = () => {
        setIsCheckedGift(!isCheckedGift);
    };

    const ProductsToCombos = async (tk) => {
        // console.log('Busncoas productos')
        setLoading(true);

        try {

            const config = {
                headers: {
                    'Authorization': tk
                }
            };



            const response = await axios.get(API_URL + '/getProductsToCombos', config);
            const productsWithIdOdoo = response.data.success.filter(product => product.id_odoo);

            // console.log('Los productos son:', productsWithIdOdoo);
            const options = productsWithIdOdoo.map(product => ({
                value: product.id_producto,
                label: `${product.sku} - ${product.titulo}`, // Concatenando SKU y título
                productDetails: product
            }));
            setProductsGift(options); // Almacenar en el estado

        } catch (error) {
        }

        setLoading(false);
    };

    const handleSelectProduct = (selectedOption) => {
        // console.log('Opción seleccionada:', selectedOption);

        // Actualiza el estado con los detalles completos del producto seleccionado
        setFormData(prevFormData => ({
            ...prevFormData,
            selectedProduct: {
                id_odoo: selectedOption.productDetails.id_odoo,
                id_producto: selectedOption.value,
                sku: selectedOption.productDetails.sku,
                titulo: selectedOption.label,
                imagen: selectedOption.productDetails.imagen,
                cantidad: 1,
                precio: { cantidad: 0, moneda: 'MXN' },

                precio_habitual: {
                    cantidad: 0,
                    moneda: "MXN"
                },
                precio_autozone: {
                    cantidad: 0,
                    moneda: "MXN"
                },
                precio_az_sin_iva: {
                    cantidad: 0,
                    moneda: "MXN"
                },
                precio_sin_iva: {
                    cantidad: 0,
                    moneda: "MXN"
                },
                descuento: 0,

            }
        }));
        const newImage = selectedOption.productDetails.imagen;

        setImagePreviewUrls(prevImages => [...prevImages, newImage]);

    };


    const fetchProductsVariant = async () => {
        try {
            const url = `${API_URL}/getProductsToVariants`;
            const headers = {
                'Authorization': info['$tk3m09s'],
                'Content-Type': 'application/json'
            };

            // console.log('URL:', url); // Verifica la URL
            // console.log('Headers:', headers); // Verifica los headers

            const response = await axios.get(url, {
                headers: headers,
                timeout: 500000
            });

            // console.log('La respuesta es', response.data.products_list);
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = response.data.products_list;
            setProductsVariante(data);
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                console.error('Request timed out');
            } else {
                console.error("Error al obtener los productos:", error);
            }
        }
    };

    useEffect(() => {
        if (variantForm) {
            fetchProductsVariant();
        } else {
            setProductsVariante([]);
            setSelectedVariants([]);
            setSearchQuery('');
        }
    }, [variantForm]);

    const filteredProducts = productsVariante.filter(product =>
        (product.sku.toLowerCase().includes(searchQuery.toLowerCase()) || product.titulo.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const handleSelectVariant = (product) => {
        setCurrentVariant({ ...product, color: '', voltage: '' });
    };

    const handleAddVariant = () => {
        if (currentVariant && currentVariant.color && currentVariant.voltage) {
            setSelectedVariants([...selectedVariants, currentVariant]);
            setCurrentVariant(null);
            setSearchQuery('');
        }
    };

    return (
        <div className=''>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            {/* w-5/6 h-auto */}
            {loading && <CargandoSpinner txtcharge="Cargando Producto" />}

            <div className='p-4 sm:ml-64'>
                {/* Nav products */}

                <div className='flex justify-between mb-10'>
                    <div className='flex items-center'>
                        <Link className='pr-5' to={'/products_managment'}><i className="fa-solid fa-arrow-left"></i></Link>
                        {/* <button className='pr-5'><i className="fa-solid fa-arrow-left"></i></button> */}
                        <p className='text-xl'>Nuevo producto</p>
                    </div>

                    <div className='flex items center'>
                        {/* <button className='bg-purple-900 text-white rounded p-1 mr-5' onClick={navigate('/addProduct')}>Añadir producto nuevo</button> */}
                        <img width="300" height="200"
                            src={NotImgPerfil} className='w-10 h-10' alt="imagen del perfil del usuario" />
                    </div>

                </div>

                {/* Section 1 */}
                {/* flex justify-between */}
                {/* Detalles del producto */}

                <ProductDetails
                    products={products} nameProduct={nameProduct} setNameProduct={setNameProduct}
                    setDescriptionProduct={setDescriptionProduct} handlePostProduct={handlePostProduct}
                    sku={sku} marca={marca} model={model}
                    setSku={setSku} setMarca={setMarca} setModel={setModel} loading={loading} descriptionProduct={descriptionProduct}
                />

                {/* Section 2 */}
                {/* Archivos multimedia */}
                <ManageImageFiles
                    fileInputRef={fileInputRef} handleClickMultiple={handleClickMultiple}
                    handleDivClick={handleDivClick} imagePreviewUrl={imagePreviewUrl}
                    imagePreviewUrls={imagePreviewUrls} multipleFilesInputRef={multipleFilesInputRef}
                    onImageChange={onImageChange} onImagesChange={onImagesChange}
                    handleDeleteImage={handleDeleteImage}
                    videoUrl={videoUrl} setVideoUrl={setVideoUrl} // Nuevas props para la URL del video



                    fileInputRefpdf={fileInputRefpdf}
                    handleDivClickpdf={handleDivClickpdf}
                    fichatecnica={fichaTecnicaPDF}
                    onPDFChange={onPDFChange}
                    pdfBase64={pdfBase64}
                    setImagePreviewUrls={setImagePreviewUrls}
                />



                {/* Section 3 */}
                {/* Precios de producto */}
                <PricesInventoryProduct
                    price={price} priceDiscount={priceDiscount}
                    inventory={inventory} inventoryMin={inventoryMin}
                    setInventory={setInventory} setInventoryMin={setInventoryMin}
                    setPrice={setPrice} setPriceDiscount={setPriceDiscount}
                    procendescuento={discount} setDiscount={setDiscount}
                    precioCubreEnvio={precioCubreEnvio} setPrecioCubreEnvio={setPrecioCubreEnvio}
                    inventoryOdoo={inventoryOdoo}
                    setMsi={setMsi} msi={msi}
                    //Datos estafeta -------------------------------------------------------------------------------
                    precioEstafeta={precioEstafeta} setPrecioEstafeta={setPrecioEstafeta}
                    tipoEnvio={tipoEnvio} setTipoEnvio={setTipoEnvio}

                    priceAtoZne={priceAtoZne} setPriceAtoZne={setPriceAtoZne}

                />
                <div className='flex flex-col space-y-4 bg-gray-100 w-3/5 px-5 py-10 rounded shadow-xl'>
                    <h2 className='text-xl mb-5 font-semibold text-gray-700'>
                        Variantes del producto
                    </h2>
                    <div>
                        <div className='flex flex-col w-1/2'>
                            <p>El producto cuenta con variantes</p>
                            <label htmlFor="variantToggle" className="relative inline-block h-6 mt-2 w-11 cursor-pointer">
                                <input
                                    type="checkbox"
                                    id="variantToggle"
                                    className="sr-only"
                                    checked={variantForm}
                                    onChange={e => setVariantForm(e.target.checked)}
                                />
                                <span className={`absolute inset-y-0 left-0 w-11 h-6 rounded-full transition duration-300 ease-in-out ${variantForm ? 'bg-green-500' : 'bg-red-500'}`}></span>
                                <span className={`absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition-transform duration-300 ease-in-out ${variantForm ? 'translate-x-5' : ''}`}></span>
                            </label>
                        </div>
                        {variantForm && (
                            <div className='flex flex-col space-y-4 bg-gray-100 w-3/5 px-5 py-10 rounded shadow-xl mt-5'>
                                <h2 className='text-xl mb-5 font-semibold text-gray-700'>
                                    Variantes del producto
                                </h2>
                                <input
                                    type="text"
                                    placeholder="Buscar variante"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="p-2 border rounded mb-5"
                                />
                                {searchQuery.length > 2 && filteredProducts.length > 0 && (
                                    <div className='space-y-4'>
                                        {filteredProducts.map(product => (
                                            <div key={product.id_producto} className='flex items-center space-x-4'>
                                                <img width="300" height="200"
                                                    src={product.imagen} alt={product.titulo} className='w-16 h-16 object-cover' />
                                                <div>
                                                    <h3 className='font-semibold'>{product.titulo}</h3>
                                                    <p>SKU: {product.sku}</p>
                                                    <button
                                                        onClick={() => handleSelectVariant(product)}
                                                        className='p-2 bg-blue-500 text-white rounded'
                                                    >
                                                        Seleccionar
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {currentVariant && (
                                    <div className='flex flex-col space-y-4'>
                                        <div className='flex flex-col'>
                                            <label htmlFor="color" className='font-semibold'>Color:</label>
                                            <input
                                                type="text"
                                                id="color"
                                                value={currentVariant.color}
                                                onChange={(e) => setCurrentVariant({ ...currentVariant, color: e.target.value })}
                                                className="p-2 border rounded"
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <label htmlFor="voltage" className='font-semibold'>Voltaje:</label>
                                            <input
                                                type="text"
                                                id="voltage"
                                                value={currentVariant.voltage}
                                                onChange={(e) => setCurrentVariant({ ...currentVariant, voltage: e.target.value })}
                                                className="p-2 border rounded"
                                            />
                                        </div>
                                        <button
                                            onClick={handleAddVariant}
                                            className='p-2 bg-blue-500 text-white rounded'
                                        >
                                            Agregar
                                        </button>
                                    </div>
                                )}
                                {selectedVariants.length > 0 && (
                                    <div className='mt-5'>
                                        <h3 className='text-lg font-semibold'>Variantes seleccionadas:</h3>
                                        <ul className='list-disc ml-5'>
                                            {selectedVariants.map((variant, index) => (
                                                <li key={index}>{variant.titulo} - SKU: {variant.sku} - Color: {variant.color} - Voltaje: {variant.voltage}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/*Section 3.5 */}
                <div className='flex flex-col space-y-4 bg-gray-100 w-3/5 px-5 mt-5 py-10 rounded shadow-xl'>
                    <h2 className='text-xl mb-5 font-semibold text-gray-700'>
                        Complementos de producto                    </h2>
                    <div>


                        <p>Incluye  producto de regalo: </p>
                        <label htmlFor="activeToggle" className="relative inline-block h-6 w-11 cursor-pointer">
                            <input
                                type="checkbox"
                                id="activeToggle"
                                className="sr-only"
                                checked={isCheckedGift}
                                onChange={handleCheckboxChange}
                            />
                            <span className={`absolute inset-y-0 left-0 w-11 h-6 rounded-full transition duration-300 ease-in-out ${isCheckedGift ? 'bg-green-500' : 'bg-red-500'}`}></span>
                            <span className={`absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition-transform duration-300 ease-in-out ${isCheckedGift ? 'translate-x-5' : ''}`}></span>
                        </label>
                    </div>
                    {/* Condicionally render this div based on isActive */}
                    {isCheckedGift && (
                        <div className="mt-2 bg-gray-400 w-3/5 p-2 rounded shadow-lg">
                            <div className='px-4'>
                                <h1 className='text-sm mb-2'>
                                    Nombre del producto:
                                </h1>
                                <Select
                                    options={productsGift}
                                    onChange={handleSelectProduct}
                                    value={formData.selectedProduct && productsGift.find(option => option.value === formData.selectedProduct.id_producto)}
                                    getOptionLabel={(option) => `${option.label}`}
                                    getOptionValue={(option) => option.value}
                                    styles={customStyles}
                                />

                            </div>
                        </div>
                    )}
                </div>

                {/* Section 4 */}

                {/* Categorias y atributos */}
                <CategoriesAndTags
                    categories={categories} handleCheckOption={handleCheckOption} handleCheckOptionSpecial={handleCheckOptionSpecial}
                    listSpecialCategories={listSpecialCategories} listTags={listTags} handleCategorySelected={handleCategorySelected}
                    tags={tags} categoriesSpecial={categoriesSpecial} categorySelected={categorySelected} />

                {info['$r07sad'] === 'dkommita' ? (
                    <div className='flex flex-col space-y-4 bg-gray-100 w-3/5 px-5 py-10 rounded shadow-xl'>
                        <div>
                            <h2 className='text-xl mb-5 font-semibold text-gray-700'>Medidas del producto</h2>
                        </div>
                        <div className='flex w-full'>
                            <div className='w-1/2'>
                                <p htmlFor="peso">Peso (kg)</p>
                                <input onChange={(event) => setPeso(parseFloat(event.target.value))} value={peso || ''}
                                    className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='Ingrese peso' />
                            </div>
                            <div className='w-1/2'>
                                <p htmlFor="alto">Alto (cm)</p>
                                <input onChange={(event) => setAlto(parseFloat(event.target.value))} value={alto || ''}
                                    className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='Ingrese alto' />
                            </div>

                        </div>
                        <div className='flex'>
                            <div className='w-1/2'>
                                <p htmlFor="largo">Largo (cm)</p>
                                <input onChange={(event) => setLargo(parseFloat(event.target.value))} value={largo || ''}
                                    className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='Ingrese largo' />
                            </div>
                            <div className='w-1/2'>
                                <p htmlFor="ancho">Ancho (cm)</p>
                                <input onChange={(event) => setAncho(parseFloat(event.target.value))} value={ancho || ''}
                                    className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='Ingrese ancho' />
                            </div>



                        </div>
                        <div>
                            <h2 className='text-xl mb-5 font-semibold text-gray-700'>Modificar ID Odoo</h2>

                            <div className='w-1/2'>
                                <p htmlFor="ancho">ID actual en Odoo</p>
                                <input onChange={(event) => setIdOdoo(parseInt(event.target.value))} value={idOdoo || ''}
                                    className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='Ingrese ID de odoo' />
                            </div>
                        </div>
                    </div>
                ) : null}

            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    )
}

export default AddProduct