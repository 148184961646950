import { useState, useEffect, lazy, Suspense } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useProductStore, useProductsCart } from '../../../store/bookStore';
import Grua from '../../../assets/gifs/grua.gif';
import { API_URL } from '../../../constants/constants';
import { goToPay } from '../../../utils/PixelMeta/metaPixelEvents';
import { dateDiffFromNow } from '../../../utils/utils';
import { SnipTrafficPlatform } from '../../../utils/Analitycs/analytics';

const Header = lazy(() => import('../../../componentes/Headers/Header'));
const CardList = lazy(() => import('../../../componentes/cards/CardList'));
const Footer = lazy(() => import('../../../componentes/footer/Footer'));
const ScrollableCategories = lazy(() => import('../../../componentes/sliders/ScrollableCategories'));
const ProductoCard = lazy(() => import('../../../componentes/productos/Card'));
const FloatingWhatsAppButton = lazy(() => import('../../../componentes/botones/FloatingWhatsAppButton'));
const ComboCard = lazy(() => import('../combos/ComboCard'));
const CargandoSpinner = lazy(() => import('../../../componentes/cargacomponetes/CargandoSpinner'));

function Categories() {
    const { id } = useParams();
    const token = window.localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(window.localStorage.getItem('k-9SMD879na89dN87m')) : null;
    const location = useLocation();
    const navigate = useNavigate();

    const { products, fetchProducts } = useProductStore();
    const [comboCategoryList, setComboCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Eliminar productos duplicados
    const uniqueProducts = Array.from(new Map(products.map(product => [product.id_producto, product])).values());

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8;
    const [filter, setFilter] = useState('');

    const applyFilter = (items) => {
        switch (filter) {
            case 'priceLowHigh':
                return [...items].sort((a, b) => {
                    const priceA = a.isCombo ? a.totalConDescuento : a.precio.cantidad;
                    const priceB = b.isCombo ? b.totalConDescuento : b.precio.cantidad;
                    return priceA - priceB;
                });
            case 'priceHighLow':
                return [...items].sort((a, b) => {
                    const priceA = a.isCombo ? a.totalConDescuento : a.precio.cantidad;
                    const priceB = b.isCombo ? b.totalConDescuento : b.precio.cantidad;
                    return priceB - priceA;
                });
            case 'titleAZ':
                return [...items].sort((a, b) => {
                    const titleA = a.isCombo ? a.nombre_combo : a.titulo;
                    const titleB = b.isCombo ? b.nombre_combo : b.titulo;
                    return titleA.localeCompare(titleB);
                });
            case 'titleZA':
                return [...items].sort((a, b) => {
                    const titleA = a.isCombo ? a.nombre_combo : a.titulo;
                    const titleB = b.isCombo ? b.nombre_combo : b.titulo;
                    return titleB.localeCompare(titleA);
                });
            default:
                return items;
        }
    };

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchProducts(id);
        getCombobyCategory();
        setCurrentPage(1);
        setFilter('');
    }, [id]);

    const getCombobyCategory = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/getCombos?category=${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 10000
            });

            const comboslist = response.data.success.map(combo => {
                let totalConIva = 0;
                let totalSinIva = 0;
                let totalConDescuento = 0;

                combo.productos.forEach(producto => {
                    totalConIva += producto.precio.cantidad;
                    totalSinIva += producto.precio_sin_iva.cantidad;
                    totalConDescuento += producto.precio_descuento.cantidad;
                });

                return {
                    ...combo,
                    totalConIva,
                    totalSinIva,
                    totalConDescuento,
                    isCombo: true // Añadir una propiedad para identificar los combos
                };
            });

            setComboCategoryList(comboslist);
            setIsLoading(false);
        } catch (error) {
            console.error("Error al obtener las categorías:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Tiempo de espera agotado en categorías slider');
            }
            setIsLoading(false);
        }
    };

    const combinedList = [...comboCategoryList, ...uniqueProducts.map(product => ({ ...product, isCombo: false }))];

    const sortedList = combinedList.sort((a, b) => {
        if (a.stock.disponible > a.stock.mostrado && b.stock.disponible <= b.stock.mostrado) {
            return -1;
        } else if (a.stock.disponible <= a.stock.mostrado && b.stock.disponible > b.stock.mostrado) {
            return 1;
        } else {
            if (a.isCombo && b.isCombo) {
                return b.totalConIva - a.totalConIva;
            } else if (a.isCombo && !b.isCombo) {
                return b.totalConIva - a.precio.cantidad;
            } else if (!a.isCombo && b.isCombo) {
                return b.totalConIva - a.precio.cantidad;
            } else {
                return b.precio.cantidad - a.precio.cantidad;
            }
        }
    });

    const filteredAndSortedCombinedList = applyFilter(combinedList);
    const currentItems = filteredAndSortedCombinedList.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredAndSortedCombinedList.length / productsPerPage);

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const { fetchCarts } = useProductsCart();

    const gopay = (totalcombo) => {
        setIsLoading(true);
        goToPay(totalcombo, token ? token['$email'] : 'No registrado');
        fetchCarts(token);
        navigate('/Pagos');
        setIsLoading(false);
    };

    useEffect(() => {
        const extractUTMParameters = () => {
            const searchParams = new URLSearchParams(location.search);

            const utmSource = searchParams.get('utm_source');
            const utmMedium = searchParams.get('utm_medium') || 'desconocido';
            const utmCampaign = searchParams.get('utm_campaign');
            const utmContent = searchParams.get('utm_content') || 'desconocido';

            let today = new Date();
            today.setHours(0, 0, 0, 0);

            const lastVisitDateStr = window.localStorage.getItem('utm');
            const lastVisitDate = lastVisitDateStr ? new Date(JSON.parse(lastVisitDateStr).date) : null;
            lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

            if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
                window.localStorage.removeItem("utm");
            }

            if (utmSource && utmMedium && utmCampaign) {
                const valuesUTM = {
                    "utmSource": utmSource,
                    "utmMedium": utmMedium,
                    "utmCampaign": utmCampaign,
                    "utmContent": utmContent,
                    "date": today
                };
                window.localStorage.setItem("utm", JSON.stringify(valuesUTM));
                SnipTrafficPlatform(utmSource, utmCampaign, utmContent);
            }
        };

        extractUTMParameters();
    }, [location.search]);

    return (
        <div className="flex flex-col min-h-screen">
            <Suspense fallback={<div>Loading Header...</div>}>
                <Header />
            </Suspense>
            <Suspense fallback={<div>Loading Scrollable Categories...</div>}>
                <ScrollableCategories />
            </Suspense>
            <Suspense fallback={<div>Loading WhatsApp Button...</div>}>
                <FloatingWhatsAppButton page="categorias" categoryName={id} />
            </Suspense>
            <div className='w-full px-2 mx-auto md:w-full md:w-full lg:w-4/5 lg:pl-4 lg:text-4xl text-3xl font-black mt-8 lg:mt-4 font-robotoch '>
                <p className='md:ml-2 lg:ml-0'>{id}</p>
            </div>

            <div className='lg:w-4/5 lg:mx-auto lg:pl-5 flex px-2 mt-4'>
                <p className='mr-2 mt-3'>Filtrar y ordenar:</p>
                <select value={filter} onChange={(e) => setFilter(e.target.value)} className="w-1/2 xl:w-1/4 py-3 px-2 pe-9 block border border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none">
                    <option value="">Todos</option>
                    <option value="priceLowHigh">Precio: más bajo a más alto</option>
                    <option value="priceHighLow">Precio: más alto a más bajo</option>
                    <option value="titleAZ">Título: A-Z</option>
                    <option value="titleZA">Título: Z-A</option>
                </select>
            </div>

            <div className='w-full lg:w-4/5 mx-auto mt-10 overflow-hidden'>
                {isLoading ? (
                    <Suspense fallback={<div>Loading Spinner...</div>}>
                        <CargandoSpinner txtcharge='Cargando datos' />
                    </Suspense>
                ) : (
                    <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 p-4'>
                        {currentItems.map((item) => (
                            item.isCombo ? (
                                <Suspense fallback={<div>Loading Combo...</div>}>
                                    <ComboCard
                                        key={item.id_combo}
                                        combo={item}
                                        goComboDetail={() => navigate(`/combonum/${item.id_combo}`)}
                                        gopay={gopay}
                                    />
                                </Suspense>
                            ) : (
                                <Suspense fallback={<div>Loading Product...</div>}>
                                    <ProductoCard key={item.id_producto} producto={item} />
                                </Suspense>
                            )
                        ))}
                    </div>
                )}
            </div>

            {totalPages > 1 && (
                <div className="p-4">
                    <div className="flex justify-center space-x-2">
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i + 1}
                                name='btn-paginate'
                                id='btn-paginate'
                                onClick={() => paginate(i + 1)}
                                className={`px-4 py-2 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </div>
            )}

            {currentItems.length === 0 && (
                <Suspense fallback={<div></div>}>

                    <div className="flex-grow w-full h-screen lg:w-4/5 mx-auto mt-5 text-center">
                        <div className="text-xl lg:text-3xl font-semibold flex flex-col justify-center items-center">
                            <p>Productos no disponibles por el momento </p>
                            <img width="300" height="200" src={Grua} className='w-52 h-52 lg:w-1/4 lg:h-1/4' />
                        </div>
                    </div>
                </Suspense>

            )}

            <Suspense fallback={<div>Loading Footer...</div>}>
                <Footer />
            </Suspense>
        </div>
    );
}

export default Categories;