import React, { useEffect, useState } from 'react';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import { useNavigate } from 'react-router-dom';
import { ValidateRefreshToken } from '../../utils/validations';
import { API_URL } from '../../constants/constants';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import TablaClientes from '../../componentes/Admi/TablaClientes';
import Sidebar from '../../componentes/Admi/Sidebar';
import { getCurrentDate } from '../../utils/Analitycs/analytics';
import axios from 'axios';

const Clientes = () => {
    const navigate = useNavigate();
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
    const [allClients, setAllClients] = useState([]);
    const [clientesConCompra, setClientesConCompra] = useState([]);
    const [clientesSinCompra, setClientesSinCompra] = useState([]);
    const [clientesDistribuidorLta, setClientesDistribuidorLta] = useState([]);
    const [totalClientes, setTotalClientes] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [mostrarClientes, setMostrarClientes] = useState('todos');
    const [paginaActualConCompra, setPaginaActualConCompra] = useState(1);
    const [paginaActualSinCompra, setPaginaActualSinCompra] = useState(1);
    const [paginaActualDistribuidor, setPaginaActualDistribuidor] = useState(1);
    const [totalClientesAll, setTotalClientesAll] = useState(null);
    const [mesSeleccionado, setMesSeleccionado] = useState({
        mes: new Date().getMonth() + 1,
        año: new Date().getFullYear()
    });

    const [activeCard, setActiveCard] = useState('mes'); // Nuevo estado para el card activo

    useEffect(() => {
        if (info) {
            cargarDatos(info['$tk3m09s'], mesSeleccionado.mes, mesSeleccionado.año);
        }
    }, [mesSeleccionado]);

    const cargarDatos = async (tk, mes, año) => {
        setIsLoading(true);
        const payload = {
            year: año,
            month: mes
        };

        try {
            const response = await axios.post(
                `${API_URL}/getInformationCustomers`,
                payload,
                {
                    headers: {
                        Authorization: tk
                    }
                }
            );

            const data = response.data;
            console.log('La data es', data)

            if (data.error === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }

            const clientesConCompraUnicos = filtrarClientesUnicos(data.clients_one_purchase);
            const clientesSinCompraUnicos = filtrarClientesUnicos(data.clients_no_purchase);
            const clientesDistribuidor = filtrarClientesUnicos(data.clients_stores);

            const clientesUnicos = [...clientesConCompraUnicos, ...clientesSinCompraUnicos];

            setClientesConCompra(clientesConCompraUnicos);
            setClientesSinCompra(clientesSinCompraUnicos);
            setClientesDistribuidorLta(clientesDistribuidor);
            setAllClients(clientesUnicos);

            const totalUnicos = clientesConCompraUnicos.length + clientesSinCompraUnicos.length;

            setTotalClientes(totalUnicos);

            setTotalClientesAll(data.total_clients)
            setActiveCard('mes')
        } catch (error) {
            console.log(error);
            console.log('Ha ocurrido un error');
        }
        setIsLoading(false);
    };

    const cargarTodosClientes = async (tk) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${API_URL}/getInformationCustomers`,
                {
                    headers: {
                        Authorization: tk
                    }
                }
            );

            const data = response.data;
            console.log('La data es', data);

            if (data.error === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }

            const clientesConCompraUnicos = filtrarClientesUnicos(data.clients_one_purchase);
            const clientesSinCompraUnicos = filtrarClientesUnicos(data.clients_no_purchase);
            const clientesDistribuidor = filtrarClientesUnicos(data.clients_stores);

            const clientesUnicos = [...clientesConCompraUnicos, ...clientesSinCompraUnicos];

            setClientesConCompra(clientesConCompraUnicos);
            setClientesSinCompra(clientesSinCompraUnicos);
            setClientesDistribuidorLta(clientesDistribuidor);
            setAllClients(clientesUnicos);

        } catch (error) {
            console.log(error);
            console.log('Ha ocurrido un error');
        }
        setIsLoading(false);
    };

    function filtrarClientesUnicos(clientes) {
        const correosUnicos = new Set();
        const clientesUnicos = [];

        clientes.forEach(cliente => {
            if (!correosUnicos.has(cliente.email)) {
                correosUnicos.add(cliente.email);
                clientesUnicos.push(cliente);
            }
        });

        return clientesUnicos;
    }

    const obtenerNombreMes = (mes) => {
        const meses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        return meses[mes - 1];
    };

    const addDistribuidor = () => {
        navigate('/agregar-distribuidor');
    };

    const handleDownloadClick = async () => {
        try {
            const response = await fetch(API_URL + '/exportCustomers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": info['$tk3m09s'],
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const blob = await response.blob();
            const text = await blob.text();

            if (text.trim() === '') {
                throw new Error('No data available for the selected statuses.');
            }

            const bom = '\uFEFF';
            const newBlob = new Blob([bom + text], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(newBlob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            let currentDate = getCurrentDate();

            a.download = `listado_clientes_${currentDate}.csv`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            alert('There has been a problem with your fetch operation: ' + error.message);
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    return (
        <div className=''>
            <Sidebar />
            <div className='p-4 sm:ml-64'>
                {isLoading ? (
                    <CargandoSpinner txtcharge="Cargando datos" />
                ) : (
                    <div>
                        <h1 className='text-2xl xl:text-2xl font-bold mt-5 text-black'>
                            Listado de clientes
                        </h1>
                        <div className='flex flex-wrap gap-4 mt-5'>
                            <div
                                className={`flex flex-col items-center border border-black rounded-lg shadow-md p-4 w-full sm:w-1/2 lg:w-1/4 cursor-pointer  hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500 ${activeCard === 'total' ? 'bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : 'border-blue-900 text-black'}`}
                                onClick={() => {
                                    setActiveCard('total');
                                    cargarTodosClientes(info['$tk3m09s']);
                                }}
                            >
                                <p className='text-lg'>Clientes en total</p>
                                <p className='text-2xl font-semibold mt-2'>{totalClientesAll}</p>
                            </div>

                            <div
                                className={`flex flex-col items-center border border-black rounded-lg shadow-md p-4 w-full sm:w-1/2 lg:w-1/4 cursor-pointer  hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500 ${activeCard === 'mes' ? 'bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : 'border-blue-900 text-black'}`}
                                onClick={() => {
                                    setActiveCard('mes');
                                    cargarDatos(info['$tk3m09s'], mesSeleccionado.mes, mesSeleccionado.año);
                                }}
                            >
                                <p className='text-lg'>Clientes en {obtenerNombreMes(mesSeleccionado.mes)} {mesSeleccionado.año}</p>


                                <p className='text-2xl font-semibold mt-2 '>{totalClientes}</p>

                            </div>
                        </div>

                        <div className='flex items-center my-4'>
                            <div className='flex flex-col'>
                                <p className='text-sm text-left'>Seleccionar mes</p>
                                <input
                                    type="month"
                                    className="capitalize mt-1 py-2 px-2 w-full border-2 text-sm border-gray-300 rounded"
                                    value={`${mesSeleccionado.año}-${String(mesSeleccionado.mes).padStart(2, '0')}`}
                                    onChange={(event) => {
                                        const [year, month] = event.target.value.split('-');
                                        setMesSeleccionado({ mes: parseInt(month), año: parseInt(year) });
                                    }}
                                />
                            </div>
                            <div className='flex flex-col justify-center ml-4 pt-1'>
                                <p className='text-sm text-left'>Seleccionar filtro</p>
                                <select
                                    className="p-2 border border-gray-400 rounded"
                                    value={mostrarClientes}
                                    onChange={(e) => {
                                        setMostrarClientes(e.target.value);
                                        setPaginaActualConCompra(1);
                                        setPaginaActualSinCompra(1);
                                        setPaginaActualDistribuidor(1);
                                    }}
                                >
                                    <option value="todos">Todos los clientes</option>
                                    <option value="conCompra">Clientes con Compra</option>
                                    <option value="sinCompra">Clientes sin Compra</option>
                                    <option value="distribuidor">Todos los Distribuidores</option>
                                </select>
                            </div>
                            {info && info['$r07sad'] !== 'order_viewer' && (
                                <div className='pt-5'>
                                    <button
                                        name='btn-nextpague'
                                        id='btn-nextpague'
                                        onClick={addDistribuidor}
                                        className='ml-6 bg-[#1a2869] hover:bg-[#007bfc] transition-colors duration-300 py-2 px-5 text-white rounded'
                                    >
                                        Agregar Distribuidor
                                    </button>
                                </div>
                            )}
                            {info && info['$r07sad'] !== 'order_viewer' && (
                                <div className='pt-5'>
                                    <button
                                        name='btn-downloadclient'
                                        id='btn-downloadclient'
                                        className='ml-4 bg-[#1a2869] hover:bg-[#007bfc] transition-colors duration-300 py-2 px-5 text-white rounded'
                                        onClick={handleDownloadClick}
                                    >
                                        <i className="fas fa-download"></i> Descargar lista de clientes
                                    </button>
                                </div>
                            )}
                        </div>

                        <div>
                            <TablaClientes
                                clientesConCompra={clientesConCompra}
                                clientesSinCompra={clientesSinCompra}
                                clientesDistribuidor={clientesDistribuidorLta}
                                todoslosclientes={allClients}
                                mostrarClientes={mostrarClientes}
                                setMostrarClientes={setMostrarClientes}
                                paginaActualConCompra={paginaActualConCompra}
                                setPaginaActualConCompra={setPaginaActualConCompra}
                                paginaActualSinCompra={paginaActualSinCompra}
                                setPaginaActualSinCompra={setPaginaActualSinCompra}
                                paginaActualDistribuidor={paginaActualDistribuidor}
                                setPaginaActualDistribuidor={setPaginaActualDistribuidor}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Clientes;
