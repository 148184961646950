import React, { useEffect, useState } from 'react';
// Importa useNavigate de react-router-dom si estás utilizando React Router para la redirección.
// import { useNavigate } from 'react-router-dom';

const ModalTelegfonogoogle = ({ isVisible, onClose }) => {
    const [numeroTelefono, setNumeroTelefono] = useState('')
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const nombreUsuario = token?.['$name'] || ''
    // Función para manejar el cambio en el input del número telefónico
    const handleTelefonoChange = (event) => {
        setNumeroTelefono(event.target.value);
    };

    // Función para manejar el envío del número telefónico (aquí puedes agregar la lógica para guardar el número)
    const handleSubmitTelefono = () => {
        //  console.log('Número telefónico ingresado:', numeroTelefono);
        // Aquí puedes agregar la lógica para enviar el número telefónico al backend o almacenarlo como prefieras
        onClose(); // Cierra el modal
    };

    if (!isVisible) {
        return null;
    }


    return (
        <div role="alert" className="fixed inset-0 bg-gray-600 bg-opacity-50 px-5 py-20 z-10">
            <div className="rounded-xl border border-gray-100 bg-white p-4">
                <div className="flex items-start gap-4">
                    <span className="text-green-600">
                        {/* Ícono de éxito */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </span>
                    <div className="flex-1">
                        <strong className="block font-medium text-gray-900">Bienvenido {nombreUsuario}</strong>
                        <p className="mt-1 text-sm text-gray-700">Para mejorar la experiencia, ingresa tu número telefónico:</p>
                        <input
                            type="text"
                            value={numeroTelefono}
                            onChange={handleTelefonoChange}
                            placeholder="Tu número telefónico"
                            className="mt-2 p-2 border rounded"
                        />
                        <button
                            onClick={handleSubmitTelefono}

                            name='btn-sendgoogle'
                            id='btn-sendgoogle'
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
                        >
                            Enviar
                        </button>
                    </div>
                    <button onClick={onClose} className="text-gray-500 transition hover:text-gray-600"

                        name='btn-closegoogole'
                        id='btn-closegogoole'

                    >
                        <span className="sr-only">Cerrar</span>
                        {/* Ícono de cerrar */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

    );
};

export default ModalTelegfonogoogle;
