import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { API_URL } from '../../constants/constants';
import axios from 'axios';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import { toast } from 'react-toastify';
import Sidebar from '../../componentes/Admi/Sidebar';


const AddCupon = () => {

    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();


    const [cupon, setCupon] = useState({
        nombre: '',
        cantidad: 0,
        des_cantidad: 0,
        des_porcentaje: 0,
        estatus: true,
        expiracion: '',
        monto_minimo: 0,
        prod_participantes: [],
        tipo_descuento: 'cantidad'
    });


    useEffect(() => {
        if (location.state && location.state.cupon) {
            const cuponData = location.state.cupon;

            cuponData.prod_participantes = cuponData.prod_participantes.map(id => Number(id));
            // Determinar el tipo de descuento basado en los valores de des_cantidad y des_porcentaje
            if (cuponData.des_cantidad > 0) {
                cuponData.tipo_descuento = 'cantidad';
            } else if (cuponData.des_porcentaje > 0) {
                cuponData.tipo_descuento = 'porcentaje';
            }

            cuponData.expiracion = cuponData.expiracion.split('T')[0];

            // console.log('los datos son ', cuponData)
            setCupon(cuponData);
        }
    }, [location.state]);

    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null


    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const getProducts = async () => {
        try {
            const response = await fetch(`${API_URL}/getProductsToSearch`);
            const data = await response.json();
            // console.log('Proisutos',data.success )
            setProducts(data.success);
        } catch (error) {
            console.error("Error al obtener los productos:", error);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
    
        // Validar el campo des_porcentaje para que esté entre 0 y 20
        if (name === 'des_porcentaje') {
            newValue = Math.min(Math.max(value, 0), 20);
        }
    
        const isNumber = ['cantidad', 'des_cantidad', 'des_porcentaje', 'monto_minimo'].includes(name);
        setCupon({
            ...cupon,
            [name]: isNumber ? parseFloat(newValue) : name === 'prod_participantes' ? value.split(',').map(id => '' + id) : value
        });
    };
    

    const handleTipoDescuentoChange = (e) => {
        setCupon({
            ...cupon,
            tipo_descuento: e.target.value
        });
    };

    const handleProductSelection = (productId) => {
        setCupon(prevCupon => {
            const prod_participantes = [...prevCupon.prod_participantes, '' + productId];
            return { ...prevCupon, prod_participantes };
        });
        setSearchTerm(''); // Limpiar el campo de búsqueda
    };

    const handleProductRemoval = (productId) => {
        setCupon(prevCupon => {
            const prod_participantes = prevCupon.prod_participantes.filter(id => id !== '' + productId);
            return { ...prevCupon, prod_participantes };
        });
    };

    const handleSubmit = async (e) => {
        // Validaciones
        if (!cupon.nombre || !cupon.cantidad || (!cupon.des_cantidad && !cupon.des_porcentaje) || !cupon.expiracion || !cupon.monto_minimo || cupon.prod_participantes.length === 0) {
            toast.error("Por favor, completa todos los campos y selecciona al menos un producto.");
            return;
        }

        setIsLoading(true);


        e.preventDefault();
        const updatedCupon = {
            ...cupon,
            prod_participantes: cupon.prod_participantes.map(id => '' + id),
            created_by: info['$udi09345']
        };
        // console.log(updatedCupon);
        // Aquí puedes enviar updatedCupon a tu API
        try {
            const response = await axios.post(API_URL + '/createCoupon', updatedCupon, {
                headers: { 'Authorization': info['$tk3m09s'] },
                timeout: 500000
            });

            // console.log('la repsues es ', response)

            toast.success("Cupón actualizado exitosamente.");


        } catch (error) {
            console.error("Error al obtener los productos:", error);
            toast.error("Error al actualizar el cupón.");
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const updateCupon = async (e) => {
        e.preventDefault();

        // Validaciones
        if (!cupon.nombre || !cupon.cantidad || (!cupon.des_cantidad && !cupon.des_porcentaje) || !cupon.expiracion || !cupon.monto_minimo || cupon.prod_participantes.length === 0) {
            toast.error("Por favor, completa todos los campos y selecciona al menos un producto.");
            return;
        }

        setIsLoading(true);
        const updatedCupon = {
            ...cupon,


            id: '' + cupon.id,

            prod_participantes: cupon.prod_participantes.map(id => '' + id),
            updated_by: info['$udi09345']
        };
        // console.log('enviare', updatedCupon)
        try {
            const response = await axios.put(API_URL + '/updateCoupon', updatedCupon, {
                headers: { 'Authorization': info['$tk3m09s'] },
                timeout: 500000
            });
            // console.log('la respuesta es ', response);
            toast.success("Cupón actualizado exitosamente.");

        } catch (error) {
            console.error("Error al actualizar el cupón:", error);
            toast.error("Error al actualizar el cupón.");
            setIsLoading(false);

        } finally {
            setIsLoading(false);
        }
    };



    const filteredProducts = products.filter(product =>
        (product.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.sku.toLowerCase().includes(searchTerm.toLowerCase())) &&
        !cupon.prod_participantes.includes('' + product.id_producto)
    );


    return (
        <div className="">
            <Sidebar />

            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (

                <div className='p-4 sm:ml-64'>

                    <div className='flex items-center mb-4'>
                        <div>
                            <Link className='pr-5' to={'/promociones-cupones'}>
                                <i className="fa-solid fa-arrow-left"></i>
                            </Link>
                        </div>
                        {
                            !location?.state?.cupon
                            ? <h1 className="text-2xl font-bold">Nuevo cupón</h1>
                            : <h1 className="text-2xl font-bold">Editar cupón</h1>
                        }
                    </div>

                    <form onSubmit={cupon.id ? updateCupon : handleSubmit} className="space-y-4">
                        <div className=' w-full flex justify-normal '>

                            <div className='w-1/3 mx-4'>
                                <label className="block text-sm font-medium">Código del cupón</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    value={cupon.nombre.toUpperCase()}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        handleChange({
                                            target: {
                                                name,
                                                value: value.toUpperCase(),
                                            }
                                        });
                                    }}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className='w-1/3'>
                                <label className="block text-sm font-medium">Cupones disponibles</label>
                                <input
                                    type="number"
                                    name="cantidad"
                                    value={cupon.cantidad}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className='mx-4'>
                                <label className="block text-sm font-medium">Activo</label>
                                <label
                                    htmlFor="estatusCheckbox"
                                    className={`relative inline-block h-8 mt-2 w-16 cursor-pointer rounded-full transition [-webkit-tap-highlight-color:_transparent] ${cupon.estatus ? 'bg-green-500' : 'bg-red-500'}`}
                                >
                                    <input
                                        type="checkbox"
                                        id="estatusCheckbox"
                                        className="peer sr-only [&:checked_+_span_div[data-checked-icon]]:block [&:checked_+_span_div[data-unchecked-icon]]:hidden"
                                        checked={cupon.estatus}
                                        onChange={() => setCupon({ ...cupon, estatus: !cupon.estatus })}
                                    />
                                    <span
                                        className="absolute inset-y-0 start-0 z-10 m-1 inline-flex h-6 w-6 items-center justify-center rounded-full bg-white text-gray-400 transition-all peer-checked:start-8 peer-checked:text-green-600"
                                    >
                                        <div
                                            data-unchecked-icon
                                            className="h-4 w-4 text-xs"
                                        >
                                            No
                                        </div>
                                        <div
                                            data-checked-icon
                                            className="hidden h-4 w-4 text-xs"
                                        >
                                            Sí
                                        </div>
                                    </span>
                                </label>
                            </div>


                        </div>

                        <div className='mx-4'>
                            <div>
                                <label className="block text-sm font-medium">Tipo de descuento</label>
                                <div className="flex items-center mt-1">
                                    <label className="mr-4">
                                        <input
                                            type="radio"
                                            name="tipo_descuento"
                                            value="cantidad"
                                            checked={cupon.tipo_descuento === 'cantidad'}
                                            onChange={handleTipoDescuentoChange}
                                            className="mr-2"
                                        />
                                        Cantidad
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="tipo_descuento"
                                            value="porcentaje"
                                            checked={cupon.tipo_descuento === 'porcentaje'}
                                            onChange={handleTipoDescuentoChange}
                                            className="mr-2"
                                        />
                                        Porcentaje
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className=' flex '>

                            {cupon.tipo_descuento === 'cantidad' && (

                                <div className='w-1/3 mx-4'>
                                    <label className="block text-sm font-medium">Monto a descontar</label>
                                    <input
                                        type="number"
                                        name="des_cantidad"
                                        value={cupon.des_cantidad}
                                        min="0"
                                        max="250"
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                </div>

                            )}

                            {cupon.tipo_descuento === 'porcentaje' && (
                                <div className='w-1/3 mx-4'>
                                    <label className="block text-sm font-medium">Porcentaje a descontar</label>
                                    <input
                                        type="number"
                                        name="des_porcentaje"
                                        value={cupon.des_porcentaje}
                                        min="0"
                                        max="20"
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    />
                                </div>

                            )}


                            <div className='w-1/3'>
                                <label className="block text-sm font-medium">Fecha de expiración</label>
                                <input
                                    type="date"
                                    name="expiracion"
                                    value={cupon.expiracion}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                            <div className='mx-4 w-1/3'>
                                <label className="block text-sm font-medium">Monto mínimo</label>
                                <input
                                    type="number"
                                    name="monto_minimo"
                                    value={cupon.monto_minimo}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                />
                            </div>

                        </div>

                        <div className="flex space-x-4 mx-4">
                            <div className="flex-1">
                                <label className="block text-sm font-medium">Buscar productos</label>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                    placeholder="Buscar por nombre o SKU"
                                />
                                {searchTerm.length > 2 && filteredProducts.length > 0 && (
                                    <div className='space-y-4 mt-4 max-h-64 overflow-y-auto'>
                                        {filteredProducts.map(product => (
                                            <div key={product.id_producto} className='flex items-center space-x-4'>
                                                <img src={product.imagen} alt={product.titulo} className='w-16 h-16 object-cover' />
                                                <div>
                                                    <h3 className='font-semibold'>{product.titulo}</h3>
                                                    <p>SKU: {product.sku}</p>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleProductSelection(product.id_producto)}
                                                        className='p-2 bg-blue-500 text-white rounded'
                                                    >
                                                        Seleccionar
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="flex-1">
                                <label className="block text-sm font-medium">Productos seleccionados</label>
                                <div className="mt-1 space-y-4 max-h-64 overflow-y-auto">
                                    {cupon.prod_participantes.map(productId => {
                                        const product = products.find(p => p.id_producto === parseInt(productId));
                                        return product ? (
                                            <div key={product.id_producto} className='flex items-center space-x-4'>
                                                <img src={product.imagen} alt={product.titulo} className='w-16 h-16 object-cover' />
                                                <div>
                                                    <h3 className='font-semibold'>{product.titulo}</h3>
                                                    <p>SKU: {product.sku}</p>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleProductRemoval(product.id_producto)}
                                                        className='p-2 bg-red-500 text-white rounded'
                                                    >
                                                        Eliminar
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            </div>
                        </div>


                        <div className='mx-4'>
                            <button type="submit" className="bg-[#1a2869] text-white py-2 px-4 rounded hover:bg-[#3b82f6] transition duration-300 ease-in-out">
                                {cupon.id ? 'Actualizar Cupón' : 'Agregar Cupón'}
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default AddCupon;
