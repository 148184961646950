import React, { useState } from 'react';
import Header from '../../../componentes/Headers/Header';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import Footer from '../../../componentes/footer/Footer';
import ImageComponent from '../../../componentes/comparador/ImageComponent';
import CardComparativo from '../../../componentes/cards/CardComparativo';
import DtoComparativo from '../../../componentes/comparador/Datoscomparativos';
import imagenDeFondo from '../../../assets/Rampas1.webp'



// pagina comparacion
function Comparation() {

    const rampas = [
        {
            id_producto: 1,
            imagen: 'https://hantec.us/cdn/shop/files/HNT4L1RJ-Posicion1.jpg?v=1691771135&width=360',
            nombre: 'Rampa de Alineación 4 Postes Ascensor Discos de Alienación y Rolling Jack 4.7 Toneladas Hantec',
            precio: {
                cantidad: 90643.81,
                moneda: 'MXN'
            },
            datocom1: 'DTOCompracion1-1',
            datocom2: 'DTOCompracion2-1',
            datocom3: 'DTOCompracion3-1',
            datocom4: 'DTOCompracion4-1',
            datocom5: 'DTOCompracion5-1',
            datocom6: 'DTOCompracion6-1',
        },
        {
            id_producto: 2,
            imagen: 'https://hantec.us/cdn/shop/files/HNT4L1RJ-Posicion1.jpg?v=1691771135&width=360',
            nombre: 'Rampa de Alineación 4 Postes Discos de Alineación 4.7 Toneladas Hantec',
            precio: {
                cantidad: 88298.70,
                moneda: 'MXN'
            },
            datocom1: 'DTOCompracion1-2',
            datocom2: 'DTOCompracion2-2',
            datocom3: 'DTOCompracion3-2',
            datocom4: 'DTOCompracion4-2',
            datocom5: 'DTOCompracion5-2',
            datocom6: 'DTOCompracion6-2',
        },
        {
            id_producto: 3,
            imagen: 'https://hantec.us/cdn/shop/files/Copiade1_2.jpg?v=1704482964&width=713',
            nombre: 'Rampa Ascensor Portería dos Postes Heavy Dutty 14500LBS 6.5 TON 110V',
            precio: '72,431.65',
            datocom1: 'DTOCompracion1-3',
            datocom2: 'DTOCompracion2-3',
            datocom3: 'DTOCompracion3-3',
            datocom4: 'DTOCompracion4-3',
            datocom5: 'DTOCompracion5-3',
            datocom6: 'DTOCompracion6-3',
        },
        {
            id_producto: 4,
            imagen: 'https://hantec.us/cdn/shop/files/Copiadeproductosrojos-57_97ca0cc7-8d20-4c26-b186-5eb11da40deb.jpg?v=1703027398&width=713',
            nombre: 'Rampa 2 postes Altura Libre Roja Ascensor Simétrica Desbloqueo Manual 4.7 Toneladas Hantec',
            precio: '45079.36',
            datocom1: 'DTOCompracion1-4',
            datocom2: 'DTOCompracion2-4',
            datocom3: 'DTOCompracion3-4',
            datocom4: 'DTOCompracion4-4',
            datocom5: 'DTOCompracion5-4',
            datocom6: 'DTOCompracion6-4',
        }
    ];

    const [productosParaComparar, setProductosParaComparar] = useState([]);

    const agregarParaComparar = (producto) => {
        if (productosParaComparar.length < 2 && !productosParaComparar.some(p => p.id_producto === producto.id_producto)) {
            setProductosParaComparar([...productosParaComparar, producto]);
        } else if (productosParaComparar.length === 2 && !productosParaComparar.some(p => p.id_producto === producto.id_producto)) {
            setProductosParaComparar([productosParaComparar[0], producto]);
        }
    };

    const limpiarComparacion = () => {
        setProductosParaComparar([]);
    };

    return (
        <div>
            <Header />
            <ScrollableCategories />
            <div className='w-full lg:w-4/5 mx-auto mt-8'>
                <ImageComponent backgroundImage={imagenDeFondo} />
                <DtoComparativo productos={productosParaComparar} onLimpiarComparacion={limpiarComparacion} />
                <CardComparativo rampas={rampas} onAgregarComparar={agregarParaComparar} />


            </div>

            <Footer />
        </div>
    );
}

export default Comparation;