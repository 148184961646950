import React from 'react';

const DatosExtraPay = ({ extra }) => {
    if (!extra) {
        return null;
    }

    return (
        <div className="mt-4 bg-gray-100 p-4 rounded">
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Banco:</p>
                <h2 className="text-[#012a45] text-base text-right font-semibold">{extra?.banco}</h2>
            </div>
           
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Marca:</p>
                <h2 className="text-[#012a45] text-base text-right font-semibold">{extra?.marca}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Respuesta:</p>
                <h2 className="text-[#012a45] text-base text-right font-semibold">{extra?.nbResponse}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Error:</p>
                <h2 className="text-[#012a45] text-base text-right font-semibold">{extra?.nb_error}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">No. de Autorización :</p>
                <h2 className="text-[#012a45] text-base text-right font-semibold">{extra?.nuAut}</h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Referencia:</p>
                <h2 className="text-[#012a45] text-base text-right font-semibold">{extra?.referencia}</h2>
            </div>
            
        </div>
    );
};

export default DatosExtraPay;
