import axios from 'axios';

// Exporta la función para ser reutilizada en distintos componentes
export const cotizarCascotes = async (lstaprsku, cp, colonia, idoficna) => {
    // console.log('Cotizare ah', cp, colonia)

    const sumaValorDeclarado = lstaprsku.reduce((acumulador, producto) => {
        const valorDeclarado = Number(producto.valor_declarado);
        return acumulador + (isNaN(valorDeclarado) ? 0 : valorDeclarado);
    }, 0);

    const storedCodigoPostal = cp;
    const storedNeighborhood = colonia;

    const nuevoArreglo = lstaprsku.map(producto => ({
        alto: producto.alto_cm,
        ancho: producto.ancho_cm,
        cantidad: producto.no_piezas,
        contenido: producto.contenido,
        largo: producto.largo_cm,
        peso: producto.peso
    }));

    const dataConIdOffice = {
        calle: '',
        noExterior: '',
        colonia: storedNeighborhood,
        ciudad: '',
        estado: '',
        ...(idoficna && { idOffice: idoficna, ocurre: "true" }),
        cp: storedCodigoPostal,
        valorDeclarado: 16000,
        paquetes: nuevoArreglo,
    };

    // console.log('enviare a cotizar', dataConIdOffice)

    try {
        const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/cotEnvio', dataConIdOffice);


        console.log('Respuesta', response.data)

        const total = response.data.TOTAL; if (total == null) { // Verifica tanto null como undefined
            // console.log("El total no está disponible en la respuesta.");
            const datosstreing= response.data
            return datosstreing; // Retorna la respuesta de la API para uso posterior
            // Manejar el caso donde TOTAL no está disponible
        } else {
            // console.log("El total es:", total);
            // Puedes retornar o hacer algo con el valor de TOTAL aquí
            return total;
        }


    } catch (error) {
        console.error("Error al enviar los datos con Ocurre directo:", error);
        throw error; // Propaga el error para manejarlo en el componente
    }
}
