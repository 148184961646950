import React from 'react';
import { useNavigate } from 'react-router-dom';

const ComboTable = ({ combos }) => {
    const navigate = useNavigate();

    // Asegúrate de que solo el combo específico se pase a esta función.
    const goToEdit = (combo) => {
        navigate('/add-combo', { state: { combo: combo } });
    };

    return (
        <div className='mt-5'>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th className="p-3">SKU</th>
                        <th className="p-3">Imagen</th>
                        <th className="p-3">Nombre</th>
                        <th className="p-3">Estatus</th>
                        <th className="p-3">Fecha de Creación</th>
                        <th className="p-3">Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    {combos.map((combo) => (
                        <tr key={combo.id_combo} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 hover:font-black">
                            {/* Añadir onClick solo al elemento SKU para redirigir a la edición de ese combo específico */}
                            <th className="p-3 cursor-pointer hover:font-black" onClick={() => goToEdit(combo)}>{combo.sku}</th>
                            <td className="p-3">
                                <img width="300" height="200"
 src={combo.imagen} alt={`Combo ${combo.nombre_combo}`} className='w-20 h-20 mr-5' />
                            </td>
                            <td className="p-3">{combo.nombre_combo}</td>
                            <td className="p-3">

                                <div className={` p-2 text-center rounded ${combo.active ? 'bg-green-700 text-white' : 'bg-red-700 text-white'}`}>

                                    <p>{combo.active ? 'Activo' : 'Inactivo'}</p>

                                </div>

                            </td>
                            <td className="p-3">{new Date(combo.created_at).toLocaleDateString()}</td>

                            <td className="p-3">
                                <button
                                    name='editcmbo'
                                    id='editcmbo'
                                    onClick={() => goToEdit(combo)}
                                    className='ml-5 cursor-pointer px-5 py-2.5 bg-[#1e3d8b] rounded transition duration-300 hover:bg-[#1e3d8b] hover:opacity-75 text-white'>
                                    <i className="far fa-edit"></i> Editar
                                </button>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ComboTable;
