// SkeletonLoader.js
import React from 'react';

const SkeletonLoader = () => (
 <div className=' lg:flex lg:w-4/5 mx-auto lg:mt-10'>
<div className='lg:w-1/2'>
<div className="border border-blue-700 shadow rounded-md p-4 max-w-sm w-full mx-auto">
    <div className="animate-pulse flex space-x-4">
      <div className="rounded-full bg-slate-700 h-10 w-10"></div>
      <div className="flex-1 space-y-6 py-1">
        <div className="h-2 bg-slate-700 rounded"></div>
        <div className="space-y-3">
          <div className="grid grid-cols-3 gap-4">
            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
          </div>
          <div className="h-2 bg-slate-700 rounded"></div>
        </div>
      </div>
    </div>
  </div>
</div>

  <div className='h-96  flex flex-col justify-end mt-20 lg:mt-0  lg:w-1/2 lg:justify-start	'> 
  <div className="border border-blue-700 shadow rounded-md p-4 max-w-sm w-full mx-auto ">
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="h-2 bg-slate-700 w-60 rounded"></div>
        <div className="space-y-3">
          <div className="grid grid-cols-3 gap-4">
            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
          </div>
          <div className="h-2 bg-slate-700 w-40 rounded mx-auto"></div>
        </div>
      </div>
    </div>
  </div>
  </div>

 </div>
);

export default SkeletonLoader;
