import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Headers/Header';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import DatosProducto from '../../../componentes/productos/DatosProducto';
import Footer from '../../../componentes/footer/Footer';
import Preguntasproducto from '../../../componentes/productos/Preguntasproducto';
import Resenaproducto from '../../../componentes/productos/Resenaprducto';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import axios from 'axios';
import GridLoader from "react-spinners/GridLoader";
import Datosresenas from '../../../componentes/productos/Datosresenas';
import { API_URL } from '../../../constants/constants';
// import { viewProduct } from '../../../utils/PixelMeta/metaPixelEvents';
import { dateDiffFromNow } from '../../../utils/utils';
import { SnipTrafficPlatform } from '../../../utils/Analitycs/analytics';
import FloatingWhatsAppButton from '../../../componentes/botones/FloatingWhatsAppButton';

const Product = () => {
    const [variant, setVariant] = useState('');
    const [id_product, setIdProduct] = useState('');
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    const location = useLocation();
    const currentPageUrl = window.location.origin + location.pathname;

    const navigate = useNavigate();

    const [idsusertoken, setIdsusertoken] = useState(null);
    const [listproductosdata, setListproductosdata] = useState([]);
    const [isLoadingProductos, setIsLoadingProductos] = useState(false);
    const [listapreguntas, setListaPreguntas] = useState([]);
    const [listacometarios, setListaComentarios] = useState([]);

    const [estaEnFavoritos, setEstaEnFavoritos] = useState(false);
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;

    const [datosResenaView, setDatosResenaView] = useState({ promedio: 0, numResenas: 0 });
    const [value, setValue] = useState([]);

    const [isDatosProductoLoaded, setIsDatosProductoLoaded] = useState(false);
    const [isPreguntasLoaded, setIsPreguntasLoaded] = useState(false);
    const [isResenasLoaded, setIsResenasLoaded] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (token) {
            setIdsusertoken(token['$udi09345']);
            verifcarFavoritos();
        }

        const payload = {
            "variant": parseInt(id_product)
        };

        const cargarDatos = async () => {
            setIsLoadingProductos(true);
            try {
                const respuesta = await axios.get(API_URL + '/getProductByVariant?variant='+variant, { timeout: 15000 });
                const datosRecibidos = await respuesta.data;

                setIdProduct(datosRecibidos["id_producto"]);
                setValue(datosRecibidos);

                if (datosRecibidos.length > 0) {
                    const skuProducto = datosRecibidos[0].sku;
                    const categoriaProducto = datosRecibidos[0].categoria;

                    const skusExcluidos = ['HNTP1N', 'HNQR9KIT3', 'HTCRJ01'];

                    if (categoriaProducto === 'Rampas' && !skusExcluidos.includes(skuProducto)) {
                        obtenerProductosConSKU(skuProducto, true);
                    } else {
                        obtenerProductosConSKU(skuProducto, false);
                    }
                }

                setIsDatosProductoLoaded(true);
            } catch (error) {
                console.error('Error al cargar datos prodcuto:', error);
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out getProductById');
                }
            } finally {
                setIsLoadingProductos(false);
            }
        };

        cargarDatos();
    }, [variant]);

    useEffect(() => {
        if (isDatosProductoLoaded) {
            const cargarPreguntas = async () => {
                const payloadata = {
                    "id_producto": parseInt(id_product)
                };
                try {
                    const response = await axios.post(API_URL + '/getQAByProductId', payloadata, { timeout: 15000 });
                    setListaPreguntas(response.data.success);
                    setIsPreguntasLoaded(true);
                } catch (error) {
                    console.error("Error al obtener las preguntas:", error);
                    if (error.code === 'ECONNABORTED') {
                        console.log('Request timed out getQAByProductId');
                    }
                }
            };

            cargarPreguntas();
        }
    }, [isDatosProductoLoaded, id_product]);

    useEffect(() => {
        if (isPreguntasLoaded) {
            const comtariosproducto = async () => {
                const payloadata = {
                    "id_producto": parseInt(id_product)
                };
                try {
                    const response = await axios.post(API_URL + '/getCommentsByProductId', payloadata, { timeout: 15000 });

                    const totalCalificaciones = response.data.success.reduce((acumulado, comentarioActual) => acumulado + comentarioActual.calificacion, 0);
                    const promedioCalificaciones = totalCalificaciones / response.data.success.length;

                    setDatosResenaView({
                        promedio: promedioCalificaciones.toFixed(2),
                        numResenas: response.data.success.length
                    });
                    setListaComentarios(response.data.success);
                    setIsResenasLoaded(true);
                } catch (error) {
                    console.error("Error al obtener los comentarios:", error);
                    if (error.code === 'ECONNABORTED') {
                        console.log('Request timed out getCommentsByProductId');
                    }
                }
            };

            comtariosproducto();
        }
    }, [isPreguntasLoaded, id_product]);

    useEffect(() => {
        const extractUTMParameters = () => {
            const searchParams = new URLSearchParams(location.search);
            let id = searchParams.get('variant');
            setVariant(id);

            const utmSource = searchParams.get('utm_source');
            const utmMedium = searchParams.get('utm_medium') || 'desconocido';
            const utmCampaign = searchParams.get('utm_campaign');
            const utmContent = searchParams.get('utm_content') || 'desconocido';

            let today = new Date();
            today.setHours(0, 0, 0, 0);

            const lastVisitDateStr = localStorage.getItem('utm');
            const lastVisitDate = lastVisitDateStr ? new Date(JSON.parse(lastVisitDateStr).date) : null;
            lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

            if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
                window.localStorage.removeItem("utm");
            }

            if (utmSource && utmMedium && utmCampaign) {
                const valuesUTM = {
                    "utmSource": utmSource,
                    "utmMedium": utmMedium,
                    "utmCampaign": utmCampaign,
                    "utmContent": utmContent,
                    "date": today
                };
                window.localStorage.setItem("utm", JSON.stringify(valuesUTM));

                SnipTrafficPlatform(utmSource, utmCampaign, utmContent);
            }
        };

        extractUTMParameters();
    }, [location.search]);

    const obtenerProductosConSKU = async (sku, motors) => {
        setIsLoadingProductos(true);

        try {
            const payload = { sku };
            const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getProductsBySku', payload, { timeout: 15000 });
            let jsonData = response.data;

            if (motors) {
                const motorPayload = { sku: "MOTOR" };
                const motorResponse = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getProductsBySku', motorPayload, { timeout: 15000 });
                const motorData = motorResponse.data;
                jsonData = jsonData.concat(motorData);
            }

            if (jsonData.length === 0) {
                setListproductosdata("El arreglo venía vacío");
            } else {
                setListproductosdata(jsonData);
            }
        } catch (error) {
            console.error("Error al obtener los productos con el SKU:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out getProductsBySku');
            }
        } finally {
            setIsLoadingProductos(false);
        }
    };

    const verifcarFavoritos = async () => {
        const data = {
            "id_usuario": token['$udi09345'],
            "id_product": parseInt(id_product)
        };

        try {
            let response = await axios.post(API_URL + "/checkFavoriteList", data, { headers: { 'Authorization': token['$tk3m09s'] }, timeout: 15000 });
            let estaEnFavoritos = response.data.success;
            setEstaEnFavoritos(estaEnFavoritos);
        } catch (error) {
            console.error('Error al obtener los favoritos: ' + error.toString());
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out checkFavoriteList');
            }
        }
    };

    const renderLoadingIndicator = () => (
        <div className="flex flex-col justify-center items-center h-screen">
            <GridLoader
                color="#1A2869"
                size={50}
                speedMultiplier={1}
                width={2.5}
            />
            <p className='mt-2 text-[#1A2869] font-black'>Cargando datos</p>
        </div>
    );

    const handlePreguntar = async (pregunta) => {
        const payload = {
            "id_usuario": token['$udi09345'],
            "id_producto": parseInt(id_product),
            "pregunta": pregunta
        };

        try {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                },
                timeout: 15000
            };

            const response = await axios.post(API_URL + '/questionAndAnswerByUser', payload, config);
            cuestionproducto();
        } catch (error) {
            console.error('No se hizo la pregunta', error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out questionAndAnswerByUser');
            }
        }
    };

    return (
        <div className='homeconte2 bg-gray-100 '>
            <Header />

            <main>
                <ScrollableCategories />

                {isLoadingProductos || (Array.isArray(listproductosdata) && listproductosdata.length === 0) || !listproductosdata
                    ? renderLoadingIndicator()
                    : <div>
                        <FloatingWhatsAppButton page="productos" categoryName={value[0]?.titulo} urlproduto={fullUrl} />

                        <DatosProducto
                            datos={value}
                            id={id_product}
                            productsend={listproductosdata}
                            datosredan={datosResenaView}
                            enfavoritos={estaEnFavoritos}
                            verifcarFavoritos={verifcarFavoritos}
                        />

                        {isDatosProductoLoaded && (
                            <div className='w-full lg:w-4/5 mx-auto '>
                                <Preguntasproducto
                                    preguntaslitas={listapreguntas}
                                    idusuario={idsusertoken}
                                    onPreguntar={handlePreguntar}
                                    nombreproducto={value[0]?.titulo}
                                    currentPageUrl={currentPageUrl}
                                />
                            </div>
                        )}

                        {isPreguntasLoaded && (
                            <div className="flex flex-col md:flex-row lg:w-4/5 mx-auto">
                                <div className="md:order-2 lg:w-1/2">
                                    <Datosresenas listacalculos={listacometarios} />
                                </div>
                                <div className="md:order-1 lg:w-1/2">
                                    <Resenaproducto listacometarios={listacometarios} />
                                </div>
                            </div>
                        )}
                    </div>
                }
            </main>
            
            {isResenasLoaded && <Footer />}
        </div>
    );
};

export default Product;