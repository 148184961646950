import React, { useState, useEffect, useRef } from 'react';
import ListaProductos from '../productos/ListaProductos';
import FavoriteList from '../favoritos/FavoriteList';
import CardCarrito from '../cards/CardCarrito';
import DatosTotal from '../pagos/DatosTotal';
import { Link, useNavigate } from 'react-router-dom';
import { useProductStore, useProductsCart } from '../../store/bookStore';
import axios from 'axios';
import SkeletonLoader from '../cargacomponetes/SkeletonLoader'
import FavoritosLista from '../Usercliente/FavoritosLista';
import { API_URL } from '../../constants/constants';
import { toast, ToastContainer, Zoom, Bounce, Slide, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { goToPay } from '../../utils/PixelMeta/metaPixelEvents';



function ProductCartList({ productos }) {
    const token = window.localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(window.localStorage.getItem('k-9SMD879na89dN87m')) : null
    const cartLocal = window.localStorage.getItem('cartLocal') ? JSON.parse(window.localStorage.getItem('cartLocal')) : null

    const { carts, error, fetchCarts, deleteCart } = useProductsCart()
    // console.log('carro loscal ', cartLocal)
    // console.log('--carrito--',carts)

    const [listProductsSeason, setListProductsSeason] = useState([])

    const [loading, setLoading] = useState(false); // Estado inicial de loading en true
    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState([]);
    const [totalPrecio, setTotalPrecio] = useState(0);
    const [cantidadProductos, setCantidadProductos] = useState(0);
    const [plazos, setPlazos] = useState(""); // Cambio aquí: inicializar como string vacío


    const [bloqueo, setBloqueo] = useState(false);

    const consultarDisponibilidad = async (productos) => {
        setBloqueo(false);

        try {
            for (const producto of productos) {
                // console.log('El producto es ', producto);
                const payload = {
                    "id_producto": producto.id_producto
                };

                const response = await axios.post(`${API_URL}/getProductsToCart`, payload);

                // console.log(`Disponibilidad recibida para id_producto ${producto.id_producto}:`, response.data.product);

                const productoRespuesta = response.data.product;

                // Verificar si la cantidad es mayor que el stock disponible
                if (producto.cantidad > productoRespuesta.stock.disponible) {
                    if (productoRespuesta.stock.disponible > 1) {
                        await updateQuantity(producto.id_producto, productoRespuesta.stock.disponible);
                        toast.warn(`Aviso: el stock disponible del producto ${producto.titulo} es de ${productoRespuesta.stock.disponible}`);
                    } else {
                        if (producto.precio === 0) {
                            await removeItem(producto.id_producto);
                            toast.warn(`El producto de regalo ${producto.titulo} se ha eliminado del carrito debido a la falta de inventario. Disculpe las molestias.`);
                            setBloqueo(false);
                        } else {
                            toast.warn(`Aviso: El producto ${producto.titulo} se encuentra agotado. Por favor, retírelo del carrito para continuar.`);
                            setBloqueo(true);
                        }
                    }
                }

                // Comparar campos de precios y descuentos solo si el precio es diferente de 0
                if (producto.precio !== 0) {
                    let nuevoDescuento = producto.descuento;
                    let nuevoPrecio = producto.precio;
                    let nuevoPrecioSinIva = producto.precio_sin_iva;

                    if (producto.descuento !== productoRespuesta.descuento) {
                        nuevoDescuento = productoRespuesta.descuento;
                    }
                    if (producto.precio !== productoRespuesta.precio.cantidad) {
                        nuevoPrecio = productoRespuesta.precio.cantidad;
                    }
                    if (producto.precio_sin_iva !== productoRespuesta.precio_sin_iva.cantidad) {
                        nuevoPrecioSinIva = productoRespuesta.precio_sin_iva.cantidad;
                    }

                    if (nuevoDescuento !== producto.descuento || nuevoPrecio !== producto.precio || nuevoPrecioSinIva !== producto.precio_sin_iva) {
                        await updatePrice(producto.id_producto, nuevoDescuento, nuevoPrecio, nuevoPrecioSinIva);
                    }
                }
            }
        } catch (error) {
            console.error('Error consultando la disponibilidad:', error);
        }
    };

    const updatePrice = async (productId, newDiscount, newPrice, newPriceWithoutTax) => {
        if (!token) {
            let productFound = cartLocal.find(productCart => productCart.id_producto === productId);

            if (productFound) {
                let updatedCart;

                // Comprueba si el producto pertenece a un combo
                if (productFound.id_combo) {
                    // Crea un nuevo arreglo actualizando los precios y el descuento para todos los productos del mismo combo
                    updatedCart = cartLocal.map(productCart => {
                        if (productCart.id_combo === productFound.id_combo) {
                            return {
                                ...productCart,
                                descuento: parseFloat(newDiscount),
                                precio: { ...productCart.precio, cantidad: parseFloat(newPrice) },
                                precio_sin_iva: { ...productCart.precio_sin_iva, cantidad: parseFloat(newPriceWithoutTax) }
                            };
                        }
                        return productCart;
                    });
                } else {
                    // Crea un nuevo arreglo actualizando los precios y el descuento solo en el producto específico
                    updatedCart = cartLocal.map(productCart => {
                        if (productCart.id_producto === productId) {
                            return {
                                ...productCart,
                                descuento: parseFloat(newDiscount),
                                precio: { ...productCart.precio, cantidad: parseFloat(newPrice) },
                                precio_sin_iva: { ...productCart.precio_sin_iva, cantidad: parseFloat(newPriceWithoutTax) }
                            };
                        }
                        return productCart;
                    });
                }

                window.localStorage.setItem('cartLocal', JSON.stringify(updatedCart));
                setCartItems(updatedCart);
            }
            return;
        } else {
            // Si existe el token actualizar los productos que ya tiene guardados
            const auxCart = [...cartItems];
            let findProduct = auxCart.find(productCart => productCart.id_producto === productId);

            findProduct.descuento = parseFloat(newDiscount);
            findProduct.precio.cantidad = parseFloat(newPrice);
            findProduct.precio_sin_iva.cantidad = parseFloat(newPriceWithoutTax);

            try {
                // Actualizamos el estado del carrito
                setCartItems(auxCart);

                // Actualizamos el carrito externo
                const response = await axios.post(API_URL + '/updateProduct', findProduct);

            } catch (error) {
                console.error('Ha ocurrido un error');
            }
        }
    };


    useEffect(() => {
        if (cartItems && cartItems.length > 0) {
            const simplifiedCartItems = cartItems
                .filter(item => !item.id_combo) // Filtrar los productos que no tienen id_combo
                .map(item => ({
                    id_producto: item.id_producto,
                    sku: item.sku,
                    cantidad: item.cantidad,
                    titulo: item.titulo,
                    precio: item.precio.cantidad,
                    precio_sin_iva: item.precio_sin_iva.cantidad,
                    descuento: item.descuento,
                }));

            if (simplifiedCartItems.length > 0) {
                // console.log('Carrito con datos para inventario ', simplifiedCartItems);
                consultarDisponibilidad(simplifiedCartItems);
            } else {
                // console.log('No hay productos sin combo en el carrito');
            }
        } else {
            // console.log('Carrito sin datos :( por qué no compran');
        }
    }, [cartItems]);



    useEffect(() => {

        if (!token) {
            setCartItems(cartLocal)
            return
        } else {
            fetchCartData();
            //fetchCartData();
            getFavoritos();
        }


    }, []);


    const fetchCartData = async () => {
        setLoading(true); // Establece loading en true al inicio de la carga de datos

        // Verifica si hay token o utiliza otro método de autenticación
        if (!token) {
            // No hay un token
            return;
        }

        try {
            const body = JSON.stringify({ "id_usuario": token['$udi09345'] });
            // Reemplaza la URL por la ruta de tu API y agrega los headers necesarios
            const response = await fetch(API_URL + '/getCartByUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body
            })



            if (response.status === 200) {
                const cartData = await response.json();
                setCartItems(cartData); // Actualiza tu estado con los datos del carrito\

                // console.log('el catotr es*/*/*/*/*/*', cartData)

                // Aquí también puedes actualizar el total y la cantidad
            } else {
                console.error("Error al obtener los datos del carrito:", response);
            }
            setLoading(false); // Asegúrate de establecer loading en false independientemente del resultado

        } catch (error) {
            console.error("Error al realizar la petición a la API:", error);
        }
    };

    const gopay = () => {
        goToPay(totalPrecio, token ? token['$email'] : 'No registrado')
        if (token) {
            fetchCarts(token)
        }
        navigate('/Pagos');
    }
    // En ProductCartList
    useEffect(() => {
        // Esto imprimirá en consola cada vez que cartItems cambie
    }, [cartItems]);


    useEffect(() => {
        getProductsSeason();
    }, []); // El arreglo de dependencias vacío asegura que esto solo se ejecute una vez


    const getProductsSeason = async () => {
        const payload = {
            "categoria": "ofertas_temporada"
        }
        try {
            const response = await axios.post(API_URL + '/getProductsByCategorySell', payload, { timeout: 15000 });
            // console.log('ladata est', response.data)
            setListProductsSeason(response.data);
        } catch (error) {
            console.error("Error al obtener los productos season:", error);
            if (error.code === 'ECONNABORTED') {
                console.error('Request timed out get products');
            }
            // Manejo adicional del error, como establecer un estado de error
        }
    }

    const updateQuantity = async (productId, newQuantity, idCombo) => {

        //Se actualiza el producto solo si no está el token
        if (!token) {
            let productFound = cartLocal.find(productCart => productCart.id_producto === productId);

            if (productFound) {
                let updatedCart;

                // Comprueba si el producto pertenece a un combo
                if (productFound.id_combo) {
                    // Crea un nuevo arreglo actualizando la cantidad para todos los productos del mismo combo
                    updatedCart = cartLocal.map(productCart => {
                        if (productCart.id_combo === productFound.id_combo) {
                            return { ...productCart, cantidad: parseInt(newQuantity) };
                        }
                        return productCart;
                    });
                } else {
                    // Crea un nuevo arreglo actualizando la cantidad solo en el producto específico
                    updatedCart = cartLocal.map(productCart => {
                        if (productCart.id_producto === productId) {
                            return { ...productCart, cantidad: parseInt(newQuantity) };
                        }
                        return productCart;
                    });
                }

                window.localStorage.setItem('cartLocal', JSON.stringify(updatedCart));
                setCartItems(updatedCart);
            }
            return;
        }

        if (idCombo) {
            const auxCart = [...cartItems];
            // Actualizar todos los productos del mismo combo en el carrito
            const updatedCartItems = auxCart.map(item => {
                if (item.id_combo === idCombo) {
                    return { ...item, cantidad: parseInt(newQuantity, 10) };
                }
                return item;
            });

            try {
                // Actualizamos el estado del carrito
                setCartItems(updatedCartItems);
                window.localStorage.setItem('cartLocal', JSON.stringify(updatedCartItems));

                // Actualizar cada producto del combo en la base de datos
                const updatePromises = updatedCartItems.filter(item => item.id_combo === idCombo).map(item => {
                    return axios.post(API_URL + '/updateProduct', item);
                });

                // Esperar a que todas las peticiones se completen
                const results = await Promise.all(updatePromises);
                const allSuccessful = results.every(result => result.status === 200 && result.data.success);

                if (!allSuccessful) {
                    throw new Error('Algunas actualizaciones de cantidad fallaron');
                }

                // console.log('Todas las cantidades se actualizaron correctamente en la base de datos.');
            } catch (error) {
                console.error('Error al actualizar las cantidades en el servidor:', error);
                // Considerar revertir los cambios en el estado local si la actualización en el servidor falla
            }

        } else {
            //Si existe el token actualizar los productos que ya tiene guardados
            const auxCart = [...cartItems]
            let findProduct = auxCart.find(productCart => productCart.id_producto === productId)

            findProduct.cantidad = parseInt(newQuantity)

            try {
                // //Actualizamos el estado del carrito
                setCartItems(auxCart)

                //        console.log('el carrtio es *******',findProduct)


                //Actualizamos el carrito externo
                const response = await axios.post(API_URL + '/updateProduct', findProduct)

            } catch (error) {
                console.error('Ha ocurrido un error')
            }
        }
    };

    //cargar lista de favoritos 
    const [favoritos, setFavoritos] = useState([]);

    const [cargandoFavoritos, setCargandoFavoritos] = useState(true);

    const getFavoritos = async () => {
        const data = { id_usuario: token['$udi09345'] };

        try {
            let response = await axios.post(API_URL + "/getProductListFavorites", data, { headers: { 'Authorization': token['$tk3m09s'] }, timeout: 10000 });
            const listaFavoritos = response.data.success.lista_favoritos;

            if (listaFavoritos && Object.keys(listaFavoritos).length > 0) {
                // Transformar el objeto listaFavoritos en un array de objetos con id y contenido
                const favoritosArray = Object.entries(listaFavoritos).map(([id, contenido]) => ({
                    id: parseInt(id), // Convertir el id a número si es necesario
                    ...contenido
                }));
                setFavoritos(favoritosArray);
            } else {
                setFavoritos([]);
            }
            setCargandoFavoritos(false);
        } catch (error) {
            console.error('Error al obtener los favoritos: ' + error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }
            setCargandoFavoritos(false);
        }
    };

    //elimanr de favoritos 
    const onDeleteProducto = async (id, idProducto) => {

    }
    const removeItem = async (productId, idCombo) => {
        if (!token) {
            // No hay token, manejo local
            let newArray = cartLocal.filter(p =>
                p.id_producto !== productId &&
                p.id_producto_padre !== productId &&
                (p.id_combo ? p.id_combo !== idCombo : true)
            );

            window.localStorage.setItem('cartLocal', JSON.stringify(newArray));
            setCartItems(newArray);
            return;
        }

        // Hay token, manejo con backend
        // Filtrar productos para eliminar por id_producto, id_producto_padre, y por id_combo
        const itemsToDelete = cartItems.filter(item =>
            item.id_producto === productId ||
            item.id_producto_padre === productId ||
            item.id_combo === idCombo
        );

        // Crear las promesas de eliminación para cada producto filtrado
        const deletePromises = itemsToDelete.map(item => {
            const payload = {
                "id_usuario": token['$udi09345'],
                "id_producto": item.id_producto,
            };
            return axios.post(API_URL + '/deleteProductCart', payload, { timeout: 10000 });
        });

        try {
            // Espera la resolución de todas las promesas de eliminación
            const results = await Promise.all(deletePromises);
            // Comprobar si todas las eliminaciones fueron exitosas
            if (results.every(result => result.data && result.data.success)) {
                // Eliminación exitosa, filtrar el carrito para remover visualmente los productos
                let newCart = carts.filter(product =>
                    product.id_producto !== productId &&
                    product.id_producto_padre !== productId &&
                    (idCombo ? product.id_combo !== idCombo : true)
                );
                deleteCart(newCart);  // Actualizar el estado y la UI
                fetchCartData();  // Refrescar los datos del carrito
            } else {
                console.error('Algunas eliminaciones fallaron');
            }
        } catch (error) {
            console.error('Error al eliminar los productos:', error);
        }

        // Actualizar el estado del carrito local para reflejar los cambios
        setCartItems(currentItems => currentItems.filter(item =>
            item.id_producto !== productId &&
            item.id_producto_padre !== productId &&
            (idCombo ? item.id_combo !== idCombo : true)
        ));
    };



    useEffect(() => {
        let total = 0;
        let cantidad = 0;

        cartItems?.forEach(item => {
            total += parseFloat(item.precio.cantidad) * (item.cantidad || 1);
            cantidad += item.cantidad || 1;
        });

        setTotalPrecio(total);
        setCantidadProductos(cantidad);
    }, [carts, cartItems]);


    const productContainerRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(false);

    const checkScrollPosition = () => {
        if (productContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = productContainerRef.current;
            const atBottom = scrollTop + clientHeight >= scrollHeight;
            setIsAtBottom(atBottom);
        }
    };

    useEffect(() => {
        checkScrollPosition();
    }, [cartItems, totalPrecio]);
    if (loading) {
        return <SkeletonLoader />; // Muestra el loader mientras los datos están cargando
    }

    if (cartItems?.length === 0 || cartItems == null) {

        // if (productos.length === 0) {
        return (
            <div className="flex flex-col mt-2 px-1 justify-center ">
                <p className="text-center mt-10 font-robotoch font-semibold lg:text-3xl">
                    Su carrito de compras HANTEC está vacío.
                </p>

                {!token &&
                    <div className='flex flex-col items-center'>
                        <Link
                            to={'/login'}
                            className="mt-4 px-4 py-2 w-40 border rounded text-black bg-[#e9bf56]">
                            INICIAR SESIÓN
                        </Link>
                        <Link
                            to={'/registermodule'}
                            className="mt-4 px-4 py-2 w-40 border rounded text-white bg-[#757575]">
                            REGISTRARSE
                        </Link>
                    </div>
                }

                {
                    favoritos && favoritos.length > 0 && (
                        <div className='lg:w-4/5 mx-auto'>
                            <FavoritosLista favoritos={favoritos} onDeleteProducto={onDeleteProducto} />
                        </div>
                    )
                }


                <div className='mt-5'>
                    {/* Aquí es donde se pasan los productos que podrían interesar al usuario */}

                    <ListaProductos titulo="PODRÍA " alldate='Ver todos' subtitulo='INTERESARTE' productos={listProductsSeason} categoria='/CategoriasEspeciales' cat='ofertas_temporada' />

                </div>
            </div>
        );
    } else {



        return (


            <div className='lg:w-4/5 lg:mx-auto  lg:mt-5'>
                <div className='pl-4 mt-1 lg:flex '>
                    <h2 className='text-[#030303] font-black text-xl lg:text-2xl'>CARRITO</h2>
                    <h2 className='text-[#1e3d8b] font-semibold text-lg lg:text-2xl lg:ml-2'>DE COMPRAS</h2>
                </div>

                <div className='lg:flex  lg:mt-4  justify-around   	'>

                    <div className='lg:w-1/2'>

                        <div
                            className="overflow-auto h-96  barra1  "
                            ref={productContainerRef}
                            onScroll={checkScrollPosition}
                        >
                            {/* El contenedor que permite desplazamiento vertical con un alto máximo */}
                            {cartItems?.map((producto, index) => (
                                <CardCarrito
                                    key={index}
                                    producto={producto}
                                    removeItem={removeItem}
                                    updateQuantity={updateQuantity} // Pasas esta función a cada CardCarrito
                                />
                            ))}

                        </div>


                        <div className='flex justify-center mb-3  lg:hidden '>


                            {!isAtBottom && (
                                <button


                                    name='btn-arrow-down'
                                    id='btn-arrow-down'
                                    className=' bg-gray-500 bg-opacity-15  w-10 rounded-full h-10  text-lg	p-2 text-black'
                                    onClick={() => productContainerRef.current.scrollTop = productContainerRef.current.scrollHeight}
                                >
                                    <i className="fas fa-arrow-down"></i>
                                </button>
                            )}
                        </div>

                    </div>

                    <div className='lg:ml-4 h-40 lg:pt-0  lg:w-1/2 flex flex-col justify-start  lg:justify-start lg:border-l-2  border-gray-200'>
                        {cartItems?.length > 0 && (
                            <DatosTotal
                                totalPrecio={totalPrecio}
                                cantidadProductos={cantidadProductos}
                                plazos={plazos}
                                setPlazos={setPlazos} // Pasar el estado y la función para actualizarlo
                            />
                        )}


                        {!bloqueo && (
                            <div className='mt-10 flex justify-center'>
                                <button

                                    name='btn-gopay20'
                                    id='btn-gopay20'
                                    onClick={gopay} className='bg-[#e9bf56] px-8 py-1 font-semibold rounded'>CONTINUAR Y COMPRAR</button>
                            </div>
                        )}


                    </div>


                </div>


                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                    style={{ top: '50%', transform: 'translateY(-50%)' }}

                />

            </div>
        );
    }
}

export default ProductCartList;
