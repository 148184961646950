import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { customStyles } from '../../constants/constants';

const ModalOcurrePro = ({ closeocurre, onSelectIdOffice, cotizarDirecto }) => {
    const [listaOcurre, setListaOcurre] = useState([]);
    const [opcionSeleccionadaOcurre, setOpcionSeleccionadaOcurre] = useState(null);

    useEffect(() => {
        const ocurresucursales = async () => {
            try {
                const response = await axios.get('https://api.castores.com.mx/catalogs/ocurreOffices');
                const jsonData = response.data.data.packaging;
                setListaOcurre(jsonData.map(item => ({
                    label: item.plaza,
                    value: item.idoficina
                })));
            } catch (error) {
                console.error("Error al obtener los productos:", error);
            }
        };

        ocurresucursales();
    }, []);

    const handleChangeocurre = (opcionSeleccionada) => {
        setOpcionSeleccionadaOcurre(opcionSeleccionada);
        // console.log('opcionSeleccionada',opcionSeleccionada)
    };

    const handleSave = () => {
        // console.log('madare',opcionSeleccionadaOcurre)
        if (opcionSeleccionadaOcurre) {
            // Llamar a la función del padre con los datos
            cotizarDirecto({
                sucursalOcurreSeleccionada: opcionSeleccionadaOcurre
            });
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-80 lg:w-1/3 shadow-lg rounded-md bg-white">
                <div className='absolute top-0 right-0 -mt-4 -mr-4'>
                    <button
                        name='btn-closeocurre2'
                        id='btn-closeocurre2'
                        onClick={closeocurre} className="rounded-full bg-red-600 text-white px-2 py-0.5 shadow-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 text-gray-900 font-black">Selecciona la sucursal</h3>
                </div>
                <div>
                    <p className='mt-4 text-xs'>Sucursales:</p>
                    <Select
                        className='mt-1'
                        options={listaOcurre}
                        onChange={handleChangeocurre}
                        value={opcionSeleccionadaOcurre}
                        isSearchable={true}
                        placeholder="Selecciona una sucursal"
                        styles={customStyles}

                    />

                    <div className='mt-5 flex justify-center'>
                        <button
                            name='btn-saveocurre2'
                            id='btn-saveocurre2'
                            className={`px-8 py-2 rounded transition-all duration-300 ${opcionSeleccionadaOcurre ? 'bg-[#e9bf56] hover:brightness-90 hover:text-white' : 'bg-gray-300 opacity-50 cursor-not-allowed'}`}
                            disabled={!opcionSeleccionadaOcurre} // El botón está deshabilitado si no se ha seleccionado una sucursal
                            onClick={handleSave}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalOcurrePro;
