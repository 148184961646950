import { useState } from 'react';

function SubmitButton({ buttonText, handleClic, disabledInput = false, color, textColor, hovercolor, hovertextColor }) {
    // Define estilos en línea para el botón
    const buttonStyle = {
        backgroundColor: color, // Usar color de fondo dinámico
        color: textColor, // Usar color de texto dinámico
    };

    // Estilos para hover usando React state para aplicarlos solo cuando es necesario
    const [hover, setHover] = useState(false);

    // Funciones para manejar los eventos de mouse
    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    // Determinar estilos de hover dinámicamente
    const currentStyle = hover ? { ...buttonStyle, backgroundColor: hovercolor, color: hovertextColor } : buttonStyle;

    return (
        <div className='conbtn'>
            <button
                name={buttonText}
                id={buttonText}
                style={currentStyle}
                className="rounded w-1/2 py-2 lg:py-3"
                type="submit"
                onClick={handleClic}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                disabled={disabledInput}
            >
                {buttonText}
            </button>
        </div>
    );
}

export default SubmitButton;
