import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { API_URL } from '../../constants/constants'



function BannerEditModal({ isOpen, onClose, banner, onSave }) {
    // console.log('el banner es ', banner)
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionUno, setSelectedOptionUno] = useState('');
    const [products, setProducts] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [combos, setCombos] = useState([]);

    const etiquetasOptions = [
        { label: "Catálogo", value: "/allProducts" },
        { label: "Llantera", value: "/etiquetas/Llantera" },
        { label: "Hojalatería y Pintura", value: "/etiquetas/Hojalatería%20y%20Pintura" },
        { label: "Taller Mecánico", value: "/etiquetas/Taller%20Mecánico" },
        { label: "Industria", value: "/etiquetas/Industria" }
    ];


    const [categoriasList, setCategoriasList] = useState([]);
    // Define las opciones para el componente Select

    // Efecto para restablecer el estado cuando el modal se abre o el banner cambia
    useEffect(() => {
        if (isOpen) {
            setPreviewUrl(banner.url || '');
            setSelectedFile(null);
            setIsActive(banner.active || false);

            if (banner.navigateTo) {
                const isCategory = banner.navigateTo.startsWith('/Categorias/');
                setSelectedOptionUno(isCategory ? 'categoria' : 'producto');
                const label = isCategory ? banner.navigateTo.replace('/Categorias/', '') : banner.navigateTo.replace('/Product/', '');
                setSelectedOption({ label: label, value: banner.navigateTo });
            } else {
                setSelectedOptionUno('');
                setSelectedOption(null);
            }

            getCategories();
            getProducts();
            getCombos();
        }
    }, [isOpen, banner]);

    const getCategories = async () => {
        try {
            const response = await axios.get(API_URL + '/getAllCategories', {
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 10000
            });

            const activeCategories = response.data.filter(category => category.activo === true);

            // Mapeando a un nuevo arreglo que solo contenga 'nombre' e 'id_categoria'
            const categoriesOptions = activeCategories.map(category => ({
                label: category.nombre, // Usando 'nombre' como la etiqueta
                value: `/Categorias/${category.nombre}` // Concatenamos '/Categorias/' con el 'nombre' para formar el valor
            }));

            //  console.log('Categorías activas mapeadas:', categoriesOptions);
            setCategoriasList(categoriesOptions); // Este arreglo ahora puede ser usado donde necesites

        } catch (error) {
            // console.error("Error al obtener las categorías:", error);
            // Manejo adicional del error, como establecer un estado de error
            if (error.code === 'ECONNABORTED') {
                console.log('Tiempo de espera agotado en categorías');
            }

            return []; // Devuelve un arreglo vacío en caso de error
        }
    }


    //nueva forma de enrutamietno
    const formatTitleForUrl = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };


    const getProducts = async () => {
        try {
            const response = await fetch(`${API_URL}/getProductsPagination`);
            const data = await response.json();

            // Mapeando los datos para extraer solo los id_odoo de cada producto
            const filteredProducts = data.filter(product => product.id_odoo !== undefined && product.id_odoo !== null);

            //  console.log('con id odo', filteredProducts);
            const productsOptions = filteredProducts.map(product => ({
                label: product.titulo, // Usando 'nombre' como la etiqueta
                value: `/product/${formatTitleForUrl(product.titulo)}/${product.id_producto}` // Concatenamos '/Categorias/' con el 'nombre' para formar el valor
            }));



            setProducts(productsOptions); // Guarda los IDs en el estado
        } catch (error) {
            console.error("Error al obtener los productos:", error);
        }
    };

    const getCombos = async () => {
        try {
            const response = await fetch(`${API_URL}/getCombos`);
            const data = await response.json();
            // console.log('la repsues es COMBOO ',data.success)
            const filteredProducts = data.success
            const comboOptions = filteredProducts.map(combo => ({
                label: combo.nombre_combo, // Usando 'nombre' como la etiqueta
                value: `/combonum/${combo.id_combo}` // Concatenamos '/Categorias/' con el 'nombre' para formar el valor
            }));

            // Mapeando los datos para extraer solo los id_odoo de cada producto

            setCombos(comboOptions); // Guarda los IDs en el estado

        } catch (error) {
            console.error("Error al obtener los productos:", error);
        }
    };


    const onFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            setSelectedFile(file);
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
        }
    };

    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
        }
    };

    // Limpieza al cerrar el modal
    const handleClose = () => {
        onClose();
        // Podrías restablecer el estado aquí si lo prefieres, pero el useEffect ya lo maneja
    };


    const handleChangeUno = (event) => {
        setSelectedOptionUno(event.target.value);
        setSelectedOption(null); // Resetea la selección al cambiar entre Producto/Categoría
        // console.log(`Opción seleccionada:`, event.target.value);
    };

    const selectOptions = selectedOptionUno === 'producto' ? products :
        (selectedOptionUno === 'categoria' ? categoriasList :
            (selectedOptionUno === 'combo' ? combos :
                (selectedOptionUno === 'tag' ? etiquetasOptions : [])));


    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        // console.log(`Opción seleccionada:`, selectedOption);
    };





    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
            <div className="bg-white w-1/2 p-4 rounded-lg shadow-lg">
                <div className='flex justify-between'>
                    <h2 className="font-bold text-lg">Editar Banner  N°{banner.id_banner} </h2>

                    <button name='btnicon1' id='btnicon1' onClick={onClose} className='text-xl hover:text-red-500'><i className="fas fa-times"></i></button>
                </div>
                <form className='mt-10'>

                    <div className="mb-4 ">


                        <div className="mt-4 bg-gray-100 flex justify-center items-center">
                            {previewUrl ? (
                                <img width="300" height="200"
                                    src={previewUrl} alt="Vista previa" className="max-h-80 w-auto rounded" />
                            ) : null}                        </div>

                        <input type="file" onChange={onFileChange} className="mt-1 block w-full hidden" id="bannerImage" />
                        <div
                            className="mt-1 block w-full border-2 border-dashed border-gray-300 p-4 text-center cursor-pointer"
                            onClick={() => document.getElementById('bannerImage').click()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={onFileDrop}
                        >
                            Arrastra y suelta una imagen aquí o haz clic para seleccionar
                        </div>
                        <div className='mt-2 w-4/5 ml-10'>
                            {'active' in banner ?
                                <p className="text-sm font-light text-gray-600">
                                    <span className="font-semibold">*</span>   Recomendamos utilizar imágenes con una resolución de <span className="font-semibold">1170x399 píxeles</span> para asegurar la mejor calidad visual en el banner.
                                </p>
                                :
                                <p className="text-sm font-light text-gray-600">
                                    <span className="font-semibold">*</span>     Recomendamos utilizar imágenes con una resolución de <span className="font-semibold">5158x2577 píxeles</span> para asegurar la mejor calidad visual en el banner.
                                </p>
                            }

                        </div>

                        <div className='mt-4 flex px-2 justify-around'>


                            <div className='w-1/3'>
                                <p>Tipo de enlace</p>

                                <select value={selectedOptionUno} className='p-2 border border-gray-300 rounded' onChange={handleChangeUno}>

                                    <option value="">Selecciona una opción</option>
                                    <option value="producto">Producto</option>
                                    <option value="tag">Etiquetas</option>
                                    <option value="categoria">Categoría</option>
                                    <option value="combo">Combo</option>


                                </select>

                            </div>


                            <div className='w-1/2'>
                                <p>Destino de enalce</p>
                                <Select
                                    value={selectedOption}
                                    onChange={handleChange}
                                    options={selectOptions}
                                    isClearable
                                    isSearchable
                                    placeholder="Selecciona una opción"
                                    isDisabled={!selectedOptionUno} // Se deshabilita si selectedOptionUno es una cadena vacía
                                    menuPlacement="top" // Esto hace que el menú de opciones se despliegue hacia arriba
                                />

                            </div>


                        </div>

                        {'active' in banner && (
                            <div className='mt-4 px-12'>
                                <p>Estado del Banner</p>
                                <label
                                    htmlFor="AcceptConditions"
                                    className={`relative inline-block h-8 w-14 cursor-pointer rounded-full transition duration-300 ease-in-out ${isActive ? "bg-green-500" : "bg-red-500"
                                        }`}
                                >
                                    <input
                                        type="checkbox"
                                        id="AcceptConditions"
                                        className="sr-only peer"
                                        checked={isActive}
                                        onChange={(e) => setIsActive(e.target.checked)}
                                    />
                                    <span className={`absolute inset-y-0 left-0 m-1 w-6 h-6 rounded-full bg-white transition-transform duration-300 ease-in-out ${isActive ? "translate-x-6" : ""
                                        }`}></span>
                                </label>
                            </div>
                        )}



                    </div>



                    <div className="flex justify-center space-x-2">
                        <button type="button" onClick={onClose} name='btncancel1' id='btncancel1' className="py-2 px-4 bg-red-500 text-white rounded hover:bg-red-800">Cancelar</button>

                        <button
                            type="button"
                            name='btnsave2' id='btnsave2'
                            disabled={!selectedFile && !previewUrl} // Permitir guardar si hay un archivo seleccionado o una URL de vista previa
                            onClick={() => onSave(selectedFile, banner.id_banner, banner.description, selectedOption.value, isActive, previewUrl, banner.titulo)}
                            className={`py-2 px-4 text-white rounded  ${selectedFile || previewUrl ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-500'}`}
                        >
                            Guardar
                        </button>

                    </div>

                </form>
            </div>
        </div>
    );
}

export default BannerEditModal;
