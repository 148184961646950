import React from 'react';
import loadingGif from '../../../assets/gifs/carrito-de-compras.gif'; // Asegúrate de que la ruta al GIF sea correcta

const CargaModal = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full bg-white flex justify-center items-center flex-col z-50">
            <img width="300" height="200"
 src={loadingGif} alt="Cargando..." className="w-48 h-48" /> {/* Ajusta el tamaño según sea necesario */}
            <p className="mt-4 text-center font-semibold text-lg lg:text-2xl ">Preparando tu carrito para la compra</p>
        </div>
    );
}

export default CargaModal;
