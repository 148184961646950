import React from 'react';
import HeaderEspecial from '../../componentes/Headers/HeadeEpecial';
import { useLocation } from 'react-router-dom';
import imgSantander from '../../assets/Santander_Logo.webp'
import imgBanorte from '../../assets/BanorteLogo.png'
import imgScotiabank from '../../assets/Logo_Scotiabank.webp'
import imgInbursa from '../../assets/Logo_de_Inbursa.webp'
import imgHsbc from '../../assets/HSBC_LOGO.webp'
import imgBcoAzteca from '../../assets/Logo_de_Banco_Azteca.webp'



function Listametodospagotxt({ mesamodal }) {
    const location = useLocation();
    // Verificar si 'msi' fue definido en el estado o si 'mesamodal' es verdadero
    const msiExists = location.state?.hasOwnProperty('msi') || mesamodal;

    const msi = location.state?.msi ?? mesamodal ?? 12;


    const metodospago = [

        {
            tipometodo: "Tarjetas de crédito",
            acreditacion: "Acreditación instantánea.",
            cuota: msi,
            imagen: [

                imgSantander,
                imgBanorte,
                imgScotiabank,
                imgInbursa,
                imgHsbc,
                imgBcoAzteca


            ]
        },
        {
            tipometodo: "Tarjetas de débito",
            acreditacion: "Acreditación instantánea.",
            cuota: 0,
            imagen: [
                'https://http2.mlstatic.com/storage/logos-api-admin/157dce60-571b-11e8-95d8-631c1a9a92a9-m.svg',
                'https://http2.mlstatic.com/storage/logos-api-admin/312238e0-571b-11e8-823a-758d95db88db-m.svg'
            ]
        },
        {
            tipometodo: "Efectivo",
            acreditacion: "Acreditación instantánea (Máximo $10,000.00 MXN).",
            cuota: 0,
            imagen: ['https://http2.mlstatic.com/storage/logos-api-admin/91b830e0-f39b-11eb-9984-b7076edb0bb7-m.svg']
        },
        {
            tipometodo: "Depósito y transferencia bancaria",
            acreditacion: "Acreditación en menos de 1 hora.",
            cuota: 0,
            imagen: [
            ]
        }
    ];

    return (
        <div  >
            <div className='lg:hidden'>
                <HeaderEspecial />
            </div>

            <div className='mt-5 lg:mt-1 text-center w-96 mx-auto border-b-2 border-gray-300 lg:w-full	pb-2'>
                <h2 className='font-black uppercase font-robotoch text-lg'>Medios de pago para este producto

                </h2>
            </div>

            <div className="mt-8 lg:mt-0">
                {metodospago.map((metodopago, index) => (
                    <div
                        key={index}
                        className='my-4 w-11/12 px-2 mx-auto border-b-2 border-gray-300 pb-2 lg:pb-0'
                    >

                        <h1 className=' font-robotoch text-base  '>{metodopago.tipometodo}</h1>
                        <p className='text-gray-500 text-sm mt-1'>{metodopago.acreditacion}</p>

                        {metodopago.cuota !== 0 ? (

                            <div>
                                {
                                    // Condición para mostrar "cuotas sin intereses" o "cuotas fijas"
                                    msiExists ?
                                        <p className='mt-2 lg:mt-0 text-sm text-[#007bfc]'>Hasta {msi} cuotas sin intereses con</p> :
                                        <p className='mt-2 lg:mt-0 text-sm text-[#007bfc]'>Hasta 12 cuotas fijas con</p>
                                }

                            </div>

                        ) : null}
                        <div className="flex flex-wrap justify-start mt-4 lg:mt-1">
                            {metodopago.imagen.map((imgUrl, imgIndex) => (
                                <div key={imgIndex} className="w-1/3 p-1 pb-2 flex justify-center items-center">
                                    <img width="300" height="200"

                                        src={imgUrl}
                                        alt={`Imagen de método de pago opción ${imgIndex + 1}`}
                                        className="h-8 w-24 object-contain " // Mantiene el tamaño fijo de las imágenes
                                    />
                                </div>
                            ))}
                        </div>


                    </div>


                ))}
            </div>

        </div>
    );
}

export default Listametodospagotxt;
