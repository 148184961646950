import React, { useState } from 'react'; // Importar useState
import InputField from '../../inputs/InputField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../FormHeader';
import DualButton from '../DualButton';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../constants/constants';


function Forgotpass() {

    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
    }

    const handleCancel = () => {
    //    console.log("Cancelado");
        navigate(-1);
        // Lógica para manejar "Cancelar"
    };

    const handleConfirm = async () => {

        if (email == '') {
            
            toastError('Es necesario ingresar un correo')
            return
        }
        const body = JSON.stringify({ correo: email});
        try {
            const response = await fetch(API_URL+'/recuperarPass', {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },

                body: body
            });
            if (!response.ok) {
                // alert('Credenciales incorrectas');
                // throw new Error(`Error: ${response.status}`);
                
                toastError('Revisa si tu correo esta correctamente escrito.');
            }else{
                navigate(`/validatemodule/${email}`);
            }
            const data = await response.json();
            // Manejar la respuesta. Por ejemplo, guardar el token de sesión, etc.
          //  console.log(data);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            toastError('Revisa si tu correo esta correctamente escrito.');
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
 
    return (

        <div className="login-list h-96 mt-20">

            <FormHeader
                title="Recuperar Contraseña"
                description="Ingresa tu correo para recibir un código de verificación "
            />
            <InputField label="Ingresa tu correo" type="text" placeholder="Email" handleChange={handleEmailChange} />

            <DualButton
                leftButtonText="Cancelar"
                rightButtonText="Solicitar Código"
                onLeftButtonClick={handleCancel}
                onRightButtonClick={handleConfirm}
            />
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            />
        </div>

    );
}

export default Forgotpass;
