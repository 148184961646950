import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants/constants';
import PaginaDos from '../../componentes/pagos/PaginaDos';


const PasoFacturacion = ({ datosPerfil, onEnviarDatos }) => {

    const [datosFacturacion, setDatosFacturacion] = useState(false);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
    const [mostrarPaginaDos, setMostrarPaginaDos] = useState(false);
    const [dualButons, setDualButons] = useState(false);

    useEffect(() => {
        dataInvoiceById();

        // Auto-select and send data if the condition is met
        if (datosPerfil['$r07sad'] === 'Tienda') {
            setOpcionSeleccionada('requiereFactura');
            onEnviarDatos({ requiereFactura: true }, datosFacturacion); // Send data back to parent
        }
    }, [datosPerfil]);

    const dataInvoiceById = async () => {
        const formDataSat = {
            user_id: datosPerfil['$udi09345']
        };
        try {
            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                },
                timeout: 10000
            };

            const response = await axios.post(API_URL + "/getDataInvoiceById", formDataSat, config);
            if (response.data.success || response.data.success.length > 0) {
                // console.log('Los datos de facturación son ----', response.data.success);
                setDatosFacturacion(response.data.success);
            } else {
                // Si no hay datos, mostrar directamente PaginaDos
                setMostrarPaginaDos(true);
                setDualButons(true)

            }
        } catch (error) {
            console.error("Error al obtener los datos: ", error);
            setMostrarPaginaDos(true);
            setDualButons(true)

        }
    };

    const handleNoRequieroFactura = () => {
        // console.log("El usuario indicó que no requiere factura.");
        onEnviarDatos({ requiereFactura: false });

        setMostrarPaginaDos(false);
    };

    const handleRequieroFactura = (formulario) => {
        // console.log("El usuario  requiere factura.", formulario);

        onEnviarDatos({ requiereFactura: true }, formulario);

        setMostrarPaginaDos(false);
    };

    const cerrarform = () => {
        //  console.log('se va a cerrar ')
        setMostrarPaginaDos(false);
        setOpcionSeleccionada('')
    };

    const handleSeleccion = (evento) => {
        const { value } = evento.target;
        setOpcionSeleccionada(value);

        if (value === 'editarDatos') {
            setMostrarPaginaDos(true);
        } else if (value === 'requiereFactura') {
            onEnviarDatos({ requiereFactura: true }, datosFacturacion);
            setMostrarPaginaDos(false);
        } else {
            onEnviarDatos({ requiereFactura: false });
            setMostrarPaginaDos(false);
        }
    };

    if (mostrarPaginaDos) {
        return <PaginaDos datosPerfilenv={datosPerfil} habilartedir={true} cancel2={cerrarform} dualbutons={dualButons} pasoapay={true} requierefactura={handleRequieroFactura} nofacturar={handleNoRequieroFactura} />;
    }

    return (
        <div className='paso-facturacion  lg:border-2 lg:border-gary-200  lg:p-2 mt-4 rounded lg:ml-5'>
            <div className='lg:grid lg:px-5'>
                <h1 className='text-center text-base text-[#1a2869] font-black mt-5  lg:text-[#007bfc]'>CONFIRMA TUS DATOS</h1>
                <h1 className='text-center text-base text-[#1a2869] font-black lg:mt-5 lg:ml-1 lg:text-[#007bfc]'>DE FACTURACIÓN</h1>
            </div>
            <div className="mt-5 flex flex-col justify-center ">
                {/* Renderiza los datos de facturación si existen */}
                {datosFacturacion && (
                    <div className='rounded p-3 mx-5 mt-1 border bg-[#f7f7f7] font-montserrat'>
                        {/* Aquí se muestra la información de facturación */}
                        <div className='flex justify-between text-sm mt-2 '>
                            <label className='flex items-center space-x-2'>
                                <input
                                    type="radio"
                                    name="facturacionOpcion"
                                    value="requiereFactura"
                                    checked={opcionSeleccionada === 'requiereFactura'}
                                    onChange={handleSeleccion}
                                />
                                <div>
                                    <div className='flex justify-between text-sm px-1 mt-1'>
                                        <p className='text-[#007bfc] text-lg font-black'> {datosFacturacion.name}</p>
                                    </div>
                                    <div className='flex  text-sm px-1  mt-'>
                                        <p className='mr-1 font-black'>RFC:</p>
                                        <p> {datosFacturacion.vat}</p>
                                    </div>
                                    <div className='flex  text-sm px-1 mt-1 mb-5'>
                                        <p className='mr-1 font-black'>Tipo de compañía:</p>
                                        <p> {datosFacturacion.company_type === 'person' ? 'Persona Fisica' : 'Empresa'}</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                        {datosPerfil['$r07sad'] !== 'Tienda' && (
                            <div>
                                <label className='flex items-center space-x-2'>
                                    <input
                                        type="radio"
                                        name="facturacionOpcion"
                                        value="editarDatos"
                                        checked={opcionSeleccionada === 'editarDatos'}
                                        onChange={handleSeleccion}
                                    />
                                    <span className='text-[#474747]  font-bold hover:text-[#1e3d8b]'>Editar o ingresar datos de facturación</span>
                                </label>
                                <label className='flex items-center space-x-2 mt-4'>
                                    <input
                                        type="radio"
                                        name="facturacionOpcion"
                                        value="noRequiereFactura"
                                        checked={opcionSeleccionada === 'noRequiereFactura'}
                                        onChange={handleSeleccion}
                                    />
                                    <span className='text-[#474747]  font-bold hover:text-[#1e3d8b]'>Continuar sin factura</span>
                                </label>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PasoFacturacion;