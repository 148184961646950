import React, { useEffect, useState } from 'react';
import Modallistaproductos from '../../modales/Modallistaproductos';
import imge1cards from '../../../assets/imgcarddetail2.png'
import imge1cards2 from '../../../assets/metopagogroup.webp'
import DialogCombo from './DialogCombo';
import { productToCart } from '../../../utils/PixelMeta/metaPixelEvents';
import axios from 'axios';
import imgHotSale from '../../../assets/prueba1.png'

import { API_URL } from '../../../constants/constants';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ModalCodigoPostal from '../../modales/ModalCodigoPostal';
import ModalOcurre from '../../modales/ModalOcurre';

const ComboDetails = ({ dtoCombo, nproducts, total, enFavoritos, addfavorite, totalSinDescuento, dtoCastores, storedNeighborhood, storedCodigoPostal, goCotizar, goCotizarOcurre, getNameOfOcurre, gopay }) => {
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    //  console.log('datos del combo es ----', getNameOfOcurre)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const [datoCotizacion, setDatoCotizacion] = useState(null);
    //console.log('sjkdfkjshfkshfs', dtoCastores)
    let stock = 5

    // Estado para almacenar la opción seleccionada
    const [selectedOption, setSelectedOption] = useState(1);

    //metodos de pago en moviles 
    const gometodoslist = () => {
        navigate('/Listametodospagotxt');
    }

    //abrir metodoas de pago en modal escritorio--------------------------------------------------------------------
    const [modalMetodos, setModalMetodos] = useState(false); // Estado para mostrar/ocultar Alerta1

    const abrirmodalmetodos = () => {
        setModalMetodos(true);
    };

    const closeModaltMetodos = () => {
        setModalMetodos(false);

    };

    //cantidad selecionada 
    // Función para manejar el cambio en el select
    const handleChange = (event) => {
        setSelectedOption(Math.max(1, Math.min(10, Number(event.target.value))));
        // console.log('se sleeciono', event.target.value)
    };

    //agregar al carrito 
    const addtocar = () => {
        // console.log('clcikc en boton')
    }

    //compartir
    const compartirEnlace = () => {
        if (navigator.share) {
            // Web Share API disponible, usar para dispositivos móviles
            navigator.share({
                title: 'Compartir enlace',
                url: window.location.href,
            }).then(() => {
                // console.log('Enlace compartido con éxito');
            }).catch((error) => {
                console.error('Error al compartir', error);
            });
        } else {
            // Web Share API no disponible, copiar al portapapeles para escritorio
            navigator.clipboard.writeText(window.location.href)
                .then(() => {
                    alert("Enlace copiado al portapapeles!");
                })
                .catch(err => {
                    console.error("Error al copiar el enlace: ", err);
                });
        }
    };

    ///clis en agregar al carrito 
    const handleAddToCart = async (combo, buyNow) => {
        const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

        // Imprimir detalles de cada producto por separado
        for (const producto of combo.productos) {

            const tituloCompleto = `COMBO ${combo.nombre_combo} - ${producto.titulo}`;


            if (token) {
                // console.log('Con token')
                const dataProduct = {
                    "id_producto": "" + producto.id_producto,
                    "id_odoo": producto.id_odoo,
                    "cantidad": selectedOption,
                    "email": token['$email'],
                    "precio": {
                        "cantidad": parseFloat(producto.precio_descuento.cantidad),
                        "moneda": "MXN"
                    },
                    "precio_habitual": {
                        "cantidad": parseFloat(producto.precio.cantidad),
                        "moneda": "MXN"
                    },
                    "titulo": tituloCompleto,
                    "imagen": combo.imagen,
                    "sku": producto.sku,
                    //odoo 
                    "precio_sin_iva": {
                        "cantidad": parseFloat(producto.precio_sin_iva.cantidad),
                        "moneda": "MXN"

                    },
                    "descuento": producto.descuento,

                    "id_combo": combo.id_combo,
                    ...(dtoCombo.msi && { "msi": dtoCombo.msi }), // Solo incluye msi si dtoCombo.msi tiene un valor válido


                }

                try {


                    // console.log('entre al tri con ', dataProduct)
                    const response = await axios.post(API_URL + "/updateShoppingCart", dataProduct, { headers: { Authorization: token['$tk3m09s'] }, timeout: 10000 })


                    // console.log('la espuesa es ', response.data)
                    // -------- Pixel Meta ---------- //
                    productToCart(
                        producto?.id_producto,
                        producto?.titulo,
                        parseFloat(producto.precio.cantidad),
                        token && token['$email'] ? token['$email'] : null,
                        token && token['$name'] ? token['$name'] : null,
                    )
                } catch (error) {
                    console.log(error)
                    console.error('Ha ocurrido un error.')
                    if (error.code === 'ECONNABORTED') {
                        console.log('Request timed out');
                    }

                }

                //Termina


            } else {
                // console.log('no tiene token mano')
                const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : null

                if (cartLocal) {
                    // console.log('Con caririto en local storage')
                    const dataProduct = {
                        "id_producto": "" + producto.id_producto,
                        "id_odoo": producto.id_odoo,
                        "cantidad": selectedOption,
                        // "email": token['$email'],
                        "precio": {
                            "cantidad": parseFloat(producto.precio_descuento.cantidad),
                            "moneda": "MXN"
                        },
                        "precio_habitual": {
                            "cantidad": parseFloat(producto.precio.cantidad),
                            "moneda": "MXN"
                        },
                        "titulo": tituloCompleto,
                        "imagen": combo.imagen,
                        "sku": producto.sku,
                        //odoo 
                        "precio_sin_iva": {
                            "cantidad": parseFloat(producto.precio_sin_iva.cantidad),
                            "moneda": "MXN"

                        },
                        "descuento": producto.descuento,
                        "id_combo": combo.id_combo,
                        ...(dtoCombo.msi && { "msi": dtoCombo.msi }), // Solo incluye msi si dtoCombo.msi tiene un valor válido



                    }


                    //Buscamos si el producto esta en el carrito
                    let findProduct = cartLocal.find(productCart => productCart.id_producto == producto.id_producto)
                    // console.log(findProduct ? true : false)

                    if (findProduct) {
                        //Aumenta la cantidad en el producto
                        findProduct.cantidad = findProduct.cantidad + 1

                        localStorage.setItem('cartLocal', JSON.stringify(cartLocal))

                        productToCart(
                            producto?.id_producto,
                            producto?.titulo,
                            parseFloat(producto?.precio?.cantidad),
                            token && token['$email'] ? token['$email'] : null,
                            token && token['$name'] ? token['$name'] : null,
                        )
                    } else {
                        //Agrega el producto a la lista
                        let newCart = [...cartLocal, dataProduct]

                        productToCart(
                            producto?.id_producto,
                            producto?.titulo,
                            parseFloat(producto?.precio?.cantidad),
                            token && token['$email'] ? token['$email'] : null,
                            token && token['$name'] ? token['$name'] : null,
                        )
                        localStorage.setItem('cartLocal', JSON.stringify(newCart))
                    }


                } else {
                    // console.log('sin carrito en locla strorage.')

                    const dataProduct = [{
                        "id_producto": "" + producto.id_producto,
                        "id_odoo": producto.id_odoo,
                        "cantidad": selectedOption,
                        // "email": token['$email'],
                        "precio": {
                            "cantidad": parseFloat(producto.precio_descuento.cantidad),
                            "moneda": "MXN"
                        },
                        "precio_habitual": {
                            "cantidad": parseFloat(producto.precio.cantidad),
                            "moneda": "MXN"
                        },
                        "titulo": tituloCompleto,
                        "imagen": combo.imagen,
                        "sku": producto.sku,
                        //odoo 
                        "precio_sin_iva": {
                            "cantidad": parseFloat(producto.precio_sin_iva.cantidad),
                            "moneda": "MXN"

                        },
                        "descuento": producto.descuento,
                        "id_combo": combo.id_combo,
                        ...(dtoCombo.msi && { "msi": dtoCombo.msi }), // Solo incluye msi si dtoCombo.msi tiene un valor válido


                    }]

                    productToCart(
                        producto?.id_producto,
                        producto?.titulo,
                        parseFloat(producto?.precio?.cantidad),
                        token && token['$email'] ? token['$email'] : null,
                        token && token['$name'] ? token['$name'] : null,
                    )

                    localStorage.setItem('cartLocal', JSON.stringify(dataProduct))
                }
            }


        };



        if (buyNow) {
            // Lógica para manejar la compra inmediata
            gopay()
        } else {
            // Lógica para solo añadir al carrito
            // console.log('voya a abrir el modalr ')
            // Abre el modal después de procesar todos los productos
            setIsModalOpen(true);

        }


    };


    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [cuota, setCuota] = useState(null);



    const [btn1, setBtn1] = useState(false)
    const [btn2, setBtn2] = useState(false)
    const [btn3, setBtn3] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0);

        // Verificar y convertir 'msi' a entero si está disponible
        if (dtoCombo.msi) {
            const msiInt = parseInt(dtoCombo.msi, 10);
            if (!isNaN(msiInt) && msiInt !== 0 && total) {
                const cantidad = parseFloat(total);
                const cuotaCalculada = cantidad / msiInt;
                setCuota(cuotaCalculada.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
            } else {
                setCuota(null); // Limpiar el estado de la cuota si los datos no son válidos
            }
        }

        // Verificar el tipo de 'dtoCastores' y asignar a 'datoCotizacion'
        if (dtoCastores !== undefined && dtoCastores !== null) {
            if (typeof dtoCastores === 'number') {
                // console.log('dtoCastores es un número:', dtoCastores);
                setDatoCotizacion(`Envío: $${dtoCastores}`);
                setBtn1(true)
                setBtn2(false)
                setBtn3(false)



            } else if (typeof dtoCastores === 'string') {
                // console.log('dtoCastores es un string:', dtoCastores);
                setDatoCotizacion(dtoCastores);
                setBtn2(true)
                setBtn1(false)
                setBtn3(false)


            }
        } else {
            // console.log('dtoCastores es nulo o indefinido');
            setDatoCotizacion("");
            setBtn3(true)
            setBtn1(false)
            setBtn2(false)


        }

    }, [dtoCastores, dtoCombo.msi, total, getNameOfOcurre]);



    const [isModalEnvioOpen, setIsModalEnvioOpen] = useState(false);
    const [modalVisibleOcurre, setModalVisibleOcurre] = useState(false);
    const [idOffice, setIdOffice] = useState(null);


    //datos del codigo postal 
    const handleCodigoPostalChange = async (datos) => {

        // console.log('datosmodal parte uno', datos.codigoPostal, datos.colonia)

        setCodigoPostalYColonia(datos);
        goCotizar(datos.codigoPostal, datos.colonia);

        setIsModalEnvioOpen(false)


    };

    const toggleModal = () => {
        setIsModalEnvioOpen(!isModalEnvioOpen);
    };

    const [codigoPostalYColonia, setCodigoPostalYColonia] = useState({
        codigoPostal: storedCodigoPostal || null,
        colonia: storedNeighborhood || null
    });

    const handleOpenModal = () => {
        // console.log("Abrir modal");
        toggleModal()
        // Código para abrir modal
    };

    const handleOcurre = () => {
        // console.log("Realizar acción de Ocurre");
        abrirModalOCurre()
        // Código para la acción de Ocurre
    };

    const handleCotizar = () => {
        // console.log("Iniciar proceso de cotización");
        toggleModal()
        // Código para iniciar la cotización
    };

    //modal ocurre por si no se peude lelgar a su destino 
    const abrirModalOCurre = () => {
        setModalVisibleOcurre(true);
    };


    //modal ocurre
    const seleccionarIdOffice = (idOffice, nameOffice) => {
        // console.log('idOffice', idOffice, nameOffice)
        setIdOffice(idOffice);

        goCotizarOcurre(idOffice, nameOffice)
        setModalVisibleOcurre(false); // Cerrar el modal
    };

    const oterclose = () => {
        setModalVisibleOcurre(false); // Cerrar el modal
        toggleModal()

    }

    return (
        <div className="lg:w-1/3 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <DialogCombo isOpen={isModalOpen} onClose={closeModal} comboname={dtoCombo.nombre_combo} comboimg={dtoCombo.imagen} />

            {modalMetodos && <Modallistaproductos onClose={closeModaltMetodos} />}

            {modalVisibleOcurre && <ModalOcurre onClose={() => setModalVisibleOcurre(false)} onSelectIdOffice={seleccionarIdOffice} oterclose={oterclose} />}


            {isModalEnvioOpen &&
                <ModalCodigoPostal
                    codigoPostal={codigoPostalYColonia.codigoPostal}
                    setCodigoPostal={handleCodigoPostalChange}
                    onClose={toggleModal}
                />
            }

            <h2 className="text-sm title-font text-gray-500 tracking-widest hidden lg:block">HANTEC</h2>
            <h1 className="text-gray-900 text-2xl title-font font-medium mb-1 hidden lg:block  ">{dtoCombo.nombre_combo}</h1>

            {/* Precio */}
            <div className="flex justify-between  px-2 lg:px-0">
                <div className=''>


                    {totalSinDescuento !== total && (
                        <div>
                            <p>Total por separado</p>
                            <span className="line-through ">  ${totalSinDescuento.toLocaleString('es-MX')}</span>
                        </div>
                    )}

                    <span className="text-sm ">En combo te llevas {nproducts} productos por  </span>

                    <p className='text-2xl lg:text-3xl  font-semibold text-[#d30711] '>
                        $ {total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </p>

                    <p >{datoCotizacion}</p>

                    {
                        // Verifica primero si el token no es 'Tienda' y después si 'dtoCombo.msi' existe y es mayor que cero
                        (!token || token['$r07sad'] !== 'Tienda') && dtoCombo.msi && dtoCombo.msi > 0 && (
                            <div className='mt-2'>
                                <p className="text-sm font-semibold text-[#09A956] lg:text-lg">
                                    En {dtoCombo.msi} meses sin intereses de ${cuota}
                                </p>
                            </div>
                        )
                    }


                    {(!token || token['$r07sad'] !== 'Tienda') && (
                        <>
                            <button onClick={gometodoslist} className='text-sm mt-6 text-[#007bfc] cursor-pointer lg:hidden border-b-2 pb-1 border-[#007bfc]'>Conoce los métodos de pago</button>

                            <button onClick={abrirmodalmetodos} className='text-sm mt-3 text-[#007bfc] cursor-pointer lg:block hidden border-b-2 pb-1 border-[#007bfc]'>Conoce los métodos de pago</button>

                        </>
                    )}
                </div>

                <div className=' text-white rounded-md p-2  h-1/2 mt-5 flex justify-end text-right'>
                    {/*
                    <img width="300" height="200"
                        src={imgHotSale} alt="Hot Sale" className="w-32 lg:w-28 lg:mt-6 h-auto" />
*/}
                </div>
            </div>

            <div className='mt-4 pl-2'>
                {btn1 && (
                    <div>
                        <p className='text-sm font-black'>
                            Envio al C.P. {storedCodigoPostal}
                        </p>

                        {getNameOfOcurre && (
                            <p className='text-sm'>Recoger en la paquetería {getNameOfOcurre}</p>
                        )}
                        <button onClick={handleOpenModal} className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"


                            name='btn-cpcbod'
                            id='btn-cpcbod'

                        >
                            Cambiar Codigo postal
                        </button>
                    </div>
                )}
                {btn2 && (
                    <button onClick={handleOcurre} className="bg-blue-500 text-white px-4 py-2 rounded"

                        name='btn-selpaqueod'
                        id='btn-selpaqueod'
                    >
                        Seleecionar paqueteria para retirar
                    </button>
                )}
                {btn3 && (
                    <button onClick={handleCotizar} className="bg-blue-500 text-white px-4 py-2 rounded"

                        name='btn-ingresarcpcbdtail'
                        id='btn-ingresarcpcbdtail'

                    >
                        Ingresa tu código postal para conocer el envío
                    </button>
                )}

            </div>


            {/* datos resenas movil*/}
            <div className="mt-2 lg:hidden px-2 lg:px-0">
                <div className="flex items-center">
                    <div className="flex items-center">

                        {/*
                        {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                                key={rating}
                                className={classNames(
                                    datosredan.promedio > rating || isNaN(datosredan.promedio) ? 'text-[#1a2869]' : 'text-gray-200',
                                    'h-5 w-5 flex-shrink-0'
                                )}
                                aria-hidden="true"
                            />
                        ))}
                      <p>aqui van las strtellas de raiting</p>--*-*-*-*-*-*-*--*-*-*-*-*-*-*-**-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*--*-*-*
                   */}

                    </div>

                    {/*           {datosredan.numResenas > 0 && (*/}

                    {/*   <span className="text-gray-600 ml-3">5 reseñas</span> -----------------------------------------------------------------*/}
                    {/*     )}*/}



                </div>
            </div>

            {/* Diponibilidad  */}
            <div className='mt-2 px-2 lg:px-0'>
                {dtoCombo && dtoCombo.stock && dtoCombo.stock.disponible > 0 ? (
                    <>
                        <h3 className="text-sm font-semibold text-[#007bfc] lg:text-lg">DISPONIBLE</h3>
                        <div className="flex items-center space-x-3">
                            <p className='text-sm text-[#757575] lg:text-md'>{dtoCombo.stock.disponible} disponibles</p>
                        </div>
                    </>
                ) : (
                    <h3 className="text-sm font-semibold text-[#d30711] lg:text-lg">NO DISPONIBLE</h3>
                )}
            </div>


            {/* Div Envios */}
            <div className=" mt-4 sm:grid-cols-8 lg:grid-cols-4 px-2 lg:px-0">
                <p className="text-xs font-bold   text-[#1a2869] lg:text-lg">ENVÍOS A TODA LA REPÚBLICA</p>
                <p className="text-xs font-thin  text-gray-500 text-[#757575] lg:text-base">Aplican Restricciones Garantía de 10 años en suministro de refacciones. Garantía de 2 años por defecto de fábrica.</p>

            </div>
            {/*cantidad disponible y selecionada  */}
            {stock >= 0 ? (
                <div>
                    {/* Opciones de cantidad */}
                    <div className="flex items-center gap-1 mt-2">
                        <button
                            type="button"
                            name='btn-handleChangecbdetail'
                            id='btn-handleChangecbdetail'
                            className="size-10 leading-10 text-[#1a2869] transition hover:opacity-75 hover:text-xl"
                            onClick={() => handleChange({ target: { value: Math.max(1, selectedOption - 1) } })}
                        >
                            <i className="fas fa-minus"></i>
                        </button>

                        <input
                            type="number"
                            id="Quantity"
                            name="quantity"
                            value={selectedOption}
                            onChange={handleChange}
                            className="h-10 w-16 rounded border border-gray-900 text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                            min="1"
                            max={dtoCombo && dtoCombo.stock && dtoCombo.stock.disponible ? dtoCombo.stock.disponible : 10} // Usa el valor disponible o un valor por defecto si no está disponible
                        />

                        <button
                            type="button"

                            name='btn-cantimorecbdetail'
                            id='btn-cantimorecbdetail'
                            className="size-10 leading-10 text-[#1a2869] transition hover:opacity-75 hover:text-xl"
                            onClick={() => {
                                const newQuantity = selectedOption + 1;
                                const maxQuantity = dtoCombo && dtoCombo.stock && dtoCombo.stock.disponible ? dtoCombo.stock.disponible : 10;
                                handleChange({ target: { name: 'quantity', value: Math.min(maxQuantity, newQuantity) } });
                            }}                        >
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>


                    {/* Botones de acción */}
                    <div className="mt-4">
                        <button

                            name='btn-buynowcbdetail'
                            id='btn-buynowcbdetail'
                            className="mt-2 flex w-full items-center justify-center rounded-xl border border-transparent bg-[#e9bf56] px-8 py-3 text-base font-medium text-black hover:bg-[#1a2869] hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => handleAddToCart(dtoCombo, true)}
                        >
                            Comprar ahora
                        </button>
                        <button

                            name='btn-addcarcbdetail'
                            id='btn-addcarcbdetail'
                            className="mt-5 flex w-full items-center justify-center rounded-xl border border-transparent bg-[#757575] px-8 py-3 text-base font-medium text-white hover:bg-[#1a2869] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => handleAddToCart(dtoCombo, false)}
                        >
                            Agregar al carrito
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    {/* Mensaje de producto agotado */}
                    <div className="text-center p-4">
                        <h3 className="text-lg font-semibold">Producto Agotado</h3>
                        <p>Nos comunicaremos contigo en cuanto esté disponible.</p>
                    </div>
                </div>
            )}

            {/* Compartir y favoritos */}
            <div className='flex items-center text-center mt-5  justify-center '>

                {enFavoritos ? (
                    <p className='flex items-center text-base text-[#007bfc] lg:text-base'>
                        {/* Ícono de corazón lleno y texto indicando que el producto ya está en favoritos */}
                        <i className="fas fa-heart mr-2 text-[#007bfc]"></i> En favoritos
                    </p>
                ) : (
                    <button
                        className='flex items-center px-4 text-base text-[#1a2869] text-sm lg:text-base hover:text-[#007bfc] '
                        onClick={addfavorite}

                        name='btn-addfavoritecbdetail'
                        id='btn-addfavoritecbdetail'

                    >
                        {/* Ícono de corazón vacío y texto para agregar a favoritos */}
                        <i className="far fa-heart mr-2"></i> Agregar a favoritos
                    </button>
                )}

                <button onClick={compartirEnlace} className='flex  px-4 text-base text-[#1a2869] text-sm lg:text-base hover:text-[#007bfc] '

                    name='btn-sharecbdetail'
                    id='btn-sharecbdetail'

                >
                    <i className="fas fa-share-alt mr-2"></i>Compartir
                </button>
            </div>

            {/*Datos compra protegida  */}
            {/* compra protegida */}
            <div className='flex flex-col text-center  mt-4 mx-2 lg:mx-0 '>
                <div className='flex px-2 lg:px-0'>
                    <div className=' text-xl text-[#1a2869] text-center w-1/5'>
                        <i className="fas fa-shield-alt mt-5"></i>

                    </div>
                    <div className=' text-left text-gray-400 text-sm w-4/5'>
                        <h3 className='border-b-2  border-gray-300 pb-1 mb-1'>Compra Protegida.</h3>
                        <p>
                            Recibe el producto que esperabas o te devolvemos tu dinero.
                        </p>
                    </div>

                </div>
            </div>

            <div className='flex flex-col text-center  mt-4 mx-2 lg:mx-0'>
                <div className='flex px-2 lg:px-0'>
                    <div className='w-1/5 text-xl text-[#1a2869]'>
                        <i className="fas fa-redo-alt"></i>
                    </div>
                    <div className='w-4/5  text-left text-gray-400 text-sm'>
                        <h3 className='border-b-2  border-gray-300 pb-1 mb-1'> Devolución gratis.</h3>
                        <p>

                            Tienes 15 días desde que lo recibes.


                        </p>
                    </div>

                </div>
            </div>

            {/*Metodos de pago */}

            <div className='flex flex-col items-center mt-2 justify-center '>

                <div className='text-left w-4/5'>
                    <h3 className='text-[#027bfc] font-oswald font-black'>
                        MÉTODOS DE PAGO
                    </h3>
                    <div >
                        <img width="300" height="200"
                            className='lg:w-1/2 w-28 mt-2 mb-2' src={imge1cards2} />
                        <p className='text-sm text-gray-400 mt-2 font-oswald font-semibold '>TRANSFERENCIA <span className='ml-2'>OXXO PAGO</span></p>
                    </div>
                </div>
            </div>


            <div className='flex flex-col items-center mt-1 justify-center '>

                <div className='text-left w-4/5'>

                    <div>
                        <img width="300" height="200"
                            className='lg:w-5/6 w-40 mt-2 mb-2' src={imge1cards} />
                        <p className='text-sm text-gray-400 mt-2 font-oswald font-semibold '>TARJETA DE CRÉDITO O DÉBITO</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ComboDetails;
