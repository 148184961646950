import React from 'react'

function CategoriesAndTags({ categories, listSpecialCategories, handleCheckOptionSpecial, listTags, handleCheckOption, handleCategorySelected, tags, categoriesSpecial, categorySelected }) {

    return (
        <div className='mb-10'>
            <div className='bg-gray-100 w-3/5 py-10 px-5 rounded shadow-xl' >
                <p className='text-xl mb-5 font-semibold text-gray-700'>Categorias y atributos</p>

                <div className='flex justify-between mb-5'>

                    <div className='flex flex-col'>
                        <label htmlFor="precio_base">Categorias</label>
                        <select className='p-1' name="categoria" id="categoria" value={categorySelected} onChange={handleCategorySelected}>
                            <option value="">Selecciona una categoria</option>
                            {
                                categories?.map((category, index) =>
                                (<option key={index} value={category.nombre}>
                                    {category.nombre}
                                </option>))
                            }
                        </select>
                    </div>
                </div>

                <p className=''>Categorias de venta especiales</p>
                {/* flex flex-wrap justify-around */}
                <div className='grid grid-cols-1 gap-2 2xl:grid-cols-2'>
                    {
                        listSpecialCategories?.map((category, index) => (
                            <div className="flex items-center mr-32 mb-5 mt-5" key={index}>
                                <label className="inline-flex items-center cursor-pointer">
                                     {/* !! convierte a booleano: undefined o false será false, true seguirá siendo true */}
                                    <input onChange={handleCheckOptionSpecial} type="checkbox" checked={!!categoriesSpecial[category.id]} value={category.id} className="sr-only peer" />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{category.name}</span>
                                </label>
                            </div>
                        ))
                    }
                </div>

                <p className=''>Etiquetas</p>
                {/* flex flex-wrap justify-around */}
                <div className='grid md:grid-cols-1 lg:grid-cols-2 gap-3 lg:grid-cols-2' >
                    {
                        listTags?.map((tag, index) => (
                            <div className="flex items-center mr-32 mb-5 mt-5" key={index}>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input onChange={handleCheckOption} type="checkbox" checked={tags?.includes(tag) ? true : false}  value={tag} className="sr-only peer" />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{tag}</span>
                                </label>
                            </div>
                        ))
                    }



                </div>                
            </div>
        </div >
    )
}

export default CategoriesAndTags