import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FloatingWhatsAppButton from '../../../componentes/botones/FloatingWhatsAppButton';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import Header from '../../../componentes/Headers/Header';
import Footer from '../../../componentes/footer/Footer';
import axios from 'axios';
import imgHotSale from '../../../assets/iconovrnofinal1.webp'
import { goToPay, productToCart } from '../../../utils/PixelMeta/metaPixelEvents';
import { API_URL } from '../../../constants/constants';
import CargandoSpinner from '../../../componentes/cargacomponetes/CargandoSpinner';
import Dialoguito from '../../../componentes/Dialog';
import { useProductsCart } from '../../../store/bookStore';
import { getCookie, getRandomInt } from '../../../utils/utils';


const ResultsBySearch = () => {

    const navigate = useNavigate();
    const { carts, loading, error, fetchCarts } = useProductsCart()


    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);

    const [value, setValue] = useState([]);

    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

    const [isDialogOpen, setIsDialogOpen] = useState(false);


    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    useEffect(() => {
        const { searchTerm, filteredProducts } = location.state || {};
        setSearchTerm(searchTerm);
        const validProducts = filteredProducts?.filter(product => product.precio?.cantidad !== 0) || [];

        const availableProducts = validProducts.filter(product => product.stock.disponible > product.stock.mostrado);
        const unavailableProducts = validProducts.filter(product => product.stock.disponible <= product.stock.mostrado);

        availableProducts.sort((a, b) => b.precio.cantidad - a.precio.cantidad);
        unavailableProducts.sort((a, b) => b.precio.cantidad - a.precio.cantidad);

        const sortedProducts = [...availableProducts, ...unavailableProducts];

        setFilteredProducts(sortedProducts);
        console.log('TProductos sin filtrar', filteredProducts);
        console.log('Productos filtrados:', sortedProducts);
    }, [location]);




    const getProductById = async (id_product, buyNow) => {
        // console.log('seta el id en ', id_product, buyNow)
        setIsLoading(true)

        const payload = {
            "id_producto": parseInt(id_product)
        };
        try {
            const respuesta = await axios.post(API_URL + '/getProductById', payload, { timeout: 15000 });
            const datosRecibidos = await respuesta.data;
            // console.log('-----Datos recibidos es: ', datosRecibidos[0]);


            setValue(datosRecibidos[0]);

            // -------- Pixel Meta ---------- //
            // viewProduct(
            //     datosRecibidos[0]?.['titulo'],
            //     datosRecibidos[0]?.['precio']?.['cantidad'],
            //     token ? token['$email'] : 'No registrado'
            // )

            addProduct(datosRecibidos[0], buyNow)
        } catch (error) {
            console.error('Error al cargar datos prodcuto:', error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out getProductById');
            }


        } finally {
        }

    }

    const addProduct = async (producto, buyNow) => {
        let event_id = getRandomInt()
        // -------------------- Pixel ------------------ //
        productToCart(
            producto.id_producto,
            producto.titulo,
            producto.precio.cantidad,
            token && token['$email'] ? token['$email'] : null,
            token && token['$name'] ? token['$name'] : null,
            event_id
        )
        // --------------------------------------------- //

        if (buyNow) {
            // Eliminar el cartLocal del localStorage
            window.localStorage.removeItem('cartLocal');
        }

        let mainProductData = token && token['$r07sad'] === 'Tienda' ? {
            "id_producto": "" + producto.id_producto,
            "id_odoo": producto.id_odoo,
            "cantidad": 1,
            "email": token ? token['$email'] : '',  // Envía email si el token existe, de lo contrario envía cadena vacía
            "precio": {
                "cantidad": producto.precio_autozone ? parseFloat(producto.precio_autozone.cantidad) : parseFloat(producto.precio.cantidad),
                "moneda": "MXN"
            },
            "precio_habitual": {
                "cantidad": producto.precio_habitual.cantidad,
                "moneda": "MXN"
            },
            "titulo": producto.titulo,
            "imagen": producto.imagen,
            "sku": producto.sku,
            "precio_sin_iva": {
                "cantidad": producto.precio_az_sin_iva ? parseFloat(producto.precio_az_sin_iva.cantidad) : parseFloat(producto.precio_sin_iva?.cantidad),
                "moneda": "MXN"
            },
            "descuento": 0,
            "msi": 0,
            "categoria": producto.categoria,
        } : {
            "id_producto": "" + producto.id_producto,
            "id_odoo": producto.id_odoo,
            "cantidad": 1,
            "email": token && token['$email'] ? token['$email'] : '',
            "precio": {
                "cantidad": parseFloat(producto.precio.cantidad),
                "moneda": "MXN"
            },
            "precio_habitual": {
                "cantidad": parseFloat(producto.precio_habitual.cantidad),
                "moneda": "MXN"
            },
            "titulo": producto.titulo,
            "imagen": producto.imagen,
            "sku": producto.sku,
            "precio_sin_iva": {
                "cantidad": parseFloat(producto.precio_sin_iva?.cantidad),
                "moneda": "MXN"
            },
            "descuento": producto.descuento,
            "msi": producto.msi,
            "cubre_envio": producto.cubre_envio,
            "categoria": producto.categoria,
            "tipo_envio": producto?.t_envio,
            "precio_envio": producto?.p_envio
        };

        let giftProductData = {};

        if (producto.isGift && producto.gifts) {
            giftProductData = {
                "id_producto": "" + producto.gifts.id_producto,
                "id_odoo": producto.gifts.id_odoo,
                "cantidad": producto.gifts.cantidad,
                "email": token && token['$email'] ? token['$email'] : '',
                "precio": {
                    "cantidad": parseFloat(producto.gifts.precio.cantidad),
                    "moneda": "MXN"
                },
                "titulo": producto.gifts.titulo,
                "imagen": producto.gifts.imagen,
                "sku": producto.gifts.sku,
                "precio_sin_iva": {
                    "cantidad": parseFloat(producto.gifts.precio_sin_iva?.cantidad),
                    "moneda": "MXN"
                },
                "descuento": 0,
                "msi": producto.msi,
                "id_producto_padre": "" + producto.id_producto,
                "cubre_envio": producto.cubre_envio,
                "categoria": producto.categoria,
                "tipo_envio": producto?.t_envio,
                "precio_envio": producto?.p_envio
            };
        }

        let endpointUrl = null;
        let dataToSend = null;

        if (buyNow) {
            endpointUrl = '/replaceCart';
            dataToSend = {
                id_cliente: token ? token['$udi09345'] : '',
                productos: [
                    mainProductData
                ],
            };
        } else {
            endpointUrl = '/updateShoppingCart';
            dataToSend = mainProductData;
        }

        try {
            dtossend["event_id"] = event_id
            const responseMain = await axios.post(API_URL + endpointUrl, dataToSend, {
                headers: {
                    Authorization: token['$tk3m09s'],
                    fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
                    fbc: getCookie("_fbc") ? getCookie("_fbc") : null,
                    ttp: getCookie("_ttp") ? getCookie("_ttp") : null,
                },
                timeout: 10000
            });

            if (giftProductData.id_producto) {
                const responseGift = await axios.post(API_URL + "/updateShoppingCart", giftProductData, {
                    headers: { Authorization: token['$tk3m09s'] },
                    timeout: 10000
                });
            }

            if (buyNow) {
                gopay();
            } else {
                setIsLoading(false);

                setIsDialogOpen(true);
            }
        } catch (error) {
            console.error('Error al agregar al carrito:', error);
            setIsLoading(false);
            setIsDialogOpen(true);

            const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : [];
            cartLocal.push(mainProductData);
            if (producto.isGift && producto.gifts) {
                cartLocal.push(giftProductData);
            }
            localStorage.setItem('cartLocal', JSON.stringify(cartLocal));

            if (buyNow) {
                gopay();
            }
        }
    };

    const cerrarModal = () => {
        setIsDialogOpen(false); // Cierra el diálogo
    };



    const gopay = () => {
        goToPay(value?.precio?.cantidad, token ? token['$email'] : 'No registrado');
        fetchCarts(token);
        navigate('/Pagos');
    }

    //nueva forma de enrutamietno
    const formatTitleForUrl = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };

    const handleNavigation = (id_product, titulo) => {
        const formattedTitle = formatTitleForUrl(titulo);
        navigate(`/product/${formattedTitle}/${id_product}`);
    };



    return (
        <div>


            <Header />
            <ScrollableCategories />
            <Dialoguito isOpen={isDialogOpen} onClose={cerrarModal} producto={value} />

            {isLoading && (
                <CargandoSpinner txtcharge='Preparando Compra' />

            )}
            <FloatingWhatsAppButton page="busqueda" categoryName={'Busqueda'} />
            <div className="container mx-auto mt-10 px-5 lg:w-4/5">
                <h1 className="text-2xl font-bold mb-4">
                    Resultados de la búsqueda para <span className="text-blue-700 italic">{searchTerm}</span>
                </h1>
                {filteredProducts.length > 0 ? (
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-1">
                        {filteredProducts.map((product) => (
                            product.imagen && product.precio_habitual?.cantidad !== 0 && (
                                <div key={product?.id_producto} className="border rounded-lg p-2 lg:p-4 flex flex-col items-center custom-shadow mr-4 mb-2 transition-transform duration-200 hover:scale-105" style={{ minHeight: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div onClick={() => handleNavigation(product.id_producto, product.titulo)} className='cursor-pointer h-auto relative block'>
                                        {product.imagen ? (
                                            <div className='relative'>
                                                   {/*
                                                {product.precio_habitual?.cantidad !== product.precio?.cantidad && (
                                                    <div className='absolute right-0 lg:-top-7 -top-5'>
                                                        <img width="300" height="200"
                                                            src={imgHotSale} alt="Hot Sale" className="h-6 lg:h-7 w-auto " />
                                                    </div>
                                                )}*/}
                                                <img loading="lazy" width="300" height="200"
                                                    src={product.imagen} alt={product.titulo} className="w-48 h-48 lg:w-44 lg:h-44 mt-6 lg:mt-5 object-contain mx-auto" />
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center w-48 h-48  rounded sm:w-48 dark:bg-gray-700">
                                                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                                </svg>
                                            </div>
                                        )}

                                        {product.titulo && (
                                            <div className="w-full h-16 lg:mt-10 text-left overflow-hidden text-center">
                                                <h2 className=" font-medium text-xs lg:text-sm ">{product.titulo}</h2>
                                            </div>
                                        )}

                                        <div className='w-full h-auto  lg:h-1/3 '>
                                            {product.precio_habitual?.cantidad !== (product.precio?.cantidad || product.precio_autozone?.cantidad) && (
                                                <div className='text-[#757575] text-xs mt-2 text-center '>
                                                    <p>Precio habitual</p>
                                                    <p className='line-through'>
                                                        $ {product.precio_habitual?.cantidad.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {product.precio?.moneda || product.precio_autozone?.moneda}
                                                    </p>
                                                </div>
                                            )}

                                            <div className={`text-center font-bold h-10 ${product.precio_habitual?.cantidad === (product.precio?.cantidad || product.precio_autozone?.cantidad) ? 'text-black mt-16' : 'mt-6 mb-4 text-[#d30711]'}`}>
                                                {product.precio_habitual?.cantidad !== (product.precio?.cantidad || product.precio_autozone?.cantidad) && (
                                                    <p className="text-sm lg:text-base">Precio de oferta<br></br></p>
                                                )}
                                                <p className={`text-sm  lg:text-base   lg:text${product?.precio_habitual?.cantidad === product?.precio?.cantidad ? 'mt-20' : ''}`}>
                                                    $ {(
                                                        product.precio?.cantidad && product.precio?.cantidad !== 0
                                                            ? product.precio.cantidad
                                                            : product.precio_autozone?.cantidad && product.precio_autozone.cantidad !== 0
                                                                ? product.precio_autozone.cantidad
                                                                : product.precio_habitual?.cantidad
                                                    ).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    <span className='text-xs ml-1'>
                                                        MXN
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {product.stock.disponible > product.stock.mostrado && (
                                        <div className='mt-6 flex w-full flex-col justify-center z-10'>
                                            <button
                                                onClick={() => getProductById(product.id_producto, true)}
                                                name='btn-getProductById'
                                                id='btn-getProductById'
                                                className='bg-[#e9bf56] px-2 py-1.5 mt-1 uppercase text-xs rounded transition duration-200 ease-in-out hover:bg-[#B99336] hover:font-semibold hover:shadow-lg'
                                                >
                                                Comprar ahora
                                            </button>
                                            <button
                                                onClick={() => getProductById(product.id_producto, false)}
                                                name='btn-getProductById'
                                                id='btn-getProductById'
                                                className='bg-[#1a2869] px-2 py-1.5 text-white mt-3 uppercase text-xs rounded transition duration-200 ease-in-out hover:bg-blue-600 hover:shadow-lg'
                                                >
                                                Añadir a carrito
                                            </button>
                                        </div>


                                    )}

                                </div>
                            )
                        ))}
                    </div>
                ) : (
                    <h2 className="text-xl font-semibold mt-10 text-center text-red-500">
                        No encontramos resultados para tu búsqueda. Intenta con otra palabra.
                    </h2>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default ResultsBySearch;