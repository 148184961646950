import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const PackageTimeline = ({ packageStatus, datecrate, updatedate, dtotracking }) => {
    const steps = [
        { description: "Pendiente", icon: "fas fa-hourglass-half" },
        { description: "Pagado", icon: "fas fa-clipboard-check" },
        { description: "Preparando", icon: "fas fa-plane-departure" },
        { description: "En camino", icon: "fas fa-truck-moving" },
        { description: "Entregado", icon: "fas fa-box-open" },
        { description: "Cancelado", icon: "fas fa-ban" }
    ];

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (packageStatus === 'Pendiente') {
            setCurrentStep(1);
        } else if (packageStatus === 'Preparado') {
            setCurrentStep(2);
        }

        // Solo verifica dtotracking si el estado es más allá de Pagado
        if (packageStatus === 'Preparado' && dtotracking && dtotracking.shipment_history) {
            const history = dtotracking.shipment_history;
            if (history.some(entry => entry.status.includes('EN BODEGA ORIGEN'))) {
                setCurrentStep(3);
            }
            if (history.some(entry => entry.status.includes('ENVÍO DOCUMENTADO'))) {
                setCurrentStep(4);
            }
            if (history.some(entry => entry.status.includes('Entregado'))) {
                setCurrentStep(5);
            }
        }
    }, [packageStatus, dtotracking]);

    const formatDate = (dateString) => {
        return format(parseISO(dateString), 'd \'de\' MMMM \'de\' yyyy', { locale: es });
    };

    return (
        <div className="p-4 mt-4 xl:p-0 w-full">
            <section className="text-gray-600 body-font">
                <div className="container px-5 xl:px-2 py-2 mx-auto flex flex-wrap">
                    <div className="flex flex-wrap w-full">
                        {steps.map((step, index) => (
                            index + 1 <= currentStep && (
                                <div key={index} className="flex relative pb-6">
                                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className={`flex-shrink-0 w-10 h-10 rounded-full ${currentStep === index + 1 ? 'bg-[#1A2869]' : 'bg-gray-300'} inline-flex items-center justify-center text-white relative `}>
                                        <i className={`${step.icon} ${currentStep === index + 1 ? 'text-white' : 'text-gray-500'}`}></i>
                                    </div>
                                    <div className="flex-grow pl-4">

                                        <div className={`p-2 rounded ${currentStep === index + 1 ? 'bg-[#1A2869] text-white' : 'text-gray-900 '} `}>

                                            <h2 className="font-medium title-font lg:text-base text-sm   tracking-wider">
                                                {index >= 2 && dtotracking && currentStep === index + 1 ? dtotracking.shipment_history[dtotracking.shipment_history.length - 1].status : step.description}
                                            </h2>
                                            <p className="text-xs lg:text-sm mt-1">
                                                {index === 0 && datecrate ? `  ${formatDate(datecrate)}` : ''}
                                                {index === 1 && updatedate ? ` ${formatDate(updatedate)}` : ''}
                                                {index >= 2 && dtotracking && dtotracking.shipment_history.length > 0 ? ` ${formatDate(dtotracking.shipment_history[dtotracking.shipment_history.length - 1].created_at)}` : ''}
                                            </p>
                                            {currentStep === index + 1 && dtotracking && dtotracking.tracking_number ?
                                                <p className='text-xs lg:ml-1 mt-2 font-black'>N° de guia: {dtotracking.tracking_number}</p> : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PackageTimeline;
