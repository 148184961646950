
import { create } from "zustand";

import { API_URL } from '../constants/constants';

export const dataUserStore = create((set) => ({
  userData: { "nombre": "Anton" },
  inc: () => set((state) => ({ userData: { "nombre": "Juan" } })),
}))

export const useUserStore = create((set) => ({
  user: null,
  isLoggedIn: false,
  setUser: (user) => set({ user: user, isLoggedIn: user !== null }),
  logout: () => set({ user: null, isLoggedIn: false })
}))

//Estado para productos

export const useProductStore = create((set) => ({
  products: [],
  productsSeason: [],
  productsSeller: [],
  loading: false,
  error: null,
  fetchProducts: async (categoria) => {
    //Indicamos que esta cargando e inicilizamos el error en null para limpiar errores anteriores
 //   console.log('entre con ', categoria)
    set({ loading: true, error: null })
    try {

      //Verificar la categoria
      const body = JSON.stringify({ "categoria": categoria, "limit": 18 }); //Cambiar el valor de la categoria por la que se quiere obtener
      //Agregar limite de productos que se obtienen
      const response = await fetch(API_URL + '/getProductsByCategory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body
      })
      const data = await response.json()
   //   console.log('datospeticoncascas', data)
      set({ products: data, loading: false })
    } catch (error) {
      set({ error: error.message, loading: false })
    }
  },
  fetchProductsSeason: async (categoria: string) => {
    //Indicamos que esta cargando e inicializamos el error en null para limpiar errores anteriores
    set({ loading: true, error: null })

    //Identificar que categoria es (puede ser con un switch)

    try {
      const payload = JSON.stringify({ "categoria": categoria });

      const response = await fetch(API_URL + '/getProductsByCategorySell', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload
      })

      const data = await response.json()

      switch (categoria) {
        case 'ofertas_temporada':
          // console.log('ofertas')
          set({ productsSeason: data, loading: false })
          break;
        case 'mas_vendidos':
          // console.log('mas vendidos')
          set({ productsSeller: data, loading: false })
        default:
          break;
      }

    } catch (error) {
      set({ error: error.message, loading: false })
    }
  },
  //Estado para los productos mas vendidos
  fetchProductsSeller: async (categoria: string) => {
    //Indicamos que esta cargando e inicializamos el error en null para limpiar errores anteriores
    set({ loading: true, error: null })

    try {
      const payload = JSON.stringify({ "categoria": categoria });

      const response = await fetch(API_URL + '/getProductsByCategorySell', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload
      })
      const data = await response.json()
      set({ productsSeller: data, loading: false })

    } catch (error) {
      set({ error: error.message, loading: false })
    }
  }
}))


export const useProductsCart = create((set) => ({
  carts: [],
  loading: false,
  error: null,
  fetchCarts: async (token) => {
    set({ loading: true, error: null })
    try {
      const body = JSON.stringify({ "id_usuario": token['$udi09345'] }); //Cambiar el valor de la categoria por la que se quiere obtener

      const response = await fetch(API_URL + '/getCartByUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body
      })
      const data = await response.json()
      set({ carts: data, loading: false })
    } catch (error) {
      set({ error: error.message, loading: false })
    }
  },
  deleteCart: (newCart) => {
    set({ carts: newCart })
  },
}))


export const useProductsCategory = create((set) => ({
  products: [],
  loading: false,
  error: null,
  // fetchProductsByCategory
}))