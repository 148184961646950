import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function ManageImageFiles({
    fileInputRef, onImageChange,
    handleDivClick, imagePreviewUrl,
    multipleFilesInputRef, onImagesChange,
    handleClickMultiple, imagePreviewUrls,
    handleDeleteImage, videoUrl, setVideoUrl,
    fichatecnica, onPDFChange, pdfBase64,
    fileInputRefpdf, handleDivClickpdf,
    setImagePreviewUrls // Añadir setImagePreviewUrls para actualizar el orden de las imágenes
}) {

    // Manejar el evento de arrastre y soltar
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const reorderedImages = Array.from(imagePreviewUrls);
        const [removed] = reorderedImages.splice(result.source.index, 1);
        reorderedImages.splice(result.destination.index, 0, removed);
        setImagePreviewUrls(reorderedImages);
    };

    return (
        <div className='flex justify-between mb-10'>

            <div className='bg-gray-100 w-3/5 py-10 px-5 rounded shadow-xl' >
                <p className='text-xl mb-5 font-semibold text-gray-700'>Archivos multimedia</p>
                <div>
                    <input className='hidden' type="file" id='imagen' alt="imagen" ref={fileInputRef} onChange={onImageChange} />
                    <label htmlFor="imagen">Imágen principal del producto</label>

                    <div className='bg-white h-3/4 w-auto p-16 my-5 rounded cursor-pointer border border-slate-300 hover:border-indigo-300' onClick={handleDivClick} htmlFor='imagen'>
                        <p className='text-center'>{imagePreviewUrl === '' ? 'Cargar imágen' : 'Cambiar imágen'} </p>
                    </div>
                </div>

                <div>
                    <input className='hidden' multiple type="file" id='imagenes' alt="imagenes" ref={multipleFilesInputRef} onChange={onImagesChange} />
                    <label htmlFor="imagenes">Imagenes del producto</label>
                    <div className='bg-white h-3/4 p-16 my-5 rounded cursor-pointer border border-slate-300 hover:border-indigo-300' onClick={handleClickMultiple}>
                        <p className='text-center'>Carga máximo 3 imágenes</p>
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="images">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {imagePreviewUrls?.map((imagePreviewUrl, index) => (
                                        <Draggable key={index} draggableId={`image-${index}`} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className='relative'
                                                >
                                                    <button name='btntrahs' id='btntrahs' onClick={() => handleDeleteImage(index)} className='absolute right-0'>
                                                        <i className="fas fa-trash text-red-400"></i>
                                                    </button>
                                                    <img width="300" height="200"
                                                        className='rounded' src={imagePreviewUrl} alt={`Preview ${index}`} style={{ width: '100px', height: '100px', margin: '10px' }} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                <div className='mt-5'>
                    <label htmlFor="videoUrl" className='block text-sm font-medium text-gray-700'>URL del video</label>
                    <input
                        type="url"
                        id="videoUrl"
                        name="videoUrl"
                        onChange={event => setVideoUrl(event.target.value)}
                        value={videoUrl}
                        className='rounded p-1 border border-slate-300 hover:border-indigo-300 w-4/5 mt-1'
                        placeholder="Introduce URL del video"
                    />
                </div>

                <div className='mt-2'>
                    <label htmlFor="fichaTecnica">Ficha Técnica (PDF)</label>
                    <input
                        className="hidden"
                        type="file"
                        id="fichaTecnica"
                        accept="application/pdf"
                        onChange={onPDFChange}
                        ref={fileInputRefpdf}
                    />
                    <div
                        className='bg-white h-3/4 p-16 my-2 rounded cursor-pointer border border-slate-300 hover:border-indigo-300'
                        onClick={() => document.getElementById('fichaTecnica').click()}
                    >
                        <p className='text-center'>{fichatecnica ? 'Cambiar PDF' : 'Cargar PDF'}</p>
                    </div>
                    {fichatecnica && <p className="text-sm text-gray-600">Nombre del archivo: <span className='font-black'>{fichatecnica.name}</span></p>}

                    <div className='mt-2'>
                        {pdfBase64 && (
                            <embed
                                src={pdfBase64}
                                type="application/pdf"
                                width="35%"
                                height="350px" />
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ManageImageFiles;
