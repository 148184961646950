import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import axios from 'axios';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import { API_URL } from '../../constants/constants';
import Sidebar from '../../componentes/Admi/Sidebar';


function AddCuestion() {
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const location = useLocation();
    const { datopregunta } = location.state || { product: null }; // Obtiene idadress del estado, si existe
    console.log('los datos de la pregunta son:', datopregunta)
    const navigate = useNavigate();

    const [isLoadingProductos, setIsLoadingProductos] = useState(false);

    const [datosProducto, setDatosProducto] = useState(null);
    //datos del textarea
    const [opinion, setOpinion] = useState("");


    useEffect(() => {
        window.scrollTo(0, 0);



        const payload = {
            "id_producto": parseInt(datopregunta.id_producto)
        };


        const cargarDatos = async () => {
            setIsLoadingProductos(true); // Indicar inicio de carga
            try {
                const respuesta = await axios.post(API_URL + '/getProductById', payload, { timeout: 15000 });
                const datosRecibidos = await respuesta.data;
                //console.log('Datos recibidos es: ', datosRecibidos);
                setDatosProducto(datosRecibidos[0]);

                setIsLoadingProductos(false);
            } catch (error) {
                console.error('Error al cargar datos:', error);
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out getProductById');
                }
                // navigate('/');
            } finally {
                setIsLoadingProductos(false); // Indicar fin de carga
            }
        };

        cargarDatos();
    }, []);

    const sendRespuesta = async () => {
        setIsLoadingProductos(true); // Inicia la carga
        // console.log("La repsuesta que sites es: ", opinion);

        const payload = {

            "id_pr": parseInt(datopregunta.id_pr),
            "respuesta": opinion,
            "id_producto":datopregunta?.id_producto,
            "id_usuario":datopregunta?.id_usuario

        }


        try {

            const config = {
                headers: {
                    'Authorization': info['$tk3m09s']

                },
                timeout: 15000
            };

            const response = await axios.post(API_URL + '/questionAndAnswerByUser', payload, config)
            // console.log('Se guardo la respuesta', response.data);
            setOpinion('')
            navigate(-1);
        } catch (error) {
            console.error('Ha ocurrido un error: ', error)
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out questionAndAnswerByUser');
            }
        } finally {
            setIsLoadingProductos(false); // Finaliza la carga
        }
    };


    return (
        <div className='p-4 sm:ml-64'>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            <div>
                <div className='flex'>
                    <Link className='pr-5' to={'/questionProduct'}><i className="fa-solid fa-arrow-left"></i></Link>
                    <h2 className='text-2xl font-black mb-8'>Responder pregunta con base al producto</h2>
                </div>
                {isLoadingProductos ? (
                    <CargandoSpinner txtcharge="Cargando datos" />
                ) : datosProducto ? (

                    <div className=' flex w-4/5 '>
                        <div className='w-1/2 flex flex-col items-center pt-4 border-2 p-4 rounded'>
                            <h2 className="text-lg">{datosProducto.titulo}</h2>
                            <img width="300" height="200"
 src={datosProducto.imagen} alt={datosProducto.titulo} className="w-auto h-44 object-cover rounded	 mt-3" />
                            <div className=' w-4/5 px-2 border-t-2 pt-3 '>
                                <div className='flex w-4/5 mx-auto  justify-between'>
                                    <p>Categoría: </p>
                                    <p className='ml-2 font-black text-[#030303]'>{datosProducto.categoria} </p>
                                </div>

                                <div className='flex w-4/5 mx-auto my-1 justify-between'>
                                    <p>SKU: </p>
                                    <p className='ml-2 font-black text-[#030303]'>{datosProducto.sku} </p>
                                </div>

                                <div className='flex  w-4/5 mx-auto my-1  justify-between'>
                                    <p>Precio Habitual: </p>
                                    <p className='ml-2 font-black text-[#030303]'>${datosProducto.precio_habitual.cantidad} </p>
                                </div>

                                <div className='flex w-4/5 mx-auto my-1  justify-between'>
                                    <p>Precio: </p>
                                    <p className='ml-2 font-black text-[#030303]'>${datosProducto.precio.cantidad}  </p>
                                </div>

                            </div>
                        </div>
                        <div className='w-1/2  text-center pt-4 '>


                            <span className="relative flex justify-center">
                                <div
                                    className="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"
                                ></div>

                                <span className="relative z-10 bg-white px-6">Datos de la pregunta</span>

                            </span>

                            <div className='w-4/5 mx-auto  justify-between pt-2	 mt-2 flex'>
                                <p className='text-[#030303]'>Fecha y hora :</p>
                                <p className=' font-semibold'>
                                    {new Intl.DateTimeFormat('es-MX', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                        timeZone: 'America/Mexico_City'
                                    }).format(new Date(datopregunta.created_at))}
                                </p>
                            </div>


                            <div className='w-4/5 mx-auto justify-between	  mt-2 flex'>
                                <p className='text-[#030303]'>Pregunta:</p>
                                <div className='w-2/3 '>
                                    <p className='font-semibold  text-right rounded'>{datopregunta.pregunta}</p>

                                </div>

                            </div>



                            {datopregunta.respuesta ? (
                                <div className='mt-4'>

                                    <span className="relative flex justify-center">
                                        <div
                                            className="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"
                                        ></div>

                                        <span className="relative z-10 bg-white px-6">Datos de la respuesta</span>
                                    </span>

                                    <div className='w-4/5 mx-auto  justify-between	pt-2 mt-2 flex'>
                                        <p className='text-[#030303]'>Fecha y hora :</p>
                                        <p className=' font-semibold'>
                                            {new Intl.DateTimeFormat('es-MX', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                second: 'numeric',
                                                timeZone: 'America/Mexico_City'
                                            }).format(new Date(datopregunta.updated_at))}
                                        </p>
                                    </div>

                                    <div className='w-4/5 mx-auto justify-between	  mt-2 flex'>
                                        <p className='text-[#030303]'>Respuesta:</p>
                                        <div className='w-2/3 '>
                                            <p className='font-semibold  text-right rounded'>{datopregunta.respuesta}</p>

                                        </div>

                                    </div>

                                </div>
                            ) : (
                                <div className='flex flex-col items-center justify-center'>
                                    <textarea
                                        className="textarea textarea-bordered w-4/5 h-40 mt-4 rounded border p-2 border-black resize-none"
                                        placeholder="Escribe tu Respuesta aquí..."
                                        value={opinion}
                                        onChange={(e) => setOpinion(e.target.value)}
                                    ></textarea>

                                    <button
                                        className={`btn mt-4 mb-4 px-10 py-3 text-lg ${opinion && opinion.length > 10 ? 'bg-[#1e3d8b] text-white hover:bg-[#163472] hover:text-[#e2e8f0]' : 'bg-gray-200 text-gray-500'} rounded-lg transition ease-in-out duration-150`}
                                        disabled={!(opinion && opinion.length > 10)}
                                        onClick={sendRespuesta}
                                    >
                                        Enviar Respuesta
                                    </button>
                                </div>
                            )}

                        </div>
                    </div>

                ) : (
                    <p>No se encontraron datos.</p>
                )}
            </div>

        </div>
    )
}

export default AddCuestion