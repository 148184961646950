import { useEffect, useState, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductStore, useProductsCart } from '../../store/bookStore';
import axios from 'axios';
import CargandoSpinner from '../cargacomponetes/CargandoSpinner';
import { API_URL } from '../../constants/constants';
import '../../assets/style/generalstyles.css';  // Asegúrate de que la ruta al archivo CSS es correcta
import { addToFavorites, goToPay, productToCart } from '../../utils/PixelMeta/metaPixelEvents';
import { getCookie, getRandomInt } from '../../utils/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Cargar componentes de forma diferida
const Dialog = lazy(() => import('../Dialog'));
const ListaProductos = lazy(() => import('../productos/ListaProductos'));
const ProductImages = lazy(() => import('./ProductImages'));
const ProductDetails = lazy(() => import('./ProductDetails'));
const Modallistaproductos = lazy(() => import('../modales/Modallistaproductos'));
const ModalNoFav = lazy(() => import('../../pages/user/favoritos/Modalnofav'));

const DatosProducto = ({ datos, id, productsend, datosredan, enfavoritos, verifcarFavoritos }) => {
    const { products, fetchProducts } = useProductStore();
    const { fetchCarts } = useProductsCart();    
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    useEffect(() => {
        fetchProducts(datos[0]?.categoria);
    }, [id, datos]);

    const [selectedOption, setSelectedOption] = useState(1);
    const handleChange = (event) => {
        setSelectedOption(Math.max(1, Math.min(10, Number(event.target.value))));
    };

    const navigate = useNavigate();

    const compartirEnlace = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Compartir enlace',
                url: window.location.href,
            }).then(() => {
                // Éxito
            }).catch((error) => {
                console.error('Error al compartir', error);
            });
        } else {
            navigator.clipboard.writeText(window.location.href)
                .then(() => {
                    alert("Enlace copiado al portapapeles!");
                })
                .catch(err => {
                    console.error("Error al copiar el enlace: ", err);
                });
        }
    };

    let listaImagenesTransformada;
    if (datos[0].imagenes && datos[0].imagenes.length > 0) {
        listaImagenesTransformada = datos[0].imagenes.map((imagen, index) => ({
            id_producto: index,
            imagen: imagen,
            titulo: `Imagen ${index + 1}`,
        }));
    } else if (datos[0].imagen) {
        listaImagenesTransformada = [{
            id_producto: 0,
            imagen: datos[0].imagen,
            titulo: 'Imagen Principal',
        }];
    } else {
        listaImagenesTransformada = [{
            id_producto: 0,
            imagen: '',
            titulo: 'Sin Imagen',
        }];
    }

    if (datos[0].url_video) {
        let urlVideo = datos[0].url_video;
        if (urlVideo.includes("youtube.com")) {
            let idVideo = urlVideo.split('v=')[1];
            urlVideo = `https://www.youtube.com/embed/${idVideo}`;
        } else if (urlVideo.includes("tiktok.com")) {
            let idVideo = urlVideo.split('/video/')[1].split('?')[0];
            urlVideo = `https://www.tiktok.com/embed/v2/${idVideo}`;
        }
        listaImagenesTransformada.push({
            id_producto: listaImagenesTransformada.length,
            imagen: urlVideo,
            titulo: 'VideoUrl'
        });
    }

    const [imagenActiva, setImagenActiva] = useState({
        imagen: listaImagenesTransformada[0].imagen,
        titulo: listaImagenesTransformada[0].titulo
    });

    const gometodoslist = (msi) => {
        if (msi) {
            navigate('/Listametodospagotxt', { state: { msi: msi } });
        } else {
            navigate('/Listametodospagotxt');
        }
    };

    const [modalMetodos, setModalMetodos] = useState(false);
    const [mesesDisponible, setMesesDisponible] = useState('');

    const abrirmodalmetodos = (msi) => {
        setMesesDisponible(msi);
        setModalMetodos(true);
    };

    const closeModaltMetodos = () => {
        setModalMetodos(false);
    };

    const addProduct = async (buyNow) => {
        let mainProductData = {};
        let giftProductData = {};
        let event_id = getRandomInt()
        productToCart(
            datos[0]?.id_producto,
            datos[0]?.titulo,
            datos[0]?.precio.cantidad,
            token && token['$email'] ? token['$email'] : null,
            token && token['$name'] ? token['$name'] : null,
            event_id
        );

        if (buyNow) {
            window.localStorage.removeItem('cartLocal');
        }

        if (datos && datos[0]) {
            mainProductData = token && token['$r07sad'] === 'Tienda' ? {
                "id_producto": "" + datos[0]?.id_producto,
                "id_odoo": datos[0]?.id_odoo,
                "cantidad": selectedOption,
                "email": token ? token['$email'] : '',
                "precio": {
                    "cantidad": datos[0]?.precio_autozone ? parseFloat(datos[0]?.precio_autozone.cantidad) : parseFloat(datos[0]?.precio.cantidad),
                    "moneda": "MXN"
                },
                "precio_habitual": {
                    "cantidad": datos[0]?.precio_habitual.cantidad,
                    "moneda": "MXN"
                },
                "titulo": datos[0]?.titulo,
                "imagen": datos[0]?.imagen,
                "sku": datos[0]?.sku,
                "precio_sin_iva": {
                    "cantidad": datos[0]?.precio_az_sin_iva ? parseFloat(datos[0]?.precio_az_sin_iva.cantidad) : parseFloat(datos[0]?.precio_sin_iva?.cantidad),
                    "moneda": "MXN"
                },
                "descuento": 0,
                "categoria": datos[0]?.categoria,
                "msi": 0
            } : {
                "id_producto": "" + datos[0]?.id_producto,
                "id_odoo": datos[0]?.id_odoo,
                "cantidad": selectedOption,
                "email": token ? token['$email'] : '',
                "precio": {
                    "cantidad": parseFloat(datos[0]?.precio.cantidad),
                    "moneda": "MXN"
                },
                "precio_habitual": {
                    "cantidad": parseFloat(datos[0]?.precio_habitual.cantidad),
                    "moneda": "MXN"
                },
                "titulo": datos[0]?.titulo,
                "imagen": datos[0]?.imagen,
                "sku": datos[0]?.sku,
                "precio_sin_iva": {
                    "cantidad": parseFloat(datos[0]?.precio_sin_iva?.cantidad),
                    "moneda": "MXN"
                },
                "descuento": datos[0]?.descuento,
                "msi": datos[0]?.msi,
                "cubre_envio": datos[0]?.cubre_envio,
                "categoria": datos[0]?.categoria,
                "tipo_envio": datos[0]?.t_envio,
                "precio_envio": datos[0]?.p_envio
            };
        }

        if (datos[0]?.isGift && datos[0]?.gifts) {
            giftProductData = {
                "id_producto": "" + datos[0]?.gifts.id_producto,
                "id_odoo": datos[0]?.gifts.id_odoo,
                "cantidad": datos[0]?.gifts.cantidad,
                "email": token ? token['$email'] : '',
                "precio": {
                    "cantidad": parseFloat(datos[0]?.gifts.precio.cantidad),
                    "moneda": "MXN"
                },
                "precio_habitual": {
                    "cantidad": parseFloat(datos[0]?.gifts.precio_habitual.cantidad),
                    "moneda": "MXN"
                },
                "titulo": datos[0]?.gifts.titulo,
                "imagen": datos[0]?.gifts.imagen,
                "sku": datos[0]?.gifts.sku,
                "precio_sin_iva": {
                    "cantidad": parseFloat(datos[0]?.gifts.precio_sin_iva?.cantidad),
                    "moneda": "MXN"
                },
                "descuento": 0,
                "msi": datos[0]?.msi,
                "id_producto_padre": "" + datos[0]?.id_producto,
                "cubre_envio": datos[0]?.cubre_envio,
                "categoria": datos[0]?.categoria,
                "tipo_envio": datos[0]?.t_envio,
                "precio_envio": datos[0]?.p_envio
            };
        }

        let endponiturl = null;
        let dtossend = null;

        if (buyNow) {
            endponiturl = '/replaceCart';
            dtossend = {
                id_cliente: token ? token['$udi09345'] : '',
                productos: [
                    mainProductData
                ],
            };
        } else {
            endponiturl = '/updateShoppingCart';
            dtossend = mainProductData;
        }

        try {
            dtossend["event_id"] = event_id
            const responseMain = await axios.post(API_URL + endponiturl, dtossend, {
                headers: {
                    Authorization: token['$tk3m09s'],
                    fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
                    fbc: getCookie("_fbc") ? getCookie("_fbc") : null,
                    ttp: getCookie("_ttp") ? getCookie("_ttp") : null,
                },
                timeout: 10000
            });

            if (giftProductData.id_producto) {
                const responseGift = await axios.post(API_URL + "/updateShoppingCart", giftProductData, {
                    headers: { Authorization: token['$tk3m09s'] },
                    timeout: 10000
                });
            }
            if (buyNow) {
                gopay();
                setIsLoading(false);
            } else {
                setIsDialogOpen(true);
            }
        } catch (error) {
            console.error('Ha ocurrido un error al agregar al carrito:', error);

            if (buyNow) {
                setIsLoading(true);
            } else {
                setIsDialogOpen(true);
            }

            const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : [];

            const updateOrAddProduct = (product, quantity) => {
                let productIndex = cartLocal.findIndex(p => p.id_producto === product.id_producto);
                if (productIndex !== -1) {
                    cartLocal[productIndex].cantidad += quantity;
                } else {
                    cartLocal.push(product);
                }
            };

            updateOrAddProduct(mainProductData, selectedOption);

            if (datos[0]?.isGift && datos[0]?.gifts) {
                updateOrAddProduct(giftProductData, datos[0]?.gifts.cantidad);
            }

            localStorage.setItem('cartLocal', JSON.stringify(cartLocal));

            if (buyNow) {
                gopay();
                setIsLoading(false);
            }
        }
    };

    const gopay = () => {
        goToPay(datos[0]?.precio.cantidad, token ? token['$email'] : 'No registrado');
        fetchCarts(token);
        navigate('/Pagos');
    };

    const abrirModal = () => {
        setIsDialogOpen(true);
    };

    const cerrarModal = () => {
        setIsDialogOpen(false);
    };

    const gologin = () => {
        setIsModalFavOpen(false);
        navigate('/login');
    };

    const goRegister = () => {
        setIsModalFavOpen(false);
        navigate('/registermodule');
    };

    const [isModalFavOpen, setIsModalFavOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const addfavorite = async () => {
        setIsLoading(true);

        if (!token) {
            setIsLoading(false);
            setIsModalFavOpen(true);
            return;
        }

        const dataProduct = {
            "id_producto": datos[0]?.id_producto,
            "precio": {
                "cantidad": datos[0]?.precio.cantidad,
                "moneda": "MXN"
            },
            "titulo": datos[0]?.titulo,
            "imagen": datos[0]?.imagen
        };

        const formdata = {
            id_usuario: token['$udi09345'],
            datos_producto: dataProduct
        };

        try {
            let event_id_favorites = getRandomInt()
            formdata["event_id"] = event_id_favorites
            const response = await axios.post(API_URL + "/addProductToFavorites", formdata, { headers: { Authorization: token['$tk3m09s'] }, timeout: 15000 });

            addToFavorites(
                datos[0]?.id_producto,
                datos[0]?.titulo,
                datos[0]?.precio.cantidad,
                token['$email'] ? token['$email'] : 'No registrado',
                event_id_favorites,
                1,
            );

            setIsLoading(false);
            verifcarFavoritos();
        } catch (error) {
            console.log(error);
            console.error('Ha ocurrido un error.');
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out addProductToFavorites');
            }
        }
    };

    const handleDownload = async (invoice_id) => {
        try {
            setIsLoading(true);
            const response = await fetch(`${API_URL}/getSheetProductById?id_producto=${invoice_id}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `Ficha_Tecnica_${invoice_id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-white">
            {isLoading && <CargandoSpinner txtcharge='Cargando datos' />}
            <Suspense fallback={<CargandoSpinner txtcharge='Cargando datos' />}>
                {modalMetodos && <Modallistaproductos onClose={closeModaltMetodos} mesesDisponible={mesesDisponible} />}
                <Dialog isOpen={isDialogOpen} onClose={cerrarModal} producto={datos[0]} />
                <ModalNoFav
                    isOpen={isModalFavOpen}
                    onClose={() => setIsModalFavOpen(false)}
                    onLogin={() => gologin()}
                    onRegister={() => goRegister()}
                />
                <section className="text-gray-600 body-font overflow-hidden">
                    <div className="container px-2 py-5 mx-auto border-t-2 border-gray-300 mt-6">
                        <h2 className="text-sm title-font text-gray-500 tracking-widest lg:hidden">HANTEC </h2>
                        <h1 className="text-gray-900 text-xl title-font font-semibold mb-1 font-robotoch lg:hidden">
                            {datos[0]?.titulo}
                        </h1>
                        <div className='lg:hidden'>
                            {datos[0]?.isGift && datos[0]?.gifts ? (
                                <p className='text-xs text-red-500'><i className="fas fa-gift"></i> <span className='font-black uppercase'>(Llevate de regalo)</span> {datos[0].gifts.titulo}</p>
                            ) : null}
                        </div>
                        <p className='text-sm mt-1 font-black lg:hidden'> {datos[0]?.sku}</p>
                        <div className="lg:w-5/6 mx-auto flex flex-wrap">
                            <ProductImages
                                listaImagenes={listaImagenesTransformada}
                                imagenActiva={imagenActiva}
                                setImagenActiva={setImagenActiva}
                            />
                            <ProductDetails
                                datos={datos}
                                selectedOption={selectedOption}
                                handleChange={handleChange}
                                datoscotizacion={productsend}
                                datosredan={datosredan}
                                gometodoslist={gometodoslist}
                                compartirEnlace={compartirEnlace}
                                abrirmodal={abrirmodalmetodos}
                                addtocar={addProduct}
                                addfavorite={addfavorite}
                                enfavoritos={enfavoritos}
                            />
                        </div>
                    </div>
                </section>
            </Suspense>
            <section className="overflow-hidden">
                <div className="container px-5 py-2 mx-auto">
                    <div className="lg:w-4/5 mx-auto flex flex-wrap border-t-4 border-t border-gray-200">
                        <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                            <div className="mt-1 mb-2">
                                <div className='flex'>
                                    <h2 className='text-[#030303] text-base font-medium ml-2 font-semibold text-base lg:text-3xl font-robotoch'>Detalles</h2>
                                    <h2 className='text-[#030303] text-base ml-2 font-semibold text-base lg:text-3xl font-robotoch'>del Producto</h2>
                                </div>
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                    {datos[0]?.descripcion}
                                </div>
                            </div>
                        </div>
                    </div>
                    {datos[0]?.url_ficha && (
                        <div className='flex justify-center'>
                            <button
                                name='btn-downloadficht'
                                id='btn-downloadficht'
                                onClick={() => handleDownload(datos[0].id_producto)} className='bg-[#e9bf56] py-2 px-10 mt-2 rounded'>
                                <i className="fas fa-download"></i> Descargar ficha tecnica
                            </button>
                        </div>
                    )}
                </div>
            </section>
            <div className="flex flex-wrap justify-center lg:w-4/5 mx-auto items-center md:grid md:grid-cols-2 md:gap-4">
                {listaImagenesTransformada.map((imagen) => (
                    <div key={imagen.id_producto} className="mt-2 lg:flex items-center justify-center">
                        {imagen.titulo === "VideoUrl" ? (
                            <div>
                                <iframe
                                    title={`Video activo: ${imagen.titulo}`}
                                    src={imagen.imagen}
                                    height="560"
                                    width="320"
                                    allowFullScreen
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    frameBorder="0"
                                    className="no-scroll"
                                    scrolling="no"
                                >
                                </iframe>
                            </div>
                        ) : (
                            <div>
                                <LazyLoadImage
                                    width="300"
                                    height="200"
                                    src={imagen.imagen}
                                    alt={imagen.titulo}
                                    className="lg:w-3/4 w-full lg:h-auto h-96 object-cover object-center rounded"
                                    effect="blur"
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <Suspense fallback={<CargandoSpinner txtcharge='Cargando datos' />}>
                <ListaProductos titulo="Productos " alldate='' subtitulo='Relacionados' productos={products} />
            </Suspense>
        </div>
    );
}

export default DatosProducto;
