import React, { useEffect, useState } from 'react';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import CategoriesTable from '../../componentes/Admi/CategoriesTable';
import Pagination from '../../componentes/Admi/Pagination';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import { API_URL } from '../../constants/constants';
import Sidebar from '../../componentes/Admi/Sidebar';


function CategoriesPage() {
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    const navigate = useNavigate();
    const [categoriasList, setCategoriasList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga


    useEffect(() => {
        const getCategories = async () => {
            setIsLoading(true); // Inicia la carga

            try {
                const response = await axios.get(`${API_URL}/getAllCategories`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Ordenar las categorías: primero las activas, luego las inactivas
                const sortedCategories = response.data.sort((a, b) => b.activo - a.activo);

                setCategoriasList(sortedCategories);
                setIsLoading(false); // Finaliza la carga

            } catch (error) {
                console.error("Error al obtener las categorías:", error);
            }
        };
        getCategories();
    }, []);

    const handleRowClick = (categoria) => {
        navigate('/IndividualCategoria', { state: { categoria } });
    };

    const addCategoria = () => {
        navigate('/IndividualCategoria');
    };

    // Paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = categoriasList.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div className='p-4 sm:ml-64'>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            <p className='text-2xl xl:text-3xl font-bold my-5 text-gray-700'>Listado de Categorias de productos</p>


            <div className='w-4/5 ml-5 flex items-end justify-end mt-4 p-2'>
                <button className='bg-[#007bfc] text-white p-2 rounded border px-2 hover:bg-[#1e3d8b] hover:text-white' onClick={addCategoria}>Agregar Categoria</button>
            </div>

            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <div>
                    <CategoriesTable categorias={currentItems} onRowClick={handleRowClick} />
                    <Pagination itemsPerPage={itemsPerPage} totalItems={categoriasList.length} paginate={paginate} currentPage={currentPage} />
                </div>
            )}
        </div>
    );
}

export default CategoriesPage;