import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../componentes/Headers/Header';
import Footer from '../../componentes/footer/Footer';
import { API_URL } from '../../constants/constants';

function ActivateAccount() {
    const { email, act_two } = useParams();
    const [error, setError] = useState(null)

    const navigate = useNavigate();
    
    useEffect(() => {

        return async () => {
            setError(null)
            const sub = act_two.substring(4,8)

            const endpoint = "/validateCode"
            const url = API_URL+endpoint

            const payload = {
                "email": email,
                "code": sub
            }
            try {
                const result = await axios.post(url, payload)
                // console.log(result)
                navigate("/")
            } catch (error) {
                console.log('Ha ocurrido un error')
                setError('Ha ocurrido un error, el enlace no es válido.')
            }
      }
    }, [])
    

    return (
        <div>
            <Header />
            <div className='flex justify-center flex-col items-center h-96 w-auto'>
                <h2>Activando cuenta</h2>
                {error && (<p className='m-2 text-center'>{error}</p>)}
            </div>
            <Footer />
        </div>
    )
}

export default ActivateAccount