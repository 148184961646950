import React, { useState, useEffect } from 'react';
import InputDos from '../inputs/Inputo2';
import validateEmail from '../../utils/validateEmail';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Select from 'react-select';
import { API_URL, customStyles } from '../../constants/constants';
import { signup } from '../../utils/PixelMeta/metaPixelEvents';
import ModalCPrevia from '../modales/ModalCPrevia';
import CargandoSpinner from '../cargacomponetes/CargandoSpinner';
import { useLocation } from 'react-router-dom';
import { data } from 'autoprefixer';
import { getRandomInt } from '../../utils/utils';


function Formulario2({ title1, title2, datosDireccion, goform }) {
    const [colonias, setColonias] = useState([]);
    const [estados, setEstados] = useState([]);
    const [isColoniaSelectDisabled, setIsColoniaSelectDisabled] = useState(true);
    const [modalRegistroPrevio, setModalRegistroPrevio] = useState(false);






    //estadod e la carga 
    const [loading, setLoading] = useState(false);

    const [verificarCorreo, setVerificarCorreo] = useState('');
    // Estado inicial para el formulario
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    const estadoInicial = {
        // nombre: '',
        id_odoo: token?.['$od'] || '',
        id_cliente: token?.['$udi09345'] || '',
        n: '',
        ap: '',
        am: '',
        nombre_usuario: token?.['$name'] || '',
        calle: '',
        noExterior: '',
        noInterior: '',
        colonia: '',
        cp: '',
        ciudad: '',
        id_estado: '',
        pais: 'México', // Puedes predefinir México como país
        telefono: '',
        email: token?.['$email'] || '', // Usa el operador de encadenamiento opcional aquí
        referencia: '',
        direccion_fiscal: false, // Nuevo campo para manejar el estado del botón de radio
        id_direccion: '',
        principal: token ? false : true // Aquí ajustas el valor basado en la existencia de 'token'

    };

    // Estado para manejar los datos del formulario
    const [formulario, setFormulario] = useState(estadoInicial);
    // Efecto para actualizar el estado del formulario cuando se reciben nuevos datosDireccion
    useEffect(() => {
        if (datosDireccion) {
            // console.log('los datos de la direciconson', datosDireccion)
            datosDireccion.email = token?.['$email'] || '';

            if (datosDireccion.principal) {
                datosDireccion.id_odoo = token?.['$od'] || '';
            }

            datosDireccion.nombre_usuario = datosDireccion.nombre_usuario || token?.['$name']

            console.log('Loid datos de riefceicon son ', datosDireccion)

            setFormulario(datosDireccion);
        } else {
            setFormulario(estadoInicial);
        }
    }, [datosDireccion]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormulario(prevState => {
            const newState = { ...prevState, [name]: value };
            if (['n', 'ap', 'am'].includes(name)) {
                newState.nombre_usuario = `${newState.n} ${newState.ap} ${newState.am}`.trim();
            }
            return newState;
        });
    };


    // Función para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();
        // @deprecated
        // if (formulario.nombre == '') {
        //     toastError('Debes ingresar un alias.')
        //     return
        // }

        if (!formulario.n) {
            toastError('Debes ingresar un nombre.');
            return;
        }
        if (!formulario.ap) {
            toastError('Debes ingresar un apellido paterno.')
            return
        }
        if (!formulario.am) {
            toastError('Debes ingresar un apellido materno.')
            return
        }
        if (formulario.nombre_usuario == '') {
            toastError('Debes ingresar un nombre.')
            return
        }
        if (formulario.email == '') {
            toastError('Debes ingresar un correo.')
            return
        }

        if (formulario.cp == '') {
            toastError('Debes ingresar un código postal.')
            return
        }

        if (formulario.colonia == '') {
            toastError('Debes ingresar una colonia.')
            return
        }
        if (formulario.ciudad == '') {
            toastError('Debes ingresar una ciudad.')
            return
        }
        if (formulario.id_estado == '') {
            toastError('Debes ingresar un estado.')
            return
        }
        if (formulario.noExterior == '') {
            toastError('Debes ingresar num exterior.')
            return
        }
        if (formulario.pais == '') {
            toastError('Debes ingresar un pais.')
            return
        }
        if (formulario.calle == '') {
            toastError('Debes ingresar una calle.')
            return
        }
        if (formulario.telefono == '') {
            toastError('Debes ingresar un telefono.')
            return
        }
        // console.log('Enviare', formulario)
        newAdress()

    };

    const camposHabilitados = formulario.cp.length > 0;

    const generarStringAleatorio = () => {
        const longitud = Math.floor(Math.random() * (16 - 8 + 1)) + 8; // Longitud aleatoria entre 8 y 40
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?';
        let resultado = '';
        for (let i = 0; i < longitud; i++) {
            resultado += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        return resultado;
    };

    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const newAdress = async () => {

        setLoading(true)
        let tokenValue = token ? token['$tk3m09s'] : null;

        if (!tokenValue) {
            // No hay token disponible. Procediendo al registro.
            const passAleatorio = generarStringAleatorio();
            if (validateEmail(formulario.email)) {
                toastError('El correo ingresado no es válido.')
                return
            }

            try {
                let event_id = getRandomInt()
                const response = await axios.post(API_URL + '/signup', {
                    nombre: formulario.nombre_usuario,
                    correo: formulario.email,
                    password: passAleatorio,
                    telefono_movil: formulario.telefono,
                    event_id: event_id
                }, { timeout: 20000 });
                let data_user = response.data;
                localStorage.setItem('k-9SMD879na89dN87m', JSON.stringify(data_user));
                //Registro completado.
                tokenValue = data_user['$tk3m09s'];

                // -------- Pixel Meta ---------- //
                signup(
                    formulario.email,
                    event_id
                )

                // Si hay carrito, actualizar con el nuevo token
                actualizarCarritoConToken(data_user);

            } catch (error) {
                console.log('error:', error.response?.data?.error);
                // Si existe error.response.data.error, muestra el modal
                if (error.response?.data?.error) {
                    setVerificarCorreo(formulario.email)
                    setLoading(false)

                    handleVerificarCorreo()
                } else {
                    // Si no hay un mensaje de error específico, muestra un mensaje genérico
                    toastError('Ha ocurrido un error en el registro.');
                    setLoading(false)

                }

                if (error.code === 'ECONNABORTED') {
                    console.log('Tiempo de espera agotado en el registro');
                    setLoading(false)

                }
                return;
            }

        }

        // Operación de añadir dirección con el token       
        try {
            const formularioSinNombreUsuario = { ...formulario };
            // delete formularioSinNombreUsuario.nombre_usuario;

            const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

            formularioSinNombreUsuario['id_odoo'] = token['$od']

            // console.log('Datos de registro enviados: ', formularioSinNombreUsuario)

            const response = await fetch(API_URL + '/setAdress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenValue
                },
                timeout: 10000,
                body: JSON.stringify(formularioSinNombreUsuario)
            });

            if (response.status === 200) {
                goform();
            } else {
                console.error("Error al establecer dirección:", response.statusText);
                setLoading(false)

            }
        } catch (error) {
            console.error("Error al realizar la petición a la API:", error);
            setLoading(false)

            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }

        }
    };

    const actualizarCarritoConToken = (tokenValue) => {
        const userInfo = tokenValue


        if (localStorage.getItem('cartLocal') !== null) {
            var carrito = localStorage.getItem('cartLocal');
            var carritoObj = JSON.parse(carrito);
            carritoObj.forEach(item => {
                item.email = userInfo['$email'];
            });


            var carritoJSON = JSON.stringify(carritoObj);
            var token = userInfo['$tk3m09s'];
            var carritoObj1 = JSON.parse(carritoJSON);
            addToCartFromLogin(carritoObj1, token);
        } else {
            // console.log('Carrito inexistente');
            navigate('/');
        }
    }

    const addToCartFromLogin = async (data, token) => {
        try {
            await axios.post(API_URL + "/updateShoppingCart", data, { timeout: 10000 });
            localStorage.removeItem('cartLocal');
            // Carrito guardado

        } catch (error) {
            console.error('Ha ocurrido un error al anadir productos.')
            if (error.code === 'ECONNABORTED') {
                console.log('Tiempo de epsera agotado anadir productos a carrito.');
            }
        } finally {
            window.location.reload()
        }
    }

    useEffect(() => {
        EstadoConodoo()
        const handler = setTimeout(() => {
            if (formulario.cp) {
                handlePostalCodeChange(formulario.cp);
            } else {
                // Si el CP se limpia, también limpiamos las colonias y reseteamos el select
                setColonias([]);
                setFormulario({ ...formulario, colonia: '' }); // Asegúrate de limpiar la colonia seleccionada
                setIsColoniaSelectDisabled(true); // Deshabilitamos el select hasta que haya un CP válido
            }
        }, 500);

        return () => clearTimeout(handler);
    }, [formulario.cp]);

    const handlePostalCodeChange = async (nuevoCodigoPostal) => {
        // console.log('entre a colonias con ', nuevoCodigoPostal)

        const formData = {
            cp: nuevoCodigoPostal,
        };


        try {
            //  console.log('el try')
            const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getDistricts', formData, { timeout: 10000000 });
            if (response.data && Array.isArray(response.data.data)) {
                // console.log('las Colonias',response.data.data)
                setColonias(response.data.data);

                setIsColoniaSelectDisabled(false);
            } else {
                setColonias([]);
                setIsColoniaSelectDisabled(true);
            }
        } catch (error) {
            console.error("Error al obtener las colonias:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }

            setColonias([]);
            setIsColoniaSelectDisabled(true);
        }
    };

    const selectOcolonias = colonias.map(colonia => ({
        value: `${colonia.text}`,
        label: `${colonia.text}`
    }));

    const handleColoniaChange = (selectedOption) => {
        setFormulario({ ...formulario, colonia: selectedOption.value });
    };

    const handleEstadoChange = (selectedOption) => {
        // convertir selectedOption en entero
        const valorComoEntero = parseInt(selectedOption.value, 10);
        setFormulario({ ...formulario, id_estado: valorComoEntero, estado: selectedOption.label });
    };

    const EstadoConodoo = async () => {
        try {
            const response = await axios.get(API_URL + '/getStatesMexico');
            setEstados(response.data.success); // Asumiendo que response.data es un array de estados
        } catch (error) {
            console.error("Error al obtener los estados: ", error);
        }
    };

    const selectEstado = estados.map(estado => ({
        value: estado.id, // Ajusta estas propiedades según la estructura de tus datos
        label: estado.name
    }));


    const handleCloseModal2 = () => {
        setModalRegistroPrevio(false);
    };

    //validar el codigo padrinoo------------------------------------------------------+-+-+-+-+-+-+-+-+-+-+--+-+-
    const handleVerificarCorreo = async () => {

        const body = JSON.stringify({ correo: formulario.email });
        try {
            const response = await fetch(API_URL + '/verifyAccountGuest', {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },

                body: body
            });
            if (!response.ok) {
                // alert('Credenciales incorrectas');
                // throw new Error(`Error: ${response.status}`);

                toastError('Revisa si tu correo est a correctamente escrito.');
            } else {
                setModalRegistroPrevio(true); // Esto abrirá el modal
            }
            const data = await response.json();
            // Manejar la respuesta. Por ejemplo, guardar el token de sesión, etc.
            // console.log(data);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            toastError('Revisa si tu correo esta correctamente escrito.');
        }
    };

    const recargarpagina = async (data_user) => {

        // console.log("Datos recibidos del modal:", data_user);
        // Espera a que actualizarCarritoConToken termine
        await actualizarCarritoConToken(data_user);



    }

    useEffect(() => {
        // Esto deshabilitará el Select hasta que se ingrese un CP válido

        setIsColoniaSelectDisabled(!formulario.cp);
    }, [formulario.cp]);

    const gohelpCP = () => {
        // console.log('ir a whats app a pedir ayuda')
        const whatsappMessage = `Hola, necesito ayuda para conocer mi código postal y registrarme en https://hantec.us/`;
        const whatsappLink = `https://api.whatsapp.com/send?phone=525593311600&text=${encodeURIComponent(whatsappMessage)}`;

        window.open(whatsappLink, "_blank");

    }



    const handleNoSeMiCodigo = (e) => {
        e.preventDefault();
        gohelpCP();
    };
    return (
        <div className="mb-5  w-full  mx-auto  mx-auto ">



            {loading &&

                <CargandoSpinner txtcharge="Cargando datos, por favor espera..." />
            }

            <ModalCPrevia isOpen={modalRegistroPrevio} onClose={handleCloseModal2} correocliente={verificarCorreo} oncloserefrech={recargarpagina} />

            <div className='flex text-center justify-center lg:justify-start lg:mt-4 mx-auto'>
                <h2 className='font-black  my-3 text-xl lg:text-2xl uppercase text-[#030303]'>{title1}</h2>
                <h2 className='font-black ml-2 my-3 text-xl lg:text-2xl uppercase text-[#1e3d8b]'>{title2}</h2>
            </div>

            <form onSubmit={handleSubmit} className=" w-full px-6  md:px-1 mx-auto lg:w-full lg:mt-20" >
                <div className="  border border-gray-300 px-6 pb-5 rounded-md">

                    <div className='lg:flex  lg:justify-start 	'>
                        {/* @deprecated */}
                        {/* <InputDos
                            label='Alias (casa, oficina)'
                            name="nombre"
                            type="text"
                            value={formulario.nombre}
                            placeholder="Alias"
                            onChange={handleChange}

                        /> */}


                        <InputDos
                            label="Nombre(s)"
                            name="n"
                            type="text"
                            value={formulario.n}
                            onChange={handleChange}
                            placeholder="Ingrese nombre(s)"
                        />
                        <InputDos
                            label="Apeliido paterno"
                            name="ap"
                            type="text"
                            value={formulario.ap}
                            onChange={handleChange}
                            placeholder="Ingrese apellido"
                        />
                        <InputDos
                            label="Apellido materno"
                            name="am"
                            type="text"
                            value={formulario.am}
                            onChange={handleChange}
                            placeholder="Ingrese apellido"
                        />




                    </div>

                    <div className='lg:flex  lg:justify-start 	'>



                        {!token && (




                            <InputDos
                                label="Email"
                                name="email"
                                type="email"
                                value={formulario.email}
                                onChange={handleChange}
                                placeholder="Email"
                            />


                        )}



                    </div>

                    <div className='lg:flex '>



                        <div className="w-full lg:w-1/3 xl:mt-4 relative">

                            <div className="relative xl:ml-2 mt-3 lg:mt-0">
                                <label htmlFor="" className='text-xs lg:text-sm'>Código Postal</label>

                                <input
                                    label='C.P.'
                                    name="cp"
                                    type="number"
                                    className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'
                                    placeholder="Código Postal"
                                    value={formulario.cp}
                                    onChange={handleChange}
                                />
                                <button


                                    name='btn-nosecpfromu2'
                                    id='btn-nosecpfromu2'
                                    onClick={handleNoSeMiCodigo}
                                    className="text-black absolute right-1.5 bottom-2.5  hover:bg-blue-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2 py-2 "
                                >
                                    No se mi código
                                </button>
                            </div>
                        </div>


                        <div className='flex justify-center items-center mt-3 w-full 2xl:mt-4 mx-auto xl:mt-2 lg:  lg:ml-2 lg:w-1/3 lg:mt-1 mr-2'>
                            <div className="w-full mx-auto  ">
                                <p className='text-xs lg:text-sm '>
                                    Colonia
                                </p>
                                <Select
                                    disabled={!camposHabilitados}
                                    options={selectOcolonias}
                                    value={selectOcolonias.find(option => option.value === formulario.colonia)} // Asegúrate de mostrar la opción seleccionada como el valor actual
                                    onChange={handleColoniaChange} // Usar la función de manejo específica para Select
                                    placeholder="Seleccione"
                                    menuPlacement="auto" // o "top" según tu necesidad de UI
                                    styles={customStyles}
                                />
                            </div>

                        </div>

                        <InputDos
                            label='Ciudad'
                            name="ciudad"
                            type="text"
                            value={formulario.ciudad}
                            onChange={handleChange}
                            placeholder="Ciudad"
                            disabled={!camposHabilitados}

                        />
                    </div>


                    <div className='lg:flex '>

                        <div className='flex justify-center mt-3 items-center w-full 2xl:mt-4 mx-auto xl:mt-2 lg:  lg:ml-2 lg:w-1/3 lg:mt-1 md:mt-1 mr-2'>
                            <div className="w-full mx-auto  ">

                                <p className='text-xs lg:text-sm '>

                                    Estado

                                </p>

                                <Select
                                    disabled={!camposHabilitados}
                                    options={selectEstado}
                                    value={selectEstado.find(option => option.value === formulario.id_estado)} // Asegúrate de mostrar la opción seleccionada como el valor actual
                                    onChange={handleEstadoChange} // Usar la función de manejo específica para Select
                                    placeholder="Seleccione"
                                    menuPlacement="auto" // o "top" según tu necesidad de UI
                                    styles={customStyles}

                                />
                            </div>
                        </div>

                        <InputDos
                            disabled={!camposHabilitados}
                            type="text"
                            label='Pais'
                            name="pais"
                            value={formulario.pais}
                            onChange={handleChange}
                            placeholder="País"
                        />
                        <InputDos
                            label='Dirección'
                            type="text"
                            name="calle"
                            value={formulario.calle}
                            placeholder="Calle"
                            onChange={handleChange}
                        />
                    </div>

                    <div className='lg:flex '>
                        <InputDos
                            label='Num. Ext.'
                            type="number"
                            name="noExterior"
                            value={formulario.noExterior}
                            onChange={handleChange}
                            placeholder="Número Exterior"
                        />
                        <InputDos
                            label='Num. Int.'
                            type="number"

                            name="noInterior"
                            value={formulario.noInterior}
                            onChange={handleChange}
                            placeholder="Número Interior"
                        />

                        <InputDos
                            label="Teléfono Contacto"
                            name="telefono"
                            type="tel"
                            value={formulario.telefono}
                            onChange={handleChange}
                            placeholder="Teléfono"
                        />
                    </div>
                    <div className='lg:flex '>


                        <InputDos
                            label="Datos de Referencia"
                            name="referencia"
                            type="text"
                            value={formulario.referencia}
                            onChange={handleChange}
                            placeholder="Referencia"
                        />

                        {
                            token && (
                                <div className="flex items-center mb-4 w-full mx-auto justify-center mt-2 lg:w-1/3 lg:mt-4 lg:mb-0">
                                    <input
                                        id="usarDatosFacturacion"
                                        name="usarDatosFacturacion"
                                        type="checkbox"
                                        checked={formulario.principal}
                                        onChange={() => setFormulario({ ...formulario, principal: !formulario.principal })}
                                        className="w-4 h-10"
                                    />
                                    <label htmlFor="usarDatosFacturacion" className="ml-2 text-sm font-medium">Guardar como dirección principal</label>
                                </div>
                            )
                        }


                    </div>

                </div>

                <div className='flex justify-center mt-2 lg:mt-4 '>

                    <button

                        name='btn-submitformu2'
                        id='btn-submitformu2'

                        type="submit" className='bg-[#e9bf56] font-semibold  py-1 w-1/2 rounded-md'>Guardar</button>

                </div>

            </form>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default Formulario2;
