import React from 'react';
import Logo from '../../assets/Logo1.webp';
import Logo2 from '../../assets/images.webp'
import { useNavigate } from 'react-router-dom';
import { SnipTransactionsNotFinish } from '../../utils/Analitycs/analytics';

function HeaderDistribuidorEspecial({ txtespecial, butonpedios }) {
    let navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };
    const goPedidos = () => {
        SnipTransactionsNotFinish()
        navigate('/Pedidos');
    }
    return (
        <header className="flex items-center justify-between p-4" style={{ borderBottom: '1px solid #152a6e' }}>
            <div className="w-16 ">
                {butonpedios ? (
                    // Si butonpedidos es true, muestra este botón
                    <button

                        name='btn-backhderdistri'
                        id='btn-backhderdistri'
                        onClick={goPedidos} className="text-[#152a6e] flex">
                        <i className="fas fa-chevron-left text-2xl"></i>
                    </button>
                ) : (
                    // Si butonpedidos es false o undefined, muestra este otro botón
                    <button

                        name='btn-texthderdistri'
                        id='btn-texthderdistri'
                        onClick={handleBack} className="text-[#152a6e] flex">
                        <i className="fas fa-chevron-left text-2xl"></i>
                        {txtespecial && <span className="ml-2 text-xs">{txtespecial}</span>}
                    </button>
                )}

            </div>
            <div className="flex-grow flex justify-center">
                <img width="300" height="200"

                    src={Logo}
                    alt="Logo"

                    className="h-10 w-auto lg:h-10"
                />


            </div>
            <div className="w-16">
            </div>
        </header>
    );
}

export default HeaderDistribuidorEspecial;
