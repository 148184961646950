import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import CargandoSpinner from "../cargacomponetes/CargandoSpinner";
import Resumnpagoslist from "./Resumnpagoslist";

// import { payWithTransferBank } from "../../utils/PixelMeta/metaPixelEvents";
import { SnipPaymentMethods } from "../../utils/Analitycs/analytics";

function CheckoutTransferForm({ carrito, instructions, dtotoal }) {

  let navigate = useNavigate();

  const [pagoCompletado, setPagoCompletado] = useState(false); // Nuevo estado para controlar la visibilidad del botón

  const location = useLocation();

  const { token, id, datospadso } = location.state || {};

  const [errorMessage, setErrorMessage] = useState(null);

  const [cargando, setCargando] = useState(false);

  const [quantityProducts, setQuantityProducts] = useState(0)

  // Obsoleto
  const handleSubmit = async (event) => {

    const aux = await datospadso?.carrito_usuario.map((value) => {
      // console.log(value?.cantidad)
      setQuantityProducts(prevValue => prevValue + value?.cantidad)
    })


    setCargando(true); // Iniciar la ventana de carga

    setCargando(false); // Ocultar la ventana de carga después de obtener la respuesta

    // -------- Pixel Meta ---------- //
    // payWithTransferBank(
    //   datospadso?.datos_orden?.email,
    //   datospadso?.datos_orden?.total,

    //   // Data
    //   datospadso,
    //   // quantityProducts
    // )
    // ------------------------------ //
    SnipPaymentMethods('transferencia')

    setPagoCompletado(true); // Actualizamos el estado para mostrar el botón

  };

  const gopedidodetale = () => {
    navigate('/Pedidos');
  }


  return (
    <div>

      {cargando && (
        <CargandoSpinner txtcharge='Generando datos para tranferencia' />

      )}

      <div className='text-center mt-5 lg:w-4/5 mx-auto lg:text-left lg:flex'>
        <h2 className='text-[#1e3d8b] font-black text-lg lg:text-2xl'>CONTINÚA CON TU</h2>
        <h1 className='text-[#030303] font-black text-xl lg:text-2xl lg:ml-2'>TRANSFERENCIA</h1>
      </div>



      <div className="lg:flex lg:w-4/5 mx-auto">

        {instructions && (
          <div className="w-full lg:w-4/5 xl:w-12/12 mx-auto flex justify-center items-center h-full">
            <iframe
              src={instructions}
              frameBorder="0"
              scrolling="no"
              seamless="seamless"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full rounded"
              height={'700px'}
              title="framrpass"
            ></iframe>
          </div>
        )}

        <div className="lg:w-1/2">
          {errorMessage && <div className=" mt-4 text-center text-lg font-robotoch px-5">{errorMessage}</div>}

          <Resumnpagoslist listaCar={carrito} />
          <div className='flex justify-center mt-5 mb-24  '>
            <button
              name='btn-gopedidodetale'
              id='btn-gopedidodetale'
              className='lg:block bg-[#e9bf56] px-5 py-1  w-80 rounded-md hidden' onClick={gopedidodetale}>
              Ir a pedidos
            </button>
          </div>
          <div className="mb-10 h-10 log:hidden">

          </div>

          <div className='fixed ml-28 lg:ml-0 bottom-0 left-1/4 transform -translate-x-1/2 lg:translate-x-0 w-full lg:w-1/2 bg-white border-t-2 lg:border-l-2 lg:border-r-2 border-gray-300 p-4 mx-auto'>
            <div className='flex justify-between items-center w-full'>
              <span className='font-bold text-base lg:mr-2 text-[#757575]'>Total de Productos:</span>
              <span className='font-bold text-base lg:mr-2 text-[#757575]'>${dtotoal.total_productos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN</span>
            </div>
            <div className='flex justify-between items-center w-full'>
              <span className='font-bold text-base lg:mr-2 text-[#757575] '>Costo de envío:</span>
              <span className='font-bold text-base lg:mr-2 text-[#757575] '>${dtotoal.total_envio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN</span>
            </div>
            <div className='flex justify-between items-center w-full mt-1 py-1 border-t-2 border-gray-300'>
              <span className='font-bold text-xl lg:mr-2 text-[#474747]'>Total:</span>
              <span className='font-bold text-xl lg:mr-2 text-[#474747]'>${dtotoal.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN</span>
            </div>

            <div className="lg:hidden flex justify-center mt-4">
              <button
                name='btn-gopedidodetale1'
                id='btn-gopedidodetale1' className=' bg-[#e9bf56] px-5 py-1  w-80 rounded-md ' onClick={gopedidodetale}>
                Ir a pedidos
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default CheckoutTransferForm