import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Tooltip, Title, Legend } from 'chart.js';
import ModalGraficos from './ModalGracios';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Title,
    Legend
);

const MultiLineChart = ({ datalabes, datasets, title }) => {
    const [showModal, setShowModal] = useState(false);

    const data = {
        labels: datalabes,
        datasets: datasets.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data,
            fill: false,
            borderColor: dataset.color || `hsl(${(index * 60) % 360}, 100%, 50%)`,
            tension: 0.1
        })),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Ocultar la leyenda
            },
            title: {
                display: false,
                text: title,
            },
        },
    };

    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                display: true, // Ocultar la leyenda
            },
            title: {
                display: true,
                text: title,
            },
        },
    };

    return (
        <div className="w-full px-4">
            <div className="flex rid grid-flow-col px-4  justify-between mx-auto  items-center">
                <h2 className="text-center text-base font-semibold">
                    {title}
                </h2>
                <button
                    name='btn-seemore7'
                    id='btn-seemore7'
                    onClick={() => setShowModal(true)}
                    className="ml-4 px-2 py-1 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    <i className="fas fa-search-plus"></i>
                </button>
            </div>
            <Line data={data} options={options} />

            <ModalGraficos show={showModal} onClose={() => setShowModal(false)}>
                <Line data={data} options={options2} />
            </ModalGraficos>

        </div>
    );
};

export default MultiLineChart;
