import React from 'react';
import { useLocation } from 'react-router-dom';

import OxxoForm from '../../componentes/pagos/oxxoformularios/OxxoForm';


function PagoOxxo() {
  // const sk = import.meta.env.VITE_STRIPE_SK;
  const location = useLocation();
  const { datospadso } = location.state || {};



  return (
      <OxxoForm />
  );
}

export default PagoOxxo;
