import React from 'react';

function MetodoPago({ mtdopago,title }) {
    return (
        <div className='px-5 mt-5'>
            <div className='px-4 bg-[#f7f7f7] lg:bg-white lg:border-b-2 lg:border-gary-200 lg:mb-2 lg:px-2'>

                <p className='text-[#030303] font-black text-lg'>{title}</p>
            </div>
            <div className='flex bg-[#f7f7f7] '>
                <div className='w-1/6 flex justify-center items-center'>
                    <i className={
                        mtdopago === 'debito' || mtdopago === 'credito' ? "fas fa-credit-card text-xl text-[#757575]" :
                            mtdopago === 'transferencia' ? "fas fa-university text-xl text-[#757575]" :
                                mtdopago === 'oxxo' ? "fas fa-money-bill-wave text-xl text-[#757575]" :
                                    "fas fa-question"
                    }></i>
                </div>
                <div className='w-5/6 p-2'>
                    <p className='text-[#474747] font-black text-lg'>
                        {mtdopago === 'debito' ? 'Pago con tarjeta de débito' :
                            mtdopago === 'credito' ? 'Pago con tarjeta de crédito' :
                                mtdopago === 'transferencia' ? 'Pago con transferencia bancaria' :
                                    mtdopago === 'oxxo' ? 'Pago con depósito en OXXO' :
                                        `Pago con ${mtdopago}`}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MetodoPago;
