import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { API_URL } from '../../constants/constants';
import LineChart from '../../componentes/Admi/Graficos/GraficoLine';
import BarChart from '../../componentes/Admi/Graficos/GraficoBarras';
import DoughnutChart from '../../componentes/Admi/Graficos/GraficoDona';
import TableDto1 from '../../componentes/Admi/Graficos/TableDto1';
import MetodosPagoGrafica from '../../componentes/Admi/Graficos/MetodosPagoGrafica';
import TasaReboteGrafica from '../../componentes/Admi/Graficos/GraficoTasaRebote';
import FechaInput from '../../componentes/inputs/FechaInput';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import MetodosPagoLineChart from '../../componentes/Admi/Graficos/MetodosPagoLineChart';
import MultiLineChart from '../../componentes/Admi/Graficos/MultiLineChart';



function HomeAdmi() {
  const [datosGrafica, setDatosGrafica] = useState({
    fechas: [],
    clicks: [],
    palabrasClave: [],
    tiempoNavegacion: [],
    clickscategorias: [],
    metodosPago: [],
    carritosabandonados: [],
    conversiones: [],
    traficoPlataforma: [],
    tasaRebote: [],
    transaccionesnoconcluidas: [],
    campaign: {}, // <-- Cambiado a un objeto para manejar múltiples campañas
    content: {}




  });

  const normalizarPlataforma = (plataforma) => {
    const plataformasMap = {
      Facebook: 'Facebook', 'facebo web': 'Facebook', 'fb': 'Facebook', 'FB': 'Facebook', 'facebook': 'Facebook',
      google: 'Google',
      ig: 'Instagram',
      an: 'An',
      tiktok: 'Tiktok'
      // Agrega más variantes aquí según sea necesario
    };

    return plataformasMap[plataforma.toLowerCase()] || plataforma;
  };
  const [fechaSeleccionada, setFechaSeleccionada] = useState('');
  const info = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

  //fechas inicales al epzesar el proyecto
  const hoy = new Date();
  const manana = new Date(hoy);
  manana.setDate(hoy.getDate() + 1);
  const hoy7menos = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 5);

  function formatearFecha(fecha) {
    const año = fecha.getFullYear();
    const mes = fecha.getMonth() + 1; // Meses van de 0 a 11
    const dia = fecha.getDate();

    return `${año}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
  }

  const fechaFormateada = formatearFecha(manana);
  const fecha7menosFormateada = formatearFecha(hoy7menos);
  const [dtoIncial, setDtoInicial] = useState(true);

  const [fechaInicio, setFechaInicio] = useState(fecha7menosFormateada);
  const [fechaFin, setFechaFin] = useState(fechaFormateada);

  useEffect(() => {
    if (info) cargarDatos();

  }, []);

  // Actualiza esto dentro de tu useEffect después de cargar y procesar los datos
  useEffect(() => {
    if (datosGrafica.fechas.length > 0) {
      setFechaSeleccionada(datosGrafica.fechas[0]); // Establece una fecha inicial
    }
  }, [datosGrafica.fechas]);

  const cargarDatos = async () => {

    setIsLoading(true); // Inicia la carga

    const formdata = {
      start_date: fechaInicio,
      end_date: fechaFin
    };
    try {
      const response = await axios.post(`${API_URL}/getDataAnalysis`, formdata, { headers: { 'Authorization': info['$tk3m09s'] }, timeout: 500000 });
      // console.log('Datos grafico ', response.data.success)
      procesarDatosParaGrafica(response.data.success);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
    setIsLoading(false); // Inicia la carga

  };


  const procesarDatosParaGrafica = (datos) => {
    const datosProcesados = datos.reduce((acc, item) => {
      const fecha = item.fecha ? item.fecha.split("T")[0] : 'No Disponible'; // Simplificamos la fecha
      if (!acc.fechas.includes(fecha)) {
        acc.fechas.push(fecha);
      }
      acc.clicks.push(item.clicks_landing_page || 0);
      acc.visitas.push(item.visitas_dia || 0);
      acc.clickscategorias.push(item.clicks_categorias || 0);
      acc.carritosabandonados.push(item.carritos_abandonados || 0);
      acc.conversiones.push(item.conversiones || 0);
      acc.transaccionesnoconcluidas.push(item.transacciones_no_concluidas || 0);




      if (!acc.metodosPago[fecha]) {
        acc.metodosPago[fecha] = { oxxo: 0, transferencia: 0, debito: 0, credito: 0 };
      }

      if (item.pagos_metodo) {
        Object.entries(item.pagos_metodo).forEach(([metodo, cantidad]) => {
          if (acc.metodosPago[fecha][metodo] !== undefined) {
            acc.metodosPago[fecha][metodo] += cantidad;
          }
        });
      }
      // Procesar los datos de la campaña
      Object.entries(item.campaign || {}).forEach(([campaña, cantidad]) => {
        // Renombrar campañas numéricas
        const campanaNombre = isNaN(campaña) ? campaña : `campaña-${campaña}`;

        if (!acc.campaign[campanaNombre]) {
          acc.campaign[campanaNombre] = [];
        }
        acc.campaign[campanaNombre].push({ fecha, cantidad });
      });



      // Procesar los datos de content
      Object.entries(item.content || {}).forEach(([contenido, cantidad]) => {
        const contenidoNombre = isNaN(contenido) ? contenido : `content-${contenido}`;
        if (!acc.content[contenidoNombre]) {
          acc.content[contenidoNombre] = [];
        }
        acc.content[contenidoNombre].push({ fecha, cantidad });
      });


      // Inicializa el objeto de tráfico por plataforma si no existe
      if (!acc.traficoPlataforma[fecha]) {
        acc.traficoPlataforma[fecha] = {};
      }

      // Acumula el tráfico por plataforma
      if (item.trafico_plataforma) {
        // console.log('El trafico es de ', item.trafico_plataforma);
        Object.entries(item.trafico_plataforma).forEach(([plataforma, cantidad]) => {
          const plataformaNormalizada = normalizarPlataforma(plataforma);
          if (acc.traficoPlataforma[fecha][plataformaNormalizada] === undefined) {
            acc.traficoPlataforma[fecha][plataformaNormalizada] = 0;
          }
          acc.traficoPlataforma[fecha][plataformaNormalizada] += cantidad;
        });
      }



      if (item.tasa_rebote) {
        acc.tasaRebote[fecha] = {
          contador: item.tasa_rebote.contador,
          porcentaje: item.tasa_rebote.porcentaje
        };
      }

      // Acumula tiempo de navegación por fecha y convierte a minutos
      if (item.tiempo_navegacion) {
        const tiempoEnMinutos = item.tiempo_navegacion / 60; // Conversión a minutos
        if (acc.tiempoNavegacion[fecha]) {
          acc.tiempoNavegacion[fecha] += tiempoEnMinutos;
        } else {
          acc.tiempoNavegacion[fecha] = tiempoEnMinutos;
        }
      } else {
        acc.tiempoNavegacion[fecha] = acc.tiempoNavegacion[fecha] || 0;
      }

      // Manejo de palabras clave (sin cambios)
      if (item.palabra_clave && Object.keys(item.palabra_clave).length > 0) {
        acc.palabrasClave[fecha] = item.palabra_clave;
      } else {
        acc.palabrasClave[fecha] = "No hay palabras";
      }


      //fechas de visualizaciones por hora 

      if (!acc.visualizacionesPorHora) {
        acc.visualizacionesPorHora = {};
      }
      if (!acc.visualizacionesPorHora[fecha]) {
        acc.visualizacionesPorHora[fecha] = {};
      }

      // Procesa las visualizaciones por hora para la fecha actual
      Object.entries(item.visualizaciones_hora || {}).forEach(([hora, cantidad]) => {
        acc.visualizacionesPorHora[fecha][hora] = cantidad;
      });



      return acc;
    }, { fechas: [], clicks: [], visitas: [], palabrasClave: {}, tiempoNavegacion: {}, clickscategorias: [], metodosPago: {}, carritosabandonados: [], conversiones: [], traficoPlataforma: {}, tasaRebote: {}, transaccionesnoconcluidas: [], visualizacionesPorHora: {}, campaign: {}, content: {} });

    setDatosGrafica(datosProcesados);
  };

  const handleVerRango = () => {
    //  console.log(`Fecha de inicio: ${fechaInicio}`);
    // console.log(`Fecha de fin: ${fechaFin}`);
    setDtoInicial(false)
    cargarDatos()
  };


  const campaignDatasets = Object.keys(datosGrafica.campaign).map(campaignName => {
    const campaignData = datosGrafica.campaign[campaignName];
    return {
      label: campaignName,
      data: datosGrafica.fechas.map(fecha => {
        const dataForFecha = campaignData.find(c => c.fecha === fecha);
        return dataForFecha ? dataForFecha.cantidad : 0;
      }),
      color: `hsl(${Math.random() * 360}, 100%, 50%)`
    };
  });

  const contentDatasets = Object.keys(datosGrafica.content).map(contentName => {
    const contentData = datosGrafica.content[contentName];
    return {
      label: contentName,
      data: datosGrafica.fechas.map(fecha => {
        const dataForFecha = contentData.find(c => c.fecha === fecha);
        return dataForFecha ? dataForFecha.cantidad : 0;
      }),
      color: `hsl(${Math.random() * 360}, 100%, 50%)`
    };
  });

  return (
    <div className='p-4 sm:ml-64 '>

      <div className=''>
        <h1 className='text-2xl xl:text-3xl font-bold my-5 text-gray-700'>Bienvenido, {info ? info['$name'] : ''}</h1>

        {dtoIncial ? (
          <h2 className='px-10 text-xl text-gray-700 mt-10'>
            Estadísticas de últimos 5 días
          </h2>
        ) : (
          <h2 className='px-10 text-xl text-gray-700 mt-10'>
            Estadísticas de {fechaInicio} a {fechaFin}
          </h2>
        )}

        <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-2 px-4 py-2 rounded'>
          <FechaInput label="Fecha de inicio" onFechaChange={setFechaInicio} />
          <FechaInput label="Fecha de fin" onFechaChange={setFechaFin} />
          <div>
          <button
            onClick={handleVerRango}
            className="mt-4 px-10 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Ver rango
          </button>

          </div>
        </div>

      </div>
      {isLoading ? (
        <CargandoSpinner txtcharge="Cargando datos" />
      ) : (
        <div className='px-2'>

          <div className='grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5'>

          

          {/* row 1 */}
          {/* <div className='grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5'> */}
            <div className=''>
              <BarChart title='Clicks Landing Page' label='N° Clicks' datalabes={datosGrafica.fechas} datadat={datosGrafica.clicks} />
            </div>

            <div className="">


              <LineChart datalabes={datosGrafica.fechas} datadat={datosGrafica.fechas.map(fecha => datosGrafica.tiempoNavegacion[fecha] || 0)} label='Tiempo de Navegación (minutos)' title='Tiempo de Navegación por Día' />
            </div>


            <TableDto1 datosGrafica={datosGrafica} />

          {/* </div> */}


          {/* row 2 */}

          {/* <div className='grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5'> */}

            <div className=''>

              <div className='w-3/5 mx-auto  p-4'>

                <DoughnutChart datalabes={datosGrafica.fechas} datadat={datosGrafica.visitas} label='N° Visitas' title='Visitas por Día' />
              </div>
            </div>
            <div className=''>


              <LineChart datalabes={datosGrafica.fechas} datadat={datosGrafica.clickscategorias} label='N° clicks' title='Click En Categorias' />

            </div>
            <div className='items-center flex'>
              <MetodosPagoGrafica datosGrafica={datosGrafica} datos1={false} />
            </div>

          {/* </div> */}

          {/* row 3 */}

          {/* <div className='grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5'> */}



            <div className=''>
              <MultiLineChart
                datalabes={datosGrafica.fechas}
                datasets={contentDatasets}
                title='Datos de Contenido por Día'
              />
            </div>


            <div className=''>
              <MultiLineChart
                datalabes={datosGrafica.fechas}
                datasets={campaignDatasets}
                title='Datos de Campaña por Día'
              />
            </div>

            {/* <div className=''>
              <MetodosPagoGrafica datosGrafica={datosGrafica} datos1={true} /> */}
            <div>
              <MetodosPagoLineChart datosGrafica={datosGrafica} datos1={true} />

            </div>

          {/* </div> */}

          {/* row 4 */}
          {/* <div className='grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5'> */}
            <div className=''>

              <div className=''>
                <label htmlFor="fechaSelector">Selecciona una Fecha: </label>
                <select
                  id="fechaSelector"
                  value={fechaSeleccionada}
                  onChange={(e) => setFechaSeleccionada(e.target.value)}
                >
                  {datosGrafica.fechas.map(fecha => (
                    <option key={fecha} value={fecha}>{fecha}</option>
                  ))}
                </select>
              </div>


              {fechaSeleccionada && (
                <div className="w-full  mt-4">
                  <div className=" w-6/6 mx-auto mt-2 mt-2">
                    {
                      // Usando fechaSeleccionada directamente para filtrar y mostrar la gráfica
                      (() => {
                        const horas = datosGrafica.visualizacionesPorHora[fechaSeleccionada] || {};
                        const horasOrdenadas = Object.keys(horas).sort((a, b) => {
                          const partesA = a.split(':').map(num => parseInt(num, 10));
                          const partesB = b.split(':').map(num => parseInt(num, 10));
                          return partesA[0] - partesB[0] || partesA[1] - partesB[1];
                        });

                        const datalabels = horasOrdenadas;
                        const datadat = horasOrdenadas.map(hora => horas[hora]);

                        return (
                          <LineChart
                            datalabes={datalabels}
                            datadat={datadat}
                            title={`Visualizaciones por hora: ${fechaSeleccionada}`}
                          />
                        );
                      })()
                    }
                  </div>
                </div>
              )}




            </div>
            <div className=''>


              <TasaReboteGrafica datosGrafica={datosGrafica} title={'Tasa de rebote'} />



            </div>

            <div className=''>

              <BarChart title='Transacciones no concluidas' label='N° no concluidas' datalabes={datosGrafica.fechas} datadat={datosGrafica.transaccionesnoconcluidas} />


            </div>



          {/* </div> */}


          {/* <div className='grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-5'> */}
            <div className=''>



              <LineChart datalabes={datosGrafica.fechas} datadat={datosGrafica.carritosabandonados} label='N° Carritos Abandonados' title='Carritos Abandonados por dia' />
            </div>
            <div className=''>

              <BarChart title='Conversiones' label='N° Conversiones' datalabes={datosGrafica.fechas} datadat={datosGrafica.conversiones} />


            </div>
          {/* </div> */}

          </div>

        </div>
      )}
    </div>


  );
}

export default HomeAdmi;
