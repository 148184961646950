import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

function ResumenPagosFinal({ listaCar }) {
    const [mostrarTodos, setMostrarTodos] = useState(false);
    // Estado para determinar si el dispositivo es de escritorio
    const [esEscritorio, setEsEscritorio] = useState(window.innerWidth >= 1024);

    // Efecto para escuchar cambios en el tamaño de la ventana y ajustar esEscritorio
    useEffect(() => {
        const handleResize = () => {
            setEsEscritorio(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);
        // Limpiar el event listener al desmontar el componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Efecto para ajustar mostrarTodos basado en esEscritorio
    useEffect(() => {
        setMostrarTodos(esEscritorio);
    }, [esEscritorio]);

    // Determinar qué productos mostrar basado en mostrarTodos
    const productosAMostrar = mostrarTodos ? listaCar : listaCar?.slice(0, 1);
    const totalCantidad = listaCar?.reduce((total, producto) => total + producto.cantidad, 0);

    // Función para alternar mostrarTodos en dispositivos móviles
    const toggleMostrarProductos = () => {
        if (!esEscritorio) {
            setMostrarTodos(!mostrarTodos);
        }
    };

    return (
        <div className=' px-5'>
            <div className='flex justify-center rounded'>
                <div className=" mt-8 rounded  w-full lg:w-full ">
                    <div className='flex justify-between p-3'>
                        <div className=' lg:flex'>
                            <p className='text-[#030303] font-black text-lg'>Productos ({totalCantidad})</p>

                        </div>

                    </div>
                    <div className='p-3 overflow-auto lg:h-60  barra1 mb-5'>
                        <div className=''>
                            {productosAMostrar?.map((producto) => (
                                <CSSTransition key={producto?.id} timeout={500} classNames="item2">
                                    <div className="flex rounded mt-2 justify-between    bg-[#f7f7f7]  lg:p-2">
                                        <img width="300" height="200"
                                            src={producto?.imagen} alt={producto?.nombre} className="w-24 h-24 object-contain rounded" />
                                        <div className='flex  w-4/5 p-2'>
                                            <div className='  w-1/2 flex items-center'>

                                                <h3 className='text-xs lg:text-base text-black font-medium   font-montserrat'>{producto?.nombre ? producto?.nombre : producto?.titulo}</h3>

                                            </div>
                                            <div className='w-1/2 flex '>
                                                <div className='flex items-center justify-center text-sm  w-1/3 lg:text-base font-black  font-montserrat '>
                                                    <p> x{producto?.cantidad}</p>


                                                </div>
                                                <div className='flex justify-center items-center text-sm w-2/3  lg:text-base font-black  font-montserrat text-center'>

                                                    <p>${producto?.precio?.cantidad.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </CSSTransition>
                            ))}
                        </div>
                    </div>
                    {!esEscritorio && (
                        <div className='flex justify-center degradado-azul rounded-b'>
                            <button
                                name='btn-uoresumen'
                                id='btn-uoresumen'
                                className="mx-auto my-2 p-1 rounded text-3xl text-[#1e3d8b]" onClick={toggleMostrarProductos}>
                                <i className={`fas ${mostrarTodos ? "fa-chevron-up" : "fa-chevron-down"} btn-icon`}></i>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ResumenPagosFinal;
