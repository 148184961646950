import React, { useState } from 'react';

function FavoritosLista({ favoritos, onDeleteProducto }) {
  // Estados para la paginación
  const [paginaActual, setPaginaActual] = useState(1);
  const elementosPorPagina = 8;

  // Calcula los índices de los elementos a mostrar
  const indiceDelUltimo = paginaActual * elementosPorPagina;
  const indiceDelPrimero = indiceDelUltimo - elementosPorPagina;

  // Divide los favoritos para mostrar solo los de la página actual
  const favoritosActuales = favoritos.slice(indiceDelPrimero, indiceDelUltimo);

  // Cambia la página actual
  const cambiarPagina = (numeroDePagina) => setPaginaActual(numeroDePagina);

  // Total de páginas
  const totalPaginas = Math.ceil(favoritos.length / elementosPorPagina);

  // Renderiza los botones de paginación
  const renderizarBotonesPaginacion = () => {
    let botones = [];
    for (let i = 1; i <= totalPaginas; i++) {
      botones.push(
        <button key={i} onClick={() => cambiarPagina(i)} className={`px-4 py-2 mx-1 ${paginaActual === i ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}

          name='btn-previuspagefalist'
          id='btn-previuspagefalist'

        >
          {i}
        </button>
      );
    }
    return botones;
  };
  //nueva forma de enrutamietno
  const formatTitleForUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  const goArticlePage = (idProducto, combo, titulo) => {
    if (combo) {
      //  console.log('Es un combo porque viene en:', combo);
      // Si es un combo, redirige a la página del combo con el id del producto
      window.location.href = `/combonum/${idProducto}`;
    } else {
      // console.log('No es combo, es producto');
      // Si no es un combo, redirige a la página del producto con el id del producto
      const titulojunto = formatTitleForUrl(titulo)

      window.location.href = `/product/${titulojunto}/${idProducto}`;
    }
    // Esta es la lógica básica para redirigir a diferentes rutas basadas en la disponibilidad del combo
  };

  return (
    <div className="flex flex-col h-full">
      {/* Contenido principal con scroll */}
      <div className="flex-grow overflow-auto">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 p-2">
          {favoritosActuales.map((favorito, idx) => (
            <div key={idx}
              className="favorito-item p-2 flex lg:flex-col lg:justify-center lg:items-center cursor-pointer mt-2 border-b-2 lg:border-2 border-gray-200">
              <img width="300" height="200"
                src={favorito.imagen} alt={favorito.titulo} className='w-20 h-20 lg:w-40 lg:h-40' onClick={() => goArticlePage(favorito.id_producto, favorito.combo, favorito.titulo)} />
              <div className='mt-1 lg:mt-4 ml-2'>
                <div onClick={() => window.location.href = `/product/${formatTitleForUrl(favorito.titulo)}/${favorito.id_producto}`}>
                  <h4 className='text-sm font-oswald'>{favorito.titulo}</h4>
                  <p className='text-sm font-robotoch mt-2 text-[#1e3d8b] font-semibold'>$ {favorito.precio.cantidad.toLocaleString('es-MX')}</p>
                </div>
                <button name='btn-delfalist'
                  id='btn-delfalist'

                  onClick={() => onDeleteProducto(favorito.id, favorito.id_producto)} className='text-red-500 text-sm'>
                  Eliminar
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Botones de paginación en la parte inferior */}
      <div className="pt-2 pb-2 flex justify-center">
        {renderizarBotonesPaginacion()}
      </div>
    </div>
  );
}

export default FavoritosLista;