import React, { useEffect, useRef, useState } from 'react'

import CheckoutTransferForm from '../../componentes/pagos/CheckoutTransferForm';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import HeaderEspecial from '../../componentes/Headers/HeadeEpecial';
import { API_URL } from '../../constants/constants';
// import { generateTransferBank, payWithTransferBank } from '../../utils/PixelMeta/metaPixelEvents';
import { SnipPaymentMethods } from '../../utils/Analitycs/analytics';
import ModalErrorTransfer from '../../componentes/pagos/modales/ModalErrorTransfer';
import { addPaymentInfo } from '../../utils/PixelMeta/metaPixelEvents';
import { getRandomInt } from '../../utils/utils';


function TransferenciaStripe() {

    const [clientSecret, setClientSecret] = useState('')
    const [instructionsPayment, setInstructions] = useState(false)
    const [quantityProducts, setQuantityProducts] = useState(0)
    const [loading, setLoading] = useState(false)

    const location = useLocation();
    const [showModal, setShowModal] = useState(false); // Estado para controlar la visibilidad del modal

    const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const tk = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const { token, id, datospadso } = location.state || {};
    //console.log('los datos son', datospadso)

    // const stripePromise = loadStripe(datospadso?.pk);
    const actionCreate = useRef(false);

    useEffect(() => {

        const fetchData = async () => {
            const pedido = await getPedidos(datosPerfil['$udi09345'], id);

            // Función para verificar si el link_pago está desactualizado (más de 24 horas)
            const isLinkOutdated = (updatedAt) => {
                const updatedDate = new Date(updatedAt);
                const now = new Date();
                const timeDiff = now - updatedDate;
                const hoursDiff = timeDiff / (1000 * 60 * 60);
                return hoursDiff > 72;
            };
            if (!pedido.link_pago || pedido.link_pago.includes('linkdelete')||   pedido.link_pago.includes('https://u.mitec.com.mx/') || pedido.link_pago.includes('https://payments.stripe.com/oxxo/voucher/') || isLinkOutdated(pedido.updated_at)) {
                 console.log('link_pago no encontrado, llamando a handleSubmit()');

                //   console.log('Validacion...')
                if (tk && !actionCreate.current) {
                    console.log('Creara la orden...')
                    createOrder();

                    actionCreate.current = true;
                }
            }
            else {
              //  console.log('link_pago encontrado:', pedido.link_pago);
                setInstructions(pedido.link_pago);
            }
        };


        fetchData();


    }, [])


    const getPedidos = async (idusuari, idpedido) => {
        try {
            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                },
            };

            let response = await axios.get(`${API_URL}/getOrdersByUser?user_id=${idusuari}&order_id=${idpedido}`, config);
             console.log('-------dasd', response.data.success);
            return response.data.success;
        } catch (error) {
            console.error('Error al obtener EL PEDIDO: ' + error.toString());

            if (error.response.data.error === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }
        }
    };

    const createOrder = async () => {
        setLoading(true)
        // console.log('Cargando...')
        const aux = await datospadso?.carrito_usuario.map((value) => {
            // console.log(value?.cantidad)
            setQuantityProducts(prevValue => prevValue + value?.cantidad)
        })

        // console.log('Creando orden');

        if (tk['$st'] == '') {
            // No se encuentra el cliente
            // console.log('Cliente no encontrado');
            setLoading(false)
            return;
        }


        let order_id;

        if (id) {
            // Si `id` tiene valor, usar ese valor directamente
            order_id = id;
            // console.log('Usando order_id existente: ', order_id);
        } else {


            // Crear pedido
            const payload = {
                "id_usuario": datospadso?.id_usuario,
                "datos_orden": datospadso?.datos_orden,
                "carrito_usuario": datospadso?.carrito_usuario,
                "direccion_usuario": datospadso?.direccion_usuario,
                "metodo_pago": "transferencia"
            }

            // console.log('payload: ', payload);

            try {
                // console.log('El pedido no fue creado...')
                // console.log('Volviendo a generar con: ', datospadso)
                const responseCreateOrder = await axios.post(API_URL + '/createOrder', datospadso, { timeout: 15000 });
                // console.log(responseCreateOrder)

                // Verifica que la orden fue creada exitosamente antes de continuar
                if (responseCreateOrder.status == 200) {
                    order_id = responseCreateOrder.data.status; // Asegúrate de que esto es lo que tu API retorna
                    // console.log('Order id creado: ', order_id)
                } else {
                    // Manejar el caso en que la creación de la orden no fue exitosa
                    // console.log('La creación de la orden falló');
                    return; // Asegúrate de salir de la función si la creación de la orden falló
                }
            } catch (error) {
                // Manejar cualquier error que ocurra durante la creación de la orden
                console.error('Error al crear la orden:', error);
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out createOrder');
                }
                setLoading(false)
                return; // Salir de la función si hubo un error
            }
        }
        const total = datospadso?.datos_orden?.total.toFixed(2);


        // Procede aquí con la creación del PI usando `order_id`
        const dataTransfer = {
            amount: total === "1.00" ? (parseFloat(total) + 10).toFixed(2) : total,
            id_st: tk['$st'],
            id_pedido: order_id,
            id_odoo: datospadso?.sale_order_id, // Id de odoo (order id de la venta)
            invoice_is_required: datospadso?.factura,
            email: datospadso?.datos_orden?.email,
        }

        // console.log('Data transfer: ', dataTransfer);
        let event_id = getRandomInt()

        try {
            dataTransfer["event_id"] = event_id
            const responseCreatePI = await axios.post(API_URL + '/createTransferBank', dataTransfer);
            let pi = responseCreatePI.data.response;
            saveLink(responseCreatePI?.data?.instructions)

            setInstructions(responseCreatePI?.data?.instructions)

            setClientSecret(pi);

            // console.log('hice el pedido con:', order_id)

        } catch (error) {
            // Manejar cualquier error que ocurra durante la creación del PI
            console.error('Error al crear el PI:', error);
            setShowModal(true); // Mostrar el modal en caso de error


        } finally {
            // -------- Pixel Meta ---------- //
            addPaymentInfo(
                datospadso?.datos_orden?.email,
                datospadso?.datos_orden?.total,

                // Data
                datospadso,
                event_id
            )
            // ------------------------------ //
            SnipPaymentMethods('transferencia')

            setLoading(false)
        }
    }

    //guardar link 
    const saveLink = async (link) => {

        try {

            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                }
            };

            const formdata = {
                no_pedido: id,
                link_pago: link
            }

             console.log('El form data para el link es ', formdata)

            const response = await axios.put(API_URL + '/updateOrder', formdata, config);
            //console.log('se guardo tu ', response)


        } catch (error) {
            console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
        }
    }




    return (
        <div>
            <HeaderEspecial butonpedios={true} />
            {!loading
                ? (
                    <CheckoutTransferForm carrito={datospadso?.carrito_usuario} instructions={instructionsPayment} dtotoal={datospadso.datos_orden} />
                )
                // TODO: Cambiar mensaje de cargando por un skeleton con animacion de pulso o algo mas
                : <p>Cargando...</p>
            }

            {showModal && <ModalErrorTransfer onClose={() => setShowModal(false)} />}

        </div>

    )
}

export default TransferenciaStripe