import React from 'react';
import HeaderEspecial from '../../../componentes/Headers/HeadeEpecial';
import { useLocation } from 'react-router-dom';
import Formulario2 from '../../../componentes/formularios/Formulario2';
import { useNavigate } from 'react-router-dom';

function Adrees() {
    const location = useLocation();
    const { adress } = location.state || {}; // Obtiene idadress del estado, si existe

    const navigate = useNavigate();
    const gobackpage = () => {
        navigate(-1);

    };

    const direccion = [

        {
            idDireccion: 1,
            nombreDestinatario: "Jhon Vel",
            calle: "Calle falsa",
            numeroExterior: "123",
            numeroInterior: "2B",
            colonia: "Centro",
            codigoPostal: "42082",
            ciudad: "Pachuca de Soto",
            estado: "Hidalgo",
            pais: "México",
            telefono: "7711234567",
            referencia: 'Casa de un piso con vocho afuera'
        },

    ];

    return (
        <div className="carrito">
            <HeaderEspecial />

            {/* Muestra un mensaje dependiendo de si idadress está presente o no */}
            {adress ? (

                <div className=' lg:w-4/5 mx-auto  '>
                    <Formulario2 title1={'Editar'} title2={'Dirección'} datosDireccion={adress} goform={gobackpage} />
                </div>

            ) : (
                <div className=' lg:w-4/5 mx-auto'>
                <Formulario2 title1={'Agregar'} title2={'Domicilio'} goform={gobackpage} />
                </div>
            )}
        </div>
    );
}

export default Adrees;
