import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useProductStore } from '../../../store/bookStore';
import Footer from '../../../componentes/footer/Footer';
import Header from '../../../componentes/Headers/Header';
import GridLoader from "react-spinners/GridLoader";
import ErrorPage from '../../erorpage/ErrorPage';
import { API_URL } from '../../../constants/constants';

function AllCategories() {
    let navigate = useNavigate();

    const { products, fetchProducts } = useProductStore();


    const [categoriasList, setCategoriasList] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga
    const [hasError, setHasError] = useState(false); // Estado para controlar el error


    const isLoadingRef = useRef(isLoading); // Ref para seguir el estado de carga

    useEffect(() => {

        const getCategories = async () => {
            try {
                const response = await axios.get(API_URL + '/getAllCategories', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });



                const activeCategories = response.data.filter(category => category.activo === true);
                setCategoriasList(activeCategories);

                setIsLoading(false); // Detener el spinner tanto si hay error como si se cargan los datos

                if (response.data === null || response.data.length === 0) {
                    console.log('Los datos son vacíos');
                    setIsLoading(false);
                    setHasError(true);
                } else {
                  //  console.log('Todas las categorías', response.data);
                    setCategoriasList(activeCategories);
                    setIsLoading(false); // Los datos se han cargado correctamente
                }

            } catch (error) {
                console.log("Error al obtener las categorías:", error);
                setHasError(true); // Indicar que hubo un error
            } finally {
                setIsLoading(false); // Detener el spinner tanto si hay error como si se cargan los datos
            }
        };

        getCategories();

        const tk = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

        // -------- Pixel Meta ---------- //
        // viewOurCategories(tk ? tk['$email'] : 'No registrado')

    }, []); // Array de dependencias vacío para que el efecto se ejecute solo una vez


    const goCategories = (categoryId) => {
        fetchProducts(categoryId);

        const tk = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

        // -------- Pixel Meta ---------- //
        // viewSingleCategory(
        //     categoryId,
        //     tk ? tk['$email'] : 'No registrado'
        // )

        navigate(`/Categorias/${categoryId}`);
    };

    const gridClass = categoriasList.length <= 3 ? "justify-center" : "";

    return (
        <div className='flex flex-col justify-between min-h-screen bg-white'>
            <Header />
            <div className='w-full lg:w-4/5 mx-auto mt-4'>
                <h1 className="title-font lg:text-4xl text-2xl mb-4 font-medium text-gray-900 text-left ml-3">
                    Nuestras categorias
                </h1>

                {isLoading ? (
                    <div className="flex flex-col justify-center h-screen items-center text-center flex-grow">
                     <GridLoader
                color="#1A2869"
                size={50}
                speedMultiplier={1}
                width={2.5}
            />
                    </div>
                ) : hasError ? (
                    <ErrorPage />
                ) : (
                    <div className={`grid grid-cols-2 md:grid-cols-4 gap-4 px-2 lg:mt-8 ${gridClass} mb-4`}>
                        {categoriasList.map((category, index) => (
                            <div key={index} className="px-2 py-3 cursor-pointer text-center bg-[#eaeaea] rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg" onClick={() => goCategories(category?.nombre)}>
                                <img width="300" height="200"
 src={category?.imagen} alt={category?.nombre} className="mx-auto w-40 h-40 object-contain  rounded-md" />
                                <p className='mt-2 mb-2 text-lg lg:text-2xl'>{category?.nombre}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Footer />
        </div>

    );
}

export default AllCategories;
