import React from 'react';

function InputDos({ label, name, value, placeholder, onChange, disabled, type }) {

    const handleChange = (e) => {
        let newValue = e.target.value;
        if (type === 'email') {
            newValue = newValue.toLowerCase();
        } else if (type === 'text') {
            // Capitalize the first letter of each word and make the rest lowercase
            newValue = newValue.split(' ').map(word => {
                if (word.length > 0) {
                    const firstChar = word.charAt(0).toUpperCase();
                    const restChars = word.slice(1).toLowerCase();
                    return firstChar + restChars;
                }
                return word;
            }).join(' ');
        }
        onChange({ target: { name: e.target.name, value: newValue } });
    };

    return (
        <div className='w-full mt-4 lg:w-1/3 lg:mx-2'>
            <div className=''>
                <p className='text-xs mb-1 lg:text-sm'>
                    {label}
                </p>
            </div>

            <input
                type={type}
                name={name}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
                className='w-full md:w-4/5 lg:w-full rounded lg:h-10 2xl:h-14 md:h-14 h-14 px-2 border border-gray-300'
            />
        </div>
    );
}

export default InputDos;
