import React from 'react'
import HomeAdmi from './HomeAdmi'
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi'
import Sidebar from '../../componentes/Admi/Sidebar'

function Dashboard() {
    return (        
        <div className=''>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            <HomeAdmi />
        </div>
    )
}

export default Dashboard