import axios from 'axios';
import { useState } from 'react';
import Dialog from '../Dialog';
import { useNavigate } from 'react-router-dom';
import { useProductStore, useProductsCart } from '../../store/bookStore';
import { API_URL } from '../../constants/constants';
import imgHotSale from '../../assets/iconovrnofinal1.webp';
import { goToPay, productToCart } from '../../utils/PixelMeta/metaPixelEvents';
import CargandoSpinner from '../cargacomponetes/CargandoSpinner';
import { getCookie, getRandomInt } from '../../utils/utils';

const ProductoCard = ({ producto }) => {
    const navigate = useNavigate();
    // console.log(producto)
    const { carts, loading, error, fetchCarts } = useProductsCart();
    const { fetchProducts } = useProductStore();

    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

 

    const addProduct = async (buyNow) => {
        let event_id = getRandomInt() // Random number to events
        productToCart(
            producto.id_producto,
            producto.titulo,
            producto.precio.cantidad,
            token && token['$email'] ? token['$email'] : null,
            token && token['$name'] ? token['$name'] : null,
            event_id
        );
        if (buyNow) {
            window.localStorage.removeItem('cartLocal');
        }

        let mainProductData = token && token['$r07sad'] === 'Tienda' ? {
            "id_producto": "" + producto.id_producto,
            "id_odoo": producto.id_odoo,
            "cantidad": 1,
            "email": token ? token['$email'] : '',
            "precio": {
                "cantidad": producto.precio_autozone ? parseFloat(producto.precio_autozone.cantidad) : parseFloat(producto.precio.cantidad),
                "moneda": "MXN"
            },
            "precio_habitual": {
                "cantidad": producto.precio_habitual.cantidad,
                "moneda": "MXN"
            },
            "titulo": producto.titulo,
            "imagen": producto.imagen,
            "sku": producto.sku,
            "precio_sin_iva": {
                "cantidad": producto.precio_az_sin_iva ? parseFloat(producto.precio_az_sin_iva.cantidad) : parseFloat(producto.precio_sin_iva?.cantidad),
                "moneda": "MXN"
            },
            "descuento": 0,
            "msi": 0,
            "categoria": producto.categoria,
        } : {
            "id_producto": "" + producto.id_producto,
            "id_odoo": producto.id_odoo,
            "cantidad": 1,
            "email": token && token['$email'] ? token['$email'] : '',
            "precio": {
                "cantidad": parseFloat(producto.precio.cantidad),
                "moneda": "MXN"
            },
            "precio_habitual": {
                "cantidad": parseFloat(producto.precio_habitual.cantidad),
                "moneda": "MXN"
            },
            "titulo": producto.titulo,
            "imagen": producto.imagen,
            "sku": producto.sku,
            "precio_sin_iva": {
                "cantidad": parseFloat(producto.precio_sin_iva?.cantidad),
                "moneda": "MXN"
            },
            "descuento": producto.descuento,
            "msi": producto.msi,
            "cubre_envio": producto.cubre_envio,
            "categoria": producto.categoria,
            "tipo_envio": producto?.t_envio,
            "precio_envio": producto?.p_envio
        };

        let giftProductData = {};

        if (producto.isGift && producto.gifts) {
            giftProductData = {
                "id_producto": "" + producto.gifts.id_producto,
                "id_odoo": producto.gifts.id_odoo,
                "cantidad": producto.gifts.cantidad,
                "email": token && token['$email'] ? token['$email'] : '',
                "precio": {
                    "cantidad": parseFloat(producto.gifts.precio.cantidad),
                    "moneda": "MXN"
                },
                "titulo": producto.gifts.titulo,
                "imagen": producto.gifts.imagen,
                "sku": producto.gifts.sku,
                "precio_sin_iva": {
                    "cantidad": parseFloat(producto.gifts.precio_sin_iva?.cantidad),
                    "moneda": "MXN"
                },
                "descuento": 0,
                "msi": producto.msi,
                "id_producto_padre": "" + producto.id_producto,
                "cubre_envio": producto.cubre_envio,
                "categoria": producto.categoria,
                "tipo_envio": producto?.t_envio,
                "precio_envio": producto?.p_envio


            };
        }
        let endponiturl = null;
        let dtossend = null;

        if (buyNow) {
            endponiturl = '/replaceCart';
            dtossend = {
                id_cliente: token ? token['$udi09345'] : '',
                productos: [
                    mainProductData
                ],
            };
        } else {
            endponiturl = '/updateShoppingCart';
            dtossend = mainProductData;
        }

        try {
            dtossend["event_id"] = event_id
            const responseMain = await axios.post(API_URL + endponiturl, dtossend, {
                headers: {
                    Authorization: token['$tk3m09s'],
                    fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
                    fbc: getCookie("_fbc") ? getCookie("_fbc") : null,
                    ttp: getCookie("_ttp") ? getCookie("_ttp") : null,
                },
                timeout: 10000
            });

            if (giftProductData.id_producto) {
                const responseGift = await axios.post(API_URL + "/updateShoppingCart", giftProductData, {
                    headers: {
                        Authorization: token['$tk3m09s'],
                        fbp: getCookie("_fbp") ? getCookie("_fbp") : null,
                        fbc: getCookie("_fbc") ? getCookie("_fbc") : null,
                        ttp: getCookie("_ttp") ? getCookie("_ttp") : null,
                    },
                    timeout: 10000
                });
            }

            if (buyNow) {
                gopay();
            } else {
                setIsDialogOpen(true);
            }
        } catch (error) {
            console.error('Error al agregar al carrito:', error);
            setIsLoading(false);
            setIsDialogOpen(true);

            const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : [];
            cartLocal.push(mainProductData);
            if (producto.isGift && producto.gifts) {
                cartLocal.push(giftProductData);
            }
            localStorage.setItem('cartLocal', JSON.stringify(cartLocal));

            if (buyNow) {
                gopay();
            }
        }
    };

    const abrirModal = () => {
        setIsDialogOpen(true);
    };

    const cerrarModal = () => {
        setIsDialogOpen(false);
    };

    const formatTitleForUrl = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };

    const gopay = () => {
        goToPay(producto?.precio?.cantidad, token ? token['$email'] : 'No registrado');
        fetchCarts(token);
        navigate('/Pagos');
    };

    return (
        <div key={producto?.id_producto} className="border rounded-lg p-2 lg:p-4 flex flex-col items-center custom-shadow mr-4 mb-2 transition-transform duration-200 hover:scale-105" style={{ minHeight: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Dialog isOpen={isDialogOpen} onClose={cerrarModal} producto={producto} />

            {isLoading && (
                <CargandoSpinner txtcharge='Preparando Compra' />
            )}

            <a href={`/product/${formatTitleForUrl(producto.titulo)}/${producto.id_producto}`} className='cursor-pointer h-auto relative block'>
                {producto?.imagen ? (
                    <div className='relative'>
                        {/*
                        {producto?.precio_habitual?.cantidad !== producto?.precio?.cantidad && (
                            <div className='absolute right-0 lg:-top-7 -top-5'>
                                <img width="300" height="200"
                                    src={imgHotSale} alt="Hot Sale" className="h-7 lg:h-7 w-auto " />
                            </div>
                        )}*/}
                        <img loading="lazy" width="300" height="200"
                            src={producto?.imagen} alt={producto?.titulo} className="w-48 h-48 lg:w-44 lg:h-44 mt-6 lg:mt-5 object-contain mx-auto" />
                    </div>
                ) : (
                    <div className="flex items-center justify-center w-48 h-48  rounded sm:w-48 dark:bg-gray-700">
                        <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                    </div>
                )}

                {producto?.titulo && (
                    <div className="w-full h-16 lg:mt-10 text-left overflow-hidden text-center">
                        <h2 className="text-lg font-medium text-sm ">{producto?.titulo}</h2>
                    </div>
                )}

                <div className='w-full h-auto  lg:h-1/3 '>
                    {producto?.precio_habitual?.cantidad !== producto?.precio?.cantidad && (
                        <div className='text-[#757575] text-xs mt-2 text-center '>
                            <p>Precio habitual</p>
                            <p className='line-through'>
                                $ {producto?.precio_habitual?.cantidad.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {producto?.precio.moneda}
                            </p>
                        </div>
                    )}
                    <div className={`text-center font-bold h-10 ${producto?.precio_habitual?.cantidad === producto?.precio?.cantidad ? 'text-black mt-16' : 'mt-6 mb-4 text-[#d30711]'}`}>
                        {producto?.precio_habitual?.cantidad !== producto?.precio?.cantidad && (
                            <p className="text-sm">
                                {token && token['$r07sad'] === 'Tienda' ? 'Precio AutoZone' : 'Precio de oferta'}
                            </p>
                        )}
                        <p className={`text-base   lg:text ${producto?.precio_habitual?.cantidad === producto?.precio?.cantidad ? 'mt-20' : ''}`}>
                            $ {token && token['$r07sad'] === 'Tienda' && producto?.precio_autozone?.cantidad ? producto.precio_autozone.cantidad.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : producto?.precio?.cantidad.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            <span className='text-xs ml-1'>
                                {producto?.precio?.moneda}
                            </span>
                        </p>
                    </div>
                </div>
            </a>

            {producto?.stock?.disponible > producto?.stock?.mostrado && (
                <div className='mt-6 flex w-full flex-col justify-center z-10'>
                    <button
                        name='btn-buynowcard'
                        id='btn-buynowcard'
                        onClick={() => addProduct(true)}
                        className='bg-[#e9bf56] px-2 py-1.5 mt-1 uppercase text-xs rounded transition duration-200 ease-in-out hover:bg-[#B99336] hover:font-semibold hover:shadow-lg'
                    >
                        Comprar ahora
                    </button>
                    <button
                        name='btn-addtocart'
                        id='btn-addtocart'
                        onClick={() => addProduct(false)}
                        className='bg-[#1a2869] px-2 py-1.5 text-white mt-3 uppercase text-xs rounded transition duration-200 ease-in-out hover:bg-blue-600 hover:shadow-lg'
                    >
                        Añadir a carrito
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProductoCard;
