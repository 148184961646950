import React from 'react';

const ModalTiemposEntrega = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    const listatiempos = [
        {
            id: 1,
            distanciakm: 1000,
            tiempohoras: 24
        },
        {
            id: 2,
            distanciakm: 2000,
            tiempohoras: 48
        },
        {
            id: 3,  // Cambié el id para ser único
            distanciakm: 3000,
            tiempohoras: 72
        },
        {
            id: 4,  // Cambié el id para ser único
            distanciakm: 3001,
            tiempohoras: 96
        },
    ];

    const calcularDias = (horas) => {
        return (horas / 24).toFixed(0); // Convertir horas a días con un decimal
    };

    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            onClose();
        }
    };

    return (
        <div
            id="modal-overlay"
            className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center"
            onClick={handleOutsideClick}
        >
            <div className="bg-white p-4 rounded" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-lg text-center text-[#1a2869] font-bold mb-4">Tiempos de Entrega</h2>
                <ul className="space-y-3">
                    {listatiempos.map((tiempo) => (
                        <li key={tiempo.id} className="flex justify-between p-2">
                            <span className="text-left bg-[#1a2869] text-white py-2 px-4 rounded">{tiempo.distanciakm} km</span>
                            <span className="ml-20 text-right">{calcularDias(tiempo.tiempohoras)} días ({tiempo.tiempohoras} horas)</span>
                        </li>
                    ))}
                </ul>
                <div className="flex justify-center">
                    <button

                        name='btn-closemodalentrega'
                        id='btn-closemodalentrega'
                        onClick={onClose}
                        className="mt-4 bg-[#e9bf56] text-[#00000] hover:bg-[#1a2869] hover:text-white px-5 py-2 rounded mx-auto"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalTiemposEntrega;
