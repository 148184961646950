import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../constants/constants';
import DialogCombo from '../../../componentes/productos/Combos/DialogCombo';
import { productToCart } from '../../../utils/PixelMeta/metaPixelEvents';


function ComboCard({ combo, goComboDetail, gopay }) {

    // console.log('el combo es ', combo)
    const [isModalOpen, setIsModalOpen] = useState(false);


    ///clis en agregar al carrito 
    const handleAddToCart = async (combo, buyNow) => {
        const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

        // Imprimir detalles de cada producto por separado
        for (const producto of combo.productos) {

            const tituloCompleto = `COMBO ${combo.nombre_combo} - ${producto.titulo}`;


            if (token) {
                // console.log('Con token')
                const dataProduct = {
                    "id_producto": "" + producto.id_producto,
                    "id_odoo": producto.id_odoo,
                    "cantidad": parseFloat(producto.cantidad),
                    "email": token['$email'],
                    "precio": {
                        "cantidad": parseFloat(producto.precio_descuento.cantidad),
                        "moneda": "MXN"
                    },
                    "precio_habitual": {
                        "cantidad": parseFloat(producto.precio.cantidad),
                        "moneda": "MXN"
                    },
                    "titulo": tituloCompleto,
                    "imagen": combo.imagen,
                    "sku": producto.sku,
                    //odoo 
                    "precio_sin_iva": {
                        "cantidad": parseFloat(producto.precio_sin_iva.cantidad),
                        "moneda": "MXN"

                    },
                    "descuento": producto.descuento,
                    "id_combo": combo.id_combo



                }

                try {


                    // console.log('entre al tri con ', dataProduct)
                    const response = await axios.post(API_URL + "/updateShoppingCart", dataProduct, { headers: { Authorization: token['$tk3m09s'] }, timeout: 10000 })


                    // console.log('la espuesa es ', response.data)
                    // -------- Pixel Meta ---------- //
                    productToCart(
                        producto?.id_producto,
                        producto?.titulo,
                        parseFloat(producto.precio.cantidad),
                        token && token['$email'] ? token['$email'] : null,
                        token && token['$name'] ? token['$name'] : null,
                    )
                } catch (error) {
                    console.log(error)
                    console.error('Ha ocurrido un error.')
                    if (error.code === 'ECONNABORTED') {
                        console.log('Request timed out');
                    }

                }

                //Termina


            } else {
                // console.log('no tiene token mano')
                const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : null

                if (cartLocal) {
                    // console.log('Con caririto en local storage')
                    const dataProduct = {
                        "id_producto": "" + producto.id_producto,
                        "id_odoo": producto.id_odoo,
                        "cantidad": parseFloat(producto.cantidad),
                        // "email": token['$email'],
                        "precio": {
                            "cantidad": parseFloat(producto.precio_descuento.cantidad),
                            "moneda": "MXN"
                        },
                        "precio_habitual": {
                            "cantidad": parseFloat(producto.precio.cantidad),
                            "moneda": "MXN"
                        },
                        "titulo": tituloCompleto,
                        "imagen": combo.imagen,
                        "sku": producto.sku,
                        //odoo 
                        "precio_sin_iva": {
                            "cantidad": parseFloat(producto.precio_sin_iva.cantidad),
                            "moneda": "MXN"

                        },
                        "descuento": producto.descuento,
                        "id_combo": combo.id_combo,
                        ...(combo.msi && { "msi": combo.msi }), // Solo incluye msi si dtoCombo.msi tiene un valor válido





                    }


                    //Buscamos si el producto esta en el carrito
                    let findProduct = cartLocal.find(productCart => productCart.id_producto == producto.id_producto)
                    // console.log(findProduct ? true : false)

                    if (findProduct) {
                        //Aumenta la cantidad en el producto
                        findProduct.cantidad = findProduct.cantidad + 1

                        localStorage.setItem('cartLocal', JSON.stringify(cartLocal))

                        productToCart(
                            producto?.id_producto,
                            producto?.titulo,
                            parseFloat(producto?.precio?.cantidad),
                            token && token['$email'] ? token['$email'] : null,
                            token && token['$name'] ? token['$name'] : null,
                        )
                    } else {
                        //Agrega el producto a la lista
                        let newCart = [...cartLocal, dataProduct]

                        productToCart(
                            producto?.id_producto,
                            producto?.titulo,
                            parseFloat(producto?.precio?.cantidad),
                            token && token['$email'] ? token['$email'] : null,
                            token && token['$name'] ? token['$name'] : null,
                        )
                        localStorage.setItem('cartLocal', JSON.stringify(newCart))
                    }


                } else {
                    // console.log('sin carrito en locla strorage.')

                    const dataProduct = [{
                        "id_producto": "" + producto.id_producto,
                        "id_odoo": producto.id_odoo,
                        "cantidad": parseFloat(producto.cantidad),
                        // "email": token['$email'],
                        "precio": {
                            "cantidad": parseFloat(producto.precio_descuento.cantidad),
                            "moneda": "MXN"
                        },
                        "precio_habitual": {
                            "cantidad": parseFloat(producto.precio.cantidad),
                            "moneda": "MXN"
                        },
                        "titulo": tituloCompleto,
                        "imagen": combo.imagen,
                        "sku": producto.sku,
                        //odoo 
                        "precio_sin_iva": {
                            "cantidad": parseFloat(producto.precio_sin_iva.cantidad),
                            "moneda": "MXN"

                        },
                        "descuento": producto.descuento,
                        "id_combo": combo.id_combo,
                        ...(combo.msi && { "msi": combo.msi }), // Solo incluye msi si dtoCombo.msi tiene un valor válido



                    }]

                    productToCart(
                        producto?.id_producto,
                        producto?.titulo,
                        parseFloat(producto?.precio_descuento?.cantidad),
                        token && token['$email'] ? token['$email'] : null,
                        token && token['$name'] ? token['$name'] : null,
                    )

                    localStorage.setItem('cartLocal', JSON.stringify(dataProduct))
                }
            }


        };



        if (buyNow) {
            // Lógica para manejar la compra inmediata
            gopay(combo.totalConDescuento)
        } else {
            // Lógica para solo añadir al carrito
            // console.log('voya a abrir el modalr ')
            // Abre el modal después de procesar todos los productos
            setIsModalOpen(true);

        }


    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className="col-span-2 md:col-span-1 border rounded-lg p-4 flex flex-col items-center custom-shadow mr-4 mb-2 transition-transform duration-200 hover:scale-105" style={{ minHeight: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <DialogCombo isOpen={isModalOpen} onClose={closeModal} comboname={combo.nombre_combo} comboimg={combo.imagen} />

            <div onClick={() => goComboDetail(combo.id_combo)} className="cursor-pointer h-auto block ">
                <img width="300" height="200"
                    src={combo.imagen} alt={combo.nombre_combo} className="w-48 h-48 object-contain rounded mx-auto" />
                <div className=' text-center  lg:mt-8'>
                    <h2 className="text-lg font-medium text-sm ">{combo.nombre_combo}</h2>

                </div>


                <div className=' lg:mt-20 mt-10'>
                    {combo.totalConIva !== combo.totalConDescuento && (
                        <div className="text-gray-600 text-xs text-center">
                            <p>Costo individualmente:</p>
                            <p className="line-through">
                                ${combo.totalConIva.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN
                            </p>
                        </div>
                    )}
                    <p className="text-center font-bold mt-2">Llévatelo por:</p>
                    <p className="font-bold text-center text-red-500">
                        ${combo.totalConDescuento.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN
                    </p>
                </div>
            </div>


            <div className='mt-6 flex w-full  flex-col justify-center z-10'>
                <button


                    name='btn-buynow'
                    id='btn-buynow'
                    className='bg-[#e9bf56] px-2 py-1 mt-1 uppercase text-xs rounded transition duration-200 ease-in-out hover:bg-[#c9a246]'
                    onClick={() => handleAddToCart(combo, true)}
                >
                    Comprar ahora
                </button>
                <button
                    name='btn-handleAddToCart'
                    id='btn-handleAddToCart'
                    className='bg-[#1a2869] px-2 py-1 text-white mt-3 uppercase text-xs rounded transition duration-200 ease-in-out hover:bg-[#151f56]'
                    onClick={() => handleAddToCart(combo, false)}
                >
                    Añadir a carrito
                </button>
            </div>

        </div>


    );
}

export default ComboCard;
