import React, { useEffect, useState } from 'react'; // Importar useState
import InputField from '../../inputs/InputField';
import SubmitButton from '../../botones/SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import validateEmail from '../../../utils/validateEmail';
import { useUserStore } from '../../../store/bookStore';
import { Link } from 'react-router-dom';
import { data } from 'jquery';
import { API_URL } from '../../../constants/constants';
// import { signin, viewSignin } from '../../../utils/PixelMeta/metaPixelEvents';


async function fetchUserInfo(accessToken) {
    const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch user info');
    }

    return await response.json();
}

function LoginForm() {

    const [errorMsg, setError] = useState(null)
    const { setUser, user } = useUserStore();
    // inc()
    function setData() {
        const userData = { name: 'John Doe', email: 'johndoe@example.com' }
        setUser(userData)
    }

    const navigate = useNavigate();

    const [isChecked, setIsChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //Manejo de carga
    const [loading, setLoading] = useState(false)

    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleError = (errorMessage) => {
        toastError(errorMessage)
    }


    const loginInto = async () => {
        setLoading(false)
        setError(null)
        if (email == '') {
            setLoading(false);
            toastError('Es necesario ingresar un correo')
            return
        }

        if (password == '') {
            setLoading(false);
            toastError('Es necesario ingresar una contraseña')
            return
        }

        if (validateEmail(email)) {
            setLoading(false);
            toastError('El correo ingresado no es válido.')
            return
        }

        const body = JSON.stringify({ correo: email, password: password });
        try {
            const response = await fetch(API_URL + '/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });
            if (!response.ok) {

                return response.json().then(errorData => {
                    console.log(errorData)
                    toastError(errorData.error || 'Ha ocurrido un error.')
                });

            } else {
                let data_user = await response.json();
                // inc()

                // -------- Pixel Meta ---------- //
                // signin(email)

                window.localStorage.setItem('k-9SMD879na89dN87m', JSON.stringify(data_user));
                if (localStorage.getItem('cartLocal') !== null) {

                    var carrito = localStorage.getItem('cartLocal');
                    var carritoObj = JSON.parse(carrito);
                    //console.log(carritoObj);
                    carritoObj.forEach(item => {
                        item.email = data_user.$email;
                    });
                    // console.log(carritoObj);
                    var carritoJSON = JSON.stringify(carritoObj);
                    //console.log(carritoJSON);
                    var token = data_user.$tk3m09s;
                    // console.log('El token pa: '+token);
                    var carritoObj1 = JSON.parse(carritoJSON);
                    // console.log(carritoObj1[0]);
                    addToCartFromLogin(carritoObj1, token);
                } else {
                    // console.log('Carrito inexistente');
                    if (data_user['$r07sad'] == 'imda' || data_user['$r07sad'] === 'dkommita') {
                        navigate('/dashboard');
                    } else if (data_user['$r07sad'] === 'order_viewer') {
                        navigate('/list_orders');
                    }
                    else {
                        navigate('/');
                    }
                }
                if (data_user['$r07sad'] == 'imda' || data_user['$r07sad'] === 'dkommita') {
                    navigate('/dashboard');
                }
                else if (data_user['$r07sad'] === 'order_viewer') {
                    navigate('/list_orders');
                } else {
                    navigate('/');
                }
            }
            // const data = await response.json();

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const goRegister = () => {
        navigate('/registermodule')
    }

    const goLoginDistribuidor = () => {
        navigate('/Login-Distribuidor')
    }

    const addToCartFromLogin = async (data, token) => {
        try {
            await axios.post(API_URL + "/updateShoppingCart", data);
            //await fetchCarts(token);
            // console.log(response)
            // console.log('Ahora el carrito es: ', carts)
            localStorage.removeItem('cartLocal');
            navigate('/');
        } catch (error) {
            console.error('Ha ocurrido un error al anadir productos.')
        }
    }

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                const userInfo = await fetchUserInfo(tokenResponse.access_token);
                try {
                    const response = await axios.post(API_URL + '/googleLoggin', { email: userInfo.email });
                    if (response.status == 200) {
                        let data_user = await response.data
                        // -------- Pixel Meta ---------- //
                        // signin(email, 'Google')

                        localStorage.setItem('k-9SMD879na89dN87m', JSON.stringify(data_user))
                        if (localStorage.getItem('cartLocal') !== null) {
                            var carrito = localStorage.getItem('cartLocal');
                            var carritoObj = JSON.parse(carrito);
                            carritoObj.forEach(item => {
                                item.email = userInfo.email;
                            });
                            var carritoJSON = JSON.stringify(carritoObj);
                            var token = response.data['tk3m09s'];
                            var carritoObj1 = JSON.parse(carritoJSON);
                            // console.log(carritoObj1[0]);
                            addToCartFromLogin(carritoObj1, token);
                        } else {
                            // console.log('Carrito inexistente');
                            navigate('/');
                        }

                    } else {
                        toastError('No existe');
                    }

                } catch (error) {
                    toastError('Ocurrio un error');
                    //console.log(error);
                }
            } catch (error) {
            }
        },
        onError: error => console.error('Login failed:', error),
    });

    return (
        <div className="w-full h-full">
            <div className="bg-white p-4  rounded-sm  w-full h-auto lg:mt-1">
                <div className="login-list flex justify-center my-5">
                    <h1 className='font-black text-2xl'>
                        <span className="text-black">INICIAR</span>
                        <span className='text-blue-700'> SESIÓN</span>
                    </h1>
                </div>
                <InputField label="" type="email" placeholder="Correo electrónico" handleChange={handleEmailChange} autoComplete="on" />
                <InputField label="" type="password" placeholder="Contraseña" handleChange={handlePasswordChange} autoComplete="on" />

                <SubmitButton buttonText="INICIAR" handleClic={loginInto} disabledInput={loading} color='#1a2869'
                    textColor='#ffffff'
                    hovercolor='#007bfc'
                    hovertextColor='#ffffff'

                />
                <div className='flex justify-center'>
                    <Link className='text-sky-500 font-extrabold mt-4 underline' to='/recuperarcontrasena'>¿Olvidaste tu contraseña?</Link>
                </div>
                <div className='mt-3 w-64 mx-auto'>
                    <button

                        name='btn-lgoin'
                        id='btn-lgoin'

                        className='flex items-center justify-center rounded-xl' onClick={() => login()}>
                        <img width="300" height="200"
                            className="w-1/6 flex-shrink-0" src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png' alt='Google logo'></img>
                    </button>
                </div>
            </div>


            {/* <SubmitButton buttonText="Iniciar Sesión" onClick={handleLogin} /> */}
            <div className="bg-white p-4 rounded-sm">
                <div className="login-list flex justify-center my-5">
                    <h1 className='font-black text-2xl'>
                        <span className="text-black">¿ERES</span>
                        <span className='text-blue-700'> NUEVO?</span>
                    </h1>
                </div>
                {/* <CustomLink to="/registermodule" text="No tengo una cuenta. Registrarse" /> */}
                <SubmitButton buttonText="REGÍSTRATE" handleClic={goRegister} disabledInput={loading} color='#e9bf56' textColor='#1a2869'

                    hovercolor='#007bfc'
                    hovertextColor='#ffffff'

                />


            </div>

            {/* <SubmitButton buttonText="Iniciar Sesión" onClick={handleLogin} /> */}
            <div className="bg-white p-1 rounded-sm  flex flex-col items-center">
                <div className="login-list flex justify-center my-2">
                    <h1 className='font-black text-xl'>
                        Soy distribuidor
                    </h1>
                </div>
                {/* <CustomLink to="/registermodule" text="No tengo una cuenta. Registrarse" /> */}
                <button
                    name='btn-goLoginDistribuidor'
                    id='btn-goLoginDistribuidor'
                    className="rounded w-1/2 py-2 bg-[#1a2869] text-white lg:py-3" onClick={goLoginDistribuidor}>
                    Iniciar como distribuidor
                </button>

            </div>


            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default LoginForm;
