import React from 'react';

function Datosresenas({ listacalculos }) {
    // Verificar si listacalculos está vacío o es null
    if (!listacalculos || listacalculos.length === 0) {
        return <div className="w-full p-4 text-center"></div>;
    }

    // Cantidad de datos
    const cantidadDatos = listacalculos.length;

    // Promedio de calificaciones
    const promedioCalificaciones = listacalculos.reduce((acc, curr) => acc + curr.calificacion, 0) / cantidadDatos;

    // Conteo de calificaciones por rango
    const calificacionesPorRango = {
        '1': listacalculos.filter(item => item.calificacion >= 1 && item.calificacion < 2).length,
        '2': listacalculos.filter(item => item.calificacion >= 2 && item.calificacion < 3).length,
        '3': listacalculos.filter(item => item.calificacion >= 3 && item.calificacion < 4).length,
        '4': listacalculos.filter(item => item.calificacion >= 4 && item.calificacion < 5).length,
        '5': listacalculos.filter(item => item.calificacion === 5).length,
    };

    const estrellasCompletas = Math.floor(promedioCalificaciones);
    const tieneMediaEstrella = promedioCalificaciones % 1 >= 0.5;
    const estrellasVacias = 5 - estrellasCompletas - (tieneMediaEstrella ? 1 : 0);

    // Calcular el progreso de cada rango
    const calcularProgreso = (cantidad) => (cantidad / cantidadDatos) * 100;

    return (
        <div className="w-full p-4">
         <div className='flex justify-center justify-start'>
                <div className=' w-1/4 text-4xl text-center lg:text-5xl text-[#007bfc] font-black font-oswald'>
                    <h2>{promedioCalificaciones.toFixed(2)}</h2>
                </div>
                <div>
                    <div className="flex lg:text-2xl text-lg">
                        {[...Array(estrellasCompletas)].map((_, i) => (
                            <i key={`completa-${i}`} className="fas fa-star text-[#007bfc]"></i>
                        ))}
                        {tieneMediaEstrella && <i className="fas fa-star-half-alt text-[#007bfc]"></i>}
                        {[...Array(estrellasVacias)].map((_, i) => (
                            <i key={`vacia-${i}`} className="far fa-star text-[#007bfc]"></i>
                        ))}
                    </div>
                    <p className='lg:text-base text-sm mt-1'>{cantidadDatos} Calificaciones</p>
                </div>
            </div>

        <div className="flex flex-col items-center justify-center w-80 mx-auto lg:w-full mt-8">
            {Object.entries(calificacionesPorRango)
             .sort((a, b) => b[0] - a[0]) // Ordena las entradas de calificaciones en orden descendente
             .map(([rango, cantidad]) => (
                <div key={rango} className="mt-2 flex items-center justify-between w-full">
                    <div className="w-4/5 bg-gray-200 rounded lg:h-4 h-2">
                        <div
                            className="bg-blue-600 lg:h-4 h-2 rounded"
                            style={{ width: `${calcularProgreso(cantidad)}%` }}
                        ></div>
                    </div>
                    <div className="w-1/5 text-sm text-end">
                        <p><i className="far fa-star"></i> {rango}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
    );
}

export default Datosresenas;
