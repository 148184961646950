import React, { useState, useEffect } from 'react';
import HeaderEspecial from '../../componentes/Headers/HeadeEpecial';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PaginaUno from '../../componentes/pagos/PaginaUno';
import PaginaDos from '../../componentes/pagos/PaginaDos';
import Imgpago1 from '../../assets/Imgpago1.png'
import axios from 'axios';
import { useProductsCart } from '../../store/bookStore';
import BarraDeProgreso from '../../componentes/pagos/BarraDeProgreso';
import PaginaFinal from './PaginaFinalpago';
import ModalOcurre from '../../componentes/modales/ModalOcurre';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import AlertaWarning from '../../componentes/alertas/AlertaWarning';
import ModalOcurrePro from '../../componentes/modales/ModalOcurredos';
import DirecionPrincipal from '../../componentes/pagos/DirecionPrincipal';
import CargaModal from '../../componentes/pagos/modales/CargaModal';
import Resumnpagoslist from '../../componentes/pagos/Resumnpagoslist';
import { API_URL } from '../../constants/constants';
import PasoFacturacion from './PasoFacturaicon';
import PaginaMetodoPAgo from './PaginaMetodoPAgo';
import PaginaMesesCredito from './PaginaMesesCredito';


function Pagos() {
    //datos generales para el uso 
    const { carts, fetchCarts } = useProductsCart()

    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : null


    const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const navigate = useNavigate();

    //verificar que  se legio ocurre 
    const [esOficinaOcurre, setEsOficinaOcurre] = useState(false);
    const [dataOffice, setdataOffice] = useState({ "officeId": 0, "officeName": "" })

    //verificar el metodo de pago 
    const [metodoPago, setMetodoPago] = useState('')

    //datos temporales de direccion
    const [datosTempolares, setDatosTempolares] = useState(null);

    //datos del cupon
    const [dtoCupon, setDtoCupon] = useState({});

    // Estado para controlar la carga
    const [cargando, setCargando] = useState(false);

    //obtener la direcicon principal o las demas direcicones
    const [direccion, setDireccion] = useState([]);
    const [direccionPrincipal, setDireccionPrincipal] = useState([]);
    const [plazoSeleccionado, setPlazoSeleccionado] = useState([]);

    //en casod e descuento 
    const [totalesDescuentos, setTotalesDescuentos] = useState({
        productosConDescuento: [],
        total: 0,
        totalsinIva: []
    });



    //paginad el proceso 
    const [paginaActual, setPaginaActual] = useState('paginaUno');
    //progreso del pago 
    const [progreso, setProgreso] = useState(0);
    //totla dle envio 
    const [totalEnvio, setTotalEnvio] = useState(null);
    const [totalmotores, setTotalMotores] = useState(0);
    const [direccionEnvio, setDireccionEnvio] = useState([]);

    //Estyadod e la factura
    const [requiereFactura, setRequiereFactura] = useState(false);
    const [fetchCart, setFetchCart] = useState(false)
    //obtener los datos 
    useEffect(() => {
        // Llama a getAdress solo si datosPerfil está definido
        // console.log(fetchCart)
        if (datosPerfil) {
            getAdress();
            fetchCarts(datosPerfil)
            setFetchCart(true)
        }

    }, []); // Lista de dependencias vacía para ejecutar solo al montar el componente

    useEffect(() => {
        // Revisar si hay productos en el carrito
        if (fetchCart) {
            if (carts?.length === 0) {
                if (cartLocal?.length === 0 || cartLocal?.length == undefined) {
                    navigate('/');
                }
            }
        }

    }, [carts])


    // Actualizar el progreso cuando cambia la página
    useEffect(() => {
        switch (paginaActual) {
            case 'paginaUno':
                setProgreso(33);
                break;
            case 'paginaDos':
                setProgreso(66);
                break;
            case 'mesesTarjeta':
                setProgreso(66);
                break;
            case 'paginaFinal':
                setProgreso(100);
                break;
            default:
                setProgreso(0);
        }
    }, [paginaActual]);



    //funcion para un redondeo mas exact 
    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };

    //calcular el total del carrito 
    const calcularTotal = (carmodificado = null) => {
        const auxProducts = carmodificado ? carmodificado : (token ? carts : cartLocal);
        return auxProducts?.reduce((total, producto) => {
            return total + (producto.precio.cantidad * producto.cantidad);
        }, 0);
    };

    //calcular el total sin IVA del carrito 
    const calcularTotalSinIva = (carmodificado = null) => {
        const auxProducts = carmodificado ? carmodificado : (token ? carts : cartLocal);
        return auxProducts?.map(producto => ({
            sku: producto.sku,
            totalSinIva: producto.precio_sin_iva.cantidad,
            descuento: producto.descuento
        }));
    };


    //calcular el total sin IVA del carrito 
    const calcularTotalIndividual = (carmodificado = null) => {
        const auxProducts = carmodificado ? carmodificado : (token ? carts : cartLocal);
        return auxProducts?.map(producto => ({
            sku: producto.sku,
            totalIndividual: producto.precio.cantidad
        }));
    };


    //calcular el total del envio que se cubree 
    const calcularTotalCubreEnvio = () => {
        const auxProducts = token ? carts : cartLocal;

        // console.log('dadad',auxProducts)

        return auxProducts?.reduce((total, producto) => {
            // Asegura que se sume 0 si producto.cubre_envio es undefined o null
            const cubreEnvio = producto.cubre_envio || 0;
            return total + (cubreEnvio * producto.cantidad);
        }, 0);

    };

    //ver los msi 
    const verificarMsi = () => {
        let numeroMsi;
        const auxProducts = token ? carts : cartLocal;

        // Filtrar valores NaN de valoresMsi y convertirlos a números enteros
        const valoresMsiFiltrados = auxProducts.map(producto => parseInt(producto.msi, 10)).filter(val => !isNaN(val));

        // Verificar si hay valores después de filtrar los NaN
        if (valoresMsiFiltrados.length === 0) {
            return null; // Si no hay valores válidos, devolver null
        }
        // console.log('valores del arrelgo msi son', valoresMsiFiltrados)
        // Verificar si todos los valores msi son iguales
        const sonIguales = valoresMsiFiltrados.every((val, i, arr) => val === arr[0]);

        if (sonIguales) {
            // Todos los valores msi son iguales
            numeroMsi = valoresMsiFiltrados[0];
        } else {
            // Al menos un valor msi es diferente
            numeroMsi = Math.min(...valoresMsiFiltrados);
        }

        return numeroMsi;
    };



    let total = calcularTotal();

    let totalsinIva = calcularTotalSinIva()
    let totalIndividual = calcularTotalIndividual()

    const totalCubreenvio = calcularTotalCubreEnvio()
    const losMsison = verificarMsi()
    // console.log('cubre total es', totalCubreenvio)
    // console.log('MSI son', losMsison)

    //elemtos dle paso 1 obtner direcicon --------------------------------------------------------------------------------------------
    // Función asíncrona interna para obtener la dirección
    const getAdress = async () => {

        setCargando(true); // Iniciar carga

        if (!datosPerfil) return; // Salir si datosPerfil no está definido

        try {
            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                },

                timeout: 10000
            };
            const data = {
                'idUsuario': datosPerfil['$udi09345']
            };
            let response = await axios.post(API_URL + "/getAddress", data, config);
            let direccionesArray = Object.values(response.data['direcciones']);
            let direccionesPrincipales = direccionesArray.filter(direccion => direccion.principal === true);
            // Las direcciones principales son -> direccionesPrincipales
            // Las direcciones son -> direccionesArray
            setDireccion(direccionesArray);

            setDireccionPrincipal(direccionesPrincipales)
        } catch (error) {
            console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out getAddress');
            }
        }
        setCargando(false); // Detener carga después de obtener datos o en caso de error
    };

    //ir a agregar direcion nueva
    const goadrees = () => {
        navigate('/adrees');
    };
    // Se recibe el total del envio 
    const manejarTotalEnvio = (total, direccionSeleccionada, storedNeighborhood, idoficna, cp, dtostempolares, cantidadMotor) => {
       // console.log('datosoe', total, storedNeighborhood, idoficna, cp)
        // Vista a paso 2
        setTotalMotores(cantidadMotor)

        const new_total =roundToTwoDecimals( total *(1+.16))
        // console.log('total dle envio*/*/*/*/*/*/* ', new_total)
        setTotalEnvio(new_total.toFixed(2))

        const datosocurre = {

            id_oficnaocurre: idoficna,
            nombreoficina: storedNeighborhood,
            tipoentrega: 'recoger en paquetria de castores',
            cp: cp
        }

        // console.log('La direccion selecionada es:', direccionSeleccionada, datosocurre)
        setEsOficinaOcurre(idoficna > 0);
        setdataOffice({ officeId: idoficna > 0 ? idoficna : 0, officeName: storedNeighborhood })

        const direccionAUsar = direccionSeleccionada ? direccionSeleccionada : datosocurre;

        // Si dtostempolares tiene valor, guardar en estado correspondiente
        if (dtostempolares && Object.keys(dtostempolares).length !== 0) {
            setDatosTempolares(dtostempolares); // Suponiendo que `setDatosTempolares` es la función de estado para guardar dtostempolares
            //      console.log('Datos temporales guardados:', dtostempolares);
        }

        setDireccionEnvio(direccionAUsar)
        setPaginaActual('paginaDos'); // Cambia al paso 2

    };

    const enviarPago = (metodopago) => {
        // console.log('La direccion selecionada es:', metodopago)
        setPlazoSeleccionado([])
        setMetodoPago(metodopago)
        setPaginaActual('paginaFinal'); // Cambia al paso 2

    }

    const creditoContado = (metodopago) => {
        const totalFinalDto = total + totalEnvioFinal;
        // console.log('totelsd', totalFinalDto, totalEnvioFinal)
        // console.log('total padrino', total)
        setMetodoPago('credito');
        const plazo = {
            meses: "1",
            precioMensual: totalFinalDto,
            precioFinal: totalFinalDto,
            msi: false
        };
        setPlazoSeleccionado(plazo);
        setPaginaActual('paginaFinal'); // Cambia directamente a la página final

    }


    const enviarPagofinal = (plazo) => {
        // console.log('*-*-*-plazoi elegido ', plazo)
        setPaginaActual('paginaFinal'); // Cambia al paso 2

        setPlazoSeleccionado(plazo)

    }

    const manejarDatosFormulario = (datos) => {
        // console.log("Requiere factura:", datos.requiereFactura);

        setRequiereFactura(datos.requiereFactura);

        setPaginaActual('paginaFinal'); // Cambia al paso 3

    };

    //navedgacion paginas 
    const regresarAPaginaDos = () => {
        setPaginaActual('paginaDos');
    };

    // Método para regresar a la página uno desde la página dos, si es necesario
    const regresarAPaginaUno = () => {
        setPaginaActual('paginaUno');
    };

    const totalEnvioFinal = Math.max(0, (totalEnvio || 0) - (totalCubreenvio || 0));
 //console.log('el envio es ', totalEnvioFinal, totalCubreenvio, totalEnvio)

    const totalFinal = totalEnvioFinal ? total + totalEnvioFinal : null;

    const aplicarDescuento = (details) => {
        //console.log('datos de descuento:', details);
        // console.log('el carrito es:', carts);

        const { des_cantidad, des_porcentaje, prod_participantes } = details;

        // Aplica el descuento a cada producto en el carrito
        const productosConDescuento = (token ? carts : cartLocal).map(producto => {
            let descuento = 0;
            let precioConDescuento = producto.precio.cantidad;
            let precioSinIvaConDescuento = producto.precio_sin_iva.cantidad;
            let tasaIva = 0.16; // Tasa de IVA (16%)


            if (prod_participantes.includes(producto.id_producto)) {
                if (des_cantidad) {
                    descuento = des_cantidad;
                    // console.log('Eld esceutno es ')
                    precioConDescuento = producto.precio.cantidad - descuento;

                    // console.log('precio descuento con iva ', precioConDescuento)

                    const presiiosiniva1 = roundToTwoDecimals((precioConDescuento) / (1 + tasaIva));

                    const preciosindescuentoiva = roundToTwoDecimals(presiiosiniva1) / (1 - roundToTwoDecimals((producto.descuento / 100)));

                    precioSinIvaConDescuento = roundToTwoDecimals(preciosindescuentoiva)
                    // console.log('dato1', precioSinIvaConDescuento)


                } else if (des_porcentaje) {
                    const descuentoredondeo = (des_porcentaje / 100)
                    // console.log('El descuento redondeas es ', descuentoredondeo)
                    descuento = producto.precio.cantidad * descuentoredondeo;
                    precioConDescuento = roundToTwoDecimals(producto.precio.cantidad * (1-descuentoredondeo));
                    // console.log('preciodesdecueto', precioConDescuento)


                    //quitar iva 
                    const presiiosiniva1 = roundToTwoDecimals((precioConDescuento) / (1 + tasaIva));
                    const preciosindescuentoiva = roundToTwoDecimals(presiiosiniva1 / (1 - (producto.descuento / 100)));

                    precioSinIvaConDescuento = roundToTwoDecimals(preciosindescuentoiva)
                    // console.log('el precio es ', precioSinIvaConDescuento)
                }

                precioConDescuento = roundToTwoDecimals(precioConDescuento);
                precioSinIvaConDescuento = roundToTwoDecimals(precioSinIvaConDescuento);
            }

            return {
                ...producto,
                precio: { ...producto.precio, cantidad: parseFloat(precioConDescuento.toFixed(2)) },
                precio_sin_iva: { ...producto.precio_sin_iva, cantidad: parseFloat(precioSinIvaConDescuento.toFixed(2)) }
            };
        });

        // Imprimir el carrito actualizado
        // console.log('Carrito actualizado:', productosConDescuento);
        const totaldescuento = calcularTotal(productosConDescuento);
        const totalsinIvafrdcuento = calcularTotalSinIva(productosConDescuento);

        setDtoCupon({
            id_cupon: details.id,
            des_cantidad: details.des_cantidad,
            des_porcentaje: details.des_porcentaje,
            nombre: details.nombre,
            prod_participantes: prod_participantes
        });

        setTotalesDescuentos({
            productosConDescuento,
            totaldescuento,
            totalsinIvafrdcuento
        });
    };

    const enviarMeses = (metodopago) => {
        //console.log('se va a ver los meses con tj', metodopago);

        // Establecer el método de pago
        if (metodopago === 'creditocontado') {
            setMetodoPago('credito'); // Establece el método de pago como 'credito' si es 'creditocontado'
        } else {
            setMetodoPago(metodopago);

        }

        const totalFinalDto = total + totalEnvioFinal;
        const totalDescuentoFinal = totalesDescuentos && totalesDescuentos.totaldescuento ? totalesDescuentos.totaldescuento + totalEnvioFinal : totalFinalDto;
        // console.log('totelsd', totalFinalDto, totalEnvioFinal)
        // console.log('total padrino', total)


        if (token['$r07sad'] === 'Tienda' || totalFinalDto < 300 || metodopago === 'creditocontado') {

            // console.log('totelsd', totalFinalDto)
            // Configuración para cuando el token es 'Tienda'
            const plazosProductosSinIva = totalesDescuentos && totalesDescuentos.totalsinIvafrdcuento
                ? totalesDescuentos.totalsinIvafrdcuento.map(producto => ({
                    sku: producto.sku,
                    precioMensualSIVA: producto.totalSinIva,
                    precioFinalSIVA: producto.totalSinIva
                }))
                : totalsinIva.map(producto => ({
                    sku: producto.sku,
                    precioMensualSIVA: producto.totalSinIva,
                    precioFinalSIVA: producto.totalSinIva
                }));


            const plazo = {
                meses: "1",
                precioMensual: totalDescuentoFinal,
                precioFinal: totalDescuentoFinal,

                plazosProductosSinIva: plazosProductosSinIva,
                precioMensualEnvio: totalEnvioFinal,
                precioFinalEnvio: totalEnvioFinal,

                msi: false
            };
            setPlazoSeleccionado(plazo);
            setPaginaActual('paginaFinal'); // Cambia directamente a la página final
        } else {
            // Configuración por defecto si no es 'Tienda'
            setPaginaActual('mesesTarjeta'); // Cambia al paso de seleccionar los meses de tarjeta
        }
    }

    let contenidoPagina;
    //Pasos en base a la pagina qeu se esta
    switch (paginaActual) {
        case 'paginaUno':
            contenidoPagina = (
                <div className='lg:w-4/5 xl:w-4/5  '>
                    <DirecionPrincipal
                        direccion={direccion}
                        onAgregarDireccion={goadrees}
                        ocurredirecto={true}
                        direccionprincial={direccionPrincipal}
                        carts={carts}
                        manejarTotalEnvio={manejarTotalEnvio}
                    />
                </div>
            );
            break;
        case 'paginaDos':
            contenidoPagina = (
                <div className='lg:w-4/5 xl:w-4/5  '>
                    <PaginaFinal
                        totalpago={total}
                        carts={carts}
                        datosPerfil={datosPerfil}
                        totalenvio={totalEnvioFinal}
                        direcciondeenvio={direccionEnvio}
                        requierefactura={requiereFactura}
                        ocurre={esOficinaOcurre}
                        enviarPago={enviarPago}
                        enviarMeses={enviarMeses}
                        creditoContado={creditoContado}
                        aplicarDescuento={aplicarDescuento}
                        totalesDescuentos={totalesDescuentos}


                    />


                </div>);
            break;
        case 'mesesTarjeta':
            contenidoPagina = (
                <div className='lg:w-full '>

                    <PaginaMesesCredito
                        totalesDescuentos={totalesDescuentos}

                        totalpago={total}
                        totalenvio={totalEnvioFinal}
                        mtdopago={metodoPago}
                        enviarPagofinal={enviarPagofinal}
                        losMsison={losMsison}
                        totalsinIva={totalsinIva}

                        totalIndividual={totalIndividual}
                    />

                </div>
            );

            break;
        case 'paginaFinal':
            contenidoPagina = (
                <div className='lg:w-full '>
                    <PaginaMetodoPAgo
                        totalpago={total}
                        carts={carts}
                        datosPerfil={datosPerfil}
                        totalenvio={totalEnvioFinal}
                        //total de los envios 
                        totalEnvioCastores={totalEnvio}
                        totalCubreenvio={totalCubreenvio}

                        direcciondeenvio={direccionEnvio}
                        requierefactura={requiereFactura}
                        ocurre={esOficinaOcurre}
                        office={dataOffice}
                        mtdopago={metodoPago}
                        plazoSeleccionado={plazoSeleccionado}
                        losMsison={losMsison}
                        datosTempolares={datosTempolares}
                        totalmotores={totalmotores}
                        dtoCupon={dtoCupon}
                        totalesDescuentos={totalesDescuentos}


                    />
                </div>
            );

            //PaginaMetodoPAgo
            break;
        default:
            contenidoPagina = <div>Contenido no encontrado</div>;
            break;
    }

    // Renderizado condicional basado en cargando
    if (cargando) {
        return <CargaModal />;
    }


    return (
        <div className="carrito">
            {/*Indica en que paso se van son 1 pasos */}
            <BarraDeProgreso progreso={progreso} irAPaginaUno={regresarAPaginaUno} irAPaginaDos={regresarAPaginaDos} />

            <div className='lg:flex  xl:w-4/5 lg:px-4 mt-5  mx-auto '>

                {contenidoPagina}


                {/* Conditional Rendering Based on paginaActual */}
                {paginaActual !== 'paginaFinal' && (
                    <div className='hidden lg:block lg:w-1/2 xl:w-1/2 pt-2 h-auto '>
                        <Resumnpagoslist listaCar={carts.length > 0 ? carts : cartLocal} />






                        <div className=' w-full px-10  mx-auto mt-5 font-montserrat'>

                            {dtoCupon && Object.keys(dtoCupon).length > 0 && (
                                <div className='flex justify-between'>
                                    <p className="text-green-600 text-md mr-2">Descuento <span className='font-semibold'>{dtoCupon?.nombre}</span>:</p>
                                    <h2 className="text-green-600 text-lg font-semibold">
                                        {dtoCupon?.des_cantidad !== 0
                                            ? `$${dtoCupon.des_cantidad}`
                                            : `${dtoCupon?.des_porcentaje}%`
                                        }
                                    </h2>
                                </div>
                            )}



                            <div className='flex justify-between'>
                                <p className="text-[#757575] text-base mr-2">Total de productos</p>

                                <h2 className="text-[#012a45] text-base">

                                    $ {(totalesDescuentos.totaldescuento || total)?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}


                                </h2>
                            </div>

                            {totalEnvio && <div className='flex justify-between'>
                                <p className="text-[#757575] text-base mr-2">Costo de envío</p>
                                <h2 className="text-[#030303] text-base">
                                    {
                                        totalEnvioFinal > 0 ?
                                            `$${totalEnvioFinal.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                                            'Envío Gratis'
                                    }
                                </h2>



                            </div>}


                            <div className='flex justify-between'>
                                <p className="text-[#474747] text-base mr-2 font-semibold">Total a pagar</p>
                                <h2 className="text-black text-lg font-semibold">
                                    $ {(totalEnvioFinal + (totalesDescuentos.totaldescuento || total)).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h2>
                            </div>




                        </div>

                    </div>
                )}
            </div>

        </div>
    );
}

export default Pagos;
