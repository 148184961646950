import React from 'react';

const DireccionDatosForAdmin = ({ direccionEntrega, ocurre }) => {
    return (
        <div className='bg-white border border-blue-900 shadow-lg w-4/5 mx-auto p-4 rounded '>
               <p className='  shrink-0 px-6 font-black my-2 text-xl text-center text-blue-800'>
               Dirección de entrega
                </p>
        {!ocurre ? (
                <>
                    <div className="flex items-center justify-between w-full mt-4">
                        <p className="text-[#757575] text-md mr-2">Persona que recibe:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.contact_name}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Pedido ocurre:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{ocurre ? "Si" : "No"}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Calle:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.calle}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">No. Exterior:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.noExterior}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">No. Interior:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.noInterior}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Colonia:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.colonia}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Código Postal:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.cp}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Ciudad:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.ciudad}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Estado:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.estado}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">País:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.pais}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Referencia:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.referencia}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Teléfono:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.telefono}</h2>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Pedido ocurre:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{ocurre ? "Si" : "No"}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Oficina:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.nombreoficina}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Identificador de oficina:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">{direccionEntrega?.id_office}</h2>
                    </div>

                    <div className="flex items-center justify-between w-full mt-2">
                        <p className="text-[#757575] text-md mr-2">Tipo de entrega:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">Recoger en paquetería Castores</h2>
                    </div>
                </>
            )}
        </div>
    );
};

export default DireccionDatosForAdmin;
