import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RegisterForm from '../../componentes/sesiones/registerform/RegisterForm';
import Imglogin from '../../assets/fondologin.webp'
import Footer from '../../componentes/footer/Footer';
import HeaderEspecial from '../../componentes/Headers/HeadeEpecial';
import { useBeforeUnload } from '../../utils/Analitycs/analytics';

function Register() {
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // El usuario ha salido de la página
    const location = useLocation();
    const fullPath = location.pathname;
    const routeName = fullPath.split('/')[1];
    useBeforeUnload(routeName);

    return (
        <div className="flex flex-col min-h-screen">
            <div className='lg:flex lg:flex-1'>
                <div className="hidden lg:block lg:w-1/2 lg:h-auto bg-blue-300">
                    <img width="300" height="200"
 className='w-full h-full object-cover' src={Imglogin} alt="Descripción de la imagen" />
                </div>
                <div className='lg:w-1/2 lg:h-auto'>
                    <div className='lg:hidden'>
                        <HeaderEspecial />
                    </div>
                    <RegisterForm />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Register;
