import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function TablaContactos({ datos }) {
    const navigate = useNavigate();
    const [paginaActual, setPaginaActual] = useState(0);
    const elementosPorPagina = 20;

    // Calcula el número total de páginas
    const numeroDePaginas = Math.ceil(datos.length / elementosPorPagina);

    // Cambia la página actual
    const cambiarPagina = (numeroPagina) => {
        setPaginaActual(numeroPagina);
    };

    // Obtén los elementos de la página actual
    const inicio = paginaActual * elementosPorPagina;
    const fin = inicio + elementosPorPagina;
    const elementosPaginaActual = datos.slice(inicio, fin);

    const verDetalles = (contacto) => {
        navigate('/detallescontacto', { state: { contacto } });
    };

    // Función para formatear el número de teléfono
    const formatearTelefono = (telefono) => {
        // Elimina el prefijo +52 si está presente
        const numeroLimpio = telefono.replace(/\+52/, '').replace(/\D/g, ''); // Elimina todos los caracteres no numéricos
        const match = numeroLimpio.match(/^(\d{2})(\d{4})(\d{4})$/); // Ajusta el patrón según el formato deseado
        if (match) {
            return `${match[1]} ${match[2]} ${match[3]}`;
        }
        return telefono; // Devuelve el número original si no coincide con el patrón esperado
    };

    return (
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            N°
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Asunto
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Correo
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Teléfono
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Estatus
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {elementosPaginaActual.map((contacto, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="py-4 px-6">{contacto.id_contacto}</td>
                            <td className="py-4 px-6">{contacto.asunto}</td>
                            <td className="py-4 px-6">{contacto.correo}</td>
                            <td className="py-4 px-6">{formatearTelefono(contacto.telefono)}</td>
                            <td className="py-4 px-6">
                                <div className={`text-white text-center rounded ${contacto.resuelto ? 'bg-green-600' : 'bg-red-600'}`}>
                                    {contacto.resuelto ? 'Resuelto' : 'Sin atender'}
                                </div>
                            </td>
                            <td className="py-4 px-6 text-left">
                                <button

                                    name='btn-detalles'
                                    id='btn-detalles'
                                    onClick={() => verDetalles(contacto)}
                                    className="font-medium text-white bg-[#1e3d8b] px-5 py-2 hover:bg-[#007bfc] rounded-full"
                                >
                                    Detalles
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <ol className="flex justify-end px-5 py-2 gap-1 text-xs font-medium  bg-gray-800">
                <li>
                    <button
                        name='btn-previuspage2'
                        id='btn-previuspage2'
                        onClick={() => cambiarPagina(paginaActual - 1)}
                        disabled={paginaActual === 0}
                        className="inline-flex items-center justify-center rounded  bg-gray-300 text-black p-2 hover:bg-[#007bfc] hover:text-white"
                        aria-label="Prev Page"
                    >
                        <i className="fas fa-angle-double-left"></i>
                    </button>
                </li>
                {Array.from({ length: numeroDePaginas }, (_, index) => (
                    <li key={index}>
                        <button

                            name='btn-actulpage2'
                            id='btn-actulpage2'
                            onClick={() => cambiarPagina(index)}
                            className={`block rounded   text-center px-2 leading-7 hover:bg-[#007bfc] hover:text-white ${paginaActual === index ? 'bg-[#1e3d8b]  text-white ' : 'text-black bg-gray-300'}`}
                        >
                            {index + 1}
                        </button>
                    </li>
                ))}
                <li>
                    <button

                        name='btn-nextpage2'
                        id='btn-nextpage2'
                        onClick={() => cambiarPagina(paginaActual + 1)}
                        disabled={paginaActual === numeroDePaginas - 1}
                        className="inline-flex items-center justify-center rounded  bg-gray-300 text-black p-2 hover:bg-[#007bfc] hover:text-white"
                        aria-label="Next Page"
                    >
                        <i className="fas fa-angle-double-right"></i>
                    </button>
                </li>
            </ol>
        </div>
    );
}

export default TablaContactos;
