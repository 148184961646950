import React from 'react';

function DatosTotal({ totalPrecio, cantidadProductos, plazos, setPlazos, costoencio }) {
    const handlePlazosChange = (e) => {
        setPlazos(Number(e.target.value));
    };
    const primerPago = plazos ? totalPrecio / plazos : 0;
   
    // const totalpeidocconenvio= (costoencio && costoencio > 0 ) ? totalPrecio + costoencio: totalPrecio;
    const totalpeidocconenvio= totalPrecio + (costoencio ? costoencio : 0)

    return (
        <div className="pagosbix px-5 pt-5 rounded ">
            <h3 className='text-black text-xl font-black'>Productos ({cantidadProductos})</h3>
            {plazos > 0 && (
                <div className='mt-1'>
                    <h3 className='text-black text-lg font-semibold'>
                        Pago a  {plazos} meses
                    </h3>

                </div>
            )}

            <div className='flex mt-5 justify-between'>
                <h2 className='mr-5 text-base 	 font-semibold	'>PRODUCTOS: </h2>
                <h3 className=' font-medium text-base	mr-10 font-semibold text-[#007bfc]' >{totalPrecio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN</h3>
             

            </div>

           
       

            


        </div>
    );
}

export default DatosTotal;
