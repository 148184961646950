import React, { useEffect } from 'react';
import imgcons from '../../assets/banner_paginaweb-1024x579.jpg'
import Header from '../../componentes/Headers/Header';
import Footer from '../../componentes/footer/Footer';

const Garantia = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div >
            <Header />
            <div className="flex py-2 flex-col min-h-screen bg-gray-100 px-2">

                <h1 className="text-3xl font-bold  text-left mb-5"> Garantía</h1>
                <img width="300" height="200"

                    src={imgcons} // Asegúrate de tener una imagen de carga apropiada
                    alt="Cargando..."
                    className="w-full h-auto"
                />
            </div>
            <Footer />

        </div>
    );
};

export default Garantia;
