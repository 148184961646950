import React from 'react';

function Pagination({ itemsPerPage, totalItems, paginate, currentPage }) {
  const pageNumbers = [];
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage + 1;
  const actualLastItemIndex = totalItems < lastItemIndex ? totalItems : lastItemIndex;
  const maxPageNumberVisible = 5; // Máximo de números de página visibles a la vez

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Calcular rango de páginas visibles
  let visiblePagesStart = currentPage - Math.floor(maxPageNumberVisible / 2);
  visiblePagesStart = Math.max(visiblePagesStart, 1);
  let visiblePagesEnd = visiblePagesStart + maxPageNumberVisible - 1;
  visiblePagesEnd = Math.min(visiblePagesEnd, pageNumbers.length);

  // Ajustar el inicio si al final hay menos páginas de las esperadas
  if (visiblePagesEnd - visiblePagesStart + 1 < maxPageNumberVisible) {
    visiblePagesStart = Math.max(visiblePagesEnd - maxPageNumberVisible + 1, 1);
  }

  return (
    <div className="flex justify-between w-4/5 items-center mt-4 ml-5 px-2 py-1">
      <span>Mostrando del {firstItemIndex} al {actualLastItemIndex} de {totalItems} elementos</span>

      <nav>
        <ul className="flex list-none">
          {currentPage > 1 && (
            <li>
              <button name='btnprimera' id='btnprimera' onClick={() => paginate(1)} className="px-2 py-2 text-xs mx-1 text-gray-800 rounded hover:bg-gray-200">Primera</button>
            </li>
          )}

          {pageNumbers.slice(visiblePagesStart - 1, visiblePagesEnd).map(number => (
            <li key={number} className="mx-1">
              <button name='corretoage' id='corretoage' onClick={() => paginate(number)} className={`px-3 py-1 ${currentPage === number ? 'bg-blue-700 text-white ' : 'bg-gray-200 text-black'}  rounded hover:bg-blue-700 hover:text-white`}>
                {number}
              </button>
            </li>
          ))}

          {currentPage < pageNumbers.length && (
            <li>
              <button name='btnultima' id='btnultima'  onClick={() => paginate(pageNumbers.length)} className="px-2 py-2 mx-1 text-xs text-gray-800 rounded hover:bg-blue-700 hover:text-white">Última</button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
