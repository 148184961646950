import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Tooltip, Title } from 'chart.js';
import ModalGraficos from './ModalGracios';


// Registra los componentes necesarios de ChartJS
// Luego, registra los componentes importados:
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement, // Registra PointElement
    LineElement, // Registra LineElement
    ArcElement, // Asegúrate de registrar ArcElement aquí
    Tooltip,
    Title
);

const BarChart = ({ datalabes, datadat, label, title }) => {

    const [showModal, setShowModal] = useState(false);


    // Configuración de la gráfica
    const opcionesGrafica = {

        scales: {
            y: {
                beginAtZero: true
            }
        }
    };
    const opcionesGrafica2 = {

        plugins: {
            legend: {
              display: true, // Ocultar la leyenda
            },
            title: {
              display: true,
              text: title,
            },
          },

        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    const datosDeGrafica = {
        labels: datalabes,
        datasets: [
            {
                label: label,
                data: datadat,
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            },
        ],
    };

    return (
        <div className="w-full px-1">
            <div className="flex rid grid-flow-col justify-between px-4  mx-auto  items-center">
                <h2 className="text-center text-base font-semibold">
                    {title}
                </h2>
                <button
                   
                   name='btn-seemore'
                   id='btn-seemore'
                    onClick={() => setShowModal(true)}
                    className="ml-4 px-2 py-1 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    <i className="fas fa-search-plus"></i>
                </button>
            </div>

            <Bar options={opcionesGrafica} data={datosDeGrafica} />
            <ModalGraficos show={showModal} onClose={() => setShowModal(false)}>
                <Bar options={opcionesGrafica2} data={datosDeGrafica} />
            </ModalGraficos>
        </div>
    )
};

export default BarChart;
