import React from 'react';

function DualButton({ leftButtonText, rightButtonText, onLeftButtonClick, onRightButtonClick }) {
    return (
        <div className='flex justify-around	mt-2 lg:w-4/5 mx-auto lg:mt-10'>
            <div>
                <button
                    name='btn-leftButtonText'
                    id='btn-leftButtonText'
                    className=' border-2 border-blue-900 px-5 rounded-md text-blue-900   h-12 w-40' onClick={onLeftButtonClick}>
                    {leftButtonText}
                </button>
            </div>
            <div>
                <button className='bg-[#1a2869] text-white px-5 rounded-md  h-12 w-40' onClick={onRightButtonClick}


                    name='btn-rightButtonText'
                    id='btn-rightButtonText'
                >
                    {rightButtonText}
                </button>
            </div>

        </div>
    );
}

export default DualButton;
