import { useState, useRef, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Minuaturavideo from '../../assets/IndicaVideo.jpg';

const ProductImages = ({ listaImagenes, imagenActiva, setImagenActiva }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const [lastTap, setLastTap] = useState(0);
    const imageContainerRef = useRef(null);

    useEffect(() => {
        if (modalIsOpen) {
            const handleScroll = () => {
                if (window.innerWidth >= 1024) { // 1024px es el ancho mínimo de la vista de escritorio según Tailwind (lg)
                    closeModal();
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [modalIsOpen]);

    const cambiarImagen = (direction) => {
        const currentIndex = listaImagenes.findIndex(item => item.imagen === imagenActiva.imagen);
        let newIndex;
        if (direction === 'next') {
            newIndex = currentIndex + 1 === listaImagenes.length ? 0 : currentIndex + 1;
        } else {
            newIndex = currentIndex - 1 < 0 ? listaImagenes.length - 1 : currentIndex - 1;
        }
        setImagenActiva(listaImagenes[newIndex]);
        setIsZoomed(false);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => cambiarImagen('next'),
        onSwipedRight: () => cambiarImagen('prev'),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setIsZoomed(false);
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    const toggleZoom = () => {
        setIsZoomed(!isZoomed);
    };

    const handleMouseMove = (e) => {
        if (!isZoomed) return;

        const imageContainer = imageContainerRef.current;
        const zoomedImage = imageContainer.querySelector('img');

        const rect = imageContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const offsetX = (x / rect.width) * 100;
        const offsetY = (y / rect.height) * 100;

        zoomedImage.style.transformOrigin = `${offsetX}% ${offsetY}%`;
    };

    const handleTouchEnd = (e) => {
        const currentTime = new Date().getTime();
        const tapLength = currentTime - lastTap;
        if (tapLength < 300 && tapLength > 0) {
            toggleZoom();
        } else {
            setLastTap(currentTime);
        }
    };

    const currentIndex = listaImagenes.findIndex(item => item.imagen === imagenActiva.imagen);

    const modalHandlers = useSwipeable({
        onSwipedLeft: () => cambiarImagen('next'),
        onSwipedRight: () => cambiarImagen('prev'),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div className='w-full lg:w-2/3 lg:flex'>
            {/* Miniaturas para escritorio */}
            <div className='hidden lg:flex flex-col w-24 lg:w-36 px-4 items-center p-2 py-2'>
                {listaImagenes.map((item, index) => (
                    <div key={index} className="p-1 cursor-pointer border-2 mb-2 hover:scale-105 rounded-md transition-transform duration-300" onClick={() => setImagenActiva(item)}>
                        {item.titulo === "VideoUrl" ? (
                            <LazyLoadImage
                                width="300"
                                height="200"
                                src={Minuaturavideo}
                                alt={item.titulo}
                                className="w-32 h-24 mx-auto object-contain"
                                effect="blur"
                            />
                        ) : (
                            <LazyLoadImage
                                width="300"
                                height="200"
                                src={item.imagen}
                                alt={item.titulo}
                                className="w-32 h-24 mx-auto object-contain"
                                effect="blur"
                            />
                        )}
                    </div>
                ))}
            </div>

            {/* Imagen principal */}
            <div {...handlers} className='lg:w-4/5 relative lg:px-4 py-2 lg:py-0 flex justify-center items-center lg:items-start'>
                {
                    imagenActiva.titulo === "VideoUrl" ? (
                        <div className="relative group flex flex-col justify-center items-center">
                            <iframe
                                title={`Video activo: ${imagenActiva.titulo}`}
                                src={imagenActiva.imagen}
                                height="580"
                                width="320"
                                allowFullScreen=""
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                frameBorder="0"
                                className="no-scroll mb-6 xl:mt-20"
                                scrolling="no"
                            >
                            </iframe>
                        </div>
                    ) : (
                        <LazyLoadImage
                            width="300"
                            height="200"
                            src={imagenActiva.imagen}
                            alt={`Imagen activa: ${imagenActiva.titulo}`}
                            className="w-full lg:h-3/5 mx-auto lg:mt-16 h-80 object-contain rounded mb-6 cursor-pointer"
                            effect="blur"
                            onClick={openModal}
                        />
                    )
                }

                {/* Indicadores solo para móviles */}
                <div className="absolute bottom-0 flex justify-center w-full pt-2 mt-14 pb-2 lg:hidden">
                    {listaImagenes.map((item, index) => (
                        <span
                            key={index}
                            className={`mx-1 h-2 w-2 bg-gray-600 rounded-full ${imagenActiva.imagen === item.imagen ? 'bg-opacity-100' : 'bg-opacity-50'}`}
                            onClick={() => setImagenActiva(item)}
                        ></span>
                    ))}
                </div>
            </div>

            {/* Modal para mostrar la imagen en grande */}
            {modalIsOpen && (
                <div {...modalHandlers} onClick={handleBackgroundClick} className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center px-6 lg:p-24 z-50">
                    <div className="relative bg-white rounded-lg shadow-lg p-4 lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center lg:p-10 w-full">
                        <button onClick={closeModal} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl lg:text-3xl font-bold z-50 px-2">
                            <span className="sr-only">Cerrar ventana</span>
                            <i className="fas fa-times"></i>
                        </button>
                        <button onClick={() => cambiarImagen('prev')} className="absolute lg:text-xl left-2 lg:left-1 top-1/2 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 text-gray-700 rounded-full h-10 w-10 flex items-center justify-center z-50">
                            <span className="sr-only">ir a imagen anterior</span>
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <button onClick={() => cambiarImagen('next')} className="z-50 absolute lg:text-xl right-2 lg:right-1 top-1/2 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 text-gray-700 rounded-full h-10 w-10 flex items-center justify-center">
                            <span className="sr-only">ir a imagen siguiente</span>
                            <i className="fas fa-chevron-right"></i>
                        </button>
                        {
                            imagenActiva.titulo === "VideoUrl" ? (
                                <iframe
                                    title={`Video activo: ${imagenActiva.titulo}`}
                                    src={imagenActiva.imagen}
                                    height="580"
                                    width="800"
                                    allowFullScreen=""
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    frameBorder="0"
                                    className="no-scroll mb-6"
                                    scrolling="no"
                                >
                                </iframe>
                            ) : (
                                <div
                                    ref={imageContainerRef}
                                    onDoubleClick={toggleZoom}
                                    onTouchEnd={handleTouchEnd}
                                    onMouseMove={handleMouseMove}
                                    className={`relative ${isZoomed ? 'cursor-grab' : 'cursor-zoom-in'} overflow-hidden`}
                                    style={{ touchAction: isZoomed ? 'none' : 'auto' }}
                                >
                                    <LazyLoadImage
                                        width="800"
                                        height="600"
                                        src={imagenActiva.imagen}
                                        alt={`Imagen activa: ${imagenActiva.titulo}`}
                                        className={`w-full h-full mx-auto object-contain rounded transition-transform duration-300 ${isZoomed ? 'scale-200' : 'scale-100'}`}
                                        style={{ transformOrigin: 'center center' }}
                                    />
                                </div>
                            )
                        }
                        <div className="text-black text-sm lg:text-base flex justify-center text-center font-semibold">
                            <p className='bg-gray-200 rounded-md text-gray-800 px-2'>
                                {currentIndex + 1} / {listaImagenes.length}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductImages;
