import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function CardCarrito({ producto, removeItem, updateQuantity }) {
    // Estado para manejar la cantidad actual del producto
    const [cantidad, setCantidad] = useState(producto?.cantidad);
    const navigate = useNavigate();

    useEffect(() => {
        setCantidad(producto.cantidad)
    }, [updateQuantity])


    // Función para incrementar la cantidad, asegurándose de no superar el máximo de 15
    const incrementar = () => {
        setCantidad((prevCantidad) => (prevCantidad < 15 ? prevCantidad + 1 : prevCantidad));
    };

    // Función para decrementar la cantidad, asegurándose de no bajar del mínimo de 0
    const decrementar = () => {
        setCantidad((prevCantidad) => (prevCantidad > 1 ? prevCantidad - 1 : prevCantidad));
    };

    // Función para manejar el cambio directo en el input, respetando los límites
    const handleInputChange = (e) => {
        const valor = Math.max(0, Math.min(15, Number(e.target.value))); // Asegura que el valor esté entre 0 y 15
        setCantidad(valor);
    };

    // Dentro de CardCarrito
    const lastQuantityRef = useRef(cantidad);
    // console.log('ref: ',lastQuantityRef)
    useEffect(() => {
        // Solo llama a updateQuantity si la cantidad realmente cambió
        if (lastQuantityRef.current !== cantidad) {
            // Llamar a updateQuantity con id_combo si está disponible
            if (producto.id_combo) {
                updateQuantity(producto.id_producto, cantidad, producto.id_combo);
            } else {
                updateQuantity(producto.id_producto, cantidad);
            }
            lastQuantityRef.current = cantidad;
        }
    }, [cantidad, updateQuantity]);
    //nueva forma de enrutamietno
    const formatTitleForUrl = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };

    const goToProduct = () => {

        const titulojunto = formatTitleForUrl(producto.titulo)
        navigate('/Product/' + titulojunto + '/' + producto?.id_producto)
    }

    return (
        <div className='p-3 h-auto overflow-y-auto hover:cursor-pointer'>
            <div className="border border-gray-200 mt-2 rounded-lg p-3 w-90 h-36">
                <div className='flex'>
                    {/* <img width="300" height="200"
 src={producto.imagen} alt={producto.titulo} className="w-20 h-20 object-cover " />
                    <div className='w-80'> */}
                    <img width="300" height="200"
                        onClick={goToProduct} src={producto.imagen} alt={producto.titulo} className="w-20 h-20 object-cover" />
                    <div className="flex-grow ml-2">
                        <div className="text-left">
                            <p onClick={goToProduct} className="text-xs mb-1">{producto?.sku} {producto.titulo}</p>
                            {producto?.precio?.cantidad > 0 && (
                                <button

                                    name='btn-deltee1'
                                    id='btn-deltee1'
                                    onClick={() => removeItem(producto.id_producto, producto.id_combo ? producto.id_combo : undefined)}
                                    className='text-[#d30711] text-xs border-b border-red-500 mb-2'>
                                    ELIMINAR
                                </button>
                            )}

                        </div>

                        {/* <div className='flex justify-between'>
                            <NumberSelect
                                value={producto?.cantidad}
                                onChange={(e) => updateQuantity(producto.id_producto, e.target.value)}
                            /> */}
                        <div className='flex justify-around items-center'>


                            <div className="flex items-center gap-1 ">
                                <button
                                    type="button"
                                    name='btn-quit'
                                    id='btn-quit'

                                    className={`h-10 w-10 leading-10 text-gray-600 transition hover:opacity-75 ${producto?.precio?.cantidad <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={producto?.precio?.cantidad > 0 ? decrementar : undefined}
                                    disabled={producto?.precio?.cantidad <= 0}
                                >
                                    <i className="fas fa-minus"></i>
                                </button>

                                <input
                                    type="number"
                                    value={cantidad}
                                    onChange={handleInputChange}
                                    className="h-10 w-16 rounded border-2 border-gray-200 text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                                    disabled={producto?.precio?.cantidad <= 0}
                                />

                                <button

                                    name='btn-addp'
                                    id='btn-addp'
                                    type="button"
                                    className={`h-10 w-10 leading-10 text-gray-600 transition hover:opacity-75 ${producto?.precio?.cantidad <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={producto?.precio?.cantidad > 0 ? incrementar : undefined}
                                    disabled={producto?.precio?.cantidad <= 0}
                                >
                                    <i className="fas fa-plus"></i>
                                </button>
                            </div>

                            <div onClick={goToProduct} className='text-center'>
                                <p className="font-black lg:text-sm text-xs">
                                    $ {producto?.precio?.cantidad?.toLocaleString('es-MX')} MXN
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default CardCarrito;
