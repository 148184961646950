import axios from "axios"
import { useEffect, useState } from "react";
import { API_URL } from "../../constants/constants";


export const getCurrentDate = () => {
    let date = new Date();

    // Compensar la diferencia de tiempo con la zona horaria de México
    let offset = date.getTimezoneOffset() / 60; // Offset en horas
    let mexicoOffset = -6; // Horario de México sin horario de verano (UTC-6:00)

    // Ajustar la fecha a la zona horaria de México
    date.setHours(date.getHours() + (mexicoOffset - offset));

    // Fecha en formato "YYYY-MM-DD"
    let currentDate = date.toISOString().slice(0, 10);

    return currentDate;
};



export const SnipNavigationTime = async (navigationTime) => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateNavigationTime', { "date": currentDate, "navigation_time": navigationTime })
    } catch (error) {
        console.log(error)
    }
}

// Saber cuánto tiempo ha pasado el usuario en la página
export const usePageTime = () => {
    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() => {
        // Función para ejecutar al salir de la página
        const handleUnload = () => {
            const endTime = Date.now();
            const spentTime = (endTime - startTime) / 1000; // Tiempo en segundos
            // console.log(`El usuario ha pasado ${spentTime} segundos en la página.`);
            SnipNavigationTime(spentTime)
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [startTime]);

    // Función para resetear el tiempo de inicio
    const resetStartTime = () => {
        setStartTime(Date.now());
    };

    return { resetStartTime };
};

export const SnipClicksCategories = async () => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateClicksCategories', { "date": currentDate })
    } catch (error) {
        console.log(error)
    }
}

export const SnipClicksLandingPage = async () => {
    // console.log('Clicks landing page')
    try {
        let currentDate = getCurrentDate()

        axios.post(API_URL + '/updateClicksLandingPage', { "date": currentDate })
    } catch (error) {
        console.log(error)
    }
}

export const SnipViewsPerDay = async () => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateViewsPerDay', { "date": currentDate })
    } catch (error) {
        console.log(error)
    }
}

//Revisar si es la primer visita del dia
export const useFirstVisitOfDay = () => {
    // Obtener la fecha de la última visita desde localStorage
    const lastVisitDateStr = localStorage.getItem('lastVisitDate');
    const lastVisitDate = lastVisitDateStr ? new Date(lastVisitDateStr) : null;
    const today = new Date();

    // Configurar las fechas para comparar solo año, mes y día
    today.setHours(0, 0, 0, 0);
    lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

    // Verificar si hoy es la primera visita del día
    if (!lastVisitDate || today > lastVisitDate) {
        // console.log('Primera visita del día');
        SnipViewsPerDay()
        SnipConversions()
        // Actualizar la fecha de la última visita en localStorage
        localStorage.setItem('lastVisitDate', today.toISOString());
        return true; // Es la primera visita del día
    } else {
        // console.log('No es la primera visita del día');
        return false; // No es la primera visita del día
    }
};


export const SnipTransactionsNotFinish = async () => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateTransactionsNotFinish', { "date": currentDate })
    } catch (error) {
        console.log(error)
    }
}


export const SnipConversions = async () => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateConversions', { "date": currentDate })
    } catch (error) {
        console.log(error)
    }
}

//TODO
export const SnipAbandonedCarts = async () => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateAbandonedCarts', { "date": currentDate })
    } catch (error) {
        console.log(error)
    }
}

export const SnipBounceRate = async () => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateBounceRate', { "date": currentDate })
    } catch (error) {
        console.log(error)
    }
}

// Saber si el tiempo que ha estado en la página es poco
export const usePageLittleTime = () => {
    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() => {
        // Función para ejecutar al salir de la página
        const handleUnload = () => {
            const endTime = Date.now();
            const spentTime = (endTime - startTime) / 1000; // Tiempo en segundos
            // console.log(`El usuario ha pasado ${spentTime} segundos en la página.`);
            if (spentTime < 10) {
                SnipBounceRate(spentTime)
            }
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [startTime]);

    // Función para resetear el tiempo de inicio
    const resetStartTime = () => {
        setStartTime(Date.now());
    };

    return { resetStartTime };
};

export const SnipViewsPerHour = async () => {
    try {
        let currentDate = getCurrentDate()
        let hour = new Date().getHours() + ':00'
        await axios.post(API_URL + '/updateViewsPerHour', { "date": currentDate, "hour": hour })
    } catch (error) {
        console.log(error)
    }
}


export const SnipTrafficPlatform = async (platform, campaign, content) => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateTrafficPlatform', {
            "date": currentDate,
            "platform": platform,
            "campaign": campaign,
            "content": content,
        })
    } catch (error) {
        console.log(error)
    }
}


export const SnipPaymentMethods = async (payment) => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updatePaymentMethods', { "date": currentDate, "payment": payment })
    } catch (error) {
        console.log(error)
    }
}

export const SnipInteractionsState = async (state) => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateInteractionsState', { "date": currentDate, "state": state })
    } catch (error) {
        console.log(error)
    }
}

export const SnipLastView = async (page) => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateLastView', { "date": currentDate, "page": page })
    } catch (error) {
        console.log(error)
    }
}

// Saber cuando un usuario sale de la pagina

export const useBeforeUnload = (routeName) => {

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // El usuario salío de la página
            SnipLastView(routeName)
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [routeName]);
};

export const SnipKeyWordSearched = async (keyword) => {
    try {
        let currentDate = getCurrentDate()

        await axios.post(API_URL + '/updateKeyWord', { "date": currentDate, "key_word": keyword })
    } catch (error) {
        console.log(error)
    }
}

