import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../componentes/Headers/Header';
import Footer from '../../../componentes/footer/Footer';
import axios from 'axios';
import RiseLoader from "react-spinners/RiseLoader";
import FavoritosLista from '../../../componentes/Usercliente/FavoritosLista';
import { API_URL } from '../../../constants/constants';
import { useBeforeUnload } from '../../../utils/Analitycs/analytics';

function Favoritos() {
    let navigate = useNavigate();

    const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    const [favoritos, setFavoritos] = useState([]);

    const [cargandoFavoritos, setCargandoFavoritos] = useState(true);


    useEffect(() => {

        getFavoritos();
        window.scrollTo(0, 0);
    }, []);

    // El usuario ha sálido de la página
    const location = useLocation();
    const fullPath = location.pathname;
    const routeName = fullPath.split('/')[1];
    useBeforeUnload(routeName);


    const getFavoritos = async () => {
        const data = { id_usuario: datosPerfil['$udi09345'] };

        try {
            let response = await axios.post(API_URL + "/getProductListFavorites", data, { headers: { 'Authorization': datosPerfil['$tk3m09s'] }, timeout: 15000 });
            const listaFavoritos = response.data.success.lista_favoritos;

            if (listaFavoritos && Object.keys(listaFavoritos).length > 0) {
                // Transformar el objeto listaFavoritos en un array de objetos con id y contenido
                const favoritosArray = Object.entries(listaFavoritos).map(([id, contenido]) => ({
                    id: parseInt(id), // Convertir el id a número si es necesario
                    ...contenido
                }));
                setFavoritos(favoritosArray);
            } else {
                setFavoritos([]);
            }
            setCargandoFavoritos(false);
        } catch (error) {
            console.error('Error al obtener los favoritos: ' + error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out getProductListFavorites');
            }

            //Verificar si el token ya expiro
            if (error.response.data.error == 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m')
                navigate('/');
                return
            }

            setCargandoFavoritos(false);
        }
    };


    const onDeleteProducto = async (id, idProducto) => {
        // console.log('los voy a eleminar prro', id, idProducto)
        const data = {
            id_usuario: datosPerfil['$udi09345'],
            id_favorito: id
        };


        try {
            let response = await axios.post(API_URL + "/removeProductListFavorites", data, { headers: { 'Authorization': datosPerfil['$tk3m09s'] }, timeout: 10000 });
            // console.log('la repsueta fuew correcta ', response.data)
            getFavoritos();


        } catch (error) {
            console.error('Error al obtener los favoritos: ' + error.toString());
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out removeProductListFavorites');
            }

        }
    }

    return (
        <div className='bg-white'>
            <Header />
            <div className='h-screen lg:w-4/5 mx-auto mt-2 mb-20'>
                <div className='lg:flex  px-3 lg:px-0 pl-5'>
                    <h3 className='text-[#1e3d8b]  font-black text-lg  lg:text-2xl lg:ml-5 font-robotoch'>
                        Tu lista de favoritos
                    </h3>
                </div>
                {/* Verificación de favoritos para renderizar */}
                {cargandoFavoritos ? (
                    <div className=' flex flex-col justify-center h-96 items-center'>

                        <RiseLoader color="#1e3d8b"
                            size={40} />
                        <p className='mt-12'>Cargando datos </p>
                    </div>) : (


                    favoritos.length > 0 ? (
                        <FavoritosLista favoritos={favoritos} onDeleteProducto={onDeleteProducto} />

                    ) : (
                        <div className="text-center">
                            <p className='mt-20 text-lg lg:text-2xl'>No tienes favoritos aún.</p>
                            <button onClick={() => navigate('/')} className="mt-4 px-4 py-2 bg-[#e9bf56] text-white rounded hover:bg-[#1e3d8b] transition duration-300"
                                name='btn-gofavorite'
                                id='btn-gofavorite'>
                                Ir a ver productos
                            </button>
                        </div>
                    )
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Favoritos;
