import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/Logo1.webp';


function BarraDeProgreso({ progreso, irAPaginaUno, irAPaginaDos }) {
    const navigate = useNavigate();
    const pasoActual = progreso <= 33 ? 1 : progreso <= 66 ? 2 : 3;


    const manejarClicAtras = () => {
        switch (pasoActual) {
            case 1:
                // Navegar a /CarStore si estamos en la primera página
                navigate('/car-store');
                break;
            case 2:
                // Ir a la página 1 si estamos en la página 2
                irAPaginaUno();
                break;
            case 3:
                // Ir a la página 2 si estamos en la página 3
                irAPaginaDos();
                break;
            default:
                break;
        }
    };

    return (


        <header className="flex items-center justify-between p-4" style={{ borderBottom: '1px solid #152a6e' }}>
            <div className="w-16 ">
                <button


                    name='btn-backprogres'
                    id='btn-backprogres'
                    onClick={manejarClicAtras} className="text-[#152a6e] flex">
                    <i className="fas fa-chevron-left text-2xl"></i>
                </button>


            </div>
            <div className="flex-grow flex justify-center">
                <img width="300" height="200"
                    src={Logo} alt="Logo" className="h-10 w-auto lg:h-10" />
            </div>
            <div className="w-16">
            </div>
        </header>
    );
}

export default BarraDeProgreso;
