import React from 'react';

function CategoriesTable({ categorias, onRowClick }) {
    return (
        <div className='w-4/5 ml-5 mt-5 p-4 rounded-lg shadow'>
            <table className='min-w-full leading-normal'>
                <thead>
                    <tr>
                        <th className='px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                            ID
                        </th>
                        <th className='px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                            Imagen
                        </th>
                        <th className='px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                            Nombre
                        </th>
                        <th className='px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                            Descripción
                        </th>
                        <th className='px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                            Estado
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {categorias.map((categoria, index) => (
                        <tr key={index} onClick={() => onRowClick(categoria)} className='cursor-pointer'>
                            <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
                                CAT-0{categoria.id_categoria}
                            </td>
                            <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
                                <img width="300" height="200"
 src={categoria.imagen} alt="Categoría" className="w-20 h-20 object-cover" />
                            </td>
                            <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
                                {categoria.nombre}
                            </td>
                            <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm'>
                                {categoria.descripcion}
                            </td>
                            <td className='px-5 py-5 border-b border-gray-200 bg-white text-sm text-center'>
                                {categoria.activo ?
                                    (<p className="bg-green-100 text-green-800 font-semibold p-2 rounded">Activo</p>) :
                                    (<p className="bg-red-100 text-red-800 font-semibold p-2 rounded">Inactivo</p>)
                                }
                            </td>


                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CategoriesTable;
