import React from 'react';

function ModalContacto({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (


        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-5 z-10">
            <section className="rounded-3xl shadow-2xl bg-white">
                <div className="p-8 text-center sm:p-12">
                    <p className="text-sm font-semibold uppercase tracking-widest text-[#1e3d8b]">
                        ¡Gracias por ponerte en contacto con nosotros!
                    </p>

                    <h2 className="mt-6 text-xl font-bold">Uno de nuestros representantes revisará tu mensaje y se pondrá en contacto contigo a la brevedad.</h2>

                    <button

                        name='btn-expolrarmdalcontact'
                        id='btn-expolrarmdalcontact'
                        className="mt-8 inline-block w-full rounded-full bg-[#1e3d8b] py-4 text-sm font-bold text-white shadow-xl"
                        onClick={onClose}
                    >
                        Explorar productos
                    </button>
                </div>
            </section>
        </div>


    );
}

export default ModalContacto;
