import React, { useEffect, useState } from 'react';
import '../../assets/style/generalstyles.css';
import LoginForm from '../../componentes/sesiones/loginform/LoginForm';
import HeaderSpecial from '../../componentes/Headers/HeadeEpecial';
import Footer from '../../componentes/footer/Footer';
import Imglogin from '../../assets/fondologin.webp'
import { useLocation } from 'react-router-dom';
import { useBeforeUnload } from '../../utils/Analitycs/analytics';

function Login() {
    useEffect(() => {
        window.scrollTo(0, 0);


    }, []);

    // El usuario ha sálido de la página
    const location = useLocation();
    const fullPath = location.pathname;
    const routeName = fullPath.split('/')[1];
    useBeforeUnload(routeName);
    return (
        <div>
            <div className='lg:flex lg:h-screen b'>
                <div className="hidden lg:block lg:w-1/2 lg:h-auto bg-blue-300">
                    <img width="300" height="200"
 className='w-full h-full object-cover' src={Imglogin} alt="Descripción de la imagen" />
                </div>

                <div className=' lg:w-1/2 lg:h-auto'>
                    <div className='lg:hidden'>
                        <HeaderSpecial />

                    </div>
                    <LoginForm />
                </div>

            </div>

            <Footer />
        </div>
    );
}

export default Login;
