import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '../../componentes/footer/Footer';
import Header from '../../componentes/Headers/Header';
import { useBeforeUnload } from '../../utils/Analitycs/analytics';

function Politicas() {
    let navigate = useNavigate();
    useEffect(() => {
        // Forzar la re-renderización del Slider una vez que los productos están listos
        window.scrollTo(0, 0);
    }, []); // Dependencia a 'productos' para actualizar cuando cambie


    const politicaPrivacidad = [
        {
            titulo: "Política de Privacidad y Uso de Cookies. Compromiso con la Privacidad",
            contenido: "En Hantec, estamos profundamente comprometidos con la seguridad y privacidad de la información proporcionada por nuestros usuarios. Esta Política de Privacidad establece cómo usamos y protegemos la información recabada a través de nuestro sitio web hantec.us. Nos aseguramos de que el uso de su información personal se realice estrictamente bajo los términos aquí descritos. Es importante mencionar que esta política está sujeta a cambios o actualizaciones futuras."
        },
        {
            titulo: "Recolección de Información Personal",
            contenido: "Recogemos información personal tal como nombre, información de contacto incluyendo dirección de correo electrónico, e información demográfica. Esta información se solicita al momento de realizar un pedido, una entrega, o para facturación. Además, recopilamos datos a través de nuestras campañas de marketing con el fin de identificar y contactar clientes potenciales. Aseguramos siempre un uso adecuado de esta información conforme a esta política."
        },
        {
            titulo: "Uso de Cookies",
            contenido: "Nuestro sitio web utiliza \"cookies\" para mejorar la experiencia de nuestros usuarios. Las cookies son archivos pequeños que se almacenan en su dispositivo para recopilar información sobre la interacción con nuestro sitio web y permitirnos recordarle. Esta información nos ayuda a optimizar la navegación en nuestro sitio y personalizar nuestros servicios. Usted tiene la opción de aceptar o rechazar el uso de cookies, aunque el rechazo puede limitar la plena funcionalidad del sitio."
        },
        {
            titulo: "Uso de la Información Recopilada",
            contenido: "La información recopilada de nuestros usuarios se utiliza no solo para procesar pedidos y entregas, sino también para mejorar nuestros productos y servicios. Importante destacar, utilizamos esta información para campañas de marketing, con el fin de ofrecerle contenido relevante y anuncios personalizados que puedan ser de su interés. Al aceptar nuestra política, usted otorga consentimiento para este uso de la información."
        },
        {
            titulo: "Seguridad de la Información",
            contenido: "Hantec se compromete a garantizar la protección de su información. Implementamos una serie de medidas de seguridad para mantener la confidencialidad y seguridad de su información personal frente a accesos no autorizados o divulgación indebida. Actualizaciones de la Política de Privacidad Nos reservamos el derecho de modificar o actualizar esta Política de Privacidad en cualquier momento. Le invitamos a revisarla periódicamente para estar informado sobre cómo protegemos su información y mejoramos continuamente nuestra comunicación y servicios con usted."
        },
        {
            titulo: "Consentimiento",
            contenido: "Al utilizar nuestro sitio web, usted consiente la recopilación y uso de su información personal según lo descrito en esta Política de Privacidad, incluyendo el uso de cookies y el empleo de su información para campañas de marketing. Para cualquier consulta o aclaración sobre nuestra Política de Privacidad y uso de cookies, no dude en contactarnos. Su privacidad es nuestra máxima prioridad."
        }
    ];
    // El usuario ha sálido de la página
    useBeforeUnload('Politicas_privacidad');

    return (



        <div className='bg-white'>

            <Header /> 
            <div className='lg:w-4/5 lg:px-0 px-5 mt-10 mx-auto font-robotoch'>
                {politicaPrivacidad.map((seccion, index) => (
                    <div key={index} className='my-5  lg:w-4/5 mx-auto'>
                        <h2 className='font-black text-lg lg:text-2xl text-[#1a2869]'>{seccion.titulo}</h2>
                        <p className='text-sm lg:text-base  text-[#1a2869] mt-2 text-justify'>{seccion.contenido}</p>
                    </div>
                ))}
            </div>
            <Footer />
        </div>

    );
}

export default Politicas;


