import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Headers/Header';
import axios from 'axios';
import Footer from '../../../componentes/footer/Footer';
import { useNavigate } from 'react-router-dom';
import RiseLoader from "react-spinners/RiseLoader";
import { API_URL } from '../../../constants/constants';
// import { viewOrders } from '../../../utils/PixelMeta/metaPixelEvents';


function Pedidos() {
    const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
    const [pedidos, setPedidos] = useState([]);
    const [cargandoPedidos, setCargandoPedidos] = useState(true);
    const [paginaActual, setPaginaActual] = useState(1);
    const pedidosPorPagina = 9;

    // Datos del filtrado de pedidos
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [estadoSeleccionado, setEstadoSeleccionado] = useState('');

    //boton para mostar filtros 
    const [mostrarFiltros, setMostrarFiltros] = useState(false);


    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

        const getPedidos = async () => {
            const data = { user_id: datosPerfil['$udi09345'] };
            try {
                const config = {
                    headers: {
                        'Authorization': datosPerfil['$tk3m09s']
                    },
                    timeout: 10000000
                };
                let response = await axios.post(API_URL + "/getOrdersByUser", data, config);
                const pedidosOrdenados = response.data.orders_list.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setPedidos(pedidosOrdenados);
                // console.log('pedidos lista', pedidosOrdenados)
                setCargandoPedidos(false);
            } catch (error) {
                console.error('Error al obtener los pedidos: ' + error.toString());

                //Verificar que el token aún no ha expirado
                if (error.response.data.error == 'refresh') {
                    localStorage.removeItem('k-9SMD879na89dN87m')
                    navigate('/');
                    return
                }
                setCargandoPedidos(false);
            }
        };

        getPedidos();
        // -------- Pixel Meta ---------- //
        // viewOrders(datosPerfil ? datosPerfil['$email'] : 'No registrado')
    }, []);

    const pedidosFiltrados = pedidos.filter(pedido => {
        const fechaPedido = new Date(pedido.created_at);
        return (!estadoSeleccionado || pedido.estado_orden === estadoSeleccionado) &&
            (!fechaInicio || fechaPedido >= fechaInicio) &&
            (!fechaFin || fechaPedido <= fechaFin);
    });

    const totalPaginas = Math.ceil(pedidosFiltrados.length / pedidosPorPagina);
    const indexDelUltimoPedido = paginaActual * pedidosPorPagina;
    const indexDelPrimerPedido = indexDelUltimoPedido - pedidosPorPagina;
    const pedidosActuales = pedidosFiltrados.slice(indexDelPrimerPedido, indexDelUltimoPedido);

    const cambiarPagina = (numeroDePagina) => setPaginaActual(numeroDePagina);

    // datos para el color de los estado en base al estado dle pedido 
    const getIconAndColor = (estado) => {
        switch (estado) {
            case 'Pendiente':
                return { icon: 'fa-box', color: 'text-[#1e3d8b]' };
            case 'Preparado':
                return { icon: 'fa-truck', color: 'text-[#1e3d8b]' };
            case 'Enviado':
                return { icon: 'fa-truck', color: 'text-[#1e3d8b]' };
            case 'Camino':
                return { icon: 'fa-truck', color: 'text-[#1e3d8b]' };
            case 'Cancelado':
                return { icon: 'fa-times', color: 'text-red-500' };
            case 'Entregado':
                return { icon: 'fa-check', color: 'text-green-500' };
            default:
                return { icon: '', color: '' };
        }
    };


    // Cambia la visibilidad de los filtros
    const toggleFiltros = () => {
        setMostrarFiltros(!mostrarFiltros);
    };

    return (
        <div>
            <Header />
            <div className='mt-10 w-full mx-auto lg:w-4/5'>
                <div className='flex justify-between  w-full px-6 mx-auto  lg:w-full'>

                    <div className=' lg:flex'>
                        <h2 className='text-[#1e3d8b] font-black text-base lg:text-2xl'>MIS</h2>
                        <h2 className='text-[#030303] font-black text-xl lg:text-2xl lg:ml-2'>PEDIDOS</h2>
                    </div>

                    <div>
                        <button onClick={toggleFiltros} className='text-2xl mt-4 text-[#1e3d8b] lg:mt-0'
                            name='btn-toggleFiltros'
                            id='btn-toggleFiltros'>
                            <i className={`fas ${mostrarFiltros ? 'fa-times' : 'fa-filter'}`}></i>
                        </button>
                    </div>

                </div>

                <div className={`transition-all ease-in-out duration-500 mt-4 lg:w-full mx-auto w-80 ${mostrarFiltros ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                    {/* Filtros */}
                    <div className='flex justify-center items-center  mx-auto  lg:justify-start lg:pl-3'>
                        <p className='mr-2 lg:mt-2'>de</p>
                        <input className='border-2 border-gray-200 px-2  w-36 rounded' type="date" onChange={(e) => setFechaInicio(e.target.value ? new Date(e.target.value) : null)} />
                        <p className='mx-4 lg:mt-2'>a</p>
                        <input className='border-2 border-gray-200 px-2 w-36 rounded' type="date" onChange={(e) => setFechaFin(e.target.value ? new Date(e.target.value) : null)} />
                    </div>
                    <div className=''>
                        <select onChange={(e) => setEstadoSeleccionado(e.target.value)} className='mt-5 border-2 border-gray-200 px-2 px-1 rounded'>
                            <option value="">Todos los estados</option>
                            <option value="Pendiente">Pendiente</option>
                            <option value="Preparado">Preparado</option>
                            <option value="Enviado">Enviado</option>
                            <option value="Camino">Camino</option>
                            <option value="Entregado">Entregado</option>
                            <option value="Cancelado">Cancelado</option>
                        </select>
                    </div>
                </div>


                <div className='grid grid-cols-1  w-auto p-5 lg:w-full mx-auto mt-2 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {cargandoPedidos ? (
                        <div className="flex flex-col items-center justify-center mt-8 w-full h-96 lg:w-4/5">
                            <RiseLoader color="#1A2869" size={40} />
                            <p className='mt-10 text-[#1A2869] font-black uppercase text-xl'>Cargando pedidos...</p>
                        </div>
                    ) : pedidosActuales.length > 0 ? (
                        pedidosActuales.map((pedido, index) => (
                            <div key={index} className='bg-white flex justify-between boxcaja rounded-lg p-4 pr-20 items-center' onClick={() => navigate(`/Detalle-Del-Pedido/${pedido.id_pedido}`, { state: { pedido } })}>
                                <div>
                                    <h3 className='text-lg font-semibold text-[#012a45]'>Pedido N° {pedido.id_pedido}</h3>
                                    <p className='text-sm text-gray-600'>Fecha: {new Date(pedido.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</p>
                                    <p className={`text-sm font-semibold ${getIconAndColor(pedido.estado_orden).color}`}>
                                        Estado: {pedido.estado_orden}
                                    </p>
                                </div>
                                <div>
                                    <i className={`fas ${getIconAndColor(pedido.estado_orden).icon} ${getIconAndColor(pedido.estado_orden).color} text-xl`}></i>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center h-96">
                            <p className='mb-4 text-xl mt-20'>No tienes pedidos.</p>
                            <a href="/Categorias/Rampas" className="bg-[#e9bf56] p-2 cursor-pointer rounded">Ir a comprar</a>
                        </div>
                    )}
                </div>

                {/* Paginación */}
                <div className="flex justify-center items-center space-x-2 my-4">
                    {Array.from({ length: totalPaginas }, (_, i) => (
                        <button key={i + 1} onClick={() => cambiarPagina(i + 1)} className={`p-2 ${paginaActual === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}>
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Pedidos;