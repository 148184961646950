import React, { useState, useMemo, createContext, useContext, forwardRef } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal
} from "@floating-ui/react";

const TooltipContext = createContext(null);

export function useTooltip({ initialOpen = false, placement = "top", open: controlledOpen, onOpenChange: setControlledOpen } = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5
      }),
      shift({ padding: 5 })
    ]
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data
    }),
    [open, setOpen, interactions, data]
  );
}

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

export function Tooltip({ children, ...options }) {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export const TooltipTrigger = forwardRef(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = children.ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed"
      })
    );
  }

  return (
    <button
      name='btn-close60'
      id='btn-close60'


      ref={ref}
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export const TooltipContent = forwardRef(function TooltipContent({ style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
          backgroundColor: '#fff',
          color: '#000',
          border: '1px solid #ddd',
          padding: '8px',
          borderRadius: '4px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex: 10,
          width: '200px', // Ajusta este valor según sea necesario
          wordWrap: 'break-word' // Asegura que el texto se envuelva
        }}
        {...context.getFloatingProps(props)}
      >
        {props.children}
      </div>
    </FloatingPortal>
  );
});

// Componente DatosExtraSeend
const DatosExtraSeend = ({ EnvioCastores, gap, t_envio, p_envio }) => {
  const [selectedEnvio, setSelectedEnvio] = useState(t_envio ? t_envio[0] : null);

  const formatCurrency = (num) => {
    return num.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
  };

  const diferencia = p_envio && selectedEnvio ? p_envio[selectedEnvio] - gap : null;

  return (
    <div className="mt-4 bg-gray-100 p-4 rounded">
      {t_envio && p_envio ? (
        <>
          <div className="flex items-center justify-between w-full mt-2">
            <p className="text-[#757575] text-md mr-2">Total del envío que pagamos a {selectedEnvio}:</p>
            <h2 className="text-[#012a45] text-base text-right font-semibold">{formatCurrency(p_envio[selectedEnvio])}</h2>
          </div>

          {selectedEnvio !== "estafeta" ? (
            <>
              <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Máximo que se cubre del envío:</p>
                <h2 className="text-[#012a45] text-base text-right font-semibold">{formatCurrency(gap)}</h2>
              </div>

              <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Total:</p>
                <h2 className={`text-base text-right font-semibold ${diferencia < 0 ? 'text-red-500' : 'text-green-500'}`}>
                  {formatCurrency(diferencia)}
                </h2>
              </div>
            </>
          ) : null}

          <div className="flex items-center justify-end w-full mt-2 relative">
            <Tooltip>
              <TooltipTrigger asChild>
                <i className="fas fa-info-circle text-2xl text-[#012a45] cursor-pointer"></i>
              </TooltipTrigger>
              <TooltipContent>
                {selectedEnvio === "estafeta" ? (
                  "El precio de estafeta está incluido en el precio del producto."
                ) : (
                  "Si el resultado del envío es 0 o menor a 0, el envío será gratuito para el cliente. Si es mayor, el cliente cubre la diferencia de lo que pagamos a paquetería."
                )}
              </TooltipContent>
            </Tooltip>
          </div>

          <div className="flex items-center justify-around w-full mt-4">
            {t_envio.map((envio) => (
              <button
                key={envio}
                onClick={() => setSelectedEnvio(envio)}
                className={`px-4 py-2 rounded ${selectedEnvio === envio ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
              >
                {envio.charAt(0).toUpperCase() + envio.slice(1)}
              </button>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between w-full mt-2">
            <p className="text-[#757575] text-md mr-2">Total del envío que pagamos a Castores:</p>
            <h2 className="text-[#012a45] text-base text-right font-semibold">{formatCurrency(EnvioCastores)}</h2>
          </div>

          <div className="flex items-center justify-between w-full mt-2">
            <p className="text-[#757575] text-md mr-2">Máximo que se cubre del envío:</p>
            <h2 className="text-[#012a45] text-base text-right font-semibold">{formatCurrency(gap)}</h2>
          </div>

          <div className="flex items-center justify-between w-full mt-2">
            <p className="text-[#757575] text-md mr-2">Total:</p>
            <h2 className="text-base text-right font-semibold text-green-500">
              {formatCurrency(EnvioCastores - gap)}
            </h2>
          </div>

          <div className="flex items-center justify-end w-full mt-2 relative">
            <Tooltip>
              <TooltipTrigger asChild>
                <i className="fas fa-info-circle text-2xl text-[#012a45] cursor-pointer"></i>
              </TooltipTrigger>
              <TooltipContent>
                Si el resultado del envío es 0 o menor a 0, el envío será gratuito para el cliente. Si es mayor, el cliente cubre la diferencia de lo que pagamos a paquetería.
              </TooltipContent>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default DatosExtraSeend;
