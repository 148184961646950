import React, { useState } from 'react';

const Preguntasproducto = ({ preguntaslitas, idusuario, onPreguntar, nombreproducto, currentPageUrl }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handlePreguntarClick = () => {
        // Llamar a la función pasada por el padre
        onPreguntar(inputValue);
        // Limpiar el input después de preguntar
        setInputValue('');
    };

    const buttonStyle = inputValue ? 'text-white bg-[#1a2869]' : 'text-white bg-gray-400 cursor-not-allowed';
    // Suponiendo que currentPageUrl ya incluye la ruta específica después del dominio
    const whatsappMessage = `Hola, quiero más información sobre: ${nombreproducto} que vi en ${currentPageUrl}`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=525593311600&text=${encodeURIComponent(whatsappMessage)}`;

    return (
        <div className='justify-center  items-center mt-10  p-2 border-b border-gray-500 lg:border-0 w-full'>
            <div className='flex text-left ml-5 lg:ml-0 lg:hidden '>
                <p className='text-[#030303] font-black'>PREGUNTAS</p>
                <p className='text-[#1e3d8b] ml-1 font-black'> Y RESPUESTAS</p>
            </div>

            <div className='bg-white shadow-lg w-full lg:flex rounded mt-4'>

                <div className='lg:w-1/2 hidden lg:block'>
                    {/* Renderizar condicionalmente basado en idusuario */}


                    {idusuario !== null && (
                        <div className='flex flex-col items-center justify-center mt-2 lg:w-full '>
                            <div className='flex flex-col justify-start items-start text-left w-4/5 '>
                                <p className='text-[#030303] text-2xl font-black font-montserrat'>PREGUNTAS</p>
                                <p className='text-[#1e3d8b] text-2xl '> Y RESPUESTAS</p>
                            </div>
                            <textarea
                                type="text"
                                className="mb-4 w-80 h-1/2  lg:w-4/5 mt-2 border-2 border-gray-300 px-2"
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                            <button

                                name='btn-Preguntar'
                                id='btn-Preguntar'
                                className={`mb-5 px-10 lg:w-4/5 w-80 h-9 uppercase font-black rounded ${buttonStyle}`}
                                disabled={!inputValue}
                                onClick={handlePreguntarClick}
                            >
                                Preguntar
                            </button>
                        </div>
                    )}
                    <div className='flex flex-col items-center justify-center  lg:w-full '>
                        {/* Este botón se muestra siempre */}
                        <a
                            href={whatsappLink}
                            target="_blank" rel="noopener noreferrer"
                            className='text-black mb-5 flex items-center lg:w-4/5 text-center h-9  font-light justify-center bg-[#e9bf56] px-10 w-80 py-1 rounded'>
                            <i className="fab fa-whatsapp mr-2"></i>
                            <span>IR A WHATSAPP</span>
                        </a>
                    </div>
                </div>

                <div className=' w-80 mx-auto lg:w-1/2 mt-2 max-h-80 overflow-x-auto pt-4'>
                    {preguntaslitas.map((lstapreg, index) => (
                        <div key={index} className='w-full mt-2  p-2 '>

                            <p className='text-xs lg:text-sm text-gray-500 font-montserrat'>
                                <span className='text-[#1e3d8b] font-black font-montserrat'>{lstapreg?.id_usuario}</span>  / {new Date(lstapreg?.created_at).toLocaleDateString('es-ES', { day: 'numeric', month: 'short', year: 'numeric' }).replace('.', '')}
                            </p>
                            <p className='mt-1 mb-2 text-base lg:text-lg font-montserrat'>{lstapreg?.pregunta}</p>
                            <p className='mt-2 mb-2 text-xs lg:text-sm ml-1 font-montserrat'><i className="fas fa-reply transform rotate-180 mr-1"></i>{lstapreg?.respuesta || "Sin respuesta aún."}</p>
                        </div>
                    ))}
                </div>

                <div className='lg:w-1/2 lg:hidden mt-2'>
                    {/* Renderizar condicionalmente basado en idusuario */}
                    {idusuario !== null && (
                        <div className='flex flex-col items-center justify-center mt-2 lg:w-full '>
                            <textarea
                                type="text"
                                className="mb-4 w-80  py-1 lg:w-4/5 h-32 mt-2 border-2 border-gray-300 px-2"
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                            <button
                                name='btn-Preguntar2'
                                id='btn-Preguntar2'
                                className={`mb-5 px-10 lg:w-4/5 w-80 h-9 uppercase font-black rounded ${buttonStyle}`}
                                disabled={!inputValue}
                                onClick={handlePreguntarClick}
                            >
                                Preguntar
                            </button>
                        </div>
                    )}
                    <div className='flex flex-col items-center justify-center  lg:w-full '>
                        {/* Este botón se muestra siempre */}
                        <a href={whatsappLink} className='text-black mb-5 flex items-center lg:w-4/5 text-center h-9  font-light justify-center bg-[#e9bf56] px-10 w-80 py-1 rounded'>
                            <i className="fab fa-whatsapp mr-2"></i>
                            <span>IR A WHATSAPP</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Preguntasproducto;
