import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const token =
    localStorage.getItem("k-9SMD879na89dN87m") != null
      ? JSON.parse(localStorage.getItem("k-9SMD879na89dN87m"))
      : null;


  const handleLogout = () => {
    localStorage.removeItem("k-9SMD879na89dN87m");
    navigate("/");
  };

  const isActive = (path) => {
    const baseClasses = "mb-5";
    const activeClasses = "rounded-lg text-white bg-blue-900 dark:bg-gray-700";
    // const inactiveClasses = "text-gray-700 hover:bg-[#1E3D8B] hover:text-white";
    return location.pathname === path ? `${baseClasses} ${activeClasses}` : `${baseClasses} text-gray-500 hover:bg-gray-00 dark:hover:bg-gray-700 `;
  };

  const svgActive = (path) => {
    const activeClasses = "";
    const disabledClasses = "group-hover:text-gray-900"
    return location.pathname === path ? `${activeClasses}` : `${disabledClasses}`;
  };

  return (
    <>
      {/* <!-- drawer init and show --> */}

      <button
        onClick={() => setIsOpen(!isOpen)}
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        name="btn-opensidebar"
        id="btn-opensidebar"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${isOpen ? "" : "-translate-x-full"
          } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <a href="/" className="flex items-center ps-2.5 mb-5">
            {/* <img width="300" height="200"
 src="https://flowbite.com/docs/images/logo.svg" className="h-6 me-3 sm:h-7" alt="Flowbite Logo" /> */}
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Hantec Dashboard
            </span>
          </a>

          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            name="btn-closemenu"
            id="btn-closemenu"
            data-drawer-hide="drawer-navigation"
            aria-controls="drawer-navigation"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close menu</span>
          </button>

          <ul className="space-y-4 font-medium gap-1">
            {token && token["$r07sad"] === "order_viewer" && (
              <>
                <Link to={"/dashboard"}>
                  <li className={`${isActive('/dashboard')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/dashboard')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 21"
                      >
                        <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                        <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                      </svg>
                      <span className="ms-3">Dashboard</span>
                    </div>
                  </li>
                </Link>
                
                <Link to={"/list_orders"}>
                  <li className={`${isActive('/list_orders')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/list_orders')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">Pedidos</span>
                    </div>
                  </li>
                </Link>

                <Link to={"/products_managment"}>
                  <li className={`${isActive('/products_managment')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <svg
                        className={`${svgActive('/products_managment')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Productos
                      </span>
                    </div>
                  </li>
                </Link>

                <Link to={"/lista-Clientes"}>
                  <li className={`${isActive('/lista-Clientes')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/lista-Clientes')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" />
                      </svg>
                      {/* <i className=" mr-1"></i> */}
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Clientes
                      </span>
                    </div>
                  </li>
                </Link>
              </>
            )}

            {token && token["$r07sad"] !== "order_viewer" && (
              <>
                <Link to={"/dashboard"}>
                  <li className={`${isActive('/dashboard')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/dashboard')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 21"
                      >
                        <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                        <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                      </svg>
                      <span className="ms-3">Dashboard</span>
                    </div>
                  </li>
                </Link>

                <Link to={"/list_orders"}>
                  <li className={`${isActive('/list_orders')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/list_orders')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">Pedidos</span>
                    </div>
                  </li>
                </Link>

                <Link to={"/products_managment"}>
                  <li className={`${isActive('/products_managment')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/products_managment')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Productos
                      </span>
                    </div>
                  </li>
                </Link>

                <Link to={"/promociones-cupones"}>
                  <li className={`${isActive('/promociones-cupones')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/promociones-cupones')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512">
                        <path d="M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3 .2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a24 24 0 0 1 -19.6 10.2l-33.4 .1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5 .2 392.3 .2z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Promociones
                      </span>
                    </div>
                  </li>
                </Link>

                <Link to={"/CategoriesPage"}>
                  <li className={`${isActive('/CategoriesPage')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/CategoriesPage')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M497.9 225.9L286.1 14.1A48 48 0 0 0 252.1 0H48C21.5 0 0 21.5 0 48v204.1a48 48 0 0 0 14.1 33.9l211.9 211.9c18.7 18.7 49.1 18.7 67.9 0l204.1-204.1c18.7-18.7 18.7-49.1 0-67.9zM112 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm513.9 133.8L421.8 497.9c-18.7 18.7-49.1 18.7-67.9 0l-.4-.4L527.6 323.5c17-17 26.4-39.6 26.4-63.6s-9.4-46.6-26.4-63.6L331.4 0h48.7a48 48 0 0 1 33.9 14.1l211.9 211.9c18.7 18.7 18.7 49.1 0 67.9z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Categorias
                      </span>
                    </div>
                  </li>
                </Link>

                <Link to={"/lista-Clientes"}>
                  <li className={`${isActive('/lista-Clientes')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/lista-Clientes')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" />
                      </svg>
                      {/* <i className=" mr-1"></i> */}
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Clientes
                      </span>
                    </div>
                  </li>
                </Link>

                <Link to={"/questionProduct"}>
                  <li className={`${isActive('/questionProduct')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/questionProduct')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M202 0C122.2 0 70.5 32.7 29.9 91c-7.4 10.6-5.1 25.1 5.2 32.9l43.1 32.7c10.4 7.9 25.1 6 33.3-4.1 25-31.4 43.6-49.4 82.8-49.4 30.8 0 68.8 19.8 68.8 49.6 0 22.6-18.6 34.1-49 51.2-35.4 19.9-82.3 44.6-82.3 106.4V320c0 13.3 10.7 24 24 24h72.5c13.3 0 24-10.7 24-24v-5.8c0-42.9 125.3-44.6 125.3-160.6C377.5 66.3 286.9 0 202 0zM192 373.5c-38.2 0-69.3 31.1-69.3 69.3 0 38.2 31.1 69.3 69.3 69.3s69.3-31.1 69.3-69.3-31.1-69.3-69.3-69.3z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Preguntas
                      </span>
                    </div>
                  </li>
                </Link>

                <Link to={"/BannersPage"}>
                  <li className={`${isActive('/BannersPage')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/BannersPage')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M480 416v16c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h16v208c0 44.1 35.9 80 80 80h336zm96-80V80c0-26.5-21.5-48-48-48H144c-26.5 0-48 21.5-48 48v256c0 26.5 21.5 48 48 48h384c26.5 0 48-21.5 48-48zM256 128c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zm-96 144l55.5-55.5c4.7-4.7 12.3-4.7 17 0L272 256l135.5-135.5c4.7-4.7 12.3-4.7 17 0L512 208v112H160v-48z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Contenido
                      </span>
                    </div>
                  </li>
                </Link>

                <Link to={"/contact_client"}>
                  <li className={`${isActive('/contact_client')}`}>
                    <div
                      className="flex items-center p-2 rounded-lg dark:text-white group"
                    >
                      <svg
                        className={`${svgActive('/contact_client')} flex-shrink-0 w-5 h-5 transition duration-75 dark:text-gray-400 dark:group-hover:text-white`}
                        aria-hidden="true"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" />
                      </svg>
                      <span className="flex-1 ms-3 whitespace-nowrap">
                        Contactar Cliente
                      </span>
                    </div>
                  </li>
                </Link>
              </>
            )}

            <li className="border-t border-gray-200">
              <a

                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLogout}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Cerrar sesión</span>
              </a>
            </li>

          </ul>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
