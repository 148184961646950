import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Headers/Header';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import DatosProducto from '../../../componentes/productos/DatosProducto';
import Footer from '../../../componentes/footer/Footer';
import Preguntasproducto from '../../../componentes/productos/Preguntasproducto';
import Resenaproducto from '../../../componentes/productos/Resenaprducto';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import axios from 'axios';
import GridLoader from "react-spinners/GridLoader";
import Datosresenas from '../../../componentes/productos/Datosresenas';
import { API_URL } from '../../../constants/constants';
// import { viewProduct } from '../../../utils/PixelMeta/metaPixelEvents';
import { dateDiffFromNow } from '../../../utils/utils';
import { SnipTrafficPlatform } from '../../../utils/Analitycs/analytics';
import FloatingWhatsAppButton from '../../../componentes/botones/FloatingWhatsAppButton';
import PulsarProduct from '../../../componentes/cargacomponetes/PulsarProduct';



const Product = () => {
    const { product_name, id_product } = useParams();

    // Usa product_name e id_product según sea necesario
    // console.log('Product Name:', product_name);
    // console.log('Product ID:', id_product);

    const token = window.localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(window.localStorage.getItem('k-9SMD879na89dN87m')) : null

    //obtner la localizacion
    const location = useLocation();
    const currentPageUrl = window.location.origin + location.pathname;

    const navigate = useNavigate();

    //variables
    const [idsusertoken, setIdsusertoken] = useState(null);
    const [listproductosdata, setListproductosdata] = useState([]);

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoadingProductos, setIsLoadingProductos] = useState(false);
    const [listapreguntas, setListaPreguntas] = useState([])
    const [listacometarios, setListaComentarios] = useState([])

    //variable que indica si esta o no en favoritos 
    const [estaEnFavoritos, setEstaEnFavoritos] = useState(false);

    const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;


    //arreglo apra el ormedio de reseanas 
    const [datosResenaView, setDatosResenaView] = useState({ promedio: 0, numResenas: 0 });

    const [value, setValue] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (token) {
            setIdsusertoken(token['$udi09345']);
            verifcarFavoritos()
        }

        const payload = {
            "id_producto": parseInt(id_product)
        };


        if (!isDataLoaded) {

            const cargarDatos = async () => {
                setIsLoadingProductos(true); // Indicar inicio de carga
                try {
                    const respuesta = await axios.post(API_URL + '/getProductById', payload, { timeout: 15000 });
                    const datosRecibidos = await respuesta.data;
                    // console.log('-----Datos recibidos es: ', datosRecibidos);


                    setValue(datosRecibidos);

                    // -------- Pixel Meta ---------- //
                    // viewProduct(
                    //     datosRecibidos[0]?.['titulo'],
                    //     datosRecibidos[0]?.['precio']?.['cantidad'],
                    //     token ? token['$email'] : 'No registrado'
                    // )

                    if (datosRecibidos.length > 0) {
                        const skuProducto = datosRecibidos[0].sku;
                        const categoriaProducto = datosRecibidos[0].categoria;
//console.log('los datos son',skuProducto )

                        // Lista de SKUs a excluir
                        const skusExcluidos = [ 'HNQR9KIT3', 'HTCRJ01'];

                        // Verifica si la categoría es "Rampas" y el SKU no está en la lista de excluidos
                        if (categoriaProducto === 'Rampas' && !skusExcluidos.includes(skuProducto)) {
                            obtenerProductosConSKU(skuProducto, true); // Motros=true

                        } if (skuProducto === 'HNTP1N') {
                            //  console.log('entreee')
                            obtenerProductosConSKU('HNTP1', true, 'HNTP100'); // Motros=true

                        }
                        else {
                            obtenerProductosConSKU(skuProducto, false); // Motros=false
                        }
                    } else {
                        // navigate('/');
                    }


                    setIsDataLoaded(false);

                } catch (error) {
                    console.error('Error al cargar datos prodcuto:', error);
                    if (error.code === 'ECONNABORTED') {
                        console.log('Request timed out getProductById');
                    }

                    // navigate('/');
                } finally {
                    setIsLoadingProductos(false); // Indicar fin de carga
                }
            };

            const comtariosproducto = async () => {
                const payloadata = {
                    "id_producto": parseInt(id_product)
                }
                try {
                    const response = await axios.post(API_URL + '/getCommentsByProductId', payloadata, { timeout: 15000 });
                    //  console.log('la DATA de Comentarios es:', response.data.success)

                    // Calcular el promedio de las calificaciones
                    const totalCalificaciones = response.data.success.reduce((acumulado, comentarioActual) => acumulado + comentarioActual.calificacion, 0);
                    const promedioCalificaciones = totalCalificaciones / response.data.success.length;

                    setDatosResenaView({
                        promedio: promedioCalificaciones.toFixed(2),
                        numResenas: response.data.success.length
                    });
                    //  console.log('datos para enviar reenas*-*-*-*-*', promedioCalificaciones.toFixed(2), response.data.success.length)
                    setListaComentarios(response.data.success);
                } catch (error) {
                    console.error("Error al obtener las precuntas:", error);

                    if (error.code === 'ECONNABORTED') {
                        console.log('Request timed out getCommentsByProductId');
                    }


                    // Manejo adicional del error, como establecer un estado de error
                }
            }


            cargarDatos();
            cuestionproducto();
            comtariosproducto()

        }
    }, []);


    useEffect(() => {
        // Function to extract UTM parameters from the URL
        const extractUTMParameters = () => {

            const searchParams = new URLSearchParams(location.search);

            const utmSource = searchParams.get('utm_source');
            const utmMedium = searchParams.get('utm_medium') || 'desconocido';
            const utmCampaign = searchParams.get('utm_campaign');

            // const utmTerm = searchParams.get('utm_term');
            const utmContent = searchParams.get('utm_content') || 'desconocido';

            let today = new Date()

            // Set hours in zero
            today.setHours(0, 0, 0, 0);

            const lastVisitDateStr = window.localStorage.getItem('utm');
            const lastVisitDate = lastVisitDateStr ? new Date(JSON.parse(lastVisitDateStr).date) : null;
            lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

            // Delete localstorage if date is more than 2 daty
            if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
                window.localStorage.removeItem("utm")
            }

            if (utmSource && utmMedium && utmCampaign) {

                // Has values
                const valuesUTM = {
                    "utmSource": utmSource,
                    "utmMedium": utmMedium,
                    "utmCampaign": utmCampaign,
                    "utmContent": utmContent,
                    "date": today
                }
                window.localStorage.setItem("utm", JSON.stringify(valuesUTM))

                // ------------ Event Meta -------------- //
                SnipTrafficPlatform(utmSource, utmCampaign, utmContent)

                return
            }

        };

        // Call the function when the component mounts
        extractUTMParameters();
    }, [location.search]);

    const obtenerProductosConSKU = async (sku, motors, sku2) => {

        setIsLoadingProductos(true); // Indicar inicio de carga
        // console.log('elmotros es: ',motors)

        try {


            const payload = { sku };
            // console.log('el payload es:', payload);
            const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getProductsBySku', payload, { timeout: 15000 });
            let jsonData = response.data;
            // console.log('la data es', jsonData);

            if (motors) {
                const motorPayload = { sku: "MOTOR" };
                // console.log('el payload de motor es:', motorPayload);
                const motorResponse = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getProductsBySku', motorPayload, { timeout: 15000 });
                const motorData = motorResponse.data;
                // console.log('la data de motor es', motorData);
                jsonData = jsonData.concat(motorData);
                // console.log('la nuevo jason data es ',jsonData)
            }

            if (sku2) {
                const sku2Payload = { sku: sku2 };
                const sku2Response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getProductsBySku', sku2Payload, { timeout: 15000 });
                const sku2Data = sku2Response.data;
                jsonData = jsonData.concat(sku2Data);
            }


            if (jsonData.length === 0) {
                // Si el arreglo está vacío, cambiamos el estado a un string
              //  console.log('vacio')
                setListproductosdata("El arreglo venía vacío");
            } else {
             //   console.log('con satos ')

                // Si el arreglo tiene elementos, establecemos el estado con los datos recibidos
                setListproductosdata(jsonData);
            }

        } catch (error) {
            console.error("Error al obtener los productos con el SKU:", error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out getProductsBySku');
            }
        } finally {
            setIsLoadingProductos(false); // Indicar fin de carga
        }
    };

    const verifcarFavoritos = async () => {
        const data = {
            "id_usuario": token['$udi09345'],
            "id_product": parseInt(id_product)

        };


        try {
            let response = await axios.post(API_URL + "/checkFavoriteList", data, { headers: { 'Authorization': token['$tk3m09s'] }, timeout: 15000 });

            // Convertimos el objeto de favoritos a un array para poder iterar sobre él más fácilmente
            // Verificamos también si el resultado es nulo o una cadena vacía
            let estaEnFavoritos = response.data.success;
            // console.log('El valor de success es:', estaEnFavoritos);

            setEstaEnFavoritos(estaEnFavoritos)
        } catch (error) {
            console.error('Error al obtener los favoritos: ' + error.toString());

            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out checkFavoriteList');
            }
        }
    }

    //indicador de carga
    const renderLoadingIndicator = () => (
        <div className="flex flex-col justify-center items-center mt-14">
            <PulsarProduct />
        </div>
    );

    //preguntas del producto
    const cuestionproducto = async () => {
        const payloadata = {
            "id_producto": parseInt(id_product)
        }
        try {
            const response = await axios.post(API_URL + '/getQAByProductId', payloadata, { timeout: 15000 });
            //console.log('la DATA de preguntas es:', response.data.success)
            setListaPreguntas(response.data.success);
        } catch (error) {
            console.error("Error al obtener las precuntas:", error);
            // Manejo adicional del error, como establecer un estado de error
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out getQAByProductId');
            }

        }
    }

    //funcion para hacer pregunra 
    const handlePreguntar = async (pregunta) => {
        //console.log("Pregunta hecha:", pregunta);

        const payload = {
            "id_usuario": token['$udi09345'],
            "id_producto": parseInt(id_product),
            "pregunta": pregunta
        }

        try {

            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                },
                timeout: 15000
            };

            const response = await axios.post(API_URL + '/questionAndAnswerByUser', payload, config)
            // console.log('correcto pregunta guardada', response.data);
            cuestionproducto();

        } catch (error) {
            console.error('No se hizo la pregunta', error)
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out questionAndAnswerByUser');
            }
        }
    };


    return (
        <div className='homeconte2 bg-gray-100 '>
            <Header />

            <main>

                <ScrollableCategories />

                {isLoadingProductos || (Array.isArray(listproductosdata) && listproductosdata.length === 0) || !listproductosdata
                    ? renderLoadingIndicator()
                    :
                    <div>
                        <FloatingWhatsAppButton page="productos" categoryName={value[0].titulo} urlproduto={fullUrl} />

                        <DatosProducto
                            datos={value}
                            id={id_product}
                            productsend={listproductosdata}
                            datosredan={datosResenaView}
                            enfavoritos={estaEnFavoritos}
                            verifcarFavoritos={verifcarFavoritos}
                        />

                        <div className='w-full lg:w-4/5 mx-auto '>
                            <Preguntasproducto
                                preguntaslitas={listapreguntas}
                                idusuario={idsusertoken}
                                onPreguntar={handlePreguntar}
                                nombreproducto={value[0].titulo}
                                currentPageUrl={currentPageUrl} // Agregando la prop currentPageUrl

                            />

                        </div>

                        <div className="flex flex-col md:flex-row lg:w-4/5 mx-auto"  >
                            <div className="md:order-2  lg:w-1/2">
                                <Datosresenas listacalculos={listacometarios} />
                            </div>
                            <div className="md:order-1 lg:w-1/2 ">
                                <Resenaproducto listacometarios={listacometarios} />

                            </div>

                        </div>

                    </div>
                }

            </main>
            <Footer />
        </div>
    );
};

export default Product;