import {
  Download,
  FileText,
  Shield,
  Clock,
  HeadsetIcon,
  GraduationCap,
  UserCheck,
} from "lucide-react";
import Header from "../../componentes/Headers/Header";
import Footer from "../../componentes/footer/Footer";
import imgBanner from "../../assets/blog/catalogo.webp";

export default function CatalogMain() {
  return (
    <>
      <Header />
      {/* Hero Section with Background Image */}
      <section
        className="relative bg-cover bg-center py-32 "
        style={{
          backgroundImage: { imgBanner },
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0">
          <img
            src={imgBanner}
            alt="banner alineadoras"
            width="1600"
            height="800"
            className="h-full w-full object-contain object-center aspect-[1600/800]"
            style={{ aspectRatio: 1600 / 800, objectFit: "cover" }}
          />
        </div>

        <div className="container mx-auto px-4 relative z-10 text-center text-white">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Descubre nuestros productos
          </h1>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Explora la gama de equipos automotrices e industriales más avanzados
            del mercado. Consulta tanto el catálogo como las fichas técnicas
            detalladas.
          </p>
          <div className="flex justify-center gap-4">
            <a
              href="https://drive.google.com/uc?export=download&id=1apyVShyLPLv92K7ZsvuSYx-5JBxNBZaH"
              download
              size="lg"
              className="flex items-center justify-center bg-black text-white hover:bg-gray-800 rounded px-2 md:px-8 py-1 md:py-3 transition-all duration-300 ease-in-out"
            >
              <Download className="md:mr-2 h-4 md:w-4" /> Descargar Catálogo
            </a>

            <a
              href="https://drive.google.com/uc?export=download&id=14aKE7nPJxEJa_AiKlBOP6p82RRy2ekZ2"
              download
              size="lg"
              variant="outline"
              className="flex items-center justify-center bg-transparent text-white border-white hover:bg-white hover:text-black border border-white text-white hover:bg-white hover:bg-opacity-20 rounded px-2 md:px-8 py-1 md:py-3 transition-all duration-300 ease-in-out"
            >
              <FileText className="md:mr-2 h-4 md:w-4" /> Descargar Fichas
              Técnicas
            </a>
          </div>
        </div>
      </section>

      {/* Interactive Flipbooks */}
      <section className="py-10 bg-gray-100">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="rounded-lg shadow-md aspect-[4/3]">
              <h3 className="text-center text-xl md:text-3xl font-bold mb-5">
                Catálogo 2024
              </h3>
              <iframe
                allowfullscreen="allowfullscreen"
                scrolling="no"
                className="fp-iframe h-full"
                src="https://heyzine.com/flip-book/64ad8afd98.html"
                style={{
                  border: "1px solid lightgray",
                  width: "100%",
                  //   height: "100%",
                }}
              ></iframe>
            </div>

            {/* Technical Sheets Flipbook */}

            <div className="rounded-lg shadow-md aspect-[4/3]">
              <h3 className="text-center text-xl md:text-3xl font-bold mb-5">
                Fichas Técnicas
              </h3>
              <iframe
                allowfullscreen="allowfullscreen"
                scrolling="no"
                className="fp-iframe h-full"
                src="https://heyzine.com/flip-book/6f6d3b6de1.html"
                style={{
                  border: "1px solid lightgray",
                  width: "100%",
                  //   height: "100%",
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* ¿Por qué elegir Hantec? Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            ¿Por qué elegir Hantec?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
              <Shield className="mx-auto mb-4 text-blue-600" size={48} />
              <h3 className="text-xl font-semibold mb-2">2 años de garantía</h3>
              <p className="text-gray-600">
                Confianza y seguridad en cada producto que ofrecemos.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
              <Clock className="mx-auto mb-4 text-blue-600" size={48} />
              <h3 className="text-xl font-semibold mb-2">
                10 años de disponibilidad de refacciones
              </h3>
              <p className="text-gray-600">
                Aseguramos el funcionamiento a largo plazo de tu equipo.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
              <HeadsetIcon className="mx-auto mb-4 text-blue-600" size={48} />
              <h3 className="text-xl font-semibold mb-2">
                Soporte técnico especializado
              </h3>
              <p className="text-gray-600">
                Asistencia profesional cuando más lo necesitas.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
              <GraduationCap className="mx-auto mb-4 text-blue-600" size={48} />
              <h3 className="text-xl font-semibold mb-2">
                Capacitaciones continuas
              </h3>
              <p className="text-gray-600">
                Mantente actualizado con nuestros programas de formación.
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
              <UserCheck className="mx-auto mb-4 text-blue-600" size={48} />
              <h3 className="text-xl font-semibold mb-2">
                Asistencia personalizada
              </h3>
              <p className="text-gray-600">
                Atención individualizada para satisfacer tus necesidades
                específicas.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
