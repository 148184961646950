import React, { useState } from "react";
import {
  Search,
  ShoppingCart,
  Cog,
  Gauge,
  Wind,
  Handshake,
  LayoutGrid,
} from "lucide-react";
import Header from "../../componentes/Headers/Header";
import Footer from "../../componentes/footer/Footer";
import HantecPopup from "../../componentes/blog/popup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imgCompressor from "../../assets/bg-compressor.webp";
import imgAlineadoras from "../../assets/products/alineadoras/test_ali2.webp";
import imgDesmontadoras from "../../assets/products/desmontadoras/bg_main.webp";
import imgBienvenidaMin from "../../assets/blog/bienvenida_min.webp";
import imgClaveExitoMin from "../../assets/blog/clave_exito_min.webp";
import { useNavigate } from "react-router-dom";

// Mock data for blog posts
const blogPosts = [
  {
    id: 1,
    title: "Bienvenidos a Hantec",
    excerpt: "Diferencia y Valor en Herramientas Automotrices",
    image: imgBienvenidaMin,
    category: "Potencia tus ventas",
    href: "/blog/autozone/1",
  },
  {
    id: 2,
    title: "La Clave del Éxito en Ventas",
    excerpt: "Actitud Positiva y Persistencia",
    image: imgClaveExitoMin,
    category: "Potencia tus ventas",
    href: "/blog/autozone/2",
  },
  {
    id: 3,
    title: "Compresores",
    excerpt:
      "Libera el poder del aire comprimido con una eficiencia y un rendimiento inigualables.",
    image: imgCompressor,
    category: "Compresores",
    href: "/force_element",
  },
  {
    id: 4,
    title: "Alineadoras",
    excerpt: "Optimiza tu Taller con Alineadoras de Dirección.",
    image: imgAlineadoras,
    category: "Alineadoras",
    href: "/alineadoras/autozone",
  },
  {
    id: 5,
    title: "Desmontadoras",
    excerpt:
      "Maximiza la eficiencia y la precisión con las desmontadoras de neumáticos.",
    image: imgDesmontadoras,
    category: "Desmontadoras",
    href: "/desmontadoras/autozone",
  },
];

const categories = [
  {
    name: "Todos",
    icon: <LayoutGrid className="w-4 h-4" />,
    href: "/productos/gatos-hidraulicos",
  },
  {
    name: "Potencia tus ventas",
    icon: <Handshake className="w-4 h-4" />,
    href: "/productos/gatos-hidraulicos",
  },
  {
    name: "Compresores",
    icon: <Wind className="w-4 h-4" />,
    href: "/productos/gatos-hidraulicos",
  },
  {
    name: "Alineadoras",
    icon: <Gauge className="w-4 h-4" />,
    href: "/productos/gatos-hidraulicos",
  },
  {
    name: "Desmontadoras",
    icon: <Cog className="w-4 h-4" />,
    href: "/productos/gatos-hidraulicos",
  },
];

const HantecBlog = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Todos");
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const filteredPosts = blogPosts.filter(
    (post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === "Todos" || post.category === selectedCategory)
  );

  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const toastError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-center">Blog Hantec</h1>

        {/* Search and CTA Section */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="relative w-full md:w-1/2 mb-4 md:mb-0">
            <input
              type="text"
              placeholder="Buscar artículos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 py-2 px-4 border rounded"
            />
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {window.localStorage.getItem("userSuscribed") == "true"
              ? "Ya se ha Suscrito al Boletín"
              : "Suscribirse al Boletín"}
          </button>
        </div>

        {/* Categories Section */}
        <div className="overflow-x-auto mb-8">
          <div className="flex space-x-4 pb-4">
            {categories.map((category) => (
              <button
                key={category.name}
                onClick={() => setSelectedCategory(category.name)}
                className="flex items-center px-4 py-2 bg-gray-100 rounded-full text-sm font-medium text-gray-700 hover:bg-blue-600 hover:text-white transition-colors duration-200"
              >
                {category.icon}
                <span className="ml-2 whitespace-nowrap">{category.name}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Main Content Area */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPosts.map((post) => (
            <div
              key={post.id}
              className="border rounded overflow-hidden hover:shadow-lg transition-shadow duration-300"
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">{post.title}</h3>
                <p className="text-sm text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-500">{post.category}</span>
                  <a
                    onClick={() => navigate(post.href)}
                    // href={post.href}
                    // target="_blank"
                    className="text-blue-500 hover:text-blue-700 cursor-pointer"
                  >
                    Leer Más
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <HantecPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toastSuccess={toastSuccess}
        toastError={toastError}
      />
      <ToastContainer />
    </>
  );
};

export default HantecBlog;
