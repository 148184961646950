import Navbar2 from '../navbar/Navbar2';

function Header({ onSearch }) {

    return (
        <header className="mb-2 lg:mb-0 sticky top-0 w-full z-40 bg-white">
            <Navbar2 />

        </header>
    );
}

export default Header;
