// SkeletonLoader.js
import React from 'react';

const PulsarProduct = () => (
    <div className=' lg:flex lg:w-4/5  px-5 px-2 w-full mx-auto '>
        <div className='lg:w-2/3 flex lg:h-[400px]  w-full '>
            <div className="border border-blue-700 shadow w-1/5 rounded-md p-4 lg:block hidden   h-full mx-auto">
                <div className="animate-pulse flex ">
                    <div className="rounded-md bg-slate-700 h-36 lg:h-20 lg:mt-8 w-full"></div>
                    <div className="flex-1 space-y-6 py-1  h-full">
                        <div className="h-full bg-slate-700 rounded"></div>

                    </div>
                </div>
                <div className="animate-pulse flex ">
                    <div className="rounded-md bg-slate-700 h-36 lg:h-20 lg:mt-8 w-full"></div>
                    <div className="flex-1 space-y-6 py-1  h-full">
                        <div className="h-full bg-slate-700 rounded"></div>

                    </div>
                </div>
                <div className="animate-pulse flex ">
                    <div className="rounded-md bg-slate-700 h-36 lg:h-20 lg:mt-8 w-full"></div>
                    <div className="flex-1 space-y-6 py-1  h-full">
                        <div className="h-full bg-slate-700 rounded"></div>

                    </div>
                </div>
            </div>
            <div className="border border-blue-700 shadow rounded-md p-4  mx-2 w-full lg:w-4/5 h-full ">
                <div className="animate-pulse flex ">
                    <div className="rounded-md bg-slate-700 h-80 lg:h-80 lg:mt-8 w-full"></div>
                    <div className="flex-1 space-y-6   h-full">
                        <div className="h-full bg-slate-700 rounded"></div>

                    </div>
                </div>
            </div>
        </div>

        <div className="border border-blue-300 lg:w-1/3 w-full mt-2 lg:mt-0 shadow rounded-md p-4 max-w-sm mx-auto">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-700 rounded"></div>

                        <div className="h-2 w-1/3 mt-4 bg-slate-700 rounded col-span-1"></div>
                    </div>
                </div>
            </div>

            <div className="animate-pulse mt-8 flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-10 bg-slate-700 rounded"></div>
                </div>
            </div>
            <div className="animate-pulse flex space-x-4 mt-6">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-700 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-700 rounded"></div>

                        <div className="h-2 w-1/3 mt-4 bg-slate-700 rounded col-span-1"></div>
                    </div>
                </div>
            </div>

            <div className="animate-pulse mt-8 flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-10 bg-slate-700 rounded"></div>
                </div>
            </div>



        </div>



    </div>
);

export default PulsarProduct;
