import React, { useState, useEffect } from 'react';
import CodigoInput from '../sesiones/passform/CodigoInput';
import { API_URL } from '../../constants/constants';
import axios from 'axios';
import CargandoSpinner from '../cargacomponetes/CargandoSpinner';


function ModalCPrevia({ isOpen, onClose, correocliente, oncloserefrech }) {
    if (!isOpen) return null;
    const [codigo, setCodigo] = useState('');

    const [loading, setLoading] = useState(false);

    const formatearCorreo = (correo) => {
        // Encuentra la posición del símbolo @
        const indiceAt = correo.indexOf('@');
        if (indiceAt === -1) return correo; // Retorna el correo tal cual si no tiene @

        // Construye el nuevo formato: primer letra, tres asteriscos, y el dominio completo
        const dominio = correo.substring(indiceAt); // Incluye el @
        return `${correo[0]}***${dominio}`;
    };

    const handleCodigoChange = (nuevoCodigo) => {
        setCodigo(nuevoCodigo);
    };

    const handleVerificar = async () => {
        setLoading(true)
        // console.log(`El código es ${codigo}`);

        const formdata = {
            'email': correocliente,
            'code': codigo
        }
        // console.log('enviare', formdata)

        try {
            const response = await axios.post(API_URL + '/validateCodeGuest', formdata);

            // console.log('la respuesta es, ', response.data)
            localStorage.setItem('k-9SMD879na89dN87m', JSON.stringify(response.data));
            setLoading(false)
            oncloserefrech(response.data); // Asegúrate de llamar a la función correctamente aquí

        } catch (error) {
            setLoading(false)

            console.error("Error al obtener los datos", error);
            // Manejo adicional del error, como establecer un estado de error

            if (error.code === 'ECONNABORTED') {
                console.log('Tiempo de espera agotado en categorías ');
            }
        }
    }

    return (


        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-2 z-10">
            {loading &&

                <CargandoSpinner txtcharge="Cargando datos, por favor espera..." />
            }

            <section className="rounded-3xl shadow-2xl bg-white lg:w-1/2">
                <div className="p-8 text-center sm:p-12  mx-auto">
                    <h2 className="text-lg font-semibold uppercase tracking-widest lg:text-xl text-[#1e3d8b]">
                        Ya has comprado anteriormente
                    </h2>

                    <p className="lg:mt-6 mt-2 lg:text-base  text-sm">


                        Para asegurar la protección de tus datos, hemos enviado un <span className='font-black'>código de verificación</span> a tu dirección <span className='font-black'>{formatearCorreo(correocliente)}</span>. Por favor, ingresa el <span className='font-black'>código</span> en el campo correspondiente para continuar. Agradecemos tu colaboración en mantener la seguridad de tu cuenta.
                    </p>
                    <CodigoInput onCodigoChange={handleCodigoChange} />

                    <div className='flex  justify-around '>
                        <button

                            name='btn-cqancelmodalcprevia'
                            id='btn-cqancelmodalcprevia'
                            onClick={onClose} className="mt-8 inline-block   rounded-full bg-red-600 text-xs px-5 py-2 font-bold text-white shadow-xl"
                        >
                            Cancelar
                        </button>
                        <button

                            name='btn-validatemodalcprevia'
                            id='btn-validatemodalcprevia'
                            className="mt-8 inline-block px-5 py-2  rounded-full bg-[#1e3d8b] text-xs font-bold text-white shadow-xl"
                            onClick={handleVerificar}
                        >
                            Validar Código
                        </button>

                    </div>
                </div>
            </section>
        </div>


    );
}

export default ModalCPrevia;
