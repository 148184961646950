// TOAST
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const API_URL_PAY =
  "https://servicehantececommerce-sjkbu6lfrq-uc.a.run.app/api/v1";

export const API_URL =
  "https://servicehantececommerce-sjkbu6lfrq-uc.a.run.app/api/v1";

export const TEAM_ID = 11;

export const STANDARD_EVENTS_META = {
  COMPLETE_REGISTRATION: "CompleteRegistration",
  ADD_PAYMENT_INFO: "AddPaymentInfo",
  ADD_TO_CART: "AddToCart",
  ADD_TO_WISH_LIST: "AddToWishlist",
  CONTACT: "Contact",
  CUSTOMIZE_PRODUCT: "CustomizeProduct",
  FIND_LOCATION: "FindLocation",
  INITIATE_CHECKOUT: "InitiateCheckout",
  LEAD: "Lead",
  PURCHASE: "Purchase",
  SEARCH: "Search",
  VIEW_CONTENT: "ViewContent",
  COMPLETE_PAYMENT: "CompletePayment"
};

export const MEDIUM_ID = {
  EMAIL: 4,
  FACEBOOK: 7, // No activo
  WHATSAPP: 19,
  MESSENGER: 20,
  CALL: 21,
};

export const CAMPAIGN_ID = {
  MARZO_2024: 9,
};

export const STATES = [
  {
    id: 1476,
    name: "Acapulco de Juarez",
    code: "39931",
  },
  {
    id: 485,
    name: "Aguascalientes",
    code: "AGU",
  },
  {
    id: 486,
    name: "Baja California",
    code: "BCN",
  },
  {
    id: 487,
    name: "Baja California Sur",
    code: "BCS",
  },
  {
    id: 490,
    name: "Campeche",
    code: "CAM",
  },
  {
    id: 488,
    name: "Chihuahua",
    code: "CHH",
  },
  {
    id: 492,
    name: "Chiapas",
    code: "CHP",
  },
  {
    id: 493,
    name: "Ciudad de México",
    code: "CMX",
  },
  {
    id: 491,
    name: "Coahuila",
    code: "COA",
  },
  {
    id: 489,
    name: "Colima",
    code: "COL",
  },
  {
    id: 494,
    name: "Durango",
    code: "DUR",
  },
  {
    id: 1409,
    name: "Estado de México",
    code: "EDMX",
  },
  {
    id: 495,
    name: "Guerrero",
    code: "GRO",
  },
  {
    id: 496,
    name: "Guanajuato",
    code: "GUA",
  },
  {
    id: 497,
    name: "Hidalgo",
    code: "HID",
  },
  {
    id: 498,
    name: "Jalisco",
    code: "JAL",
  },
  {
    id: 499,
    name: "Michoacán",
    code: "MIC",
  },
  {
    id: 500,
    name: "Morelos",
    code: "MOR",
  },
  {
    id: 502,
    name: "Nayarit",
    code: "NAY",
  },
  {
    id: 503,
    name: "Nuevo León",
    code: "NLE",
  },
  {
    id: 504,
    name: "Oaxaca",
    code: "OAX",
  },
  {
    id: 505,
    name: "Puebla",
    code: "PUE",
  },
  {
    id: 507,
    name: "Querétaro",
    code: "QUE",
  },
  {
    id: 506,
    name: "Quintana Roo",
    code: "ROO",
  },
  {
    id: 508,
    name: "Sinaloa",
    code: "SIN",
  },
  {
    id: 509,
    name: "San Luis Potosí",
    code: "SLP",
  },
  {
    id: 510,
    name: "Sonora",
    code: "SON",
  },
  {
    id: 511,
    name: "Tabasco",
    code: "TAB",
  },
  {
    id: 513,
    name: "Tamaulipas",
    code: "TAM",
  },
  {
    id: 512,
    name: "Tlaxcala",
    code: "TLA",
  },
  {
    id: 514,
    name: "Veracruz",
    code: "VER",
  },
  {
    id: 515,
    name: "Yucatán",
    code: "YUC",
  },
  {
    id: 516,
    name: "Zacatecas",
    code: "ZAC",
  },
];

// Uso de CFDI

export const INDIVIDUAL_OPTIONS = [
  { value: "G01", label: "Adquisición de mercancías." },
  { value: "G02", label: "Devoluciones, descuentos o bonificaciones." },
  { value: "G03", label: "Gastos en general." },
  { value: "I01", label: "Construcciones." },
  { value: "I02", label: "Mobiliario y equipo de oficina por inversiones." },
  { value: "I03", label: "Equipo de transporte." },
  { value: "I04", label: "Equipo de computo y accesorios." },
  { value: "I05", label: "Dados, troqueles, moldes, matrices y herramental." },
  { value: "I06", label: "Comunicaciones telefónicas." },
  { value: "I07", label: "Comunicaciones satelitales." },
  { value: "I08", label: "Otra maquinaria y equipo." },
  { value: "S01", label: "Sin efectos fiscales." },
  { value: "CP01", label: "Pagos" },
];

export const COMPANY_OPTIONS = [
  { value: "G01", label: "Adquisición de mercancías." },
  { value: "G02", label: "Devoluciones, descuentos o bonificaciones." },
  { value: "G03", label: "Gastos en general." },
  { value: "I01", label: "Construcciones." },
  { value: "I02", label: "Mobiliario y equipo de oficina por inversiones." },
  { value: "I03", label: "Equipo de transporte." },
  { value: "I04", label: "Equipo de computo y accesorios." },
  { value: "I05", label: "Dados, troqueles, moldes, matrices y herramental." },
  { value: "I06", label: "Comunicaciones telefónicas." },
  { value: "I07", label: "Comunicaciones satelitales." },
  { value: "I08", label: "Otra maquinaria y equipo." },
  {
    value: "D01",
    label: "Honorarios médicos, dentales y gastos hospitalarios.",
  },
  { value: "D02", label: "Gastos médicos por incapacidad o discapacidad." },
  { value: "D03", label: "Gastos funerales." },
  { value: "D04", label: "Donativos." },
  {
    value: "D05",
    label:
      "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
  },
  { value: "D06", label: "Aportaciones voluntarias al SAR." },
  { value: "D07", label: "Primas por seguros de gastos médicos." },
  { value: "D08", label: "Gastos de transportación escolar obligatoria." },
  {
    value: "D09",
    label:
      "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
  },
  { value: "D10", label: "Pagos por servicios educativos (colegiaturas)." },
  { value: "S01", label: "Sin efectos fiscales." },
  { value: "CP01", label: "Pagos" },
  { value: "CN01", label: "Nómina" },
];

// Toast error - light
export const TOAST_ERROR = (
  msg = "Ha ocurrido un error...vuelve a intentarlo mas tarde."
) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

//stilos
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "60px", // Altura para dispositivos móviles y por defecto
    minHeight: "30px",
    width: "100%", // Ajustar al ancho del contenedor
    borderRadius: "0.375rem", // Equivalente a 'rounded' en Tailwind
    paddingLeft: "0.5rem", // Equivalente a 'px-2' en Tailwind
    borderColor: state.isFocused ? "#4F46E5" : "#D1D5DB", // Cambia el color del borde cuando está enfocado
    boxShadow: state.isFocused ? "0 0 0 1px #4F46E5" : "none", // Sombra de enfoque
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100, // Asegúrate de que el menú desplegable esté por encima de otros elementos
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black", // Cambia el color del texto para opciones seleccionadas
    backgroundColor: state.isFocused ? "#EDE9FE" : "white", // Cambia el fondo cuando la opción está enfocada
  }),
  container: (provided) => ({
    ...provided,
    marginBottom: "1rem", // Espacio debajo del Select
  }),
};

export const ROLES = {
  CUSTOMER: "Cliente",
  STORE: "Tienda",
  ORDER_VIEWER: "order_viewer",
  IMDA: "imda",
  DKOMMITA: "dkommita",
}