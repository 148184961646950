import React from 'react';

function DatosEnvio({ direcciondeenvio }) {
    return (
        <div className='px-5 mt-5 '>
            <div className='px-4 bg-[#f7f7f7] lg:bg-white lg:border-b-2 lg:border-gary-200 lg:mb-2 lg:px-2'>
                <p className=' text-[#030303] font-black text-lg'>Detalle de envío</p>
            </div>
            <div className='flex bg-[#f7f7f7] '>
                <div className='w-1/6 flex justify-center items-center'>
                    <i className="fas fa-home text-xl text-[#757575]"></i>
                </div>
                <div className='w-5/6 p-2'>
                    <p className='text-[#474747] font-black text-lg'>
                        C.P. {direcciondeenvio.cp}
                    </p>
                    <p className='text-[#474747] text-sm'>
                        {direcciondeenvio.calle}
                    </p>
                    <div className='flex text-[#474747] text-sm mt-1'>
                        <p>{direcciondeenvio.nombre}</p>
                        <p className='mx-2'>-</p>
                        <p>{direcciondeenvio.telefono}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DatosEnvio;
