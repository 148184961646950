import React from 'react';

const TarjetaGuardada = ({ tarjeta, metodoSeleccionado, seleccionarMetodo, obtenerIconoCompania }) => {
    return (
        <div
            className={`flex items-center ${metodoSeleccionado === tarjeta.token
                ? 'bg-[#1a2869] text-white  mt-2 h-14'
                : 'text-[#1a2869]   mt-2  h-14 borde-personalizado'}`
            }
            onClick={() => seleccionarMetodo(tarjeta.token)}
        >
            <input
                type="radio"
                id={tarjeta.token}
                name="metodoPago"
                value={tarjeta.token}
                checked={metodoSeleccionado === tarjeta.token}
                onChange={() => seleccionarMetodo(tarjeta.token)}
                className="sr-only"
            />
            <label htmlFor={tarjeta.token} className='ml-5'>
                <i className={`fab ${obtenerIconoCompania(tarjeta.compania)} mr-2`}></i>
                {tarjeta.tipoTarjeta} - **** **** **** {tarjeta.numFinales}
            </label>
        </div>
    );
};

export default TarjetaGuardada;
