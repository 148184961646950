import React, { useState } from 'react';
import BannerEditModal from './BannerEditModal';
import axios from 'axios';
import CargandoSpinner from '../cargacomponetes/CargandoSpinner';
import { API_URL } from '../../constants/constants';

function BannersTable({ imgbanners, currentPage, itemsPerPage, totalPages, setCurrentPage, startIndex, endIndex, getBanners, secundary }) {
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    console.log('imgbanners', imgbanners)
    //abrir modal 
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedBanner, setSelectedBanner] = useState(null);


    //carga en el guarad banner neuvo 
    const [isLoading, setIsLoading] = useState(false);


    const handleEditClick = (banner) => {
        setSelectedBanner(banner);
        setIsModalOpen(true);
    };

    // Esta función maneja el clic en el botón de copiar
    const handleCopyToClipboard = async (url) => {
        try {
            await navigator.clipboard.writeText(url);
            alert('URL copiado al portapapeles'); // Opcional: Muestra una notificación al usuario
        } catch (error) {
            console.error('Error al copiar al portapapeles', error);
        }
    };

    const onSavePrimary = async (selectedFile, idBanner, description, navegacion) => {
        if (selectedFile) {
            setIsLoading(true); // Muestra el spinner

            const reader = new FileReader();
            // Crear un nuevo objeto Image
            const img = new Image();

            // Evento que se ejecuta cuando se termina de cargar el archivo
            reader.onloadend = async () => {
                // Crear un nuevo objeto Image
                const img = new Image();

                // Evento que se ejecuta cuando se termina de cargar la imagen
                img.onload = async () => {
                    // Crear un canvas
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Establecer las dimensiones deseadas para el canvas (puedes ajustar esto)
                    canvas.width = img.width / 2; // Reducir el tamaño por 2, por ejemplo
                    canvas.height = img.height / 2;

                    // Dibujar la imagen en el canvas con el tamaño especificado
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // Convertir el canvas a una imagen en base64 con la calidad especificada
                    const dataUrl = canvas.toDataURL('image/jpeg', 0.7); // Ajustar la calidad aquí, 0.7 por ejemplo

                    // Preparar el payload para la solicitud POST
                    const payload = {
                        "id_banner": parseInt(idBanner),
                        "titulo": selectedFile.name,
                        "descripcion": description,
                        "url_imagen": dataUrl,
                        "navegacion": navegacion,
                        "id_usuario": info['$udi09345']
                    };
                    // console.log('lkeljada', payload)
                    try {
                        const config = {
                            headers: {
                                'Authorization': info['$tk3m09s']
                            },
                            timeout: 10000000
                        };
                        // Realizar la solicitud POST con Axios
                        const response = await axios.post(API_URL + '/updateBannersPromotionals', payload, config);
                        // Cierra el modal después de la operación
                        setIsModalOpen(false);
                        getBanners();
                    } catch (error) {
                        // Manejar cualquier error que ocurra durante la solicitud
                        console.error('Error al enviar los datos a la API:', error);
                        if (error.code === 'ECONNABORTED') {
                            console.log('Tiempo de espera agotado updatebanners');
                        }
                    } finally {
                        setIsLoading(false); // Ocultar el spinner independientemente del resultado
                    }
                };

                // Cargar la imagen en el objeto Image
                img.src = reader.result;
            };

            // Leer el archivo como URL de datos
            reader.readAsDataURL(selectedFile);
        }
    };

    const onSaveSecundary = async (selectedFile, idBanner, description, navegacion, isActive, urlimg, ) => {
        // console.log('on save secundary', selectedFile, urlimg);
        
        if (selectedFile) {
            setIsLoading(true); // Muestra el spinner
    
            const reader = new FileReader();
    
            reader.onloadend = async () => {
                const img = new Image();
    
                img.onload = async () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
    
                    canvas.width = img.width / 2; // Reducir el tamaño por 2, por ejemplo
                    canvas.height = img.height / 2;
    
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
                    const dataUrl = canvas.toDataURL('image/jpeg', 0.7); // Ajustar la calidad aquí, 0.7 por ejemplo
    
                    const payload = {
                        "id_banner": parseInt(idBanner),
                        "titulo": selectedFile.name,
                        "descripcion": description,
                        "url_imagen": dataUrl,
                        "navegacion": navegacion,
                        "activo": isActive,
                        "id_usuario": info['$udi09345']
                    };
    
                    try {
                        const config = {
                            headers: {
                                'Authorization': info['$tk3m09s']
                            },
                            timeout: 10000000
                        };
                        const response = await axios.put(API_URL + '/managmentSecondBanners', payload, config);
                        setIsModalOpen(false);
                        getBanners();
                    } catch (error) {
                        console.error('Error al enviar los datos a la API:', error);
                        if (error.code === 'ECONNABORTED') {
                            console.log('Tiempo de espera agotado updatebanners');
                        }
                    } finally {
                        setIsLoading(false);
                    }
                };
    
                img.src = reader.result;
            };
    
            reader.readAsDataURL(selectedFile);
        } else {
            // Utilizar urlimg si no se carga una nueva imagen
            const payload = {
                "id_banner": parseInt(idBanner),
                "titulo": 'Banert-'+idBanner, // Usar banertitulo
                "descripcion": description,
                "url_imagen": urlimg, // Usar la URL de la imagen existente
                "navegacion": navegacion,
                "activo": isActive,
                "id_usuario": info['$udi09345']
            };
    
            try {
                const config = {
                    headers: {
                        'Authorization': info['$tk3m09s']
                    },
                    timeout: 10000000
                };
                const response = await axios.put(API_URL + '/managmentSecondBanners', payload, config);
                setIsModalOpen(false);
                getBanners();
            } catch (error) {
                console.error('Error al enviar los datos a la API:', error);
                if (error.code === 'ECONNABORTED') {
                    console.log('Tiempo de espera agotado updatebanners');
                }
            }
        }
    };
    


    return (
        <div className='mt-4'>
            <BannerEditModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={secundary ? onSaveSecundary : onSavePrimary} // Uso condicional de onSave
                banner={selectedBanner || {}} />


            {isLoading && <CargandoSpinner txtcharge="Guardado Imagen" />}

            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">Imagen</th>
                        <th scope="col" className="px-6 py-3">Nombre</th>
                        <th scope="col" className="px-6 py-3">Status</th>


                        <th scope="col" className="px-6 py-3">Navegación</th>
                        <th scope="col" className="px-6 py-3">Acciones</th>

                    </tr>
                </thead>
                <tbody>
                    {imgbanners.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage).map((imgbann, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td onClick={() => handleEditClick(imgbann)} className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white"><img width="300" height="200"
 src={imgbann.url} alt={imgbann.title} className='h-20 w-32 object-contain' /></td>
                            <td onClick={() => handleEditClick(imgbann)} className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white">{imgbann.title}</td>

                            <td
                                onClick={() => handleEditClick(imgbann)}
                                className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white"
                            >
                                <div
                                    className={
                                        `rounded px-4 py-2 font-medium text-gray-900 text-center whitespace-wrap dark:text-white ${imgbann.active === true ? 'bg-green-600 text-white' : imgbann.active === false ? 'bg-red-600 text-white' : ''}`
                                    }
                                >
                                    {imgbann.active === true ? 'Activo' : imgbann.active === false ? 'Inactivo' : 'NA'}
                                </div>

                            </td>

                            <td onClick={() => handleEditClick(imgbann)} className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white">{imgbann.navigateTo}</td>
                            <td className=" px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white">
                                <a href={imgbann.navigateTo} target="_blank" rel="noopener noreferrer" className="">

                                    Visualizar página de destino
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='flex justify-between bg-gray-200 dark:bg-gray-700 text-black dark:text-white p-2'>
                <div>Mostrando {startIndex + 1}-{endIndex} de {imgbanners.length} banners</div>
                <div>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button key={index} name={index} id={index}className={`px-4 py-2 mx-2 rounded-lg text-white transition-colors duration-300 ${currentPage === index ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-300 hover:bg-gray-400'}`} onClick={() => setCurrentPage(index)}>
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BannersTable;
