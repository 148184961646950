import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Headers/Header';
import Perfil1 from '../../../assets/Perfil1.webp';
import ImageComponent from '../../../componentes/comparador/ImageComponent';
import CardPerfil from '../../../componentes/perfil/CardPerfil';
import TabComponent from '../../../componentes/perfil/TabComponent';
import Footer from '../../../componentes/footer/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { API_URL } from '../../../constants/constants';
import { useBeforeUnload } from '../../../utils/Analitycs/analytics';

function PerfilUser() {

    const info = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const navigate = useNavigate()
    // const [infoPers, setInfoPers] = useState([])

    const [usuario, setUsuario] = useState({});

    // El usuario ha sálido de la página
    useBeforeUnload('Perfil');

    useEffect(() => {
        const getInfoUser = async () => {
            try {
                const response = await axios.post(API_URL + '/getDataUser', { email: info['$email'] }, { headers: { 'Authorization': info["$tk3m09s"] } })
                // console.log('Telefono movil es: ', response.data.telefono_movil)
                const user = {
                    nombre: response.data.nombre,
                    correo: response.data.correo,
                    telefono: response.data.telefono_movil,
                    //Podrá cambiar su contraseña
                    contrasena: 'contraseña',
                    imagenPerfil: response.data.imagen
                }
                setUsuario(user)
            } catch (error) {
                // console.log('El token ha expirado.')
                // console.log('Agregar toast con mensaje de que la sesión ha finalizado')
                //Verificar que el token aún no ha expirado
                if (error.response.data.error == 'refresh') {
                    localStorage.removeItem('k-9SMD879na89dN87m')
                    navigate('/');
                    return
                }
            }
        }

        window.scrollTo(0, 0);


        if (info) {
            getInfoUser()
        }
        window.scrollTo(0, 0);

    }, [])


    return (
        <div>
            <Header />
            <ImageComponent backgroundImage={Perfil1} />
            <div className='flex-grow'>

                <CardPerfil nombre={usuario?.nombre} imagenPerfil={usuario?.imagenPerfil} />
                <TabComponent usuario={usuario} />
            </div>
            <Footer />
        </div>
    );
}

export default PerfilUser;
