import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'


function TableProducts({ productsList, total, currentPage, paginasTotales, cambiarPagina, handleSearch, valueSearch }) {
    const [isFocused, setIsFocused] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const navigate = useNavigate()
    const goToEdit = (product) => {
        navigate('/addProduct', { state: { product: product } });
    }

    const toggleProductSelection = (productId) => {
        setSelectedProducts(prevSelectedProducts =>
            prevSelectedProducts.includes(productId)
                ? prevSelectedProducts.filter(id => id !== productId)
                : [...prevSelectedProducts, productId]
        );
    };


    return (
        <div>
            {/* Tabla de productos */}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">


                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded" >
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {/* <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                        </div>

                                        
                                    </th> */}

                            <th scope="col" className="px-6 py-3">
                                SKU
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Producto
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Estatus
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Inventario
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Canales de venta
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Categoria
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ...productsList?.map((product) => {
                                return (
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        {/* <td className="w-4 p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                            </td> */}

                                        <td className="px-6 py-4 cursor-pointer">
                                            {product.sku}
                                        </td>
                                        <th scope="row" onClick={() => goToEdit(product)} className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white">
                                            <div className='xl:flex'>
                                                <img width="300" height="200"
                                                    className='w-20 h-20 mr-5' src={product.imagen} alt={product.titulo} />
                                                {product.titulo}
                                            </div>
                                        </th>
                                        <td onClick={() => goToEdit(product)} className="px-6 py-4 cursor-pointer">
                                            {product.stock.disponible <= 0
                                                ? <div className='bg-red-700 text-white text-center rounded px-2'>Inactivo</div>
                                                : <div className='bg-green-700 text-white text-center rounded'>Activo</div>
                                            }
                                        </td>

                                        <td onClick={() => goToEdit(product)} className="px-6 py-4 cursor-pointer">
                                            {product.stock.disponible}
                                        </td>
                                        <td onClick={() => goToEdit(product)} className="px-6 py-4 cursor-pointer">
                                            {/* <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a> */}
                                            4
                                        </td>
                                        <td onClick={() => goToEdit(product)} className="px-6 py-4 cursor-pointer">
                                            {product.categoria}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                {/* Paginado */}
                <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4 pb-4 px-4  dark:bg-gray-700" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Mostrando <span className="font-semibold text-gray-900 dark:text-white">1-{productsList.length}</span> de <span className="font-semibold text-gray-900 dark:text-white">{total}</span></span>
                    <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 space-x-2">
                        {[...Array(paginasTotales)].map((_, index) => (
                            <button

                                name='btn-paginated'
                                id='btn-paginated'
                                key={index}
                                onClick={() => cambiarPagina(index + 1)}
                                className={`px-4 py-2 rounded-lg text-white transition-colors duration-300 ${currentPage === index + 1
                                    ? 'bg-blue-600 hover:bg-blue-700'
                                    : 'bg-gray-300 hover:bg-gray-400'
                                    }`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default TableProducts