import React, { useState, useEffect, useRef } from 'react';

function InfoPedido({ pedido, canceloedido,dtosDireccion }) {
    // console.log('el pedido es:', pedido);

    // Asegúrate de que 'pedido' contiene los datos necesarios
    if (!pedido) {
        return <p>Cargando detalles del pedido...</p>;
    }



    const procesarMetodoPago = (metodo) => {
        switch (metodo) {
            case 'Nueva credito':
                return 'Tarjeta de Crédito';
            case 'Nueva debito':
                return 'Débito';
            case 'oxxo':
                return 'Efectivo en Oxxo';
            case 'Débito':
                return 'Tarjeta de Débito';
            case 'transferencia':
                return 'Transferencia Bancaria';
            default:
                return metodo;
        }
    };


    return (
        <div className='flex flex-col items-center px-2 justify-center mt-2 lg:px-3 px-5 '>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-base mr-2">Número de pedido:</p>
                <h2 className="text-[#354C5B] text-lg font-semibold text-base">{pedido.idPedido}</h2>
            </div>

            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-base mr-2">Fecha de pedido:</p>
                <h2 className="text-[#354C5B] text-base font-semibold">{pedido.fechaPedido}</h2>

            </div>

            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-base mr-2">Método de pago:</p>
                <h2 className="text-[#354C5B] text-sm font-semibold uppercase">
                    {procesarMetodoPago(pedido.metodopago)}

                </h2>
            </div>

            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Costo de Productos:</p>
                <h2 className="text-[#354C5B] text-lg font-semibold">
                    {pedido.totalproductos === 0 ? "Envío Gratis" : `$ ${pedido.totalproductos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN`}

                </h2>
            </div>
            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Costo de envío:</p>
                <h2 className="text-[#354C5B] text-lg font-semibold">
                    {pedido.costoEnvio === 0 ? "Envío Gratis" : `$ ${pedido.costoEnvio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN`}

                </h2>
            </div>

            <div className="flex items-center justify-between w-full mt-2">
                <p className="text-[#757575] text-md mr-2">Total del pedido:</p>
                <h2 className="text-[#354C5B] text-lg font-semibold">
                    $  {pedido.totalPedido.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN
                </h2>
            </div>


         {/* Información de entrega */}
         <div className="w-full mt-6">
                <p className="font-bold text-lg mb-2">INFORMACIÓN <span className='text-[#1e3d8b]'>  DE ENTREGA</span></p>
                <div className="flex items-center justify-between mt-2">
                    <p className="text-[#757575] text-md">Recibe:</p>
                    <p className="text-[#354C5B] font-semibold text-md">{dtosDireccion.contact_name}</p>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <p className="text-[#757575] text-md">Dirección:</p>
                    <p className="text-[#354C5B] font-semibold text-sm text-right">{dtosDireccion.calle}, No. Ext: {dtosDireccion.noExterior}{dtosDireccion.noInterior && `, No. Int: ${dtosDireccion.noInterior}`}</p>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <p className="text-[#757575] text-md">Colonia:</p>
                    <p className="text-[#354C5B] font-semibold text-sm text-right">{dtosDireccion.colonia}</p>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <p className="text-[#757575] text-md">Ciudad y CP:</p>
                    <p className="text-[#354C5B] font-semibold text-sm text-right">{dtosDireccion.ciudad}, {dtosDireccion.estado}, {dtosDireccion.cp}</p>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <p className="text-[#757575] text-md">País:</p>
                    <p className="text-[#354C5B] font-semibold text-smtext-right">{dtosDireccion.pais}</p>
                </div>
                <div className="flex items-center justify-between mt-2 text-right">
                    <p className="text-[#757575] text-md">Teléfono:</p>
                    <p className="text-[#354C5B] font-semibold text-sm text-right ">{dtosDireccion.telefono}</p>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <p className="text-[#757575] text-md">Referencia:</p>
                    <p className="text-[#354C5B] font-semibold text-sm text-right">{dtosDireccion.referencia}</p>
                </div>
            </div>

        </div>
    );
}

export default InfoPedido;
