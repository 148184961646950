import { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import '../../assets/style/generalstyles.css'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../constants/constants';
import { SnipClicksLandingPage, useBeforeUnload } from '../../utils/Analitycs/analytics';
import { dateDiffFromNow } from '../../utils/utils';

import Header from '../../componentes/Headers/Header';
import Footer from '../../componentes/footer/Footer';
import CardSkeletonLoader from '../../componentes/cargacomponetes/ImgSkeletonLoader';
import ErrorPage from '../erorpage/ErrorPage';

const Carousel = lazy(() => import('../../componentes/homecompoenntes/Carousel'));
const ScrollableCategories = lazy(() => import('../../componentes/sliders/ScrollableCategories'));
const Banner = lazy(() => import('../../componentes/banerimagenes/Banner'));
const ListaProductos = lazy(() => import('../../componentes/productos/ListaProductos'));
const AnimationBanner = lazy(() => import('../../componentes/banerimagenes/AnimationBanner'));
const BannersSecundaryList = lazy(() => import('../../componentes/banerimagenes/BannersSecundaryList'));

import imgCategorias from '../../assets/GaranriaAnios.webp';

function Home() {
    const [imgbanners, setImgBanners] = useState([]);
    const [listProductsSeller, setListProductsSeller] = useState([]);
    const [listProductsSeason, setListProductsSeason] = useState([]);
    const [imgBannersSecundary, setImgBannersSecundary] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBestSellers, setIsLoadingBestSellers] = useState(false);
    const [isLoadingProductsSeason, setIsLoadingProductsSeason] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();

    useBeforeUnload('landing_page'); // Función para saber cuándo y de dónde salió el usuario.

    const fetchBanners = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/getBanners`, { timeout: 15000 });
            setImgBanners(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchBestSellers = useCallback(async () => {
        setIsLoadingBestSellers(true);
        try {
            const response = await axios.post(`${API_URL}/getProductsByCategorySell`, { categoria: "mas_vendidos" }, { timeout: 15000 });
            const filteredAndSortedProducts = response.data
                .filter(product => product.stock.disponible > product.stock.mostrado && product.id_odoo)
                .sort((a, b) => b.precio.cantidad - a.precio.cantidad);
            setListProductsSeller(filteredAndSortedProducts);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoadingBestSellers(false);
        }
    }, []);

    const fetchSecondaryBanners = useCallback(async () => {
        try {
            const response = await axios.get(`${API_URL}/getSecondBanners`, { timeout: 15000 });
            setImgBannersSecundary(response.data.success);
        } catch (error) {
            setError(error.message);
        }
    }, []);

    const fetchProductsSeason = useCallback(async () => {
        setIsLoadingProductsSeason(true);
        try {
            const response = await axios.post(`${API_URL}/getProductsByCategorySell`, { categoria: "ofertas_temporada" }, { timeout: 15000 });
            const filteredAndSortedProducts = response.data
                .filter(product => product.stock.disponible > product.stock.mostrado && product.id_odoo)
                .sort((a, b) => b.precio.cantidad - a.precio.cantidad);
            setListProductsSeason(filteredAndSortedProducts);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoadingProductsSeason(false);
        }
    }, []);

    useEffect(() => {
        const extractUTMParameters = () => {
            const searchParams = new URLSearchParams(location.search);
            const utmSource = searchParams.get('utm_source');
            const utmMedium = searchParams.get('utm_medium');
            const utmCampaign = searchParams.get('utm_campaign');
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            const lastVisitDateStr = window.localStorage.getItem('utm');
            const lastVisitDate = lastVisitDateStr ? new Date(JSON.parse(lastVisitDateStr).date) : null;
            lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);
            if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
                window.localStorage.removeItem("utm");
            }
            if (utmSource && utmMedium && utmCampaign) {
                const valuesUTM = {
                    "utmSource": utmSource,
                    "utmMedium": utmMedium,
                    "utmCampaign": utmCampaign,
                    "date": today
                };
                window.localStorage.setItem("utm", JSON.stringify(valuesUTM));
            }
        };

        extractUTMParameters();
    }, [location.search]);

    useEffect(() => {
        window.scrollTo(0, 0)
        Promise.all([fetchBanners(), SnipClicksLandingPage()])
    }, [fetchBanners]);

    useEffect(() => {
        if (imgbanners.length > 0) {
            fetchBestSellers();
        }
    }, [imgbanners, fetchBestSellers]);

    useEffect(() => {
        if (listProductsSeller.length > 0) {
            fetchSecondaryBanners();
        }
    }, [listProductsSeller, fetchSecondaryBanners]);

    useEffect(() => {
        if (imgBannersSecundary.length > 0) {
            fetchProductsSeason();
        }
    }, [imgBannersSecundary, fetchProductsSeason]);

    return (
        <div className="homeconte overscroll-x-none">
            <Header />
            <Suspense fallback={<CardSkeletonLoader />}>
                <Carousel images={imgbanners} />
            </Suspense>

            <div className='mb-20'>
                <Suspense fallback={<CardSkeletonLoader />}>
                    <ScrollableCategories />
                </Suspense>
            </div>

            {imgbanners.length > 0 && (
                <Suspense fallback={<CardSkeletonLoader />}>
                    <AnimationBanner />
                </Suspense>
            )}

            <div className='mb-20'>
                {isLoadingBestSellers ? <CardSkeletonLoader /> :
                    <Suspense fallback={<CardSkeletonLoader />}>
                        <ListaProductos
                            titulo="LOS MÁS "
                            alldate='Ver todos'
                            subtitulo='VENDIDOS'
                            productos={listProductsSeller}
                            categoria='/CategoriasEspeciales'
                            cat='mas_vendidos'
                        />
                    </Suspense>
                }
            </div>

            <div className='mb-2'>
                {imgBannersSecundary.length > 0 && (
                    <Suspense fallback={<CardSkeletonLoader />}>
                        <BannersSecundaryList goclick={true} urlpage='/combos' listadebaners2={imgBannersSecundary} />
                    </Suspense>
                )}
            </div>

            <div className='mb-20'>
                {listProductsSeason.length > 0 && (
                    <Suspense fallback={<CardSkeletonLoader />}>
                        <ListaProductos
                            titulo="Ofertas "
                            alldate='Ver todos'
                            subtitulo='De Temporada'
                            productos={listProductsSeason}
                            categoria='/CategoriasEspeciales'
                            cat='ofertas_temporada'
                        />
                    </Suspense>
                )}
            </div>

            {listProductsSeason.length > 0 && (
                <div>
                    <Suspense fallback={<CardSkeletonLoader />}>
                        <Banner imageUrl={imgCategorias} />
                    </Suspense>
                    <Suspense fallback={<CardSkeletonLoader />}>
                        <Footer />
                    </Suspense>
                </div>
            )}

            {error && <ErrorPage message={error} />}

        </div>
    );
}

export default Home;