import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

const ModalCodigoPostal = ({ setCodigoPostal, onClose }) => {
    const [valorTemporal, setValorTemporal] = useState('');
    const [colonias, setColonias] = useState([]);
    const [isColoniaSelectDisabled, setIsColoniaSelectDisabled] = useState(true);
    const [neighborhood, setNeighborhood] = useState('');

    useEffect(() => {
        const storedCodigoPostal = localStorage.getItem('codigoPostal');
        const storedNeighborhood = localStorage.getItem('neighborhood');
        if (storedCodigoPostal) setValorTemporal(storedCodigoPostal);
        if (storedNeighborhood) setNeighborhood(storedNeighborhood);
    }, []);

    useEffect(() => {
        localStorage.setItem('codigoPostal', valorTemporal);
        localStorage.setItem('neighborhood', neighborhood);
    }, [valorTemporal, neighborhood]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (valorTemporal.length === 5 && !isNaN(valorTemporal)) {
                handlePostalCodeChange(valorTemporal);
            }
        }, 500);
        return () => clearTimeout(handler);
    }, [valorTemporal]);

    // Efecto para resetear el valor de la colonia cuando cambia el código postal
    useEffect(() => {
        setNeighborhood('');
        setIsColoniaSelectDisabled(true);
    }, [valorTemporal]);

    const handlePostalCodeChange = async (nuevoCodigoPostal) => {
        try {
            const formData = { cp: nuevoCodigoPostal };
            const response = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getDistricts', formData);
            if (response.data && Array.isArray(response.data.data)) {
                setColonias(response.data.data);
                setIsColoniaSelectDisabled(false);
            } else {
                throw new Error('No data returned');
            }
        } catch (error) {
            console.error("Error al obtener las colonias:", error);
            setColonias([]);
            setIsColoniaSelectDisabled(true);
        }
    };

    const selectOptions = colonias.map(colonia => ({ value: colonia.text, label: colonia.text }));

    const handleSave = () => {
        setCodigoPostal({ codigoPostal: valorTemporal, colonia: neighborhood });
        onClose();
    };

    const isSaveButtonDisabled = valorTemporal.length !== 5 || !neighborhood || isNaN(valorTemporal);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center space-y-4 w-4/5 border border-gray-100 lg:w-1/3">
                <button


                    name='btn-closemodalcp'
                    id='btn-closemodalcp'
                    onClick={onClose} className="self-end px-1 text-2xl text-red-500 font-semibold rounded">
                    <i className="fas fa-times"></i>
                </button>
                <div className="text-base font-semibold text-black lg:text-xl">
                    <p>Actualizar Código Postal</p>
                </div>
                <div className='lg:flex'>
                    <input
                        type="number"
                        value={valorTemporal}
                        onChange={(e) => setValorTemporal(e.target.value.replace(/[^0-9]/g, '').slice(0, 5))}
                        className="border border-gray-300 p-2 rounded mb-4 lg:mb-0 lg:mr-2 lg:w-40"
                        placeholder="Ingrese nuevo CP"
                    />
                    <Select
                        isDisabled={isColoniaSelectDisabled}
                        options={selectOptions}
                        value={selectOptions.find(option => option.value === neighborhood)}
                        onChange={(selectedOption) => setNeighborhood(selectedOption ? selectedOption.value : '')}
                        placeholder="Seleccione una Colonia"
                        menuPlacement="top"
                    />
                </div>
                <button name='btn-savemodalcp'
                    id='btn-savemodalcp' onClick={handleSave}
                    className={`inline-block w-full rounded-lg px-5 py-3 font-medium sm:w-auto ${isSaveButtonDisabled ? 'bg-gray-300 text-gray-500' : 'bg-[#e9bf56] text-black'}`}
                    disabled={isSaveButtonDisabled}
                >
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default ModalCodigoPostal;
