import React, { useEffect } from 'react';
import { roundToTwoDecimals } from '../../utils/utils';

function PricesInventoryProduct({
    price, setPrice,
    priceDiscount, setPriceDiscount,
    inventory, setInventory,
    inventoryMin, setInventoryMin,
    procendescuento, setDiscount,
    precioCubreEnvio, setPrecioCubreEnvio, // New state and setter
    setMsi, msi, priceAtoZne, setPriceAtoZne, inventoryOdoo,
    tipoEnvio, setTipoEnvio,
    precioEstafeta, setPrecioEstafeta

}) {




    useEffect(() => {
        // Redondeo supremo        
        const priceFloat = parseFloat(price);

        // console.log('precio coniva y sin descuento', priceFloat)

        const discountFloat = parseFloat(procendescuento).toFixed(2);
        //console.log('Decuento A', discountFloat)

        //pasarar el desucneot de ejemplo 10 a 0.10 para las operaicones 
        let descuentooperation = discountFloat / 100


        let precio_sin_iva = roundToTwoDecimals(priceFloat / 1.16)

        //console.log('------precio_sin_iva', precio_sin_iva)

        let preci_sinivaDeceunto = roundToTwoDecimals(precio_sin_iva * (1 - descuentooperation))
        //  console.log('------precio_sin_ivacondesntoo---', preci_sinivaDeceunto)

        let total = roundToTwoDecimals(preci_sinivaDeceunto * 1.16)
        // console.log('******El precio final es ', total)

        //console.log('*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*  -_- :O :3 :B  ')




       // console.log("FINAL: ", total)

        setPriceDiscount(total)

    }, [price, procendescuento, setPriceDiscount]); // Dependencias del efecto




    const handleSelectChange = (e) => {
        setTipoEnvio(e.target.value);
    };

    return (
        <div className='mb-10'>
            <div className='bg-gray-100 w-3/5 py-10 px-5 rounded shadow-xl' >
                <p className='text-xl mb-5 font-semibold text-gray-700'>Precios e inventario del producto</p>
                <p className='text-xl mb-5'>Precios</p>

                <div className='flex sm:flex-col xl:flex-row justify-between mb-5'>

                    <div className='flex flex-col'>
                        <label htmlFor="precio_base">Precio base</label>
                        <input onChange={(event) => setPrice(event.target.value)} value={price}
                            className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='$0.00' />
                    </div>

                    <div className='flex flex-col  justify-center items-center'>

                        <label htmlFor="precio_base">% descuento</label>
                        <input onChange={(event) => setDiscount(event.target.value)} value={procendescuento}
                            min="0"
                            className='rounded p-1 border border-slate-300 hover:border-indigo-300 w-1/2 ml-2' type="number" placeholder='$0.00' />

                    </div>

                    <div className='flex flex-col xl:pr-20'>
                        <label htmlFor="precio_descuento">Precio con descuento</label>
                        <input onChange={(event) => setPriceDiscount(event.target.value)} value={priceDiscount} readOnly disabled
                            className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='$0.00' />
                    </div>
                </div>

                <div className='flex'>
                    <div className='w-1/2'>
                        {/* Nuevo input similar a Precio Base pero para 'priceAtoZne' */}
                        <p className='text-xl mb-1 mt-4'>Precio Distribuidor </p>
                        <div className='flex flex-col'>
                            <label htmlFor="precio_autozone">Precio Autozone</label>
                            <input onChange={(event) => setPriceAtoZne(event.target.value)} value={priceAtoZne}
                                className='rounded w-3/5 p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='$0.00' />
                        </div>
                    </div>

                    <div className='w-1/2'>
                        {/* Selector de MSI */}
                        <p className='text-xl mb-1 mt-4'>Opciones de financiamiento</p>
                        <div className='flex flex-col xl:pr-20'>
                            <label htmlFor="msi">Meses sin intereses</label>
                            <select onChange={(event) => setMsi(event.target.value)} value={msi}
                                className='rounded p-1 border border-slate-300 hover:border-indigo-300 w-4/5 mt-1'>
                                <option value="0">Sin meses</option>
                                <option value="3">3 meses</option>
                                <option value="6">6 meses</option>
                                <option value="9">9 meses</option>
                                <option value="12">12 meses</option>
                                <option value="18">18 meses</option>
                                <option value="24">24 meses</option>
                            </select>
                        </div>
                    </div>

                </div>

                <p className='text-xl mb-1 mt-4'>Inventario</p>

                <div className='flex sm:flex-col xl:flex-row justify-between'>
                    <div className='flex flex-col'>
                        <label htmlFor="precio_inicial">Inventario inicial</label>
                        <input onChange={(event) => setInventory(event.target.value)} value={inventory}
                            className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='$0.00' />
                        <div className='mt-2'>
                            <h2>El inventario en Odoo es </h2>
                            <p>{inventoryOdoo}</p>
                        </div>
                    </div>


                    <div className='flex flex-col xl:pr-20'>
                        <label htmlFor="precio_minimo">Minimo para mostrarse</label>
                        <input onChange={(event) => setInventoryMin(event.target.value)} value={inventoryMin}
                            className='rounded p-1 border border-slate-300 hover:border-indigo-300' type="number" placeholder='$0.00' />
                    </div>
                </div>


                <p className='text-xl mb-1 mt-4'>Datos Envio</p>

                <div className="flex justify-between px-4 mt-2 items-start">
                    <div className="mb-4">
                        <p className="mr-2">Selecciona un envío</p>
                        <select
                            id="shipping"
                            className="p-2 border rounded"

                            onChange={(event) => setTipoEnvio(event.target.value)} value={tipoEnvio}
                        >
                            <option value="" disabled>Selecciona una opción</option>
                            <option value="castores">Envío Castores</option>
                            <option value="estafeta">Envío Estafeta</option>
                        </select>
                    </div>

                    {tipoEnvio === 'castores' && (
                        <div className="flex flex-col justify-between  rounded px-4">
                            <div className='px-10'>
                                <p>Presupuesto que cubre envío</p>
                                <input
                                    onChange={(event) => setPrecioCubreEnvio(event.target.value)}
                                    value={precioCubreEnvio}
                                    className='rounded p-1 border border-slate-300 hover:border-indigo-300 w-4/5 mt-1'
                                    type="number"
                                    placeholder='$0.00'
                                />
                            </div>
                            <div className='text-justify w-4/5 mx-auto mt-4'>
                                <p className='text-xs text-gray-900'>
                                    <span className='text-base text-red-600 font-black'>*</span>
                                    Este valor representa el máximo que cubre el envío. Para montos inferiores, el envío es gratuito. Si se supera este presupuesto, el cliente deberá pagar la diferencia.
                                </p>
                            </div>
                        </div>
                    )}

                    {tipoEnvio === 'estafeta' && (
                        <div className=" rounded">
                            <div className='px-10'>
                                <p>Costo de envio Estafeta</p>
                                <input
                                    onChange={(event) => setPrecioEstafeta(event.target.value)}
                                    value={precioEstafeta}
                                    className='rounded p-1 border border-slate-300 hover:border-indigo-300 w-4/5 mt-1'
                                    type="number"
                                    placeholder='$0.00'
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className='flex flex-col xl:pr-20'>

                </div>

            </div>
        </div>
    )
}

export default PricesInventoryProduct