import React, { useEffect, useState } from 'react'
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi'
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../constants/constants';
import TablaContactos from '../../componentes/Admi/TablaContactos';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import Sidebar from '../../componentes/Admi/Sidebar';

function ContactarClientes() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const [datosContacto, setDatosContacto] = useState([]);

    const cargarDatos = async (tk) => {
        setIsLoading(true); // Inicia la carga

        try {
            const response = await fetch(`${API_URL}/getContactsSupport`, {
                headers: {
                    Authorization: tk,
                }
            });
            const data = await response.json();

            if (data['error'] == 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }
      //      console.log('los cdatosd e contato son:', data.success)

            setDatosContacto(data.success)
        } catch (error) {
            console.log(error);
            console.log('Ha ocurrido un error');
        }
        setIsLoading(false); // Finaliza la carga

    };


    useEffect(() => {
        cargarDatos(info['$tk3m09s'])
    }, [])

    return (
        <div className=''>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <div className='p-4 sm:ml-64'>
                    {/* <h1 className='text-2xl xl:text-5xl font-bold my-5 text-gray-700'>Bienvenido</h1> */}
                    <p className='text-2xl xl:text-3xl font-bold my-5 text-gray-700'>Contacto a clientes </p>

                    <TablaContactos datos={datosContacto} />

                </div>
            )}

        </div>
    )
}

export default ContactarClientes