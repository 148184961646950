import React from 'react';

const EstafetaProducts = ({ productos }) => {
    const productosEstafeta = productos.filter(producto => producto.t_envio === 'estafeta');

    return (
        <>
            {productosEstafeta.length > 0 && (
                <div className="mt-4 bg-gray-100 p-4 rounded">
                    <p className='  shrink-0 px-6 font-black my-2 text-lg text-left text-black'>
                        Datos de estafeta
                    </p>
                    <h3 className="text-base text-[#012a45] mb-2 px-6">Se enviarán por estafeta los siguientes productos:</h3>
                    <ul className='px-6'>
                        {productosEstafeta.map((producto, index) => (
                            <li key={index} className="mb-2 flex">
                                <img src={producto.imagen} className='w-20 mr-6' alt="" />
                                <div>
                                    <p className="text-[#757575] text-sm">{producto.nombre}</p>
                                    <p className="text-[#012a45] text-base font-semibold text-sm">{producto.sku}</p>
                                    <p className="text-[#012a45] text-xs">Número de piezas: {producto.cantidad}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default EstafetaProducts;
