// ErrorPage.js

import React from 'react';
import Errorimg from '../../assets/error500.webp'

function ErrorPage() {
  return (
    <div className="flex justicy-center py-5 h-screen lg:h-auto ">
      <img width="300" height="200"
 src={Errorimg} className='h-3/5 mx-auto mt-0 lg:w-4/5 lg:h-auto object-cover'/>
      
    </div>
  );
}

export default ErrorPage;
