import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductStore } from '../../store/bookStore';
import { API_URL } from '../../constants/constants';
import { search } from '../../utils/PixelMeta/metaPixelEvents';
import { SnipKeyWordSearched } from '../../utils/Analitycs/analytics';

const SearchBar = ({ onclose }) => {
    const token = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { fetchProducts } = useProductStore();

    const inputRef = useRef(null); // Crear una referencia para el input

    const getProducts = async () => {
        try {
            const url = token && token['$r07sad'] === 'Tienda'
                ? `${API_URL}/getProductsToSearch?rol=Tienda`
                : `${API_URL}/getProductsToSearch`;

            const response = await fetch(url);
            const data = await response.json();
            setProducts(data.success);
        } catch (error) {
            console.error("Error al obtener los productos:", error);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        inputRef.current.focus(); // Seleccionar automáticamente el input
    }, []);

    const filteredProducts = products.filter(product =>
        product.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.sku.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const goproductos = async (product) => {
        await search(searchTerm, product);
        const titulojunto = formatTitleForUrl(product.titulo);
        window.location.href = `/product/${titulojunto}/${product.id_producto}`;
    };

    // Nueva forma de enrutamiento
    const formatTitleForUrl = (title) => {
        return title.toLowerCase().replace(/\s+/g, '-');
    };

    const handleSearchSubmit = () => {
        if (searchTerm.trim() !== "") {
            // search(searchTerm, "");
            navigate('/search-results', { state: { searchTerm, filteredProducts } });
            onclose();
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    return (
        <div>
            <div className="relative w-80 mx-auto lg:w-1/2 lg:mb-4 rounded-md text-blue-900">
                <label htmlFor="Search" className="sr-only">Buscar</label>
                <input
                    type="text"
                    id="Search20"
                    placeholder="Encuentra todo para tu taller"
                    className="w-full rounded-md border-2 border-[#1a2869] p-2.5 pe-10 shadow-sm sm:text-sm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                    ref={inputRef} // Asignar la referencia al input
                />
                <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
                    <button

                        name='btn-search'
                        id='btn-search'
                        type="button"
                        className="text-blue-900 hover:text-gray-700"
                        onClick={handleSearchSubmit}
                    >
                        <span className="sr-only">Buscar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-4 w-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </button>
                </span>
            </div>

            {
                searchTerm && (
                    <div className="w-full lg:w-4/5 mx-auto mt-1 z-10 bg-white shadow-lg lg:h-1/2 overflow-auto h-screen">
                        <h3 className='ml-2 text-sm mt-2'>Productos</h3>
                        {filteredProducts.slice(0, 10).map((product, index) => (
                            <div key={index} className="flex items-center p-2 hover:bg-gray-100 cursor-pointer" onClick={() => { goproductos(product); SnipKeyWordSearched(searchTerm); }}>
                                <img width="300" height="200"
                                    src={product.imagen} alt={product.titulo} className="h-10 w-10 object-cover mr-2" />
                                <p className="text-xs">{product.sku} - {product.titulo}</p>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
}

export default SearchBar;
