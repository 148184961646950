import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ChevronLeft } from "lucide-react";
import HantecPopup from "../../../componentes/blog/popup";
import Footer from "../../../componentes/footer/Footer";
import Header from "../../../componentes/Headers/Header";
import imgCompressor from "../../../assets/bg-compressor.webp";
import imgDesmontadoras from "../../../assets/products/desmontadoras/bg_main.webp";
import imgClaveExito from "../../../assets/blog/clave_exito.webp";
import imgClaveExitoMobile from "../../../assets/blog/clave_exito_min.webp";
import imgBienvenida from "../../../assets/blog/bienvenida_min.webp";
import RelatedArticles from "../../../componentes/blog/RelatedArticles";
import CallToAction from "../../../componentes/blog/CallToAction";
import "react-toastify/dist/ReactToastify.css";

export default function BlogPost2() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const toastError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const relatedArticles = [
    {
      title: "Bienvenidos a Hantec",
      description: "Diferencia y Valor en Herramientas Automotrices...",
      image: imgBienvenida,
      href: "/blog/autozone/1",
      buttonText: "Leer Más",
    },
    {
      title: "Desmontadoras",
      description:
        "Explora las últimas innovaciones en el mundo de las herramientas...",
      image: imgDesmontadoras,
      href: "/desmontadoras/autozone",
      buttonText: "Leer Más",
    },
    {
      title: "Compresores",
      description:
        "Libera el poder del aire comprimido con una eficiencia y un rendimiento inigualables...",
      image: imgCompressor,
      href: "/force_element",
      buttonText: "Leer Más",
    },
  ];

  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto px-4 pb-4">
        {/* Title Section */}
        <div className="bg-blue-600 w-28 rounded-lg">
          <div className="flex mb-2 w-auto">
            <ChevronLeft className="my-auto w-6 h-6 text-white" />
            <button
              onClick={() => navigate("/blog/autozone")}
              className="text-muted-foreground text-lg hover:underline text-white"
            >
              Regresar
            </button>
          </div>
        </div>

        <header className="mb-8">
          <h1 className="text-4xl font-bold mb-4 leading-tight">
            La Clave del Éxito en Ventas: Actitud Positiva y Persistencia
          </h1>
          <div className="flex items-center text-muted-foreground text-sm">
            <span className="flex items-center mr-4">
              {/* <Clock className="mr-1 h-4 w-4" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              8 min de lectura
            </span>
            <span>7 de Octubre, 2024</span>
          </div>
        </header>

        {/* Introduction */}
        <p className="text-lg mb-8">
          En el mundo de las ventas, especialmente cuando se trata de productos
          de ticket alto como las herramientas automotrices de Hantec, el éxito
          depende en gran medida de dos elementos fundamentales: una actitud
          positiva y una persistencia bien enfocada.
        </p>

        {/* Main Content */}
        <article className="space-y-8">
          {/* Featured Image */}

          {/* Mobile */}
          {/* <img
            src={imgClaveExitoMobile}
            alt="Éxito en Ventas con Hantec"
            className="block md:hidden rounded-lg w-full h-auto"
          /> */}

          <div className="block md:hidden flex justify-center mb-4">
            <img
              src={imgClaveExitoMobile}
              alt="Éxito en Ventas con Hantec"
              className="object-cover object-top rounded-lg w-full h-32"
            />
          </div>

          {/* Desktop */}
          <img
            src={imgClaveExito}
            alt="Éxito en Ventas con Hantec"
            className="hidden md:block rounded-lg w-full h-auto"
          />

          {/* Content Sections */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              Actitud Positiva: El Motor de Toda Interacción
            </h2>
            <p>
              La actitud positiva es el pilar sobre el cual se construye una
              relación sólida con el cliente. Al interactuar con potenciales
              compradores, la energía y el entusiasmo proyectados influyen
              directamente en cómo perciben el producto y al vendedor.
            </p>
            <ul className="list-disc pl-6 space-y-2 mt-4">
              <li>
                <strong>Generación de Confianza Mutua:</strong> Al hablar con
                entusiasmo sobre las herramientas de Hantec y sus beneficios, se
                transmite esa emoción al cliente, fomentando una conexión más
                profunda y confianza en la calidad del producto.
              </li>
              <li>
                <strong>Enfoque en la Solución de Problemas:</strong> Una
                actitud positiva implica centrarse en ayudar al cliente a
                resolver sus necesidades específicas, convirtiéndose en un
                asesor más que en un simple vendedor.
              </li>
              <li>
                <strong>El Poder de la Sonrisa (Incluso por Teléfono):</strong>{" "}
                Aunque parezca un detalle menor, sonreír durante las
                interacciones, incluso si no son presenciales, afecta
                positivamente el tono y la actitud en la conversación, creando
                un ambiente más agradable y receptivo.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              Persistencia Inteligente: Presencia sin Insistencia
            </h2>
            <p>
              Vender productos de alto valor a menudo implica que los clientes
              necesiten más tiempo para tomar decisiones. Aquí es donde una
              persistencia bien dirigida se convierte en una herramienta clave
              para cerrar ventas exitosas.
            </p>
            <ul className="list-disc pl-6 space-y-2 mt-4">
              <li>
                <strong>Realiza Seguimientos Estratégicos:</strong> Mantente
                presente de manera oportuna y relevante. Esto no significa
                saturar al cliente, sino ofrecer información valiosa en el
                momento adecuado.
              </li>
              <li>
                <strong>Aporta Valor en Cada Contacto:</strong> Cada vez que te
                comuniques, proporciona algo útil, como detalles adicionales del
                producto, testimonios de éxito o promociones especiales que
                puedan interesarle.
              </li>
              <li>
                <strong>Maneja Objeciones con Profesionalismo:</strong> Es
                posible que no recibas un "sí" inmediato. En lugar de
                desanimarte, utiliza cada objeción como una oportunidad para
                profundizar en las necesidades del cliente y mostrar cómo Hantec
                puede satisfacerlas.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">
              El Equilibrio entre Positividad y Persistencia
            </h2>
            <p>
              Combinar una actitud positiva con una persistencia inteligente te
              permitirá generar confianza y establecer relaciones duraderas con
              tus clientes. Al adoptar esta mentalidad, te posicionarás no solo
              como un vendedor, sino como un aliado que acompaña al cliente en
              todo el proceso de compra.
            </p>
            <p className="mt-4">
              En Hantec, reconocemos y valoramos la importancia de estas
              cualidades en nuestros vendedores. Sabemos que tu éxito es también
              el nuestro, y que un cliente satisfecho no solo realizará futuras
              compras, sino que también recomendará nuestros productos a otros
              profesionales.
            </p>
          </section>

          {/* Call to Action */}
          <CallToAction
            title={
              "¡Mantén una actitud positiva, sé persistente, y lograrás grandes cosas!"
            }
            subtitle={
              "Descubre cómo las herramientas Hantec pueden ayudarte a alcanzar el éxito en ventas."
            }
          />
        </article>

        {/* Related Articles */}
        <RelatedArticles relatedArticles={relatedArticles} />

        {/* Newsletter Subscription */}
        <section className="mt-12 bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">
            Mantente Actualizado con Hantec
          </h2>
          <p className="mb-4">
            Suscríbete a nuestro boletín para recibir consejos de ventas,
            novedades del sector y actualizaciones de productos.
          </p>
          <div className="flex gap-4">
            {/* <input
                type="email"
                placeholder="Ingresa tu correo electrónico"
                className="flex-grow border rounded-lg px-4 py-2"
            /> */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
            >
              Suscribirse
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 inline-block ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </button>
          </div>
        </section>
      </div>

      <Footer />
      <HantecPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toastSuccess={toastSuccess}
        toastError={toastError}
      />
      <ToastContainer />
    </>
  );
}
