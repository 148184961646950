import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import axios from 'axios';
import { API_URL } from '../../constants/constants';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import Sidebar from '../../componentes/Admi/Sidebar';


function DetallesContacto() {
    const navigate = useNavigate();

    const location = useLocation();
    const contacto = location.state.contacto;
    // console.log("el contaco a atender es ", contacto)
    const [resuelto, setResuelto] = useState(contacto.resuelto);

    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

    const numeroFormateado = contacto.telefono.replace('+', '');
    const urlWhatsApp = `https://wa.me/${numeroFormateado}`;

    const toggleResuelto = () => {
        setResuelto(!resuelto);
    };


    const guardarCambios = async () => {
        //    console.log("Guardando el estado de 'resuelto': ", resuelto);
        // Aquí enviarías `resuelto` a tu backend o realizarías alguna acción con este valor.

        setIsLoading(true); // Inicia la carga

        const formdata = {
            id_contacto: contacto.id_contacto.toString(),
            resuelto: resuelto,
        };


        const config = {
            headers: {
                'Authorization': info['$tk3m09s'],
                'Content-Type': 'application/json' // Asegúrate de que tu backend espera este tipo de contenido

            }
        };

        try {



            //   console.log('voooy a enviar actulizar ', formdata)

            const response = await axios.post(API_URL + '/editContactSupport', formdata, config);

            //   console.log('Categoría actualizada con éxito:', response.data);
            setIsLoading(false); // Inicia la carga
            navigate(-1); // Navegar a la página anterior tras éxito



        } catch (error) {
            console.error('Error al realizar la operación:', error);
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            }
            setIsLoading(false); // Inicia la carga




        };
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('¡Texto copiado al portapapeles!');
            })
            .catch((err) => {
                console.error('Error al copiar al portapapeles: ', err);
            });
    };


    return (



        <div className=''>


            {
                isLoading ? <CargandoSpinner txtcharge="Gusrdando datos" /> : null
            }

            {/* <SidebarAdmi /> */}
            <Sidebar />

            <div className='p-4 sm:ml-64 font-robotoch'>
                <div>
                    <Link className='pr-5' to={'/contact_client'}><i className="fa-solid fa-arrow-left"></i></Link>

                </div>
                <h2 className='mt-5 text-lg xl:text-3xl text-black font-semibold '>Detalles del Contacto</h2>



                <div

                    className="relative block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8"
                >
                    <span
                        className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"
                    ></span>

                    <div className="sm:flex sm:justify-between  w-1/2 sm:gap-4">
                        <div>
                            <h3 className="text-lg font-bold text-gray-900 sm:text-xl">
                                Asunto
                            </h3>

                            <p className="mt-1 text-sm font-medium text-gray-600">{contacto.asunto}</p>
                        </div>

                        <div className="hidden sm:block sm:shrink-0">
                            <h3 className='text-lg font-bold text-gray-900 sm:text-base'>Nombre :</h3>
                            <p className="text-pretty text-sm text-gray-500">
                                {contacto.nombre}
                            </p>
                        </div>
                    </div>

                    <div className="mt-4 bg-gray-100 p-2 rounded">
                        <h2 className='text-lg font-bold text-gray-900 sm:text-base'>Mensaje:</h2>
                        <p className="text-pretty text-sm text-gray-500">
                            {contacto.mensaje}
                        </p>
                    </div>

                    <div className=" mt-4  sm:block sm:shrink-0">
                        <h3 className='text-lg font-bold text-gray-900 sm:text-base'>
                            El cliente ha realizado compras previamente:</h3>
                        <p className="text-pretty text-sm text-gray-500">
                            {contacto.compra ? 'Sí' : 'No'}
                        </p>
                    </div>


                    <h3 className=' mt-5 text-lg font-bold text-gray-900 sm:text-base'>
                        Contactar
                    </h3>
                    <div className="mt-2 flex gap-4 sm:gap-6">
                        <div className="flex flex-col-reverse">
                            <a
                                href={urlWhatsApp}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">

                                <i className="fab fa-whatsapp mr-2"></i>
                                Contactar por WhatsApp
                            </a>


                        </div>

                        <div className="flex flex-col-reverse">
                            <a href={`mailto:${contacto.correo}`}
                                className="inline-flex items-center bg-[#007bfc] text-white px-4 py-2 rounded-full hover:bg-[#1e3d8b] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                            >

                                <i className="fas fa-envelope mr-2"></i>

                                Enviar correo
                            </a>
                        </div>


                    </div>
                    <div>
                        <div className='mt-5 flex'>
                            <p className='mr-2 font-bold text-gray-900 sm:text-base'>Número de contacto:</p>
                            <p>{numeroFormateado}</p>
                            <button  onClick={() => handleCopy(numeroFormateado)} 
                             className="ml-2 bg-[#1e3d8b] text-white hover:bg-[#007bfc] px-2 py-1 rounded-full" 
                             >
                                <i className="fas fa-copy"></i>
                            </button>
                        </div>
                        <div className='mt-4 flex'>
                            <p className='mr-2 font-bold text-gray-900 sm:text-base'>Correo:</p>
                            <p>{contacto?.correo}</p>
                            <button onClick={() => handleCopy(contacto?.correo)} 
                             className="ml-2 bg-[#1e3d8b] text-white hover:bg-[#007bfc] px-2 py-1 rounded-full" 
                            >
                                <i className="fas fa-copy"></i>
                            </button>
                        </div>
                    </div>

                    <div>
                        <h3 className=' mt-5 text-lg font-bold text-gray-900 sm:text-base'>

                            Si el cliente ya fue contactado, por favor selecciona la opción "Atendido" para evitar enviar información nuevamente.
                        </h3>
                        <div>
                            <label
                                htmlFor="toggleActivo"
                                className={`relative inline-block h-8 w-14 cursor-pointer rounded-full transition-colors ${resuelto ? 'bg-green-500' : 'bg-red-500'
                                    }`}
                            >
                                <input
                                    type="checkbox"
                                    id="toggleActivo"
                                    className="peer sr-only"
                                    checked={resuelto}
                                    onChange={toggleResuelto}
                                />
                                <span
                                    className={`absolute inset-y-0 left-0 m-1 h-6 w-6 rounded-full bg-white transition-transform ${resuelto ? 'translate-x-6' : 'translate-x-0'
                                        }`}
                                ></span>
                            </label>
                        </div>

                        <button
                            className="mt-4 px-4 py-2 bg-[#1e3d8b] text-white rounded hover:bg-[#007bfc]"
                            onClick={() => guardarCambios()}
                        >
                            Guardar
                        </button>


                    </div>
                </div>
            </div>

        </div>
    );
}
export default DetallesContacto