import React, { useState } from 'react';

function InputField({ label, type, placeholder, handleChange, autoComplete }) {
    const [inputType, setInputType] = useState(type);

    const togglePasswordVisibility = () => {
        setInputType(inputType === 'password' ? 'text' : 'password');
    };

    const handleInputChange = (event) => {
        let value = event.target.value;

        // Si el tipo es email, convertimos el texto a minúsculas
        if (type === 'email') {
            value = value.toLowerCase();
        }

        // Llamamos a la función handleChange pasada como prop
        handleChange({ ...event, target: { ...event.target, value } });
    };

    return (
        <div className='conteinput relative'>
            <div className=' w-full  lg:w-4/5 lg:px-0 mt-2 px-5'>
                <p className='text-sm'>{label}</p>
            </div>
            <input
                className='iptlogin m-2'
                type={inputType}
                placeholder={placeholder}
                onChange={handleInputChange}
                autoComplete={autoComplete}
            />
            {type === 'password' && (
                <button

                    name='btn-sennpas'
                    id='btn-sennpas'
                    type="button"
                    className="absolute inset-y-8 mt-2 lg:right-20 right-6 pr-3 flex items-center text-gray-700"
                    onClick={togglePasswordVisibility}
                >
                    <i className={inputType === 'password' ? 'far fa-eye-slash' : 'far fa-eye'}></i>
                </button>
            )}
        </div>
    );
}

export default InputField;
