// PreguntasTabla.js
import React from 'react';

function PreguntasTabla({ listapreguntas, onRowClick }) {

    console.log('Las pregutnas son', listapreguntas)
    return (
        <div className="overflow-x-auto mt-10">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

                    <tr>
                        <th className="px-6 py-3">N°</th>
                        <th className="px-6 py-3">Pregunta</th>
                        <th className="px-6 py-3">Estado</th>
                        <th className="px-6 py-3">Fecha </th>
                    </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                    {listapreguntas.map((pregunta, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th onClick={() => onRowClick(pregunta)} className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white">{pregunta.id_pr}</th>
                            <td onClick={() => onRowClick(pregunta)} className="px-3 py-2 cursor-pointer">{pregunta.pregunta}</td>

                            <td onClick={() => onRowClick(pregunta)} className="px-3 py-2 cursor-pointer">
                                <p className={`text-white text-center rounded ${pregunta.respuesta ? 'bg-green-600' : 'bg-red-600'}`}>
                                    {pregunta.respuesta ? 'Respondido' : 'Sin respuesta'}
                                </p>
                            </td>
                            <td onClick={() => onRowClick(pregunta)} className="px-3 py-2 cursor-pointer">{new Date(pregunta.created_at).toLocaleDateString()}</td>
                        </tr>
                    ))}


                </tbody>
            </table>
        </div>
    );
}

export default PreguntasTabla;
