import React, { useEffect, useState } from 'react';
import CardEntrega from '../cards/CardEntrega';
import { useNavigate } from 'react-router-dom';
import EditableField from '../../pages/EditableField';
import Metodopago from '../pagos/Metodpago';
import TarjetaGuardada from '../pagos/TarjetaGuardada';
import PaginaDos from '../pagos/PaginaDos';
import axios from 'axios';
import { API_URL } from '../../constants/constants';


function TabComponent({ usuario }) {
  const info = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
  const ideusuairo = info['$udi09345']
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [correoUsuario, setCorreoUsuario] = useState('');
  const [telefonoUsuario, setTelefonoUsuario] = useState('');

  //Editar información del usuario
  const editInfoUser = async (payload) => {

    if (info) {
      try {
        const response = await axios.post(API_URL + '/editDataUser', payload, { headers: { 'Authorization': info['$tk3m09s'] }, timeout: 10000 })
        const { nombre, correo, telefono_movil } = response?.data?.usuario;

        setNombreUsuario(nombre)
        setCorreoUsuario(correo)
        setTelefonoUsuario(telefono_movil)

      } catch (error) {
        // console.log('El token ha expirado.')
        if (error.code === 'ECONNABORTED') {
          console.log('Request timed out');
        }

        //Verificar que el token aún no ha expirado
        if (error.response.data.error == 'refresh') {
          localStorage.removeItem('k-9SMD879na89dN87m')
          navigate('/');
          return
        }
      }
    }
  }

  useEffect(() => {
    setNombreUsuario(usuario.nombre)
    setCorreoUsuario(usuario.correo)
    setTelefonoUsuario(usuario.telefono)
  }, [usuario])

  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const [direccion, setDireccion] = useState([]);

  const datosPerfil = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
  const handleTabClick = (tabNumber) => {

    setActiveTab(tabNumber);
  };
  useEffect(() => {
    const getAdress = async (data) => {
      try {

        const config = {
          headers: {
            'Authorization': datosPerfil['$tk3m09s']
          },
          timeout: 10000
        };
        let response = await axios.post(API_URL + "/getAddress", data, config);

        let direccionesArray = Object.values(response.data['direcciones']);
        // console.log('las direcioens son ',response.data )
        setDireccion(direccionesArray);
      } catch (error) {
        console.error('Ha ocurrido un error al obtenerDirecciones.' + error.toString())
        if (error.code === 'ECONNABORTED') {
          console.log('Request timed out');
        }
      }
    }
    const data = {
      'idUsuario': datosPerfil['$udi09345']
    }
    getAdress(data);
  }, []);

  const [tarjetasGuardadas, setTarjetasGuardadas] = useState([])

  const goadrees = () => {
    navigate('/adrees');
  };

  //funciones de los input--------------*****************----------------

  // const [nombreUsuario, setNombreUsuario] = useState('Juan');
  const [editNombre, setEditNombre] = useState(false);

  const handleNombreChange = (event) => setNombreUsuario(event.target.value);
  const handleEditNombre = () => setEditNombre(true);
  const handleSaveNombre = () => {/* lógica de guardar */editInfoUser({ idUsuario: info['$udi09345'], nombre: nombreUsuario }); setEditNombre(false) };
  const handleCancelNombre = () => { setNombreUsuario(usuario?.nombre); setEditNombre(false) };

  // Para correo
  // const [correoUsuario, setCorreoUsuario] = useState(usuario?.correo);
  const [editCorreo, setEditCorreo] = useState(false);

  const handleCorreoChange = (event) => setCorreoUsuario(event.target.value);
  const handleEditCorreo = () => setEditCorreo(true);
  const handleSaveCorreo = () => {/* lógica de guardar */editInfoUser({ idUsuario: info['$udi09345'], correo: correoUsuario }); setEditCorreo(false) };
  const handleCancelcorreo = () => { setCorreoUsuario(usuario?.correo); setEditCorreo(false) };

  // Para Telefono
  // const [telefonoUsuario, setTelefonoUsuario] = useState(usuario?.telefono);
  const [editTelefono, setEditTelefono] = useState(false);

  const handleTelefonoChange = (event) => setTelefonoUsuario(event.target.value);
  const handleEditCTelefono = () => setEditTelefono(true);


  const manejarDatosFormulario = (datos) => {

    // console.log('los datos son: ', datos)

  };

  //funciones para el icno de las tarjetas 
  const obtenerIconoCompania = (compania) => {
    switch (compania) {
      case 'visa':
        return "fa-cc-visa";
      case 'mastercard':
        return "fa-cc-mastercard";
      case 'amex':
        return "fa-cc-amex";
      default:
        return "fa-credit-card";
    }
  }


  const handleSaveTelefono = () => { editInfoUser({ idUsuario: info['$udi09345'], telefono_movil: telefonoUsuario }); setEditTelefono(false); };
  const handleCancelTelefono = () => { setTelefonoUsuario(usuario?.telefono); setEditTelefono(false); };

  return (
    <div className="text-center mt-5 ">
      <ul className="flex justify-between px-2">
        <li
          className={` cursor-pointer flex flex-col items-center flex-1 ${activeTab === 1 ? 'text-blue-500 transform scale-110' : 'text-[#1e3d8b]'}`}
          onClick={() => handleTabClick(1)}
          aria-label="Account Data Tab"
        >
          <i className={`fas fa-user mb-1`}></i>
          <p className=" font-semibold text-xs lg:text-base">
            Información personal
          </p>
        </li>
        <li
          className={`cursor-pointer flex flex-col items-center flex-1 ${activeTab === 2 ? 'text-blue-500 transform scale-110' : 'text-[#1e3d8b]'}`}
          onClick={() => handleTabClick(2)}
          aria-label="Addresses Tab"
        >
          <i className={`fas fa-home mb-1`}></i>

          <p className=" font-semibold text-xs lg:text-base">
            Direcciones
          </p>
        </li>

        <li
          className={`cursor-pointer flex flex-col items-center flex-1 ${activeTab === 4 ? 'text-blue-500 transform scale-110' : 'text-[#1e3d8b]'}`}
          onClick={() => handleTabClick(4)}
          aria-label="Orders Tab"
        >
          <i className={`fas fa-file-invoice mb-1`}></i>

          <p className=" font-semibold text-xs lg:text-base">
            Facturación
          </p>
        </li>
      </ul>

      <div className="mt-4 p-4 border rounded h-auto mx-auto ">
        {activeTab === 1 && (
          <div className='lg:flex lg:items-center lg:justify-center mx-auto  '>
            <div className='lg:mr-2'>
              <EditableField
                title="Nombre de Usuario"
                value={nombreUsuario} //nombreUsuario
                onChange={handleNombreChange}
                onSave={handleSaveNombre}
                onCancel={handleCancelNombre}
                onEdit={handleEditNombre}
                isEditable={editNombre}
                showEditButtons={editNombre}
              />
            </div>

            <div className='lg:mr-2'>
              <EditableField
                title="Correo"
                value={correoUsuario}
                onChange={handleCorreoChange}
                onSave={handleSaveCorreo}
                onCancel={handleCancelcorreo}
                onEdit={handleEditCorreo} // Agrega esto si es necesario
                isEditable={editCorreo}
                showEditButtons={editCorreo}
              />
            </div>
            {info && info['$r07sad'] !== 'Tienda' && (

              <div className='lg:mr-2'>
                <EditableField
                  title="Telefono"
                  value={telefonoUsuario}
                  onChange={handleTelefonoChange}
                  onSave={handleSaveTelefono}
                  onCancel={handleCancelTelefono}
                  onEdit={handleEditCTelefono} // Agrega esto si es necesario
                  isEditable={editTelefono}
                  showEditButtons={editTelefono}
                />

              </div>

            )}


          </div>
        )}

        {activeTab === 2 &&
          <div>

            <div className='lg:flex overflow-x-auto lg:overflow-visible lg:flex-wrap lg:justify-center'>

              {direccion?.map((dir) => (
                <div className="min-w-[calc(25%-1rem)] lg:min-w-0 lg:w-1/4 p-2" key={dir.id_direccion}>

                  <CardEntrega key={dir.idDireccion} direccion={dir} title='DIRECCION' showAddButton={false} />
                </div>

              ))}
            </div>
            {info && info['$r07sad'] !== 'Tienda' && (
              <button 
              name='btn-agregardirecciontab'
              id='btn-agregardirecciontab'
               onClick={goadrees} className='bg-[#e9bf56] py-2 p-5 mt-5  rounded uppercase'>agregar dirección </button>
            )}
          </div>}
        {activeTab === 3 && <div>

          <div className='text-center flex justify-center text-center'>
            <h2 className='text-[#1e3d8b] font-black text-lg'>MIS</h2>
            <h1 className='text-[#030303] font-black text-xl ml-2'>TARJETAS</h1>
          </div>

          {tarjetasGuardadas?.map(tarjeta => (
            <TarjetaGuardada
              key={tarjeta.idTarjeta}
              tarjeta={tarjeta}
              obtenerIconoCompania={obtenerIconoCompania}


            />
          ))}          </div>}
        {activeTab === 4 && <div>
          <div className='lg:w-4/5 mx-auto'>
            <PaginaDos iduser={ideusuairo} txtbtn='GUARDAR' onEnviarDatos={manejarDatosFormulario} />

          </div>


        </div>}
      </div>
    </div>
  );
}

export default TabComponent;
