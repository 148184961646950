import React from 'react';
import Listametodospagotxt from '../../pages/pagos/Listametodospagotxt';

const Modallistaproductos = ({ onClose, mesesDisponible }) => {
    return (
        <div className="fixed inset-0 flex items-start  justify-center p-4 bg-black bg-opacity-50 sm:justify-center z-50	" >

            <div className='bg-white w-4/5 lg:rounded'>

                <div className=' flex justify-end px-5 h-10 text-2xl'>

                    <button onClick={onClose} className="text-red-500 transition hover:text-gray-600 hover:text-3xl"
                        name='btn-closemdallistaproducts'
                        id='btn-closemdallistaproducts'
                    >

                        <i className="fas fa-times"></i>

                    </button></div>

                <div>
                    <Listametodospagotxt mesamodal={mesesDisponible} />
                </div>
            </div>

        </div>

    );
};

export default Modallistaproductos;
