import React, { useEffect, useState } from 'react';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi'
import axios from 'axios';
import PreguntasTabla from '../../componentes/Admi/PreguntasTabla';
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../constants/constants';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import Sidebar from '../../componentes/Admi/Sidebar';


function QuestionProduct() {
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

    const navigate = useNavigate()

    const [listapreguntas, setListaPreguntas] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        cuestionproducto(info['$tk3m09s'])


    }, []);

    const cuestionproducto = async (tk) => {
        setIsLoading(true); // Inicia la carga

        try {

            const config = {
                headers: {
                    'Authorization': tk
                }
            };
            const response = await axios.get(API_URL + '/getAllQuestions', config);
            //console.log('la DATA de preguntas es:', response.data.success)
            setListaPreguntas(response.data.success);
        } catch (error) {
            console.error("Error al obtener las precuntas:", error);
            // Manejo adicional del error, como establecer un estado de error
        }
        setIsLoading(false); // Finaliza la carga

    }
    const handleRowClick = (pregunta) => {
        // console.log("Fila seleccionada:", pregunta);

        navigate('/addCuestion', { state: { datopregunta: pregunta } });

    };

    return (
        <div className='p-4 sm:ml-64'>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <div>
                    <h1 className='text-lg xl:text-2xl font-bold mt-5 text-gray-700'>Preguntas de los clientes </h1>
                    <h1 className='text-lg xl:text-2xl font-bold mt-2 text-gray-700'>sobre los productos Hantec</h1>

                    <div className='bg-blue-300 w-4/5 ml-5   '>
                        <PreguntasTabla listapreguntas={listapreguntas} onRowClick={handleRowClick} />

                    </div>
                </div>
            )}
        </div>
    )
}

export default QuestionProduct