import React, { useEffect, useState } from 'react';
import TableOrders from '../../componentes/Admi/TableOrders';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants/constants';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import Sidebar from '../../componentes/Admi/Sidebar';
import { capitalizeAfterPeriod } from '../../utils/utils';
import axios from 'axios';


function Orders() {
    const navigate = useNavigate();
    const [ordersList, setOrdersList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [totalIngresos, setTotalIngresos] = useState(0);
    const [search, setSearch] = useState(null);
    const [filtroActivo, setFiltroActivo] = useState('Pagado');
    const [orderDenegadoSort, setOrderDenegadoSort] = useState('');

    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    //datos par aponer los ingresos por mes 
    const [mesSeleccionado, setMesSeleccionado] = useState({
        mes: new Date().getMonth() + 1,
        año: new Date().getFullYear()
    });

    console.log('el mes isncial es de ', mesSeleccionado)
    useEffect(() => {
        if (info) {
            cargarDatos(info['$tk3m09s'], mesSeleccionado.mes, mesSeleccionado.año);
        }
    }, [mesSeleccionado]);


    useEffect(() => {
        filtrarPedidosInicial();
    }, [ordersList]);


    useEffect(() => {
        const sumaTotalesPagadosMesSeleccionado = ordersList.reduce((acumulador, orden) => {
            const fechaOrden = new Date(orden.created_at);
            const mesOrden = fechaOrden.getMonth() + 1;
            const añoOrden = fechaOrden.getFullYear();

            if (orden.estado_pago === "Pagado" && mesOrden === mesSeleccionado.mes && añoOrden === mesSeleccionado.año) {
                return acumulador + orden.total;
            }
            return acumulador;
        }, 0);

        setTotalIngresos(sumaTotalesPagadosMesSeleccionado);
    }, [ordersList, mesSeleccionado]);

    useEffect(() => {
        if (filtroActivo === 'Denegado') {
            filtrarPedidosPorEstadoPago('Denegado');
        }
    }, [orderDenegadoSort]);

    const productosPorPagina = 15;
    const ultimoProductoIndex = currentPage * productosPorPagina;
    const primerProductoIndex = ultimoProductoIndex - productosPorPagina;
    const pedidosActuales = ordersList?.slice(primerProductoIndex, ultimoProductoIndex);

    const pendientesCount = ordersList.filter(order => order.estado_pago === "Pendiente").length;
    const denegadosCount = ordersList.filter(order => order.estado_pago === "Denegado").length;
    const enviadosCount = ordersList.filter(order => order.estado_orden === "Enviado" || order.estado_orden === "En camino").length;
    const pagadosCount = ordersList.filter(order => order.estado_pago === "Pagado").length;
    const entregadosCount = ordersList.filter(order => order.estado_orden === "Entregado").length;
    const canceladoCount = ordersList.filter(order => order.estado_pago === "Cancelado").length;

    const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
    const sumaTotal = ordersList?.reduce((acumulador, orden) => acumulador + orden.total, 0);

    const paginasTotales = Math.ceil(ordersList?.length / productosPorPagina);

    const [sumaHoy, setSumaHoy] = useState(0);
    const [sumaEstaSemana, setSumaEstaSemana] = useState(0);

    const cambiarPagina = (numeroPagina) => {
        setSearch(null);
        setPedidosFiltrados([]);
        setCurrentPage(numeroPagina);
    };



    const cargarDatos = async (tk, mes, año) => {
        setIsLoading(true);
        const payload = {
            "year": año,
            "month": mes

        };


        try {
            const response = await axios.post(
                `${API_URL}/getOrders`,
                payload,
                {
                    headers: {
                        Authorization: tk
                    }
                }
            );

            const data = response.data;

            if (data.error === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }

            const dataFiltrada = data.filter(item => item.id_pedido);
            setOrdersList(dataFiltrada);

            const hoy = new Date();
            const primerDiaSemana = new Date(hoy);
            primerDiaSemana.setDate(hoy.getDate() - hoy.getDay() + (hoy.getDay() === 0 ? -6 : 1));
            primerDiaSemana.setHours(0, 0, 0, 0);

            const sumaDeHoy = dataFiltrada.reduce((acumulador, orden) => {
                const fechaOrden = new Date(orden.created_at);
                if (fechaOrden.toDateString() === hoy.toDateString()) {
                    return acumulador + orden.total;
                }
                return acumulador;
            }, 0);

            const sumaDeEstaSemana = dataFiltrada.reduce((acumulador, orden) => {
                const fechaOrden = new Date(orden.created_at);
                if (fechaOrden >= primerDiaSemana && fechaOrden <= hoy && orden.estado_pago === 'Pendiente') {
                    return acumulador + orden.total;
                }
                return acumulador;
            }, 0);

            setSumaHoy(sumaDeHoy);
            setSumaEstaSemana(sumaDeEstaSemana);

        } catch (error) {
            console.log(error);
            console.log('Ha ocurrido un error');
        }

        setIsLoading(false);
    };


    const handleSearch = (event) => {
        setSearch(event.target.value);
        const ordenesFiltradas = pedidosActuales.filter(orden =>
            orden.no_orden.toString().startsWith(event.target.value)
        );
        setPedidosFiltrados(ordenesFiltradas);
    };

    const filtrarPedidosPorEstado = (estados) => {
        setFiltroActivo(estados);

        if (estados === 'Todos') {
            setPedidosFiltrados([]);
            setSearch(null);
        } else {
            const estadosArray = Array.isArray(estados) ? estados : [estados];
            const filtrados = ordersList.filter(order => estadosArray.includes(order.estado_orden));
            setPedidosFiltrados(filtrados);
        }
        setCurrentPage(1);
    };

    const filtrarPedidosPorEstadoPago = (estadosPago) => {
        setFiltroActivo(estadosPago);

        if (estadosPago === 'Todos') {
            setPedidosFiltrados([]);
            setSearch(null);
        } else {
            const estadosPagoArray = Array.isArray(estadosPago) ? estadosPago : [estadosPago];
            let filtrados = ordersList.filter(order => estadosPagoArray.includes(order.estado_pago));

            if (estadosPago === 'Denegado') {
                filtrados = filtrados.sort((a, b) => {
                    const aError = a.extra?.nb_error || 'Sin datos';
                    const bError = b.extra?.nb_error || 'Sin datos';
                    return aError.localeCompare(bError);
                });

                if (orderDenegadoSort) {
                    filtrados = filtrados.sort((a, b) => {
                        const aError = a.extra?.nb_error || 'Sin datos';
                        const bError = b.extra?.nb_error || 'Sin datos';
                        if (aError === orderDenegadoSort && bError !== orderDenegadoSort) return -1;
                        if (aError !== orderDenegadoSort && bError === orderDenegadoSort) return 1;
                        return 0;
                    });
                }
            }

            setPedidosFiltrados(filtrados);
        }
        setCurrentPage(1);
    };


    const getCurrentMonthAndYear = () => {
        const fechaActual = new Date();
        const mesActual = fechaActual.getMonth() + 1; // Los meses van de 0 a 11, por lo que sumamos 1
        const añoActual = fechaActual.getFullYear();
        return { mesActual, añoActual };
    };

    const { mesActual, añoActual } = getCurrentMonthAndYear();

    const sumaTotalesPagadosMesActual = ordersList.reduce((acumulador, orden) => {
        const fechaOrden = new Date(orden.created_at);
        const mesOrden = fechaOrden.getMonth() + 1;
        const añoOrden = fechaOrden.getFullYear();

        if (orden.estado_pago === "Pagado" && mesOrden === mesActual && añoOrden === añoActual) {
            return acumulador + orden.total;
        }
        return acumulador;
    }, 0);


    const sumaTotalesPagadosMesSeleccionado = ordersList.reduce((acumulador, orden) => {
        const fechaOrden = new Date(orden.created_at);
        const mesOrden = fechaOrden.getMonth() + 1;
        const añoOrden = fechaOrden.getFullYear();

        if (orden.estado_pago === "Pagado" && mesOrden === mesSeleccionado.mes && añoOrden === mesSeleccionado.año) {
            return acumulador + orden.total;
        }
        return acumulador;
    }, 0);

    const handleMesSeleccionado = (event) => {
        setMesSeleccionado(parseInt(event.target.value));
    };

    const filtrarPedidosHoy = () => {
        setFiltroActivo('transaciconeshoy');

        const pedidosAMostrar = ordersList.filter(orden => {
            const fechaOrden = new Date(orden.created_at);
            return fechaOrden.toDateString() === new Date().toDateString();
        });

        setPedidosFiltrados(pedidosAMostrar);
        setCurrentPage(1);
    };

    const filtrarPedidosEstaSemana = () => {
        setFiltroActivo('transaciconesPendientes');

        const hoy = new Date();
        const primerDiaSemana = new Date(hoy.setDate(hoy.getDate() - hoy.getDay() + (hoy.getDay() === 0 ? -6 : 1)));
        primerDiaSemana.setHours(0, 0, 0, 0);

        const pedidosAMostrar = ordersList.filter(orden => {
            const fechaOrden = new Date(orden.created_at);
            return fechaOrden >= primerDiaSemana && fechaOrden <= hoy && orden.estado_pago === 'Pendiente';
        });

        setPedidosFiltrados(pedidosAMostrar);
        setCurrentPage(1);
    };

    const filtrarPedidosInicial = () => {
        const filtrados = ordersList.filter(order =>
            order.estado_pago === "Pagado" || order.estado_pago === "Pendiente"
        );
        setPedidosFiltrados(filtrados);
    };
    const obtenerErroresUnicos = () => {
        const errores = ordersList
            .filter(order => order.estado_pago === "Denegado")
            .map(order => {
                const error = order.extra?.nb_error || 'Sin datos';
                if (error.startsWith("La transacción ya fue rechazada el")) {
                    return "La transacción ya fue rechazada";
                }
                return error;
            });
        return [...new Set(errores)];
    };

    return (
        <div className=''>
            {/* <SidebarAdmi /> */}
            <Sidebar />
            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <div className='p-4 sm:ml-64'>

                    <div className='px-5'>

                        <h1 className='text-2xl xl:text-3xl font-bold my-5 text-gray-700'>Lista de pedidos</h1>
                        {info && info['$r07sad'] === 'order_viewer' && (
                            <h1 className='text-xl font-bold my-5 text-gray-700'>Bienvenido, {info ? info['$name'] : ''}</h1>

                        )}
                    </div>



                    <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5'>
                        <div
                            className={`rounded-xl bg-white p-1 border shadow-lg p-4 cursor-pointer transform transition-all duration-300 ${filtroActivo === 'Todos' ? ' scale-110 bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : 'border-blue-900 text-black'}  hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500`}
                            onClick={() => filtrarPedidosPorEstado('Todos')}
                        >
                            <p className='text-lg'>Total de pedidos</p>
                            <div className='flex justify-between w-4/5 mx-auto'>
                                <p className='text-center font-bold text-2xl'>{ordersList?.length}</p>
                                <i className='fas fa-list-alt text-2xl'></i>
                            </div>
                        </div>

                        <div
                            className={`rounded-xl bg-white p-1 border shadow-lg p-4 cursor-pointer transform transition-all duration-300 ${filtroActivo === 'Pagado' ? ' scale-110 bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : 'border-blue-900 text-black'}  hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500`}
                            onClick={() => filtrarPedidosPorEstadoPago('Pagado')}
                        >
                            <p className='text-lg'>Pedidos Pagados</p>
                            <div className='flex justify-between w-4/5 mx-auto'>
                                <p className='text-center font-bold text-2xl'>{pagadosCount}</p>
                                <i className='fas fa-money-check-alt text-2xl'></i>
                            </div>
                        </div>

                        <div
                            className={`rounded-xl bg-white p-1 border shadow-lg p-4 cursor-pointer transform transition-all duration-300 ${filtroActivo === 'Pendiente' ? ' scale-110 bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : 'border-blue-900 text-black'}  hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500`}
                            onClick={() => filtrarPedidosPorEstadoPago('Pendiente')}
                        >
                            <p className='text-lg'>Pedidos Pendientes</p>
                            <div className='flex justify-between w-4/5 mx-auto'>
                                <p className='text-center font-bold text-2xl'>{pendientesCount}</p>
                                <i className='fas fa-hourglass-half text-2xl'></i>
                            </div>
                        </div>


                        <div
                            className={`rounded-xl bg-white p-1 border shadow-lg p-4 cursor-pointer transform transition-all duration-300 ${filtroActivo === 'Denegado' ? 'scale-110 bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : 'border-blue-900 text-black'}  hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500`}
                            onClick={() => filtrarPedidosPorEstadoPago('Denegado')}
                        >
                            <p className='text-lg'>Pedidos Denegados</p>
                            <div className='flex justify-between w-4/5 mx-auto'>
                                <p className='text-center font-bold text-2xl'>{denegadosCount}</p>
                                <i className='fas fa-times-circle text-2xl'></i>
                            </div>

                            {filtroActivo === 'Denegado' && (
                                <div className="flex justify-center mt-2 w-full">
                                    <select
                                        className="py-1 px-2 border-2 border-gray-300 bg-transparent rounded text-white w-4/5 text-sm"
                                        value={orderDenegadoSort}
                                        onChange={(event) => {
                                            setOrderDenegadoSort(event.target.value);
                                            filtrarPedidosPorEstadoPago('Denegado');
                                        }}
                                    >
                                        <option value="" className="text-black text-sm">Ordenar por error</option>
                                        {obtenerErroresUnicos().map((error, index) => (
                                            <option key={index} value={error} className="text-black text-sm">
                                                {error}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>


                        <div
                            className={`rounded-xl bg-white p-1 border shadow-lg p-4 cursor-pointer transform transition-all duration-300 ${filtroActivo === 'Cancelado' ? '  scale-110 bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : 'border-blue-900 text-black'}  hover:bg-gradient-to-r hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500`}
                            onClick={() => filtrarPedidosPorEstadoPago('Cancelado')}
                        >
                            <p className='text-lg'>Pedidos Cancelados</p>
                            <div className='flex justify-between w-4/5 mx-auto'>
                                <p className='text-center font-bold text-2xl'>{canceladoCount}</p>
                                <i className='fas fa-ban text-2xl'></i>
                            </div>
                        </div>
                    </div>

                    {/* Incoming */}

                    <div className='grid grid-cols-1 xl:grid-cols-2'>


                        <div className='grid sm:grid-cols-3 bg-white border border-blue-900 rounded-xl w-full sm:h-32 xl:h-32 my-5 p-5 shadow-xl'>

                            <div className='py-5 flex justify-center  items-center'>
                                <i className="fas fa-coins text-black text-4xl"></i>
                            </div>

                            <div className='flex flex-col justify-center text-center items-center md:items-start md:ml-1'>
                                <p className='text-sm text-center md:text-center'>Total de ingresos del mes</p>
                                <p className='text-center lg:text-center sm:text-2xl xl:text-2xl font-bold mt-2'>$ {sumaTotalesPagadosMesSeleccionado?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>

                            <div className='flex flex-col justify-center   items-center py-4'>
                                <p className='text-sm text-center'>Seleccionar mes</p>
                                <input
                                    type="month"
                                    className="capitalize mt-2 py-2 px-2 w-5/5 border-2 text-sm w-4/5 border-gray-300 rounded"
                                    value={`${mesSeleccionado.año}-${String(mesSeleccionado.mes).padStart(2, '0')}`}
                                    onChange={(event) => {
                                        const [year, month] = event.target.value.split('-');
                                        setMesSeleccionado({ mes: parseInt(month), año: parseInt(year) });
                                    }}
                                />
                            </div>

                        </div>


                        <div className='grid grid-cols-2'>
                            <div
                                className={`flex flex-col bg-white rounded-xl border border-blue-900 rounded w-6/6 xl:w-6/6 sm:h-32 xl:h-32 m-5 px-5 py-1 shadow-xl cursor-pointer ${filtroActivo === 'transaciconeshoy' ? ' scale-110 bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : ''} hover:scale-110 hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500`}
                                onClick={filtrarPedidosHoy}
                            >
                                <i className="fas fa-file-invoice-dollar  text-2xl self-center"></i>
                                <p className='text-center'>Transacciones hoy</p>
                                <p className='pb-1 text-center md:text-lg xl:text-xl xl:text-3xl font-bold break-all'>${sumaHoy?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>

                            <div
                                className={`flex flex-col bg-white rounded-xl border border-blue-900 rounded w-6/6 xl:w-6/6 sm:h-32 xl:h-32 m-5 px-5 py-1 shadow-xl cursor-pointer ${filtroActivo === 'transaciconesPendientes' ? 'scale-110 bg-gradient-to-r from-[#163d6d] to-blue-600 text-white' : ''} hover:scale-110 hover:bg-gradient-to-r  hover:from-[#163d6d] hover:to-blue-600 hover:text-white hover:border-blue-500`}
                                onClick={filtrarPedidosEstaSemana}
                            >
                                <i className="fas fa-file-invoice-dollar  text-2xl self-center"></i>
                                <p className='text-center'>Ingresos Pendientes esta semana</p>
                                <p className='text-center sm:text-lg xl:text-xl font-bold break-all'>$ {sumaEstaSemana?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                        </div>

                    </div>

                    <TableOrders
                        ordersList={pedidosFiltrados.length === 0 ? pedidosActuales : pedidosFiltrados}
                        total={ordersList?.length}
                        currentPage={currentPage}
                        paginasTotales={paginasTotales}
                        cambiarPagina={cambiarPagina}
                        handleSearch={handleSearch}
                    />
                </div>
            )}
        </div>
    );
}

export default Orders;
