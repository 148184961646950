import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import TableProducts from "../../componentes/Admi/TableProducts";
import SidebarAdmi from "../../componentes/Admi/SidebarAdmi";
import CargandoSpinner from "../../componentes/cargacomponetes/CargandoSpinner";
import ComboTable from "../../componentes/Admi/ComboTable";
import { API_URL, TOAST_ERROR } from "../../constants/constants";
import { getCurrentDate } from "../../utils/Analitycs/analytics";
import { Transition } from "@headlessui/react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../../componentes/Admi/Sidebar";

function ProductsManagment() {
  const navigate = useNavigate();
  const info = localStorage.getItem("k-9SMD879na89dN87m")
    ? JSON.parse(localStorage.getItem("k-9SMD879na89dN87m"))
    : null;
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [combos, setCombos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [categorySelect, setCategorySelect] = useState("");
  const [showCombos, setShowCombos] = useState(false);

  useEffect(() => {
    if (info) {
      getProducts(info["$tk3m09s"]);
    }
  }, []);

  const productosPorPagina = 20;
  const productosFiltrados = products.filter(
    (product) =>
      (product.titulo.toLowerCase().includes(search.toLowerCase()) ||
        product.sku.toLowerCase().includes(search.toLowerCase())) &&
      (!categorySelect || product.categoria === categorySelect)
  );

  const paginasTotales = Math.ceil(
    productosFiltrados.length / productosPorPagina
  );
  const categoriasUnicas = [
    ...new Set(products.map((product) => product.categoria)),
  ];

  const getProducts = async (tk) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/getProductsToDashboard`, {
        headers: { Authorization: tk },
      });
      const data = await response.json();
      if (data.error === "refresh") {
        localStorage.removeItem("k-9SMD879na89dN87m");
        navigate("/");
        return;
      }
      console.log("los datos del producto  son", data.success);


      const specificImageURL1 = "https://firebasestorage.googleapis.com/v0/b/dotkomma.appspot.com/o/hantec_ecommerce%2Fgeneral%2F425392263_910836217201170_1470954410750102114_n.jpg?alt=media";
      const specificImageURL2="https://storage.googleapis.com/dotkomma.appspot.com/hantec_ecommerce/product_images/425392263_910836217201170_1470954410750102114_n.jpg2024-05-17%2017%3A35%3A07.817664"
      // Ordenar los datos según los criterios especificados
      const sortedData = data.success.sort((a, b) => {
        if (a.sku === "PRUEBAS") return -1;
        if (b.sku === "PRUEBAS") return 1;
        if (a.imagen === specificImageURL1 || a.imagen === specificImageURL2) return 1;
        if (b.imagen === specificImageURL1 || b.imagen === specificImageURL2) return -1;
        return 0;
    });

      console.log("Los datos del producto son", sortedData);


      setProducts(sortedData);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
    setIsLoading(false);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página al buscar
  };

  const handleSelectChange = (event) => {
    setCategorySelect(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página al cambiar de categoría
  };

  const cambiarPagina = (numeroPagina) => {
    setCurrentPage(numeroPagina);
  };
  const toggleShowCombos = () => {
    getCombos(info["$tk3m09s"]);
    setShowCombos(!showCombos);
  };

  const getCombos = async (tk) => {
    //   console.log('entre a combos')
    setIsLoading(true);
    try {
      const response = await axios.get(API_URL + "/getCombos");
      //    console.log('el combo es:', response.data.success)
      setCombos(response.data.success);
    } catch (error) { }

    setIsLoading(false);
  };

  const handleDownloadProductsToMeta = async () => {
    try {
      const response = await axios.get(
        API_URL + "/exportProductsToCsv",
        {
          responseType: "blob", // Esto es importante para manejar archivos binarios
          headers: {
            Authorization: info["$tk3m09s"],
          },
        }
      );

      // Crear un enlace de descarga
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      let currentDate = getCurrentDate();
      let fileName = `productos_${currentDate}.csv`;
      link.setAttribute("download", fileName); // Nombre del archivo de descarga
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
      TOAST_ERROR("Ha ocurrido un error al descargar el archivo.")
    }
  };

  const DropdownMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className="relative inline-block text-left mx-4">
        <div>
          <button
            onClick={toggleMenu}
            className="bg-gray-800 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <span>Exportar productos</span>
            <svg
              className="fill-current h-4 w-4 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.5 7l4.5 4 4.5-4z" />
            </svg>
          </button>
        </div>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div
              ref={ref}
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div className="py-1">
                <button
                  onClick={handleDownloadProductsToMeta}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full text-left"
                >
                  Formato META
                </button>
                {/* Aquí se pueden agregar más botones*/}
              </div>
            </div>
          )}
        </Transition>
      </div>
    );
  };

  return (
    <div className="">
      {/* <SidebarAdmi /> */}
      <Sidebar />
      {isLoading ? (
        <CargandoSpinner txtcharge="Cargando datos" />
      ) : (
        <div className="p-4 sm:ml-64">
          {showCombos ? (
            <h1 className="text-2xl xl:text-3xl font-bold my-5 text-gray-700">
              Lista de combos
            </h1>
          ) : (
            <h1 className="text-2xl xl:text-3xl font-bold my-5 text-gray-700">
              Lista de productos
            </h1>
          )}
          <div className="flex w-full  pl-1 justify-stretch">
            {showCombos ? (
              <div className="mt-2">
                <Link
                  to={"/add-combo"}
                  className="ml-5 cursor-pointer  px-5 py-2.5 bg-[#1e3d8b] rounded transition duration-300 hover:bg-[#e9bf56] hover:text-black text-white"
                >
                  Nuevo Combo
                </Link>
              </div>
            ) : (
              <div className="mt-2">
                <Link
                  to={"/addProduct"}
                  className="bg-[#007bfc] px-5 py-2  text-white md:text-sm rounded sm:h-10 p-2.5 break-all shadow-xl transition duration-300 hover:bg-[#1e3d8b] hover:text-white"
                >
                  Nuevo producto
                </Link>
              </div>
            )}

            <div className=" ml-5 flex">
              <button
                onClick={toggleShowCombos}
                className="px-5 py-2 bg-[#e9bf56] rounded transition duration-300 hover:bg-[#1e3d8b] hover:text-white"
              >
                {showCombos ? "Ver productos" : "Ver combos"}
              </button>
            </div>

            {/* Dropdown with many options to export and download information about products */}
            <DropdownMenu />
          </div>

          {showCombos ? null : (
            <div className="flex">
              <div className="w-1/3 mt-6 mb-5 flex flex-wrap items-stretch ">
                <input
                  type="text"
                  placeholder="Buscar productos..."
                  value={search}
                  onChange={handleSearch}
                  className="rounded md:h-10 md:text-sm border-2  w-4/5 border-[#1e3d8b] mr-5 cursor-pointer px-2"
                />

                {/* <!--Search icon--> */}
                <span
                  className="input-group-text flex items-center whitespace-nowrap rounded px-1 py-1.5 text-center text-base font-normal text-neutral-black"
                  id="basic-addon2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <div className="mt-6 mb-5">
                <select
                  onChange={handleSelectChange}
                  value={categorySelect}
                  className="rounded md:h-10 md:text-sm border-2 border-[#1e3d8b] mr-5 cursor-pointer px-2"
                >
                  <option value="">Selecciona una categoría</option>
                  {categoriasUnicas.map((categoria, index) => (
                    <option key={index} value={categoria}>
                      {categoria}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {showCombos ? (
            <ComboTable combos={combos} />
          ) : (
            <TableProducts
              productsList={productosFiltrados.slice(
                (currentPage - 1) * productosPorPagina,
                currentPage * productosPorPagina
              )}
              total={products.length}
              currentPage={currentPage}
              paginasTotales={paginasTotales}
              cambiarPagina={cambiarPagina}
            />
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default ProductsManagment;
