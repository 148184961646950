import React, { useEffect, useState } from 'react';
import Header from '../../../componentes/Headers/Header';
import Footer from '../../../componentes/footer/Footer';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../../constants/constants';
import ProductoCard from '../../../componentes/productos/Card';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import CargandoSpinner from '../../../componentes/cargacomponetes/CargandoSpinner';
import FloatingWhatsAppButton from '../../../componentes/botones/FloatingWhatsAppButton';
import { dateDiffFromNow } from '../../../utils/utils';
import { SnipTrafficPlatform } from '../../../utils/Analitycs/analytics';



const TagSelecct = () => {
    const location = useLocation();

    const { titulo } = useParams();
    // Decodifica el título para manejar caracteres especiales correctamente
    const decodedTitulo = decodeURIComponent(titulo);

    //Datos del producto
    const [productsTags, setProductsTags] = useState([]);

    //Datros para le filtradod e prodcutos 
    const [filter, setFilter] = useState('');

    //datos de la paginacion
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8;

    //indicador de carga     
    const [isLoading, setIsLoading] = useState(false);


    const [categories, setCategories] = useState([]); // Nuevo estado para categorías
    const [categoryFilter, setCategoryFilter] = useState(''); // Nuevo estado para el filtro de categoría
    const [otherFilter, setOtherFilter] = useState('');
    const [showFilters, setShowFilters] = useState(false);  // Estado para controlar la visibilidad de los filtros


    const applyFilter = (products) => {
        // Primero filtrar por categoría si está establecido
        let filtered = categoryFilter ? products.filter(product => product.categoria === categoryFilter) : products;

        // Aplicar otros filtros sobre los productos ya filtrados por categoría
        switch (otherFilter) {
            case 'priceLowHigh':
                filtered = filtered.sort((a, b) => a.precio.cantidad - b.precio.cantidad);
                break;
            case 'priceHighLow':
                filtered = filtered.sort((a, b) => b.precio.cantidad - a.precio.cantidad);
                break;
            case 'titleAZ':
                filtered = filtered.sort((a, b) => a.titulo.localeCompare(b.titulo));
                break;
            case 'titleZA':
                filtered = filtered.sort((a, b) => b.titulo.localeCompare(a.titulo));
                break;
        }
        return filtered;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(1);
        setOtherFilter('');
        setCategoryFilter('');

        if (decodedTitulo) {
            getTags(decodedTitulo)
        }
    }, [decodedTitulo]);

    useEffect(() => {
        // Function to extract UTM parameters from the URL
        const extractUTMParameters = () => {

            const searchParams = new URLSearchParams(location.search);

            const utmSource = searchParams.get('utm_source');
            const utmMedium = searchParams.get('utm_medium') || 'desconocido';
            const utmCampaign = searchParams.get('utm_campaign');

            // const utmTerm = searchParams.get('utm_term');
            const utmContent = searchParams.get('utm_content') || 'desconocido';

            let today = new Date()

            // Set hours in zero
            today.setHours(0, 0, 0, 0);

            const lastVisitDateStr = localStorage.getItem('utm');
            const lastVisitDate = lastVisitDateStr ? new Date(JSON.parse(lastVisitDateStr).date) : null;
            lastVisitDate && lastVisitDate.setHours(0, 0, 0, 0);

            // Delete localstorage if date is more than 2 daty
            if (!lastVisitDate || dateDiffFromNow(today, lastVisitDate) > 2) {
                window.localStorage.removeItem("utm")
            }

            if (utmSource && utmMedium && utmCampaign) {

                // Has values
                const valuesUTM = {
                    "utmSource": utmSource,
                    "utmMedium": utmMedium,
                    "utmCampaign": utmCampaign,
                    "utmContent": utmContent,
                    "date": today
                }
                window.localStorage.setItem("utm", JSON.stringify(valuesUTM))

                // ------------ Event snipped -------------- //
                SnipTrafficPlatform(utmSource, utmCampaign, utmContent)

                return
            }

        };

        // Call the function when the component mounts
        extractUTMParameters();
    }, [location.search]);

    const getTags = async (titulotag) => {
        setIsLoading(true)
        try {
            const fordata = {
                tag: titulotag,
                limit: 40
            };
            const response = await axios.post(API_URL + '/getProductsByTag', fordata);
            // console.log('La respuesta original es', response.data);

            const seenIds = new Set();
            const uniqueProducts = response.data.filter(product => {
                if (!seenIds.has(product.id_producto)) {
                    seenIds.add(product.id_producto);
                    return true;
                }
                return false;
            });

            // Filtrar productos con stock disponible mayor a stock mostrado
            const filteredProducts = uniqueProducts.filter(product => product.stock.disponible > product.stock.mostrado);

            // Ordenar los productos por precio.cantidad de mayor a menor
            const sortedProducts = filteredProducts.sort((a, b) => b.precio.cantidad - a.precio.cantidad);

            // Extraer categorías únicas
            const categorySet = new Set(sortedProducts.map(product => product.categoria));
            setCategories([...categorySet]);
            setProductsTags(sortedProducts);
        } catch (error) {
            console.log('Fallo', error);
        }

        finally {
            setIsLoading(false); // Oculta el spinner independientemente del resultado
        }

    }

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const filteredProducts = applyFilter(productsTags);
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };


    const toggleFilters = () => {
        setShowFilters(!showFilters);  // Cambia el estado de visibilidad de los filtros
    };
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;
    return (
        <div className='flex flex-col justify-between bg-white min-h-screen'>
            <Header />
            <ScrollableCategories />
            {isLoading && <CargandoSpinner txtcharge="Cargando Producto" />}

            <div className='w-full lg:w-4/5 mx-auto mt-10 overflow-hidden'>

                <FloatingWhatsAppButton page="categorias" categoryName={decodedTitulo} urlproduto={fullUrl} />

                <div className='w-full flex justify-between xl:w-4/5 px-4 lg:px-6 mx-auto md:w-full md:w-full  lg:pl-4 lg:text-4xl text-3xl font-black mt-8 lg:mt-4 font-robotoch '>
                    <h1 className='md:ml-2 lg:ml-0'>
                        {decodedTitulo}
                    </h1>
                    <button className='text-2xl' onClick={toggleFilters}
                        name='btn-toggleFilters'
                        id='btn-toggleFilters'>
                        {showFilters ? <i className="fas fa-times"></i> : <i className="fas fa-filter"></i>}
                    </button>
                </div>
                {/* {showFilters && ( */}

                <div className={`transition-all ease-in-out duration-500 lg:w-4/5 lg:mx-auto lg:pl-5  px-2 mt-4 ${showFilters ? '' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                    <p className='ml-2 text-sm  font-semibold'>Filtrar y ordenar:</p>
                    <div className='flex mt-1'>
                        <select value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)}
                            className="w-1/2 md:w-1/3 py-3 px-1 m-1 block border border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                        >
                            <option value="">Todas las Categorías</option>
                            {categories.map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>
                        <select value={otherFilter} onChange={(e) => setOtherFilter(e.target.value)}
                            className="w-1/2 md:w-1/3 py-3 px-1 m-1 block border border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                        >
                            <option value="">Seleccionar filtro</option>
                            <option value="priceLowHigh">Precio: más bajo a más alto</option>
                            <option value="priceHighLow">Precio: más alto a más bajo</option>
                            <option value="titleAZ">Título: A-Z</option>
                            <option value="titleZA">Título: Z-A</option>
                        </select>
                    </div>
                </div>
                {/* )} */}
                <div >
                    <div className="flex-grow w-full lg:w-4/5 mx-auto mt-5">
                        <div className="grid grid-cols-2 md:grid-cols-3 w-full lg:grid-cols-4 xxl:grid-cols-5 gap-1 px-2 b">
                            {currentProducts.map((producto) => (
                                <ProductoCard key={producto.id_producto} producto={producto} />
                            ))}
                        </div>
                    </div>

                    <div className="p-4">
                        <div className="flex justify-center space-x-2">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => paginate(i + 1)}
                                    className={`px-4 py-2 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <Footer />

        </div>
    );
};

export default TagSelecct;
