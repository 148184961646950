import React from 'react';
import { HashLoader } from 'react-spinners';

const CargandoSpinner = ({txtcharge}) => {
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex flex-row justify-center items-center z-50">
            <div className='flex flex-col justify-center items-center'>
                <HashLoader color="#ffffff" size={100} />
                <div className='w-80 mx-auto text-center'>
                <h3 className='text-white font-black  font-robotoch uppercase mt-4 '>
                    {txtcharge}
                </h3>
                </div>
                
            </div>
        </div>
    );
};

export default CargandoSpinner;
