// Function to get number of days from difference between 2 dates
export function dateDiffFromNow(date1, date2 = Date.now()) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  return Math.ceil(Math.abs(date1 - date2) / MS_PER_DAY);
}

export function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

/**
 * Transforms a given text so that the first letter of each sentence is capitalized,
 * and all other letters are in lowercase. A sentence is considered to start after a period followed by a space.
 *
 * @param {string} text - The input text to be transformed.
 * @return {string} - The transformed text with proper capitalization.
 */
export function capitalizeAfterPeriod(text) {
  // Convert all text to lowercase
  text = text.toLowerCase();
  // Split the text into sentences using the period followed by a space as the separator
  let sentences = text.split(". ");
  // Capitalize the first letter of each sentence
  sentences = sentences.map(
    (sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1)
  );
  // Join the sentences back together with a period and a space
  return sentences.join(". ");
}
//funcion para un redondeo mas exact
export function roundToTwoDecimals(num) {
  return Math.round(num * 100) / 100;
}

//redondeo para clacular el IVA como lo hace odoo
export function customRounding(number) {
  // Convert the number to a string to handle the decimals
  let strNum = number.toFixed(10);
  // Split the integer part from the decimal part
  let parts = strNum.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // If the decimal part has fewer than 3 or 4 digits, pad with zeros
  if (decimalPart.length < 3) {
      decimalPart = decimalPart.padEnd(3, '0');
  }
  if (decimalPart.length < 4) {
      decimalPart = decimalPart.padEnd(4, '0');
  }

  // Get the relevant digits
  let secondDecimal = parseInt(decimalPart[1]);
  let thirdDecimal = parseInt(decimalPart[2]);
  let fourthDecimal = parseInt(decimalPart[3]);

  // Apply rounding rules
  if (thirdDecimal < 5) {
      if (fourthDecimal > 4 && secondDecimal > 4) {
          number = Math.round((number + 0.01) * 100) / 100;
      } else {
          number = Math.round(number * 100) / 100;
      }
  } else {
      number = Math.round(number * 100) / 100;
  }

  return number;
}

export function getRandomInt(max=9999999999) {
  return Math.floor(Math.random() * max).toString();
}