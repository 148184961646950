import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants/constants';

const DiscountModal = ({ isOpen, onClose, onApply, carstore }) => {
    const [discountCode, setDiscountCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    useEffect(() => {
        if (isOpen) {
            setDiscountCode('');
            setErrorMessage('');
        }
    }, [isOpen]);

    const filterProductsByPrice = (maxPrice) => {
        return carstore.filter(product => product.precio.cantidad <= maxPrice);
    };

    const getAllSKUs = () => {
        return carstore.map(product => product.id_producto);
    };

    

    const getLowestPrice = () => {
        // Filtrar productos con precio.cantidad mayor que 0
        const validPrices = carstore
            .filter(product => product.precio.cantidad > 0)
            .map(product => product.precio.cantidad);
    
        // Si no hay precios válidos, retornar 0
        if (validPrices.length === 0) return 0;
    
        // Retornar el precio más bajo entre los precios válidos
        return Math.min(...validPrices);
    };

    const handleApplyDiscount = async () => {
        const filteredProducts = getLowestPrice();
        const skus = getAllSKUs();

        const config = {
            headers: {
                'Authorization': token['$tk3m09s']
            }
        };

        const payload = {
            coupon: discountCode,
            amount: filteredProducts,
            ids_products: skus,
            id_user: token['$udi09345']
        };

        // console.log('Enviare',payload )

        try {
            const response = await axios.post(API_URL + '/validateCoupon', payload, config);
            const { coupon_details, message } = response.data;
            setErrorMessage('');

            onApply(discountCode, {
                coupon_details,
                message
            });

            onClose();
        } catch (error) {
            setErrorMessage(error.response.data.message);
        }
    };

    const handleChange = (e) => {
        setDiscountCode(e.target.value.toUpperCase());
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleApplyDiscount();
        }
    };

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div 
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 modal-overlay" 
            onClick={handleClickOutside}
        >
            <div className="bg-white p-6 rounded-lg shadow-lg w-80 relative">
                <button
                    className="absolute -top-4 -right-4 text-red-500 bg-white border border-red-500 rounded-full w-8 h-8 flex items-center justify-center hover:bg-blue-700 hover:text-white hover:border-blue-700"
                    onClick={onClose}
                >
                    <i className="fas fa-times text-xl"></i>
                </button>
                <h2 className="text-lg font-bold text-center mb-4">Ingresa tu código de descuento</h2>
                <input
                    type="text"
                    value={discountCode}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className="border p-2 mb-4 w-full"
                    placeholder="Código de descuento"
                />
                {errorMessage && (
                    <p className="text-red-500 mb-4">{errorMessage}</p>
                )}
                <button
                    onClick={handleApplyDiscount}
                    className="bg-[#1a2869] text-white p-2 rounded w-full"
                >
                    Aplicar
                </button>
            </div>
        </div>
    );
};

export default DiscountModal;
