import React from 'react';

const TableDto1 = ({ datosGrafica }) => {
  return (
    <div className="mt-1 overflow-auto max-h-48">
      <h2 className="ml-16 text-base font-semibold">
        Palabras clave por búsqueda al día
      </h2>
      <table className="w-5/5 border-collapse shadow-lg mx-auto mt-2">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-3 text-left font-semibold text-black">Fecha</th>
            <th className="px-3 text-left font-semibold text-black">Palabras Clave</th>
          </tr>
        </thead>
        <tbody>
          {datosGrafica.fechas.map((fecha, index) => (
            <tr key={index} className="border-b hover:bg-gray-50">
              <td className="px-3 py-1 text-black">{fecha}</td>
              <td className="px-3 text-black">
                {typeof datosGrafica.palabrasClave[fecha] === "object" ?
                  Object.entries(datosGrafica.palabrasClave[fecha]).map(([palabra, cantidad]) => (
                    <span key={palabra} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-black mr-2 mb-2">
                      {palabra}: {cantidad}
                    </span>
                  )) :
                  datosGrafica.palabrasClave[fecha]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableDto1;
