import React, { useState } from 'react';

const Resenaproducto = ({ listacometarios }) => {

    const [verTodas, setVerTodas] = useState(false);

    const [ocultar, setOcultar] = useState(false);

    const [animar, setAnimar] = useState(false);




    const formatearFecha = (fechaISO) => {
        const meses = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
        const fecha = new Date(fechaISO);
        const dia = fecha.getDate();
        const mes = meses[fecha.getMonth()];
        const ano = fecha.getFullYear();
        return `${dia} ${mes} ${ano}`;
    };




    const resenas = listacometarios.map(comentario => ({
        id: comentario.id_comentario,
        user: comentario.id_usuario, // Asumiendo que quieres usar el id_usuario como el nombre de usuario
        date: formatearFecha(comentario.created_at), // Usar la función formatearFecha para convertir la fecha
        resena: comentario.comentario,
        calificacion: comentario.calificacion,
        // Los campos like y dislike no están presentes en los nuevos datos, se podrían dejar como valores estáticos o eliminar
        like: 0, // Valor estático, ajustar según sea necesario
        dislike: 0 // Valor estático, ajustar según sea necesario
    }));
    const mostrarResenas = verTodas ? resenas : resenas.slice(0, 2);

    const renderEstrellas = (calificacion) => {
        let estrellas = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= calificacion) {
                estrellas.push(<i key={i} className="fas fa-star text-yellow-500"></i>);
            } else if (i - 0.5 === calificacion) {
                estrellas.push(<i key={i} className="fas fa-star-half-alt text-yellow-500"></i>);
            } else {
                estrellas.push(<i key={i} className="far fa-star text-yellow-500"></i>);
            }
        }
        return estrellas;
    };

    return (
        <div className='flex flex-col justify-center items-center mt-5 p-2 border-b border-gray-500 lg:border-0 lg:w-full'>
            <div className='flex text-left w-full pl-5'>
                <p className='text-[#030303] font-black uppercase'>comentarios</p>
                <p className='text-[#1e3d8b] ml-1 font-black uppercase'> Y reseñas</p>
            </div>
            {mostrarResenas?.map((resena, index) => (

                <div
                    key={resena.id}
                    className={`flex flex-col rounded-lg w-80 mb-2 items-left p-4 lg:w-4/5 justify-left mt-2 shadow-lg rounded-xl bg-white transition-opacity duration-1500 ${verTodas ? (ocultar ? 'opacity-0' : 'opacity-100') : (index >= 2 && animar ? 'opacity-0' : 'opacity-100')}`}
                >


                    <div className='flex mt-2'>
                        <p className=' mt-0.5 text-[#757575] font-semibold uppercase text-xs'>{resena.date}</p>
                    </div>
                    <div className='text-justify text-xs mt-2'>
                        <p>{resena.resena}</p>
                    </div>
                    <div className='flex mt-2 justify-between'>
                        <p className='text-[#1e3d8b] font-semibold uppercase text-sm'>
                            {renderEstrellas(resena.calificacion)}
                        </p>
                        {/* 
                        <div className='flex'>
                            <p className='ml-2 mt-0.5 text-[#030303] uppercase text-xs'> {resena.like} <i className="fas text-[#1e3d8b] fa-thumbs-up"></i></p>
                            <p className='ml-2 mt-0.5 text-[#030303] uppercase text-xs'> {resena.dislike} <i className="fas text-[#1e3d8b] fa-thumbs-down"></i></p>
                        </div>
                        */}
                    </div>
                </div>
            ))}
            {mostrarResenas.length != 0 

                ? <button 
                
                name='btn-seenall'
                id='btn-seenall'
                
                onClick={() => {
                    if (!verTodas) {
                        setAnimar(true);
                        setTimeout(() => {
                            setVerTodas(true);
                        }, 1500); // Asegúrate de que este tiempo coincida con la duración de la animación
                    } else {
                        setOcultar(true);
                        setTimeout(() => {
                            setVerTodas(false);
                            setOcultar(false);
                        }, 1500); // Asegúrate de que este tiempo coincida con la duración de la animación
                    }
                }} className='mt-4 border-b-2 uppercase px-4 rounded text-[#757575] transition duration-300 text-sm'>
                    {verTodas ? 'Ver Menos' : 'Ver Todos'}
                </button>
                
                : <p>Aún no hay comentarios.</p>
            }


        </div>
    );
};

export default Resenaproducto;