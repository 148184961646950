import React, { useState, useEffect } from 'react';

function IconoModal({ isOpen, onClose, imageUrl, onSave }) {
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (isOpen) {
            setImage(imageUrl || null);
        }
    }, [isOpen, imageUrl]);

    const onFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            compressImage(file);
        }
    };

    const onFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            compressImage(file);
        }
    };

    const compressImage = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 800; // max width for the image
                const maxHeight = 800; // max height for the image
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                const dataUrl = canvas.toDataURL('image/png'); // keep PNG format
                setImage(dataUrl);
            };
        };
        reader.readAsDataURL(file);
    };

    const handleSave = () => {
        onSave(image); // Send the base64 string to the onSave function
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-4">Agregar icono</h2>
                <div
                    className="border-2 border-dashed border-gray-400 p-4 rounded-lg flex justify-center items-center cursor-pointer"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={onFileDrop}
                    onClick={() => document.getElementById('fileInput').click()}
                >
                    {image ? (
                        <img width="300" height="200"
                            src={image} alt="Preview" className="max-w-full max-h-64" />
                    ) : (
                        <p>Arrastra y suelta una imagen aquí, o haz clic para seleccionar una imagen</p>
                    )}
                </div>
                <input
                    type="file"
                    id="fileInput"
                    onChange={onFileChange}
                    className="hidden"
                />
                <button
                    name='save2'
                    id='save2'
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                    onClick={handleSave}
                >
                    Guardar
                </button>
                <button
                    name='close2'
                    id='close2'
                    className="bg-gray-500 text-white px-4 py-2 rounded mt-4 ml-2"
                    onClick={onClose}
                >
                    Cerrar
                </button>
            </div>
        </div>
    );
}

export default IconoModal;
