import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../../../componentes/footer/Footer';
import Header from '../../../componentes/Headers/Header';
import { API_URL } from '../../../constants/constants';
import ScrollableCategories from '../../../componentes/sliders/ScrollableCategories';
import { goToPay } from '../../../utils/PixelMeta/metaPixelEvents';
import ComboCard from './ComboCard';
import { useProductsCart } from '../../../store/bookStore';
import CargandoSpinner from '../../../componentes/cargacomponetes/CargandoSpinner';
import FloatingWhatsAppButton from '../../../componentes/botones/FloatingWhatsAppButton';

function ListCombos() {
    let navigate = useNavigate();
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null
    const { fetchCarts } = useProductsCart()

    const [isLoading, setIsLoading] = useState(false);
    const [combos, setCombos] = useState([]);

    // Efecto para desplazarse a la parte superior al montar el componente
    useEffect(() => {
        window.scrollTo(0, 0);
        getCombos()
    }, []);

    const getCombos = async () => {
        // console.log('entre a combos')
        setIsLoading(true);
        window.scrollTo(0, 0);

        try {



            const response = await axios.get(API_URL + '/getCombos');

            //    console.log('La Lista de combos es padrino ', response.data.success)
            // Verifica que realmente llegas a esta línea
            //   console.log('Procesando los combos para añadir totales...');

            const combosWithTotals = response.data.success.map(combo => {
                //   console.log('Procesando combo:', combo); // Verifica la estructura de cada combo
                let totalConIva = 0;
                let totalSinIva = 0;
                let totalConDescuento = 0

                combo.productos.forEach(producto => {
                    //  console.log('Producto:', producto); // Verifica la estructura de cada producto
                    totalConIva += producto.precio.cantidad;
                    totalSinIva += producto.precio_sin_iva.cantidad;
                    totalConDescuento += producto.precio_descuento.cantidad;
                });

                //   console.log('Totales calculados para combo:', { totalConIva, totalSinIva });

                return {
                    ...combo,
                    totalConIva,
                    totalSinIva,
                    totalConDescuento
                };
            });

            // console.log('aaaaaCombos con totales:', combosWithTotals);
            setCombos(combosWithTotals);
        } catch (error) {
        }

        setIsLoading(false);
    };



    //ir a combo
    const goComboDetail = (idCombo) => {
        // console.log('-----El combo selecionado es:', idCombo)

        navigate(`/combonum/${idCombo}`);
    }


    const gopay = (totalcombo) => {
        setIsLoading(true);  // Comienza la carga global

        goToPay(totalcombo, token ? token['$email'] : 'No registrado');
        fetchCarts(token);
        navigate('/Pagos');
        setIsLoading(false);  // Comienza la carga global

    }

    return (
        <div className='flex flex-col justify-between bg-white min-h-screen'>
            <Header />

            <ScrollableCategories />
            <FloatingWhatsAppButton page="combos" categoryName="Combos" />


            {isLoading && (
                <CargandoSpinner txtcharge='Cargando datos' />

            )}
            {/* Eliminamos la clase h-screen del div principal del contenido para evitar que tome toda la pantalla y desplace al footer */}
            <div className='w-full lg:w-4/5 mx-auto mt-10 overflow-hidden'>
                <div className='w-full px-2 mx-auto md:w-full md:w-full lg:w-4/5 lg:pl-4 lg:text-4xl text-3xl font-black mt-8 lg:mt-4 font-robotoch '>

                    <h1 className='md:ml-2 lg:ml-0'>
                        Nuestros Combos
                    </h1>
                </div>
                <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4'>
                    {combos.filter(combo => combo.active).map((combo) => (
                        <ComboCard
                            key={combo.id_combo}
                            combo={combo}
                            goComboDetail={goComboDetail}
                            gopay={gopay}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </div>

    );

}

export default ListCombos;
