import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import ModalGraficos from './ModalGracios';

const MetodosPagoLineChart = ({ datosGrafica, datos1 }) => {

  const [showModal, setShowModal] = useState(false);

  const dataMetodosPago = {
    labels: datosGrafica.fechas,
    datasets: [
      {
        label: 'Oxxo',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.oxxo || 0),
        borderColor: 'rgba(0, 123, 252, 0.7)', // #007BFC
        fill: false,
      },
      {
        label: 'Transferencia',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.transferencia || 0),
        borderColor: 'rgba(15, 107, 227, 0.7)', // #0F6BE3
        fill: false,
      },
      {
        label: 'Debito',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.debito || 0),
        borderColor: 'rgba(25, 91, 176, 0.7)', // #195BB0
        fill: false,
      },
      {
        label: 'Credito',
        data: datosGrafica.fechas.map(fecha => datosGrafica.metodosPago[fecha]?.credito || 0),
        borderColor: 'rgba(22, 61, 109, 0.7)', // #163D6D
        fill: false,
      },
    ],
  };

  const opcionesMetodosPago = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const opcionesMetodosPago2 = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Métodos de Pago',
      },

    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },


  };

  const colors = [
    'rgba(59, 89, 152, 0.7)',   // Color de Facebook
    'rgba(188, 42, 141, 0.7)',  // Color de Instagram
    'rgba(30, 61, 139, 0.7)',   // Color de Anuncios dinámicos
    'rgba(0, 0, 0, 0.7)',       // Color de TikTok
  ];

  const getPlatforms = (traficoPlataforma) => {
    const fechas = Object.keys(traficoPlataforma);
    const plataformas = new Set();

    fechas.forEach(fecha => {
      Object.keys(traficoPlataforma[fecha]).forEach(plataforma => {
        plataformas.add(plataforma);
      });
    });

    return Array.from(plataformas);
  };

  const plataformas = getPlatforms(datosGrafica.traficoPlataforma);

  const generateDatasets = (datosGrafica, plataformas, colors) => {
    return plataformas.map((plataforma, index) => ({
      label: plataforma.charAt(0).toUpperCase() + plataforma.slice(1),
      data: datosGrafica.fechas.map(fecha => datosGrafica.traficoPlataforma[fecha]?.[plataforma] || 0),
      borderColor: colors[index % colors.length],
      fill: false,
    }));
  };

  const dataTraficoPlataforma = {
    labels: datosGrafica.fechas,
    datasets: generateDatasets(datosGrafica, plataformas, colors),

  };



  const opcionesTraficoPlataforma = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda
      },

    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const opcionesTraficoPlataforma2 = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Ocultar la leyenda
      },
      title: {
        display: true,
        text: 'Tráfico de Plataforma',
      },
    },

    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };


  const dataFinal = datos1 ? dataTraficoPlataforma : dataMetodosPago;
  const opcionesFinal = datos1 ? opcionesTraficoPlataforma : opcionesMetodosPago;
  const opcionesFinal2 = datos1 ? opcionesTraficoPlataforma2 : opcionesMetodosPago2;


  return (
    <div className="w-full px-1">

      <div className="flex rid grid-flow-col justify-between px-6 mx-auto  items-center">
        <h2 className="text-center text-base font-semibold">
          {datos1 ? 'Tráfico de Plataforma' : 'Métodos de Pago'}
        </h2>
        <button
          name='btn-seemore6'
          id='btn-seemore6'
          onClick={() => setShowModal(true)}
          className="ml-4 px-2 py-1 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <i className="fas fa-search-plus"></i>
        </button>
      </div>
      <Line data={dataFinal} options={opcionesFinal} />


      <ModalGraficos show={showModal} onClose={() => setShowModal(false)}>
        <Line data={dataFinal} options={opcionesFinal2} />
      </ModalGraficos>
    </div>
  );
};

export default MetodosPagoLineChart;
