import React, { useEffect, useState } from 'react';
import { API_URL } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import TablaCupones from '../../componentes/Admi/TableCupons';
import Sidebar from '../../componentes/Admi/Sidebar';

function PromosCupones() {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [listCupones, setListCupones] = useState(null);

    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

    useEffect(() => {
        if (info) {
            cargarPromo(info['$tk3m09s']);
        } else {
            navigate('/');
        }
    }, []);

    //cargar los cupones 
    const cargarPromo = async (tk) => {
        setIsLoading(true);

        try {
            const response = await fetch(`${API_URL}/getCoupons`, {
                headers: {
                    Authorization: tk,
                }
            });
            const data = await response.json();

            if (data['error'] === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            } else {
                // console.log('la respuesta es ', data.list_coupons);
                setListCupones(data.list_coupons);
                setIsLoading(false);
            }

        } catch (error) {
            console.log(error);
            console.log('Ha ocurrido un error');
            setIsLoading(false);
        }
    };

    return (
        <div className=''>
            <Sidebar />
            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <div className='p-4 sm:ml-64'>
                    <h1 className="text-3xl font-bold">Apartado de Promociones </h1>
                    {listCupones ? (
                        <TablaCupones cupones={listCupones} />
                    ) : (
                        <p>No hay cupones disponibles.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default PromosCupones;
