import React from 'react';

function Columnas() {

  const items = [
    {
      id: 1,
      icon: "fas fa-truck",
      title: "Entrega de 2/4 días",
      content: "Nuestra entrega tarda sólo 10 días."
    },
    {
      id: 2,
      icon: "fas fa-headset",
      title: "Servicio al Cliente",
      content: "Servicio al cliente de 9am a 6pm de lunes a viernes."
    },
    {
      id: 3,
      icon: "fas fa-users",
      title: "32mil Seguidores",
      content: "Súmate a nuestra comunidad Hantec."
    },
    {
      id: 4,
      icon: "fas fa-award",
      title: "10 Años Hantec",
      content: "Especializados en herramientas y equipo desde hace 10 años."
    }
  ];


  return (
    <div className="flex px-5  mx-auto  flex-wrap justify-center my-10">
      {items.map((item, index) => (
        <div key={item.id}
          className={`mt-2  p-4 w-1/2 md:w-1/4 flex flex-col items-center ${index % 2 === 0 ? "bg-[#1e3d8b] bg-opacity-10" : "bg-[#f0f0f0] bg-opacity-80"
            }`}>
          <i className={`${item.icon} text-6xl text-[#1e3d8b]`}></i>
          <h2 className='text-[#1e3d8b] font-black mt-4 text-sm lg:text-xl text-center'>
            {item.title}
          </h2>
          <p className='text-xs mt-5 lg:text-sm text-center'>
            {item.content}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Columnas;
