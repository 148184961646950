import React, { useEffect } from 'react';
import Header from '../../componentes/Headers/Header';
import Footer from '../../componentes/footer/Footer';
import img1 from '../../assets/Banner10.webp'

import img2 from '../../assets/Banner2.webp'
import img3 from '../../assets/BannerCompraSegura.webp'

const GarantiasPage = () => {

    const dtoGarantias = [
        {
            id: 1,
            imgUrl: img1, // Usa la variable de la imagen importada en lugar de la URL
            titulo: 'Suministro de Refacciones por 10 Años',
            contenido: 'Comprometidos con la excelencia y la longevidad de nuestros productos, Hantec ofrece un suministro garantizado de refacciones originales durante 10 años. Este compromiso te asegura que tu equipo seguirá funcionando de manera óptima, asegurando su rendimiento y eficiencia a lo largo del tiempo. En Hantec, nos aseguramos de que tengas acceso al soporte necesario para mantener tu equipo en perfectas condiciones, ahora y en el futuro.'
        },
        {
            id: 2,
            imgUrl: img2, // Usa la variable de la imagen importada en lugar de la URL
            titulo: 'Garantía Extendida de Dos Años',
            contenido: 'En Hantec, entendemos lo vital que es para ti contar con productos fiables y de alta calidad. Por ello, todos nuestros artículos vienen con una garantía extendida de dos años, cubriendo cualquier defecto de fabricación. Esta cobertura garantiza que tu compra esté protegida, brindándote la seguridad y confianza para disfrutar de tus productos con total tranquilidad.'
        },
        {
            id: 3,
            imgUrl: img3, // Usa la variable de la imagen importada en lugar de la URL
            titulo: 'Servicio Postventa Excepcional',
            contenido: 'Nuestro compromiso contigo no termina con la compra; se extiende a través de un servicio postventa excepcional. Si tienes alguna duda o enfrentas problemas con tu producto, nuestro equipo de servicio técnico está listo para ayudarte. Comunícate con nosotros en cualquier momento para recibir asistencia técnica personalizada.'
        }
    ];

    useEffect(() => {
        // Forzar la re-renderización del Slider una vez que los productos están listos
        window.scrollTo(0, 0);
    }, []); // Dependencia a 'productos' para actualizar cuando cambie

    return (
        <div>
            <Header />
            <div className=' lg:w-5/6 mx-auto flex-grow'> {/* flex-grow permite que este div ocupe el espacio disponible */}



                <section className="text-gray-600 body-font">
                    <div className="container px-5 py-2 mx-auto">
                        <div className="flex flex-wrap w-full mb-20">
                            
                            <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                                <h1 className="sm:text-3xl text-2xl font-semibold title-font mb-2 text-[#1e3d8b]">
                                    Compromiso de Garantía
                                    <span className='text-black ml-2 font-medium'>
                                        y Soporte Integral de Hantec

                                    </span></h1>


                                <div className="h-1 w-20 bg-blue-900 rounded"></div>
                            </div>
                            <p className="lg:w-1/2 w-full leading-relaxed text-gray-500 text-justify">
                                En Hantec, nos esforzamos por brindarte no solo productos de alta calidad, sino también un soporte y garantía inigualables. Tu satisfacción es la esencia de nuestro compromiso, y estamos aquí para asegurarnos de que disfrutes de una experiencia sin preocupaciones.
                            </p>
                        </div>


                        <div className="flex flex-wrap -m-3">
                            {dtoGarantias.map((garantia) => (
                                <div className="xl:w-1/3 md:w-1/2 p-4" key={garantia.id}>
                                    <div className="flex flex-col bg-gray-100 p-6 rounded-lg h-full"> {/* Asegura que el contenedor de la tarjeta sea flexible y ocupe todo el alto disponible */}
                                        <img width="300" height="200"
 className="h-40 rounded w-full object-cover mb-6" src={garantia.imgUrl} alt={garantia.titulo} />
                                        <h2 className="text-lg text-gray-900 font-semibold title-font mb-4">{garantia.titulo}</h2>
                                        <p className="flex-grow leading-relaxed text-base text-justify">{garantia.contenido}</p> {/* Utiliza flex-grow para que el párrafo ocupe el espacio restante, asegurando que el footer de cada tarjeta esté alineado */}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </section>


            </div>


            <Footer />
        </div>
    );
};

export default GarantiasPage;
