import React, { useEffect, useState } from 'react';
import SidebarAdmi from '../../componentes/Admi/SidebarAdmi';
import BannersTable from '../../componentes/Admi/BannersTable';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import axios from 'axios';

import { API_URL } from '../../constants/constants';
import BannerEditModal from '../../componentes/Admi/BannerEditModal';
import Sidebar from '../../componentes/Admi/Sidebar';
import IconoModal from '../../componentes/Admi/IconoModal';

function BannersPage() {
    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const [imgbanners, setImgBanners] = useState([]);
    const [imgbannersSecundary, setImgBannersSecundary] = useState([]);

    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageSecundary, setCurrentPageSecundary] = useState(0);

    const itemsPerPage = 5;
    const itemsPerPageDos = 10;


    //agregar un banner neuvo de los banners secundarios 
    const [modalOpen, setModalOpen] = useState(false);
    const [currentBanner, setCurrentBanner] = useState({}); // Estado vacío para un nuevo banner

    //datos para abrir el modal de iconos 
    const [isNuevoModalOpen, setIsNuevoModalOpen] = useState(false);

    const openNuevoModal = () => setIsNuevoModalOpen(true);
    const closeNuevoModal = () => setIsNuevoModalOpen(false);

    const [imageData, setImageData] = useState(null);

    useEffect(() => {
        getBanners();
        getBannersSecundary()
    }, []);

    const getBanners = async () => {
        setIsLoading(true); // Inicia la carga
        try {
            const response = await fetch(`${API_URL}/getBanners`);

            const data = await response.json();
            // console.log('los dato son', data)
            setImgBanners(data);
        } catch (error) {
            console.log('Error fetching banners:', error);
        }
        setIsLoading(false); // Finaliza la carga
    };

    const getBannersSecundary = async () => {
        setIsLoading(true); // Inicia la carga
        try {
            const config = {
                headers: {
                    'Authorization': info['$tk3m09s']
                },
                timeout: 10000000

            };
            const response = await axios.get(API_URL + '/managmentSecondBanners', config);

            const sortedBanners = response.data.success.sort((a, b) => b.active - a.active);

            //console.log('los datos de los Banners Secundarios son', response.data.success)
            setImgBannersSecundary(sortedBanners);

        } catch (error) {
            console.log('Error fetching banners:', error);
        }
        setIsLoading(false); // Finaliza la carga
    };
    //Banners principales
    const totalPages = Math.ceil(imgbanners.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, imgbanners.length);
    //Banners secundatios

    const totalPagesSecundary = Math.ceil(imgbannersSecundary.length / itemsPerPageDos);
    const startIndexSecundary = currentPageSecundary * itemsPerPageDos;
    const endIndexSecundary = Math.min(startIndexSecundary + itemsPerPageDos, imgbannersSecundary.length);

    const handleAddBanner = () => {
        setCurrentBanner({ url: '', active: false }); // Establece valores por defecto para un nuevo banner
        setModalOpen(true);
    };


    const handleClose = () => {
        setModalOpen(false);
    };

    const handleSave = (selectedFile, idBanner, description, navigation, active) => {
        if (selectedFile) {
            setIsLoading(true); // Muestra el spinner

            const reader = new FileReader();

            // Evento para actulizar banner 
            reader.onloadend = async () => {
                // El resultado contiene el contenido del archivo en Base64.
                const base64String = reader.result;


                const payload = {
                    "titulo": selectedFile.name,
                    "descripcion": description,
                    "url_imagen": base64String,
                    "navegacion": navigation,
                    "activo": active,
                    "id_usuario": info['$udi09345']
                };



                try {
                    //      console.log('voy a guardar En secundarioo', payload)

                    const config = {
                        headers: {
                            'Authorization': info['$tk3m09s']
                        },
                        timeout: 10000000

                    };
                    // Realiza la solicitud POST con Axios
                    const response = await axios.post(API_URL + '/managmentSecondBanners', payload, config);

                    // respueas
                    //      console.log('Respuesta de la API Banners Secundarios:', response.data);

                    // Cierra el modal después de la operación
                    setModalOpen(false);
                    getBannersSecundary();

                } catch (error) {
                    // Maneja cualquier error que ocurra durante la solicitud
                    console.error('Error al enviar los datos a la API:', error);
                    if (error.code === 'ECONNABORTED') {
                        console.log('Tiempo de espera agotado uppdatebanners');
                    }


                } finally {
                    setIsLoading(false); // Oculta el spinner independientemente del resultado
                }

            };

            // Inicia la conversión del archivo a Base64.
            reader.readAsDataURL(selectedFile);
        }
    };


    const handleSaveIcon = (image) => {
        setImageData(image);
        console.log('Imagen guardada:', image);
    };

    return (
        <div className=''>

            <BannerEditModal
                isOpen={modalOpen}
                onClose={handleClose}
                onSave={handleSave} // Uso condicional de onSave
                banner={currentBanner} />

            {/* <SidebarAdmi /> */}
            <Sidebar />
            <div className='p-4 sm:ml-64'>
                <div className=' h-1/3 w-full pt-10'>
                    <h2 className='font-black text-3xl'>Archivos multimedia sitio web</h2>
                </div>

                {isLoading ? (
                    <CargandoSpinner txtcharge="Cargando datos" />
                ) : (
                    <div>
                        <h2 className='mt-8 font-semibold text-lg mb-0'>Banners Principales </h2>
                        <BannersTable
                            imgbanners={imgbanners}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                            startIndex={startIndex}
                            endIndex={endIndex}
                            getBanners={getBanners}
                            secundary={false}
                        />


                        <div className='flex justify-between mt-8 py-2  pr-24 px-2'>
                            <h2 className='font-semibold text-lg mb-0'>tabla de iconos promo</h2>

                            <button
                                id='addicon2'
                                name='addicon2'
                                className='bg-[#163d6d] text-white px-10 py-2 rounded'
                                onClick={openNuevoModal}
                            >
                                Agregar icono
                            </button>

                            <IconoModal
                                isOpen={isNuevoModalOpen}
                                onClose={closeNuevoModal}
                                onSave={handleSaveIcon}
                                imageUrl='https://hantec.us/assets/iconovrnofinal1-UMzT67Fd.png'
                            />

                        </div>

                        <div className='flex justify-between mt-8 py-2  pr-24 px-2'>
                            <h2 className='font-semibold text-lg mb-0'>Banners Secundarios </h2>
                            <button className='bg-[#163d6d] text-white px-10 py-2 rounded' name='addbanner1' id='addbanner1' onClick={handleAddBanner}>
                                Agregar Banner
                            </button>

                        </div>

                        <BannersTable
                            imgbanners={imgbannersSecundary}
                            currentPage={currentPageSecundary}
                            itemsPerPage={itemsPerPageDos}
                            totalPages={totalPagesSecundary}
                            setCurrentPage={setCurrentPageSecundary}
                            startIndex={startIndexSecundary}
                            endIndex={endIndexSecundary}
                            getBanners={getBannersSecundary}
                            secundary={true}


                        />


                    </div>

                )}
            </div>
        </div>
    );
}

export default BannersPage;
