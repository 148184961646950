import React from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterForm from '../../componentes/sesiones/registerform/RegisterForm';
import Imglogin from '../../assets/fondologin.webp'
import Forgotpass from '../../componentes/sesiones/passform/Forgotpass';
import HeaderEspecial from '../../componentes/Headers/HeadeEpecial';
import Footer from '../../componentes/footer/Footer';
import Logo from '../../assets/Logo1.webp';

function RecuperarContrasena() {
    let navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Navega a la página anterior
    };

    return (

        <div>
            <div className='lg:flex lg:h-screen '>
                <div className="hidden lg:block lg:w-1/2 lg:h-auto bg-blue-300">
                    <img width="300" height="200"
 className='w-full h-full object-cover' src={Imglogin} alt="Descripción de la imagen" />
                </div>

                <div className=' lg:w-1/2 lg:h-auto'>
                    <div className="hidden lg:flex lg:justify-center lg:items-center mt-20">
                        <img width="300" height="200"
 src={Logo} alt="Logo" className="h-16 w-auto" />
                    </div>

                    <div className='lg:hidden'>
                        <HeaderEspecial />

                    </div>
                    <Forgotpass />
                </div>

            </div>

            <Footer />
        </div>

    );
}

export default RecuperarContrasena;
