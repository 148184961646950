import React from 'react';

const AlertaWarning = ({ onClose, titleAlert, conteAlert }) => {
    return (
        // Se ajusta el contenedor para ser fijo y abarcar toda la pantalla, con flexibilidad para posicionar su contenido.
        <div className="fixed inset-0 flex items-start justify-center p-4 bg-black bg-opacity-50 sm:justify-end">
            {/* Ajustes de responsive: centrado en móviles y a la derecha en escritorio */}
            <div role="alert" className="mt-5 w-full max-w-xs rounded border-s-4 border-red-500  bg-red-50 p-4 sm:mt-0 sm:max-w-sm lg:max-w-md">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-red-800">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
                            <path
                                fillRule="evenodd"
                                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <strong className="block font-medium">{titleAlert || "Something went wrong"}</strong>
                    </div>
                    <button
                        name='btn-close10'
                        id='btn-close10' onClick={onClose} className="text-red-800">
                        <span className="sr-only">Close</span>
                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                    </button>
                </div>
                <p className="mt-2 text-sm text-red-700">
                    {conteAlert || "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo quasi assumenda numquam deserunt consectetur autem nihil quos debitis dolor culpa."}
                </p>
            </div>
        </div>
    );
};

export default AlertaWarning;
