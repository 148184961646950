import React from 'react';

const ModalErrorTransfer = ({ onClose }) => {
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96 relative">
                <button

                    name='btn-closemodalerror'
                    id='btn-closemodalerror'
                    className="absolute top-2 right-2 text-red-500 text-2xl px-2 hover:text-gray-700"
                    onClick={onClose}
                >
                    <i className="fas fa-times"></i>
                </button>
                <h2 className="text-2xl font-semibold mb-4 text-center">¡UPS!</h2>
                <p className="mb-6">Lo sentimos, ocurrió un problema. Inténtalo más tarde o refresca la página para intentar nuevamente.</p>
                <div className="flex flex-col space-y-3">
                    <button

                        name='btn-gonindex'
                        id='btn-gonindex'
                        className="bg-[#1a2869] text-white py-2 px-2 w-3/5 mx-auto rounded hover:bg-blue-600"
                        onClick={() => window.location.href = '/'}
                    >
                        Ir a Inicio
                    </button>

                </div>
            </div>
        </div>
    );
};

export default ModalErrorTransfer;
