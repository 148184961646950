import { useState, useRef, useEffect } from 'react';
import InputField from '../../inputs/InputField';
import SubmitButton from '../../botones/SubmitButton';
import RememberMeCheckbox from '../RememberMeCheckbox';
import FormHeader from '../FormHeader';
import CustomLink from '../CustomLink';
import { useGoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import validateEmail, { validateMayusMinus, validateNumSpecial } from '../../../utils/validateEmail';
import { signup } from '../../../utils/PixelMeta/metaPixelEvents';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { API_URL } from '../../../constants/constants';
import RegistroExitosoModal from './ModlaCompleto';
import CargandoSpinner from '../../cargacomponetes/CargandoSpinner';
import ModalTelegfonogoogle from './ModalTelegfonogoogle';
import { getRandomInt } from '../../../utils/utils';

async function fetchUserInfo(accessToken) {
    const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch user info');
    }

    return await response.json();
}

function RegisterForm() {
    //variables para la creacion de un nuevo usuario
    const [isName, setIsName] = useState('')
    const [isApellidoPaterno, setIsApellidoPaterno] = useState('')
    const [isApellidoMaterno, setIsApellidoMaterno] = useState('')
    const [isEmail, setIsEmail] = useState('')
    const [isPassword, setIsPassword] = useState('')
    const [isConfirmPassword, setIsConfirmPassword] = useState('')
    const [valuetel, setValuetel] = useState('');
    const [valorTelEnviar, setValorTelEnviar] = useState('');

    const navigate = useNavigate();
    //Manejo de carga y errores
    const [loading, setLoading] = useState(false)
    const [msgLenght, setMsgLenght] = useState('')
    const [msgMayus, setMsgMayus] = useState('')
    const [msgCaracter, setMsgCaracter] = useState('')

    const handleSetName = (e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/(^\w|\s\w)/g, char => char.toUpperCase());
        setIsName(newValue);
    };

    const handleSetApellidoPaterno = (e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/(^\w|\s\w)/g, char => char.toUpperCase());
        setIsApellidoPaterno(newValue);
    };

    const handleSetApellidoMaterno = (e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/(^\w|\s\w)/g, char => char.toUpperCase());
        setIsApellidoMaterno(newValue);
    };

    const handleSetEmail = (e) => setIsEmail(e.target.value)

    const handleSetPassword = (e) => setIsPassword(e.target.value)

    const handleSetConfirmPassword = (e) => setIsConfirmPassword(e.target.value)

    const toastError = (msg) => {
        toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    // useEffect(() => {
    //     // -------- Pixel Meta ---------- //
    //     // viewSignup()
    // }, [])

    const handleSubmit = async () => {
        setMsgLenght('')
        setLoading(true)

        if (isName === '' || isApellidoPaterno === '' || isApellidoMaterno === '') {
            setLoading(false);
            toastError('Debes ingresar tu nombre completo.');
            return;
        }

        if (isEmail === '') {
            setLoading(false);
            toastError('Debes ingresar un correo.');
            return;
        }

        if (validateEmail(isEmail)) {
            setLoading(false);
            toastError('El correo ingresado no es válido.');
            return;
        }

        if (isPassword === '' || isConfirmPassword === '') {
            setLoading(false);
            toastError('Debes ingresar una contraseña y confirmar la contraseña.');
            return;
        }

        if (isPassword.length < 8 || isPassword.length > 40) {
            setMsgLenght('❌')
            setLoading(false);
            return;
        }
        setMsgLenght('✅')

        if (!validateMayusMinus(isPassword)) {
            setMsgMayus('❌')
            setLoading(false);
            return;
        }
        setMsgMayus('✅')

        if (!validateNumSpecial(isPassword)) {
            setMsgCaracter('❌')
            setLoading(false);
            return;
        }
        setMsgCaracter('✅')

        if (isPassword !== isConfirmPassword) {
            toastError('Las contraseñas ingresadas deben ser la misma.')
            setLoading(false)
            return;
        }

        if (!valuetel || valuetel.length < 13 || !valuetel.startsWith('+52')) {
            toastError('Número telefónico no válido. Ingrese un número válido de 10 dígitos con la lada +52.');
            setLoading(false);
            return;
        }

        const fullName = `${isName} ${isApellidoPaterno} ${isApellidoMaterno}`;

        try {
            let event_id_sg = getRandomInt()
            const response = await axios.post(API_URL + '/signup', { nombre: fullName, correo: isEmail, password: isPassword, telefono_movil: valorTelEnviar, event_id: event_id_sg });
            let data_user = await response.data;
            window.localStorage.setItem('k-9SMD879na89dN87m', JSON.stringify(data_user));
            window.localStorage.removeItem('cartLocal');
            signup(isEmail, event_id_sg);

            setIsName('');
            setIsApellidoPaterno('');
            setIsApellidoMaterno('');
            setIsEmail('');
            setIsPassword('');
            setIsConfirmPassword('');
            setValuetel('');
            setValorTelEnviar('');
            abrirModal();
        } catch (error) {
            toastError(error.response.data.error || 'Ha ocurrido un error');
        } finally {
            setLoading(false);
        }
    }

    const registerGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                const userInfo = await fetchUserInfo(tokenResponse.access_token);
                let event_id = getRandomInt()
                try {
                    const response = await axios.post(API_URL + '/googleRegister', { name: userInfo.name, email: userInfo.email, urlImage: userInfo.picture, event_id: event_id });

                    let data_user = await response.data;
                    signup(userInfo.email, event_id, 'Google');
                    window.localStorage.setItem('k-9SMD879na89dN87m', JSON.stringify(data_user));
                    navigate('/');
                } catch (error) {
                    toastError('Ha ocurrido un error al registrar el usuario o el correo ya ha sido registrado.');
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        onError: error => console.error('Login failed:', error),
    });

    const handleChangetel = (valuetel) => {
        setValuetel(valuetel);
        if (valuetel) {
            const telefonoSinCodigoPais = valuetel.slice(-10);
            setValorTelEnviar(telefonoSinCodigoPais);
        }
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [modalGoogle, seModalGoogle] = useState(false);

    const abrirModal = () => {
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
        navigate('/');
    }

    const abrirModalGoogle = () => {
        seModalGoogle(true);
    };

    const cerrarModalGoogle = () => {
        seModalGoogle(false);
        navigate('/');
    }

    return (
        <div className="items-center">
            {loading && (
                <CargandoSpinner txtcharge='Generando cuenta' />
            )}
            <ModalTelegfonogoogle isVisible={modalGoogle} onClose={cerrarModalGoogle} />
            <RegistroExitosoModal isVisible={modalVisible} onClose={cerrarModal} />

            <div className="bg-white p-4 rounded-sm lg:mt-1">
                <div className="login-list flex justify-center my-5 lg:my-0">
                    <h1 className='font-black text-2xl'>
                        <span className="text-black">REGÍSTRATE</span>
                    </h1>
                </div>



                <div className='w-90 mx-auto lg:w-4/5 lg:px-0 mt-2 px-5'>
                    <p className='text-sm mb-1 '>Nombre(s)</p>
                    <input

                        className=' w-full h-full border-gray-400 border p-2 rounded'
                        type="text"
                        placeholder="Ingrese nombre"
                        value={isName}
                        onChange={handleSetName}
                        autoComplete="off"
                    />
                </div>
                <div className='lg:flex  lg:w-4/5 mx-auto lg:my-2'>
                    <div className='w-90 mx-auto lg:w-4/5 lg:px-0 mt-2 px-5'>
                        <p className='text-sm mb-1'>Apellido Paterno</p>
                        <input

                            className=' w-full lg:w-4/5 lg:h-auto h-full border-gray-400 border p-2 rounded'
                            type="text"
                            placeholder="Ingrese apellido paterno"
                            value={isApellidoPaterno}
                            onChange={handleSetApellidoPaterno}
                            autoComplete="off"
                        />
                    </div>



                    <div className='w-90 mx-auto lg:w-4/5 lg:px-0 mt-2 px-5'>
                        <p className='text-sm mb-1'>Apellido Materno</p>
                        <input

                            className=' w-full h-full lg:w-4/5 lg:h-auto  border-gray-400 border p-2 rounded'
                            type="text"
                            placeholder="Ingrese apellido materno"
                            value={isApellidoMaterno}
                            onChange={handleSetApellidoMaterno}
                            autoComplete="off"
                        />
                    </div>
                </div>




                <InputField label="Correo electrónico" type="email" placeholder="Ingrese Correo electrónico" handleChange={handleSetEmail} autoComplete="off" />
                <div className='w-90 mx-auto lg:w-4/5 lg:px-0 mt-2 px-5'>
                    <p className='mb-1 text-sm'>Número de teléfono</p>
                    <PhoneInput
                        defaultCountry="MX"
                        placeholder="Ingrese su número de teléfono"
                        value={valuetel}
                        onChange={handleChangetel}
                        className=' w-full h-full border-gray-400 border p-2 rounded'
                        autoComplete="off"
                    />
                </div>
                <InputField label="Contraseña" type="password" placeholder="Ingresar contraseña" handleChange={handleSetPassword} autoComplete="new-password" />
                <InputField label="Confirmar contraseña" type="password" placeholder="Confirmar contraseña" handleChange={handleSetConfirmPassword} autoComplete="new-password" />
                <div className=' lg:w-4/5 mx-auto'>
                    <p className='text-xs font-semibold my-1 mx-5'>Su contraseña debe incluir lo siguiente:</p>
                    <p className='text-gray-400 text-xs mx-5'>8 - 40 caracteres {msgLenght}</p>
                    <p className='text-gray-400 text-xs mx-5'>Letras mayúsculas y minúsculas {msgMayus}</p>
                    <p className='text-gray-400 text-xs mx-5'>Al menos un número o carácter especial {msgCaracter}</p>
                </div>

                <SubmitButton
                    buttonText="INICIAR"
                    handleClic={handleSubmit}
                    disabledInput={loading}
                    color='#1a2869'
                    textColor='#ffffff'
                    hovercolor='#007bfc'
                    hovertextColor='#ffffff'
                />
                <div className='flex justify-center'>
                    <Link className='text-sky-500 font-extrabold mt-4 lg:mt-4 underline' to='/login'>¿Ya tienes una cuenta?, ingresa aquí</Link>
                </div>
                <div className='mt-3 w-64 mx-auto'>
                    <button className='flex items-center justify-center rounded-xl'
                        name='btn-registerGoogle'
                        id='btn-registerGoogle'
                        onClick={() => registerGoogle()}>
                        <img width="300" height="200"
                            className="w-1/6 flex-shrink-0" src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png' alt='Google logo'></img>
                    </button>
                </div>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default RegisterForm;
