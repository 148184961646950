import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../constants/constants';
import MoonLoader from "react-spinners/MoonLoader";
import ModalDescargaPedidos from './modalesAdmin/ModalDescargaPedidos ';


function TableOrders({ ordersList, total, currentPage, paginasTotales, cambiarPagina, handleSearch }) {

    const info = localStorage.getItem('k-9SMD879na89dN87m') != null ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    // console.log('jkdjkhfaaf', ordersList)
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [currentValue, setCurrentValue] = useState('')

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModalPedidos = () => {
        setIsModalOpen(true);
    };

    const closeModalPedidos = () => {
        setIsModalOpen(false);
    };


    //btna deacarga factura 
    const downloadPdf = async (invoice_id) => {
        try {
            setLoading(true)
            setCurrentValue(invoice_id)
            const response = await fetch(`${API_URL}/odoo/getPDFInvoice/${invoice_id}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Obtiene el blob del PDF
            const blob = await response.blob();

            // Crea un enlace virtual para descargar el archivo
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `factura_${invoice_id}.pdf`); // Usa el invoice_id en el nombre
            document.body.appendChild(link);
            link.click();

            // Limpia el recurso cuando ya no es necesario
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        } finally {
            setLoading(false)
        }
    };


    const goToEdit = (pedido) => {
        navigate('/addPedido', { state: { pedido: pedido } });
    }

    const [selectedStatus, setSelectedStatus] = useState('');

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };
    const DescargarPendientes = async (selectedStatuses) => {
        // console.log('Enviaré', selectedStatuses);
        if (!selectedStatuses || selectedStatuses.length === 0) {
            alert('Por favor, seleccione al menos un estado.');
            return;
        }

        try {
            const response = await fetch(API_URL + '/exportOrders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": info['$tk3m09s'],
                },
                body: JSON.stringify({ options_status_payment: selectedStatuses })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const blob = await response.blob();
            const text = await blob.text();

            // Log the text content to the console
            // console.log('API response text:', text);

            if (text.trim() === '') {
                throw new Error('No data available for the selected statuses.');
            }

            // Recreate the blob from the text to continue with the download
            const bom = '\uFEFF';
            const newBlob = new Blob([bom + text], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(newBlob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'pedidos_exportados.csv';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            alert('There has been a problem with your fetch operation: ' + error.message);
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    //paginaicon 
    const renderPagination = () => {
        const pages = [];
        const maxVisiblePages = 3; // Máximo de páginas visibles antes del elipsis

        if (currentPage > 1) {
            pages.push(
                <button

                    name='btn-previospage3'
                    id='btn-previospage3'
                    key="prev"
                    onClick={() => cambiarPagina(currentPage - 1)}
                    className="px-4 py-2 rounded-lg text-white transition-colors duration-300 bg-gray-400 hover:bg-gray-400"
                >
                    <i className="fas fa-chevron-left"></i>
                </button>
            );
        }

        // Página 1 fija
        pages.push(
            <button

                name='btn-actualpage3'
                id='btn-actualpage3'
                key={1}
                onClick={() => cambiarPagina(1)}
                className={`px-4 py-2 rounded-lg text-white transition-colors duration-300 ${currentPage === 1
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-gray-400 hover:bg-gray-400'
                    }`}
            >
                1
            </button>
        );

        // Páginas ajustables
        let startPage = Math.max(currentPage, 2);
        let endPage = Math.min(startPage + 1, paginasTotales - 3);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button

                    name='btn-previspagemore'
                    id='btn-previspagemore'
                    key={i}
                    onClick={() => cambiarPagina(i)}
                    className={`px-4 py-2 rounded-lg text-white transition-colors duration-300 ${currentPage === i
                        ? 'bg-blue-600 hover:bg-blue-700'
                        : 'bg-gray-400 hover:bg-gray-400'
                        }`}
                >
                    {i}
                </button>
            );
        }

        // Elipsis antes de las últimas páginas

        pages.push(
            <span key="end-ellipsis" className="px-4 py-2 dark:text-white text-black">...</span>
        );


        // Últimas 3 páginas fijas
        for (let i = Math.max(paginasTotales - 2, 2); i <= paginasTotales; i++) {
            pages.push(
                <button

                    name='btn-fijasfin'
                    id='btn-fijasfin'
                    key={i}
                    onClick={() => cambiarPagina(i)}
                    className={`px-4 py-2 rounded-lg text-white transition-colors duration-300 ${currentPage === i
                        ? 'bg-blue-600 hover:bg-blue-700'
                        : 'bg-gray-400 hover:bg-gray-400'
                        }`}
                >
                    {i}
                </button>
            );
        }

        if (currentPage < paginasTotales) {
            pages.push(
                <button

                    name='btn-nextpage3'
                    id='btn-nextpage3'
                    key="next"
                    onClick={() => cambiarPagina(currentPage + 1)}
                    className="px-4 py-2 rounded-lg text-white transition-colors duration-300 bg-gray-400 hover:bg-gray-400"
                >
                    <i className="fas fa-chevron-right"></i>
                </button>
            );
        }

        return pages;
    };


    return (
        <div className=''>
            {/* Tabla de productos */}


            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className='grid grid-cols-1 lg:grid-cols-2'>

                    {/* Search bar */}

                    <div className='mb-8 mt-2 pl-4 ml-8  w-1/3'>
                        <label htmlFor="table-search" className='sr-only'>Search</label>

                        <div className=' mt-5'>
                            <div className={`bg-gray-200 h-10 rounded absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center pl-3 ${isFocused ? 'ring-2 ring-black' : ''}`}>
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <input type="text"
                                    id='table-search'
                                    className='rounded bg-gray-200 border-none w-full focus:outline-none pl-10 pr-3 py-2'
                                    placeholder='Buscar por número de orden'
                                    onChange={handleSearch}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)} />
                            </div>
                        </div>

                    </div>
                    <div className='w-3/5'>

                        <button

                            name='btn-exportorder'
                            id='btn-exportorder'
                            className='bg-blue-900 text-white px-5 ml-4 py-2 rounded' onClick={openModalPedidos}>
                            <i className="fas fa-file-download"></i>  Exportar pedidos
                        </button>
                    </div>
                </div>

                {isModalOpen && (
                    <ModalDescargaPedidos onClose={closeModalPedidos} onDownload={DescargarPendientes} />
                )}


                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">

                        <tr>
                            {/* <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                        </div>
                                    </th> */}
                            <th scope="col" className="px-6 py-3">
                                No. Orden
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Fecha
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Cliente
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Estado de pago
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Estado de entrega
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Factura
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Envio Adicional
                            </th>
                        </tr>
                    </thead>


                    <tbody>
                        {
                            ...ordersList?.map((order) => {
                                const fechaObjeto = new Date(order.created_at);
                                // Formateamos la fecha
                                const fechaFormateada = new Intl.DateTimeFormat('es-ES', {
                                    day: 'numeric',
                                    month: 'short', // "short" para el nombre abreviado del mes
                                    year: 'numeric',
                                }).format(fechaObjeto);

                                // console.log('los datos son ', ordersList);

                                return (
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                        <th  scope="row" className="cursor-pointer px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white">
                                            #{order?.no_orden}
                                        </th>
                                        <td onClick={() => goToEdit(order)} className="px-3 py-2 cursor-pointer">
                                            {fechaFormateada}
                                        </td>

                                        <td onClick={() => goToEdit(order)} className="px-3 py-2 cursor-pointer">
                                            {order?.cliente}
                                        </td>

                                        <td onClick={() => goToEdit(order)} className="px-3 py-2 cursor-pointer">
                                            ${order.total?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>

                                        <td onClick={() => goToEdit(order)} className="px-3 py-2 cursor-pointer">
                                            {
                                                order.estado_pago === 'Pagado'
                                                    ? <p className='bg-green-700 text-white text-center rounded'>{order.estado_pago}</p>
                                                    : order.estado_pago === 'Cancelado'
                                                        ? <p className='bg-red-600 text-white text-center rounded'>{order.estado_pago}</p>
                                                        : <p className='bg-yellow-500 text-white text-center rounded'>{order.estado_pago}</p>
                                            }
                                        </td>

                                        <td onClick={() => goToEdit(order)} className="px-3 py-2 cursor-pointer font-black uppercase text-left dark:text-white text-gray-900">
                                            {order.estado_orden}
                                        </td>


                                        <td className="px-3 py-2 cursor-pointer">
                                            {
                                                order.id_factura && order.id_factura[0] && order.id_factura[0].id ? (
                                                    loading && currentValue == order?.id_factura[0]?.id
                                                        ? (<div className="flex justify-center items-center m-5">
                                                            <MoonLoader color="#1A2869"
                                                                size={30}
                                                                speedMultiplier={1}
                                                                width={2.5}
                                                            />
                                                        </div>)
                                                        : (
                                                            <button

                                                                name='btn-downloadinvoice'
                                                                id='btn-downloadinvoice'
                                                                onClick={() => downloadPdf(order.id_factura[0].id)} className='bg-[#1e3d8b] px-4 py-2 rounded text-white mt-4 hover:bg-[#007bfc]'>
                                                                Descargar factura
                                                            </button>
                                                        )
                                                ) : null
                                            }
                                        </td>

                                        <td onClick={() => goToEdit(order)} className="px-3 py-2 cursor-pointer">
                                            {order.t_envio && order.t_envio.includes("estafeta") ? "Envío por estafeta" : "NA"}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>

                </table>

                {/* Paginado */}
                <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4 pb-4 px-4 dark:bg-gray-700" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                        Mostrando <span className="font-semibold text-gray-900 dark:text-white">1-{ordersList.length}</span> de <span className="font-semibold text-gray-900 dark:text-white">{total}</span>
                    </span>
                    <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 space-x-2">
                        {renderPagination()}
                    </ul>
                </nav>


            </div>
        </div>
    )
}

export default TableOrders