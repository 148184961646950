
export default function validateEmail(emailUser) {
    let regexEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/
    
    if (regexEmail.test(emailUser)) {
        return false
    }
    return true
}

export function validateMayusMinus(texto) {
    const contieneMinusculas = /[a-z]/.test(texto);
    const contieneMayusculas = /[A-Z]/.test(texto);
    
    return contieneMayusculas && contieneMinusculas
}

export function validateNumSpecial(texto) {
    const contieneNumero = /[0-9]/.test(texto);
    const contieneCaracterEspecial = /[^a-zA-Z0-9]/.test(texto);

    return contieneNumero || contieneCaracterEspecial;
}